<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")

  block custom
    change-settings-window
</template>

<script>
import BaseTable from '@crud_view/table'
import ChangeSettingsWindow from '../../components/change_settings_window'
import reportsTable from '../../components/reports_table'

export default {
  components: {
    BaseTable,
    ChangeSettingsWindow
  },
  extends: BaseTable,
  computed:{},
  methods: {
    formatExpandedRow(row) {
      return `<b>Schedule:</b><br/>
      ${row['options']['schedule'].map((s) => `&nbsp;-&nbsp;Days: ${s['days_range']} - every [${s['value']}] - last start: ${s['last_run_time']}`).join('<br/>')}<br/>
      <b>Routes:</b><br/>${row['options']['routes'].split(';').join('<br/>')} <br/>
      <b>Airlines:</b> ${row['options']['airlines'].join(',')} <br/>
      `
    },
    actionChangeSettings(params) {
      this.$emit('showChangeSettingsWindow', params.entity)
    }
  }
}
</script>
