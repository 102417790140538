export default {
  name: 'pesimisation_rules',
  meta: {
    title: 'pesimisation_rules',
    icon: 'skill'
  },
  items: [
    {
      name: 'as_pesimisation_rules_list',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaSearchPesimisationRules',
        queries: {
          index: {
            id: true,
            requestType: true,
            referenceType: true,
            referenceValue: true,
            routes: true,
            offices: true,
            gdsId: true,
            updatedAt: true,
            active: true,
            data: {
              count: true,
              multiTicket: true,
              multiPcc: true
            }
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '40px',
            sortable: false
          },
          {
            name: 'requestType',
            width: '50px',
            format: 'from_options',
            sortable: false
          },
          {
            name: 'referenceType',
            width: '90px',
            format: 'from_options',
            sortable: false
          },
          {
            name: 'referenceValue',
            width: '140px',
            format: 'array_as_string',
            sortable: false
          },
          {
            name: 'routes',
            width: null,
            class: 'overflow_hidden',
            readMore: true,
            sortable: false
          },
          {
            name: 'offices',
            width: '140px',
            format: 'array_as_string',
            sortable: false
          },
          {
            name: 'gdsId',
            width: '80px',
            format: 'from_options',
            sortable: false
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px',
            sortable: false
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time',
            sortable: false
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::SearchPesimisationRuleRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text'
          },
          {
            name: 'reference_type',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          },
          {
            name: 'reference_value',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          },
          {
            name: 'routes',
            type: 'text'
          },
          {
            name: 'offices',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'gds_id',
            type: 'select',
            store: {
              0: 'sabre'
            },
            camelizeLangKey: true
          },
          {
            name: 'active',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              '': 'Any',
              true: 'yes',
              false: 'no'
            }
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'active',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'request_type',
                type: 'select',
                store: {
                  0: 'all',
                  1: 'api'
                },
                translateOptions: true,
                default: '1',
                required: true,
                valueType: 'integer'
              },
              {
                name: 'reference_type',
                type: 'select',
                store: {
                  0: 'all',
                  1: 'depot',
                  2: 'traffic_source'
                },
                translateOptions: true,
                default: '0',
                required: true,
                valueType: 'integer'
              },
              {
                name: 'reference_value',
                type: 'text',
                required: false,
                valueType: 'array_of_integer'
              },
              {
                name: 'routes',
                type: 'textarea',
                required: true,
                rows: 6
              },
              {
                name: 'offices',
                type: 'textarea',
                required: true,
                valueType: 'array_of_string',
                valueDelimiter: ','
              },
              {
                name: 'gds_id',
                type: 'select',
                store: {
                  0: 'sabre'
                },
                translateOptions: true,
                default: '0',
                required: true,
                valueType: 'integer'
              }
            ]
          },
          {
            legend: 'settings',
            translateLegend: true,
            items: [
              {
                name: 'data__count',
                type: 'select',
                store: {
                  '20ITINS': '20i',
                  '50ITINS': '50i',
                  '100ITINS': '100i',
                  '200ITINS': '200i'
                },
                translateOptions: true,
                default: '50ITINS',
                required: true,
                valueType: 'string'
              },
              {
                name: 'data__multiTicket',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'data__multiPcc',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        ]
      }
    }
  ]
}
