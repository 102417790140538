import communication from './communication'

export default {
  mixins: [communication],
  computed: {
    fieldsets() {
      if (!this.config.hasForm) {
        return []
      }
      return this._prepareFieldsets('form')
    },

    records() {
      return this.$store.getters[`${this.storeName}/list`].reduce((acc, i) => acc.concat(Object.freeze(i)), [])
    }
  },

  data() {
    return {
      dialogFormVisible: false,
      crudType: '',
      textMap: {},
      temp: {},
      tempActualize: {},
      formLoader: false,
      formSubmitLoader: false,
      prefilled: {}
    }
  },

  mounted() {
    this.$elItem.$on(this.$channels.filterReady, this.eventFilterReady)
    this.textMap = {
      update: this.translate('form.edit'),
      create: this.translate('form.create')
    }
  },

  beforeDestroy() {
    this.$elItem.$off(this.$channels.filterReady, this.eventFilterReady)
  },

  methods: {

    async actionCreate(row) {
      this.crudType = 'create'
      this.tempActualize = this.actualizeRow(Object.assign(await this.resetTemp(), row))
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },

    async actionUpdate(row) {
      this.crudType = 'update'
      this.dialogFormVisible = true
      this.formLoader = true
      this.tempActualize = this.actualizeRow(row)
    },
    
    eventFilterReady(filters) {
      const prefilled = {}
      for(let i in filters.prefilledData) {
        prefilled[this._preparePrefilledName(i)] = filters.prefilledData[i]
      }
      this.prefilled = prefilled
    },

    async actualizeRow(row) {
      let temp = Object.assign({}, row)
      if (this.crudType === 'update') {
        if (this.config.form.actualize) {
          try {
            temp = await this.$store.dispatch(`${this.storeName}/get`, this.buildActualizeParams(row))
          } catch (err) {
            this.$message({
              message: err,
              type: 'error'
            })
          }
        }
        try {
          temp = await this.actualizeCallback(temp)
        } catch (err) {
          this.$message({
            message: err,
            type: 'error'
          })
        }
      }
      return temp
    },

    updateData() {
      this.$refs.dataForm.validate(async(valid) => {
        if (valid) {
          this.formSubmitLoader = true
          try {
            await this.$store.dispatch(`${this.storeName}/update`, this.buildCrudParams())
            this.afterUpdateCallback()
            this.reloadDependentTabs()
            this.parentItemComponent().$emitAction('refresh')
            this.dialogFormVisible = false
            this.$notify({
              title: this.$t('alert.update_record'),
              message: this.$t('alert.success'),
              type: 'success',
              duration: 2000
            })
          } catch (err) {
            this.$message({
              message: err,
              type: 'error',
              duration: 5 * 1000,
              dangerouslyUseHTMLString: true
            })
          }
          this.$nextTick(() => {
            this.formSubmitLoader = false
          })
        }
      })
    },

    createData() {
      this.$refs.dataForm.validate(async(valid) => {
        if (valid) {
          this.formSubmitLoader = true
          try {
            await this.$store.dispatch(`${this.storeName}/create`, this.buildCrudParams())
            this.afterCreateCallback()
            this.reloadDependentTabs()
            this.parentItemComponent().$emitAction('refresh')
            this.dialogFormVisible = false
            this.$notify({
              title: this.$t('alert.create_record'),
              message: this.$t('alert.success'),
              type: 'success',
              duration: 2000
            })
          } catch (err) {
            this.$message({
              message: err,
              type: 'error',
              duration: 5 * 1000,
              dangerouslyUseHTMLString: true
            })
          }
          this.$nextTick(() => {
            this.formSubmitLoader = false
          })
        }
      })
    },

    async dialogOpenedEvent() {
      if (this.$refs.dataForm) {
        this.$refs.dataForm.clearValidate()
      }
    },

    async dialogOpenEvent() {
      if (this.$refs.dataForm) {
        this.$refs.dataForm.clearValidate()
      }

      this.temp = await this.tempActualize
      if (this.crudType == 'update') {
        this.beforeUpdateCallback()
      } else {
        this.beforeCreateCallback()
      }
      this.$nextTick(() => {
        this.formLoader = false
      })
    },

    async dialogClosedEvent() {
      if (this.crudType === 'update') {
        this.temp = await this.resetTemp()
      }
    },

    async resetTemp() {
      const temp = {}
      for(let i in this.fields) {
        let value = ''
        if (this.fields[i].default !== undefined) {
          value = await this.formatDefaultValue(this.fields[i])
        } else if (this.fields[i].type === 'multiselect') {
          value = this.fields[i].reverse ? '' : []
        }
        if (this.prefilled[this.fields[i].name]) {
          value = this.prefilled[this.fields[i].name]
        }
        temp[this.fields[i].name] = value
      }
      return temp
    },

    formatTemp() {
      return this.temp
    },

    reverseField(field, fieldset) {
      field.reverse = !field.reverse
      if(field.reverse) { // textarea
        field.valueType = 'array_of_integer'
        this.temp[field.name] = this.formatFieldReverse(this.temp[field.name], field)
      } else {
        this.temp[field.name] = this.formatField(this.temp[field.name], field)
        field.valueType = null
      }
    },

    _preparePrefilledName(str) {
      const arr = str.split(/[_-]/)
      let newStr = ''
      for (let i = 1; i < arr.length; i++) {
        newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      }
      return arr[0] + newStr
    },

    attr(fieldName, field, fieldset) {
      if(!field) { field = this.fieldReference[fieldName] }
      if(!fieldset) { fieldset = this.fieldsets[field.fieldsetIndex] }

      return {
        field: field,
        fieldset: fieldset
      }
    },

    isReversed(field, fieldset) {
      return !!field.reverse
    },

    expandSelect(field, fieldset, refs) {
      field.collapseTags = !field.collapseTags
      refs[`reverse_${field.name}`].handleResize()
    }
  }
}
