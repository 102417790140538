export default {
  name: 'seats_block_events',
  meta: {
    title: 'rules',
    icon: 'skill',
    permission: 'rules'
  },
  lazy: true,
  items: [
    {
      name: 'seats_block_settings',
      endpoint: '/persistance/default',
      // endpoint: '/persistance/general/ttn_service',
      type: 'graphql',
      graphql: {
        name: 'aviaSeatsBlockSettings',
        queries: {
          index: {
            id: true,
            name: true,
            options: true,
            settingsType: true,
            active: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'name'
          },
          {
            name: 'settingsType',
            width: '150px',
            sortable: false,
            format: 'from_options'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '125px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::SeatsBlock::Rule::BookingRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'name',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'settings_type',
            type: 'select',
            store: {
              1: 'check_worker',
              2: 'pricer_worker'
            },
            translateOptions: false,
            valueType: 'integer'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'name',
            required: true
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'settingsType',
            type: 'select',
            store: {
              1: 'check_worker',
              2: 'pricer_worker'
            },
            translateOptions: true,
            default: '1',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'options',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'options__validatingSupplier',
            valueType: 'array_of_string',
            required: false,
            ignore: true
          },
          {
            name: 'options__contractId',
            valueType: 'array_of_string',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__index',
            valueType: 'integer',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__seatsRange',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__departureIn',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__minToDeparture',
            valueType: 'integer',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__time',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__flights',
            valueType: 'array_of_integer',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__routes',
            valueType: 'array_of_string',
            required: false,
            ignore: true
          },
          {
            name: 'options__depot_id',
            type: 'select',
            storeKey: 'depots',
            storeLabelKeys: ['name'],
            required: false,
            ignore: true
          },
          {
            name: 'options__remained_seats_operator',
            type: 'select',
            store: {'eq': 'eq', 'gt': 'gt', 'gte': 'gte', 'lt': 'lt', 'lte': 'lte'},
            default: 'eq',
            required: false,
            ignore: true
          },
          {
            name: 'options__remained_seats',
            type: 'text',
            valueType: 'integer',
            required: false,
            ignore: true
          },
          {
            name: 'options__departure_in',
            required: false,
            ignore: true
          },
          {
            name: 'options__departure_date',
            type: 'daterange',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false,
            ignore: true
          },
          {
            name: 'options__active',
            type: 'radiogroup',
            button: true,
            default: 'all',
            operator: 'eq',
            store: {
              'all': 'All',
              true: 'Yes',
              false: 'No'
            },
            required: false,
            ignore: true
          },
          {
            name: 'options__schedules',
            valueType: 'array_of_string',
            required: false,
            ignore: true
          },
          {
            name: 'options__scheme_id',
            valueType: 'integer',
            required: false,
            ignore: true
          },
          {
            name: 'options__system_id',
            valueType: 'integer',
            required: false,
            ignore: true
          },
        ]
      }
    }
  ]
}
