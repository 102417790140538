import Vue from 'vue'
import Channels from './channels'

const __CHANNELS__ = [
  'navigation:tab',
  'navigation:item_ready',
  'filter:apply',
  'filter:ready',
  'filter:collapse',
  'filter:clear',

  'pagination:params',
  'pagination:page',
  'pagination:size',

  'table:selection_change',

  'history:tab_change',

  'navigation:item_finish'
]

const __NAMED_CHANNELS__ = [
  'hooks:after_list',
  'hooks:before_list',
  'hooks:preload_start',
  'hooks:preload_finish',
  'navigation:item_start',
  'navigation:item_activate'
]

const LoadingConstructor = Vue.extend(Channels)

const ChannelService = () => {
  const options = {
    channels: __CHANNELS__,
    namedChannels: __NAMED_CHANNELS__
  }
  let instance = new LoadingConstructor({
    props: options
  })

  return instance
}
export default ChannelService