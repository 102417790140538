export default {
  inject: [
    '$elItem',
    '$elTab',
    '$elContainer',
    '$elRootItem'
  ],

  props: {
    config: {
      required: false,
      type: Object,
      default() {
        return this.$elItem.config
      }
    },
    tab: {
      required: false,
      type: String,
      default() {
        return this.$elContainer.tab
      }
    },
    item: {
      required: false,
      type: String,
      default() {
        return this.$elTab.item
      }
    }
  }
}