export default {
  meta_markup: {
    form: {
      fields: {
        refid: 'RefId',
        dates: 'Date range',
        supplier: "Supplier(s) via ';' or 'AK+' as part",
        route: "Route(s) via ';'",
        configId: 'Config Item Id',
        priceRange: 'Price Range(0-10;1-MAX,0+;0-)',
        groupOptions: 'Group By...',
        currency: 'Currency',
        exceptConfig: 'Exclude Config Id',
        exceptSupplier: "Exclude Supplier(s) via ';'",
        routeType: 'Route type (OW, RT, OJ)'
      },
      legends: {
        main: 'Main filter',
        extended: 'Extended filter'
      },
    },
    table: {
      index: 'Index',
      clicksCount: 'Clicks',
      bookingsCount: 'Total Bookings',
      convBookingsCount: 'Conv. Bookings',
      ctr: 'CTR, %',
      realCtr: 'Real CTR, %',
      metaMarkup: 'Націнки',
      key: 'Key',
      outgoings: 'Витрати',
      revenue: 'Різниця',
      priceRange: 'Price Range',
      route: 'Route',
      systemId: 'Config ID',
      supplier: 'Supplier',
      createdAt: 'Created At',
      finalPrice: 'Final Price',
      currency: 'Currency',
      keyName: 'Key Name',
      convOutgoings: 'Витрати на conv. bookings'
    },
  },
  top_items: {
    table: {
      index: 'Index',
      clicks_count: 'Clicks',
      bookings_count: 'Total Bookings',
      conv_bookings_count: 'Conv. Bookings',
      ctr: 'CTR, %',
      real_ctr: 'Real CTR, %',
      key: 'Key',
      key_name: 'Key Name',
      meta_markup: 'Націнки',
      outgoings: 'Витрати',
      revenue: 'Різниця',
      conv_outgoings: 'Витрати на conv. bookings'
    }
  },
  first_level: {
    table: {
      index: 'Index',
      clicks_count: 'Clicks',
      bookings_count: 'Total Bookings',
      conv_bookings_count: 'Conv. Bookings',
      ctr: 'CTR, %',
      real_ctr: 'Real CTR, %',
      key: 'Key',
      key_name: 'Key Name',
      meta_markup: 'Націнки',
      outgoings: 'Витрати',
      revenue: 'Різниця',
      conv_outgoings: 'Витрати на conv. bookings'
    }
  },
  second_level: {
    table: {
      index: 'Index',
      clicks_count: 'Clicks',
      bookings_count: 'Total Bookings',
      conv_bookings_count: 'Conv. Bookings',
      ctr: 'CTR, %',
      real_ctr: 'Real CTR, %',
      key: 'Key',
      key_name: 'Key Name',
      meta_markup: 'Націнки',
      outgoings: 'Витрати',
      revenue: 'Різниця',
      conv_outgoings: 'Витрати на conv. bookings'
    }
  },
  third_level: {
    table: {
      index: 'Index',
      clicks_count: 'Clicks',
      bookings_count: 'Total Bookings',
      conv_bookings_count: 'Conv. Bookings',
      ctr: 'CTR, %',
      real_ctr: 'Real CTR, %',
      key: 'Key',
      key_name: 'Key Name',
      meta_markup: 'Націнки',
      outgoings: 'Витрати',
      revenue: 'Різниця',
      conv_outgoings: 'Витрати на conv. bookings'
    }
  },
}
