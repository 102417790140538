import docTypeRules from './settings/doc_type_rule'
import refidConfigs from './settings/refid_config'
import configs from './settings/config'

export default {
  namespace: 'frontend',
  component: 'settings',
  meta: {
    title: 'frontend.settings',
    icon: 'skill'
  },
  tabs: [
    docTypeRules,
    refidConfigs,
    configs
  ]
}
