import { loadCrudModules } from '@/utils'

export default {
  name: 'system_tools',
  path: '/system-tools',
  // redirect: '/system-tools/pricing',
  alwaysShow: true,
  meta: {
    title: 'system_tools.default',
    icon: 'chart'
  },
  children: loadCrudModules(require.context('./system_tools', true, /\.js$/))
}
