export default {
  dynamic_pricing_schemes: {
    table: {
      name: 'Name',
      market: 'Market',
      depotId: 'Depot',
      useMarkup: 'Markups',
      validateAuction: 'Validate auction'
    },
    form: {
      fields: {
        depotId: 'Depot',
        name: 'Name',
        market: 'Market',
        useMarkup: 'Use markups',
        validateAuction: 'Validate auction'
      },
      legends: {
        default: 'Filters'
      }
    },
    messages: {
      hasRules: 'Unable to delete. One of the schemas contains rules'
    }
  },
  dynamic_pricing_rules: {
    table: {
      schemeId: 'Scheme',
      refid: 'Refid',
      routeInclude: 'Route include',
      routeExclude: 'Route exclude',
      airline: 'VC',
      airlineType: 'VC type',
      driverIds: 'GDS ID',
      driverType: 'GDS type',
      active: 'Active',
      priority: 'Priority'
    },
    form: {
      fields: {
        schemeId: 'Scheme',
        refid: 'Refid',
        routeInclude: 'Route include',
        routeExclude: 'Route exclude',
        withCombination: 'Combinations',
        airline: 'VC',
        airlineType: 'VC type',
        driverIds: 'GDS',
        driverType: 'GDS type',
        airportToCity: 'Format airports',
        active: 'Active',
        priority: 'Priority',
        forDirect: 'For direct',
        markupRoundType: 'Round type',
        markupRoundValue: 'Round value',
        markupLossPosition: 'Loss of positions',
        markupBehavior: 'Behavior',
        maxMarkupPercent: 'Max markup percent'
      },
      options: {
        airlineType: {
          include: 'Include',
          exclude: 'Exclude',
          0: 'Include',
          1: 'Exclude'
        },
        driverType: {
          include: 'Include',
          exclude: 'Exclude',
          0: 'Include',
          1: 'Exclude'
        },
        markupRoundType: {
          percent: 'Percent',
          amount: 'Amount'
        },
        markupLossPosition: {
          allow: 'Allow',
          block: 'Block'
        },
        markupBehavior: {
          best: 'Best',
          cheapest: 'Cheapest',
          save_position: 'Save position',
          save_profit: 'Save profit'
        }
      },
      legends: {
        markup: 'Markup settings',
        default: 'Filters'
      }
    },
    messages: {
      allInactive: 'All entries must be inactive'
    }
  }
}
