export default {
  seats_block_rules: {
    form: {
      fields: {
        id: 'ID',
        routeKey: 'Route',
        validatingSupplier: 'Airline',
        issueOfficeId: 'Issue office',
        availableSeats: 'Available seats',
        remainedSeats: 'Remained seats',
        startOfSales: 'Start of sales',
        comment: 'Comment',
        currency: 'Currency',
        contractId: 'Contract ID',
        locator: 'Locator',
        saleBeforeDate: 'Last day',
        departureDate: 'Departure',
        priceDiff: 'Diff',
        configKey: 'Config key',
        fareConditions: 'Fare conditions',
        checkPrice: 'Check price',
        checkPriceParams__configId: 'Check config ID',
        blockRemainedUpdate: 'Block remained upd.',
        childRules: 'Child rules',

        flights__departureLocation: 'Departure ',
        flights__arrivalLocation: 'Arrival',
        flights__departureTime: 'time',
        flights__arrivalTime: 'time',
        flights__marketingSupplier: 'Market. sup.',
        flights__marketingFlightNumber: 'Market. flight',
        flightsMarketingFlightNumber: 'Market. flight',
        flights__operatingSupplier: 'Oper. sup.',
        flights__operatingFlightNumber: 'Oper. flight',
        flights__rbd: 'Rbd',
        flights__rbdType: 'Cabin',
        flights__departureTerminal: 'terminal',
        flights__arrivalTerminal: 'terminal',
        flights__aircraft: 'Aircraft',

        fares: 'Fares',
        fares__name: 'Brand name',
        fares__fare: 'Fare',
        fares__taxes: 'Taxes',
        fares__total: 'Total',
        fares__fareCode: 'Code',
        fares__baggage: 'Bag.',
        fares__baggageDetails__dimensionSum: 'size',
        fares__baggageDetails__dimensionUnit: 'in',
        fares__baggageDetails__weight: 'Weight',
        fares__baggageDetails__weightUnit: 'in',
        fares__baggageDetails__count: 'Count',

        fareFamilies__name: 'Brand name',
        fareFamilies__fare: 'Fare',
        fareFamilies__taxes: 'Taxes',
        fareFamilies__total: 'Total',
        fareFamilies__fareCode: 'Code',
        fareFamilies__baggage: 'Bag.',
        fareFamilies__baggageDetails__dimensionSum: 'size',
        fareFamilies__baggageDetails__dimensionUnit: 'in',
        fareFamilies__baggageDetails__weight: 'Weight',
        fareFamilies__baggageDetails__weightUnit: 'in',
        fareFamilies__baggageDetails__count: 'Count',


        features__isLcc: 'Lcc',
        features__isCharter: 'Charter',
        features__showSeatsCount: 'Show count',

        active: 'Active',
        updatedAt: 'Updated at',
        locatorConfigId: 'Locator config Id',
        blockSegmentsUpdate: 'Block seg. upd.'
      },
      options: {},
      placeholder: {
        flights__departureLocation: 'airport',
        flights__arrivalLocation: 'airport',
        saleBeforeDate: 'Last sale date before departure',
        fares__baggageDetails__dimensionSum: 'Format: 10*10*10',
        fares__baggageDetails__dimensionUnit: 'CM/...',
        fares__baggageDetails__weightUnit: 'KG/...',
        fareFamilies__baggageDetails__dimensionSum: 'Format: 10*10*10',
        fareFamilies__baggageDetails__dimensionUnit: 'CM/...',
        fareFamilies__baggageDetails__weightUnit: 'KG/...'
      },
      buttons: {
        add_segment: 'Add segment',
        remove_segment: 'Remove segment',
        add_trip: 'Add trip',
        remove_trip: 'Remove trip',
      },
      trip_fieldsets: ['Inbound', 'Outbound'],
      fieldsets: {
        features: 'Features'
      }
    },
    table: {
      routeKey: 'Route',
      validatingSupplier: 'Suppl.',
      issueOfficeId: 'Offc.',
      availableSeats: 'Avail.',
      remainedSeats: 'Left',
      startOfSales: 'Start of sales',
      active: 'Active',
      updatedAt: 'Updated at',
      lastSaleTime: 'Last sale (IEV)',
      contractId: 'Contract',
      departureDate: 'Depart.',
      priceDiff: 'Diff'
    },
    combine_titles:{
      seats_block_rules_history: 'Old view history',
      seats_block_rule_booking: 'Bookings'
    },
    cloneWindow:{
      title: 'Duplicate rule settings',
      form:{
        availableSeats: 'Available seats',
        weekDays: {
          0: 'Sun',
          1: 'Mon',
          2: 'Tue',
          3: 'Wed',
          4: 'Thu',
          5: 'Fri',
          6: 'Sat',
        },
        from: 'From',
        to: 'To'
      },
      trip_part_name: {
        0: 'Inbound',
        1: 'Outbound'
      },
      confirm: 'Are you sure?',
      msg:{
        title: 'Message',
        success: 'Rule duplicated',
        error: 'Something went wrong, check logs'
      }
    },
    groupChangeWindow: {
      title: 'Change properties',
      form: {
        fromId: 'From ID',
        toId: 'To ID',
        status_label: 'Status',
        status: {
          0: 'Dont change',
          1: 'Disable',
          2: 'Enable'
        },
        fareFamilies: 'Fare families',
        toChange: 'Change'
      },
      msg:{
        success: 'Rules updated success'
      }
    },
    manageSeatsWindow: {
      title: 'Manage seats count',
      form: {
        bookingId: 'Booking ID',
        bookingLocator: 'Booking locator',
        locator: 'Locator',
        seatsCount: 'Count',
        justLog: 'Just log',
      },
      msg:{
        success: 'Seats count changed'
      }
    },
    action:{
      check_price: 'Price',
      group_change: 'Change',
      check_in_gds: 'Check GDS',
    },
    messages: {
      title: 'Message',
      error: 'Error has happened',
      no_item_selected: 'No item selected',
      no_active_items: 'All items without checkConfigId',
      success: 'Worker started'
    }
  },
  seats_block_rules_history: {
    table: {
      time: 'Time',
      userLogin: 'Login',
      changes: 'Changes',
      locator: 'Locator',
      remainedSeatsBefore: 'Remained before',
      seatsCount: 'Seats used',
      remainedSeatsAfter: 'Remained after'
    }
  },
  seats_block_rule_booking: {
    table: {
      bookingId: 'Booking ID',
      orderLocator: 'Locator',
      backendUser: 'Login',
      locator: 'PC Locator',
      remainBefore: 'Remained before',
      seatsCount: 'Seats used',
      remainAfter: 'Remained after'
    },
    form: {
      options: {
        status: {
          0: 'Created',
          1: 'Issued',
          2: 'Refunded',
          3: 'Cancelled'
        }
      }
    }
  }
}
