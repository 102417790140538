import { loadCrudModules } from '@/utils'

export default {
  name: 'core',
  path: '/core',
  redirect: '/core/feature_setting',
  alwaysShow: true,
  meta: {
    title: 'core.default',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./core', true, /\.js$/))
}
