import { jsonToGraphQLQuery } from 'json-to-graphql-query'

export default {
  methods: {
    buildParams(type, params) {
      switch (this.config.type) {
        case 'graphql':
          return this[`_${type}GraphqlParams`](params)
        default:
          return this[`_${type}RestParams`](params)
      }
    },

    _listGraphqlParams() {
      const query = {}
      let collection = {}
      const cfg = this.config.graphql
      if (cfg.queries.index) {
        collection = cfg.queries.index
      } else {
        collection = this.tableFields.filter((i) => !cfg.exclude.includes(i.name)).reduce((acc, i) => {
          acc[i.name] = true
          return acc
        }, {})
      }
      query[cfg.actions.index] = { collection }
      query[cfg.actions.index]['__args'] = {
        limit: this.pagination.limit,
        page: this.pagination.page,
        filters: this.filters
      }
      if (this.pagination.sort) {
        query[cfg.actions.index]['__args'].sort = this.pagination.sort.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
        if (this.pagination.dir) {
          query[cfg.actions.index]['__args'].dir = this.pagination.dir
        }
      }
      query[cfg.actions.index].metadata = {
        totalCount: true
      }

      if (this.config.paginationType === 'cursor') {
        query[cfg.actions.index]['__args']['cursor'] = this.pagination.cursor
        query[cfg.actions.index].metadata['pagination'] = {
          number: true,
          state: true
        }
      }

      if(cfg.baseParams.index){
        let baseParams = { ...cfg.baseParams.index }
        let mergeParams = {}

        if (baseParams.filters){
          Object.entries(baseParams.filters).forEach(([scope, values]) => {
            if (!query[cfg.actions.index]['__args'].filters[scope]) {
              query[cfg.actions.index]['__args'].filters[scope] = []
            }

            query[cfg.actions.index]['__args'].filters[scope] = query[cfg.actions.index]['__args'].filters[scope].concat(
              [...Array.isArray(values) ? values : [values]]
            )

          })

          let { filters: _, ...mergeParams} = baseParams

        } else{
          mergeParams = {...baseParams}
        }

        query[cfg.actions.index]['__args'] = { ...query[cfg.actions.index]['__args'], ...mergeParams }
      }

      return { query: `{${jsonToGraphQLQuery(query)}}` }
    },

    _preloadGraphqlParams() {
      const query = {}
      const cfg = this.config.graphql
      query[cfg.actions.preload] = cfg.preloadFields.reduce((acc, i) => {
        acc[i] = true
        return acc
      }, {})

      return { query: `{${jsonToGraphQLQuery(query)}}` }
    },

    _deleteGraphqlParams() {
      const query = {
        mutation: {}
      }
      query.mutation[this.config.graphql.actions.delete] = { id: true }
      query.mutation[this.config.graphql.actions.delete]['__args'] = { ids: this.multipleSelection.map((i) => { return (i.id.toString().includes('-') ? i.id.toString() : parseInt(i.id)) }) }
      return { query: `${jsonToGraphQLQuery(query)}` }
    },

    _listRestParams() {
      return { ...this.pagination }
    },

    _deleteRestParams() {
      return { ids: this.multipleSelection.map((i) => { return parseInt(i.id) }) }
    },

    _preloadRestParams() {
      return {}
    }
  }
}
