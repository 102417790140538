export default {
  report_position: {
    form: {
      fields: {
        market: 'Market',
        routes: 'Routes',
        searchDates: 'Search date',
        suppliers: 'Airlines',
        byAirline: 'Group airline',
        firstLegDates: 'Departure date',
        lastLegInterval: 'Leg interval',
        type: 'Chart type'
      }
    },
    rank: 'Rank',
    rec_count: 'Count',
    rec_percent: 'Percent',
    discount: 'Discount',
    markup: 'Markup',
    charts: {
      xaxis: 'Date',
      yaxis: 'Value',
      range_xaxis: 'Range',
      price_xaxis: 'Price'
    }
  },

  report_time: {
    form: {
      fields: {
        market: 'Market',
        routes: 'Routes',
        searchDates: 'Search date',
        suppliers: 'Airlines',
        byAirline: 'Group airline',
        firstLegDates: 'Departure date',
        lastLegInterval: 'Leg interval'
      }
    },
    key: 'Key',
    first: 'First',
    second: 'Second',
    charts: {
      xaxis: 'Hours',
      yaxis: 'Position count'
    }
  }
}