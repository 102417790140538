<template lang="pug">
  div(class="el-badge")
    slot
    transition(name="el-zoom-in-center")
      sup(
        v-show="!hidden && (content || content === 0 || isDot)"
        v-text="content"
        class="el-badge__content"
        :class="badgeClasses"
        :style="badgeStyle"
      )
</template>

<script>
import Badge from 'element-ui/packages/badge/index.js'
export default {
  name: 'TtnBadge',
  extends: Badge,
  props: {
    badgeStyle: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    badgeClasses() {
      return [
        this.type ? 'el-badge__content--' + this.type : null,
        {
          'is-fixed': this.$slots.default,
          'is-dot': this.isDot
        }
      ]
    }
  }
}
</script>
