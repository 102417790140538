import gdsStore from '@/settings/modules/avia/general/gds_store'

export default {
  name: 'operator_schedule',
  meta: {
    title: 'restrictions',
    icon: 'skill',
    permission: 'restrictions'
  },
  lazy: true,
  items: [
    {
      name: 'operator_schedule',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaOperatorScheduleItems',
        queries: {
          index: {
            id: true,
            name: true,
            active: true,
            createdAt: true,
            updatedAt: true,
            settings: true,
            inQueue: true,
            isSystem: true,
            shift: true
          }
        }
      },
      table: {
        class: 'operator-schedule-table',
        items: [
          'selection',
          { name: 'id', width: '50px' },
          { name: 'name', width: '150px'},
          {
            name: 'mo',
            sortable: false
          }, 
          {
            name: 'tu',
            sortable: false
          }, 
          {
            name: 'we',
            sortable: false
          }, 
          {
            name: 'th',
            sortable: false
          }, 
          {
            name: 'fr',
            sortable: false
          }, 
          { 
            name: 'sa',
            sortable: false
          },
          { 
            name: 'su', 
            sortable: false
          },
          {
            name: 'active',
            type: 'checkbox',
            width: '75px',
            align: 'center'
          },
          {
            name: 'createdAt',
            sortable: false
          },
          { 
            name: 'actions',
            width: '75px',
            align: 'center',
            sortable: false
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::OperatorScheduleRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {},
      form: {
        actualize: true,
        labelWidth: '70px',
        fullscreen: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false
          },
          {
            name: 'name',
            type: 'text',
            required: true            
          },
          {
            name: 'shift',
            required: true,
            store: [
              { id: '0-24', name: 'Full-day' }, 
              { id: '0-8', name: 'Night' },
              { id: '8-16', name: 'Day' },
              { id: '16-24', name: 'Evening' }
            ],
            storeLabelKeys: ['id', 'name'],
            type: 'array_of_string'
          },
          {
            name: 'active',
            type: 'checkbox',
            required: false,
            default: false
          },
          {
            name: 'settings',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'settings__pc',
            required: false,
            ignore: true,
            label: false,
            type: 'string'
          },
          {
            name: 'settings__status',
            required: false,
            ignore: true,
            store: [
              { id: 'PN', name: 'Payed. Not issued' }, 
              { id: 'PC', name: 'Cancelled. Payed' },
              { id: 'NP', name: 'Pay not confirm' }
            ],
            storeLabelKeys: ['id', 'name'],
            type: 'array_of_string'
          },
          {
            name: 'settings__driver',
            required: false,
            ignore: true,
            store: gdsStore.arrayByName(),
            storeLabelKeys: ['id', 'name'],
            collapseTags: true,
            type: 'array_of_string'
          },
          {
            name: 'settings__depot',
            required: false,
            ignore: true,
            storeKey: 'depots',
            storeLabelKeys: ['id', 'name'],
            lazy: true,
            collapseTags: true,
            splitInput: true,
            type: 'array_of_integer'
          },
          {
            name: 'settings__depot_type',
            required: false,
            ignore: true,
            store: [
              { id: 'b2b', name: 'B2B' }, 
              { id: 'b2c', name: 'B2C' },
              { id: 'all', name: 'ALL' }
            ],
            storeLabelKeys: ['id', 'name'],
            type: 'array_of_string'
          },
          {
            name: 'settings__depot_except',
            required: false,
            ignore: true
          },
          {
            name: 'settings__driver_except',
            required: false,
            ignore: true
          },
          {
            name: 'settings__backlog',
            required: false,
            ignore: true
          },
          {
            name: 'settings__depot_except',
            required: false,
            ignore: true
          },
          {
            name: 'settings__driver_except',
            required: false,
            ignore: true
          }
        ]
      }
    }
  ]
}
