<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    el-date-picker(
      v-model="dataValue"
      :type="field.type"
      :placeholder="translate(`form.placeholder.${field.name}`, translate(`form.placeholder.datepicker`))"
      :disabled="isDisabledField(field, fieldset.legend)"
      @change="changeFieldValue(field, $event)"
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
</template>

<script>

import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnDatePicker',
  mixins: [formElements],
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        'style': 'width: 100%',
        'picker-options': field.options,
        'value-format': field.valueFormat,
        'range-separator': 'To',
        'start-placeholder': 'Start date',
        'end-placeholder': 'End date'
      }
    }
  }
}
</script>
