export default {
  name: 'aviaFareRules',
  exclude: ['actions', 'expand'],
  queries: {
    index: {
      id: true,
      priority: true,
      active: true,
      validatingSupplier: true,
      rbd: true,
      fareBasis: true,
      fareCode: true,
      penaltyBeforeDepartureAmount: true,
      penaltyBeforeDepartureCurrency: true,
      penaltyBeforeDepartureNonRef: true,
      penaltyAfterDepartureAmount: true,
      penaltyAfterDepartureCurrency: true,
      penaltyAfterDepartureNonRef: true,
      penaltyNoshowAmount: true,
      penaltyNoshowCurrency: true,
      penaltyNoshowNonRef: true,
      bsp: true,
      gds: true
    }
  }
}