<template lang="pug">
  el-row(:gutter="20" class="search-block")
    el-col(:span="12")
      el-row(type="flex" justify="space-around")
        el-col
          el-descriptions(:border="true" :column="3" size="mini")
            el-descriptions-item(
              v-for="(paxCnt, paxType) in passengers"
              :label="paxType"
              :labelClassName="'pax-type' + (paxCnt > 0 ? ' pax-type-active' : '')"
              :contentClassName="paxCnt > 0 ? 'pax-type-fill' : ''"
              :key="`p_${paxType}`"
            )
              span(:key="`paxx_${paxType}`" :class="{'pax-count': paxCnt > 0, 'pax-count-zero': paxCnt === 0}") {{paxCnt}}
    el-col(:span="12")
      el-row(type="flex" justify="space-around")
        el-col(v-for="(loc, l_index) in locations" :key="`step_${l_index}`" )
          el-steps
            el-step(icon="el-icon-top-right" status="finish")
              template(v-slot:title)
                div {{loc.departure}},
                  span(class="step-loc-date") &nbsp;{{loc.date}}
            el-step(:title="loc.arrival" icon="el-icon-bottom-right" status="success")
</template>

<script>
export default {
  props: {
    passengers: {
      required: true,
      type: Object
    },
    locations: {
      required: true,
      type: Array
    }
  }
}
  
</script>

<style lang="scss" scoped>
  .search-block {
    .el-descriptions__body{
      .el-descriptions__table .el-descriptions-item__cell {
        &.pax-type-fill {
          background: rgb(225, 243, 216);
        }
        &.pax-type {
          font-weight: bold;
          font-size:14px;
          text-transform: uppercase;
          &.pax-type-active {
            color: #67C23A;
          }
        }
        .pax-count {
          font-weight: bold;
          font-size:14px;
          color: red;
          padding:0 5px 0 3px;
        }
      }
    }
    .el-steps {
      .el-step__title {
        font-size: 12px;
        line-height: 15px;
        .step-loc-date {
          font-size: 11px;
        }
      }
      .el-step__icon.is-icon {
        height: 15px;
        width: 20px;
        .el-step__icon-inner{
          font-size: 16px;
        }
      }
      .el-step__description {
        font-size: 10px;
      }
    }
  }
</style>
