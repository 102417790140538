<template lang="pug">
  div(class="list-content")
    el-table(:data="records")
      el-table-column(prop="id", label="ID")
      el-table-column(label="User")
        template(slot-scope="scope")
          span {{ scope.row.backendUsers.login }} ({{scope.row.userId}})
      el-table-column(prop="entity", label="Entity")
      el-table-column(label="Report")
        template(slot-scope="scope")
          el-button(type="primary", icon="el-icon-download", @click="actionDownload(scope.row)")
      el-table-column(label="Date Created")
        template(slot-scope="scope")
          span {{new Date(scope.row.createdAt) | moment("LLL")}}

</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  data() {
    return {
    }
  },

  methods: {
    getFileName(filePath) {
      return filePath.substring(filePath.lastIndexOf('/') + 1)
    },

    actionDownload(row) {
      this.startDownload(row)
    },

      async startDownload(row) {
        try {
          const query = {
            content: true,
            __args: {
              key: this.getFileName(row['filePath'])
            }
          }

          let result = await this.graphql('reportFileFileContent', query, 'collection')

          if(result.content) {
            const blob = new Blob([result.content], { type: 'text/csv' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = Date.now() + ".csv";
            link.click()
            URL.revokeObjectURL(link.href)
          } else{
            this.$message({
              message: this.translate('messages.download_empty_content'),
              type: 'error'
            })
          }

          return true
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      },
  },
}
</script>
