export default {
  namespace: 'railway',
  component: 'stations',
  meta: {
    title: 'railway.directory',
    icon: 'signpost-split'
  },
  tabs: [
    {
      name: 'stations',
      items: [
        {
          name: 'stations_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          preload: true,
          preloadStores: [
            {
              name: 'railRoads'
            }
          ],
          graphql: {
            name: 'stations',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                code: true,
                name: true,
                seo: true,
                parentCode: true,
                replaceCode: true,
                geoCityCode: true,
                geoCountryCode: true,
                iataCode: true,
                status: true,
                score: true,
                latitude: true,
                longitude: true,
                railRoad: {
                  id: true,
                  name: true,
                  geoCountryCode: true
                },
                stationSynonyms: {
                  id: true,
                  name: true,
                  locale: true,
                  status: true
                },
                stationCities: {
                  id: true,
                  name: true,
                  locale: true,
                  status: true
                },
                stationProviders: {
                  id: true,
                  providerName: true,
                  providerCode: true,
                  status: true
                  // providerId: true
                }
              }
            },
            preloadFields: ['railRoads{id name}']
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              'code', 'name', 'seo', 'parentCode', 'replaceCode', 'geoCityCode', 'geoCountryCode', 'iataCode',
              { name: 'status', type: 'switch' },
              'score', 'latitude', 'rail_road_id',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }, 'expand'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'StationRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'code',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'name',
                operator: 'like',
                type: 'text'
              },
              {
                name: 'seo',
                operator: 'like',
                type: 'text'
              },
              {
                name: 'parent_code',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'replace_code',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'geo_city_code',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'geo_country_code',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'iata_code',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'rail_road_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'railRoads',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                scope: 'stations'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                valueType: 'integer',
                required: false
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'code',
                type: 'text',
                required: true
              },
              {
                name: 'seo',
                type: 'text',
                required: true
              },
              {
                name: 'parent_code',
                type: 'text',
                required: false
              },
              {
                name: 'replace_code',
                type: 'text',
                required: false
              },
              {
                name: 'geo_city_code',
                type: 'text',
                required: false
              },
              {
                name: 'geo_country_code',
                type: 'text',
                required: false
              },
              {
                name: 'iata_code',
                type: 'text',
                required: false
              },
              {
                name: 'status',
                type: 'switch',
                default: false,
                required: true
              },
              {
                name: 'score',
                type: 'number',
                default: 0,
                valueType: 'integer',
                required: true
              },
              {
                name: 'latitude',
                type: 'float',
                valueType: 'float',
                default: 0.0,
                required: true
              },
              {
                name: 'longitude',
                type: 'float',
                valueType: 'float',
                default: 0.0,
                required: true
              },
              {
                name: 'rail_road_id',
                type: 'select',
                storeKey: 'railRoads',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                // type: 'number',
                // valueType: 'integer',
                require: true
              }
            ]
          },
          combined: [
            {
              name: 'station_synonyms_list',
              graphql: {
                name: 'stationSynonyms'
              },
              type: 'graphql',
              boot: {
                type: 'local',
                row_key: 'stationSynonyms',
                relation: {
                  stationId: 'id'
                }
              },
              table: {
                items: [
                  'selection',
                  {
                    name: 'id',
                    width: '80px'
                  }, 'name', 'locale',
                  { name: 'status', type: 'switch' },
                  {
                    name: 'actions',
                    width: '70px',
                    align: 'center'
                  }
                ]
              },
              actions: {
                row: [
                  {
                    name: 'update',
                    icon: 'el-icon-edit',
                    type: 'primary',
                    plain: true
                  }
                ],
                panel: [
                  {
                    name: 'create',
                    icon: 'el-icon-plus',
                    type: 'primary',
                    plain: false
                  },
                  {
                    name: 'delete',
                    icon: 'el-icon-delete',
                    type: 'danger',
                    plain: true,
                    dependent: 'selection'
                  },
                  {
                    name: 'history',
                    icon: 'el-icon-refresh',
                    type: 'warning',
                    plain: true,
                    params: {
                      record_class: 'StationSynonymRelation'
                    }
                  }
                ]
              },
              form: {
                actualize: true,
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false
                  },
                  {
                    name: 'station_id',
                    type: 'hidden',
                    required: true
                  },
                  {
                    name: 'name',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'locale',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'status',
                    type: 'switch',
                    default: false,
                    required: true
                  }
                ]
              }
            }, {
              name: 'station_cities_list',
              graphql: {
                name: 'stationCities'
              },
              type: 'graphql',
              boot: {
                type: 'local',
                row_key: 'stationCities',
                relation: {
                  stationId: 'id'
                }
              },
              table: {
                items: [
                  'selection',
                  {
                    name: 'id',
                    width: '80px'
                  }, 'name', 'locale',
                  { name: 'status', type: 'switch' },
                  {
                    name: 'actions',
                    width: '70px',
                    align: 'center'
                  }
                ]
              },
              actions: {
                row: [
                  {
                    name: 'update',
                    icon: 'el-icon-edit',
                    type: 'primary',
                    plain: true
                  }
                ],
                panel: [
                  {
                    name: 'create',
                    icon: 'el-icon-plus',
                    type: 'primary',
                    plain: false
                  },
                  {
                    name: 'delete',
                    icon: 'el-icon-delete',
                    type: 'danger',
                    plain: true,
                    dependent: 'selection'
                  },
                  {
                    name: 'history',
                    icon: 'el-icon-refresh',
                    type: 'warning',
                    plain: true,
                    params: {
                      record_class: 'StationCityRelation'
                    }
                  }
                ]
              },
              form: {
                actualize: true,
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false
                  },
                  {
                    name: 'station_id',
                    type: 'hidden',
                    required: true
                  },
                  {
                    name: 'name',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'locale',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'status',
                    type: 'switch',
                    default: false,
                    required: true
                  }
                ]
              }
            }, {
              name: 'station_providers_list',
              graphql: {
                name: 'stationProviders'
              },
              type: 'graphql',
              boot: {
                type: 'local',
                row_key: 'stationProviders',
                relation: {
                  stationId: 'id'
                }
              },
              table: {
                items: [
                  'selection',
                  {
                    name: 'id',
                    width: '80px'
                  }, 'providerName', 'providerCode',
                  { name: 'status', type: 'switch' },
                  {
                    name: 'actions',
                    width: '70px',
                    align: 'center'
                  }
                ]
              },
              actions: {
                row: [
                  {
                    name: 'update',
                    icon: 'el-icon-edit',
                    type: 'primary',
                    plain: true
                  }
                ],
                panel: [
                  {
                    name: 'create',
                    icon: 'el-icon-plus',
                    type: 'primary',
                    plain: false
                  },
                  {
                    name: 'delete',
                    icon: 'el-icon-delete',
                    type: 'danger',
                    plain: true,
                    dependent: 'selection'
                  },
                  {
                    name: 'history',
                    icon: 'el-icon-refresh',
                    type: 'warning',
                    plain: true,
                    params: {
                      record_class: 'StationProviderRelation'
                    }
                  }
                ]
              },
              form: {
                actualize: true,
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false
                  },
                  {
                    name: 'station_id',
                    type: 'hidden',
                    required: true
                  },
                  {
                    name: 'provider_name',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'provider_code',
                    type: 'text',
                    required: true
                  },
                  {
                    name: 'status',
                    type: 'switch',
                    default: false,
                    required: true
                  }
                ]
              }
            }
          ]
        }
      ]
    }, {
      name: 'rail_roads',
      items: [
        {
          name: 'rail_roads_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railRoads',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                name: true,
                geoCountryCode: true
              }
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              'name', 'geoCountryCode',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'RailRoadRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'name',
                operator: 'like',
                type: 'text'
              },
              {
                name: 'geo_country_code',
                type: 'text',
                operator: 'eq'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'geo_country_code',
                type: 'text',
                required: true
              }
            ]
          }
        }
      ]
    }
  ]
}