<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    el-select(
      v-model="dataValue"
      :placeholder="translate(`form.placeholder.${field.name}`, translate(`form.placeholder.select`))"
      :remote-method="data => remoteMethod(data, field, blockScope, dataValue, fieldset)"
      :disabled="isDisabledField(field, fieldset.legend)"
      @change="changeFieldValue(field, $event)"
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
      slot(v-bind:options="fieldOptions(field, blockScope, dataValue, fieldset)" v-bind:field="field")
        el-option(
          v-for="option in fieldOptions(field, blockScope, dataValue, fieldset)"
          :key="`${option.id}_${option.label}`"
          :label="field.translateOptions ? translate(`form.options.${field.name}.${option.label}`) : option.label"
          :value="valueAsObject ? option : option.id"
        )
          slot(v-if="$scopedSlots.options" v-bind:option="option" name="options")
</template>

<script>
import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnSelect',
  mixins: [formElements],
  props: {
    valueAsObject: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        clearable: true,
        filterable: true,
        remote: field.lazy || false
      }
    }
  }
}
</script>
