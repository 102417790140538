export default {
  component: 'xex_pricing',
  meta: {
    title: 'dynamicPricing',
    icon: 'table',
    roles: ['xex_pricing_component']
  },
  tabs: [
    {
      name: 'xex_base',
      meta: {
        title: 'xexBase',
        roles: ['xex_pricing_tab']
      },
      lazy: true,
      items: [
        {
          name: 'xex_schemes',
          endpoint: '/persistance/default',
          // roles: ['xex_pricing_schemes'],
          type: 'graphql',
          graphql: {
            name: 'aviaDynamicPricingSchemes',
            queries: {
              index: {
                id: true,
                depotId: true,
                name: true,
                useMarkup: true,
                validateAuction: true,
                rules: {
                  id: true,
                  schemeId: true,
                  refid: true,
                  forDirect: true,
                  routeInclude: true,
                  routeExclude: true,
                  withCombination: true,
                  airline: true,
                  airlineType: true,
                  airportToCity: true,
                  active: true,
                  updatedAt: true,
                  markupRoundValue: true,
                  markupRoundType: true,
                  markupLossPosition: true,
                  markupBehavior: true,
                  maxMarkupPercent: true
                },
                updatedAt: true
              }
            }
            // preloadFields: ['depots']
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'name',
              {
                name: 'depotId',
                format: 'from_boot',
                storeKey: 'depots_hash',
                storeLabelKey: 'name'
              },
              {
                name: 'useMarkup',
                type: 'switch'
              },
              {
                name: 'validateAuction',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              },
              {
                name: 'expand'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::DynamicPricing::SchemeRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            collapsed: true,
            fieldsets: [
              {
                legend: 'default',
                items: [
                  {
                    name: 'name',
                    type: 'text',
                    operator: 'like',
                    scope: 'dynamicPricingSchemes'
                  }
                ]
              },
              {
                legend: 'extended',
                collapsed: true,
                items: [
                  {
                    name: 'depot_id',
                    operator: 'eq',
                    type: 'select',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true,
                    scope: 'dynamicPricingSchemes'
                  },
                  {
                    name: 'use_markup',
                    type: 'radiogroup',
                    button: true,
                    operator: 'eq',
                    store: {
                      '': 'Any',
                      true: 'yes',
                      false: 'no'
                    },
                    scope: 'dynamicPricingSchemes'
                  }
                ]
              }
            ]
          },
          form: {
            actualize: true,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'depot_id',
                    type: 'select',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true
                  },
                  {
                    name: 'name',
                    type: 'text'
                  },
                  {
                    name: 'validate_auction',
                    type: 'checkbox',
                    default: false,
                    required: false
                  },
                  {
                    name: 'use_markup',
                    type: 'checkbox',
                    default: false,
                    required: false
                  }
                ]
              }
            ]
          },

          history: [
            'Avia::DynamicPricing::SchemeRelation'
          ],

          combined: [
            {
              name: 'xex_rules',
              graphql: {
                name: 'aviaDynamicPricingRules'
              },
              type: 'graphql',
              boot: {
                type: 'local',
                row_key: 'rules',
                relation: {
                  schemeId: 'id'
                }
              },
              table: {
                items: [
                  'selection',
                  {
                    name: 'id',
                    width: '80px'
                  },
                  {
                    name: 'schemeId',
                    format: 'from_instance',
                    storeKey: 'schemes',
                    storeLabelKey: 'name'
                  },
                  {
                    name: 'refid',
                    format: 'array_as_string',
                    storeKey: 'traffic_sources_hash',
                    storeLabelKey: 'name',
                    default: '-',
                    readMore: true,
                    readMoreChars: 50
                  },
                  {
                    name: 'routeInclude',
                    default: '-'
                  },
                  {
                    name: 'routeExclude',
                    default: '-'
                  },
                  {
                    name: 'airline',
                    format: 'array_as_string'
                  },
                  {
                    name: 'airlineType',
                    format: 'from_options'
                  },
                  {
                    name: 'active',
                    type: 'switch',
                    width: '70px'
                  },
                  {
                    name: 'updatedAt',
                    width: '120px',
                    format: 'date_time'
                  },
                  {
                    name: 'actions',
                    width: '60px'
                  },
                  {
                    name: 'expand',
                    type: 'expand'
                  }
                ]
              },
              actions: {
                row: [
                  {
                    name: 'update',
                    icon: 'el-icon-edit',
                    type: 'primary',
                    plain: true
                  }
                ],
                panel: [
                  {
                    name: 'create',
                    icon: 'el-icon-plus',
                    type: 'primary',
                    plain: false
                  },
                  {
                    name: 'delete',
                    icon: 'el-icon-delete',
                    type: 'danger',
                    plain: true,
                    dependent: 'selection'
                  }
                ]
              },
              form: {
                fieldsets: [
                  {
                    legend: 'Default',
                    items: [
                      {
                        name: 'id',
                        type: 'hidden',
                        required: false,
                        valueType: 'integer'
                      },
                      {
                        name: 'scheme_id',
                        type: 'hidden',
                        required: true,
                        valueType: 'integer'
                      },
                      {
                        name: 'refid',
                        type: 'text',
                        valueType: 'array_of_integer',
                        valueDelimiter: ','
                      },
                      {
                        name: 'for_direct',
                        type: 'checkbox',
                        default: false,
                        required: false
                      },
                      {
                        name: 'airline',
                        type: 'text',
                        valueType: 'array_of_string',
                        valueDelimiter: ','
                      },
                      {
                        name: 'airline_type',
                        type: 'text',
                        valueType: 'integer'
                      },
                      {
                        name: 'route_include',
                        type: 'textarea',
                        required: false,
                        // valueType: 'array_of_string',
                        valueDelimiter: ','
                      },
                      {
                        name: 'route_exclude',
                        type: 'textarea',
                        required: false,
                        // valueType: 'array_of_string',
                        valueDelimiter: ','
                      },
                      {
                        name: 'airportToCity',
                        type: 'checkbox',
                        default: false,
                        required: false
                      },
                      {
                        name: 'active',
                        type: 'checkbox',
                        default: false,
                        required: false
                      }
                    ]
                  }
                ]
              },
              history: ['Avia::DynamicPricing::RuleRelation']
            }
          ]
        },
        {
          name: 'xex_schemes2',
          endpoint: '/persistance/default',
          // roles: ['xex_pricing_schemes'],
          type: 'graphql',
          lazy: true,
          graphql: {
            name: 'aviaDynamicPricingSchemes',
            queries: {
              index: {
                id: true,
                depotId: true,
                name: true,
                useMarkup: true,
                validateAuction: true,
                rules: {
                  id: true,
                  schemeId: true,
                  refid: true,
                  forDirect: true,
                  routeInclude: true,
                  routeExclude: true,
                  withCombination: true,
                  airline: true,
                  airlineType: true,
                  airportToCity: true,
                  active: true,
                  updatedAt: true,
                  markupRoundValue: true,
                  markupRoundType: true,
                  markupLossPosition: true,
                  markupBehavior: true,
                  maxMarkupPercent: true
                },
                updatedAt: true
              }
            }
            // preloadFields: ['depots']
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'name',
              {
                name: 'depotId',
                format: 'from_boot',
                storeKey: 'depots_hash',
                storeLabelKey: 'name'
              },
              {
                name: 'useMarkup',
                type: 'switch'
              },
              {
                name: 'validateAuction',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              },
              {
                name: 'expand'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::DynamicPricing::SchemeRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            collapsed: true,
            fieldsets: [
              {
                legend: 'default',
                items: [
                  {
                    name: 'name',
                    type: 'text',
                    operator: 'like',
                    scope: 'dynamicPricingSchemes'
                  }
                ]
              },
              {
                legend: 'extended',
                collapsed: true,
                items: [
                  {
                    name: 'depot_id',
                    operator: 'eq',
                    type: 'select',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true,
                    scope: 'dynamicPricingSchemes'
                  },
                  {
                    name: 'use_markup',
                    type: 'radiogroup',
                    button: true,
                    operator: 'eq',
                    store: {
                      '': 'Any',
                      true: 'yes',
                      false: 'no'
                    },
                    scope: 'dynamicPricingSchemes'
                  }
                ]
              }
            ]
          },
          form: {
            actualize: true,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'depot_id',
                    type: 'select',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true
                  },
                  {
                    name: 'name',
                    type: 'text'
                  },
                  {
                    name: 'validate_auction',
                    type: 'checkbox',
                    default: false,
                    required: false
                  },
                  {
                    name: 'use_markup',
                    type: 'checkbox',
                    default: false,
                    required: false
                  }
                ]
              }
            ]
          },

          history: [
            'Avia::DynamicPricing::SchemeRelation'
          ]
        }
      ]
    },
    {
      name: 'xex_base2',
      meta: {
        title: 'xexBase',
        roles: ['xex_pricing_tab']
      },
      items: [
        {
          name: 'xex_schemes3',
          endpoint: '/persistance/default',
          // roles: ['xex_pricing_schemes'],
          type: 'graphql',
          lazy: true,
          graphql: {
            name: 'aviaDynamicPricingSchemes',
            queries: {
              index: {
                id: true,
                depotId: true,
                name: true,
                useMarkup: true,
                validateAuction: true,
                rules: {
                  id: true,
                  schemeId: true,
                  refid: true,
                  forDirect: true,
                  routeInclude: true,
                  routeExclude: true,
                  withCombination: true,
                  airline: true,
                  airlineType: true,
                  airportToCity: true,
                  active: true,
                  updatedAt: true,
                  markupRoundValue: true,
                  markupRoundType: true,
                  markupLossPosition: true,
                  markupBehavior: true,
                  maxMarkupPercent: true
                },
                updatedAt: true
              }
            }
            // preloadFields: ['depots']
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'name',
              {
                name: 'depotId',
                format: 'from_boot',
                storeKey: 'depots_hash',
                storeLabelKey: 'name'
              },
              {
                name: 'useMarkup',
                type: 'switch'
              },
              {
                name: 'validateAuction',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              },
              {
                name: 'expand'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::DynamicPricing::SchemeRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            collapsed: true,
            fieldsets: [
              {
                legend: 'default',
                items: [
                  {
                    name: 'name',
                    type: 'text',
                    operator: 'like',
                    scope: 'dynamicPricingSchemes'
                  }
                ]
              },
              {
                legend: 'extended',
                collapsed: true,
                items: [
                  {
                    name: 'depot_id',
                    operator: 'eq',
                    type: 'select',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true,
                    scope: 'dynamicPricingSchemes'
                  },
                  {
                    name: 'use_markup',
                    type: 'radiogroup',
                    button: true,
                    operator: 'eq',
                    store: {
                      '': 'Any',
                      true: 'yes',
                      false: 'no'
                    },
                    scope: 'dynamicPricingSchemes'
                  }
                ]
              }
            ]
          },
          form: {
            actualize: true,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'depot_id',
                    type: 'select',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true
                  },
                  {
                    name: 'name',
                    type: 'text'
                  },
                  {
                    name: 'validate_auction',
                    type: 'checkbox',
                    default: false,
                    required: false
                  },
                  {
                    name: 'use_markup',
                    type: 'checkbox',
                    default: false,
                    required: false
                  }
                ]
              }
            ]
          },

          history: [
            'Avia::DynamicPricing::SchemeRelation'
          ]
        }
      ]
    }
  ]
}
