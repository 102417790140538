<template lang="pug">
  extends /table
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatCell({ row, $column, column, cellValue, index }) {
      if (column.name == 'binStart') {
        return cellValue + ' *** ' + row.binEnd
      }
      return cellValue
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 1:
          return { 'color': (row['active'] ? 'green' : 'red') }
        default:
          return {}
      }
    }
  }
}
</script>