export default {
  cache_warmup_worker: {
    table: {
      id: 'ID',
      depotId: 'Depot ID',
      trafficSourceId: 'Traf. ID',
      requestType: 'Req. type',
      routes: 'Routes',
      routeTypes: 'Route types',
      departurePeriods: 'Departure periods',
      configIds: 'Config ids',
      schedule: 'Schedule',
      active: 'Active',
      forGoogleFeed: 'For google feed',
      forTtnAviaVi: 'For ttn avia vi',
      lastPerformTime: 'Last perform'
    },
    form: {
      fields: {
        id: 'ID',
        depotId: 'Depot ID',
        trafficSourceId: 'or Traf. ID',
        requestType: 'Request type',
        routes: 'Routes',
        routeTypes: 'Route types',
        departurePeriods: 'Departure periods',
        configIds: 'Config ids',
        schedule: 'Schedule',
        active: 'Active',
        forGoogleFeed: 'Google feed',
        forTtnAviaVi: 'TtnAviaVi',
        lastPerformTime: 'Last perform time',
        requestParams: 'Request params'
      },
      options: {
        requestType: {
          0: 'Optimized',
          'optimized': 'Optimized',
          1: 'Api request',
          'api_request': 'Api request',
        },
        routeTypes: {
          1: 'OW',
          2: 'RT',
        }
      },
      placeholder: {
        depotId: 'set 0 or dep. ID',
        trafficSourceId: 'set 0 or tr. ID',
        configIds: '10,74,125',
        departurePeriods: '5-15,25-30',
        schedule: '10hh,12hh,16hh,20hh',
        routes: 'IEV-LON,BER;LWO-WAW,BCN'
      }
    }
  }
}
