export default {
  name: 'substitution',
  items: [
    {
      name: 'from_rankings',
      endpoint: '/persistance/rankings',
      autoLoad: false,
      paginationType: 'cursor',
      paginationSizes: [10, 25, 30, 50, 1000],
      type: 'graphql',
      graphql: {
        name: 'reportSubstitutionData',
        queries: {
          index: {
            origin: true,
            destination: true,
            supplier: true,
            flightNumber: true,
            variants: true,
            originCountry: true,
            destinationCountry: true,
            otaCount: true
          }
        },
        actions: {
          preload: 'commonRankingsPreload'
        },
        preloadFields: ['markets{id name}']
      },
      preload: true,
      preloadStores: ['markets'],
      table: {
        items: [
          'index',
          {
            name: 'originCountry',
            sortable: false,
            width: '80px'
          },
          {
            name: 'destinationCountry',
            sortable: false,
            width: '80px'
          },
          {
            name: 'origin',
            sortable: false,
            width: '80px'
          },
          {
            name: 'destination',
            sortable: false,
            width: '80px'
          },
          {
            name: 'otaCount',
            sortable: false,
            width: '80px'
          },
          {
            name: 'flight',
            sortable: false
          },
          {
            name: 'variants',
            sortable: false
          }
        ]
      },
      actions: {
        row: [],
        panel: [
          {
            name: 'download',
            icon: 'el-icon-download',
            type: 'success',
            plain: true
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: '',
            items: [
              {
                name: 'market',
                operator: 'eq',
                type: 'select',
                storeKey: 'markets',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                default: 'UA',
                required: true
              },
              {
                name: 'supplier',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'originCountry',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'destinationCountry',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'origin',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'destination',
                operator: 'eq',
                type: 'text'
              // },
              // {
              //   name: 'otaCount',
              //   operator: 'eq',
              //   type: 'slider',
              //   range: true,
              //   max: 15,
              //   min: 1,
              //   default: [null,null]
              }
            ]
          }
        ]
      },
      form: {
        items: []
      }
    }
  ]
}
