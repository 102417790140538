<style scoped>
  .row{ padding-right: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='4' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='70px')
        el-col(:span='5' class='row')
          ttn-input(v-model="temp['index']" v-bind="attr('index')" label-width='70px')
        el-col(:span='9' class='row')
          ttn-input(v-model="temp['key']" v-bind="attr('key')" label-width='70px')
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['daysRange']" v-bind="attr('daysRange')" label-width='120px')

      el-row
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['minAirportConnTime']" v-bind="attr('minAirportConnTime')" label-width='150px')
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['minCityConnTime']" v-bind="attr('minCityConnTime')" label-width='150px')
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['maxConnTime']" v-bind="attr('maxConnTime')" label-width='130px')

      el-row
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['maxPrice']" v-bind="attr('maxPrice')" label-width='120px')
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['priceComponentsCount']" v-bind="attr('priceComponentsCount')" label-width='140px')
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['flightDuration']" v-bind="attr('flightDuration')" label-width='130px')

      ttn-textarea(v-model="temp['departures']" v-bind="attr('departures')")
      ttn-textarea(v-model="temp['arrivals']" v-bind="attr('arrivals')")
      ttn-textarea(v-model="temp['countries']" v-bind="attr('countries')")

</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    methods: {
    }
  }
</script>
