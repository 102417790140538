import { loadCrudModules } from '@/utils'

export default {
  name: 'backend',
  path: '/backend',
  redirect: '/backend/users',
  alwaysShow: true,
  meta: {
    title: 'backend.default',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./backend', true, /\.js$/))
}
