<template>
  <div>
    <component :is="componentPanel" :config="config" :el-item="'CrudCTab'" :el-tab="'CrudItem'" :row="row" />
    <component :is="componentTable" :config="config" :records="records" :el-item="'CrudCTab'" :el-tab="'CrudItem'" :parent-filters="parentFilters" :row="row" />
    <component :is="componentForm" :config="config" :el-item="'CrudCTab'" :el-tab="'CrudItem'" :row="row" />
  </div>
</template>

<script>
import common from './../mixin/common'
import component from './../mixin/component'
import di from './../mixin/di'

export default {
  name: 'CrudCombined',
  mixins: [common, component, di],
  computed: {
    componentTable() {
      return () => this.dynamicComponent('table')
    },
    componentForm() {
      return () => this.dynamicComponent('form')
    },
    componentPanel() {
      return () => this.dynamicComponent('panel')
    }
  },
  methods: {
    dynamicComponent(type) {
      return new Promise((resolve) => {
        try {
          const file = `${[this.config.namespace, this.config.component, this.config.tab, this.config.parentName, this.config.name, type].filter((i) => !!i).join('/')}.vue`
          const module = require(`@/views/crud/${file}`)
          resolve(module)
        } catch (e) {
          resolve(require(`./combined/${type}.vue`))
        }
      })
    },
    async reloadDependentTabs() {
    }
  }
}
</script>
