<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    el-upload(
      v-model="dataValue"
      :multiple="false"
      :limit="1"
      :auto-upload="false"
      :disabled="isDisabledField(field, fieldset.legend)"
      action=""
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
      el-button(
        size="small"
        plain
        type="primary") {{ translate('table.select_file') }}
</template>

<script>

import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnUpload',
  mixins: [formElements],
  data() {
    return {
      tempValue: null
    }
  },
  methods: {
    defaultAttribs(field, fieldset) {
      return {}
    },
    changeFieldValue(field, value) {
      return this.$form.changeFieldValue(field, value)
    }
  }
}
</script>
