import graphqlItem            from './item/graphql'
import tableItem              from './item/table'
import actionsItem            from './item/actions'
import filtersItem            from './item/filters'
import generalFormItem        from './item/form/general'
import fareFormItem           from './item/form/fare'
import penaltyBeforeFormItem  from './item/form/penalty_before'
import penaltyAfterFormItem   from './item/form/penalty_after'
import penaltyNoshowFormItem  from './item/form/penalty_noshow'


export default {
  name: 'fare_rules',
  meta: {
    title: 'fare_rules',
    icon: 'star',
    permission: 'fare_rules'
  },
  lazy: true,
  items: [
    {
      name: 'fare_rules_full',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: graphqlItem,
      table: tableItem,
      actions: actionsItem,
      filter: filtersItem,
      form: {
        actualize: true,
        fieldsets: [
          generalFormItem,
          fareFormItem,
          penaltyBeforeFormItem,
          penaltyAfterFormItem,
          penaltyNoshowFormItem
        ]
      }
    }
  ]
}