import main from './core/main'
import history from './core/history'

export default {
  core: {
    settings: {
      main: main
    },
    history: {
      history: history
    }
  }
}
