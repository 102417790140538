<template lang="pug">
  el-table-column(
    type="selection"
    :selectable="isSelectable"
    v-on="$listeners"
    v-bind="attribs"
  )
    slot(v-for="slot in Object.keys($slots)" :name="slot" :slot="slot")
    template(v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope")
      slot(:name="slot" v-bind="scope")
</template>

<script>

import tableColumns from './../../../mixin/table/columns'

export default {
  name: 'TtnTableColumnSelection',
  mixins: [tableColumns],
  methods: {
    isSelectable(row, index) {
      return this.$table.isSelectable(row, index)
    }
  }
}
</script>
