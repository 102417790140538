<style lang="scss">
.vi-s-sr-pr-line {
  div.cell {
    white-space: pre-line;
  }
}
</style>
<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatCell({ row, $column, column, cellValue, index }) {
      switch (column.name) {
        case 'Index':
          return row['index']
        default:
          return cellValue
      }
    },
    cellClassName({ row, column, rowIndex, columnIndex }) {
      switch (columnIndex) {
        case 7:
          return `vi-s-sr-pr-line`
        default:
          return ''
      }
    },
    formatExpandedRow(row) {
      return `<b>Search countries:</b>  ${row['countries'].join(',')}<br>
              <b>Departure:</b>  ${row['departures'].join(',')}<br>
              <b>Arrival:</b>  ${row['arrivals'].join(',')}`
    }
  }
}
</script>
