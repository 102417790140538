import components from './components'

export default {
  ryanair_worker: {
    table: {
      id: 'ID',
      jobType: 'Job type',
      lastStartTime: 'Last start time',
      jobStatus: 'Job status',
      sessionId: 'Session',
      message: 'Message',
      filePath: 'File path',
      file: 'File?',
      params: 'Params',
      attemptsCount: 'Attempts'
    },
    form: {
      fields: {
        id: 'ID',
        jobType: 'Job type',
        lastStartTime: 'Last start time',
        jobStatus: 'Job status',
        sessionId: 'Session',
        message: 'Message',
        filePath: 'File path',
        params: 'Params'
      },
      options: {
        jobType: {
          1: 'RyanairWorker'
        },
        jobStatus: {
          1: 'Initalized',
          2: 'Working',
          3: 'Waiting next start',
          4: 'Error',
          5: 'Success',
        }
      },
    },
    action: {
      change_settings: 'Change settings'
    },
    messages: {
      download_error: 'File not exist',
      download_empty_content: 'File is empty'
    },
    changeSettingsWindow: components.changeSettingsWindow
  }
}
