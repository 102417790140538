export default {
  card_list: {
    table: {
      active: 'Active',
      binStart: 'Full BIN',
      forLogins: 'User',
      userTeamInfo: 'User Team'
    },
    form: {
      fields: {
        id: 'ID',
        active: 'Active',
        binStart: 'BIN Start',
        binEnd: 'BIN End',
        forLogins: 'User',
        userTeamInfo: 'User Team'
      },
      legends: {
        card: 'Card Data'
      }
    },
    action: {
      total: 'Total by card'
    }
  }
}