<template lang=pug>
  el-dialog(:title="translate('changeSettingsWindow.title')" :visible.sync="dialogFormVisible" width="1024px")
    div(class="txt-w-header")
      b {{ translate('changeSettingsWindow.description') }}
      span(class="txt-pd-10") {{ description() }}
    el-form(:model="form" v-loading="formLoading" v-if="entity")
      el-collapse(v-model="collapseFormParts")
        el-collapse-item(:title="translate(`changeSettingsWindow.form.${entity}.fieldset.markets`)" name="markets")
          div(class="m-l-15")
            el-button(@click="actionAddItem('markets')" type="primary") {{ translate('form.add') }}

            div(v-if="form.settings.markets" v-for="(item, i) in form.settings.markets")
              el-row
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.market.depot_id`)}`" label-width="80px" class="is-required")
                    el-input(v-model="form.settings.markets[i][0]")
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.market.traffic_source_id`)}`" label-width="100px" class="is-required")
                    el-input(v-model="form.settings.markets[i][1]")
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.market.storage_key`)}`" label-width="100px" class="is-required")
                    el-input(v-model="form.settings.markets[i][2]")
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.market.file_prefix`)}`" label-width="100px" class="is-required")
                    el-input(v-model="form.settings.markets[i][3]")
                el-col(:span='1' class='row')
                  span &nbsp
                el-col(:span='1' class='row')
                  el-button(@click="actionRemoveItem('markets', i)" type="danger" :disabled="isDeleteRowDisabled('markets')" class="sbpr-cnc-b") -

        el-collapse-item(:title="translate(`changeSettingsWindow.form.${entity}.fieldset.feed_markets`)" name="feed_markets")
          div(class="m-l-15")
            el-button(@click="actionAddItem('feed_markets')" type="primary") {{ translate('form.add') }}

            div(v-if="form.settings.feed_markets" v-for="(item, i) in form.settings.feed_markets")
              el-row
                el-col(:span='7' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.feed_markets.traffic_source_id`)}`" label-width="100px" class="is-required")
                    el-input(v-model="form.settings.feed_markets[i][0]")
                el-col(:span='7' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.feed_markets.storage_key`)}`" label-width="100px" class="is-required")
                    el-input(v-model="form.settings.feed_markets[i][1]")
                el-col(:span='7' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.feed_markets.active`)}`" label-width="100px")
                    el-switch(v-model="form.settings.feed_markets[i][2]")
                el-col(:span='1' class='row')
                  span &nbsp
                el-col(:span='1' class='row')
                  el-button(@click="actionRemoveItem('feed_markets', i)" type="danger" :disabled="isDeleteRowDisabled('feed_markets')" class="sbpr-cnc-b") -

        el-collapse-item(:title="translate(`changeSettingsWindow.form.${entity}.fieldset.storages`)" name="storages")
          div(class="m-l-15")
            el-button(@click="actionAddItem('storages')" type="primary") {{ translate('form.add') }}

            div(v-if="form.settings.storages" v-for="(item, i) in form.settings.storages")
              el-row
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.storages.key`)}`" label-width="60px" class="is-required")
                    el-input(v-model="form.settings.storages[i].key")
                el-col(:span='4' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.storages.protocol`)}`" label-width="80px" class="is-required")
                    el-input(v-model="form.settings.storages[i].protocol")
                el-col(:span='6' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.storages.host`)}`" label-width="80px")
                    el-input(v-model="form.settings.storages[i].host")
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.storages.port`)}`" label-width="100px" class="is-required")
                    el-input(v-model="form.settings.storages[i].port")
                el-col(:span='1' class='row')
                  span &nbsp
                el-col(:span='1' class='row')
                  el-button(@click="actionRemoveItem('storages', i)" type="danger" :disabled="isDeleteRowDisabled('storages')" class="sbpr-cnc-b") -
              el-row
                el-col(:span='6' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.storages.username`)}`" label-width="80px")
                    el-input(v-model="form.settings.storages[i].username")
                el-col(:span='6' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.storages.password`)}`" label-width="100px" class="is-required")
                    el-input(v-model="form.settings.storages[i].password")
                el-col(:span='6' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.storages.file_prefix`)}`" label-width="100px")
                    el-input(v-model="form.settings.storages[i].file_prefix")
              hr

    span(slot="footer" class="dialog-footer" v-loading="changeLoading")
      el-button(@click="dialogFormVisible = false" type="danger") {{ translate('form.cancel') }}
      el-button(@click="actionPerform" type="primary" :disabled="changeLoading") {{ translate('form.confirm') }}

</template>

<style scoped>
  .txt-w-header{
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 10px;

  }
  .txt-pd-10{
    padding: 0 0 0 10px;
  }
  .mt20 {
    margin: 20px 0 0 0;
  }
  .m-l-15{
    margin: 0 0 0 20px;
  }
</style>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  computed:{
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    },
    defaultValues(){
      return {
        markets: ['', '', '', ''],
        feed_markets: ['', '', false],
        storages: {
          key: '',
          protocol: "sftp",
          host: "partnerupload.google.com",
          port: 19321,
          username: "feeds-",
          password: "",
          file_prefix: ""
        }
      }
    },
    defaultForm(){
      return {
        id: null,
        settings: {
          markets: [[...this.defaultValues['markets']]],
          feed_markets: [[...this.defaultValues['feed_markets']]],
          storages: [{...this.defaultValues['storages']}]
        }
      }
    }
  },
  data: function() {
    return {
      collapseFormParts: ['markets', 'feed_markets', 'storages'],
      dialogFormVisible: false,
      changeLoading: false,
      formLoading: true,
      formLabelWidth: '140px',
      entity: null,
      form: this.defaultForm
    }
  },

  mounted() {
    this.$parent.$on('showChangeSettingsWindow', (entity) => {
      this.entity = entity
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showChangeSettingsWindow')
  },

  methods: {
    reset() {
      this.changeLoading = false
      this.formLoading = true
      this.loadForm()
      this.form = this.defaultForm
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    description(){
      return `${this.entity}`
    },
    actionPerform() {
      if(this.changeLoading){ return }

      if (!this.isValid()) { return }

      this.formatForm()
      this.changeLoading = true

      this.performRequest(this.form)
    },
    async loadForm(){
      try {
        const query = {
          collection:{
            id: true,
            entity: true,
            setting: true,
          },
          __args: {
            limit: 25,
            page: 1,
            filters: {
              featureSettings: [{field: "entity", operator: "eq", value: this.entity, hidden: false}]
            }
          },
        }

        let result = await this.graphql('featureSettings', query, 'collection')

        if(result['collection'] && result['collection'][0]){
          this.form.id = result['collection'][0].id
          this.form.settings = JSON.parse(result['collection'][0].setting)
        }

        this.formLoading = false
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.load_error'),
          type: 'warning',
          duration: 2600
        })
      }
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            id: this.form.id,
            entity: this.entity,
            settingJson: JSON.stringify(this.form.settings)
          },
          id: true
        }

        let result = await this.graphql((this.form.id ? 'updateFeatureSetting' : 'createFeatureSetting'), query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.success_config'),
          type: 'success',
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
    },
    isDeleteRowDisabled(key){
      return (this.form.settings[key].length <= 1)
    },
    actionAddItem(key) {
      console.log(key)
      if(key == 'storages'){
        this.form.settings[key].push({ ...this.defaultValues[key] })
      } else{
        this.form.settings[key].push([ ...this.defaultValues[key] ])
      }
    },
    actionRemoveItem(key, i) {
      this.form.settings[key].splice(i, 1)
    },
    isValid(){
      let errorFields = []
      if(this.entity == 'google_flights'){
        for(let i = 0; i < this.form.settings['markets'].length; i++){
          let hasError = false
          for(let j = 0; j < this.form.settings['markets'][i].length; j++){
            if(!this.form.settings['markets'][i][j] || this.form.settings['markets'][i][j] == ''){
              errorFields.push('markets')
              hasError = true
              break
            }
          }

          if(hasError){ break }
        }

        for (let i = 0; i < this.form.settings['feed_markets'].length; i++) {
          let hasError = false
          for (let j = 0; j < 2; j++) {
            if (!this.form.settings['feed_markets'][i][j] || this.form.settings['feed_markets'][i][j] == '') {
              errorFields.push('feed_markets')
              hasError = true
              break
            }
          }

          if (hasError) { break }
        }

        let storageKeys = ['key', 'protocol', 'host', 'port', 'username', 'password']
        for (let i = 0; i < this.form.settings['storages'].length; i++) {
          let hasError = false
          for (let j = 0; j < storageKeys.length; j++){
            if (!this.form.settings['storages'][i][storageKeys[j]] || this.form.settings['storages'][i][storageKeys[j]] == '') {
              errorFields.push('storages')
              hasError = true
              break
            }
          }

          if (hasError) { break }
        }
      }

      if(errorFields.length == 0){
        return true
      } else {
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: `${this.translate('changeSettingsWindow.msg.for_error_fields')}${errorFields.join(', ')}`,
          type: 'warning',
          duration: 5000
        })

        return false
      }
    },
    formatForm(){
      if(this.entity == 'google_flights'){
        for (let i = 0; i < this.form.settings['markets'].length; i++) {
          this.form.settings['markets'][i][0] = parseInt(this.form.settings['markets'][i][0])
          this.form.settings['markets'][i][1] = parseInt(this.form.settings['markets'][i][1])
        }

        for (let i = 0; i < this.form.settings['feed_markets'].length; i++) {
          this.form.settings['feed_markets'][i][0] = parseInt(this.form.settings['feed_markets'][i][0])
        }

        for (let i = 0; i < this.form.settings['storages'].length; i++) {
          this.form.settings['storages'][i]['port'] = parseInt(this.form.settings['storages'][i]['port'])
        }
      }
    }
  }
}
</script>
