import filters from './fake_pnr/filters'
import gds_list from '@/settings/modules/avia/general/gds_store'

export default {
  namespace: 'avia',
  component: 'fake_pnr',
  meta: {
    title: 'avia.fake_pnr',
    icon: 'table'
  },
  tabs: [
    {
      name: 'fake_pnr',
      items: [
        {
          name: 'fake_rules',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrRules',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                createdAt: true,
                updatedAt: true,
                depots: true,
                status: true,
                gds: true,
                refId: true,
                priority: true
              }
            }
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::RuleRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'status',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'allowed',
                  false: 'blocked'
                }
              },
              {
                name: 'gds',
                type: 'select',
                operator: 'in_array_str',
                store: gds_list.objectByName()
              },
              {
                name: 'depots',
                type: 'text',
                operator: 'in_array_int'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'priority',
                type: 'text',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'depots',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'refId',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'gds',
                type: 'multiselect',
                required: true,
                store: gds_list.arrayByName()
              },
              {
                name: 'status',
                type: 'checkbox',
                default: false,
                required: true
              }
            ]
          }
        },
        {
          name: 'fake_airline_rules',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrAirlineRules',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                createdAt: true,
                updatedAt: true,
                status: true,
                gds: true,
                vc: true,
                hoursBeforeDeparture: true,
                priority: true
              }
            }
          },
          table: {
            items: [
              'selection',
              'id',
              'priority',
              {
                name: 'vc',
                format: 'array_as_string'
              },
              {
                name: 'gds',
                format: 'array_as_string'
              },
              'hoursBeforeDeparture',
              {
                name: 'status',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::AirlineRuleRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'vc',
                type: 'text',
                operator: 'in_array_str'
              },
              {
                name: 'gds',
                type: 'select',
                operator: 'in_array_str',
                store: gds_list.objectByName()
              },
              {
                name: 'status',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'priority',
                type: 'text',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'vc',
                type: 'textarea',
                valueType: 'array_of_string',
                required: false
              },
              {
                name: 'hoursBeforeDeparture',
                type: 'text',
                valueType: 'integer',
                required: false,
                default: 0
              },
              {
                name: 'gds',
                type: 'multiselect',
                store: gds_list.arrayByName()
              },
              {
                name: 'status',
                type: 'checkbox',
                default: false,
                required: true
              }
            ]
          }
        },
        {
          name: 'fake_price_rules',
          endpoint: '/persistance/default',
          lazy: true,
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrPriceRules',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                createdAt: true,
                updatedAt: true,
                depot: true,
                status: true,
                refid: true,
                config: true,
                vc: true,
                markup: true,
                auction: true,
                dynamicPricing: true,
                ancillary: true,
                upsales: true,
                priority: true,
                budgets: {
                  currency: true,
                  amount: true,
                  startDate: true,
                  endDate: true
                },
                discounts: {
                  currency: true,
                  value: true,
                  priceFrom: true,
                  priceTo: true,
                  type: true,
                  startDate: true,
                  endDate: true
                },
                discountLimits: {
                  currency: true,
                  value: true,
                  priceFrom: true,
                  priceTo: true,
                  type: true
                }
              }
            }
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::PriceRuleRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'status',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'allowed',
                  false: 'blocked'
                }
              },
              {
                name: 'depot',
                type: 'text',
                operator: 'in_array_int'
              },
              {
                name: 'refid',
                type: 'text',
                operator: 'in_array_int'
              },
              {
                name: 'config',
                type: 'text',
                operator: 'in_array_int'
              },
              {
                name: 'vc',
                type: 'text',
                operator: 'in_array_str'
              },
              {
                name: 'm_disc_price_from',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'm_disc_price_to',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'm_disc_type',
                type: 'select',
                operator: 'eq',
                store: {
                  1: 'Order',
                  2: 'Ticket'
                }
              },
              {
                name: 'm_disc_date_range',
                operator: 'eq',
                type: 'daterange',
                required: false,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd'
              },
              {
                name: 'm_budget_status',
                type: 'select',
                operator: 'eq',
                store: {
                  itm: 'In The Money',
                  otm: 'Out Of Money'
                }
              },
              {
                name: 'm_budget_date_range',
                operator: 'eq',
                type: 'daterange',
                required: false,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd'
              },
              {
                name: 'max_disc_price_from',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'max_disc_price_to',
                type: 'text',
                operator: 'eq'
              },

              {
                name: 'max_dis_type',
                type: 'select',
                operator: 'eq',
                store: {
                  1: 'Order',
                  2: 'Ticket'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'priority',
                type: 'text',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'depot',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'refid',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'config',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'vc',
                type: 'textarea',
                valueType: 'array_of_string',
                required: false
              },
              {
                name: 'markup',
                type: 'string',
                required: false
              },
              {
                name: 'auction',
                type: 'string',
                required: false
              },
              {
                name: 'dynamicPricing',
                type: 'string',
                required: false
              },
              {
                name: 'ancillary',
                type: 'string',
                required: false
              },
              {
                name: 'upsales',
                type: 'string',
                required: false
              },
              {
                name: 'discounts_json',
                type: 'jsoneditor',
                required: false,
                options: {
                  modes: ['text', 'code', 'tree', 'form', 'view'],
                  mode: 'code',
                  ace: ace
                }
              },
              {
                name: 'budgets_json',
                type: 'jsoneditor',
                required: false,
                options: {
                  modes: ['text', 'code', 'tree', 'form', 'view'],
                  mode: 'code',
                  ace: ace
                }
              },
              {
                name: 'discount_limits_json',
                type: 'jsoneditor',
                required: false,
                options: {
                  modes: ['text', 'code', 'tree', 'form', 'view'],
                  mode: 'code',
                  ace: ace
                }
              },
              {
                name: 'status',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        },
        {
          name: 'fake_upsale_settings',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrUpsaleSettings',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                createdAt: true,
                updatedAt: true,
                gdsId: true,
                supplier: true,
                fareFamilyCheck: true,
                ancillaryCheck: true,
                fallbackRules: true,
                ancillaryAllowedSsr: true
              }
            }
          },
          table: {
            items: [
              'selection',
              'id',
              'supplier',
              {
                name: 'gdsId',
                format: 'from_options',
                format: 'array_as_string'
              },
              'fallbackRules',
              'ancillaryAllowedSsr',
              {
                name: 'fareFamilyCheck',
                type: 'switch'
              },
              {
                name: 'ancillaryCheck',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::UpsaleSettingRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'fare_family_check',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'enabled',
                  false: 'disabled'
                }
              },
              {
                name: 'ancillary_check',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'enabled',
                  false: 'disabled'
                }
              },
              {
                name: 'gds_id',
                type: 'select',
                operator: 'in_array_int',
                store: gds_list.objectById()
              },
              {
                name: 'supplier',
                type: 'text',
                operator: 'like'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'supplier',
                type: 'text',
                required: true
              },
              {
                name: 'gdsId',
                type: 'multiselect',
                store: gds_list.arrayById(),
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'fallbackRules',
                type: 'textarea',
                required: false
              },
              {
                name: 'ancillaryAllowedSsr',
                type: 'textarea',
                required: false,
                placeholder: true
              },
              {
                name: 'fareFamilyCheck',
                type: 'checkbox',
                default: false,
                required: true
              },
              {
                name: 'ancillaryCheck',
                type: 'checkbox',
                default: false,
                required: true
              }
            ]
          }
        },
        {
          name: 'fake_recc_setting',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakeReccSetting',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                vc: true,
                status: true,
                parentId: true,
                createdAt: true,
                updatedAt: true,
                comments: true,
                maxPercent: true,
                maxAmount: true,
                minAmount: true,
                refid: true,
                childRules: {
                  id: true,
                  vc: true,
                  status: true,
                  parentId: true,
                  createdAt: true,
                  updatedAt: true,
                  comments: true,
                  maxPercent: true,
                  maxAmount: true,
                  minAmount: true,
                  refid: true,
                }
              }
            }
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::FakeReccSettingRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                scope: 'aviaFakereccSettingRules',
                name: 'vc',
                type: 'text',
                operator: 'in_array_str'
              },
              {
                scope: 'aviaFakereccSettingRules',
                name: 'refid',
                type: 'text',
                operator: 'in_array_int'
              },
              {
                scope: 'aviaFakereccSettingRules',
                name: 'status',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'On',
                  false: 'Off'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'parentId',
                type: 'hidden',
                required: false,
                default: null
              },
              {
                name: 'vc',
                type: 'text',
                required: false,
                valueType: 'array_of_string'
              },
              {
                name: 'refid',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },

              {
                name: 'maxPercent',
                type: 'text',
                default: 0.00,
                required: false,
                valueType: 'float'
              },
              {
                name: 'maxAmount',
                type: 'text',
                default: 0.00,
                required: false,
                valueType: 'float'
              },
              {
                name: 'minAmount',
                type: 'text',
                default: 0.00,
                required: false,
                valueType: 'float'
              },

              {
                name: 'status',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'comments',
                type: 'textarea',
                required: false
              }

            ]
          }
        },

      ]
    },

    {
      name: 'search_rules',
      items: [
        {
          name: 'fake_search_rules',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrSearchRule',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                configItems: true,
                vc: true,
                flightGeography: true,
                status: true,
                monosearch: true,
                parentId: true,
                createdAt: true,
                updatedAt: true,
                comments: true,
                depots: true,
                bookStatus: true,
                gds: true,
                refid: true,
                childRules: {
                  id: true,
                  configItems: true,
                  vc: true,
                  flightGeography: true,
                  status: true,
                  monosearch: true,
                  parentId: true,
                  createdAt: true,
                  updatedAt: true,
                  comments: true,
                  depots: true,
                  bookStatus: true,
                  gds: true,
                  refid: true
                }
              }
            }
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::ReSearchRulesRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                scope: 'aviaReSearchRules',
                name: 'vc',
                type: 'text',
                operator: 'in_array_str'
              },
              {
                scope: 'aviaReSearchRules',
                name: 'depots',
                type: 'text',
                operator: 'in_array_int'
              },
              {
                scope: 'aviaReSearchRules',
                name: 'config_items',
                type: 'text',
                operator: 'in_array_int'
              },
              {
                scope: 'aviaReSearchRules',
                name: 'flight_geography',
                type: 'text',
                operator: 'like'
              },
              {
                scope: 'aviaReSearchRules',
                name: 'status',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'On',
                  false: 'Off'
                }
              },
              {
                scope: 'aviaReSearchRules',
                name: 'monosearch',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'On',
                  false: 'Off'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'bookStatus',
                type: 'hidden',
                required: false,
                default: 1
              },
              {
                name: 'parentId',
                type: 'hidden',
                required: false,
                default: null
              },
              {
                name: 'vc',
                type: 'text',
                required: false,
                valueType: 'array_of_string'
              },
              {
                name: 'configItems',
                type: 'textarea',
                valueType: 'array_of_integer'
              },
              {
                name: 'depots',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'gds',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'flightGeography',
                type: 'text',
                required: false
              },
              {
                name: 'monosearch',
                type: 'radiogroup',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'refid',
                type: 'textarea',
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'status',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'comments',
                type: 'textarea',
                required: false
              }

            ]
          }
        },
        {
          name: 'fake_search_depot_rules',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrSearchDepotRule',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                configCurrency: true,
                daysBeforeDeparture: true,
                rateMargin: true,
                status: true,
                calculate: true,
                margin: true,
                createdAt: true,
                updatedAt: true,
                blockedVc: true,
                l1: true,
                l2: true,
                l3: true,
                depots: true,
                childRules: {
                  id: true,
                  configCurrency: true,
                  daysBeforeDeparture: true,
                  rateMargin: true,
                  status: true,
                  calculate: true,
                  margin: true,
                  createdAt: true,
                  updatedAt: true,
                  blockedVc: true,
                  l1: true,
                  l2: true,
                  l3: true,
                  depots: true,
                  createdAt: true,
                  updatedAt: true
                }
              }
            }
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::ReSearchDepotRulesRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                scope: 'aviaReSearchDepotRules',
                name: 'blocked_vc',
                type: 'text',
                operator: 'in_array_str'
              },
              {
                scope: 'aviaReSearchDepotRules',
                name: 'depots',
                type: 'text',
                operator: 'in_array_int'
              },
              {
                scope: 'aviaReSearchDepotRules',
                name: 'status',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  true: 'On',
                  false: 'Off'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'parentId',
                type: 'hidden',
                required: false,
                default: null
              },
              {
                name: 'depots',
                type: 'textarea',
                required: false,
                valueType: 'array_of_integer'
              },
              {
                name: 'blockedVc',
                type: 'text',
                valueType: 'array_of_string',
                required: false
              },
              {
                name: 'l1',
                type: 'radiogroup',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'l2',
                type: 'radiogroup',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'l3',
                type: 'radiogroup',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'daysBeforeDeparture',
                type: 'input',
                required: false,
                valueType: 'integer',
                default: 0
              },
              {
                name: 'rateMargin',
                type: 'input',
                required: false
              },
              {
                name: 'margin',
                type: 'input',
                required: false
              },
              {
                name: 'calculate',
                type: 'input',
                required: false,
                default: 'R,RD,Revenue,M'
              },
              {
                name: 'configCurrency',
                type: 'textarea',
                required: false
              },
              {
                name: 'status',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        }
      ]
    },
    {
      name: 'statistic',
      items: [
        {
          name: 'fake_search_stata',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrBookingStatistics',
            exclude: ['actions', 'expand'],
            queries:{
              index: {
                locator: true,
                id: true,
                depot: {
                  id: true,
                  name: true,
                  currency: true
                },
                orderedAt: true,
                status: true,
                trafficSource:{
                  id: true,
                  name: true
                },
                booking: {
                  status: true,
                  startAllPrice: true,
                  finalAllPrice: true,
                  additionalInfo: true
                },
                fakeBooking: {
                  bookingId: true,
                  attrs: true,
                  rule: true,
                  airlineRule: true,
                  priceRules: true,
                  status: true,
                  bookedSearchRule: true,
                  payedSearchRule: true,
                  timeLimit: true,
                  searchStat: true,
                  bookingStatus: true,
                  profit: true,
                  currency: true,
                  budget: true,
                  manualDiscount: true,
                  rebookResults: true,
                  rebookStatus: true,
                  fallbackConfigId: true,
                  priceRulesFallback: true,
                  searchAttempts: true,
                  searchResultsFallback: true
                },
                aviaBookings: {
                  locator: true,
                  supplier: true,
                  priceScheme: true,
                  departureTime: true,
                  aviaBookingSegments: {
                    departureDate: true,
                    departureTime: true,
                    departure: true,
                    arrival: true,
                    arrivalDate: true,
                    arrivalTime: true,
                    supplier: true
                  },
                  aviaBookingPassengers: {
                    type: true,
                    gender: true,
                    firstName: true,
                    lastName: true,
                    birthDate: true
                  }
                }
              }
            }
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [],
            panel: [
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: filters,
          form: {
          }
        }
      ]
    },
    {
      name: 'segment_cost',
      items: [
        {
          name: 'segment_cost_rules',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFakePnrSegmentCost',
            exclude: ['actions', 'expand'],
            queries:{
              index: {
                id: true,
                gdsId: true,
                bsp: true,
                supplier: true,
                saleFrom: true,
                saleTo: true,
                periodFrom: true,
                periodTo: true,
                flightGeography: true,
                fareBasis: true,
                fareBasisType: true,
                rbd: true,
                amount: true,
                currency: true,
                costType: true,
                valueType: true,
                priority: true,
                status: true,
                rbdType: true,
                marketingSupplier: true,
                flightType: true,
                configGroups: true
              }
            }
          },
          table: {
            items: [
              {
                name: 'id',
                fixed: 'left'
              },
              {
                name: 'priority',
                fixed: 'left'
              },
              {
                name: 'amount',
                fixed: 'left'
              },
              {
                name: 'currency',
                fixed: 'left'
              },
              {
                name: 'gdsId',
                format: 'from_options',
                format: 'array_as_string',
                fixed: 'left'
              },
              {
                name: 'bsp',
                format: 'array_as_string'
              },
              'configGroups',
              'supplier',
              'marketingSupplier',
              {
                name: 'flightType',
                format: 'from_options'
              },
              'flightGeography',
              'fareBasis',
              {
                name: 'fareBasisType',
                format: 'from_options'
              },
              'rbd',
              {
                name: 'rbdType',
                format: 'from_options'
              },
              {
                name: 'costType',
                format: 'from_options'
              },
              {
                name: 'valueType',
                format: 'from_options'
              },
              {
                name: 'saleFrom',
                format: 'date_time'
              },
              {
                name: 'saleTo',
                format: 'date_time'
              },
              {
                name: 'periodFrom',
                format: 'date_time'
              },
              {
                name: 'periodTo',
                format: 'date_time'
              },
              {
                name: 'status',
                type: 'switch',
                width: '70px'
              },
              {
                name: 'actions',
                width: '100px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FakePnr::SegmentCostRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            collapsed: true,
            items: [
              {
                name: 'status',
                type: 'radiogroup',
                scope: 'aviaSegmentCost',
                button: true,
                operator: 'eq',
                store: {
                  true: 'allowed',
                  false: 'blocked'
                }
              },
              {
                name: 'gds_id',
                type: 'select',
                scope: 'aviaSegmentCost',
                operator: 'in_array_int',
                store: gds_list.objectById()
              },
              {
                name: 'flight_geography',
                scope: 'aviaSegmentCost',
                type: 'text',
                operator: 'like'
              },
              // {
              //   name: 'flight_geography',
              //   scope: 'aviaSegmentCost',
              //   type: 'text',
              //   operator: 'like'
              // },
              {
                name: 'supplier',
                scope: 'aviaSegmentCost',
                type: 'text',
                operator: 'like'
              },
              {
                name: 'marketing_supplier',
                scope: 'aviaSegmentCost',
                type: 'text',
                operator: 'like'
              },
              {
                name: 'flight_type',
                type: 'select',
                button: true,
                operator: 'eq',
                scope: 'aviaSegmentCost',
                store: {
                  0: 'Любой',
                  1: 'Прямой',
                  2: 'С пересадками'
                }
              },
              {
                name: 'cost_type',
                type: 'select',
                button: true,
                operator: 'eq',
                scope: 'aviaSegmentCost',
                store: {
                  0: 'На Пассажира',
                  1: 'На Сегмент'
                }
              },
              {
                name: 'value_type',
                type: 'select',
                button: true,
                operator: 'eq',
                scope: 'aviaSegmentCost',
                store: {
                  0: 'Инсентив ГДС',
                  1: 'Перебронирование'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'priority',
                type: 'text',
                required: false,
                valueType: 'integer',
                default: 0
              },
              {
                name: 'bsp',
                type: 'textarea',
                valueType: 'array_of_string',
                required: false
              },
              {
                name: 'supplier',
                type: 'text',
                required: false
              },
              {
                name: 'marketingSupplier',
                type: 'text',
                required: false
              },
              {
                name: 'configGroups',
                type: 'text',
                required: false
              },
              {
                name: 'flightType',
                type: 'select',
                operator: 'eq',
                store: {
                  0: 'Any',
                  1: 'Прямой',
                  2: 'С пересадками'
                },
                valueType: 'integer'
              },
              {
                name: 'gdsId',
                type: 'multiselect',
                store: gds_list.arrayById(),
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'flightGeography',
                type: 'text',
                required: false
              },
              {
                name: 'saleFrom',
                type: 'datetime',
                valueType: 'datetime',
                required: false
              },
              {
                name: 'saleTo',
                type: 'datetime',
                required: false,
                valueType: 'datetime'
              },
              {
                name: 'periodFrom',
                type: 'datetime',
                required: false,
                valueType: 'datetime'
              },
              {
                name: 'periodTo',
                type: 'datetime',
                required: false,
                valueType: 'datetime'
              },
              {
                name: 'fareBasis',
                type: 'text',
                required: false
              },
              {
                name: 'fareBasisType',
                type: 'select',
                required: false,
                storeLabelKeys: ['name'],
                store: [{
                  id: 0,
                  name: 'Все'
                },
                {
                  id: 1,
                  name: 'Один из'
                }],
                default: 0
              },
              {
                name: 'rbd',
                type: 'text',
                required: false
              },
              {
                name: 'rbdType',
                type: 'select',
                required: false,
                storeLabelKeys: ['name'],
                store: [{
                  id: 0,
                  name: 'Все'
                },
                {
                  id: 1,
                  name: 'Один из'
                }],
                default: 0
              },
              {
                name: 'amount',
                type: 'text',
                default: 0.00,
                required: true,
                valueType: 'float'
              },
              {
                name: 'currency',
                type: 'text',
                default: '',
                required: true
              },
              {
                name: 'costType',
                type: 'select',
                storeLabelKeys: ['name'],
                store: [{
                  id: 0,
                  name: 'На Пассажира'
                },
                {
                  id: 1,
                  name: 'На Сегмент'
                }]
              },
              {
                name: 'valueType',
                type: 'select',
                store: [{
                  id: 0,
                  name: 'Инсентив ГДС'
                },
                {
                  id: 1,
                  name: 'Перебронирование'
                }],
                storeLabelKeys: ['name']
              },
              {
                name: 'status',
                type: 'switch',
                default: true
              }
            ]
          }
        }
      ]
    }

  ]
}
