export default {
  global: {
    table:{
      time: 'Time',
      userLogin: 'User',
      operation: 'Operation',
      recordClass: 'DB relation',
      recordId: 'Relation ID',
      changes: 'Changes'
    },
    form: {
      fields: {
        time: 'Time',
        userLogin: 'User',
        operation: 'Operation',
        recordClass: 'DB relation',
        recordId: 'Relation ID',
      }
    }
  }
}
