export default {
  name: 'conversion_analysis',
  alwaysShow: true,
  items: [
    {
      name: 'conversion',
      endpoint: '/persistance/default',
      pagination: false,
      type: 'graphql',
      graphql: {
        name: 'analysisConversion',
        queries: {
          index: {
            key: true,
            searches: true,
            clicksCount: true,
            cts: true,
            createdBookings: true,
            paidBookings: true,
            issuedBookings: true,
            conversBookings: true,
            totalIssuedBookings: true,
            btr: true,
            ctr: true,
            demoCtr: true,
            paidToCreated: true,
            issuedToCreated: true,
            items: true,
          }
        }
      },
      combined: [
        {
          name: 'items',
          boot: {
            type: 'local',
            row_key: 'items'
          },
          combined: [
            {
              name: 'first_level_items',
              boot: {
                type: 'local',
                row_key: 'first_level_items'
              },
              combined: [
                {
                  key: true,
                  name: 'second_level_items',
                  boot: {
                    type: 'local',
                    row_key: 'second_level_items'
                  },
                  combined: [
                    {
                      name: 'third_level_items',
                      boot: {
                        type: 'local',
                        row_key: 'third_level_items'
                      },
                      combined: [
                        {
                          name: 'locators',
                          boot: {
                            type: 'local',
                            row_key: 'locators'
                          },
                          table: {
                            hideHeader: true,
                            items: [
                              {
                                name: 'index',
                                type: 'index',
                                width: '40',
                              },
                              'locator'
                            ]
                          },
                        },
                      ],
                      table: {
                        sortType: 'local',
                        items: [
                          {
                            name: 'expand',
                            type: 'expand',
                            width: '25',
                          },
                          {
                            name: 'key',
                            width: '100',
                          },
                          'clicks_count',
                          'created_bookings',
                          'paid_bookings',
                          'issued_bookings',
                          'convers_bookings',
                          'total_issued_bookings',
                          {
                              name: 'btr',
                              width: '70',
                          },
                          {
                              name: 'ctr',
                              width: '80',
                          },
                          'demo_ctr',
                          'paid_to_created',
                          'issued_to_created',
                        ]
                      },
                    },
                  ],
                  table: {
                    sortType: 'local',
                    items: [
                      {
                        name: 'expand',
                        type: 'expand',
                        width: '25',
                      },
                      {
                        name: 'key',
                        width: '100',
                      },
                      'clicks_count',
                      'created_bookings',
                      'paid_bookings',
                      'issued_bookings',
                      'convers_bookings',
                      'total_issued_bookings',
                      {
                          name: 'btr',
                          width: '70',
                      },
                      {
                          name: 'ctr',
                          width: '80',
                      },
                      'demo_ctr',
                      'paid_to_created',
                      'issued_to_created',
                    ]
                  },
                },
              ],
              table: {
                sortType: 'local',
                items: [
                  {
                    name: 'expand',
                    type: 'expand',
                    width: '25',
                  },
                  {
                    name: 'key',
                    width: '100',
                  },
                  'searches',
                  'clicks_count',
                  {
                      name: 'cts',
                      width: '80',
                  },
                  'created_bookings',
                  'paid_bookings',
                  'issued_bookings',
                  'convers_bookings',
                  'total_issued_bookings',
                  {
                      name: 'btr',
                      width: '70',
                  },
                  {
                      name: 'ctr',
                      width: '80',
                  },
                  'demo_ctr',
                  'paid_to_created',
                  'issued_to_created',
                ]
              },
            },
          ],
          table: {
            sortType: 'local',
            items: [
              {
                name: 'expand',
                type: 'expand',
                width: '25',
              },
              {
                name: 'key',
                width: '100',
              },
              'searches',
              'clicks_count',
              {
                  name: 'cts',
                  width: '80',
              },
              'created_bookings',
              'paid_bookings',
              'issued_bookings',
              'convers_bookings',
              'total_issued_bookings',
              {
                  name: 'btr',
                  width: '70',
              },
              {
                  name: 'ctr',
                  width: '80',
              },
              'demo_ctr',
              'paid_to_created',
              'issued_to_created',
            ]
          },
        },
      ],
      table: {
        items: [
          {
              name: 'expand',
              type: 'expand',
              width: '30',
          },
          {
              name: 'key',
              sortable: false,
          },
          {
              name: 'searches',
              sortable: false,
          },
          {
              name: 'clicksCount',
              sortable: false,
          },
          {
              name: 'cts',
              sortable: false,
              width: '70',
          },
          {
              name: 'createdBookings',
              sortable: false,
          },
          {
              name: 'paidBookings',
              sortable: false,
          },
          {
              name: 'issuedBookings',
              sortable: false,
          },
          {
              name: 'conversBookings',
              sortable: false,
          },
          {
              name: 'totalIssuedBookings',
              sortable: false,
          },
          {
              name: 'btr',
              sortable: false,
              width: '70',
          },
          {
              name: 'ctr',
              sortable: false,
              width: '70',
          },
          {
              name: 'demoCtr',
              sortable: false,
          },
          {
              name: 'paidToCreated',
              sortable: false,
          },
          {
              name: 'issuedToCreated',
              sortable: false,
          },
        ]
      },
      actions: {
        panel: [
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Analysis::ConversionSearchHistory'
            }
          },
          {
            name: 'download',
            icon: 'el-icon-download',
            type: 'primary',
            plain: true
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
            items: [
              {
                name: 'refid',
                type: 'multiselect',
                storeKey: 'traffic_sources',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                operator: 'eq'
              },
              {
                name: 'depots',
                type: 'multiselect',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                operator: 'in_array_int'
              },
              {
                name: 'direct',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                default: '0',
                store: {
                  '1': 'direct by depot',
                  '0': 'by refid'
                },
              },
              {
                name: 'group_options',
                type: 'multiselect',
                store: {
                  date: 'date',
                  validating_supplier: 'supplier',
                  avia_search_config_id: 'config_id',
                  route: 'route',
                },
                operator: 'eq',
                default: ['date']
              },
              {
                name: 'dates',
                operator: 'eq',
                type: 'daterange',
                required: true,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: ['last week', 'now'],
              },
              {
                name: 'supplier',
                camelizeLangKey: true,
                type: 'text',
                required: false,
                operator: 'in_array_str',
              },
              {
                name: 'config_id',
                type: 'multiselect',
                storeKey: 'config_items',
                storeLabelKeys: ['id', 'name'],
                operator: 'in_array_str',
                lazy: true
              },
              {
                name: 'route',
                type: 'text',
                required: false,
                operator: 'in_array_str'
              },
            ]
      }
    }
  ]
}
