import paramsHistory from './params.js'

export default {
  data() {
    return {
      pagination: {
        page: 1,
        limit: 25
      },
      filter: {},
      loading: false,
      pickerOptions: {
        firstDayOfWeek: 1
      },
      historyOperations: ['create', 'update', 'delete']
    }
  },

  inject: ['$elHistory'],
  mixins: [paramsHistory],

  mounted() {
    this.$elHistory.$on(this.$channels.historyTabChange, this.eventTabChange)
  },

  beforeDestroy() {
    this.$elHistory.$off(this.$channels.historyTabChange, this.eventTabChange)
  },

  computed: {
    records() {
      return this.$store.getters[`${this.historyStoreName}/historyList`]
    },
    total() {
      return this.$store.getters[`${this.historyStoreName}/historyTotal`]
    },
    historyFields() {
      return this.config.graphql.historyFields
    },
    historyStoreName() {
      return [this.record.namespace, this.record.component, this.record.tab, this.record.name].filter((i) => !!i).join('/')
    }
  },

  methods: {
    eventTabChange(tab) {
      if (this.record.record_class === tab && this.records.length === 0) {
        this.eventFilterApply()
      }
    },

    eventFilterApply() {
      this.pagination = {
        page: 1,
        limit: 25
      }
      this.list()
    },

    eventFilterReset() {
      this.filter = {}
      this.eventFilterApply()
    },

    eventPageChange(val) {
      this.pagination.page = val
      this.list()
    },

    eventSizeChange(val) {
      this.pagination.limit = val
      this.pagination.page = 1
      this.list()
    },

    async list() {
      const params = Object.assign({}, { record_class: this.record.record_class }, this.filter)
      try {
        this.loading = true
        await this.$store.dispatch(`${this.historyStoreName}/history`, this.buildParams('history', params))
        this.$nextTick(() => {
          this.loading = false
        })
      } catch (err) {
        this.loading = false
        this.$message({
          message: err,
          type: 'error'
        })
      }
    },

    formatTime(value) {
      return this.$moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
