export default {
  table: {
    active: 'Active',
    priority: 'Priority',
    gdsId: 'GDS Name',
    fareCode: 'Fare Code(-s)',
    marketingSupplier: 'Supplier',
    geography: 'Geography',
    flightNumber: 'Flight(-s)',
    baggageDetails: 'Types',
    actions: 'Actions'
  },
  form: {
    fields: {
      id: 'ID',
      active: 'Active',
      priority: 'Priority',
      gdsId: 'GDS Name',
      fareCode: 'Fare Code(-s)',
      marketingSupplier: 'Marketing Supplier',
      geography: 'Geography',
      flightNumber: 'Flight Number(-s)',
      override: 'Override GDS information',
      info__count: 'Count',
      info__weight: 'Weight',
      info__weightUnit: 'Unit',
      info__width: 'Width',
      info__height: 'Height',
      info__length: 'Length',
      info__dimensionSum: 'Sum',
      info__dimensionUnit: 'Unit'
    },
    buttons: {
      copy_adt: 'Copy fields from ADT',
      copy_chd: 'Copy fields from CHD',
      clear: 'Clear fields'
    },
    legends: {
      general_data: 'General Data',
      flight_data: 'Flight Data',
      baggage_data: 'Details Block',
      baggage: 'Baggage Info',
      carry_on: 'Сarry On Info',
      personal_item: 'Personal Item Info'
    },
    placeholder: {
      priority: '0 is lowest priority',
      flightNumber: '6000,7000-7100,7500,8000-8999',
      fareCode: 'FARECODE   |   FAR*   |   *ECO*   |   *ODE   |   FAR*,*ODE,*ECO*,FARECODE   |   -(empty fare_code from GDS)',
      marketingSupplier: 'BT   |   W6   |   J2   |   FIA',
      geography: 'FR-GB   |   PAR-LON   |   CDG-SAW   |   FR-LON   |   FR-LHR   |   FR-*   |   PAR-*   |   *-LHR   |   *-LHR,PAR-*,DE-IT',
      info__count: '1',
      info__weight: '23',
      info__width: '52',
      info__height: '76',
      info__length: '30',
      info__dimensionSum: '158'
    },
    validations: {
      default: 'This field allow only digits',
      integer: 'This field allow only 1-3 digits',
      count: 'This field allow only 1 digit',
      unit: 'This field is required',
      sum: 'width + height + length != sum',
      gdsId: 'Please select at least one item',
      marketingSupplier: 'This field allow only 2-3 letters/digits',
      flightNumber: 'Not valid flights list/ranges',
      fareCode: 'Not valid fare codes list',
      geography: 'Not valid geography format',
      notZero: 'This field can\'t be 0',
      notInteger: 'Not valid integer value',
      use: 'There must be at least one active block',
      allGds: 'Selected combination with `all` value is not allowed'
    }
  }
}