import graphqlTemplate  from './template/graphql'
import tableTemplate    from './template/table'
import actionsTemplate  from './template/actions'
import filtersTemplate  from './template/filters'
import ruleForm         from './template/form'

export default {
  name: 'fare_rule_templates',
  meta: {
    title: 'fare_rule_templates',
    icon: 'star',
    permission: 'fare_rules'
  },
  lazy: true,
  items: [
    {
      name: 'fare_rule_templates_full',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: graphqlTemplate,
      table: tableTemplate,
      actions: actionsTemplate,
      filter: filtersTemplate,
      form: ruleForm
    }
  ]
}