<template lang="pug">
  div(:class="className" :style="{ height: height, width: width }")
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons')

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '600px'
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')

      this.chart.setOption({
        title: {
          left: 'center',
          text: this.title
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          left: 'right'
        },
        series: [{
          type: 'pie',
          radius: ['40%', '50%'],
          data: this.data,
          animationDuration: 1000,
          label: {
            // show: false,
            formatter: '{c} ({d}%)\n{b}'
          },
          // labelLine: {
          //   show: false
          // },
        }]
      })
    }
  },
  watch: {
    data(val) {
      this.initChart()
    }
  }
}
</script>
