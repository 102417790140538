<template>
  <el-dialog :visible.sync="visible" :fullscreen="true">
    <el-button :loading="downloadLoading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
      Export
    </el-button>
    <chart-position v-for="(data, key) in charts" :key="key" :data="data" :type="'markup'" />
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('crud.form.cancel') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ChartPosition from './details_chart'
export default {
  components: { ChartPosition },
  data() {
    return {
      visible: false,
      details: [],
      charts: [],
      downloadLoading: false
    }
  },

  mounted() {
    this.$parent.$on('showInfo', (details) => {
      this.details = details
      this.visible = true
      this.charts = []
      this.loadData()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showInfo')
  },

  methods: {
    loadData() {
      const data = { markup: {}, discount: {}}
      const priceData = { markup: {}, discount: {}}
      for (let i = 0; i < this.details.length; i++) {
        let markup = Math.ceil(this.details[i].my_markup / 10) * 10
        let discount = Math.ceil(this.details[i].my_discount / 10) * 10
        let price = Math.ceil(this.details[i].my_price / 10) * 10
        if (!data.markup[markup]) {
          data.markup[markup] = 0
        }
        data.markup[markup] += 1
        if (!data.discount[discount]) {
          data.discount[discount] = 0
        }
        data.discount[discount] += 1

        if (!priceData.discount[price]) {
          priceData.discount[price] = this.details[i].my_discount
        }
        if (!priceData.markup[price]) {
          priceData.markup[price] = this.details[i].my_markup
        }
      }
      const rangeChart = {
        legends: [],
        series: {},
        x_lines: {},
        xaxis: this.$t('travel_rankings.report_position.charts.range_xaxis')
      }
      const priceChart = {
        legends: [],
        series: {},
        x_lines: {},
        xaxis: this.$t('travel_rankings.report_position.charts.price_xaxis')
      }
      rangeChart.legends.push('Count')
      priceChart.legends.push('Value')
      for(let t in data) {
        rangeChart.series[t] = {
          name: 'Count',
          data: []
        }
        rangeChart.x_lines[t] = []
        for(let k in data[t]) {
          rangeChart.series[t].data.push(data[t][k])
          rangeChart.x_lines[t].push(k)
        }
      }

      for(let t in priceData) {
        priceChart.series[t] = {
          name: 'Value',
          data: []
        }
        priceChart.x_lines[t] = []
        for(let k in priceData[t]) {
          priceChart.series[t].data.push(priceData[t][k])
          priceChart.x_lines[t].push(k)
        }
      }
      rangeChart.series = [rangeChart.series]
      priceChart.series = [priceChart.series]
      this.charts.push(rangeChart)
      this.charts.push(priceChart)
    },

    handleDownload() {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['my_rank', 'my_price', 'my_discount', 'my_markup', 'total_po', 'prices', 'segments', 'leg_dates', 'search_date']
        const filterVal = ['my_rank', 'my_price', 'my_discount', 'my_markup', 'total_po', 'prices', 'segments', 'leg_dates', 'search_date']
        const data = this.formatJson(filterVal)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'details-list'
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal) {
      return this.details.map(v => filterVal.map(j => {
        switch(j) {
          case 'prices':
            return v[j].map((i) => {
              return `${i.rank} ${i.provider}, ${i.price}`
            }).join('\n')  
          case 'segments':
            return v['legs'].map((l) => {
              return l.segments.map((s) => {
                return `${s.supplier}${s.number}, ${s.origin}-${s.destination}`
              })
            }).flat().join('\n')
          case 'leg_dates':
            let s = [v.first_leg_date]
            if (v.first_leg_date !== v.last_leg_date) {
              s.push(v.last_leg_date)
            }
            return s.join('-')
          default:
            return v[j]
        }
      }))
    }
  }
}
</script>

<style>
  .no_wrap {
    white-space: nowrap;
  }
</style>
