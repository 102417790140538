export default {
  rail_prices_list: {
    table: {
      id : 'ID',
      ruleIndex: 'Index',
      name: 'Name',
      description: 'Description',
      depotType: 'Depot Type',
      enabled: 'Enabled',
      default: 'Default',
      startDate: 'Start Date',
      endDate: 'End Date',
      trafficSourceId: 'Traffic Source ID',
      upsale: 'Upsale',
      depotId: 'Depot ID',
      createdAt: 'Created At',
      updatedAt: 'Updated At'
    },
    action: {
      clear_cache: 'Clear Cache'
    },
    form: {
      fields: {
        id: 'ID',
        index: 'Index',
        name: 'Name',
        description: 'Description',
        depotType: 'Depot Type',
        enabled: 'Enabled',
        default: 'Default',
        startDate: 'Start Date',
        endDate: 'End Date',
        trafficSourceId: 'Traffic Source ID',
        upsale: 'Upsale',
        depotId: 'Depot ID'
      },
      placeholder: {
        trafficSourceId: 'example: 0 for All or ID'
      }
    }
  },
  railway_rail_price_rules: {
    table: {
      providerName: 'Provider Name',
      serviceCost: 'Service Cost',
      bookingCost: 'Booking Cost',
      acquiringCost: 'Acquiring Cost',
      roundPrice: 'Round Price',
      refundCost: 'Refund Cost',
      extraServiceCost: 'Extra Service Cost'
    },
    action: {
      clear_cache: 'Clear Cache'
    },
    form: {
      fields: {
        providerName: 'Provider Name',
        serviceCost: 'Service Cost',
        bookingCost: 'Booking Cost',
        acquiringCost: 'Acquiring Cost',
        roundPrice: 'Round Price',
        refundCost: 'Refund Cost',
        extraServiceCost: 'Extra Service Cost'
      },
      placeholder: {
        providerName: 'example: gioc_tickets',
        serviceCost: 'example: 5.10',
        bookingCost: 'example: 10.20',
        acquiringCost: 'example: 0.017',
        refundCost: 'example: 2',
        extraServiceCost: 'example: 0.50'
      }
    }
  },
  railway_rail_price_rule_items: {
    table: {
      startPrice: 'From Price',
      endPrice: 'To Price',
      serviceCost: 'Service Cost',
      carType: 'Car Type',
      carClass: 'Car Class',
      departureTimeStart: 'Departure Time From',
      departureTimeEnd: 'Departure Time To',
      travelTimeStart: 'Travel Time From',
      travelTimeEnd: 'Travel Time To',
      trainNumber: 'Train Number',
      startDate: 'Start Date',
      endDate: 'End Date'
    },
    action: {
      clear_cache: 'Clear Cache'
    },
    form: {
      fields: {
        startPrice: 'From Price',
        endPrice: 'To Price',
        serviceCost: 'Service Cost',
        carType: 'Car Type',
        carClass: 'Car Class',
        departureTimeStart: 'Departure Time From',
        departureTimeEnd: 'Departure Time To',
        travelTimeStart: 'Travel Time From',
        travelTimeEnd: 'Travel Time To',
        trainNumber: 'Train Number',
        startDate: 'Start Date',
        endDate: 'End Date'
        
      },
      options: {
        carType: {
          0: 'all',
          1: 'luxury',
          2: 'coupe',
          3: 'reserved',
          4: 'sitting',
          5: 'common',
          6: 'soft'
        }
      },
      placeholder: {
        startPrice: 'example: 0',
        endPrice: 'example: 400',
        serviceCost: 'example: 5 or 2%',
        carType: 'example: К',
        carClass: 'example: 1',
        departureTimeStart: 'example: 01.10.2022 or 09:00 or 01.10.2022 09:10',
        departureTimeEnd: 'example: 10.10.2022 or 22:10 or 10.10.2022 22:10',
        travelTimeStart: 'example: 120',
        travelTimeEnd: 'example: 300',
        trainNumber: 'example: 92',
        startDate: 'example: 2022-10-10 00:00:00',
        endDate: 'example: 2022-10-20 23:59:59'
      }
    }
  }

}
