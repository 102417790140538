import markupAnalysis from './system_tools/avia/markup_analysis'
import thoughtsOfSales from './system_tools/avia/thoughts_of_sales'
import conversionAnalysis from './system_tools/avia/conversion_analysis'
import iataParser from './system_tools/avia_parser/iata_parser'

export default {
  system_tools: {
    avia: {
      thoughts_of_sales: thoughtsOfSales,
      markup_analysis: markupAnalysis,
      conversion_analysis: conversionAnalysis
    },
    avia_parsers: {
      iata_parser: iataParser
    }
  }
}
