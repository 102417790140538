import { loadCrudModules } from '@/utils'

export default {
  name: 'web_scraper',
  path: '/web_scraper',
  redirect: '/web_scraper/configuration',
  alwaysShow: true,
  layout: () => import('@/views/crud/web_scraper/layout'),
  meta: {
    title: 'web_scraper.default',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./web_scraper', false, /\.js$/))
}
