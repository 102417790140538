import items        from './fare_rules/items'
import defaultItems from './fare_rules/default_items'
import templates    from './fare_rules/templates'

export default {
  namespace: 'avia',
  component: 'fare_rules',
  meta: {
    title: 'avia.fare_rules',
    icon: 'star',
    roles: ['fare_rules']
  },
  tabs: [
    items,
    defaultItems,
    templates
  ]
}
