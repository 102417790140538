<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    actionDownload(row) {
      if(row['handlerStatus'] != 1) {
        this.$message({
          message: this.translate('table.messages.download_error'),
          type: 'error'
        })
      } else{
        this.startDownload(row)
      }
    },
    async startDownload(row) {
      try {
        const query = {
          content: true,
          __args: {
            key: row['filePath']
          }
        }

        let result = await this.graphql('aviaReportFileContent', query, 'collection')

        if(result.content) {
          const blob = new Blob([result.content], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = row['filePath']
          link.click()
          URL.revokeObjectURL(link.href)
        } else{
          this.$message({
            message: this.translate('table.messages.download_empty_content'),
            type: 'error'
          })
        }

        return true
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 5:
          switch(row['handlerStatus']) {
            case 0:
              return { 'background-color': '#ced0dc6b' }
            case 1:
              return { 'background-color': '#2cf3606b' }
            case 2:
              return { 'background-color': '#f9d93a7a' }
            case 3:
              return { 'background-color': '#f93a3a7a' }
          }
      }

      return {}
    },
    formatExpandedRow(row) {
      return `<b>Params:</b>  ${ JSON.stringify(row.reportParams, null, 2) }
      `
    },
    isSelectedItems(checkFunction) {
      let error = false
      if (this.multipleSelection.length == 0) {
        error = 'messages.selection.no_item_selected'
      } else if (!this.multipleSelection.some((row) => { return checkFunction(row) })) {
        error = 'messages.selection.no_active_items'
      }

      if (error) {
        this.$notify({
          title: this.translate('messages.selection.title'),
          message: this.translate(error),
          type: 'warning',
          duration: 2600
        })
        return false
      } else {
        return true
      }
    },
    actionRegenerate(){
      if (this.isSelectedItems((row) => { return true })) {
        this.performRequest('CacheHandler::Avia::Report::GenerateHandler', true)
      }
    },
    async performRequest(entity, seperateRequests) {
      let ids = this.multipleSelection.map((i) => { return parseInt(i.id) })
      if (!seperateRequests) { ids = [ids.join(',')] }
      let results = {success: [], errors: []}

      for await (const id of ids) {
        try {
          const query = {
            __args: {
              entity: entity,
              type: 'handler',
              params: { id: id }
            }
          }

          await this.$elItem.graphql('regenerateCache', query, 'mutation')

          this.dialogFormVisible = false
          results['success'].push(id)
        } catch (e) {
          console.error(e)
          results['errors'].push(id)
        }
      }

      if (results['success'].length > 0){
        this.$notify({
          title: this.translate('messages.performRequest.title'),
          message: `${this.translate('messages.performRequest.success')} ${results['success'].join(',')}`,
          duration: 5000
        })
      }

      if (results['errors'].length > 0) {
        this.$notify({
          title: this.translate('messages.performRequest.title'),
          message: `${this.translate('messages.performRequest.error')} ${results['errors'].join(',')}`,
          type: 'warning',
          duration: 5000
        })
      }
    },
  }
}
</script>
