<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    el-slider(
      v-model="dataValue"
      :disabled="isDisabledField(field, fieldset.legend)"
      @change="changeFieldValue(field, $event)"
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
</template>

<script>

import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnSlider',
  mixins: [formElements],
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        range: field.range || false,
        'show-stops': field.showStops || false,
        max: field.max,
        style: 'width: 95%; padding-left: 10px'
      }
    }
  }
}
</script>
