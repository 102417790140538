export default {
  name: 'parsing',
  items: [
    {
      name: 'site_projects',
      endpoint: '/persistance/webscraper',
      type: 'graphql',
      lazy: true,
      graphql: {
        name: 'siteProjects',
        queries: {
          index: {
            id: true,
            name: true,
            projectToken: true,
            startValueFormat: true,
            startValue: true,
            active: true,
            transformer: true,
            airlines: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '80px'
          },
          'name',
          'projectToken',
          'transformer',
          {
            name: 'airlines',
            format: 'array_as_string'
          },
          {
            name: 'active',
            type: 'switch'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '130px',
            align: 'center'
          },
          {
            name: 'expand',
            type: 'expand'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          },
          {
            name: 'groups_list',
            icon: 'el-icon-s-operation',
            type: 'success',
            plain: true,
            roles: false
          },
          {
            name: 'run_template',
            icon: 'el-icon-caret-right',
            type: 'danger',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'name',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'project_token',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                }
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'name',
                type: 'text'
              },
              {
                name: 'projectToken',
                type: 'text'
              },
              {
                name: 'airlines',
                type: 'text',
                valueType: 'array_of_string',
                valueDelimiter: ','
              },
              {
                name: 'transformer',
                type: 'select',
                required: false,
                store: {
                  TruncatedSegment: 'TruncatedSegment'
                }
              },
              {
                name: 'active',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'startValueFormat',
                type: 'jsoneditor',
                options: {
                  mode: 'code',
                  ace: ace
                }
              }
            ]
          }
        ]
      }
    },
    {
      name: 'site_run_groups',
      endpoint: '/persistance/webscraper',
      type: 'graphql',
      lazy: true,
      graphql: {
        name: 'siteRunGroups',
        queries: {
          index: {
            id: true,
            name: true,
            revision: true,
            apiAgentId: true,
            amount: true,
            currency: true,
            prices: true,
            extraData: true,
            status: true,
            updatedAt: true
          }
        },
        preloadFields: ['agents{id name}']
      },
      preload: true,
      preloadStores: [
        {
          name: 'agents',
          storePrimaryKey: 'id'
        }
      ],
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '80px'
          },
          'name',
          'amount',
          {
            name: 'apiAgentId',
            format: 'from_instance',
            storeKey: 'agents',
            storeLabelKey: 'name'
          },
          'status',
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '80px',
            align: 'center'
          },
          {
            name: 'expand',
            type: 'expand'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'run_list',
            icon: 'el-icon-s-operation',
            type: 'success',
            plain: true,
            roles: false
          },
          {
            name: 'view_details',
            icon: 'el-icon-caret-right',
            type: 'success',
            plain: true
          }
        ],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'api_agent_id',
                type: 'select',
                operator: 'eq',
                storeKey: 'agents',
                storePrimaryKey: 'id',
                storeLabelKey: 'name'
              },
              {
                name: 'id',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'name',
                operator: 'like',
                type: 'text'
              }
            ]
          }
        ]
      },
      form: {
        actualize: true
      }
    },
    {
      name: 'site_runs',
      endpoint: '/persistance/webscraper',
      lazy: true,
      type: 'graphql',
      graphql: {
        name: 'siteProjectRuns',
        queries: {
          index: {
            id: true,
            siteProjectId: true,
            siteRunGroupId: true,
            runToken: true,
            status: true,
            runningTime: true,
            queuedTime: true,
            startUrl: true,
            startValue: true,
            decodedData: true,
            decodedSourceSegments: true,
            searchType: true,
            supplier: true,
            subGroup: true,
            passengersArray: true,
            createdAt: true
          }
        },
        preloadFields: ['projects{id name} groups{id name}']
      },
      preload: true,
      preloadStores: [
        {
          name: 'projects',
          storePrimaryKey: 'id'
        }
      ],
      stores: {
        statuses: {
          'initialized': 'initialized',
          'queued': 'queued',
          'running': 'running',
          'cancelled': 'cancelled',
          'complete': 'complete',
          'error': 'error'
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '80px'
          },
          {
            name: 'siteProjectId',
            format: 'from_instance',
            storeKey: 'projects',
            storeLabelKey: 'name'
          },
          'runToken',
          'searchType',
          'supplier',
          'subGroup',
          {
            name: 'passengersArray',
            format: 'array_as_string'
          },
          'status',
          {
            name: 'queuedTime',
            width: '90px',
            default: '-'
          },
          {
            name: 'runningTime',
            width: '90px',
            default: '-'
          },
          {
            name: 'createdAt',
            width: '120px',
            format: 'date_time',
            default: '-'
          },
          {
            name: 'actions',
            width: '100px',
            align: 'center'
          },
          {
            name: 'expand',
            type: 'expand'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'check_run_data',
            icon: 'el-icon-refresh-right',
            type: 'danger',
            plain: true
          },
          {
            name: 'match_variant',
            icon: 'el-icon-caret-right',
            type: 'success',
            plain: true
          }
        ],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: '',
            items: [
              {
                name: 'site_project_id',
                type: 'select',
                operator: 'eq',
                storeKey: 'projects',
                storePrimaryKey: 'id',
                storeLabelKey: 'name'
              },
              {
                name: 'run_token',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'status',
                type: 'select',
                operator: 'eq',
                storeKey: 'statuses',
                translateOptions: false
              },
              {
                name: 'site_run_group_id',
                type: 'select',
                operator: 'eq',
                storeKey: 'groups',
                storePrimaryKey: 'id',
                storeLabelKey: 'name'
              }
            ]
          }
        ]
      }
    }
  ]
}
