<template lang="pug">
  el-dialog(
    v-if="booking"
    :visible.sync="explorerCheckDialog.visible"
    :title="explorerCheckDialog.title"
    center
    @closed="handleClose"
    v-loading="loading" elementLoadingSpinner="el-icon-loading"
  )
    el-row
      el-steps(:space="400" :active="explorerCheckInformation.status" finish-status="success" align-center)
        el-step(v-for="v in explorerCheckDialog.steps[explorerCheckInformation.status]" :title="v" :value="v" :key="v")

    el-row(v-if="showSentData")
      el-divider(content-position="left") Data Sent to Queue
      el-descriptions(class="margin-top" border="border" direction="horizontal" :column="1")
        el-descriptions-item(v-for="(value, key) in sentData" :key="key" :label="translate(key)")
          span {{translate(explorerCheckInformation[key])}}

    el-row(v-if="showProcessedData")
      el-divider(content-position="left") Processed Data
      el-descriptions(class="margin-top" border="border" direction="horizontal" :column="1")
        el-descriptions-item(v-for="(value, key) in processedData" :key="key" :label="translate(key)" v-if="!(key == 'processingIncomeCurrency' && !processedData['processingIncomeAmount'])")
          span {{translate(explorerCheckInformation[key])}}

    el-form(v-if="showSendingForm" :model="sendingForm" :rules="sendingFormRules" ref="sendingForm" labelPosition="right" labelWidth="150px")

      el-form-item(:label="translate('sendingReason')" prop="sendingReason")
        el-select(v-model="sendingForm.sendingReason" placeholder="Choose reason" size="large" @change="sendingReasonOnChange")
          el-option(v-for="(label, value) in sendingFormReasons" :label="label" :value="value" :key="value")

      el-form-item(v-if="sendingForm.sendingReason == 'price_increase'" :label="translate('sendingSurchargeAmount')")
        el-form-item(prop="sendingSurchargeAmount")
          el-input(v-model="sendingForm.sendingSurchargeAmount" clearable size="medium")
            template(slot="append") {{sendingForm.sendingSurchargeCurrency}}

      el-form-item(:label="translate('sendingLinks')" prop="sendingLinks")
        el-input(v-model="sendingForm.sendingLinks" type="textarea" :autosize="{ minRows: 3, maxRows: 5}")

      el-form-item(:label="translate('sendingDescription')" prop="sendingDescription")
        el-input(v-model="sendingForm.sendingDescription" type="textarea" :autosize="{ minRows: 3, maxRows: 5}")

    el-form(v-if="showProcessingForm" :model="processingForm" :rules="processingFormRules" ref="processingForm" labelPosition="right" labelWidth="150px")
      el-divider(content-position="left") Enter Processing data

      el-form-item(:label="translate('processingReason')" prop="processingReason")
        el-select(v-model="processingForm.processingReason" placeholder="Choose reason" size="large" @change="processingReasonOnChange")
          el-option(v-for="(label, value) in processingFormReasons" :label="label" :value="value" :key="value")

      el-form-item(v-if="showSubProcessingReasons" :label="translate('processingResult')" prop="processingResult")
        el-select(v-model="processingForm.processingResult" placeholder="Choose reason" size="large" @change="processingResultOnChange")
          el-option(v-for="(label, value) in resultReasons" :label="label" :value="value" :key="value")

      el-form-item(v-if="processingForm.processingResult == 'additional_income'" :label="translate('processingIncomeAmount')" inline)
        el-row
          el-col(:span="19")
            el-form-item(prop="processingIncomeAmount")
              el-input(v-model="processingForm.processingIncomeAmount" clearable size="medium")
          el-col(:span="1" class="line") &nbsp;
          el-col(:span="4")
            el-select(v-model="processingForm.processingIncomeCurrency" size="medium")
              el-option(v-for="value in currenciesList" :label="value" :value="value" :key="value")

      el-form-item(:label="translate('processingLinks')" prop="processingLinks")
        el-input(v-model="processingForm.processingLinks" type="textarea" :autosize="{ minRows: 3, maxRows: 5}")

      el-form-item(:label="translate('processingComment')" prop="processingComment")
        el-input(v-model="processingForm.processingComment" type="textarea" :autosize="{ minRows: 3, maxRows: 5}")

    span(v-if="!complete" slot="footer" class="dialog-footer" center)
      el-button(@click="handleSave(false)" type="primary") Save
      el-button(@click="handleSave(true)" type="primary") {{queueButtonText}}
      el-button(@click="explorerCheckDialog.visible = false") Cancel

</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  props: {
    booking: null
  },
  data() {
    return {
      loading: false,
      explorerCheckDialog: {
        title: 'Explorer Check',
        visible: false,
        status: 0,
        steps:[
          ['Sending', 'Processing'],
          ['Sent', 'Processing'],
          ['Sent', 'Processed']
        ]
      },
      explorerCheckInformation:{
        status: 0,
        sendingReason: null,
        sendingSurchargeCurrency: null,
        sendingSurchargeAmount: null,
        sendingLinks: null,
        sendingDescription: null,
        processingReason: null,
        processingResult: null,
        processingIncomeCurrency: null,
        processingIncomeAmount: null,
        processingLinks: null,
        processingComment: null
      },
      sendingForm:{
        sendingReason: '',
        sendingSurchargeAmount: '',
        sendingSurchargeCurrency: '',
        sendingLinks: '',
        sendingDescription: ''
      },
      sendingFormRules:{
        sendingReason: [{ required: true, message: 'Please select reason', trigger: 'change' }]
      },
      sendingFormReasons:{
        scheme: 'Схема',
        price_increase: 'Ріст ціни',
        no_seats: 'Нема місць',
        not_tariffed: 'Не тарифікується',
        upsales_not_added: 'Не можу додати дод. послуги',
        uc_un_no: 'UC/UN/NO',
        other: 'Інше'
      },
      processingForm:{
        processingReason: '',
        processingResult: '',
        processingIncomeCurrency: '',
        processingIncomeAmount: '',
        processingLinks: '',
        processingComment: ''
      },
      processingFormRules:{
        processingReason: [{ required: true, message: 'Please select reason', trigger: 'change' }]
      },
      processingFormReasons:{
        agent_error: 'Помилка агента',
        surcharge: 'Виставлена доплата',
        book_cancelling: 'Ануляція броні',
        alternative_proposed:  'Запропонована альтернатива',
        book_issue_original: 'Виписка по оригінальних класах/ціні',
        book_issue_without_surcharge:  'Виписка без доплати з урахуванням націнок',
        other: 'Інше'
      },
      subProcessingReasons:{
        right_reason: 'Причина 1 підтвердилась',
        rebook_or_split: 'Перебук місць/спліт',
        fare_finded: 'Знайдено тариф',
        aircompany_site: 'Сайт АК',
        additional_income: 'Додатковий заробіток',
        other: 'Інше'
      }
    }
  },

  computed: {
    showSentData() { return this._.indexOf([1, 2], this.explorerCheckInformation.status) > -1 },
    showProcessedData() { return this.explorerCheckInformation.status == 2 },
    processedData() {
      return Object.fromEntries(Object.entries(this.explorerCheckInformation).filter(([key, value]) => {
        return key.startsWith('processing') && value !== null && value != 0 }))
    },
    sentData() {
      return Object.fromEntries(Object.entries(this.explorerCheckInformation).filter(([key, value]) => {
        return key.startsWith('sending') && value !== null }))
    },
    showSendingForm() { return this._.indexOf([null, 0], this.explorerCheckInformation.status) > -1 },
    showProcessingForm() { return this.explorerCheckInformation.status == 1 },
    complete() { return this.showSentData && this.showProcessedData },
    queueButtonText() { return this.showSendingForm ? 'Send to Queue' : 'Remove from queue' },
    showSubProcessingReasons() {
      return this._.indexOf(['surcharge', 'book_issue_original', 'book_issue_without_surcharge'], this.processingForm.processingReason) > -1
    },
    resultReasons() {
      if(this._.indexOf(['book_issue_original', 'book_issue_without_surcharge'], this.processingForm.processingReason) > -1) {
        return this.subProcessingReasons
      }else{
        return Object.fromEntries(Object.entries(this.subProcessingReasons).filter(([key, value]) => {
          return key !== 'additional_income' }))
      }
    },
    currenciesList() {
      return Object.keys(JSON.parse(this.booking.finalAllPrice))
    }
  },

  methods: {
    show() {
      this.getExplorerCheckInformation()
    },

    async getExplorerCheckInformation() {
      try {
        this.loading = true
        const query = {
          __args: {
            bookingId: this.booking.id
          },
          status: true,
          sendingReason: true,
          sendingSurchargeCurrency: true,
          sendingSurchargeAmount: true,
          sendingLinks: true,
          sendingDescription: true,
          processingReason: true,
          processingResult: true,
          processingIncomeCurrency: true,
          processingIncomeAmount: true,
          processingLinks: true,
          processingComment: true
        }

        let res = await this.graphql('aviaBookingItemExplorerCheckInformation', query, 'query')

        if(res.status !== null) {
          this.explorerCheckInformation = { ...res }
          this.sendingForm = { ...this.customMerge(
            this._.zipObject(['sendingReason', 'sendingSurchargeCurrency', 'sendingSurchargeAmount', 'sendingLinks', 'sendingDescription']),
            this.explorerCheckInformation) }
          this.processingForm = { ...this.customMerge(
            this._.zipObject(['processingReason', 'processingResult', 'processingIncomeCurrency', 'processingIncomeAmount', 'processingLinks', 'processingComment']),
            this.explorerCheckInformation) }
        }

        this.explorerCheckDialog.visible = true
        
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    translate(key) {
      return {
        sendingReason: 'Причина',
        sendingLinks: 'Посилання на скрін(-и)',
        sendingDescription: 'Опис',
        sendingSurchargeCurrency: 'Валюта',
        sendingSurchargeAmount: 'Cума доплати',
        processingReason: 'Причина',
        processingResult: 'Результат',
        processingIncomeCurrency: 'Валюта',
        processingIncomeAmount: 'Cума заробітку',
        processingLinks: 'Посилання на скрін(-и)',
        processingComment: 'Коментар',
        scheme: 'Схема',
        price_increase: 'Ріст ціни',
        no_seats: 'Нема місць',
        not_tariffed: 'Не тарифікується',
        upsales_not_added: 'Не можу додати дод. послуги',
        uc_un_no: 'UC/UN/NO',
        other: 'Інше',
        agent_error: 'Помилка агента',
        surcharge: 'Виставлена доплата',
        book_cancelling: 'Ануляція броні',
        alternative_proposed:  'Запропонована альтернатива',
        book_issue_original: 'Виписка по оригінальних класах/ціні',
        book_issue_without_surcharge:  'Виписка без доплати з урахуванням націнок',
        other: 'Інше',
        right_reason: 'Причина 1 підтвердилась',
        rebook_or_split: 'Перебук місць/спліт',
        fare_finded: 'Знайдено тариф',
        aircompany_site: 'Сайт АК',
        additional_income: 'Додатковий заробіток',
        other: 'Інше'

      }[key] || key
    },

    isArrayInclude(array, value) {
      return this._.indexOf(array, value) > -1
    },

    handleClose() {
      this.explorerCheckDialog.visible = false
      this.explorerCheckInformation = { ...{
        status: 0,
        sendingReason: null,
        sendingSurchargeCurrency: null,
        sendingSurchargeAmount: null,
        sendingLinks: null,
        sendingDescription: null,
        processingReason: null,
        processingResult: null,
        processingIncomeCurrency: null,
        processingIncomeAmount: null,
        processingLinks: null,
        processingComment: null
      }}

      this.sendingForm = { ...this.customMerge(this.sendingForm, this.explorerCheckInformation) }
      this.processingForm = { ...this.customMerge(this.processingForm, this.explorerCheckInformation) }

      this.$refs.sendingForm?.resetFields()
      this.$refs.processingForm?.resetFields()
    },

    handleSave(to_queue = false) {
      if (this.explorerCheckInformation.status == 1) {
        this.$refs.processingForm.validate((valid) => {
          if (valid) {
            this.handleSaveProcessing(to_queue)
          }else{
            return false
          }
        })
      }else{
        this.$refs.sendingForm.validate((valid) => {
          if (valid) {
            this.handleSaveSending(to_queue)
          }else{
            return false
          }
        })
      }
    },

    handleSaveSending(to_queue = false) {
      let data = this.prepareData(this.sendingForm, to_queue ? 1 : 0)
      this.saveForm(data)
    },

    handleSaveProcessing(from_queue = false) {
      let data = this.prepareData(this.processingForm, from_queue ? 2 : 1)
      this.saveForm(data)
    },

    prepareData(formData, status) {
      let data = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => {
          return value && value != '' && value !== null
        }
        )
      )
      data['status'] = status
      return data
    },

    async saveForm(data) {
      this.loading = true

      try {
        const query = {
          __args: {
            bookingId: this.booking.id,
            data: this.camelToSnake(data)
          }
        }
        let res = await this.graphql('aviaBookingItemSaveExplorerCheckInformation', query, 'mutation')
        this.$message({
          message: res.text,
          type: res.status
        })
        this.getExplorerCheckInformation()
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    sendingReasonOnChange(value) {
      if(value === 'price_increase') {
        this.sendingForm.sendingSurchargeCurrency = this.booking.depotCurrency
      }else{
        this.sendingForm.sendingSurchargeAmount = null
        this.sendingForm.sendingSurchargeCurrency = null
        this.sendingForm = { ...this.sendingForm }
      }
    },

    processingReasonOnChange(value) {
      if (this.showSubProcessingReasons) {

      }else{
        this.processingForm.processingResult = null
        this.processingForm.processingIncomeAmount = null
        this.processingForm.processingIncomeCurrency = null
        this.processingForm = { ...this.processingForm }
      }
    },

    processingResultOnChange(value) {
      if (value === 'additional_income') {
        this.processingForm.processingIncomeCurrency = this.booking.depotCurrency
      }else{
        this.processingForm.processingIncomeAmount = null
        this.processingForm.processingIncomeCurrency = null
        this.processingForm = { ...this.processingForm }
      }
    },

    handleCancel() {
      this.explorerCheckDialog.visible = false
      this.$refs.sendingForm?.resetFields()
      this.$refs.ProcessingForm?.resetFields()
    },

    customMerge(object1, object2) {
      for (const key in object1) {
        if (object1.hasOwnProperty(key) && object2.hasOwnProperty(key)) {
          object1[key] = object2[key]
        }
      }
      return object1
    },

    camelToSnake(obj) {
      const snakeCaseObj = {}
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
          snakeCaseObj[snakeCaseKey] = obj[key]
        }
      }
      return snakeCaseObj
    }

  }
}
</script>
