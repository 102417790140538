export default {
  inject: ['$elContainer'],

  data() {
    return {
      itemName: '',
      itemModel: '',
      tabName: '',
      lastState: null
    }
  },
  provide() {
    return {
      '$elTab': this
    }
  },

  watch: {
    item: {
      immediate: true,
      handler: function(val) {
        this.itemModel = val
      }
    },
    tab: {
      immediate: true,
      handler: function(val, old) {
        // console.log(`change tab: val=${val}, old=${old}, componentNmae= ${this.component.name}, itemName=${this.itemName}, itemModel=${this.itemModel}`)
        this.tabName = val
        if (val === this.component.name && old !== '') {
          const item = this.lastState || this.component.items[0].name
          this.itemModel = item
          this.itemName = item
          this.$elContainer.itemChange({ tab: val, item: item })
        }
      }
    },
    itemName(val) {
      if (this.tab === this.component.name && val !== this.lastState) {
        this.lastState = val
      }
    }
  },

  methods: {
    tabLabel(config) {
      return `${config.namespace ? config.namespace + '.' : ''}${config.name}`
    },

    eventBeforeLeave(activeName, oldActiveName) {
      if (this.tab === this.component.name && this.itemModel) {
        // console.log(`eventBeforeLeave: activeName=${activeName}, itemNmae=${this.itemName}, item=${this.item}, itemModel=${this.itemModel}`)
        activeName === this.component.name
        if (!this.itemName || this.itemName !== this.itemModel) {
          this.itemName = activeName
          this.$elContainer.$emit(this.$channels.navigationItemStart(this.tab, activeName))
          this.$elContainer.itemChange({ tab: this.tab, item: activeName })
        } else {
          this.$elContainer.$emit(this.$channels.navigationItemStart(this.tab, activeName))
        }
      }
    },

    tabClick(tab) {
      if(tab.name !== this.item) {
        this.itemName = tab.name
        this.$elContainer.itemChange({ tab: this.tab, item: tab.name })
      }
    },
    checkIsHide() {
      return !this.$elContainer.checkIsHide() && this.component.items.length === 1
    }
  }
}