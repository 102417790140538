export default {
  items: [
    'selection',
    {
      name: 'id',
      width: '50px'
    },
    {
      name: 'priority',
      width: '75px',
      align: 'center'
    },
    {
      name: 'active',
      type: 'switch',
      width: '75px',
      align: 'center'
    },
    {
      name: 'bsp',
      width: '60px',
      align: 'center'
    },
    {
      name: 'gds',
      format: 'array_as_string'
    },
    {
      name: 'validatingSupplier',
      width: '80px',
      align: 'center'
    },
    {
      name: 'rbd',
      width: '60px',
      align: 'center'
    },
    'fareBasis',
    'fareCode',
    {
      name: 'penaltyBeforeDepartureAmount',
      align: 'center'
    },
    {
      name: 'penaltyAfterDepartureAmount',
      align: 'center'
    },
    {
      name: 'penaltyNoshowAmount',
      align: 'center'
    },
    {
      name: 'actions',
      width: '70px',
      align: 'center'
    }
  ]
}