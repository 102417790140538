<style scoped>
  .row{ padding-right: 10px }
</style>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    computed: {
      required() {
        const message = this.translate('form.required')
        const required = true
        return [{ required, message, trigger: 'change' }]
      }
    },
    extends: BaseForm,
    methods: {
      formatTemp() {
        let reportParams = {}

        for (let i in this.temp) {
          if(i.startsWith('reportParams__')){
            reportParams[i.replace('reportParams__', '')] = this.formatField(this.temp[i], this.fieldReference[i])
          }
        }

        if(!this._.isEmpty(reportParams)){
          this.temp['reportParams'] = JSON.stringify(reportParams)
        }

        return this.temp
      },
      beforeCreateCallback(){
        this.temp = Object.assign(
          {},
          this.temp,
          { reportParams: {} }
        )
      }
    }
  }
</script>
