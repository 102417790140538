import parsing from './web_scraper/parsing'
import access from './web_scraper/access'
import validator from './web_scraper/validator'

export default {
  web_scraper: {
    configuration: {
      parsing,
      access,
      validator
    }
  }
}