<template lang="pug">
  extends /table

  block custom
    refresh-monthly-statistic-window
</template>

<script>
import BaseTable from '@crud_view/table'
import BaseStatisticTable from '../base/table'
import RefreshMonthlyStatisticWindow from '../base/refresh_monthly_statistic_window'

export default {
  components: {
    RefreshMonthlyStatisticWindow
  },
  extends: BaseTable,
  mixins: [BaseStatisticTable],
  computed:{
    gdsStorePath() { return 'avia/l2b/l2b_statistics/monthly_by_depot/preload' }
  },
  methods: {
    actionRefreshMonthlyStatistic() {
      this.$emit('showRefreshMonthlyWindow')
    }
  }
}
</script>

<style lang="scss">
  .avia_l2b__statistics_main{
    .el-table__expanded-cell{
      padding: 0px 0px 0px 10px !important;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000
    }
    .filter-form{
      display: none
    }
    .el-table .cell{
      padding: 0 5px;
    }
    .el-table--border th:first-child .cell, .el-table--border td:first-child .cell {
      padding: 0 5px;
    }
  }
  .avia_l2b__statistics_mounthly{
    .el-table__expanded-cell{
      padding: 0px 0px 0px 2px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000
    }
    .filter-form{
      display: none
    }
    .el-table .cell{
      padding: 0 5px;
    }
    .el-table--border th:first-child .cell, .el-table--border td:first-child .cell {
      padding: 0 5px;
    }
  }
</style>
