<style scoped>
.row {
  padding-right: 10px
}
.del-ceil{
  padding: 4px 0px 0px 10px
}
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='3' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='70px')
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['ruleIndex']" v-bind="attr('ruleIndex')" label-width='90px')
        el-col(:span='15' class='row')
          ttn-input(v-model="temp['airlines']" v-bind="attr('airlines')" label-width='70px')
      ttn-input(v-model="temp['officeId']" v-bind="attr('officeId')" label-width='70px')
      ttn-multiselect(v-model="temp['driverId']" v-bind="attr('driverId')" label-width='70px')
      ttn-textarea(v-model="temp['routes']" v-bind="attr('routes')" label-width='70px')

      fieldset(:class="`el-form-fieldset`")
        legend {{ translate(`form.legends.markups`) }}

        el-button(@click="actionAddItem('markups')" type="primary") {{ translate('form.add') }}
        template(v-if="temp.markups_decoded")
          div(v-for="(markup, i) in temp.markups_decoded")
            el-row
              el-col(:span='4' class='row')
                ttn-select(v-model="temp['markups_decoded'][i]['pax_type']" v-bind="elAttr('markups__paxType', `markups_decoded.${i}.pax_type`)" label-width='50px' :rules="required")
              el-col(:span='5' class='row')
                ttn-input(v-model="temp['markups_decoded'][i]['days_range']" v-bind="elAttr('markups__daysRange', `markups_decoded.${i}.days_range`)" label-width='80px')
              el-col(:span='6' class='row')
                ttn-input(v-model="temp['markups_decoded'][i]['value']" v-bind="elAttr('markups__value', `markups_decoded.${i}.value`)" label-width='60px')
              el-col(:span='5' class='row')
                ttn-input(v-model="temp['markups_decoded'][i]['percent_value']" v-bind="elAttr('markups__percentValue', `markups_decoded.${i}.percent_value`)" label-width='80px' :rules="required")
              el-col(:span='3' )
                ttn-select(v-model="temp['markups_decoded'][i]['percent_from']" v-bind="elAttr('markups__percentFrom', `markups_decoded.${i}.percent_from`)" label-width='20px')
              el-col(:span='1' class='del-ceil')
                el-button(@click="actionRemoveItem('markups', i)" type="danger" :disabled="isDeleteRowDisabled('markups')" class="sbpr-cnc-b") -
            hr

</template>

<script>
  import BaseForm from '@crud_view/form'
  import MultiRowField from '@crud_lib/form/multi_row_field'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    mixins: [MultiRowField],
    computed: {
      multiRowConfig() {
        return {
          markups: {
            default: {pax_type: '0', value: '0.0EUR', percent_value: 0.0, percent_from: '0', days_range: ''}
          }
        }
      }
    },
    methods: {
    }
  }
</script>
