export default {
  seats_block_reports: {
    table: {
      name: 'Name',
      handlerStatus: 'Status',
      filePath: 'Report file',
      reportType: 'Type',
    },
    form: {
      fields: {
        name: 'Name',
        reportType: 'Report type',
        searchOptions__startDepartureDate: 'Dep. date from',
        searchOptions__endDepartureDate: 'Dep. date to',
        searchOptions__validatingSupplier: 'Validating supplier',
        searchOptions__officeId: 'Office ID',
        searchOptions__contractId: 'Contract ID',
        searchOptions__routeKey: 'Route',
        searchOptions__useGdsData: 'Use gds data',
      },
      options: {
        handlerStatus: {
          0: 'Undefined',
          1: 'Success',
          2: 'Error',
          3: 'Exception',
          4: 'Started',
        },
        reportType: {
          0: 'Available report',
          'available_seats': 'Available report'
        }
      },
      legends: {
        settings: 'Settings'
      }
    },
    messages:{
      download_error: 'Download valid only for Success status',
      download_empty_content: 'File not valid'
    }
  }
}
