<template lang="pug">
  div(v-if="booking")
    
    div(v-if="false") {{ preparedInsuranceServices }}
    
    table(border="0" style="width: 100%")
      tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
        th Locator
        th Provider
        th Dates (from/to)
        th Cost
        th Status

      template(v-for="(insurance, insuranceInx) in insuranceBookings")
        tr(style="text-align: center")
          td.bold {{ insurance.reservationId }}
          td.bold {{ insurance.providerName }}
          td {{ insurance.periodFrom }} ... {{ insurance.periodTo }}
          td.bold  {{ insurance.depotCost }} {{ insurance.depotCurrency }}
          td {{ insurance.bookingStatus }}
</template>

<script>
import BaseTable from '@crud_view/table'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  // extends: BaseTable,
  mixins: [common, di],

  props: {
    booking: null,
    reloadBooking: null
  },

  data() {
    return {
      preparedInsuranceServices: []
    }
  },

  computed: {
    insuranceBookings() { return this.booking.insuranceBookings },

    required() {
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    }
  },

  watch: {
    booking(val) {
      this.initInsuranceBookings()
      // this.loading = false
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.initInsuranceBookings()
    })
  },

  methods: {
    initInsuranceBookings() {
      this.preparedInsuranceBookings = []

      this.booking.insuranceBookings.forEach((x) => {
        this.preparedInsuranceBookings = [
          ...this.preparedInsuranceBookings,
          {
            ...x,
            active: false
          }
        ]
      })
    }

  }
}
</script>
