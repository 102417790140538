<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatCell({row, $column, column, cellValue, index}) {
      switch(column.name) {
        case 'description':
          let previous = [
            ['previousLocation', 'Prev. loc'], ['arrivalTerminal', 'Ter.'], ['arrivalCarrier', 'Car.'],
            ['arrivalCodeshareCarrier', 'CodSr'], ['arrivalFlightNumber', 'Flght'], ['arrivalAircraft', 'AirCr']
          ].map(k => {
            return row[k[0]].length == 0 ? null : `${k[1]}: [${row[k[0]].join(',')}]`
          }).filter(n => n).join(' | ')

          let next = [
            ['nextLocation', 'Next loc'], ['departureTerminal', 'Ter.'], ['departureCarrier', 'Car.'],
            ['departureCodeshareCarrier', 'CodSr'], ['departureFlightNumber', 'Flght'], ['departureAircraft', 'AirCr']
          ].map(k => {
            return row[k[0]].length == 0 ? null : `${k[1]}: [${row[k[0]].join(',')}]`
          }).filter(n => n).join(' | ')

          return `${previous} --> ${next}`
        default:
          return cellValue
      }
    },
    formatExpandedRow(row){
      return [
        'previousLocation', 'arrivalTerminal', 'arrivalCarrier', 'arrivalCodeshareCarrier',
        'arrivalFlightNumber', 'arrivalAircraft', 'nextLocation', 'departureTerminal',
        'departureCarrier', 'departureCodeshareCarrier', 'departureFlightNumber',
        'departureAircraft', 'validFrom', 'validTo'
      ].map((k) => {
        return `<b>${this.translate(`table.${k}`)}</b>: [&nbsp;${Array.isArray(row[k]) ? row[k].join(', ') : row[k]}&nbsp;]`
      }).join('<br>')
    }
  }
}
</script>
