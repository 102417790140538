export default {
  feature_setting: {
    table:{
      entity: 'Entity',
      setting: 'Settings'
    },
    form: {
      fields: {
        entity: 'Entity',
        setting: 'Settings',
        settingJson: 'Settings'
      }
    }
  },

  cache_interval: {
    table: {
      entity: 'Entity',
      lastRun: 'Last Run',
      interval: 'Interval (sec.)',
      type: 'Type',
      params: 'Additional Params',
      startAt: 'Start At',
      active: 'Active',
      running: 'Is running?'
    },
    form: {
      fields: {
        entity: 'Entity',
        interval: 'Iterval',
        type: 'Type',
        params: 'Additional Params',
        startAt: 'Start At',
        active: 'Active',
        running: 'Is running?'
      }
    },
    action: {
      run: 'RUN!'
    },
  }
}
