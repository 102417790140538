import settings from './settings/service_setting'
import connections from './settings/connection'
import packets from './settings/packet'
import risks from './settings/risk'
import packetsRisks from './settings/packets_risks'

export default {
  namespace: 'insurance',
  component: 'settings',
  meta: {
    title: 'insurance.settings',
    icon: 'gear'
  },
  tabs: [
    settings,
    connections,
    packets,
    risks,
    packetsRisks,
  ]
}
