export default {
  name: 'data_flight_item',
  meta: {
    title: 'data_flight_item',
    icon: 'el-icon-s-comment',
    permission: 'data_flight_item'
  },
  lazy: true,
  items: [
    {
      name: 'data_flight_item',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      // paginationType: 'cursor',
      graphql: {
        // name: 'graphFlight',
        name: 'graphDataItem',
        queries: {
          index: {
            bucketKey: true,
            flightKey: true,
            departureDate: true,
            departureCity: true,
            departureLocation: true,
            departureTime: true,
            arrivalCity: true,
            arrivalLocation: true,
            arrivalTime: true,
            route: true,
            price: true,
            updatedAt: true,
            fullDetails: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'bucketKey'
          },
          {
            name: 'departure',
            width: '90px'
          },
          {
            name: 'departureTime',
            width: '110px'
          },
          {
            name: 'flights',
            width: '130px'
          },
          {
            name: 'validatingSupplier',
            width: '60px'
          },
          {
            name: 'officeId',
            width: '110px'
          },
          {
            name: 'arrival',
            width: '90px'
          },
          {
            name: 'arrivalTime',
            width: '110px'
          },

          {
            name: 'price'
          },
          {
            name: 'updatedAt'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
       ],
       panel: [
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'bucket_key',
            type: 'date',
            operator: 'eq',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: true
          },
          {
            name: 'departure_city',
            operator: 'eq'
          },
          // {
          //   name: 'arrival_city',
          //   operator: 'eq'
          // },
          {
            name: 'route',
            operator: 'eq'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'bucketKey',
            type: 'text',
            required: false
          },
          {
            name: 'flightKey',
            type: 'text',
            required: false
          },
          {
            name: 'departureDate',
            type: 'text',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'departureCity',
            type: 'text',
            required: false
          },
          {
            name: 'departureLocation',
            type: 'text',
            required: false
          },
          {
            name: 'departureTime',
            type: 'text',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'arrivalCity',
            type: 'text',
            required: false
          },
          {
            name: 'arrivalLocation',
            type: 'text',
            required: false
          },
          {
            name: 'arrivalTime',
            type: 'text',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'route',
            type: 'text',
            required: false
          },
          {
            name: 'price',
            type: 'text',
            required: false
          }
        ]
      }
    }
  ]
}
