export default {
  namespace: 'avia',
  component: 'reports',
  meta: {
    title: 'avia.reports',
    icon: 'table'
  },
  tabs: [
    {
      name: 'reports',
      items: [
        {
          name: 'generated_files',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'reportFiles',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                createdAt: true,
                updatedAt: true,
                userId: true,
                filePath: true,
                entity: true,
                storage: true,
                backendUsers: {
                  login: true
                }
              }
            }
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [],
            panel: [
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'reportFileFileContent'
                }
              },
            ]
          },
          filter: {
            items: [
              {
                name: 'entity',
                type: 'select',
                operator: 'eq',
                store: {
                  'FakePnr': 'FakePnr'
                }
              },
              {
                name: 'user_id',
                type: 'text',
                operator: 'eq'
              }
            ]
          },
          form: {
          }
        }
      ]
    }
  ]
}
