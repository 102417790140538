import rule from './connection_time/rule'

export default {
  namespace: 'ttn_avia_vi',
  component: 'connection_time',
  meta: {
    title: 'ttn_avia_vi.connection_time',
    icon: 'el-icon-rank'
  },
  tabs: [
    rule
  ]
}
