import gds_list from          '@/settings/modules/avia/general/gds_store'
import bsp_list from          '../../general/bsp_list'
import currencies_list from   '../../general/currencies_list'

export default{
  actualize: true,
  fieldsets: [
    {
      legend: 'rule_text',
      translateLegend: true,
      items: [
        {
          name: 'ruleText',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 8,
          ignore: true
        },
        {
          name: 'beforeDeparture',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'beforeDepartureAddFee',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'beforeDepartureChangeTo',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'beforeDepartureNonRef',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'afterDeparture',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'afterDepartureAddFee',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'afterDepartureChangeTo',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'afterDepartureNonRef',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'noShow',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'noShowAddFee',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'noShowChangeTo',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },{
          name: 'noShowNonRef',
          type: 'textarea',
          valueType: 'string',
          required: false,
          rows: 6
        },
        {
          name: 'penaltyAfterDepartureAmount',
          type: 'text',
          valueType: 'float',
          required: false,
          ignore: true
        },
        {
          name: 'penaltyAfterDepartureCurrency',
          type: 'select',
          store: Object.assign({'%': '%'}, currencies_list),
          required: false,
          ignore: true
        },
        {
          name: 'penaltyAfterDepartureNonRef',
          type: 'checkbox',
          default: false,
          required: false,
          ignore: true
        },
        {
          name: 'penaltyBeforeDepartureAmount',
          type: 'text',
          valueType: 'float',
          required: false,
          ignore: true
        },
        {
          name: 'penaltyBeforeDepartureCurrency',
          type: 'select',
          store: Object.assign({'%': '%'}, currencies_list),
          required: false,
          ignore: true
        },
        {
          name: 'penaltyBeforeDepartureNonRef',
          type: 'checkbox',
          default: false,
          required: false,
          ignore: true
        },
        {
          name: 'penaltyNoshowAmount',
          type: 'text',
          valueType: 'float',
          required: false,
          ignore: true
        },
        {
          name: 'penaltyNoshowCurrency',
          type: 'select',
          store: Object.assign({'%': '%'}, currencies_list),
          required: false,
          ignore: true
        },
        {
          name: 'penaltyNoshowNonRef',
          type: 'switch',
          default: false,
          required: false,
          ignore: true
        },
        {
          name: 'id',
          type: 'hidden',
          required: false,
          valueType: 'integer'
        },
        {
          name: 'priority',
          type: 'hidden',
          default: 0,
          valueType: 'integer'
        },
        {
          name: 'active',
          type: 'checkbox',
          required: false,
          default: false
        },
        {
          name: 'fareBasis',
          type: 'text',
          valueType: 'string',
          required: false,
        },
        {
          name: 'validatingSupplier',
          type: 'text',
          valueType: 'string'
        },
        {
          name: 'gds',
          type: 'select',
          store: gds_list.objectByName(),
          required: false,
        },
        {
          name: 'bsp',
          type: 'select',
          store: bsp_list,
          required: false,
        }
      ]
    }
  ]
}