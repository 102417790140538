import parsersRyanair from './parsers/ryanair'
import parsersPaxport from './parsers/paxport'

export default {
  namespace: 'avia',
  component: 'parsers',
  meta: {
    title: 'avia.parsers',
    icon: 'el-icon-download',
    roles: ['pesimisation_rules']
  },
  tabs: [
    parsersRyanair,
    parsersPaxport
  ]
}
