async function quickFetch(request, url, params, method) {
  return new Promise((resolve, reject) => {
    const requestData = {
      url,
      method
    }
    if (method === 'get') {
      requestData.params = params
    } else {
      requestData.data = params
    }
    request(requestData).then((res) => {
      resolve(res.data)
    })
  })
}

export default function makeRestService(request, endpoint) {
  return {
    async list(params) {
      return quickFetch(request, `${endpoint}/index`, params, 'get')
    },
    // async get(id) {
    //   return quickFetch(`${endpoint}/${id}`, {}, 'get')
    // },
    async get(params) {
      return quickFetch(request, `${endpoint}/get`, params, 'get')
    },
    async create(params) {
      // return quickFetch(endpoint, params, 'post')
      return quickFetch(request, `${endpoint}/create`, params, 'post')
    },
    async update(params) {
      // return quickFetch(`${endpoint}/${params.id}`, params, 'put')
      return quickFetch(request, `${endpoint}/update`, params, 'put')
    },
    async delete(params) {
      return quickFetch(request, `${endpoint}/delete`, params, 'delete')
    },
    async preload(params) {
      return quickFetch(request, `${endpoint}/preload`, params, 'get')
    },
    async custom(url, params) {
      return quickFetch(request, `${endpoint}/${url}`, params, 'get')
    },
    async history(params) {
      return quickFetch(request, `${endpoint}/history`, params, 'get')
    }
  }
}
