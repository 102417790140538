import flightItem from './data/flight_item'

export default {
  namespace: 'ttn_avia_vi',
  component: 'data',
  meta: {
    title: 'ttn_avia_vi.data',
    icon: 'el-icon-coin'
  },
  tabs: [
    flightItem
  ]
}
