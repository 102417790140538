function table_items(elements, level, width = 70) {
  return [
    {
      name: elements[0],
      width: `${width - (level * 12)}px`,
      sortable: false
    },
    {
      name: elements[1],
      width: '140px',
      sortable: false
    },
    {
      name: 'trxCache',
      width: '70px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'trxCachePercent',
      width: '40px',
      sortable: false
    },
    {
      name: 'trxGdsSuccess',
      width: '70px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'trxGdsSuccessPercent',
      width: '40px',
      sortable: false
    },
    {
      name: 'trxGdsError',
      width: '70px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'trxGdsErrorPercent',
      width: '40px',
      sortable: false
    },
    {
      name: 'trxGdsTimeout',
      width: '65px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'trxGdsTimeoutPercent',
      width: '35px',
      sortable: false
    },
    {
      name: 'trxTotal',
      width: '70px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'trxGdsReal',
      width: '70px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'ordersCount',
      width: '47px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'bookingsCount',
      width: '50px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'segmentsCount',
      width: '50px',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'l2bRate',
      format: 'formatted_integer',
      sortable: false
    },
    {
      name: 'expand',
      type: 'expand',
      width: '30px'
    }
  ]
}

function graphql_config(name, fields, group_fields, params) {
  let querie_fields = {
    id: true,
    date: true,
    gdsId: true,
    gdsKey: true,
    segmentsCount: true,
    bookingsCount: true,
    ordersCount: true,
    trxCache: true,
    trxCachePercent: true,
    trxGdsError: true,
    trxGdsErrorPercent: true,
    trxGdsTimeout: true,
    trxGdsTimeoutPercent: true,
    trxGdsReal: true,
    trxGdsSuccess: true,
    trxGdsSuccessPercent: true,
    trxTotal: true,
    l2bRate: true
  }

  for(let f of fields) { querie_fields[f] = true }

  return {
    name: name,
    queries: {
      index: querie_fields
    },
    baseParams:{
      index: {
        ...{
          groupBy: ['gds_key'].concat(group_fields)
        },
        ...params
      }
    },
    preloadFields: ['gds {id gdsName maxValue}']
  }
}

function boot_config(fields) {
  return {
    type: 'remote',
    filters: ['gds_key'].concat(fields),
    parent_filters: true
  }
}

export default {
  name: 'l2b_statistics',
  meta: {
    title: 'l2b_statistics',
    icon: 'skill',
    permission: 'pesimisation_rules'
  },
  lazy: true,
  items: [
    {
      name: 'daily_statistics',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: graphql_config('aviaL2bDailyStatisticItems', ['date'], ['date'], {}),
      combined: [
        {
          name: 'daily_statistics_offices',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: graphql_config('aviaL2bDailyStatisticItems', ['date', 'officeId'], ['date', 'office_id'], {}),
          boot: boot_config(['date']),
          combined: [
            {
              name: 'daily_statistics_depots',
              endpoint: '/persistance/default',
              type: 'graphql',
              graphql: graphql_config('aviaL2bDailyStatisticItems', ['date', 'officeId', 'depotId', 'depotName'], ['date', 'office_id', 'depot_id'], { combine: ['depot'] }),
              boot: boot_config(['date', 'office_id']),
              table: {
                hideHeader: true,
                items: table_items(['empty', 'depotId'], 2)
              },
              combined: [
                {
                  name: 'daily_statistics_refs',
                  endpoint: '/persistance/default',
                  type: 'graphql',
                  graphql: graphql_config('aviaL2bDailyStatisticItems', ['date', 'officeId', 'depotId', 'refId', 'refName'], ['date', 'office_id', 'depot_id', 'ref_id'], { combine: ['ref'] }),
                  boot: boot_config(['date', 'office_id', 'depot_id']),
                  table: {
                    hideHeader: true,
                    items: table_items(['empty', 'refId'], 3)
                  }
                }
              ]
            }
          ],
          table: {
            hideHeader: true,
            items: table_items(['empty', 'officeId'], 1)
          }
        }
      ],
      preload: true,
      table: {
        class: 'avia_l2b__statistics_main',
        items: table_items(['date', 'gdsKey'], 0)
      },
      actions: {
        panel: [
          {
            name: 'refresh_statistic',
            icon: 'el-icon-circle-check',
            type: 'primary',
            plain: true
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'date',
            type: 'date',
            operator: 'eq',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            default: 'today',
            required: true
          },
          {
            name: 'gds_id',
            type: 'select',
            operator: 'eq',
            storeKey: 'gds',
            storePrimaryKey: 'id',
            storeLabelKey: 'gdsName'
          },
          {
            name: 'gds_key',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'office_id',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'depot_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          },
          {
            name: 'ref_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          }
        ]
      }
    },
    {
      name: 'daily_by_depot',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: graphql_config('aviaL2bDailyStatisticItems', ['date'], ['date'], {}),
      combined: [
        {
          name: 'daily_by_depot_depot',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: graphql_config('aviaL2bDailyStatisticItems', ['date', 'depotId', 'depotName'], ['date', 'depot_id'], { combine: ['depot'] }),
          boot: boot_config(['date']),
          table: {
            hideHeader: true,
            items: table_items(['empty', 'depotId'], 1)
          },
          combined: [
            {
              name: 'daily_by_depot_refs',
              endpoint: '/persistance/default',
              type: 'graphql',
              graphql: graphql_config('aviaL2bDailyStatisticItems', ['date', 'depotId', 'refId', 'refName'], ['date', 'depot_id', 'ref_id'], { combine: ['ref'] }),
              boot: boot_config(['date', 'depot_id']),
              table: {
                hideHeader: true,
                items: table_items(['empty', 'refId'], 2)
              },
              combined: [
                {
                  name: 'daily_by_depot_offices',
                  endpoint: '/persistance/default',
                  type: 'graphql',
                  graphql: graphql_config('aviaL2bDailyStatisticItems', ['date', 'depotId', 'refId', 'officeId'], ['date', 'depot_id', 'ref_id', 'office_id'], {}),
                  boot: boot_config(['date', 'depot_id', 'ref_id']),
                  combined: [

                  ],
                  table: {
                    hideHeader: true,
                    items: table_items(['empty', 'officeId'], 3)
                  }
                }
              ]
            }
          ]
        }
      ],
      preload: true,
      table: {
        class: 'avia_l2b__statistics_main',
        items: table_items(['date', 'gdsKey'], 0)
      },
      actions: {
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'date',
            type: 'date',
            operator: 'eq',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            default: 'today',
            required: true
          },
          {
            name: 'gds_id',
            type: 'select',
            operator: 'eq',
            storeKey: 'gds',
            storePrimaryKey: 'id',
            storeLabelKey: 'gdsName'
          },
          {
            name: 'gds_key',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'office_id',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'depot_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          },
          {
            name: 'ref_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          }
        ]
      }
    },
    {
      name: 'monthly_statistics',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: graphql_config('aviaL2bMonthlyStatisticItems', [], [], {}),
      combined: [
        {
          name: 'monthly_statistics_dates',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date'], ['date'], { orderBy: ['date'] }),
          boot: boot_config([]),
          combined: [
            {
              name: 'monthly_statistics_offices',
              endpoint: '/persistance/default',
              type: 'graphql',
              graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date', 'officeId'], ['date', 'office_id'], {}),
              boot: boot_config(['date']),
              combined: [
                {
                  name: 'monthly_statistics_depots',
                  endpoint: '/persistance/default',
                  type: 'graphql',
                  graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date', 'officeId', 'depotId', 'depotName'], ['date', 'office_id', 'depot_id'], { combine: ['depot'] }),
                  boot: boot_config(['date', 'office_id']),
                  combined: [
                    {
                      name: 'monthly_statistics_refs',
                      endpoint: '/persistance/default',
                      type: 'graphql',
                      graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date', 'officeId', 'depotId', 'refId', 'refName'], ['date', 'office_id', 'depot_id', 'ref_id'], { combine: ['ref'] }),
                      boot: boot_config(['date', 'office_id', 'depot_id']),
                      table: {
                        hideHeader: true,
                        items: table_items(['empty', 'refId'], 3)
                      }
                    }
                  ],
                  table: {
                    hideHeader: true,
                    items: table_items(['empty', 'depotId'], 2)
                  }
                }
              ],
              table: {
                hideHeader: true,
                items: table_items(['empty', 'officeId'], 1)
              }
            }
          ],
          table: {
            hideHeader: true,
            class: 'avia_l2b__statistics_main',
            items: table_items(['date', 'gdsKey'], 0, 70)
          }
        }
      ],
      preload: true,
      table: {
        class: 'avia_l2b__statistics_mounthly',
        items: table_items(['date', 'gdsKey'], 0, 74)
      },
      actions: {
        panel: [
          {
            name: 'refresh_monthly_statistic',
            icon: 'el-icon-circle-check',
            type: 'primary',
            plain: true
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'date',
            operator: 'eq',
            type: 'daterange',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            default: ['the beginning of this month', 'the end of this month'],
            required: true
          },
          {
            name: 'gds_id',
            type: 'select',
            operator: 'eq',
            storeKey: 'gds',
            storePrimaryKey: 'id',
            storeLabelKey: 'gdsName'
          },
          {
            name: 'gds_key',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'office_id',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'depot_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          },
          {
            name: 'ref_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          }
        ]
      }
    },
    {
      name: 'monthly_by_depot',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: graphql_config('aviaL2bMonthlyStatisticItems', [], [], {}),
      combined: [
        {
          name: 'monthly_by_depot_dates',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date'], ['date'], { orderBy: ['date'] }),
          boot: boot_config([]),
          combined: [
            {
              name: 'monthly_by_depot_depots',
              endpoint: '/persistance/default',
              type: 'graphql',
              graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date', 'depotId', 'depotName'], ['date', 'depot_id'], { combine: ['depot'] }),
              boot: boot_config(['date']),
              combined: [
                {
                  name: 'monthly_by_depot_refs',
                  endpoint: '/persistance/default',
                  type: 'graphql',
                  graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date', 'depotId', 'refId', 'refName'], ['date', 'depot_id', 'ref_id'], { combine: ['ref'] }),
                  boot: boot_config(['date', 'depot_id']),
                  table: {
                    hideHeader: true,
                    items: table_items(['empty', 'refId'], 2)
                  },
                  combined: [
                    {
                      name: 'monthly_by_depot_offices',
                      endpoint: '/persistance/default',
                      type: 'graphql',
                      graphql: graphql_config('aviaL2bMonthlyStatisticItems', ['date', 'officeId', 'depotId', 'refId', 'refName'], ['date', 'office_id', 'depot_id', 'ref_id'], {}),
                      boot: boot_config(['date', 'depot_id', 'ref_id']),
                      table: {
                        hideHeader: true,
                        items: table_items(['empty', 'officeId'], 3)
                      }
                    }
                  ]
                }
              ],
              table: {
                hideHeader: true,
                items: table_items(['empty', 'depotId'], 1)
              }
            }
          ],
          table: {
            hideHeader: true,
            class: 'avia_l2b__statistics_main',
            items: table_items(['date', 'gdsKey'], 0, 70)
          }
        }
      ],
      preload: true,
      table: {
        class: 'avia_l2b__statistics_mounthly',
        items: table_items(['date', 'gdsKey'], 0, 74)
      },
      actions: {
        panel: [
          {
            name: 'refresh_monthly_statistic',
            icon: 'el-icon-circle-check',
            type: 'primary',
            plain: true
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'date',
            operator: 'eq',
            type: 'daterange',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            default: ['the beginning of this month', 'the end of this month'],
            required: true
          },
          {
            name: 'gds_id',
            type: 'select',
            operator: 'eq',
            storeKey: 'gds',
            storePrimaryKey: 'id',
            storeLabelKey: 'gdsName'
          },
          {
            name: 'gds_key',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'office_id',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'depot_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          },
          {
            name: 'ref_id',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          }
        ]
      }
    }
  ]
}
