export default {
  name: 'aviaDefaultFareRules',
  exclude: ['actions', 'expand'],
  queries: {
    index: {
      id: true,
      priority: true,
      active: true,
      validatingSupplier: true,
      noShowTime: true,
      nonRefTax: true,
      refundableTax: true,
      void: true,
      voidSwitcher: true,
      bsp: true,
      gds: true
    }
  }
}