export default {
  namespace: 'insurance',
  component: 'controls',
  meta: {
    title: 'insurance.controls',
    icon: 'gear'
  },
  tabs: [
    {
      name: 'controls',
      items: [
        {
          name: 'controls_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'insuranceControls',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                settingType: true,
                config: true,
                depotId: true,
                createdAt: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              'settingType', 'config', 'depotId', 'createdAt', 'updatedAt',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Insurance::ControlsRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'depot_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true
              },
              {
                name: 'setting_type',
                type: 'text',
                operator: 'eq',
                camelizeLangKey: true
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'setting_type',
                type: 'text',
                required: true
              },
              {
                name: 'config',
                type: 'yamleditor',
                required: true
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                required: true
              }

            ]
          }
        }
      ]
    }
  ]
}
