export default {
  legend: 'fare_data',
  translateLegend: true,
  items: [
    {
      name: 'rbd',
      type: 'text',
      valueType: 'string',
      required: false
    },
    {
      name: 'fareBasis',
      type: 'text',
      valueType: 'string',
    },
    {
      name: 'fareCode',
      type: 'text',
      valueType: 'string',
      required: false
    },
  ]

}