<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:mmbLink="scope")
      a(class="el-button el-button--default el-button--mini is-plain el-icon-link" style="width: 100%;" title="MMB Link" :href="scope.row.mmbLink" target="_blank" v-if="scope.row.mmbLink")
    template(v-slot:comment="scope")
      div(v-if="scope.row.comment")
        div(v-html="previewComment(scope.row.comment)" style="word-wrap: break-word")
        div(v-if="(scope.row.comment.length > 30)")
          a(style="color:blue" @click="showComment(scope.row.comment)") {{translate('modal.show_more')}}
  block custom
    comment-dialog
</template>

<script>
  import BaseTable from '@crud_view/table'
  import CommentDialog from './comment_dialog'

  export default {
    components: {
      BaseTable,
      CommentDialog
    },
    extends: BaseTable,
    methods: {
      actionMarkAsNew() {
        this.updateStatus(0, 'alert.mark_as_new_confirm')
      },

      actionMarkAsAwaiting() {
        this.updateStatus(1, 'alert.mark_as_awaiting_confirm')
      },

      actionMarkAsSuccess() {
        this.updateStatus(2, 'alert.mark_as_success_confirm')
      },

      updateStatus(status, message) {
        this.$confirm(this.translate(message), this.$t('alert.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(async() => {
          try {
            const query = {
              __args: {
                ids: this.multipleSelection.map((i) => { return parseInt(i.id) }),
                status: status
              }
            }

            await this.graphql('mcoKiwiQueueIssueUpdateStatus', query, 'mutation')

            this.$message({
              message: 'Success!',
              type: 'success'
            })
            this.$elItem.$emitAction('refresh')
          } catch (e) {
            this.$message({
              message: e,
              type: 'error'
            })
          }
        }).catch((e) => {})
      },

      rowClassName({ row, rowIndex }) {
        switch(true) {
          case (row.status == 1):
            return 'warning-row'
          case (row.status == 2):
            return 'success-row'
          default:
            return ''
        }
      },

      previewComment(text) {
        if (text.length > 30) text = text.substring(0, 30) + '...'
        return text
      },

      showComment(comment) {
        this.$emit('showComment', comment.replace(/===>/g, '<font color=red>===></font>'))
      },

      expandRequestFilters(childConfig, row){
        switch(childConfig.name){
          case "kiwi_queue_issue_history":
            return {
              record_class: this.config.history[0].record_class,
              recordId: row.id
            }
          default:
            return {}
        }
      }
    }
  }
</script>