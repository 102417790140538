import engine from './engine'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

engine.router.router.beforeEach((to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      if (!to.query.redirect) {
        next({ path: '/' })
      } else {
        next({ path: decodeURIComponent(to.query.redirect) })
      }
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = engine.store.getters.roles && engine.store.getters.roles.length > 0 || engine.store.getters.rolesLoaded
      if (hasRoles) {
        next()
      } else {
        engine.store.dispatch('boot/getData').then((bData) => {
          engine.store.dispatch('permission/generateRoutes', bData.bootingData.roles).then((accessRoutes) => {
            for(let r of accessRoutes) {
              engine.router.router.addRoute(r)
            }
            next({ ...to, replace: true })
          }).catch((error) => {
            console.error(error)
            // remove token and go to login page to re-login
            engine.store.dispatch('user/resetToken')
            next({ path:'/login', query: { redirect: encodeURIComponent(to.fullPath) }})
            NProgress.done()
          })
        }).catch((error) => {
          console.error(error)
          // remove token and go to login page to re-login
          engine.store.dispatch('user/resetToken')
          next({ path:'/login', query: { redirect: encodeURIComponent(to.fullPath) }})
          NProgress.done()
        })
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next({ path:'/login', query: { redirect: encodeURIComponent(to.fullPath) }})
      NProgress.done()
    }
  }
})

engine.router.router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
