import { loadCrudModules } from '@/utils'

export default {
  name: 'insurance',
  path: '/insurance',
  alwaysShow: true,
  meta: {
    title: 'insurance.default',
    icon: 'gear'
  },
  children: loadCrudModules(require.context('./insurance', true, /\.js$/))
}
