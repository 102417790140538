export default {
  items: [
    'selection',
    'id',
    'priority',
    'active',
    {
      name: 'validatingSupplier',
      format: 'array_as_string'
    },
    {
      name: 'fareName',
      format: 'array_as_string'
    },
    {
      name: 'gdsId',
      format: 'array_as_string'
    },
    {
      name: 'departureFrom',
      format: 'array_as_string'
    },
    {
      name: 'arrivalTo',
      format: 'array_as_string'
    },
    'carrier',
    'flight',
    {
      name: 'actions',
      width: '70px',
      align: 'center'
    }
  ]
}