export default {
  name: 'settings_system_settings',
  meta: {
    title: 'settings_system_settings',
    icon: 'el-icon-location',
    permission: 'settings_system_settings'
  },
  lazy: true,
  items: [
    {
      name: 'settings_system_settings',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'systemSetting',
        queries: {
          index: {
           id: true,
            key: true,
            value: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'key',
            wibth: '30%'
          },
          {
            name: 'value'
          },
          // {
          //   name: 'createdAt'
          // },
          {
            name: 'updatedAt',
            wibth: '20%'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
         {
           name: 'update',
           icon: 'el-icon-edit',
           type: 'primary',
           plain: true
         }
       ],
       panel: [
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'TtnAviaMultiSearchService::SystemSetting'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'key',
            type: 'text',
            operator: 'like'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'key',
            type: 'text',
            required: true
          },
          {
            name: 'value',
            type: 'textarea',
            required: true,
            rows: 5
          }
        ]
      }
    }
  ]
}
