export default {
  site_projects: {
    table: {
      name: 'Name',
      projectToken: 'Token',
      active: 'Active',
      transformer: 'Data transformer',
      airlines: 'Airlines'
    },
    form: {
      fields: {
        name: 'Name',
        projectToken: 'Token',
        active: 'Active',
        startValueFormat: 'Value format',
        transformer: 'Data transformer',
        airlines: 'Airlines',
        modal: {
          startValueOverride: 'Start value',
          startUrl: 'Start URL'
        }
      },
      legends: {
        default: '',
        runParams: 'Launch parameters'
      }
    },
    action: {
      groups_list: 'List of project run groups'
    },
    modal: {
      run_project: 'Run project'
    }
  },
  site_runs: {
    table: {
      siteProjectId: 'Project',
      runToken: 'Token',
      searchType: 'Search type',
      supplier: 'Supplier',
      subGroup: 'Subgroup',
      passengersArray: 'Passengers',
      queuedTime: 'Queued, sec',
      runningTime: 'Running, sec',
      startUrl: 'Start url',
      startValue: 'Start value',
      data: 'Data',
      sourceData: 'Source data'
    },
    form: {
      fields: {
        siteProjectId: 'Site project',
        siteRunGroupId: 'Group',
        runToken: 'Token'
      }
    },
    modal: {
      view_results: 'View results'
    }
  },
  site_run_groups: {
    table: {
      currency: 'Currency',
      apiAgentId: 'Agent',
      name: 'Name',
      amount: 'Amount'
    },
    form: {
      fields: {
        id: 'ID',
        apiAgentId: 'Agent',
        name: 'Name'
      },
      legends: {
        default: '',
      }
    },
    action: {
      run_list: 'List of project runs'
    },
    modal: {
      view_results: 'Group info'
    }
  }
}
