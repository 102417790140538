<template lang="pug">
  extends /table
  //- block expand_combined
  //-   template(v-for="(exp, exkey) in config.combined")
  //-     component(:is="exp.name" :records="childRecords[scope.row.id] || []" :row="scope.row" :key="'sub_' + exkey")
  block expand
    div custom render parent
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,
  data() {
    return {
      childRecords: {}
    }
  },
  methods:{
    expandChange(row, expandedRows) {
      let isExpanded = expandedRows.some(r => r.id === row.id)
      setTimeout(() => {
        if (isExpanded) {
          if (!this.childRecords[row.id]) {
            // load from server
            this.childRecords[row.id] = [{ 'id': '1', 'schemeId': 1, 'refid': [122], 'forDirect': false, 'routeInclude': '', 'routeExclude': '', 'withCombination': true, 'airline': ['00'], 'airlineType': '0', 'airportToCity': false, 'active': true, 'updatedAt': '2021-05-18 15:46:49 +0000', 'markupRoundValue': '0', 'markupRoundType': '1', 'markupLossPosition': '1', 'markupBehavior': '0', 'maxMarkupPercent': '0' }]
          }
        } else {
          this.childRecords[row.id] = null
        }
        this.childRecords = { ...this.childRecords }
      }, 1000)
    }
  }
}
</script>
