<template lang="pug">
  div
    table(border="0" style="width: 100%")
      tr(style="background-color:#f5f7fa; height: 25px; color: #000")
        th
        template(v-for="fieldName in fieldsInTable")
          el-tooltip(placement="top")
            div(slot="content"
                v-html="tooltipContentFor(fieldName)")
            th(v-html="fields[fieldName].title")

      tr(v-for="currency in priceCalculation.currencies")
        td(:class="{ bold: currency == priceCalculation.payedCurrency }") {{ currency }}
        td(v-for="fieldName in fieldsInTable" :class="{ bold: currency == priceCalculation.payedCurrency }") {{ _.find(priceCalculation[fieldName], { currency: currency }).amount }}
</template>

<script>
export default {
  props: {
    booking: null
  },

  data() {
    return {
      fields: {
        'netto': {
          title: 'Netto',
          tooltip: 'Netto from gds'
        },
        'nettoCorrection': {
          title: 'Netto<br/>correction',
          tooltip: 'Difference between search and booking'
        },
        'profitMarkups': {
          title: 'Profit<br/>markups',
          tooltip: `Dynamic<sup>1</sup> + Markups<sup>2</sup> + Auction<sup>3</sup> + FF comm<sup>4</sup><br/><br/>
                   <sup>1</sup> dynamic from price_details<br/>
                   <sup>2</sup> rl[0] + df.rl[0] from price_details<br/>
                   <sup>3</sup> rl[5] + df.rl[5] from price_details<br/>
                   <sup>4</sup> rl[7] + df.rl[7] from price_details`
        },
        'paymentMarkups': {
          title: 'Payment<br/>markups',
          tooltip: `Discount - Promocode + Payment commission<sup>1</sup> + Transaction commission<sup>2</sup><br/><br/>
                   <sup>1</sup> from pricer table<br/>
                   <sup>2</sup> if present service pack or transaction commission`
        },
        'surchargeFlight': {
          title: 'Surcharge<br/>flight',
          tooltip: 'Mco surcharges with reasons: tarif_change, free_seats, chd_tarif, client_surcharge, pay_to_us, add_infant'
        },
        'surchargeAddons': {
          title: 'Surcharge<br/>add-ons',
          tooltip: 'Mco surcharges with reasons: meal_surcharge, baggage_surcharge, seat_surcharge, surcharge_free_text, additional_services'
        },
        'surchargePostsale': {
          title: 'Surcharge<br/>post sale',
          tooltip: 'Mco surcharges with reasons: pass_not_correct, docs_change'
        },
        'paymentMethodMarkup': {
          title: 'Payment<br/>method markup',
          tooltip: 'Payment commisssion without service pack'
        },
        'alternativeCurrencyMarkup': {
          title: 'Alternative<br/>currency markup',
          tooltip: `Service pack <sup>1</sup><br/><br/>
                    <sup>1</sup> from discount_info -> service_pack_cash_depot`
        },
        'flightTotal': {
          title: 'Flight total',
          tooltip: 'Transaction total - Bonus - Promocode + Transaction commission + Surcharge flight'
        },
        'upsale': {
          title: 'Upsale',
          tooltip: 'Non GDS additional services'
        },
        'ancillary': {
          title: 'Ancillary',
          tooltip: 'GDS additional services'
        },
        'insurance': {
          title: 'Insurance',
          tooltip: 'Insurance'
        },
        'diffMarkup': {
          title: 'Diff markup',
          tooltip: 'Additional discount'
        },
        'payedTotal': {
          title: 'Payed total',
          tooltip: 'Payment transaction'
        }
      },

      fieldsInTable: [
        'netto',
        'nettoCorrection',
        'profitMarkups',
        'paymentMarkups',
        'surchargeFlight',
        'surchargeAddons',
        'surchargePostsale',
        'paymentMethodMarkup',
        'alternativeCurrencyMarkup',
        'flightTotal',
        'upsale',
        'ancillary',
        'insurance',
        'diffMarkup',
        'payedTotal'
      ]
    }
  }, 
  
  computed:{
    priceCalculation() { return this.booking.priceCalculation },
    fieldCalculationDetails() {
      let data = JSON.parse(this.booking.priceCalculation.fieldCalculationDetails)
      data = this._.reduce(data, (result, value, key) => { result[this._.camelCase(key)] = value; return result }, {})
      let result = {}
      this._.each(this.fieldsInTable, (key) => { result[this._.camelCase(key)] = data[key] })
      return result
    }
  },

  methods: {
    tooltipContentFor(fieldName) {
      let html = `${this.fields[fieldName].tooltip}`

      if (this.fieldCalculationDetails[fieldName]) {
        html += `<br/><br/>${this.fieldCalculationDetails[fieldName]}`
      }
      return html
    }
  } 
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  table {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
  }

  table tr { background-color: #fff }
  table tr td { padding: 5px }
  table tr th { padding: 5px }
</style>