import backendUsers from './access/backend_users'

export default {
  namespace: 'backend',
  component: 'access',
  meta: {
    title: 'backend.access',
    icon: 'table'
  },
  tabs: [
    backendUsers
  ]
}
