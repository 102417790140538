export default {
  seats_block_settings: {
    form: {
      fields: {
        id: 'Id',
        name: 'Name',
        settingsType: 'Type',
        active: 'Active',
        options__validatingSupplier: 'Suppliers',
        options__contractId: 'Contracts',
        options__schedule__index: 'Index',
        options__schedule__seatsRange: 'Seats Range',
        options__schedule__departureIn: 'Departure In',
        options__schedule__time: 'Every',
        options__schedule__routes: 'Routes',
        options__schedule__flights: 'Flights',
        options__depotId: 'Depot ID',
        options__remainedSeatsOperator: 'Remained seats',
        options__schedule__minToDeparture: 'Min. to dep.',
        options__remainedSeats: '',
        options__departureIn: 'Period',
        options__departureDate: 'Departure',
        options__active: 'Active',
        options__schedules: 'Schedule',
        options__schemeId: 'Scheme ID',
        options__systemId: 'System ID',
      },
      options: {
        settingsType: {
          1: 'Check worker',
          'check_worker': 'Check worker',
          2: 'Pricer worker',
          'pricer_worker': 'Pricer worker',
        },
      },
      fieldsets: {
        schedule: 'Schedule',
        pricerWorkerFilters: 'Settings'
      },
      placeholder: {
        options__schedule__time: '10h / 30m',
        options__schedule__routes: 'Example: IEV-MUC,LWO-NYC',
        options__schedule__flights: 'Example: 712,520',
        options__departureIn: 'Example: *-*',
        options__schedules: 'Example: 7hh,15hh,21hh',
        options__schedule__minToDeparture: 'in hours'
      }
    },
    table: {
      name: 'Name',
      settingsType: 'Type',
      options: 'Options',
      active: 'Active',
    },
  }
}
