export default {
  data() {
    return {
      page: 1,
      limit: 25,
      total: 0,
      enabled: [],
      search: {
        value: null,
        field: null
      }
    }
  },

  mounted() {
    this.limit = parseInt(this.$route.query.limit) || this.config.paginationSize
    this.$elItem.$on(this.$channels.paginationParams, this.eventPaginationParams)
    this.$elItem.$on(this.$channels.tableSelectionChange, this.eventSelectionChange)
  },

  beforeDestroy() {
    this.$elItem.$off(this.$channels.paginationParams, this.eventPaginationParams)
    this.$elItem.$off(this.$channels.tableSelectionChange, this.eventSelectionChange)
  },

  computed: {
    showPaginationBlock() {
      return !this.config.isCombined || this.config.isCombined && this.config.boot.type !== 'local'
    },
    panelActions() {
      const actions = this.config.actions.panel.filter((i) => this.checkPermission(i.roles || []))
      if (this.config.isCombined && Object.keys(this.row).length > 0) {
        const params = {}
        for(let i in this.config.boot.relation) {
          params[i] = this.row[this.config.boot.relation[i]]
        }
        return [...actions].map((a) => {
          const ac = Object.assign({}, a)
          ac.params = Object.assign(ac.params || {}, params)
          return ac
        })
      } else {
        return actions
      }
    },
    paginationItems() {
      return this.$store.getters[`${this.storeName}/pagination`]
    },
    paginationLayout() {
      return this.config.paginationType === 'limit' ? 'total, sizes, prev, pager, next' : 'total, sizes, prev, pager, next'
    },
    searchByFields() {
      let searchFields = []
      if ( this.config.actions.searchBy ) {
        searchFields = this.config.actions.searchBy
        this.search.field = (searchFields.find((x) => { return x.selected }) || {}).name
      }
      return searchFields
    },
  },

  methods: {
    /* Actions & Events */
    actionClearFilter() {
      this.$elItem.$emit(this.$channels.filterClear)
    },
    actionApplyFilter() {
      this.$elItem.$emit(this.$channels.filterApply)
    },

    actionClearSearch() {
      this.search.field = null
      this.search.value = null
      this.actionApplyFilter()
    },

    eventPaginationParams(data) {
      this.total = data.total
      this.limit = data.limit
      this.$nextTick(() => {
        this.page = data.page
      })
    },

    eventPaginationPage(val) {
      const iPage = this.paginationItems.find((i) => i.number === val.toString())
      const cursor = iPage ? iPage.state : null
      this.$elItem.$emit(this.$channels.paginationPage, { page: val, cursor })
    },

    eventPaginationSize(val) {
      this.$elItem.$emit(this.$channels.paginationSize, val)
    },

    eventSelectionChange(val) {
      const enabled = []
      if (val) {
        this.panelActions.forEach((a) => {
          enabled.push(a.name)
        })
      }
      this.enabled = enabled
    },

    /* Other */
    isDisabled(action) {
      return !this.enabled.includes(action.name) && action.dependent === 'selection'
    }
  }
}
