<style scoped>
  .rs-dv{ padding-bottom: 5px;  }
  .rs-label{ font-weight: bold; padding-right: 10px; display: inline-block; width: 90px}
  .rs-value{}
  .rs-date{ width: 72px; display: inline-block; padding-right: 10px }
  .rs-tx-left{ text-align: right }
</style>

<template lang="pug">
  extends /table
  block expand
    div(class="rs-dv")
      span(class="rs-label") Route type:
      span(class="rs-value") {{ scope.row.routeTypes.map(x => from_options(x, {name: 'routeTypes'}, _self)).join(', ') }}
    div(class="rs-dv")
      span(class="rs-label") Flight type:
      span(class="rs-value") {{ from_options(scope.row.flightType, {name: 'flightType'}, _self) }}
    div(class="rs-dv")
      span(class="rs-label") Departure period:
      span(class="rs-value") {{ scope.row.departurePeriod}}
    div(class="rs-dv")
      span(class="rs-label") Timelimit:
      span(class="rs-value") {{ scope.row.timelimit}}
    div(class="rs-dv")
      span(class="rs-label") Drivers:
      span(class="rs-value") {{ array_as_string_options(scope.row.drivers, {name: 'drivers'}, _self) }}
    div(class="rs-dv")
      span(class="rs-label") Offices:
      span(class="rs-value") {{ array_as_string(scope.row.officeIds, {name: 'officeIds'}, _self) }}
    div(class="rs-dv")
      span(class="rs-label") Routes:
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")

  block custom
    change-settings-window
</template>

<script>
import formats from '@/utils/formats.js'
import BaseTable from '@crud_view/table'
import ChangeSettingsWindow from '../../components/change_settings_window'

export default {
  components: {
    BaseTable,
    ChangeSettingsWindow
  },
  extends: BaseTable,

  methods: {
    ...formats,
    formatExpandedRow(row) {
      var res = []
      var routes = row['routes'].split(';')
      routes.forEach(v => {
        res.push(v.replace('-', '<font color=red> -- </font>'))
      })
      return res.join('<br/>')
    },
    actionChangeSettings(params) {
      this.$emit('showChangeSettingsWindow', params.entity)
    }
  }
}
</script>
