<template lang="pug" src="./../templates/panel.pug" />

<script>
import common from './../../mixin/common'
import panel from './../../mixin/panel'
import di from './../../mixin/di'
import component from './../../mixin/component'
import formBase from './../../mixin/form/base'

export default {
  name: 'CrudPanel',
  mixins: [component, common, di, panel, formBase]
}
</script>
