export default {
  name: 'meta',
  items: [
    {
      name: 'report_position',
      endpoint: '/persistance/rankings',
      autoLoad: false,
      pagination: false,
      type: 'graphql',
      graphql: {
        name: 'reportPositionData',
        queries: {
          index: {
            airline: true,
            chart: true,
            route: true,
            items: true
          }
        },
        actions: {
          preload: 'commonRankingsPreload'
        },
        preloadFields: ['markets{id name}']
      },
      preload: true,
      preloadStores: ['markets'],
      table: {
        items: [
          'routeKey',
          'currency',
          'ttl',
          'expand'
        ]
      },
      actions: {
        row: [],
        panel: [
          {
            name: 'filter',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: '',
            items: [
              {
                name: 'market',
                operator: 'eq',
                type: 'select',
                storeKey: 'markets',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                default: 'UA',
                required: true
              },
              {
                name: 'routes',
                operator: 'eq',
                type: 'textarea',
                required: true,
                default: 'LHR-SIN'
              },
              {
                name: 'search_dates',
                operator: 'eq',
                type: 'daterange',
                required: true,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: ['2020-05-25', '2020-05-28']
              },
              {
                name: 'suppliers',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'by_airline',
                operator: 'eq',
                type: 'switch',
                default: false
              },
              {
                name: 'first_leg_dates',
                operator: 'eq',
                type: 'daterange',
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd'
              },
              {
                name: 'last_leg_interval',
                operator: 'eq',
                type: 'slider',
                range: true,
                showStops: true,
                max: 50,
                default: [0, 50]
              }
            ]
          }
        ]
      },
      form: {
        items: []
      }
    },
    {
      name: 'report_time',
      endpoint: '/persistance/rankings',
      autoLoad: false,
      pagination: false,
      type: 'graphql',
      graphql: {
        name: 'reportTimeData',
        queries: {
          index: {
            airline: true,
            date: true,
            route: true,
            items: true
          }
        },
        actions: {
          preload: 'commonRankingsPreload'
        },
        preloadFields: ['markets{id name}']
      },
      preload: true,
      preloadStores: ['markets'],
      table: {
        items: []
      },
      actions: {
        row: [],
        panel: [
          {
            name: 'filter',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: '',
            items: [
              {
                name: 'market',
                operator: 'eq',
                type: 'select',
                storeKey: 'markets',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                default: 'UA',
                required: true
              },
              {
                name: 'routes',
                operator: 'eq',
                type: 'textarea',
                required: true
              },
              {
                name: 'search_dates',
                operator: 'eq',
                type: 'daterange',
                required: true,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd'
              },
              {
                name: 'suppliers',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'by_airline',
                operator: 'eq',
                type: 'switch',
                default: false
              },
              {
                name: 'first_leg_dates',
                operator: 'eq',
                type: 'daterange',
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd'
              },
              {
                name: 'last_leg_interval',
                operator: 'eq',
                type: 'slider',
                range: true,
                showStops: true,
                max: 50,
                default: [0, 50]
              }
            ]
          }
        ]
      },
      form: {
        items: []
      }
    }
  ]
}
