<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  computed:{
    gdsStorePath() { return 'avia/l2b/l2b_reports/l2b_reports/preload' },
    gdsStore() {
      let result = {}
      for(let i of (this.$store.getters[this.gdsStorePath]['gds'] || [])) { result[i.id] = i }
      return result
    }
  },
  methods: {
    actionDownload(row) {
      if(row['handlerStatus'] != 1) {
        this.$message({
          message: this.translate('messages.download_error'),
          type: 'error'
        })
      } else{
        this.startDownload(row)
      }
    },
    async startDownload(row) {
      try {
        const query = {
          content: true,
          __args: {
            key: row['filePath']
          }
        }

        let result = await this.graphql('aviaL2bReportRuleFileContent', query, 'collection')

        if(result.content) {
          const blob = new Blob([result.content], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = row['filePath']
          link.click()
          URL.revokeObjectURL(link.href)
        } else{
          this.$message({
            message: this.translate('messages.download_empty_content'),
            type: 'error'
          })
        }

        return true
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 4:
          switch(row['handlerStatus']) {
            case 0:
              return { 'background-color': '#ced0dc6b' }
            case 1:
              return { 'background-color': '#2cf3606b' }
            case 2:
              return { 'background-color': '#f9d93a7a' }
            case 3:
              return { 'background-color': '#f93a3a7a' }
          }
      }

      return {}
    },
    formatExpandedRow(row) {
      return `<b>Period:</b>  ${row['searchOptions']['startDate']} - ${row['searchOptions']['endDate']}<br/>
      <b>Gds:</b>  ${row['searchOptions']['gdsIds'].map((k) => { return (this.gdsStore[k] ? this.gdsStore[k].gdsName : k) })} <br/>
      <b>Offices:</b>  ${row['searchOptions']['offices'].join(', ')} <br/>
      <b>Depots:</b>  ${row['searchOptions']['depots'].join(', ')} <br/>
      <b>Refs:</b>  ${row['searchOptions']['refs'].join(', ')} <br/>
      `
    }
  }
}
</script>
