import performedHandler from './actions/performed_handler'

export default {
  namespace: 'ttn_avia_vi',
  component: 'actions',
  meta: {
    title: 'ttn_avia_vi.actions',
    icon: 'el-icon-cpu'
  },
  tabs: [
    performedHandler
  ]
}
