<template>
  <table class="custom-table el-table__body el-table--border">
    <slot />
  </table>
</template>
<script>
export default {
}
</script>

<style lang="scss">
  table.custom-table {
    width: 100%;
    // tr.custom-table--row td {
    //   border-top: 1px solid #dfe6ec;
    //   border-left: 1px solid #dfe6ec;
    // }
  }
</style>
