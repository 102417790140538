<style scoped>
  .row{ padding-right: 20px }
  .row-small{ padding-left: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='5' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')")
        el-col(:span='4' class='row')
          ttn-input(v-model="temp['index']" v-bind="attr('index')" label-width='60px')
        el-col(:span='15')
          ttn-multiselect(v-model="temp['trafficSourceIds']" v-bind="attr('trafficSourceIds')" label-width='90px')

      ttn-textarea(v-model="temp['airlines']" v-bind="attr('airlines')")
      ttn-textarea(v-model="temp['routes']" v-bind="attr('routes')")

      el-row
        el-col(:span='12' class='row')
          ttn-select(v-model="temp['flightType']" v-bind="attr('flightType')")
        el-col(:span='12')
          ttn-select(v-model="temp['interline']" v-bind="attr('interline')" label-width='120px')

      el-row
        el-col(:span='16' class='row')
          ttn-multiselect(v-model="temp['rbdTypes']" v-bind="attr('rbdTypes')")
        el-col(:span='8')
          ttn-select(v-model="temp['rbdTypesFor']" v-bind="attr('rbdTypesFor')" label-width='60px')

      el-row
        el-col(:span='12' class='row')
          ttn-select(v-model="temp['passengerTypes']" v-bind="attr('passengerTypes')")
        el-col(:span='12')
          ttn-input(v-model="temp['minSegments']" v-bind="attr('minSegments')" label-width='120px')

      el-row
        el-col(:span='6')
          ttn-date-picker(v-model="temp['searchDateFrom']" v-bind="attr('searchDateFrom')" label-width='90px')
        el-col(:span='5' class='row-small')
          ttn-date-picker(v-model="temp['searchDateTo']" v-bind="attr('searchDateTo')" label-width='30px')
        el-col(:span='2')
          div &nbsp;
        el-col(:span='6')
          ttn-date-picker(v-model="temp['travelDateFrom']" v-bind="attr('travelDateFrom')" label-width='90px')
        el-col(:span='5' class='row-small')
          ttn-date-picker(v-model="temp['travelDateTo']" v-bind="attr('travelDateTo')" label-width='30px')

      el-row
        el-col(:span='6')
          ttn-input(v-model="temp['minPrice']" v-bind="attr('minPrice')" label-width='90px')
        el-col(:span='5' class='row-small')
          ttn-input(v-model="temp['maxPrice']" v-bind="attr('maxPrice')" label-width='30px')
        el-col(:span='2')
          div &nbsp;
        el-col(:span='6')
          ttn-input(v-model="temp['toDepartureHours']" v-bind="attr('toDepartureHours')" label-width='90px')
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  components: {
    BaseForm
  },
  extends: BaseForm
}
</script>
