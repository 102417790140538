import railway_providers_list from '@/settings/modules/railway/general/railway_providers_store'
export default {
  namespace: 'railway',
  component: 'rail_controls',
  meta: {
    title: 'railway.rail_controls',
    icon: 'gear'
  },
  tabs: [
    {
      name: 'rail_controls',
      items: [
        {
          name: 'rail_controls_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayRailControls',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                depotId: true,
                providerName: true,
                active: true,
                percent: true,
                depotType: true,
                countryCodes: true
              }
            }
          },
          
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              {
                name: 'depotId',
                format: 'from_boot',
                storeKey: 'depots_hash',
                storeLabelKey: 'name'
              }, 'providerName', {
                name: 'active',
                type: 'switch'
              }, 'percent', 'countryCodes',
              {
                name: 'actions',
                width: '120px',
                align: 'center'
              },
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                mutation: 'railway_rail_price_create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::RailControlRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'depot_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                scope: 'rail_controls'
              },
              {
                name: 'provider_name',
                type: 'text',
                operator: 'eq',
                camelizeLangKey: true,
                scope: 'rail_controls'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                required: true
              },
              {
                name: 'provider_name',
                type: 'select',
                store: railway_providers_list.objectByName(),
                valueType: 'string',
                required: true
              },
              {
                name: 'active',
                type: 'switch',
                default: false,
                required: true
              },
              {
                name: 'percent',
                type: 'number',
                default: 100,
                valueType: 'integer',
                required: true
              },
              {
                name: 'country_codes',
                type: 'yamleditor',
                required: false
              }
            ]
          }
        }
      ]
    },
    {
      name: 'planned_maintenances',
      items: [
        {
          name: 'planned_maintenance_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayPlannedMaintenances',
            exclude: ['selection', 'actions', 'expand'],
            queries: {
              planned_maintenance: {
                id: true,
                providerName: true,
                disabledStart: true,
                disabledUntil: true,
                disableReason: true
              }
            },
            actions: {
              index: 'railwayRailControlPlannedMaintenance',
              update: 'railwayRailControlUpdatePlannedMaintenance',
              clear_time: 'railwayRailControlClearPlannedMaintenance'
            }
          },
          stores: {
            disableReasons: {
              0: 'No reason',
              1: 'Provider offline',
              2: 'Technical problems'
            }
          },
          table: {
            items: [
              'selection',
              { name: 'id', width: '50px' },
              {
                name: 'providerName'
              }, {
                name: 'disabledStart',
                format: 'date_time'
              }, {
                name: 'disabledUntil',
                format: 'date_time'
              }, {
                name: 'disableReason',
                format: 'from_store',
                storeKey: 'disableReasons'
              },
              {
                name: 'actions',
                width: '120px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-watch',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'clear_time',
                icon: 'el-icon-cold-drink',
                type: 'success',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::RailControlRelation'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'provider_name',
                type: 'select',
                store: railway_providers_list.objectByName(),
                valueType: 'string',
                required: true
              },
              {
                name: 'disabled_start',
                type: 'text',
                required: true
              },
              {
                name: 'disabled_until',
                type: 'text',
                required: true
              },
              {
                name: 'disable_reason',
                type: 'select',
                store: {
                  0: 'No reason',
                  1: 'Provider offline',
                  2: 'Technical problems',
                  3: 'Disconnected by provider'
                },
                valueType: 'integer',
                required: true
              }
            ]
          }
        }
      ]
    }
  ]
}