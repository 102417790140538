<template lang="pug">
  div(:class="`base-table ${config.table.class}`")
    el-tabs(type="card")
      el-tab-pane(
        :label="translate('table.results')"
        class="tab-content-table"
        v-loading="loading"
      )
        div(v-if="records.length === 0 && Object.keys(sessions.all || {}).length > 0")
          div(class="blocked_error") Возможно направление заблокировано? Проверьте логи
          table(class="el-table el-table__body el-table--border")
            tr(class="el-table__row" v-for="(v, k) in (sessions.all || {})" :key="k")
              th(class="is-leaf")
                div(class="cell") {{k}}
              td(class="is-leaf")
                div(class="cell") {{v}}
        div(v-else)
          div(v-if="records.length > 0" class="search-info-block")
            span #[span(class="bold") {{translate('table.time')}}:] {{searchEndTime | moment("DD/MM/YYYY HH:mm:ss")}}&nbsp;&nbsp;
            span(v-if="trafficSource") #[span(class="bold") {{translate('table.trafficSource')}}:] {{trafficSource}}&nbsp;&nbsp;
            span(v-if="apiKey") #[span(class="bold") {{translate('table.apiKey')}}:] {{apiKey}}&nbsp;&nbsp;
            span(style="float: right;" v-if="searchTime") {{translate('table.searchTime', {time: searchTime})}}
          result-table(
            :records="records"
            :columns="tableColumns"
            :sessions="sessions"
            :configs="configs"
          )

      el-tab-pane(
        :label="translate('table.calculation')"
        class="tab-content-commercial"
        v-if="filterValue.report_type && filterValue.report_type.value === '1'"
      )
        el-form(
          :inline="true"
          :model="commercialForm"
          ref="commercialForm"
          :rules="commercialRules"
          label-width="200px"
        )
          el-row
            el-col(:span="24")
              el-form-item(prop="config" :label="translate('commercial.config')")
                el-input-number(v-model="commercialForm.config" :min="0")
              el-form-item(prop="acquiring" :label="translate('commercial.acquiring')")
                el-input-number(v-model="commercialForm.acquiring" :step="0.01")
              el-form-item
                el-switch(v-model="commercialForm.withEmpty" :active-text="translate('commercial.with_empty')")
              el-button(type="primary" :loading="loading" @click="commercialSubmit") {{translate('commercial.submit')}}
        result-table(
          :records="commercialTable.data"
          :columns="commercialTable.headers"
          :summary="commercialTable.summary"
          :sessions="sessions"
          :configs="configs"
        )
</template>

<script>
import BaseTable from '@crud_view/table'
import ResultTable from './result-table.vue'

export default {
  components: {
    ResultTable
  },
  extends: BaseTable,
  data() {
    const __self = this
    return {
      commercialForm: {
        config: '',
        acquiring: 1.35,
        withEmpty: true
      },
      commercialRules: {
        config: [{ required: true, message: __self.$elItem.translate('form.required'), trigger: 'blur' }],
        acquiring: [{ required: true, message: __self.$elItem.translate('form.required'), trigger: 'blur' }]
      },
      commercialTable: {
        headers: [],
        data: [],
        summary: []
      },
      searchEndTime: new Date()
    }
  },
  computed: {
    firstListRow() {
      const list = this.$store.getters[`${this.storeName}/list`]
      return list.length > 0 ? list[0] : {}
    },

    records() {
      return this.firstListRow.table ? this.firstListRow.table.data : []
    },
    tableColumns() {
      return this.firstListRow.table ? this.firstListRow.table.headers : []
    },
    sessions() {
      return this.firstListRow.sessions || {}
    },
    configs() {
      return this.firstListRow.configs || []
    },
    searchCacheKey() {
      return this.firstListRow.cacheKey
    },
    trafficSource() {
      return this.firstListRow.trafficSource
    },
    apiKey() {
      return this.firstListRow.apiKey
    },
    searchTime() {
      return this.firstListRow.time
    }
  },
  methods: {
    async initBeforeListCallbacks() {
      this.commercialTable = {
        headers: [],
        data: [],
        summary: []
      }
    },

    async commercialSubmit() {
      this.$refs.commercialForm.validate(async(valid) => {
        if (valid) {
          try {
            this.loading = true
            this.commercialForm.cacheKey = this.searchCacheKey
            const query = {
              __args: this.commercialForm,
              collection: {
                table: true
              }
            }
            const res = await this.graphql(this.config.graphql.actions.commercial, query, 'query')
            this.commercialTable = res.collection[0].table
          } catch (e) {
            this.$message({
              message: e,
              type: 'error'
            })
          }
          this.$nextTick(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .thoughts_of_sales_table {
    .el-table--mini {
      // .el-table__body-wrapper
      .el-table__cell {
        &.my-cell {
          padding: 0;
          .cell {
            padding: 0;
            .my-row, .header-content {
              padding: 8px;
            }

            .my-row {
              display: flex;
              justify-content: stretch;
              line-height: 32px;
              padding: 0 0 0 10px;
            }
            .my-flex-col {
              display: flex;
              flex: auto;
              flex-direction: column;
            }
            .my-flex-row {
              display: flex;
              flex: auto;
              flex-direction: row;
              justify-content: right;
              &.search-link {
                justify-content: center;
              }
            }

            .header-label span {
              float: right;
            }

            .current_config {
              background: #dddffd;
            }

            .header_minmax {
              line-height: 14px;
              font-size: 11px;
              span {
                display: inline-block;
                &.s_last {
                  float: right;
                }
              }
            }
          }
        }
      }

      .el-table__footer .cell {
        padding: 10px 0;
        padding-left: 10px;
      }

      .header-search-flex {
        display: flex;
        flex-direction: row;
        .header-search-flex {
          flex: 1 1;
          align-self: center;
        }
      }
    }

    .int_icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: #f11717;
      &.el-icon-connection {
        color: darkgreen;
      }
    }

    a.search-link {
      text-decoration: none;
      color: #67696c;
      padding: 2px;
      border: 1px solid #a5a6a994;
      border-radius: 5px;
      height: 16px;;
      &:hover {
        background: #e0e0e0;
      }
    }

    .tab-content-commercial {
      .el-form--inline {
        text-align: center;
      }
    }

    .blocked_error {
      padding: 10px 0;
      margin-bottom: 10px;
      color: #dc3545;
      text-align: center;
    }

    .search-info-block {
      font-size: 14px;
      color: #606266;
      padding-bottom: 10px;
    }

    .bold {
      font-weight: bold;
    }
  }
</style>
