<template lang="pug">
  div
    div(style="padding: 10px 0")
      span Pricer code: {{ booking.priceDetailsCode }}
      span(v-html="fillEmptySpace(10)")
      span Extra meta revenue: {{ bookingInformation.extraMetaRevenue }} / {{ `${priceInDepotCurrency()}${depotCurrency}` }}
      span(v-html="fillEmptySpace(10)")
      span Wrong price extra comm.: {{ `${~~additionalInfo.wrong_rrice_was_changed}${depotCurrency}` }}
      span(v-html="fillEmptySpace(10)")
      span.bold Change price after pricer: {{ comparePriceInfo() }}
      span(v-html="fillEmptySpace(10)")
      span FF commission: {{ ffCommission() }}

    table(border="0")
      tr(style="background-color:#f5f7fa; height: 25px; color: #000")
        th(width="100") Start price
        th(width="100" v-for="(title, k) in priceDetailsComponents" ) {{ title }}
        th(width="80") Commission discount
        th(width="80") Meta markup
        th(width="80") Rate markup
        th(width="80") Revenue markup
        th(width="80") Dynamic
        th(width="80") Selling price
    
      template(v-for="(prdo, i) in priceDetails")
        template(v-for="(prdi, j) in dataToLoop(prdo, i).itemsToLoop")
          tr(:style="bgColor(prdi[1])" :set="prd = prdi[0]" :updatePrd="updatePrd(dataToLoop(prdo, i), prdi[0], i, j)")
            td {{ prd.st_p }}
            td(v-for="(title, k) in priceDetailsComponents") {{ _.get(prd, ['rl', k, 'm'], '-') }}
            td {{ `-${prd.bsp_c}` }}
            td {{ prd.meta }}
            td {{ prd.rate_margin }}
            td {{ prd.revenue_margin }}
            td {{ _.get(prd, 'dynamic', '-') }}
            td {{ prd.sl_p }}

            tr(style="font-size: 11px")
              td Sch ID
              td(v-for="(title, k) in priceDetailsComponents" ) {{ _.get(prd, ['rl', k, 'shid'], '-') }}
              td(v-for="x in Array(6)") -

            tr(style="font-size: 11px")
              td Rule ID
              td(v-for="(title, k) in priceDetailsComponents" ) {{ _.get(prd, ['rl', k, 'rid'], '-') }}
              td(v-for="x in Array(6)") -

            tr(style="font-size: 11px")
              td
              td(v-for="(title, k) in priceDetailsComponents" )
                a(:href="'https://j.tickets.ua/browse/'+_.get(prd, ['rl', k, 'tks'])" target='_blank' v-if="_.get(prd, ['rl', k, 'tks'], false)") {{ _.get(prd, ['rl', k, 'tks']) }}
                span(v-else) -
              td(v-for="x in Array(6)") -

        tr(style="background-color: #dcc8fa" :set="totalPricesDetails = dataToLoop(prdo, i).totalPricesDetails")
          td {{ totalPricesDetails.st_p }}
          td(v-for="(title, k) in priceDetailsComponents" ) {{ _.get(totalPricesDetails, ['rl', k, 'm'], '-') }}
          td {{ `-${totalPricesDetails.bsp_c}` }}
          td {{ totalPricesDetails.meta }}
          td {{ totalPricesDetails.rate_margin }}
          td {{ totalPricesDetails.revenue_margin }}
          td {{ _.get(totalPricesDetails, ['dynamic'], '-') }}
          td {{ totalPricesDetails.sl_p }}

    span(@click="showPricerLog(0)").bold.pointer Booking price log
    span(v-html="fillEmptySpace(5)")
    span(@click="showPricerLog(1)").bold.pointer Full price log
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'
export default {
  mixins: [common, di],
  props: {
    booking: null
  },

  data() {
    return {
      priceDetailsComponents: {
        0: 'Markups',
        1: 'Insentive markup',
        2: 'Consolid. markup',
        4: 'Segments discount',
        6: 'Rebook markup',
        5: 'Auction',
        7: 'FF comm.',
        3: 'Payment comm.',
        20: 'Restrictions'        
      }
    }
  }, 
  
  computed:{
    finalAllPrice() { return JSON.parse(this.booking.finalAllPrice) },
    additionalInfo() { return JSON.parse(this.booking.additionalInfo) },
    priceDetails() { return JSON.parse(this.booking.bookingInformations.priceDetails) },
    depotCurrency() { return this.booking.depotCurrency },
    bookingInformation() { return this.booking.bookingInformations }
  },

  methods: {
    fillEmptySpace(count) {
      return Array(count).fill('&nbsp;', 0).join('')
    },

    priceInDepotCurrency() {
      return this.finalAllPrice[this.depotCurrency] * this.bookingInformation.extraMetaRevenue / 100.0
    },

    comparePriceInfo() {
      if (this.additionalInfo.compare_price_info) {
        return `${~~this.additionalInfo.compare_price_info.real_diff}${this.depotCurrency} / Rule ID: ${this.additionalInfo.compare_price_info.rule_id}`
      } else {
        return `0${this.depotCurrency}`
      }
    },

    ffCommission() {
      if (this.additionalInfo.ff_commission) {
        return `${Array(this.additionalInfo.ff_commission).flat().reduce((a, b) => Number(a) + Number(b), 0)}${this.depotCurrency}`
      } else {
        return `0${this.depotCurrency}`
      }
    },

    dataToLoop(prdo, i) {
      let itemsToLoop = [[prdo, '#dcc8fa']]
      let hasDefault = false
      let totalPricesDetails = prdo

      if ('df' in prdo) {
        itemsToLoop.unshift([prdo.df, '#d9ffcc'])
        hasDefault = true
        if (i == 0) { totalPricesDetails = prdo.df }
      }

      return { itemsToLoop: itemsToLoop, hasDefault: hasDefault, totalPricesDetails: totalPricesDetails }
    },

    bgColor(color) {
      return `background-color: ${color}`
    },

    updatePrd(dataToLoop, prd, i, j) {
      let totalPricesDetails = dataToLoop.totalPricesDetails
      let hasDefault = dataToLoop.hasDefault

      if (i == 0 && j == 1) { totalPricesDetails.sl_p = 0 }

      if (!(i == 0 && j == 0)) {
        this._.forEach(prd, (prdv, prdk) => {
          if (prdk == 'rl' && prd.rl) {
            this._.forEach(prd.rl, (prdrlv, prdrlk) => {
              if (!this._.get(totalPricesDetails, ['rl', prdrlk], undefined)) {
                totalPricesDetails.rl[prdrlk] = prdrlv
              } else {
                totalPricesDetails.rl[prdrlk].m = Number(totalPricesDetails.rl[prdrlk].m) + Number(prdrlv.m)
              }
            })
          } else {
            if (!totalPricesDetails.prdk || (prdk == 'df') || (prdk == 'st_p' && j == 1) || (prdk == 'sl_p' && hasDefault && j == 0)) {

            } else {
              totalPricesDetails.prdk = Number(totalPricesDetails.prdk) + Number(prdv)
            }
          }
        })
      }
    },

    showPricerLog(type) {
      console.log(type) // TODO
    }
  } 
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  table {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
    width: 100%;
  }

  table tr { background-color: #fff }
  table tr td { padding: 5px }
  table tr th { padding: 5px }
</style>