export default {
  name: 'thoughts_of_sales',
  items: [
    {
      name: 'search',
      endpoint: '/persistance/default',
      pagination: false,
      autoLoad: true,
      type: 'graphql',
      graphql: {
        name: 'systemToolsThoughtsOfSalesSearch',
        queries: {
          index: {
            table: true,
            apiKey: true,
            sessions: true,
            configs: true,
            cacheKey: true,
            trafficSource: true,
            time: true
          }
        },
        actions: {
          index: 'systemToolsThoughtsOfSalesSearchResult',
          commercial: 'systemToolsThoughtsOfSalesSearchCommercial'
        },
        preloadFields: ['sites{key name}']
      },
      preload: true,
      table: {
        class: 'thoughts_of_sales_table',
        items: []
      },
      actions: {
        row: [],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          }
        ]
      },
      filter: {
        position: 'top',
        fieldsets: [
          {
            legend: 'main',
            scope: 'STTOSSR',
            collapsible: false,
            items: [
              {
                name: 'adt',
                operator: 'eq',
                type: 'inputnumber',
                default: 1,
                required: true
              },
              {
                name: 'chd',
                operator: 'eq',
                type: 'inputnumber',
                default: 0
              },
              {
                name: 'inf',
                operator: 'eq',
                type: 'inputnumber',
                default: 0
              },
              {
                name: 'domain',
                operator: 'eq',
                type: 'select',
                storeKey: 'sites',
                storeLabelKeys: ['name'],
                storePrimaryKey: 'key'
              },
              {
                name: 'site',
                operator: 'eq',
                type: 'hidden',
                required: true
              },
              {
                name: 'key',
                operator: 'eq',
                type: 'input',
                required: true
              },
              {
                name: 'refid',
                operator: 'eq',
                type: 'inputnumber'
              },
              {
                name: 'departure',
                operator: 'eq',
                type: 'input',
                required: true
              },
              {
                name: 'arrival',
                operator: 'eq',
                type: 'input',
                required: true
              },
              {
                name: 'departure_date',
                operator: 'eq',
                type: 'date',
                required: true,
                valueFormat: 'yyyy-MM-dd'
              },
              {
                name: 'arrival_date',
                operator: 'eq',
                type: 'date',
                valueFormat: 'yyyy-MM-dd'
              },
              {
                name: 'airline',
                operator: 'eq',
                type: 'input'
              },
              {
                name: 'class',
                operator: 'eq',
                type: 'select',
                store: {
                  'A': 'Любой',
                  'B': 'Бизнес',
                  'E': 'Эконом'
                },
                default: 'A'
              },
              {
                name: 'with_link',
                operator: 'eq',
                type: 'switch',
                default: false
              },
              {
                name: 'report_type',
                operator: 'eq',
                type: 'radiogroup',
                store: {
                  '0': 'Sales Performance Office',
                  '1': 'Commercial Office'
                },
                default: '0'
              },
              {
                name: 'server_scheme',
                operator: 'eq',
                type: 'select',
                store: {
                  'http': 'http://',
                  'https': 'https://'
                },
                default: 'http',
                required: true
              },
              {
                name: 'server_domain',
                operator: 'eq',
                type: 'input',
                default: 'coreproxy.tickets.com.ua:8100',
                required: true
              },
              {
                name: 'search_type',
                operator: 'eq',
                type: 'radiogroup',
                store: {
                  '2': 'По каждому конфигу',
                  '1': 'Обычный поиск'
                },
                default: '2'
              },
              {
                name: 'configs',
                operator: 'eq',
                type: 'input'
              }
            ]
          }
        ]
      },
      form: {
        items: []
      }
    }
  ]
}
