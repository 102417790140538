export default {
  name: 'l2b_reports',
  meta: {
    title: 'Reports',
    icon: 'skill',
    permission: 'pesimisation_rules'
  },
  lazy: true,
  items: [
    {
      name: 'l2b_reports',
      endpoint: '/persistance/default',
      type: 'graphql',
      preload: true,
      graphql: {
        name: 'aviaL2bReportRules',
        queries: {
          index: {
            id: true,
            name: true,
            handlerStatus: true,
            filePath: true,
            searchOptions: {
              startDate: true,
              endDate: true,
              gdsIds: true,
              offices: true,
              depots: true,
              refs: true
            },
            groupOptions: true,
            reportType: true,
            createdAt: true,
            updatedAt: true
          }
        },
        preloadFields: ['gds {id gdsName maxValue}']
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '40px'
          },
          {
            name: 'name'
          },
          {
            name: 'reportType',
            format: 'from_options'
          },
          {
            name: 'handlerStatus',
            format: 'from_options'
          },
          {
            name: 'filePath'
          },
          {
            name: 'groupOptions'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          // {
          //   name: 'update',
          //   icon: 'el-icon-edit',
          //   type: 'primary',
          //   plain: true
          // },
          {
            name: 'download',
            icon: 'el-icon-download',
            type: 'success',
            plain: true,
            roles: false
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::L2b::ReportRelation'
            }
          }
          // {
          //   name: 'filter',
          //   context: 'panel',
          //   icon: 'el-icon-help',
          //   plain: true
          // }
        ]
      },
      // filter: {
      //   items: [
      //     {
      //       name: 'id',
      //       type: 'text'
      //     },
      //     {
      //       name: 'active',
      //       type: 'radiogroup',
      //       button: true,
      //       operator: 'eq',
      //       store: {
      //         '': 'Any',
      //         true: 'yes',
      //         false: 'no'
      //       }
      //     },
      //     {
      //       name: 'gds_id',
      //       type: 'select',
      //       operator: 'eq',
      //       storeKey: 'gds',
      //       storePrimaryKey: 'id',
      //       storeLabelKey: 'gdsName'
      //     }
      //   ]
      // },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'General',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'report_type',
                type: 'select',
                store: {
                  0: 'l2b_report',
                  1: 'sales_report'
                },
                translateOptions: true,
                default: '0',
                required: true,
                valueType: 'integer'
              },
              {
                name: 'group_options',
                type: 'multiselect',
                store: {
                  date: 'date',
                  gds_id: 'gds_id',
                  office_id: 'office_id',
                  depot_id: 'depot_id',
                  ref_id: 'ref_id'
                },
                default: ['date', 'gds_id']
              }
            ]
          },
          {
            legend: 'settings',
            translateLegend: true,
            items: [
              {
                name: 'searchOptions__startDate',
                type: 'date',
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: 'the beginning of this month',
                required: true
              },
              {
                name: 'searchOptions__endDate',
                type: 'date',
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: 'the end of this month',
                required: true
              },
              {
                name: 'searchOptions__gdsIds',
                type: 'multiselect',
                storeKey: 'gds',
                storePrimaryKey: 'id',
                storeLabelKey: 'gdsName',
                required: false
              },
              {
                name: 'searchOptions__offices',
                type: 'text',
                required: false,
                valueType: 'array_of_string'
              },
              {
                name: 'searchOptions__depots',
                type: 'text',
                required: false,
                valueType: 'array_of_integer'
              },
              {
                name: 'searchOptions__refs',
                type: 'text',
                required: false,
                valueType: 'array_of_integer'
              }
            ]
          }
        ]
      }
    }
  ]
}
