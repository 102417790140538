import docTypeRules from './frontend/settings/doc_type_rules'
import refidConfigs from './frontend/settings/refid_configs'
import config from './frontend/settings/config'

export default {
  frontend: {
    settings: {
      doc_type_rules: docTypeRules,
      refid_configs: refidConfigs,
      settings_config: config
    }
  }
}
