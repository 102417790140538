import request from '@/utils/request'
import settings from '@/settings'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'

export function getData() {
  return request({
    url: settings.boot.enpoint,
    method: 'post',
    data: {
      query: `{${jsonToGraphQLQuery(settings.boot.query)}}`
    }
  })
}
