import markupAnalysis from './avia/markup_analysis'
import thoughtsOfSales from './avia/thoughts_of_sales'
import conversionAnalysis from './avia/conversion_analysis'

export default {
  namespace: 'system_tools',
  component: 'avia',
  meta: {
    title: 'system_tools.avia',
    icon: 'tree-table'
  },
  tabs: [
    thoughtsOfSales,
    markupAnalysis,
    conversionAnalysis
  ]
}
