export default {
  pricer_rules: {
    table: {
      id: 'ID',
      ruleIndex: 'Rule index',
      airlines: 'Airlines',
      officeId: 'Office',
      driverId: 'Driver',
      routes: 'Routes',
      markups: 'Markups',
      active: 'Active'
    },
    form: {
      fields: {
        id: 'ID',
        ruleIndex: 'Rule index',
        airlines: 'Airlines',
        officeId: 'Office',
        driverId: 'Driver',
        routes: 'Routes',
        markups: 'Markups',
        active: 'Active',
        markups__paxType: 'Pax',
        markups__value: 'Value',
        markups__percentValue: 'Percent',
        markups__percentFrom: ':',
        markups__daysRange: 'Days range',
      },
      placeholder: {
        ruleIndex: '0 (sort from 0)',
        airlines: 'PS,LO or 00 for all',
        officeId: '5K1G,LWOU228AE or empty',
        routes: 'WAW-PAR,LON;UA-PL;DE-*;*-LIS,GB or *-*',
        markups__daysRange: '0-15',
        markups__value: '0.5EUR'
      },
      legends: {
        markups: 'Markups'
      }
    }
  }
}
