<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
    )
    yaml-editor( v-model="dataValue" v-on="$listeners" v-bind="attribs(field, fieldset)" v-on:opened="opened")

</template>
<script>
import YamlEditor from '@/components/YamlEditor/index.vue'
import formElements from './../../../mixin/form/elements'
const yamlData = 'test: true\n'
export default {
  name: 'TtnYamleditor',
  components: { YamlEditor },
  mixins: [formElements],
  props: {
    value: {
      type: String,
      default: yamlData
    }
  },
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        plus: true,
        options: field.options || {}
      }
    }
  }
}
</script>