import engine from '@/engine'

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(value) {
  if (value && value instanceof Array) {
    if (value.length > 0 && process.env.ENABLE_PERMISSIONS) {
      const roles = engine.store.getters && engine.store.getters.roles
      const permissionRoles = value

      const hasPermission = roles.includes('admin') || roles.some(role => {
        return permissionRoles.includes(role)
      })

      if (!hasPermission) {
        return false
      }
    }
    return true
  } else {
    console.error(`need roles! Like v-permission="['admin','editor']"`)
    return false
  }
}
