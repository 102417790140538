<template lang="pug" src="./../templates/form.pug" />

<script>
import common from './../../mixin/common'
import form from './../../mixin/form'
import formBase from './../../mixin/form/base'
import di from './../../mixin/di'
import component from './../../mixin/component'

export default {
  name: 'CrudCForm',
  mixins: [common, di, formBase, form, component],
  props: {
    config: {
      required: true,
      type: Object
    }
  }
}
</script>
