export default {
  name: 'settings_search',
  meta: {
    title: 'settings_search',
    icon: 'skill',
    permission: 'settings_search'
  },
  lazy: true,
  items: [
    {
      name: 'settings_search',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'settingsSearcheRules',
        queries: {
          index: {
            id: true,
            index: true,
            key: true,
            departures: true,
            arrivals: true,
            countries: true,
            active: true,
            daysRange: true,
            maxPrice: true,
            flightDuration: true,
            minAirportConnTime: true,
            minCityConnTime: true,
            maxConnTime: true,
            priceComponentsCount: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: '_index',
            width: '70px'
          },
          {
            name: 'key',
            width: '150px'
          },
          {
            name: 'departures',
            format: 'array_as_string',
            readMore: true,
            readMoreChars: 25
            // width: '150px'
          },
          {
            name: 'arrivals',
            format: 'array_as_string',
            readMore: true,
            readMoreChars: 25
            // width: '150px'
          },
          {
            name: 'days_range',
            width: '70px'
          },
          {
            name: 'flight_duration',
            width: '70px'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '90px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'TtnAviaMultiSearchService::Settings::SearchRule'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'departures',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'arrivals',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'countries',
            type: 'text',
            operator: 'in_array_str'
          },
          // {
          //   name: 'active',
          //   type: 'radiogroup',
          //   button: true,
          //   default: 'all',
          //   operator: 'eq',
          //   store: {
          //     'all': 'All',
          //     true: 'Yes',
          //     false: 'No'
          //   }
          // }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'index',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'key',
            type: 'text',
            required: true
          },
          {
            name: 'days_range',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'departures',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 5
          },
          {
            name: 'arrivals',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 5
          },
          {
            name: 'countries',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 5
          },
          {
            name: 'max_price',
            type: 'text',
            required: true,
            valueType: 'integer',
            default: 500
          },
          {
            name: 'flight_duration',
            type: 'text',
            required: true,
            valueType: 'integer',
            default: 500
          },
          {
            name: 'min_airport_conn_time',
            type: 'text',
            required: true,
            valueType: 'integer',
            default: 40
          },
          {
            name: 'min_city_conn_time',
            type: 'text',
            required: true,
            valueType: 'integer',
            default: 90
          },
          {
            name: 'max_conn_time',
            type: 'text',
            required: true,
            valueType: 'integer',
            default: 900
          },
          {
            name: 'price_components_count',
            type: 'text',
            required: true,
            default: '2-3'
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          }
        ]
      }
    }
  ]
}
