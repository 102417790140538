import restrictions from './google_flights/restrictions'
import feed_settings from './google_flights/feed_settings'

export default {
  namespace: 'avia',
  component: 'google_flights',
  meta: {
    title: 'avia.google_flights',
    icon: 'google'
  },
  tabs: [
    restrictions,
    feed_settings
  ]
}
