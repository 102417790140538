<template lang=pug>
   el-dialog(:title="translate('cloneWindow.title')" :visible.sync="dialogFormVisible")
      el-form(:model="form")
        el-form-item(:label="translate('cloneWindow.form.availableSeats')" :label-width="formLabelWidth")
          el-input(v-model="form.availableSeats" width="200px")

        div(v-for="(tripPart, index) in form.tripParts")
          el-divider
          span(class="ch-tp-title") {{ translate(`cloneWindow.trip_part_name.${index}`) }}
          el-row
            el-col(:span='12')
              el-form-item(:label="translate('cloneWindow.form.from')" label-width="60px")
                el-date-picker(v-model="tripPart.from" type="date")
            el-col(:span='12')
              el-form-item(:label="translate('cloneWindow.form.to')" label-width="60px")
                el-date-picker(v-model="tripPart.to" type="date")

          div(class='ch-gr-padding')
            el-checkbox-group(v-model="tripPart.week_days")
              el-checkbox(v-for="dayIndex in weekDays" :label="dayIndex" :key="dayIndex") {{translate(`cloneWindow.form.weekDays.${dayIndex}`)}}

      span(slot="footer" class="dialog-footer" v-loading="changeLoading")
        el-button(@click="dialogFormVisible = false" type="danger") {{ translate('form.cancel') }}
        el-button(@click="actionPerform" type="primary" :disabled="changeLoading") {{ translate('form.confirm') }}
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  computed:{
    paxTypes(){ return ['ADT', 'CHD', 'INF'] },
    faresFields(){ return ['fare', 'taxes', 'total', 'fare_code', 'baggage'] },
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    }
  },
  data: function() {
    return {
      editRow: {},
      dialogFormVisible: false,
      form: {
        id: null,
        availableSeats: null,
        tripParts: []
      },
      changeLoading: false,
      formLabelWidth: '120px',
      weekDays: [1, 2, 3, 4, 5, 6, 0]
    }
  },

  mounted() {
    this.$parent.$on('showCloneWindow', (row) => {
      this.editRow = row
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showCloneWindow')
  },

  methods: {
    reset() {
      this.changeLoading = false
      this.form.id = this.editRow.id
      this.form.availableSeats = this.editRow.availableSeats
      let tripParts = []
      for(let segment of this.editRow.flights){
        if(!tripParts[segment.leg_id] && segment.segment_index == 0){
          tripParts[segment.leg_id] = {
            week_days: this.weekDays,
            from: new Date(this.$moment(segment.departure_time, 'YYYY-MM-DD hh:mm:ss').add(1, 'days').format()),
            to: new Date(this.$moment(segment.departure_time, 'YYYY-MM-DD hh:mm:ss').add(8, 'days').format())
          }
        }
      }

      this.form.tripParts = tripParts
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    actionPerform() {
      if(this.changeLoading){ return }
      this.changeLoading = true
      this.performRequest(this.form)
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            id: parseInt(form.id),
            availableSeats: parseInt(form.availableSeats),
            tripParts: form.tripParts.map((tp) => {
              tp.from = this.$moment(tp.from).format('YYYY-MM-DD')
              tp.to = this.$moment(tp.to).format('YYYY-MM-DD')
              return tp
            })
          }
        }

        await this.graphql('aviaSeatsBlockRuleDuplicate', query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('cloneWindow.msg.title'),
          message: this.translate('cloneWindow.msg.success'),
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('cloneWindow.msg.title'),
          message: this.translate('cloneWindow.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
      this.$elItem.$emitAction('refresh')
    }

  }
}
</script>
