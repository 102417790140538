const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.boot.roles,
  legacyRoles: state => state.boot.legacyRoles,
  rolesLoaded: state => state.boot.isLoaded,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  bootData: state => state.boot,
  permissions: state => state.permission.permissions
}
export default getters
