export default {
  changeSettingsWindow: {
    title: 'Change settings',
    description: 'Description: config entity - ',
    msg: {
      title: 'Message',
      load_error: 'Settings not loaded',
      for_error_fields: 'Errors in fields: ',
      success_config: 'Config changed'
    },
    form: {
      google_flights: {
        market: {
          depot_id: 'DepotID',
          traffic_source_id: 'Tr source ID',
          storage_key: 'Storage key',
          file_prefix: 'File prefix',
        },
        feed_markets: {
          traffic_source_id: 'Tr source ID',
          storage_key: 'Storage key',
          active: 'Active',
        },
        storages:{
          key: 'Key',
          protocol: 'Protocol',
          host: 'Host',
          port: 'Port',
          username: 'Usrname',
          password: 'Password',
          file_prefix: 'File prefix',
        },
        fieldset: {
          markets: 'Markets',
          feed_markets: 'Feed markets',
          storages: 'Storages'
        }
      }
    }
  }
}
