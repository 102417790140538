export default {
  'AE': 'AE',
  'AT': 'AT',
  'AU': 'AU',
  'AZ': 'AZ',
  'BY': 'BY',
  'DE': 'DE',
  'EE': 'EE',
  'ES': 'ES',
  'FR': 'FR',
  'IL': 'IL',
  'IT': 'IT',
  'KZ': 'KZ',
  'MD': 'MD',
  'MY': 'MY',
  'NL': 'NL',
  'PE': 'PE',
  'PL': 'PL',
  'PY': 'PY',
  'RO': 'RO',
  'RU': 'RU',
  'TR': 'TR',
  'UA': 'UA',
  'US': 'US'
}