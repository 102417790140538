import common from './../common'
import di from './../di'

export default {
  mixins: [common, di],
  inject: ['$table'],
  computed: {
    labelClassName() {
      return this.$table.labelClassName(this.column)
    },
    attribs() {
      return Object.assign({}, this.defaultAttribs(), this.attributes(), { ...this.$attrs })
    }
  },

  methods: {
    defaultAttribs() {
      return {
        prop: this.column.name,
        width: this.column.width,
        align: this.column.align,
        fixed: this.column.fixed || false,
        columnKey: this.column.name,
        className: this.column.class,
        labelClassName: this.labelClassName
      }
    },

    attributes() {
      return {}
    },
    
    slotAttribs(original) {
      return {
        isSelected: original.isSelected,
        isExpanded: original.isExpanded,
        row: original.row,
        column: this.column,
        $column: original.column,
        $index: original.$index,
        $store: original.store,
        $_self: original._self,
        _self: this
      }
    }
  },

  props: {
    column: {
      type: Object,
      required: true
    }
  }
}