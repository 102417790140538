<style scoped>
  .row{ padding-right: 10px }
  .row-small{ padding-left: 10px }
  .sbf-fieldset-0{ border: 1px solid #9b0101 }
  .sbf-fieldset-1{ border: 1px solid #062876; margin-top: 20px }
  .sbf-fieldset-fares{ border: 1px solid #f556ff; margin-top: 20px }
  .sbf-fl-bt-block{ padding: 10px 0 0 15px; }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      ttn-input(v-model="temp['routeKey']" v-bind="attr('routeKey')")
      ttn-input(v-model="temp['remainedSeats']" v-bind="attr('remainedSeats')")
      el-row
        el-col(:span='6' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')")
        el-col(:span='5' class='row')
          ttn-input(v-model="temp['validatingSupplier']" v-bind="attr('validatingSupplier')" label-width='90px')
        el-col(:span='5' class='row')
          ttn-input(v-model="temp['currency']" v-bind="attr('currency')" label-width='90px')
        el-col(:span='8')
          ttn-input(v-model="temp['configKey']" v-bind="attr('configKey')" label-width='120px')
      el-row
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['availableSeats']" v-bind="attr('availableSeats')")
        el-col(:span='7' class='row')
          ttn-input(v-model="temp['issueOfficeId']" v-bind="attr('issueOfficeId')" label-width='90px')
        el-col(:span='9')
          ttn-date-picker(v-model="temp['startOfSales']" v-bind="attr('startOfSales')" label-width='120px')
      el-row
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['contractId']" v-bind="attr('contractId')")
        el-col(:span='7' class='row')
          ttn-input(v-model="temp['locator']" v-bind="attr('locator')" label-width='90px')
        el-col(:span='9')
          ttn-input(v-model="temp['saleBeforeDate']" v-bind="attr('saleBeforeDate')" label-width='90px')
      el-row
        el-col(:span='6' class='row')
          ttn-checkbox(v-model="temp['blockRemainedUpdate']" v-bind="attr('blockRemainedUpdate')")
        el-col(:span='10' class='row')
          ttn-input(v-model="temp['childRules']" v-bind="attr('childRules')" label-width='90px')
        el-col(:span='8' class='row')
          ttn-checkbox(v-model="temp['blockSegmentsUpdate']" v-bind="attr('blockSegmentsUpdate')" label-width='130px')

      ttn-textarea(v-model="temp['comment']" v-bind="attr('comment')" label-width='120px')

      fieldset(:class="`el-form-fieldset`" )
        div
          el-row
            el-col(:span='6' class='row')
              ttn-checkbox(v-model="temp['checkPrice']" v-bind="attr('checkPrice')")
            el-col(:span='8' class='row')
              ttn-input(v-model="temp['checkPriceParams__configId']" v-bind="attr('checkPriceParams__configId')" label-width='120px')
            el-col(:span='8' class='row')
              ttn-input(v-model="temp['locatorConfigId']" v-bind="attr('locatorConfigId')" label-width='120px')

      fieldset(:class="`el-form-fieldset`")
        legend {{ translate(`form.fieldsets.features`) }}
        div
          el-row
            el-col(:span='3' class='row')
              ttn-checkbox(v-model="temp['features__isLcc']" v-bind="attr('features__isLcc')" label-width="50px")
            el-col(:span='3' class='row')
              ttn-checkbox(v-model="temp['features__isCharter']" v-bind="attr('features__isCharter')" label-width="60px")
            el-col(:span='3' class='row')
              ttn-checkbox(v-model="temp['features__showSeatsCount']" v-bind="attr('features__showSeatsCount')" label-width="85px")


      fieldset(:class="`el-form-fieldset sbf-fieldset-${legIndex}`" v-for="(segments, legIndex) in temp['flights_decoded']")
        legend {{ translate(`form.trip_fieldsets.${legIndex}`) }}
        div(v-for="(segment, segmentIndex) in segments")
          el-row
            el-col(:span='7' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['departure_location']" v-bind="elAttr('flights__departureLocation', `flights_decoded.${legIndex}.${segmentIndex}.departure_location`)" label-width='80px' :rules="required")
            el-col(:span='7' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['departure_terminal']" v-bind="elAttr('flights__departureTerminal', `flights_decoded.${legIndex}.${segmentIndex}.departure_terminal`)" label-width='80px')
            el-col(:span='7' class='row')
              ttn-date-picker(v-model="temp['flights_decoded'][legIndex][segmentIndex]['departure_time']" v-bind="elAttr('flights__departureTime', `flights_decoded.${legIndex}.${segmentIndex}.departure_time`)" label-width='60px' :rules="required")
          el-row
            el-col(:span='7' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['arrival_location']" v-bind="elAttr('flights__arrivalLocation', `flights_decoded.${legIndex}.${segmentIndex}.arrival_location`)" label-width='80px' :rules="required")
            el-col(:span='7' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['arrival_terminal']" v-bind="elAttr('flights__arrivalTerminal', `flights_decoded.${legIndex}.${segmentIndex}.arrival_terminal`)" label-width='80px')
            el-col(:span='7' class='row')
              ttn-date-picker(v-model="temp['flights_decoded'][legIndex][segmentIndex]['arrival_time']" v-bind="elAttr('flights__arrivalTime', `flights_decoded.${legIndex}.${segmentIndex}.arrival_time`)" label-width='60px' :rules="required")
          el-row
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['marketing_supplier']" v-bind="elAttr('flights__marketingSupplier', `flights_decoded.${legIndex}.${segmentIndex}.marketing_supplier`)" label-width='100px' :rules="required")
            el-col(:span='7' class='row')
              ttn-input-number(v-model="temp['flights_decoded'][legIndex][segmentIndex]['marketing_flight_number']" v-bind="elAttr('flights__marketingFlightNumber', `flights_decoded.${legIndex}.${segmentIndex}.marketing_flight_number`)" label-width='100px' :rules="required")
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['operating_supplier']" v-bind="elAttr('flights__operatingSupplier', `flights_decoded.${legIndex}.${segmentIndex}.operating_supplier`)" label-width='80px')
            el-col(:span='6' class='row')
              ttn-input-number(v-model="temp['flights_decoded'][legIndex][segmentIndex]['operating_flight_number']" v-bind="elAttr('flights__operatingFlightNumber', `flights_decoded.${legIndex}.${segmentIndex}.operating_flight_number`)" label-width='80px')
          el-row
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['rbd']" v-bind="elAttr('flights__rbd', `flights_decoded.${legIndex}.${segmentIndex}.rbd`)" label-width='70px' :rules="required")
            el-col(:span='6' class='row')
              ttn-select(v-model="temp['flights_decoded'][legIndex][segmentIndex]['rbd_type']" v-bind="elAttr('flights__rbdType', `flights_decoded.${legIndex}.${segmentIndex}.rbd_type`)" label-width='70px' :rules="required")
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['flights_decoded'][legIndex][segmentIndex]['aircraft']" v-bind="elAttr('flights__aircraft', `flights_decoded.${legIndex}.${segmentIndex}.aircraft`)" label-width='70px' :rules="required")
          hr

        el-row(class='sbf-fl-bt-sblock')
          el-col(:span='17' class='row')
            span &nbsp;
          el-col(:span='3' class='row')
            el-button(@click="actionAddSegment(legIndex)" type="primary" :disabled="segments.length >= 3") {{ translate('form.buttons.add_segment') }}
          el-col(:span='4' class='row')
            el-button(@click="actionRemoveSegment(legIndex)" type="danger" :disabled="segments.length < 2") {{ translate('form.buttons.remove_segment') }}

      el-row(class='sbf-fl-bt-block')
        el-col(:span='2' class='row')
          el-button(@click="actionAddTrip" type="primary" :disabled="!temp['flights_decoded'] || temp['flights_decoded'].length == 2") {{ translate('form.buttons.add_trip') }}
        el-col(:span='5' class='row')
          el-button(@click="actionRemoveTrip" type="danger" :disabled="!temp['flights_decoded'] || temp['flights_decoded'].length == 1") {{ translate('form.buttons.remove_trip') }}
      el-divider

      fieldset(class="el-form-fieldset sbf-fieldset-fares" v-if="temp['fares_decoded']")
        legend {{ translate('form.fields.fares') }}

        el-tabs(v-model="fareEditableTabsValue" type="card" editable @edit="handleFareTabsEdit")
          el-tab-pane(label="Base" key='base' name='base')
            ttn-input(v-model="temp['fares_decoded']['name']" v-bind="elAttr('fares__name', `fares_decoded.name`)")
            div(v-for="paxCode in paxTypes" :key="paxCode")
              el-row
                el-col(:span='2' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") {{ paxCode }}
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['fare']" v-bind="elAttr('fares__fare', `fares_decoded.fares.${paxCode}.fare`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['taxes']" v-bind="elAttr('fares__taxes', `fares_decoded.fares.${paxCode}.taxes`)" label-width='60px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['total']" v-bind="elAttr('fares__total', `fares_decoded.fares.${paxCode}.total`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['fare_code']" v-bind="elAttr('fares__fareCode', `fares_decoded.fares.${paxCode}.fare_code`)" label-width='55px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['baggage']" v-bind="elAttr('fares__baggage', `fares_decoded.fares.${paxCode}.baggage`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
              el-row(v-for="baggageDetailsType in baggageDetailsTypes" :key="baggageDetailsType")
                el-col(:span='4' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") -{{ baggageDetailsType }}
                el-col(:span='6' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_sum']" v-bind="elAttr('fares__baggageDetails__dimensionSum', `fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_sum`)" label-width='60px' :status-icon="false")
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_unit']" v-bind="elAttr('fares__baggageDetails__dimensionUnit', `fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight']" v-bind="elAttr('fares__baggageDetails__weight', `fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight`)" label-width='60px')
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight_unit']" v-bind="elAttr('fares__baggageDetails__weightUnit', `fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['count']" v-bind="elAttr('fares__baggageDetails__count', `fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.count`)" label-width='60px')
              hr

          el-tab-pane(
            v-for="(fareFamilyItem, index) in temp['fareFamilies_decoded']"
            :key="index+1+''"
            :label="fareFamilyItem.name"
            :name="index+1+''"
          )
            ttn-input(v-model="temp['fareFamilies_decoded'][index]['name']" v-bind="elAttr('fareFamilies__name', `fareFamilies_decoded.${index}.name`)" :rules="required")
            div(v-for="paxCode in paxTypes" :key="paxCode")
              el-row
                el-col(:span='2' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") {{ paxCode }}
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['fare']" v-bind="elAttr('fareFamilies__fare', `fareFamilies_decoded.${index}.fares.${paxCode}.fare`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['taxes']" v-bind="elAttr('fareFamilies__taxes', `fareFamilies_decoded.${index}.fares.${paxCode}.taxes`)" label-width='60px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['total']" v-bind="elAttr('fareFamilies__total', `fareFamilies_decoded.${index}.fares.${paxCode}.total`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['fare_code']" v-bind="elAttr('fareFamilies__fareCode', `fareFamilies_decoded.${index}.fares.${paxCode}.fare_code`)" label-width='55px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['baggage']" v-bind="elAttr('fareFamilies__baggage', `fareFamilies_decoded.${index}.fares.${paxCode}.baggage`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
              el-row(v-for="baggageDetailsType in baggageDetailsTypes" :key="baggageDetailsType")
                el-col(:span='4' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") -{{ baggageDetailsType }}
                el-col(:span='6' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_sum']" v-bind="elAttr('fareFamilies__baggageDetails__dimensionSum', `fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_sum`)" label-width='60px' :status-icon="false")
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_unit']" v-bind="elAttr('fareFamilies__baggageDetails__dimensionUnit', `fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight']" v-bind="elAttr('fareFamilies__baggageDetails__weight', `fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight`)" label-width='60px')
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight_unit']" v-bind="elAttr('fareFamilies__baggageDetails__weightUnit', `fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['count']" v-bind="elAttr('fareFamilies__baggageDetails__count', `fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.count`)" label-width='60px')
              hr

      el-divider

      ttn-textarea(v-model="temp['fareConditions']" v-bind="attr('fareConditions')" label-width='120px')
</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    computed:{
      paxTypes(){ return ['ADT', 'CHD', 'INF'] },
      faresFields(){ return ['fare', 'taxes', 'total', 'fare_code', 'baggage', 'baggage_details'] },
      baggageDetailsTypes() { return ['baggage', 'carry_on', 'personal_item'] },
      required(){
        const message = this.translate('form.required')
        const required = true
        return [{ required, message, trigger: 'change' }]
      }
    },
    extends: BaseForm,
    data() {
      return {
        fareEditableTabsValue: 'base',
        fareTabIndex: 0
      }
    },
    methods: {
      elAttr(fieldName, prop){
        return this.attr(fieldName, Object.assign({}, this.fieldReference[fieldName], {prop: prop}))
      },
      formatTemp() {
        let fares = {}

        if(this.temp['flights_decoded']){
          let flights = this.temp['flights_decoded'].reduce((prev, curr) => prev.concat(curr))
          for(let segment of flights){
            Object.keys(segment).map((key, index) => { segment[key] = this.formatField(segment[key], this.fieldReference[`flights__${this._.camelCase(key)}`]) })
          }
          this.temp['flights'] = JSON.stringify(flights)
        }

        if(this.temp['fares_decoded']){
          for(let paxCode in this.temp['fares_decoded']['fares']){
            for(let fareKey in this.temp['fares_decoded']['fares'][paxCode]){
              switch (fareKey) {
                case 'baggage_details':
                  for(let fareBaggageDetailsType in this.baggageDetailsTypes){
                    for (let baggageDetailsKey in this.temp['fares_decoded']['fares'][paxCode][fareKey][fareBaggageDetailsType]){
                      this.temp['fares_decoded']['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey] = this.formatField(this.temp['fares_decoded']['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey], this.fieldReference[`fares__${this._.camelCase(fareKey)}__${this._.camelCase(baggageDetailsKey)}`])
                    }
                  }
                  break;
                default:
                  this.temp['fares_decoded']['fares'][paxCode][fareKey] = this.formatField(this.temp['fares_decoded']['fares'][paxCode][fareKey], this.fieldReference[`fares__${this._.camelCase(fareKey)}`])
              }
            }
          }
          this.temp['fares'] = JSON.stringify(this.temp['fares_decoded'])
        }

        if(this.temp['fareFamilies_decoded']){
          for(let i in this.temp['fareFamilies_decoded']){
            for(let paxCode in this.temp['fareFamilies_decoded'][i]['fares']){
              for(let fareKey in this.temp['fareFamilies_decoded'][i]['fares'][paxCode]){
                switch (fareKey) {
                  case 'baggage_details':
                    for (let fareBaggageDetailsType in this.baggageDetailsTypes) {
                      for (let baggageDetailsKey in this.temp['fareFamilies_decoded'][i]['fares'][paxCode][fareKey][fareBaggageDetailsType]) {
                        this.temp['fareFamilies_decoded'][i]['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey] = this.formatField(this.temp['fareFamilies_decoded'][i]['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey], this.fieldReference[`fareFamilies__${this._.camelCase(fareKey)}__${this._.camelCase(baggageDetailsKey)}`])
                      }
                    }
                    break;
                  default:
                    this.temp['fareFamilies_decoded'][i]['fares'][paxCode][fareKey] = this.formatField(this.temp['fareFamilies_decoded'][i]['fares'][paxCode][fareKey], this.fieldReference[`fareFamilies__${this._.camelCase(fareKey)}`])
                }
              }
            }
          }
          this.temp['fareFamilies'] = JSON.stringify(this.temp['fareFamilies_decoded'])
        }

        return this.temp
      },

      async actualizeCallback(temp){
        this.resetFareFamilyTabs()
        let newTemp = {}

        for(let i = 0; i < this.fields.length; i++) {
          let field = this.fields[i]

          if(field.name.indexOf('flights__') !== -1 || field.name.indexOf('fares__') !== -1) {
            continue
          } else {
            newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
          }
        }

        if(temp['flights']){
          newTemp['flights_decoded'] = []
          for(let segment of temp['flights']){
            if(!newTemp['flights_decoded'][segment['leg_id']]){ newTemp['flights_decoded'][segment['leg_id']] = [] }
            Object.keys(segment).map((key, index) => { segment[key] = this.formatFieldReverse(segment[key], this.fieldReference[`flights__${this._.camelCase(key)}`]) })

            newTemp['flights_decoded'][segment['leg_id']][segment['segment_index']] = segment
          }
        }

        if(temp['fares']){
          newTemp['fares_decoded'] = {fares: {}, name: (temp['fares']['name'] || '')}

          let fares = (temp['fares']['fares'] || temp['fares'])
          for(let paxCode in fares){
            newTemp['fares_decoded']['fares'][paxCode] = {}

            for(let fareFieldName of this.faresFields){
              switch (fareFieldName) {
                case 'baggage_details':
                  if(!fares[paxCode][fareFieldName]){
                    newTemp['fares_decoded']['fares'][paxCode]['baggage_details'] = this.baggageDetailsTypes.reduce((bo, bkey) => ({ ...bo, [bkey]: {} }), {})
                  } else{
                    newTemp['fares_decoded']['fares'][paxCode][fareFieldName] = {}
                    for (let fareBaggageDetailsType of this.baggageDetailsTypes) {
                      newTemp['fares_decoded']['fares'][paxCode][fareFieldName][fareBaggageDetailsType] = {}

                      for (let baggageDetailsKey in fares[paxCode][fareFieldName][fareBaggageDetailsType]) {
                        newTemp['fares_decoded']['fares'][paxCode][fareFieldName][fareBaggageDetailsType][baggageDetailsKey] = this.formatFieldReverse(fares[paxCode][fareFieldName][fareBaggageDetailsType][baggageDetailsKey], this.fieldReference[`fares__${this._.camelCase(fareFieldName)}__${this._.camelCase(baggageDetailsKey)}`])
                      }
                    }
                  }
                  break;
                default:
                  newTemp['fares_decoded']['fares'][paxCode][fareFieldName] = this.formatFieldReverse(fares[paxCode][fareFieldName], this.fieldReference[`fares__${this._.camelCase(fareFieldName)}`])
              }
            }
          }
        }

        newTemp['fareFamilies_decoded'] = []
        if(temp['fareFamilies']){
          for(let i in temp['fareFamilies']){
            let newFareFamily = {...temp['fareFamilies'][i], fares: {}}

            for(let paxCode in temp['fareFamilies'][i]['fares']){
              newFareFamily['fares'][paxCode] = {}
              for(let fareFieldName of this.faresFields){
                switch (fareFieldName) {
                  case 'baggage_details':
                    if (!temp['fareFamilies'][i]['fares'][paxCode][fareFieldName]){
                      newFareFamily['fares'][paxCode][fareFieldName] = this.baggageDetailsTypes.reduce((bo, bkey) => ({ ...bo, [bkey]: {} }), {})
                    } else{
                      newFareFamily['fares'][paxCode][fareFieldName] = {}
                      for (let fareBaggageDetailsType of this.baggageDetailsTypes) {
                        newFareFamily['fares'][paxCode][fareFieldName][fareBaggageDetailsType] = {}

                        for (let baggageDetailsKey in temp['fareFamilies'][i]['fares'][paxCode][fareFieldName][fareBaggageDetailsType]) {
                          newFareFamily['fares'][paxCode][fareFieldName][fareBaggageDetailsType][baggageDetailsKey] = this.formatFieldReverse(temp['fareFamilies'][i]['fares'][paxCode][fareFieldName][fareBaggageDetailsType][baggageDetailsKey], this.fieldReference[`fareFamilies__${this._.camelCase(fareFieldName)}__${this._.camelCase(baggageDetailsKey)}`])
                        }
                      }
                    }
                    break;
                  default:
                    newFareFamily['fares'][paxCode][fareFieldName] = this.formatFieldReverse(temp['fareFamilies'][i]['fares'][paxCode][fareFieldName], this.fieldReference[`fares__${this._.camelCase(fareFieldName)}`])
                }
              }
            }

            this.fareTabIndex++
            newTemp['fareFamilies_decoded'].push(newFareFamily)
          }
        }

        for(let i of ['checkPriceParams', 'features']){
          if(temp[i]){
            for(let key in temp[i]){
              newTemp[`${i}__${key}`] = temp[i][key]
            }
          }
        }

        return newTemp
      },
      isDisabledField(field, legend){
        if (field.name !== 'availableSeats') {
          return false
        }
        return this.temp.remainedSeats !== ''
      },
      defaultFlightObject(legId, segmentIndex){
        return {
          arrival_location: '', arrival_time: null, departure_location: '', departure_time: null,
          marketing_flight_number: null, marketing_supplier: '', operating_flight_number: null, operating_supplier: '',
          rbd: '', rbd_type: 'economy',
          leg_id: legId, segment_index: segmentIndex
        }
      },
      defaultFareFamily(){
        return {name: '', fares: this.paxTypes.reduce((o, key) => ({ ...o, [key]: {
          baggage_details: this.baggageDetailsTypes.reduce((bo, bkey) => ({ ...bo, [bkey]: {}}), {})
        }}), {})}
      },
      beforeCreateCallback(){
        this.resetFareFamilyTabs()
        this.temp = Object.assign({}, this.temp, {flights_decoded: [], fares_decoded: this.defaultFareFamily(), fareFamilies_decoded: [] })
        this.actionAddTrip()
      },
      actionAddTrip(){
        this.temp['flights_decoded'].push([this.defaultFlightObject(this.temp['flights_decoded'].length, 0)])
      },
      actionRemoveTrip(){
        this.temp['flights_decoded'].splice(this.temp['flights_decoded'].length-1, 1)
      },
      actionAddSegment(legId){
        this.temp['flights_decoded'][legId].push(this.defaultFlightObject(legId, this.temp['flights_decoded'][legId].length))
      },
      actionRemoveSegment(legId){
        this.temp['flights_decoded'][legId].splice(this.temp['flights_decoded'][legId].length-1, 1)
      },
      resetFareFamilyTabs(){
        this.fareEditableTabsValue = 'base'
        this.fareTabIndex = 0
      },
      handleFareTabsEdit(targetName, action){
        if (action === 'add') {
          this.temp['fareFamilies_decoded'].push(this.defaultFareFamily());
          this.fareEditableTabsValue = ++this.fareTabIndex + '';
        }
        if (action === 'remove' && targetName != 'base') {
          const tab_index = parseInt(targetName) - 1
          if(this.fareEditableTabsValue === targetName){
            if(!this.temp['fareFamilies_decoded'][tab_index + 1]){
              this.fareEditableTabsValue = (tab_index == 0 ? 'base' : tab_index.toString())
            }
          }
          this.fareTabIndex--
          this.temp['fareFamilies_decoded'].splice(tab_index, 1)
        }
      },
    }
  }
</script>
