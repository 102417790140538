import { loadCrudModules } from '@/utils'

export default {
  name: 'railway',
  path: '/railway',
  alwaysShow: true,
  meta: {
    title: 'railway.default',
    icon: 'train'
  },
  children: loadCrudModules(require.context('./railway', true, /\.js$/))
}
