import { loadCrudModules } from '@/utils'

export default {
  name: 'orders',
  path: '/orders',
  redirect: '/orders/order_items',
  alwaysShow: true,
  meta: {
    title: 'orders.default',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./orders', true, /\.js$/))
}
