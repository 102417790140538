export default {
  name: 'settings_config',
  meta: {
    title: 'settings_config',
    icon: 'el-icon-s-comment',
    permission: 'settings_config'
  },
  lazy: true,
  items: [
    {
      name: 'config',
      endpoint: '/persistance/default',
      type: 'graphql',
      lazy: true,
      graphql: {
        name: 'frontendConfig',
        queries: {
          index: {
           id: true,
            key: true,
            depotId: true,
            version: true,
            ttl: true,
            setting: true,
            createdAt: true,
            updatedAt: true
          }
        },
        preloadFields: ['depotsWithDefault{id name}']
      },
      preload: true,
      preloadStores: ['depotsWithDefault'],
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'key'
          },
          {
            name: 'depotId',
            width: '180px',
            format: 'from_instance',
            storeKey: 'depotsWithDefault',
            storePrimaryKey: 'id',
            storeLabelKey: 'name'
          },
          {
            name: 'version',
            width: '100px'
          },
          {
            name: 'ttl',
            width: '100px'
          },
          // {
          //   name: 'setting'
          // },
          // {
          //   name: 'createdAt'
          // },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
         {
           name: 'update',
           icon: 'el-icon-edit',
           type: 'primary',
           plain: true
         }
       ],
       panel: [
         {
           name: 'mass_change',
           icon: 'el-icon-bottom',
           type: 'plain',
           params: {}
         },
         {
           name: 'change_settings',
           icon: 'el-icon-magic-stick',
           type: 'info',
           params: {
             entity: 'frontend_configs'
           }
         },
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'PersistanceApi::Frontend::Config::ItemRepository'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'depot_id',
            type: 'select',
            operator: 'eq',
            storeKey: 'depotsWithDefault',
            storePrimaryKey: 'id',
            storeLabelKey: 'name'
          },
          {
            name: 'key',
            type: 'text',
            operator: 'like'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'key',
            type: 'text',
            required: true
          },
          {
            name: 'depotId',
            type: 'select',
            storeKey: 'depotsWithDefault',
            // default: 0,
            storePrimaryKey: 'id',
            // storeLabelKey: 'name',
            storeLabelKeys: ['id', 'name'],
            required: true,
            // valueType: 'integer'
          },
          {
            name: 'ttl',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          // {
          //   name: 'setting',
          //   type: 'textarea',
          //   required: true,
          //   valueType: 'json_as_string',
          //   rows: 6
          // }
          {
            name: 'setting',
            required: true,
            type: 'jsoneditor',
            options: {
              modes: ['text', 'code', 'tree', 'form', 'view'],
              mode: 'code',
              ace: ace,
              height: 200
            },
            default: {},
            rows: 10
          }
        ]
      }
    }
  ]
}
