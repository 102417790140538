import { render, staticRenderFns } from "./old_price_details.vue?vue&type=template&id=73a8bddf&scoped=true&lang=pug&"
import script from "./old_price_details.vue?vue&type=script&lang=js&"
export * from "./old_price_details.vue?vue&type=script&lang=js&"
import style0 from "./old_price_details.vue?vue&type=style&index=0&id=73a8bddf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a8bddf",
  null
  
)

export default component.exports