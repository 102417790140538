<template lang="pug">
  div(class="list-content")
    el-table(:data="records" @selection-change="eventSelectionChange" v-loading="loading")
      el-table-column(type="expand")
        template(slot-scope="scope")
          el-row
            h3 Manual Discount
          el-row
            el-table(:stripe="true", :data="scope.row.discounts")
              el-table-column(label="Price Range")
                el-table-column(prop="priceFrom", label="From")
                el-table-column(prop="priceTo", label="To")
              el-table-column(prop="currency", label="Currency")
              el-table-column(prop="value", label="Value")
              el-table-column(label="Type")
                template(slot-scope="scope")
                  span {{ scope.row.type == 1 ? 'order' : 'ticket' }}
              el-table-column(label="Expiration Date")
                el-table-column(prop="startDate", label="Start")
                el-table-column(prop="endDate", label="End")

            el-row
              h3 Manual Budget
            el-row
              el-table(:stripe="true", :data="scope.row.budgets")
                el-table-column(prop="amount", label="Amount")
                el-table-column(prop="currency", label="Currency")
                el-table-column(label="Expiration Date")
                  el-table-column(prop="startDate", label="Start")
                  el-table-column(prop="endDate", label="End")

            el-row
              h3 Max Discount
            el-row
              el-table(:stripe="true", :data="scope.row.discountLimits")
                el-table-column(label="Price Range")
                  el-table-column(prop="priceFrom", label="From")
                  el-table-column(prop="priceTo", label="To")
                el-table-column(prop="currency", label="Currency")
                el-table-column(prop="value", label="Value")
                el-table-column(label="Type")
                  template(slot-scope="scope")
                    span {{ scope.row.type == 1 ? 'order' : 'ticket' }}

      el-table-column(type="selection", width="55")
      el-table-column(prop="id", label="ID")
      el-table-column(prop="priority", label="Priority")
      el-table-column(label="Depots")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.depot.join(", ") }}
      el-table-column(label="RefId")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.refid.join(", ") }}
      el-table-column(label="Config Item")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.config.join(", ") }}
      el-table-column(label="Supplier")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.vc.join(", ") }}
      el-table-column(prop="markup", label="Markup")
      el-table-column(prop="auction", label="Auction")
      el-table-column(prop="dynamicPricing", label="Dynamic")
      el-table-column(prop="ancillary", label="Ancillary")
      el-table-column(prop="upsales", label="Upsales")
      el-table-column(label="Status")
        template(slot-scope="scope")
          el-switch(disabled=true v-model="scope.row.status")
      el-table-column
        template(slot-scope="scope")
          span {{ filledTypes(scope.row) }}
      el-table-column(label="Updated At")
        template(slot-scope="scope")
          span {{new Date(scope.row.updatedAt) | moment("LLL")}}
      el-table-column(class-name="small-padding" :label="$t('crud.table.actions')" width="70px" align="center")
        template(slot-scope="scope")
          el-button(
            :title="translate(`action.update`)"
            type="primary"
            plain
            size="mini"
            icon="el-icon-edit"
            @click="handleAction('update', scope.row)"
          )
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  data() {
    return {
    }
  },

  methods: {

    filledTypes(row) {
      var types = []
      if(row.discounts.length > 0) { types.push('Manual Discount') }
      if(row.budgets.length > 0) { types.push('Budget') }
      if(row.discountLimits.length > 0) { types.push('Max Discount') }
      return types.join(', ')
    }
  }
}
</script>
