<template lang="pug">
  el-table-column(
    type="expand"
    v-on="$listeners"
    v-bind="attribs"
  )
    template(slot-scope="scope")
      slot(v-bind="slotAttribs(scope,column)")

</template>

<script>

import tableColumns from './../../../mixin/table/columns'

export default {
  name: 'TtnTableColumnExpand',
  mixins: [tableColumns],
  methods: {
    attributes() {
      return {
        class: this.config.table.expandRowClass
      }
    }
  }
}
</script>
