export default {
  name: 'gds_settings',
  meta: {
    title: 'gds_settings',
    icon: 'skill',
    permission: 'pesimisation_rules'
  },
  lazy: true,
  items: [
    {
      name: 'gds_settings',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaL2bGdsRules',
        queries: {
          index: {
            id: true,
            gdsName: true,
            gdsRequests: true,
            keyFields: true,
            // city: true,
            extraCoefficients: true,
            issueOffices: true,
            maxValue: true,
            active: true,
            useTrxStatuses: true,
            formulaType: true,
            offices: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '40px'
          },
          {
            name: 'gdsName'
          },
          {
            name: 'gdsRequests',
            format: 'array_as_string',
            width: '150px'
          },
          {
            name: 'keyFields',
            format: 'array_as_string'
          },
          // {
          //   name: 'city',
          //   width: '50px'
          // },
          {
            name: 'maxValue',
            width: '80px'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::L2b::GdsRuleRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          // {
          //   name: 'id',
          //   type: 'text'
          // },
          // {
          //   name: 'active',
          //   type: 'radiogroup',
          //   button: true,
          //   operator: 'eq',
          //   store: {
          //     '': 'Any',
          //     true: 'yes',
          //     false: 'no'
          //   }
          // }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'gds_name',
            type: 'text',
            required: true
          },
          {
            name: 'gds_requests',
            type: 'text',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'key_fields',
            type: 'multiselect',
            store: [
              { id: 'gds_name', name: 'Gds name' },
              { id: 'gds_office_id', name: 'Office Id' }
            ],
            default: ['gds_name']
          },
          {
            name: 'use_trx_statuses',
            type: 'multiselect',
            store: [
              { id: 1, name: 'Success' },
              { id: 2, name: 'Error' },
              { id: 3, name: 'Timeout' }
            ],
            default: [1, 2, 3],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: true
          },
          {
            name: 'formula_type',
            type: 'select',
            store: [
              { id: 1, name: 'requests / segments*passengers' },
              { id: 2, name: 'requests / books' },
              { id: 3, name: 'requests / orders' }
            ],
            storeLabelKeys: ['id', 'name'],
            default: 1,
            style: 'width: 300px'
          },
          {
            name: 'maxValue',
            type: 'text',
            required: true,
            valueType: 'integer',
            default: 0
          },
          {
            name: 'offices',
            type: 'textarea',
            required: false,
            rows: 3,
            default: '',
            valueType: 'array_of_string'
          },
          {
            name: 'extra_coefficients',
            type: 'textarea',
            required: true,
            rows: 3,
            default: '{}',
            valueType: 'json_as_string'
          },
          {
            name: 'issue_offices',
            type: 'textarea',
            required: true,
            rows: 3,
            default: '{}',
            valueType: 'json_as_string'
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          }
        ]
      }
    }
  ]
}
