<style scoped>
  .row{ padding-right: 20px }
  .row-small{ padding-left: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='5' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')")
        el-col(:span='19')
          ttn-multiselect(v-model="temp['trafficSourceIds']" v-bind="attr('trafficSourceIds')" label-width='90px')

      ttn-textarea(v-model="temp['airlines']" v-bind="attr('airlines')")
      ttn-textarea(v-model="temp['routes']" v-bind="attr('routes')")

      el-row
        el-col(:span='12' class='row')
          ttn-multiselect(v-model="temp['routeTypes']" v-bind="attr('routeTypes')")
        el-col(:span='12')
          ttn-select(v-model="temp['flightType']" v-bind="attr('flightType')" label-width='120px')

      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['departurePeriod']" v-bind="attr('departurePeriod')")
        el-col(:span='12')
          ttn-input(v-model="temp['timelimit']" v-bind="attr('timelimit')" label-width='120px')

      el-row
        el-col(:span='12' class='row')
          ttn-multiselect(v-model="temp['drivers']" v-bind="attr('drivers')")
        el-col(:span='12')
          ttn-textarea(v-model="temp['officeIds']" v-bind="attr('officeIds')" label-width='120px')

      fieldset(:class="`el-form-fieldset`" )
        legend {{ translate(`form.legends.force_reprice_options`) }}
        div
          el-row
            el-col(:span='12' class='row')
              ttn-checkbox(v-model="temp['forceReprice']" v-bind="attr('forceReprice')")
            el-col(:span='12' class='row')
              ttn-input(v-model="temp['forceRepriceOptions__aviaConfigItemsIds']" v-bind="attr('forceRepriceOptions__aviaConfigItemsIds')" label-width='120px')
          el-row
            el-col(:span='12' class='row')
              ttn-multiselect(v-model="temp['forceRepriceOptions__routeTypes']" v-bind="attr('forceRepriceOptions__routeTypes')")
            el-col(:span='12' class='row')
              ttn-input(v-model="temp['forceRepriceOptions__hoursToProcess']" v-bind="attr('forceRepriceOptions__hoursToProcess')" label-width='120px')

</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  components: {
    BaseForm
  },
  extends: BaseForm
}
</script>
