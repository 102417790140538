<template>
  <div class="card-base card-shadow--medium bg-white black-text pv-15 ph-15">
    <div ref="chart_position" :style="{height:height, width:width}" />
    <el-row>
      <el-col style="text-align: right;">
        <el-select v-model="currentType" size="small" @change="changeType">
          <el-option
            v-for="(key, value) in types"
            :key="key"
            :label="key"
            :value="value"
          />
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    height: {
      type: String,
      default: '250px'
    },
    width: {
      type: String,
      default: '100%'
    },
    type: {
      type: String,
      default: 'markup'
    }
  },
  data() {
    return {
      chart: null,
      types: {
        discount: 'Discount',
        markup: 'Markup'
      },
      currentType: 'markup'
    }
  },

  watch: {
    data(val) {
      this.rebuild()
    },
    type(val) {
      this.currentType = val
    },
    currentType(val) {
      this.changeType(val)
    }
  },
  mounted() {
    // window.addEventListener('resize', this.__resizeHanlder)
    this.rebuild()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    // window.removeEventListener('resize', this.__resizeHanlder)
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    __resizeHanlder() {
      if (this.chart) {
        this.chart.resize()
      }
    },

    changeType(val) {
      this.chart.dispose()
      this.rebuild()
    },

    rebuild() {
      let series = this.data.series.map((i) => {
        return {
          name: i[this.currentType].name,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          showSymbol: false,
          lineStyle: { normal: { width: 1 }},
          data: i[this.currentType].data
        }
      })

      this.chart = echarts.init(this.$refs.chart_position)
      this.chart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 0,
          icon: 'rect',
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: this.data.legends,
          right: '4%',
          textStyle: { fontSize: 10 }
        },
        grid: {
          top: 30,
          left: '3%',
          right: '7%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          name: this.data.xaxis,
          axisLine: { lineStyle: { color: '#57617B' }},
          data: this.data.x_lines[this.currentType]
        }],
        yAxis: [{
          type: 'value',
          name: this.$t('travel_rankings.report_position.charts.yaxis'),
          nameLocation: 'end',
          inverse: false,
          axisTick: { show: true },
          axisLine: { lineStyle: { color: '#57617B' }},
          axisLabel: {
            margin: 10,
            textStyle: { fontSize: 14 }
          },
          splitLine: { lineStyle: { color: '#ddd' }}
        }],
        series: series
      })

      this.$nextTick(() => {
        this.__resizeHanlder()
      })
    }
  }
}
</script>
