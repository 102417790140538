export default {
  name: 'iata_parser',
  alwaysShow: true,
  items: [
    {
      alwaysShow: true,
      name: 'iata_portal',
      endpoint: '/persistance/default',
      pagination: true,
      type: 'graphql',
      graphql: {
        name: 'metaparserIataTickets',
        queries: {
          index: {
            id: true,
            ticketNumbers: true,
            filename: true,
            status: true,
            error: true,
            cacheKey: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      stores: {
        statuses: {
          0: 'New',
          1: 'Success',
          2: 'Error',
          3: 'Upload error',
        }
      },
      table: {
        items: [
          {
            name: 'id',
            width: '60'
          },
          {
              name: 'ticketNumbers',
              sortable: false,
              format: 'array_as_string'
          },
          {
            name: 'filename',
            default: '-'
          },
          {
            name: 'status',
            format: 'from_store',
            storeKey: 'statuses',
          },
          'error',
          'cacheKey',
          {
            name: 'createdAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '100px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'upload',
            icon: 'el-icon-upload',
            type: 'danger',
            plain: true
          },
          {
            name: 'download',
            icon: 'el-icon-download',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-edit',
            type: 'danger',
            plain: true
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'ticketNumber',
            type: 'text'
            // operator: 'in_array_int'
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'ticket_numbers',
                type: 'textarea',
                valueType: 'array_of_string',
                valueDelimiter: ','
              }
            ]
          }
        ]
      }
    }
  ]
}
