import operatorSchedule from './issue_queue/operator_schedule'
import issueQueueStatistic from './issue_queue/statistic'
import shiftStatistic from './issue_queue/shift_statistic'

export default {
  namespace: 'orders',
  component: 'issue_queue',
  meta: {
    title: 'orders.issue_queue',
    icon: 'table'
  },
  tabs: [
    operatorSchedule,
    shiftStatistic,
    issueQueueStatistic
  ]
}
