<style scoped>
  .row{ padding-right: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      ttn-input(v-model="temp['parserType']" v-bind="attr('parserType')")
      el-row
        el-col(:span='4' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='80px')
        el-col(:span='20' class='row')
          ttn-input(v-model="temp['name']" v-bind="attr('name')" label-width='90px')

      template(v-if="temp['options_decoded']")
        el-row
          el-col(:span='24' class='row')
            fieldset(:class="`el-form-fieldset`")
              legend {{ translate(`fieldset.schedule`) }}
              el-button(@click="actionAddItem('schedule')" type="primary") {{ translate('form.add') }}
              div(v-for="(item, i) in temp['options_decoded']['schedule']")
                el-row
                  ttn-input(v-model="temp['options_decoded']['schedule'][i]['last_run_time']" v-bind="attr('options__schedule__lastRunTime')" label-width='90px')
                  el-col(:span='6' class='row')
                    ttn-input(v-model="temp['options_decoded']['schedule'][i]['days_range']" v-bind="attr('options__schedule__daysRange')" label-width='90px')
                  el-col(:span='6' class='row')
                    ttn-input(v-model="temp['options_decoded']['schedule'][i]['ttl']" v-bind="attr('options__schedule__ttl')" label-width='120px')
                  el-col(:span='10' class='row')
                    ttn-input(v-model="temp['options_decoded']['schedule'][i]['value']" v-bind="attr('options__schedule__value')" label-width='90px')
                  el-col(:span='1' class='row')
                    span &nbsp
                  el-col(:span='1' class='row')
                  el-button(@click="actionRemoveItem('schedule', i)" type="danger" :disabled="isDeleteRowDisabled('schedule')" class="sbpr-cnc-b") -

        el-row
          el-col(:span='24' class='row')
            span &nbsp;

        el-row
          el-col(:span='24' class='row')
            ttn-textarea(v-model="temp['options_decoded']['routes']" v-bind="attr('options__routes')" label-width='90px')

        el-row
          el-col(:span='24' class='row')
            ttn-textarea(v-model="temp['options_decoded']['airlines']" v-bind="attr('options__airlines')" label-width='90px')
</template>

<script>
  import BaseForm from '@crud_view/form'
  // import MultiRowField from '@crud_lib/form/multi_row_field'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    // mixins: [MultiRowField],
    computed: {

    },
    methods: {
      formatTemp() {
        let options = {}

        if (this.temp['options_decoded']){
          for (let i in this.temp['options_decoded']) {
            switch (i) {
              case 'schedule':
                options[i] = []
                for (let j in this.temp['options_decoded'][i]){
                  options[i][j] = {}
                  for (let k in this.temp['options_decoded'][i][j]){
                    options[i][j][k] = this.formatField(this.temp['options_decoded'][i][j][k], this.fieldReference[`options__${this._.camelCase(i)}__${this._.camelCase(k)}`])
                  }
                }
                break
              default:
                options[i] = this.formatField(this.temp['options_decoded'][i], this.fieldReference[`options__${this._.camelCase(i)}`])
            }
          }
        }
        this.temp['options'] = JSON.stringify(options)

        return this.temp
      },
      async actualizeCallback(temp) {
        let newTemp = {}

        for (let i = 0; i < this.fields.length; i++) {
          let field = this.fields[i]

          if (!field.ignore) {
            newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
          }
        }

        if(temp['options']){
          newTemp['options_decoded'] = {}

          for(let i in temp['options']){
            switch (i) {
              case 'schedule':
                newTemp['options_decoded'][i] = []
                for (let j in temp['options'][i]) {
                  newTemp['options_decoded'][i][j] = {}
                  Object.keys(temp['options'][i][j]).map((key, index) => { newTemp['options_decoded'][i][j][key] = this.formatFieldReverse(temp['options'][i][j][key], this.fieldReference[`options__${this._.camelCase(i)}__${this._.camelCase(key)}`]) })
                }
                break
              default:
                newTemp['options_decoded'][i] = this.formatFieldReverse(temp['options'][i], this.fieldReference[`options__${this._.camelCase(i)}`])
            }

          }
        }

        return newTemp
      },
      defaultOptionSchedule(){
        return {days_range: '', ttl: 60, value: '', last_run_time: ''}
      },
      defaultOptions() {
        return {
          schedule: [this.defaultOptionSchedule()],
          routes: '', airlines: ''
        }
      },
      beforeCreateCallback() {
        this.temp = Object.assign(
          {},
          this.temp,
          { options_decoded: this.defaultOptions() }
        )
      },
      isDeleteRowDisabled(key) {
        switch(key){
          case 'schedule':
            return (this.temp[`options_decoded`]['schedule'].length <= 1)
        }
        return true
      },
      actionAddItem(key) {
        switch (key) {
          case 'schedule':
            this.temp['options_decoded']['schedule'].push({ ...this.defaultOptionSchedule() })
            break
        }
      },
      actionRemoveItem(key, i) {
        switch (key) {
          case 'schedule':
            this.temp['options_decoded']['schedule'].splice(i, 1)
            break
        }
      }
    }
  }
</script>
