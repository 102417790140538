<template lang="pug">
  div(class="list-content")
    el-table(:data="records" @selection-change="eventSelectionChange" v-loading="loading")
      el-table-column(type="selection", width="55")
      el-table-column(prop="id", label="ID")
      el-table-column(prop="priority", label="Priority")
      el-table-column(label="Depots")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.depots.join(", ") }}
      el-table-column(label="RefId")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.refId.join(", ") }}
      el-table-column(label="GDS")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.gds.join(", ") }}
      el-table-column(label="Status")
        template(slot-scope="scope")
          span(v-html="wrapStatus(scope.row.status)")
      el-table-column(label="Updated At")
        template(slot-scope="scope")
          span {{new Date(scope.row.updatedAt) | moment("LLL")}}
      el-table-column(label="Created At")
        template(slot-scope="scope")
          span {{new Date(scope.row.createdAt) | moment("LLL")}}
      el-table-column(class-name="small-padding" :label="$t('crud.table.actions')" width="70px" align="center")
        template(slot-scope="scope")
          el-button(
            :title="translate(`action.update`)"
            type="primary"
            plain
            size="mini"
            icon="el-icon-edit"
            @click="handleAction('update', scope.row)"
          )
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  data() {
    return {
      trafficSources: {}
    }
  },

  created() {
    this.trafficSources = this.$store.getters['bootData']['traffic_sources_hash']
  },

  methods: {
    wrapStatus(status) {
      if (status === true) {
        return '<span class="el-tag el-tag--success color-inherit"> allowed </span>'
      } else {
        return '<span class="el-tag el-tag--danger color-inherit"> blocked </span>'
      }
    },

    wrapGds(gds) {
      if (gds.length === 0) {
        return '-'
      } else {
        return '<span class="el-tag el-tag--warning margin-bot-5 color-inherit">' + gds.join('</span><span class="el-tag el-tag--warning color-inherit margin-bot-5">') + '</span>'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-tag.el-tag--warning.margin-bot-5{
    margin-bottom: 5px;
  }

  .el-tag.color-inherit{
    color: inherit;
  }

</style>
