import graphqlDefaultItem            from './default/graphql'
import tableDefaultItem              from './default/table'
import actionsDefaultItem            from './default/actions'
import filtersDefaultItem            from './default/filters'
import generalFormDefaultItem        from './default/form/general'
import taxesFormDefaultItem          from './default/form/taxes'

export default {
  name: 'default_fare_rules',
  meta: {
    title: 'default_fare_rules',
    icon: 'star',
    permission: 'fare_rules'
  },
  lazy: true,
  items: [
    {
      name: 'fare_rules_full',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: graphqlDefaultItem,
      table: tableDefaultItem,
      actions: actionsDefaultItem,
      filter: filtersDefaultItem,
      form: {
        actualize: true,
        fieldsets: [
          generalFormDefaultItem,
          taxesFormDefaultItem
        ]
      }
    }
  ]
}