<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatExpandedRow(row) {
      return ''.concat(
        'Officces: ', row['offices'], '<br>',
        'Extra options: ', JSON.stringify(row['extraCoefficients']), '<br>',
        'Extra offices: ', JSON.stringify(row['issueOffices'])
      )
    }
  }
}
</script>
