<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:mmbLink="scope")
      a(class="el-button el-button--default el-button--mini is-plain el-icon-link" style="width: 100%;" title="MMB Link" :href="scope.row.mmbLink" target="_blank" v-if="scope.row.mmbLink")
  block expand
    el-table(:data="scope.row.details" border stripe)
      el-table-column(prop="amount", label="Amount", width="150")
      el-table-column(prop="currency", label="Currency", width="100")
      el-table-column(prop="segments", label="")
        template(slot-scope="scope")
          el-table(:data="scope.row.segments" border)
            el-table-column(prop="supplier", label="Supplier", width="100")
            el-table-column(prop="pnr", label="PNR", width="150")
            el-table-column(label="Vouchers")
              template(slot-scope="scope")
                div(v-for="voucher in scope.row.vouchers") {{voucher.code}} (expire {{voucher.expire}})
</template>

<script>

import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    actionMarkAsNew() {
      this.updateStatus(0, 'alert.mark_as_new_confirm')
    },

    actionMarkAsAwaiting() {
      this.updateStatus(1, 'alert.mark_as_awaiting_confirm')
    },

    actionMarkAsSuccess() {
      this.updateStatus(2, 'alert.mark_as_success_confirm')
    },

    updateStatus(status, message) {
      this.$confirm(this.translate(message), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        try {
          const query = {
            __args: {
              ids: this.multipleSelection.map((i) => { return parseInt(i.id) }),
              status: status
            }
          }

          await this.graphql('mcoKiwiQueueRefundUpdateStatus', query, 'mutation')

          this.$message({
            message: 'Success!',
            type: 'success'
          })
          this.$elItem.$emitAction('refresh')
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }).catch((e) => {})
    },

    rowClassName({ row, rowIndex }) {
      switch(true) {
        case (row.status == 1):
          return 'warning-row'
        case (row.status == 2):
          return 'success-row'
        default:
          return ''
      }
    }
  }
}
</script>