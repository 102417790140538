export default {
  namespace: 'railway',
  component: 'rail_settings',
  meta: {
    title: 'railway.rail_settings',
    icon: 'gear'
  },
  tabs: [
    {
      name: 'rail_settings',
      items: [
        {
          name: 'rail_settings_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayRailSettings',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                settingType: true,
                config: true,
                depotId: true,
                createdAt: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              'settingType', 'config',
              {
                name: 'depotId',
                width: '70px',
                align: 'right'
              }, 'createdAt', 'updatedAt',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'clear_cache',
                icon: 'el-icon-s-release',
                type: 'info',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::RailSettingRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'depot_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                scope: 'RailSettings'
              },
              {
                name: 'setting_type',
                type: 'text',
                operator: 'eq',
                camelizeLangKey: true,
                scope: 'RailSettings'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'setting_type',
                type: 'text',
                required: true
              },
              {
                name: 'config',
                type: 'yamleditor',
                required: true
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                required: true
              }

            ]
          }
        }
      ]
    }
  ]
}
