import gds_list from '@/settings/modules/avia/general/gds_store'

export default {
  legend: 'general_data',
  translateLegend: true,
  items: [
    {
      name: 'setting_type',
      type: 'hidden',
      required: false,
      default: 0,
      valueType: 'integer'
    },
    {
      name: 'id',
      type: 'hidden',
      required: false,
      valueType: 'integer'
    },
    {
      name: 'active',
      type: 'checkbox',
      default: true,
      required: false
    },
    {
      name: 'priority',
      type: 'text',
      valueType: 'integer'
    },
    {
      name: 'validatingSupplier',
      type: 'text',
      valueType: 'array_of_string'
    },
    {
      name: 'fareName',
      type: 'text',
      valueType: 'array_of_string'
    },
    {
      name: 'gdsId',
      type: 'multiselect',
      store: Object.assign({ 'all': 'all' }, gds_list.objectByName())
    }
  ]
}