<style>
  .fr-c-w200 input{ width: 200px }
  .fr-c-h400 .jsoneditor-outer{ height: 400px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='10')
          ttn-input(v-model="temp['key']" v-bind="attr('key')" label-width='60px')
        el-col(:span='8')
          ttn-select(v-model="temp['depotId']" v-bind="attr('depotId')" label-width='90px' class='fr-c-w200')
        el-col(:span='6')
          ttn-input(v-model="temp['ttl']" v-bind="attr('ttl')" label-width='100px')

      ttn-jsoneditor(v-model="temp['setting']" v-bind="attr('setting')" class="fr-c-h400")

</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  components: {
    BaseForm
  },
  extends: BaseForm
}
</script>
