import reportBase from '../../avia/general/report'

export default {
  booking_report: {
    table: {
      ...reportBase.table
    },
    form: {
      fields: {
        ...reportBase.form_fields,
        reportParams__startDate: 'Start date',
        reportParams__endDate: 'End date'
      },
      options: {
        ...reportBase.form_options,
        reportType: {
          1: 'TtnAviaVI::BookingReport'
        }
      }
    },
    action: {
      ...reportBase.action
    },
    messages: {
      ...reportBase.messages
    }
  }
}
