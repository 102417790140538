<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:latitude="scope")
      div {{ scope.row['latitude'] }}, {{ scope.row['longitude'] }}
      a(:href="`http://www.google.com/maps/place/${scope.row['latitude']},${scope.row['longitude']}`" target="_blank") map
    template(v-slot:railRoadId="scope")
      div {{ scope.row['railRoad']['name'] }}
</template>

<script>

import BaseTable from '@crud_view/table'
import di from '@crud_lib/di'
export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  mixins: [di]
}
</script>