<template lang="pug">
  div(class="list-content" v-loading="loading")
    shift-bar-pie(:data="records" v-if="records.length > 0" @showDrawer="showDrawer")

    el-drawer(
      :title="drawer.title"
      :visible.sync="drawer.visible"
      direction="ttb")
      span {{ drawer.locators }}
</template>

<script>
import Base from '@crud_view/table'
import ShiftBarPie from './chart'

export default {
  extends: Base,
  components: {
    ShiftBarPie
  },
  data() {
    return {
      drawer: {
        visible: false,
        title: '',
        locators: []
      }
    }
  },
  methods: {
    showDrawer(drawer) {
      this.drawer = drawer
    }
  }
}
</script>
