<template lang="pug">
  extends /form
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  extends: BaseForm,

    data() {
    return {
      fieldTypes: {}
    }
  },

  methods: {

    isDisabledField(field, legend) {

      if( this.fieldTypes[field.name] === undefined ){
        this.fieldTypes[field.name] = field.type
      }

      if (this.temp.parentId == null && ['comments', 'refid', 'status'].includes(field.name) == false) {
        field.type = 'hidden'

      }else if (this.temp.parentId != null && field.name == 'refid'){
        field.type = 'hidden'
      }else{
        field.type = this.fieldTypes[field.name]
      }
    }
  }
}
</script>
