<template lang="pug">
  el-dialog(:fullscreen="false" :title="translate('modal.run_project')" :visible.sync="visible" :close-on-click-modal="false" width="70%" :custom-class="'dialog-edit-form'")
    el-form(ref="dataForm" :model="temp" :label-position="'left'" :label-width="'120px'")
      fieldset(class="el-form-fieldset")
        legend {{translate('form.legends.runParams')}}
        el-form-item(:label="translate('form.fields.modal.startUrl')")
          el-input(v-model="temp.startUrl" clearable)
        el-form-item(:label="translate('form.fields.modal.startValueOverride')")
          v-jsoneditor( v-model="temp.startValueOverride" :plus="true" :options="{ mode: 'code', ace: ace}")
    div(slot="footer" class="dialog-footer")
      el-button(@click="visible = false") {{ translate('form.cancel') }}
      el-button(type="primary" @click="runTemplate()") {{ translate('form.confirm') }}
</template>

<script>
import common from '@crud_lib/common'

export default {
  mixins: [common],
  props: {
    config: {
      required: true,
      type: Object
    },
    tab: {
      required: true,
      type: String
    },
    item: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      visible: false,
      filterText: '',
      temp: {
        startUrl: '',
        startValueOverride: ''
      },
      currentRow: {},
      ace: window.ace
    }
  },

  mounted() {
    this.$parent.$on('showRunDialog', (row) => {
      this.currentRow = row
      this.temp = Object.assign({}, { startValueOverride: row.startValue, startUrl: '' })
      this.visible = true
    })
  },

  beforeDestroy() {
    this.$parent.$off('showRunDialog')
  },

  methods: {
    async runTemplate() {
      try {
        const query = {
          __args: Object.assign({ id: this.currentRow.id }, this.temp),
          id: true
        }

        await this.graphql('siteProjectRunTemplate', query, 'mutation')
        this.$message({
          message: 'Success!',
          type: 'success'
        })
        this.visible = false
        // this.getParent(this.elItem).$emit('action_refresh')
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    }

  }
}
</script>
