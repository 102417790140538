import components from './components'

export default {
  name: 'paxport',
  meta: {
    title: 'paxport',
    icon: 'el-icon-turn-off'
  },
  lazy: true,
  items: [
    // {
    //   name: 'paxport_worker',
    //   ...components.reportsQraphql(2),
    //   table: {
    //     items: components.reportsTableItems()
    //   },
    //   actions: {
    //     row: [
    //       ...components.reportsRowActions()
    //     ],
    //     panel: [
    //       ...components.reportsPanelActions()
    //     ]
    //   },
    //   filter: {
    //     items: [
    //       {
    //         name: 'id',
    //         type: 'text',
    //         operator: 'eq'
    //       },
    //     ]
    //   },
    //   form: {
    //     actualize: true,
    //     items: [
    //       ...components.reportsFormItems()
    //     ]
    //   }
    // },
    {
      name: 'paxport_settings',
      ...components.settingsQraphql(2),
      table: {
        items: [
          ...components.settingsTableItems()
        ]
      },
      actions: {
        row: [
          ...components.settingsRowActions()
        ],
        panel: [
          {
            name: 'change_settings',
            icon: 'el-icon-magic-stick',
            type: 'info',
            params: {
              entity: 'paxport_api_parser'
            }
          },
          ...components.settingsPanelActions()
        ]
      },
      filter: {
        items: [
          ...components.settingsFilterItems()
        ]
      },
      form: {
        actualize: true,
        items: [
          ...components.settingsFormItems(2),
          {
            name: 'options__schedule__daysRange',
            required: false,
            ignore: true
          },
          {
            name: 'options__schedule__value',
            required: false,
            valueType: 'array_of_string',
            ignore: true
          },
          {
            name: 'options__schedule__ttl',
            required: false,
            valueType: 'integer',
            ignore: true
          },
          {
            name: 'options__schedule__lastRunTime',
            required: false,
            valueType: 'string',
            type: 'hidden',
            ignore: true
          },
          {
            name: 'options__routes',
            required: false,
            ignore: true,
            type: 'textarea',
            rows: 5
          },
          {
            name: 'options__airlines',
            required: false,
            ignore: true,
            valueType: 'array_of_string',
            type: 'textarea',
            rows: 5
          },
        ]
      }
    }
  ]
}
