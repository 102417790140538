<template lang="pug">
  el-dialog(
    v-if="diffMarkupWindow.visible"
    :visible.sync="diffMarkupWindow.visible" 
    center
    :title="diffMarkupWindow.title"
    v-loading="diffMarkupWindow.loading" 
    element-loading-text="Loading...")
    el-form(:model="diffMarkupWindow.form")
      el-row(:gutter="24")
        el-col(:span="8")
          fieldset
            legend General
            el-form-item(:label="`Value ${booking.depotCurrency}`")
              el-input(v-model="diffMarkupWindow.form.inbound")
            el-form-item(label="Reason")
              el-select(v-model="diffMarkupWindow.form.inboundReason")
                el-option(
                  v-for="o in diffMarkupWindow.dictionaryFor.general" 
                  :label="o[1]"
                  :value="o[1]"
                  :key="o[0]")
            el-form-item(label="Another reason")
              el-input(
                v-model="diffMarkupWindow.form.inboundReasonOther"
                :disabled="diffMarkupWindow.form.inboundReason != 1")

        el-col(:span="8")
          fieldset
            legend General Ancillary
            el-form-item(:label="`Value ${booking.depotCurrency}`")
              el-input(v-model="diffMarkupWindow.form.inboundAncillary")
            el-form-item(label="Reason")
              el-select(v-model="diffMarkupWindow.form.inboundAncillaryReason")
                el-option(
                  v-for="o in diffMarkupWindow.dictionaryFor.ancillary" 
                  :label="o[1]"
                  :value="o[1]"
                  :key="o[0]")
            el-form-item(label="Another reason")
              el-input(
                v-model="diffMarkupWindow.form.inboundAncillaryReasonOther"
                :disabled="diffMarkupWindow.form.inboundAncillaryReason != 1")

        el-col(:span="8")
          fieldset
            legend Upsale
            el-form-item(:label="`Value ${booking.depotCurrency}`")
              el-input(v-model="diffMarkupWindow.form.upsale")
            el-form-item(label="Reason")
              el-select(v-model="diffMarkupWindow.form.upsaleReason")
                el-option(
                  v-for="o in diffMarkupWindow.dictionaryFor.upsale" 
                  :label="o[1]"
                  :value="o[1]"
                  :key="o[0]")

    span.dialog-footer(slot="footer")
      el-button(@click="saveDiffMarkupWindow()" type="primary" plain) Save
      el-button(@click="diffMarkupWindow.visible = false") Close
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins:[common, di],
  props: {
    booking: null
  },
  data() {
    return {
      diffMarkupWindow: {
        title: 'Additional markup',
        visible: false,
        loading: false,
        form: {},
        dictionaryFor: {
          general: [
            [0, 'Issue schema'],
            [1, 'Add another one'],
            [2, 'Incorrect price with notification'],
            [3, 'Incorrect price without notification'],
            [4, 'MultiGDS'],
            [5, 'No money on robot'],
            [6, 'Payment NOT on the day of creation'],
            [7, 'Issue after midnight'],
            [8, 'Seats removed by time limit within booking validity'],
            [9, 'Additional payment card fee'],
            [10, 'Downgrade'],
            [11, 'Baggage rate instead of luggage']
          ],
          ancillary: [
            [0, 'Issue schema'],
            [1, 'Add another one'],
            [2, 'Wrong prive in admin panel'],
            [3, 'MultiGDS'],
            [4, 'No money on robot'],
            [5, 'Payment NOT on the day of creation'],
            [6, 'Issue after midnight'],
            [7, 'Baggage rate instead of luggage']
          ],
          upsale: [
            [0, 'Total'],
            [1, 'HighPass']
          ]
        }
      }
    }
  },

  methods: {
    show() {
      this.diffMarkupWindow.visible = true
      this.diffMarkupWindow.form = {
        inbound: '0',
        inboundReason: 'Issue schema',
        inboundReasonOther: '',
        inboundAncillary: '0',
        inboundAncillaryReason: 'Issue schema',
        inboundAncillaryReasonOther: '',
        upsale: '0',
        upsaleReason: 'Total'
      }
    },

    async saveDiffMarkupWindow() {
      this.diffMarkupWindow.loading = true
      try {
        const query = {
          __args: {
            id: this.booking.id,
            ...this.diffMarkupWindow.form
          }
        }

        let res = await this.graphql('aviaBookingItemAddDiffMarkup', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Diff markup added and synchronized with the old admin',
          duration: 2600
        })

        this.diffMarkupWindow.visible = false
        this.diffMarkupWindow.loading = false
      } catch (e) {
        this.diffMarkupWindow.visible = false
        this.diffMarkupWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }      
    }
  }
}
</script>

<style lang="scss" scopped>
  .bold { font-weight: bold }
  fieldset {
    border-width: 1px;
    border-color: #fff;
  }
</style>