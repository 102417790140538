import common from './../common'
import di from './../di'

export default {
  mixins: [common, di],
  inheritAttrs: false,
  data() {
    return {
      dataValue: this.value,
      withFormItem: this.formItemWraper
    }
  },
  inject: ['$form'],

  watch: {
    value: {
      immediate: true,
      handler: function(newValue) {
        this.dataValue = newValue
      }
    }
  },

  computed: {
    labelAllowedAttributes() {
      return ['label-width', 'label', 'prop', 'rules', 'label-position']
    },
    wrapperComponent() {
      return this.withFormItem ? 'el-form-item' : 'div'
    },
    defaultOperators() {
      return this.$elItem.defaultOperators
    }
  },

  methods: {

    defaultAttribs(field, fieldset) {
      return {}
    },

    defaultLabelAttribs(field, fieldset) {
      return this.wrapperComponent === 'div' ? {} : {
        prop: field.name,
        label: field.type === 'hidden' ? null : this.translate(`form.fields.${field.labelKey || field.name}`)
      }
    },

    isDisabledField(field, legend) {
      return this.$form.isDisabledField(field, legend)
    },

    fieldOptions(field, type, value, fieldset) {
      return this.config.isCombined ? this.$elRootItem._fieldOptions(field, type, value, fieldset) : this.$elItem._fieldOptions(field, type, value, fieldset)
    },

    remoteMethod(query, field, type, value, fieldset) {
      this.config.isCombined ? this.$elRootItem._remoteMethod(query, field, type, value, fieldset) : this.$elItem._remoteMethod(query, field, type, value, fieldset)
    },

    reverseField(field, fieldset) {
      return this.$form.reverseField(field, fieldset)
    },

    isReversed(field, fieldset) {
      return this.$form.isReversed(field, fieldset)
    },

    expandSelect(field, fieldset) {
      this.$form.expandSelect(field, fieldset, this.$refs)
    },

    attribs(field, fieldset) {
      return Object.assign({}, this.defaultAttribs(field, fieldset), this._.omit({ ...this.$attrs }, ...this.labelAllowedAttributes))
    },

    labelAttribs(field, fieldset) {
      return Object.assign({}, this.defaultLabelAttribs(field, fieldset), this._.pick({ ...this.$attrs }, ...this.labelAllowedAttributes))
    },
    filterOperatorOptions(field) {
      return this.$elItem.filterOperatorOptions(field)
    },
    changeFieldValue(field, value) {
      return this.$form.changeFieldValue(field, value)
    }

  },
  props: {
    value: {
      type: null
    },
    field: {
      type: Object,
      required: true
    },
    fieldset: {
      type: Object,
      required: true
    },
    formItemWraper: {
      type: Boolean,
      required: false,
      default: true
    },
    blockScope: {
      type: String,
      required: false,
      default: 'form'
    }
  }
}
