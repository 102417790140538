<template lang="pug">
  el-dialog(
    v-if="surchargeWindow.visible"
    :visible.sync="surchargeWindow.visible" 
    center
    :title="surchargeWindow.title"
    v-loading="surchargeWindow.loading" 
    element-loading-text="Loading..."
    class="avia-surcharge-window")
    el-row(:gutter="20")
      el-col(:span="15")
        el-form(
          :model="surchargeWindow.form" 
          :rules="surchargeFormRules"
          ref="surchargeWindow")
          el-row
            el-form-item(label="Locator")
              el-input(v-model="surchargeWindow.form.surcharge_locator" disabled)

            el-form-item(label="Amount" prop="surcharge_sum")
              el-input(v-model="surchargeWindow.form.surcharge_sum" @change="handleCSurchargeSumChange($event)")

            el-form-item(label="With commission")
              el-checkbox(v-model="surchargeWindow.form.surcharge_pay_system_commission_enabled")

            el-form-item(label="Payment commission")
              el-input(v-model="surchargeWindow.form.surcharge_pay_system_commission" :disabled="!surchargeWindow.form.surcharge_pay_system_commission_enabled")

            el-form-item(label="Currency")
              el-input(v-model="surchargeWindow.form.surcharge_currency" disabled)

            el-form-item(label="Pay to" prop="surcharge_date")
              el-date-picker(
                v-model="surchargeWindow.form.surcharge_date"
                format="MM.dd.yyyy HH:mm:ss"
                type="datetime"
                :default-value="new Date()"
                value-format="MM.dd.yyyy HH:mm")

            el-form-item(label="Reason" prop="surcharge_reason")
              el-select(v-model="surchargeWindow.form.surcharge_reason")
                el-option(
                  v-for="(title, key) in surchargeWindow.surchargeReasons" 
                  :label="title"
                  :value="key"
                  :key="key")

            el-form-item(label="Free text")
              el-input(v-model="surchargeWindow.form.surcharge_free_text" :disabled="surchargeWindow.form.surcharge_reason != 'surcharge_free_text'")

            el-form-item(label="External servce")
              el-checkbox(v-model="surchargeWindow.form.surcharge_external_service")

            el-form-item(label="Surcharge link")
              el-checkbox(v-model="surchargeWindow.form.surcharge_link")
      el-col(:span="5")
        div(v-if="surchargeWindow.paymentInfo.admin_price_details" :set="info = surchargeWindow.paymentInfo.admin_price_details")
          div.bold Price details:
          div(v-for="(value, info_key) in info" :class="{bold: info_key == 'total'}") {{ info_key }}: {{ value }}

    span.dialog-footer(slot="footer")
      el-button(@click="saveSurchargeWindow()" type="primary" plain) Save
      el-button(@click="surchargeWindow.visible = false") Close
</template>

<script>
import di from '@crud_lib/di'
import common from '@crud_lib/common'

export default {
  mixins:[common, di],
  props: {
    locator: {
      type: String
    }
  },
  data() {
    return {
      surchargeWindow: {
        loading: false,
        visible: false,
        title: 'Surcharge',
        paymentInfo: null,
        form: {
          surcharge_locator: this.locator,
          surcharge_sum: '',
          surcharge_pay_system_commission_enabled: true,
          surcharge_pay_system_commission: 0,
          surcharge_currency: '',
          surcharge_link: true,
          surcharge_reason: '',
          surcharge_free_text: '',
          surcharge_external_service: false,
          surcharge_date: ''
        },
        surchargeReasons: {
          'pass_not_correct': "Passenger\'s name is incorrect",
          'tarif_change': 'Airline changed the fare',
          'free_seats': 'Airline canceled the seats',
          'docs_change' : 'For first/last name changes',
          'chd_tarif': 'Child fare calculated',
          'meal_surcharge': 'Surcharge for meal',
          'baggage_surcharge': 'Surcharge for additional baggage',
          'seat_surcharge': 'Surcharge for choice of seat',
          'client_surcharge': "Invoice issued at customer\'s request",
          'pay_to_us': 'Payment to us, not to avia company',
          'bg_n4': 'Baggage N4',
          'add_infant': 'Add booking for infant',
          'surcharge_free_text': 'Other',
          'additional_services': 'Add additional services'
        }
      },
      surchargeFormRules: {
        surcharge_sum:    [{ required: true, message: 'Please input Surcharge sum', trigger: 'blur' }],
        surcharge_reason: [{ required: true, message: 'Please select Surcharge reason', trigger: 'blur' }],
        surcharge_date:   [{ required: true, message: 'Please select Surcharge date', trigger: 'blur' }]
      }
    }
  },

  methods: {
    async show() {
      this.surchargeWindow.visible = true
      this.surchargeWindow.loading = true
      this.$refs.surchargeWindow?.resetFields()
      try {
        const query = {
          __args: {
            locator: this.locator
          }
        }

        let res = await this.graphql('aviaBookingItemSurchargeInfo', query, 'query')
        this.surchargeWindow.loading = false
        this.surchargeWindow.paymentInfo = JSON.parse(res)

        this.surchargeWindow.form.surcharge_currency = this.surchargeWindow.paymentInfo.surcharge_commission.currency
        this.surchargeWindow.form.surcharge_pay_system_commission = 0
      } catch (e) {
        this.surchargeWindow.visible = false
        this.surchargeWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }      
    },

    saveSurchargeWindow() {
      this.$refs.surchargeWindow.validate((valid) => {
        if (valid) { this.addSurcharge() }
      })
    },

    async addSurcharge() {
      this.surchargeWindow.loading = true

      const form = this.surchargeWindow.form

      let surcharge_total_sum = parseFloat(form.surcharge_sum) + parseFloat(form.surcharge_pay_system_commission)
      let reason_text = form.surcharge_free_text ? form.surcharge_free_text : this.surchargeWindow.surchargeReasons[form.surcharge_reason]
      let user_comment = form.surcharge_free_text ? form.surcharge_free_text : ''

      let formData = {
        status: 'WP',
        comment: `Surcharge total amount: ${surcharge_total_sum} ${form.surcharge_currency}. Amount: ${form.surcharge_sum}. Pay system commission: ${form.pay_system_commission} . Pay before: ${form.surcharge_date}<br/>${reason_text}`,
        reason: form.surcharge_reason,
        user_comment: user_comment,
        penalties: {
          sum: surcharge_total_sum,
          pay_system_commission: form.surcharge_pay_system_commission,
          currency: form.surcharge_currency,
          date: form.surcharge_date
        },
        changes: [{
          external_service: (form.surcharge_external_service ? 'true' : 'false'),
          surcharge_link: (form.surcharge_link ? 'true' : 'false')
        }]
      }

      try {
        const query = {
          __args: {
            locator: this.locator,
            json: JSON.stringify(formData)
          }
        }

        let res = await this.graphql('aviaBookingItemAddSurcharge', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Surcharge added and synchronized with the old admin',
          duration: 2600
        })

        this.surchargeWindow.loading = false
        this.surchargeWindow.visible = false
      } catch (e) {
        this.surchargeWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      } 
    },

    handleCSurchargeSumChange(value) {
      this.surchargeWindow.form.surcharge_pay_system_commission = this._.floor(parseFloat(value) * this.surchargeWindow.paymentInfo.surcharge_commission.pay_system_commission / 100, 2)
    }
  }
}
</script>

<style lang="scss" scopped>
  .avia-surcharge-window  {
    .el-form-item.el-form-item--mini { margin-bottom: 5px }
  }
</style>