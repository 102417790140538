<template lang="pug">
  extends /table
  block custom
    details-dialog
</template>

<script>
import Base from '@crud_view/table'
import detailsDialog from './modal/details'

export default {
  extends: Base,
  components: {
    detailsDialog
  },
  methods: {
    cellDblClick( row, column, cell, event ) {
      switch(column.columnKey) {
      case 'rules':
        this.$emit('showDialogInfo', row.rules)
        break;
      case 'description':
        this.$emit('showDialogInfo', row.description)
        break;
      default: 
        return;
      }
    },

    rowClassName({ row, rowIndex }) {
      switch(row.status) {
      case 0:
        return 'row-new'
      case 2: 
        return 'row-inactive'
      case 3:
        return 'row-updated'
      default:
        return ''
      }
    },
  }
}

</script>

<style>
  .row-new td {background: #dbf5db !important}
  .row-inactive td {background: #eabcbc !important}
  .row-updated td {background: #ecda95 !important}
</style>