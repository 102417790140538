<template lang="pug">
  div(class="segment-wrapper")
    el-divider(v-if="trip_index > 0")
      i(class="el-icon-more" v-for="(n, ni) in (new Array(10))" :key="`ic_${ni}`")
    el-row(v-for="segment in trip" :key="`ss_${segment.id}`")
      el-col(:span="6")
        el-descriptions(:border="true" :column="1")
          el-descriptions-item(label="Departure")
            div(class="bold") {{segment.departure_location}},&nbsp;{{segment.departure_country}}
              span(v-if="segment.departure_terminal !== ''") ,&nbsp;{{segment.departure_terminal}}
            div {{segment.departure_time}}
      el-col(:span="6")
        el-descriptions(:border="true" :column="1")
          el-descriptions-item(label="Arrival")
            div(class="bold") {{segment.arrival_location}},&nbsp;{{segment.arrival_country}}
              span(v-if="segment.arrival_terminal !== ''") ,&nbsp;{{segment.arrival_terminal}}
            div {{segment.arrival_time}}
      el-col(:span="6")
        el-descriptions(:border="true" :column="1")
          el-descriptions-item(label="Flight")
            div
              span(class="bold") {{segment.supplier_iata}}{{segment.carrier}}
              span(
                v-if="segment.other_supplier_iata !='' && segment.other_supplier_iata !== segment.supplier_iata"
              ) 
                span(class="other_supplier_iata") ({{segment.other_supplier_iata}}{{segment.carrier}})&nbsp;
              span(v-else) ,&nbsp;
              span {{segment.booking_class_type}}(#[span(class="bold") {{segment.booking_class}}) ]
            div {{segment.aircraft || segment.aircraft_code}}
      el-col(:span="6")
        el-descriptions(:border="true" :column="1")
          el-descriptions-item(label="Info")
            div
              span Fare code: &nbsp;
              span(class="bold") {{segment.fare_code}}
            div
              span Baggage: &nbsp;
              span(class="bold") {{segment.baggage}}
</template>

<script>
export default {
  props: {
    trip: {
      required: true,
      type: Array
    },
    trip_index: {
      required: true,
      type: Number
    }
  }
}
  
</script>
