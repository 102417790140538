<template lang="pug">
  extends /table
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,
  methods: {
    actionImport() {
      this.$confirm(this.translate('alert.import'), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        try {
          const query = {
            __args: {
              roles: this.$store.getters['permissions']
            }
          }

          await this.graphql('backendRoleImportRole', query, 'mutation')
          this.$message({
            message: 'Success!',
            type: 'success'
          })
          this.$elItem.$emitAction('refresh')
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }).catch((e) => {})
    }
  }
}
</script>
