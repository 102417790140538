import { loadCrudModules } from '@/utils'

export default {
  name: 'ttn_avia_vi',
  path: '/ttn_avia_vi',
  redirect: '/ttn_avia_vi/pricing',
  alwaysShow: true,
  meta: {
    title: 'ttn_avia_vi.default',
    icon: 'guide'
  },
  children: loadCrudModules(require.context('./ttn_avia_vi', true, /\.js$/))
}
