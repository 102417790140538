<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    el-radio-group(
      v-model="dataValue"
      :disabled="isDisabledField(field, fieldset.legend)"
      @change="changeFieldValue(field, $event)"
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
      component(
        :is="radioComponent"
        v-for="option in fieldOptions(field, blockScope, dataValue)"
        :key="`${option.id}_${option.label}`"
        :label="option.id"
        :border="field.border"
      ) {{field.translateOptions ? translate(`form.options.${field.name}.${option.label}`) : option.label}}
</template>

<script>

import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnRadioGroup',
  mixins: [formElements],
  computed: {
    radioComponent() {
      return this.field.button ? 'el-radio-button' : 'el-radio'
    }
  },
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        style: 'width: 100%'
      }
    }
  }
}
</script>
