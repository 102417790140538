<template lang=pug>
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")

  block custom
    change-markups-window

</template>

<script>
import BaseTable from '@crud_view/table'
import ChangeMarkupsWindow from './components/change_markups_window'

export default {
  components: {
    BaseTable,
    ChangeMarkupsWindow
  },
  extends: BaseTable,
  methods: {
    formatExpandedRow(row){
      return `<b>Error:</b>  ${row['error'] || '-'}<br/>
      <b>Session id:</b> ${row['userToken']} / ${row['sessionId']} <br/>
      <b>Currency:</b>  ${row['currency']} <br/>
      `
    },
    rowClassName({ row, rowIndex }) {
      if(row['isCorrect']){
        return 'success-row'
      }

      return ''
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 5:
          return { 'background-color': '#00d00099' }
        case 6:
        case 7:
          return { 'background-color': '#47ff5e69' }
        case 8:
        case 9:
          return { 'background-color': '#daa2d894' }
        case 10:
        case 11:
          return { 'background-color': '#f9b47894' }
        case 12:
        case 13:
          return { 'background-color': '#d4d4d4' }
        case 14:
          if(row['recommendedMarkups']){
            return { 'background-color': (row['recommendedMarkups'] < 0 ? '#ff393973' : '#47ff5e69') }
          } else{ return {} }
        default:
          return {}
      }
    },
    actionRedirect(row){
      window.open(row['redirectUrl'], '_blank')
    },
    actionChangeMarkups(row){
      this.$emit('showChangeMarkupsWindow', row)
    }
  }
}
</script>
