export default {
  rail_price_rule_service_items_list: {
    table: {
      depotId: 'Depot',
      enabled: 'Enabled',
      providerName: 'Provider Name',
      cost: 'Cost',
      addlInfo: 'Additional Info',
      serviceId: 'Service ID'
    },
    form: {
      fields: {
        depotId: 'Depot',
        enabled: 'Enabled',
        providerName: 'Provider Name',
        cost: 'Cost',
        addlInfo: 'Additional Info',
        serviceId: 'Service ID'
      }
    }
  }
}