<template lang="pug">
  div
    el-dialog(title="Edit passenger information"
              :visible.sync="editPaxDialog.visible"
              width="800px"
              v-loading="editPaxDialog.loading" 
              element-loading-text="Loading...")
      el-form(:model="editPaxDialog.form" :rules="editPaxDialog.rules" label-position="right" label-width="100px" style="padding: 25px 0")

        el-row(:gutter="10")
          el-col(:span="12")
            el-form-item(label="First name" prop="firstName")
              el-input(v-model="editPaxDialog.form.firstName" style="width: 200px")    

            el-form-item(label="Last name" prop="lastName")
              el-input(v-model="editPaxDialog.form.lastName" style="width: 200px")

            el-form-item(label="Patronymic" prop="patronymic")
              el-input(v-model="editPaxDialog.form.patronymic" style="width: 200px")            

            el-form-item(label="Sex" prop="gender")
              el-select(v-model="editPaxDialog.form.gender")
                el-option(
                  v-for="o in ['M', 'F', 'MI', 'FI']"
                  :label="o"
                  :value="o"
                  :key="o")

            el-form-item(label="Date of birth" prop="birthDay")
              el-date-picker(v-model="editPaxDialog.form.birthDay" 
                             type="date" 
                             placeholder="Pick a day"
                             format="dd-MM-yyyy")

            el-form-item(label="Document" prop="passportNumber")
              el-input(v-model="editPaxDialog.form.passportNumber" style="width: 200px")

            el-form-item(label="Doc. exp. date" prop="passportTerm")
              el-date-picker(v-model="editPaxDialog.form.passportTerm" 
                             type="date" 
                             placeholder="Pick a day"
                             format="dd-MM-yyyy")

            el-form-item(label="Nationality" prop="countryId")
              el-select(v-model="editPaxDialog.form.countryId")
                el-option(
                  v-for="o in countryList"
                  :label="`${o.name} (${o.code})`"
                  :value="o.code"
                  :key="o.code")

          el-col(:span="12")
            el-form-item(label="Bonus card" prop="bonusCard")
              el-input(v-model="editPaxDialog.form.bonusCard" style="width: 200px")

            el-form-item(label="Tariff")
              el-input(v-model="editPaxDialog.form.amounts.values.tarif" style="width: 200px")

            el-form-item(label="Equivalent")
              el-input(v-model="editPaxDialog.form.amounts.values.equiv" style="width: 200px")

            el-form-item(label="Taxes")
              el-input(v-model="editPaxDialog.form.amounts.values.taxes" style="width: 200px")

            el-form-item(label="Currency")
              el-input(v-model="editPaxDialog.form.amounts.currency" style="width: 200px")

      span.dialog-footer(slot="footer")
        el-button(type="primary" small @click="savePaxDialogForm()") Save

    table(border="0" style="width: 100%")
      tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
        th PTC
        th Last name
        th First name
        th Patronymic
        th Date of birth
        th Gender
        th Citizenship
        th Passport
        th Doc. exp. date
        th Bonus card
        th

      template(v-for="(pax, paxInx) in passengers")
        tr(style="text-align: center")
          td.bold
            span {{ pax.ageType }}
            span(v-if="['CHD', 'INF'].indexOf(pax.ageType) != -1") &nbsp;{{ calculateAge(pax) }}
          td.bold {{ pax.lastName }}
          td.bold {{ pax.firstName }}
          td.bold {{ pax.patronymic }}
          td {{ formatDate(pax.birthDay, 'DD-MM-YYYY') }}
          td {{ pax.gender }}
          td {{ `${getDictionaryBy('countries', pax.countryId)} (${pax.countryId})` }}
          td {{ pax.passportNumber }}
          td {{ formatDate(pax.passportTerm, 'DD-MM-YYYY') }}
          td {{ pax.bonusCard }}
          td(style="vertical-align: middle")
            el-button(icon="el-icon-edit" mini type="primary" plain @click="showEditPaxDialog(pax)")

</template>

<script>
// import BaseTable from '@crud_view/table'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],

  props: {
    booking: null,
    reloadBooking: null
  },

  data() {
    return {
      editPaxDialog: {
        visible: false,
        loading: false,
        form: {
          amounts: {
            values: {}
          }
        },
        rules: {}
      },
      countryList: []
    }
  },

  computed: {
    passengers() {
      return this._.map(this.booking.passengers, (pax) => {
        return { ...pax,
          birthDay: Number(pax.birthDay) * 1000,
          passportTerm: Number(pax.passportTerm) * 1000
        }
      })
    },
    dictionary() { return JSON.parse(this.booking.dictionary) },
    firstSegment() { return this.booking.locations[0] }
  },

  methods: {
    getDictionaryBy(key, code) {
      return this.dictionary[key][code] || code
    },

    formatDate(date, formatStr) {
      if(!date) return ''

      return this.$moment(date).format(formatStr)
    },

    calculateAge(pax) {
      const birthday = this.$moment(pax.birthDay)
      const departureDate = this.$moment(`${this.firstSegment.arrivalDate} ${this.firstSegment.arrivalTime}`, 'DD.MM.YYYY h:mm')
      const diff = departureDate.diff(birthday, 'month')

      const years = Math.floor(diff / 12)
      const month = diff % 12

      return `${years}y${month}m`
    },

    showEditPaxDialog(pax) {
      console.log()
      this.editPaxDialog.visible = true
      this.editPaxDialog.form = {
        id: pax.id,
        gender: pax.gender,
        birthDay: this.$moment(pax.birthDay),
        passportNumber: pax.passportNumber,
        passportTerm: this.$moment(pax.passportTerm),
        bonusCard: pax.bonusCard,
        countryId: pax.countryId,
        firstName: pax.firstName,
        lastName: pax.lastName,
        patronymic: pax.patronymic,
        amounts: { ...JSON.parse(pax.amounts) }
      }

      this.loadCountryList()
    },

    async loadCountryList() {
      if(this.countryList.length > 0) return this.countryList

      this.editPaxDialog.loading = true
      let res = await this.graphql('aviaBookingItemCountryList', {}, 'query')
      this.countryList = JSON.parse(res)   
      this.editPaxDialog.loading = false   
    },

    async savePaxDialogForm() {
      this.editPaxDialog.loading = true

      try {
        const query = {
          __args: {
            ...this.editPaxDialog.form,
            birthDay: this.editPaxDialog.form.birthDay.toString(),
            passportTerm: this.editPaxDialog.form.passportTerm.toString(),
            amounts: JSON.stringify(this.editPaxDialog.form.amounts)
          }
        }

        let res = await this.graphql('aviaBookingItemSavePassenger', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.editPaxDialog.loading = false
        this.editPaxDialog.visible = false

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.editPaxDialog.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      } 
    }
  }
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  table {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
    width: 100%;
  }

  table tr { background-color: #fff; vertical-align: middle }
  table tr.route { vertical-align: middle; height: 40px }
  table tr td { padding: 5px }
  table tr th { padding: 5px }
</style>