import settings from "../../settings/modules/frontend/settings";

export default {
  route: {
    dashboard: 'Dashboard',
    profile: 'Profile',
    avia: {
      default: 'Avia Settings',
      pricing: 'Pricing',
      fake_pnr: 'Fake PNR',
      search_settings: 'Search settings',
      reports: 'Reports',
      l2b: 'L2b',
      fare_families: 'Fare Families',
      flight_details: 'Flight Details',
      google_flights: 'Google Flights',
      seats_block: 'Seats block',
      substitution: 'Substitution',
      fare_rules: 'Fare rules',
      lowcost_params: 'Lowcost params',
      cache: 'Avia cache',
      parsers: 'Parsers'
    },
    core: {
      default: 'Core',
      settings: 'Settings',
      history: 'History'
    },
    backend: {
      default: 'Backend',
      access: 'Access'
    },
    frontend: {
      default: 'Frontend',
      settings: 'Settings'
    },
    mco: {
      default: 'MCO',
      gds_queue: 'GDS Queue',
      kiwi_queue: 'Kiwi Queue'
    },
    orders: {
      default: 'Avia',
      items: 'Order Items',
      issue_queue: 'Cockpit',
      bookings: 'Bookings'
    },

    travel_rankings: {
      default: 'Travel Rankings',
      admin: 'Admin',
      reports: 'Reports'
    },
    web_scraper: {
      default: 'Webscraper',
      configuration: 'Configuration'
    },
    system_tools: {
      default: 'System Tools',
      avia: 'Avia tools',
      conversion_analysis: 'Conversion Analysis',
      avia_parsers: 'Avia parsers'
    },
    railway: {
      bookings: 'Bookings',
      default: 'Railway',
      rail_settings: 'Rail Settings',
      upsale_services: 'Upsale Services',
      rail_prices: 'Rail Prices',
      timetable: 'Timetable',
      directory: 'Directory Service',
      rail_controls: 'Provider Control',
      rail_agents: 'Rail Agents'
    },
    insurance: {
      default: 'Insurance',
      settings: 'Insurance Settings',
      controls: 'Insurance Controls',
    },
    payment: {
      default: 'Payment',
      corporate_cards: 'Corporate Cards'
    },
    ttn_avia_vi: {
      default: 'AviaVI',
      settings: 'AviaVI Settings',
      connection_time: 'Avia VI Connection rules',
      price_limit: 'Avia VI Price limits',
      actions: 'AviaVI Actions',
      data: 'AviaVi Data',
      reports: 'AviaVi Reports',
      pricer: 'AviaVi Pricer'
    }
  },
  main_tabs: {
    avia: {
      dynamic_pricing: 'Dynamic',
      fake_pnr: 'Fake PNR',
      pesimisation_rules: 'Pesimisation rules',
      gds_settings: 'Settings',
      l2b_statistics: 'Statistics',
      fare_families: 'Fare Families',
      baggage_data: 'Baggage Details',
      search_rules: 'Search Rules',
      statistic: 'Bookings',
      l2b_reports: 'Reports',
      segment_cost: 'Segment Cost',
      google_flights_restrictions: 'Restrictions',
      google_flights_feed_settings: 'Feed settings',
      seats_block_rules: 'Seats block',
      seats_block_reports: 'Reports',
      seats_block_generators: 'Generators',
      seats_block_analytics: 'Analytics',
      seats_block_pricer_rules: 'Pricer',
      substitution: 'Substitution',
      fare_rules: 'Fare rules',
      default_fare_rules: 'Default fare rules',
      fare_rule_templates: 'Templates',
      operator_schedule: 'Operator scheduling',
      issue_queue_statistic: 'Statistic',
      seats_block_events: 'Settings/Events',
      lowcost_baggage: 'Lowcost Baggage',
      ryanair: 'Ryanair',
      paxport: 'Paxport',
    },
    core: {
      main: 'Main',
      history: 'History'
    },
    frontend: {
      doc_type_rules: 'DocType rules',
      refid_configs: 'Refid Configs',
      settings_config: 'Configs'
    },
    backend: {
      backend_users: 'Users'
    },
    mco: {
      gds_queue_manager: 'GDS Queue Manager'
    },
    orders: {
      items: 'Items',
      operator_schedule: 'Cockpit',
      issue_queue_statistic: 'Dashboard',
      shift_statistic: 'Shift',
    },
    system_tools: {
      markup_analysis: 'Markup Analysis',
      thoughts_of_sales: 'Thoughts of sales',
      conversion_analysis: 'Conversion Analysis',
      iata_parser: 'IATA parser',
      airline_parser: 'Airline parser'
    },
    travel_rankings: {
      settings: 'Settings',
      meta: 'Metasearch',
      pricing: 'Pricing',
    },
    web_scraper: {
      site_settings: 'Settings',
      parsing: 'Parsing',
      access: 'Access',
      validator: 'Validator'
    },
    railway: {
      bookings: 'Bookings',
      rail_prices: 'Rail Prices',
      rail_price_rule_service_items: 'Rail Service Prices',
      rail_price_rule_extra_items: 'Rail Baggage Prices',
      timetable: {
        agencies: 'Agencies',
        calendar_dates: 'Calendar Dates',
        routes: 'Routes',
        stop_times: 'Stop Times',
        stops: 'Stops',
        transfers: 'Transfers',
        trips: 'Trips'
      },
      rail_controls: 'Provider Control',
      planned_maintenances: 'Planned Maintenances',
      stations: 'Stations',
      rail_roads: 'Rail Roads',
      rail_agents: 'Rail Agents'
    },
    insurance: {
      settings: 'Settings',
      connections: 'Connections',
      packets: 'Packets',
      risks: 'Risks',
      packets_risks: 'Packets/Risks',
      controls: 'Controls',
    },
    payment: {
      corporate_cards: 'Corporate Cards'
    },
    ttn_avia_vi: {
      settings_search: 'Search',
      settings_timelimit: 'Timelimits',
      settings_refresh_schedule: 'Buckets refresh schedule',
      connection_time: 'Connection rules',
      settings_limits: 'Limits',
      settings_price_concurrency: 'Price concurrency',
      settings_system_settings: 'General settings',
      actions_performed_handler: 'Perform worker',
      data_flight_item: 'Flights',
      reports: 'Reports'
    }
  },

  item_tabs: {
    avia: {
      dynamic_pricing_schemes: 'Schemes',
      dynamic_pricing_rules: 'Rules',
      fake_rules: 'Rules',
      fake_airline_rules: 'Airline Rules',
      fake_price_rules: 'Price Rules',
      fake_search_booked_rules: 'Ordered Book',
      fake_search_rules: 'Payed Book',
      fake_search_stata: "Statistics",
      fake_search_depot_rules: "Depot Rules",
      fake_upsale_settings: 'Upsale Settings',
      fare_families_full: 'Fare Families Full',
      baggage_details: 'Baggage Details',
      rec_settings: 'Fake Recc Settings',
      fake_recc_setting: 'Fake Recc Settings',
      main: 'Main',
      seats_block_analytics: 'Analytics',
      seats_block_pricer_rules: 'Pricer rules',
      as_pesimisation_rules_list: 'Pesimisation rules',
      from_rankings: 'Rankings data',
      daily_statistics: 'Daily statistics',
      monthly_statistics: 'Monthly statistics',
      daily_by_depot: 'Daily statistics depot',
      monthly_by_depot: 'Monthly statistics depot',
      lowcost_search_baggage: 'Lowcost baggage',
      google_flights_feed_settings: 'GooFl Feed settings',
      cache_warmup_worker: 'Cache warmup',
      ryanair_worker: 'Ryanair',
      paxport_worker: 'Workers',
      paxport_settings: 'Settings'
    },
    frontend: {
      doc_type_rules: 'DocType rules',
      refid_configs: 'Refid Configs'
    },
    backend: {
      backend_users: 'Users',
      as_pesimisation_rules_list: 'Pesimisation rules',
      generated_files: 'Generated Reports',
      gds_settings: 'L2b settings',
      daily_statistics: 'Daily statistics',
      monthly_statistics: 'Monthly statistics',
      daily_by_depot: 'Daily statistics depot',
      monthly_by_depot: 'Monthly statistics depot',
      l2b_reports: 'Reports',
      segment_cost_rules: 'Rules',
      fare_families_full: 'Full settings',
      fake_upsale_settings: 'Fare Family / Ancillary Setting',
      google_flights_restrictions: 'Restrictions',
      google_flights_feed_settings: 'Feed settings',
      seats_block_rules: 'Flights',
      from_rankings: 'Rankings',
      fare_rules_full: 'Fare rules',
      operator_schedule: 'Operator scheduling',
      fare_rule_templates_full: 'Fare rule Templates',
      seats_block_analytics: 'Analytics',
      seats_block_pricer_rules: 'Pricer',
      seats_block_events: 'Settings/Events'
    },
    core: {
      feature_setting: 'Feature Setting',
      cache_interval: 'Cache Interval',
      global: 'Global'
    },
    backend: {
      backend_users: 'Users',
      backend_roles: 'Roles'
    },
    frontend: {
      doc_type_rules_list: 'DocType rules',
      refid_configs_list: 'Refid Configs',
    },
    mco: {
      gds_queue_manager_settings: 'Settings',
      gds_queue_manager_locators: 'Locators',
      kiwi_queue_locators: 'Locators',
      kiwi_queue_refunds: 'Refunds',
      kiwi_queue_issues: 'Issues'
    },
    orders: {
      avia_bookings: 'Avia Bookings',
      avia_booking_list: 'Items',
      avia_booking_show: 'Details'
    },
    travel_rankings: {
      logs: 'Logs',
      airports: 'Airports',
      report_pricing_data: 'Pricing report',
      report_original_data: 'Original data',
      report_position: 'Position report',
      report_time: 'Time report'
    },
    web_scraper: {
      site_projects: 'Site projects',
      site_runs: 'Site runs',
      site_run_groups: 'Run groups',
      agents: 'API Agents',
      json_schema: 'Json schema'
    },
    system_tools: {
      markup: 'Meta Markup Analysis',
      search: 'Search',
      conversion: 'Conversion Analysis',
      iata_portal: 'Iata Portal'
    },
    railway: {
      bookings_list: 'Bookings List',
      rail_settings_list: 'Rail Settings',
      upsale_services_list: 'Upsale Services',
      rail_price_rule_service_items_list: 'Rail ServicePrices',
      rail_prices_list: 'Rail Prices',
      railway_rail_price_rules: 'Rail Price Rules',
      railway_rail_price_rule_items: 'Rail Price Rule Items',
      stations_list: 'Stations',
      station_synonyms_list: 'Station Synonyms',
      station_providers_list: 'Station Providers',
      rail_roads_list: 'Rail Roads',
      rail_agents_list: 'Rail Agents'
    },
    insurance: {
      settings_list: 'Insurance Settings',
      connections_list: 'Insurance Connections',
      packets_list: 'Insurance Packets',
      risks_list: 'Insurance Risks',
      controls_list: 'Insurance Control',
    },
    payment: {
      corporate_cards: 'Corporate Cards',
      card_list: 'Card List'
    },
    ttn_avia_vi: {
      settings_search: 'Search settings',
      settings_timelimit: 'Timelimit settings',
      settings_refresh_schedule: 'Buckets refresh schedule',
      connection_time_rule: 'Rules',
      settings_price_limit: 'Price limits',
      settings_restrictions: 'Restrictions',
      settings_system_settings: 'General settings',
      actions_performed_handler: 'Perform worker',
      data_flight_item: 'Flights',
      booking_report: 'Booking report',
      pricer_rules: 'Pricer'
    }
  }
}
