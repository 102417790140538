export default {
  rail_roads_list: {
    table: {
      id: 'ID',
      name: 'Name',
      geoCountryCode: 'Country'
    },
    form: {
      fields: {
        name: 'Name',
        geoCountryCode: 'Country'
      }
    }
  }
}