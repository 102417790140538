import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import enLocale from './en/index'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  }
}
export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // missing: (locale, key, vm) => {
  //   if (!key.includes('crud.')) {
  //     const newKey = key.replace(/^(.*?)\./, 'crud.')
  //     console.log(newKey)
  //     // return vm.$t(newKey)
  //     return newKey
  //   }
  //   return 'eee'
  // },
  // set locale messages
  messages
})

export default i18n
