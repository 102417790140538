<template>
  <div class="card-base card-shadow--medium bg-white black-text pv-15 ph-15">
    <div ref="chart_position" :style="{height:height, width:width}" />
  </div>
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    group: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '250px'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      chart: null,
      source: [],
      data: {
        series: [],
        legends: [],
        x_lines: [...Array(24).keys()].map((i) => `${i}`)
      }
    }
  },

  watch: {},
  mounted() {
    this.rebuild()
    this.getParent('TrTimeReport').$on('updateChart', this.updateChart)
  },
  beforeDestroy() {
    this.getParent('TrTimeReport').$off('updateChart', this.updateChart)
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    __resizeHanlder() {
      if (this.chart) {
        this.chart.resize()
      }
    },

    rebuild() {
      let series = this.data.series.map((i) => {
        return {
          name: i.name,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          showSymbol: false,
          lineStyle: { normal: { width: 1 }},
          data: i.data
        }
      })

      this.chart = echarts.init(this.$refs.chart_position)
      this.chart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 0,
          icon: 'rect',
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: this.data.legends,
          right: '4%',
          textStyle: { fontSize: 10 }
        },
        grid: {
          top: 30,
          left: '3%',
          right: '7%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          name: this.$t('travel_rankings.report_position.charts.xaxis'),
          axisLine: { lineStyle: { color: '#57617B' }},
          data: this.data.x_lines
        }],
        yAxis: [{
          type: 'value',
          name: this.$t('travel_rankings.report_position.charts.yaxis'),
          nameLocation: 'end',
          inverse: false,
          axisTick: { show: true },
          axisLine: { lineStyle: { color: '#57617B' }},
          axisLabel: {
            margin: 10,
            textStyle: { fontSize: 14 }
          },
          splitLine: { lineStyle: { color: '#ddd' }}
        }],
        series: series
      })

      this.$nextTick(() => {
        this.__resizeHanlder()
      })
    },

    updateChart(data) {
      if(data.key !== this.group) {
        return
      }
      this.chart.dispose()
      this.data.legends = []
      this.data.series = []
      for(let i in data.data) {
        for(let rank in data.data[i]) {
          this.data.legends.push(data.data[i][rank].name)
          this.data.series.push(data.data[i][rank])
        }
      }
      this.data = { ...this.data }
      this.chart.dispose()
      this.rebuild()
    },

    getParent(name) {
      let component = null
      let parent = this.$parent
      while (parent && !component) {
        if (parent.$options.name === name) {
          component = parent
        }
        parent = parent.$parent
      }
      return component
    }
  }
}
</script>
pivko.ttndev.com