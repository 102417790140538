import currencies_list from '../../../general/currencies_list'

export default {
  legend: 'penalty_noshow',
  translateLegend: true,
  items: [
    {
      name: 'penaltyNoshowAmount',
      type: 'text',
      valueType: 'float',
      required: false
    },
    {
      name: 'penaltyNoshowCurrency',
      type: 'select',
      store: Object.assign({'%': '%'}, currencies_list),
      required: false
    },
    {
      name: 'penaltyNoshowNonRef',
      type: 'checkbox',
      default: false,
      required: false
    }
  ]

}