export default {
  gds_queue_manager_settings: {
    table: {
      gds: 'GDS',
      office: 'Office',
      aviaConfigId: 'Сonfig ID',
      reason: 'Reason',
      queue: 'Queue',
      archiveQueue: 'Archive queue',
      segmentStatuses: 'Segment statuses',
      deleteStatuses: 'Seg. st. for delete',
      changesStatuses: 'Seg. st. for MCO',
      lastRun: 'Last run',
      runStatus: 'Run status',
      active: 'Active'
    },
    form: {
      fields: {
        gds: 'GDS',
        office: 'Office',
        aviaConfigId: 'Avia config item ID',
        reason: 'Reason',
        queue: 'Queue',
        archiveQueue: 'Archive queue',
        segmentStatuses: 'Segment statuses',
        deleteStatuses: 'Seg. st. for delete',
        changesStatuses: 'Seg. st. for MCO',
        active: 'Active'
      },
      options: {
        reason: {
          cancelation: 'Flight cancellation',
          changes: 'Change of schedule',
          tkt_actualization: 'Ticket numbers actualization'
        }
      }
    }
  },

  gds_queue_manager_locators: {
    table: {
      gdsLocator: 'GDS locator',
      adminLocator: 'Admin locator',
      gds: 'GDS',
      office: 'Office/Config',
      status: 'Status',
      reason: 'Reason',
      segmentDate: 'Segment date',
      mcoType: 'MCO type',
      error: 'Error',
      type: 'Type'
    },
    form: {
      fields: {
        gds: 'GDS',
        office: 'Office',
        aviaConfigId: 'Avia config item ID',
        locator: 'Locator',
        status: 'Status',
        segmentDate: 'Urgent Flight',
        error: 'Error',
        type: 'Type',
        reason: 'Reason'
      },
      options: {
        reason: {
          all: 'All',
          cancelation: 'Flight cancellation',
          changes: 'Change of schedule',
          tkt_actualization: 'Ticket numbers actualization',
          bg_checkin: 'Baggage Checkin',
          additional_services: 'Additional Services',
          sq_checkin: 'Quick Seat Checkin'
        }
      }
    },
    alert: {
      mark_success_confirm: 'Mark selected entries as successful. Are you sure?',
      requeue_confirm: 'Re-queue the selected entries. Are you sure?'
    },
    action: {
      mark_as_success: 'Successful!',
      requeue: 'Re-queue!'
    }
  },

  gds_queue_manager_locator_logs: {
    table: {
      logTime: 'Time',
      userLogin: 'User',
      message: 'Message'
    }
  }
}
