export default {
  name: 'settings_refresh_schedule',
  meta: {
    title: 'settings_refresh_schedule',
    icon: 'skill',
    permission: 'settings_refresh_schedule'
  },
  lazy: true,
  items: [
    {
      name: 'settings_refresh_schedule',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      // paginationType: 'cursor',
      graphql: {
        // name: 'graphRefreshSchedule',
        name: 'settingsRefreshScheduleRule',
        queries: {
          index: {
            id: true,
            intervalSeconds: true,
            dayFromRange: true,
            dayToRange: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '150px'
          },
          {
            name: 'dayFromRange'
          },
          {
            name: 'dayToRange'
          },
          {
            name: 'intervalSeconds'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'TtnAviaMultiSearchService::Graph::RefreshSchedule'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'dayFromRange',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'dayToRange',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'intervalSeconds',
            type: 'text',
            required: true,
            valueType: 'integer'
          }
        ]
      }
    }
  ]
}
