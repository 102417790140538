import settingsSearch from './ttn_avia_vi/settings/search'
import settingsTimelimit from './ttn_avia_vi/settings/timelimit'
import settingsRefreshSchedule from './ttn_avia_vi/settings/refresh_schedule'
import settingsLimits from './ttn_avia_vi/settings/limits'
import settingsPriceConcurrency from './ttn_avia_vi/settings/price_concurrency'
import settingsSystemSettings from './ttn_avia_vi/settings/system_settings'

import connectionTimeRule from './ttn_avia_vi/connection_time/rule'

import actionsPerformedHandler from './ttn_avia_vi/actions/performed_handler'

import dataFlightItem from './ttn_avia_vi/data/flight_item'

import reportsBookingReport from './ttn_avia_vi/reports/booking_report'

import pricerRules from './ttn_avia_vi/pricer/rules'

export default {
  ttn_avia_vi: {
    settings: {
      settings_search: settingsSearch,
      settings_timelimit: settingsTimelimit,
      settings_refresh_schedule: settingsRefreshSchedule,
      settings_limits: settingsLimits,
      settings_price_concurrency: settingsPriceConcurrency,
      settings_system_settings: settingsSystemSettings
    },
    connection_time: {
      connection_time_rule: connectionTimeRule
    },
    actions: {
      actions_performed_handler: actionsPerformedHandler
    },
    data: {
      data_flight_item: dataFlightItem
    },
    reports:{
      booking_report: reportsBookingReport
    },
    pricer:{
      pricer: pricerRules
    }
  }
}
