import restService from './service/rest'
import graphqlService from './service/graphql'
import engine from './../../../engine'

export default function makeCrudModule({
  request,
  item
} = {}) {
  const defaultState = {
    result: {
      items: [],
      total: 0,
      pagination: []
    },
    preload: {},
    preloaded: false,
    history: {
      items: [],
      total: 0
    }
  }

  const service = item.type === 'graphql' ? graphqlService(request, item.endpoint) : restService(request, item.endpoint)
  const prepareResult = (result, method, collection) => {
    if (item.type === 'graphql') {
      const data = result[item.graphql.actions[method]] || result[method]
      return Object.freeze(collection ? {
        items: data.collection,
        total: data.metadata.totalCount,
        pagination: data.metadata.pagination || []
      } : data)
    }
    return result
  }

  const formatError = (err) => {
    if (err.status_code) {
      switch(err.status_code) {
      case 412:
        engine.store.dispatch('user/resetToken').then((res) => {
          engine.router.router.go()
        })
      default:
        return err
      }
    } else {
      return err
    }
  }

  return {
    actions: {
      all: async({ commit }, params) => {
        try {
          if (!service) throw new Error('No service specified!')

          const result = await service.list(params)

          commit('all', prepareResult(result, 'index', true))
        } catch (err) {
          formatError(err)
        }
      },
      preload: async({ commit }, params) => {
        try {
          if (!service) throw new Error('No service specified!')
          commit('setPreload', true)
          const result = await service.preload(params)
          commit('preload', prepareResult(result, 'preload'))
        } catch (err) {
          formatError(err)
        }
      },
      custom: async({ commit }, data) => {
        try {
          if (!service) throw new Error('No service specified!')

          const result = await service.custom(data.url, data.params)
          return prepareResult(result, data.url)
        } catch (err) {
          formatError(err)
        }
      },
      get: async({ commit }, id) => {
        try {
          if (!service) throw new Error('No service specified!')

          const result = await service.get(id)
          return prepareResult(result, 'get')
        } catch (err) {
          formatError(err)
        }
      },
      update: async({ commit }, params) => {
        try {
          if (!service) throw new Error('No service specified!')

          const result = await service.update(params)
          return prepareResult(result, 'update')
        } catch (err) {
          formatError(err)
        }
      },
      create: async({ commit }, params) => {
        try {
          if (!service) throw new Error('No service specified!')

          const result = await service.create(params)
          return prepareResult(result, 'update')
        } catch (err) {
          formatError(err)
        }
      },
      delete: async({ commit }, params) => {
        try {
          if (!service) throw new Error('No service specified!')

          return await service.delete(params)
      } catch (err) {
          formatError(err)
        }
      },
      history: async({ commit }, params) => {
        try {
          if (!service) throw new Error('No service specified!')

          const result = await service.history(params)
          commit('history', prepareResult(result, 'loadHistory', true))
        } catch (err) {
          formatError(err)
        }
      },
      setPreload: async({ commit }, params) => {
        commit('setPreload', params)
      },

      resetState: async({ commit }) => {
        commit('resetState')
      }
    },
    getters: {
      list: (state, getters) => {
        return state.result.items
      },
      total: (state, getters) => {
        return state.result.total
      },
      pagination: (state, getters) => {
        return state.result.pagination
      },
      preload: (state, getters) => {
        return state.preload
      },
      preloaded: (state, getters) => {
        return state.preloaded
      },
      historyList: (state, getters) => {
        return state.history.items
      },
      historyTotal: (state, getters) => {
        return state.history.total
      }
    },
    mutations: {
      all: (state, data) => {
        state.result = data
      },
      preload: (state, data) => {
        state.preload = data
        state.preloaded = true
      },
      history: (state, data) => {
        state.history = data
      },
      setPreload: (state, data) => {
        state.preloaded = data
      },
      resetState: (state) => {
        Object.assign(state, defaultState)
      }
    },
    namespaced: true,
    state: { ...defaultState }
  }
}
