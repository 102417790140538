export default {
  provide() {
    return {
      '$form': this
    }
  },

  data() {
    const __self = this
    return {
      validator: (rule, value, callback) => {
        if (rule.required && !value.value) {
          return callback(new Error(__self.translate('form.required')))
        }
        callback()
      }
    }
  },

  computed: {
    isFilterForm() {
      return this.crudType === undefined
    },

    fields() {
      return this.fieldsets.reduce((acc, i) => acc.concat(i.items), [])
    },
    fieldReference() {
      return this.fields.reduce((acc, i) => {
        acc[i.name] = i
        return acc
      }, {})
    },
    rules() {
      const message = this.translate('form.required')
      const rules = {}
      this.fields.forEach(f => {
        let required = true
        if (
          f.required === false ||
          this.isFilterForm && f.required === undefined ||
          (f.required !== undefined && f.required !== this.crudType && f.required !== true)
        ) {
          required = false
        }
        const rule = { required, message, trigger: 'change' }
        if (this.isFilterForm) {
          rule.validator = this.validator
        }
        rules[f.name] = [rule]
      })
      return rules
    }
  },
  methods: {
    _prepareFieldsets(key) {
      if (this.config[key].fieldsets.length > 0) {
        return this.config[key].fieldsets.map((fs, f_index) => {
          const item = { ...fs }
          let i_index = 0
          item.items = fs.items.filter((e) => {
            const incl = key === 'filter' || !e.scope || e.scope === this.crudType
            if (incl) {
              e.fieldsetIndex = f_index
              e.index = i_index
              i_index += 1
            }
            return incl
          })
          return item
        })
      } else {
        return this.records && this.records[0] ? [{
          items: Object.keys(this.records[0]).map((i, index) => { return { name: i, type: 'text', fieldsetIndex: 0, index } })
        }] : []
      }
    },

    isDisabledField(_field, _legend) {
      return false
    },

    changeFieldValue(field, value) {},

    async reloadDependentTabs() {
      if (this.isFilterForm) {
        return
      }
      await this.$elItem.reloadDependentTabs()
    },

    formatField(value, field) {
      switch(field.valueType) {
        case 'float':
          return parseFloat(value)
        case 'integer':
          return parseInt(value)
        case 'array_of_integer':
          return value.split(field.valueDelimiter || ',').filter(i => !!i).map((i) => parseInt(i))
        case 'array_of_string':
          return value.split(field.valueDelimiter || ',').filter(i => !!i)
        case 'datetime':
          if(value instanceof Date) {
            return this.$moment.utc(value).format()
          } else if(value instanceof Array) {
            return this._.map(value, (x) => { return this.$moment(x).format() })
          } else{
            return null
          }
        case 'datetime_no_tz':
          if(value instanceof Date) {
            return this.$moment(value).format(this.mapDateFormat(field.valueFormat))
          } else{
            return value
          }
        case 'json_as_string':
          return JSON.stringify(JSON.parse(value))
        case 'timestamp':
          if(value instanceof Array) {
            return this._.map(value, (x) => { return this.$moment(x).format('X') })
          } else if (value instanceof String) {
            return this.$moment(value).format('X')
          } else {
            return value
          }
        case 'regexp':
          if (value != null) {
            return field.regexpFormat.replace('%value%', value)
          } else {
            return value
          }
        default:
          return value
      }
    },

    formatFieldReverse(value, field) {
      switch(field.valueType) {
        case 'integer':
        case 'float':
          return '' + (value == null ? '' : value)
        case 'array_of_integer':
          return value.join(field.valueDelimiter || ',')
        case 'array_of_string':
          return value.join(field.valueDelimiter || ',')
        case 'json_as_string':
          return JSON.stringify(value)
        case 'datetime':
          if(value != null) {
            return new Date(this.$moment_timezone.tz(value, 'Europe/Kiev').format())
          }else{
            return value
          }
        case 'datetime_no_tz':
          if(value != null) {
            return new Date(this.$moment(value, this.mapDateFormat(field.valueFormat)).format())
          }else{
            return value
          }
        default:
          return value
      }
    },

    mapDateFormat(value) {
      const formats = {
        'yyyy': 'YYYY',
        'dd': 'DD'
      }
      for(let k in formats) { value = value.replace(k, formats[k]) }
      return value
    },

    async formatDefaultValue(field) {
      switch (field.type) {
        case 'datetime':
          return Date.create(field.default)
        case 'date':
          return Date.create(field.default).toJSON().slice(0, 10)
        case 'daterange':
          return field.default.map(i => Date.create(i).toJSON().slice(0, 10))
        default:
          return field.default
      }
    }
  }
}
