import * as router from './router'
import store from '../store'

import settings from '@/settings'
import ElementCrud from '@/vendor/element-crud'
import Layout from '@/layout'
import request from '@/utils/request'

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
const asyncRoutes = []

asyncRoutes.push({ path: '*', redirect: '/404', hidden: true })

const normalizeChild = (module) => {
  const children = []
  for(let ci in module.children) {
    if (module.children[ci].children) {
      const c2 = normalizeChild(module.children[ci])
      if (c2) {
        children.push(c2)
      }
    } else {
      const c1 = ElementCrud.normalizeModule(module.children[ci])
      if (c1) {
        children.push(c1)
      }
    }
  }
  if (children.length === 0) {
    return false
  }
  module.children = children
  return module
}

const registerCombined = (parts, items) => {
  items.forEach(si => {
    store.registerModule(
      `${parts.concat(si.name).join('/')}`,
      ElementCrud.makeCrudModule({
        request,
        item: si
      })
    )
    ElementCrud.registerComponent(si)

    // register subcombined elements
    if(si.combined && si.combined.length > 0){
      registerCombined(parts, si.combined)
    }
  })
}

const registerStore = (module) => {
  for(let ci in module.children) {
    if (module.children[ci].children) {
      registerStore(module.children[ci])
    } else {
      for(let t in module.children[ci].tabs) {
        module.children[ci].tabs[t].items.forEach(i => {
          let parts = [i.namespace, i.component, i.tab].filter((i) => !!i)
          store.registerModule(
            `${parts.concat(i.name).join('/')}`,
            ElementCrud.makeCrudModule({
              request,
              item: i
            })
          )
          if(i.combined) {
            registerCombined(parts, i.combined)
          }
        })
      }
    }
  }
  return module
}

export default {
  init: () => {
    const permissions = []
    settings.modules.forEach(module => {
      module = ElementCrud.normalizeModule(module)
      permissions.push(module.meta.permissions)
      if (module) {
        asyncRoutes.push(ElementCrud.makeCrudRoute({
          module,
          layout: Layout
        }))
        registerStore(module)
      }
    })
    store.dispatch('permission/apply', permissions)
  },
  router: {
    constantRoutes: router.constantRoutes,
    asyncRoutes: asyncRoutes,
    resetRouter: router.resetRouter,
    router: router.default
  },
  store
}
