<template>
  <div>
    <el-dialog :visible.sync="dialogFormVisible" title="Update Search Configs">
      <el-form :model="form">
        <el-form-item label="Search Configs" :label-width="formLabelWidth">
          <el-input v-model="form.search_configs" type="string" />
        </el-form-item>
       </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button @click="actionConfirm" slot="reference">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  name: 'SearchRuleForm',
  mixins: [common, di],
  props: {
    isVisible: {
      required: false,
      type: Boolean,
      default: () => { return false }
    }
  },
  data: function() {
    return {
      dialogFormVisible: this.isVisible,
      fkID: null,
      pcID: null,
      type: null,
      form: {
        search_configs: "",
      },
      formLabelWidth: '120px'
    }
  },

  methods: {
    reset() {
      this.form.search_configs = ""
    },
    show(configs, type, fkID, pcID) {
      this.reset()
      this.fkID = fkID
      this.type = type
      this.pcID = pcID
      this.form.search_configs = configs
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    actionConfirm() {
      this.performRequest()
    },
    async performRequest() {

      try {
        const query = {
          __args: {
            fkId: this.fkID,
            pcId: this.pcID,
            configs: this.form.search_configs,
            type: this.type
          }
        }

        await this.graphql('updatFakebook', query, 'mutation')

        this.dialogFormVisible = false

        this.$message({
          message: 'Success!',
          type: 'success'
        })

        this.$elItem.$emitAction('refresh')

    } catch (e) {
      this.$message({
        message: e,
        type: 'error'
      })
    }
  }
  }
}
</script>
