import configuration from './configuration/parsing'
import access from './configuration/access'
import validator from './configuration/validator'

export default {
  namespace: 'web_scraper',
  component: 'configuration',
  meta: {
    title: 'web_scraper.configuration',
    icon: 'table'
  },
  tabs: [
    configuration,
    access,
    validator
  ]
}
