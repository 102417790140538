<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:time="scope")
      span {{formatTime(scope.row.time)}}
    template(v-slot:changes="scope")
      custom-table
        custom-row(v-for="(val, field) in JSON.parse(scope.row.changes)" :key="field")
          custom-cell(width="26%")
            span(class="log_field_name") {{field}}:
          custom-cell
            span(class="log_old_value") {{val[0] === '' ? '&quot;&quot;' : val[0]}}
          custom-cell
            span(class="log_new_value") {{val[1]}}
</template>

<script>
  import Base from '@crud_combined/table'

  export default {
    components: {
      Base
    },
    extends: Base,
    methods: {
      formatTime(value) {
        return this.$moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
</script>
