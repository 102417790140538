<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='3' class='row')
          ttn-switch(
            v-model="temp['active']"
            v-bind="attr('active')"
            active-text="Active"
            label='' label-width='0px'
          )
        el-col(:span='4' class='row')
          ttn-input(
            v-model="temp['binStart']"
            v-bind="{ ...attr('binStart'), maxlength: 6, rules: binStartRule }"
            label-width='80px' style='width:150px'
          )
        el-col(:span='4' class='row')
          ttn-input(
            v-model="temp['binEnd']"
            v-bind="{ ...attr('binEnd'), maxlength: 4, rules: binEndRule }"
            label-width='80px' style='width:150px'
          )
      el-row
        el-col(:span='24' class='row')
          ttn-input(
            v-model="temp['forLogins']"
            v-bind="attr('forLogins')"
          )
        el-col(:span='24' class='row')
          ttn-input(
            v-model="temp['userTeamInfo']"
            v-bind="attr('userTeamInfo')"
          )
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  extends: BaseForm,
  data() {
    return {
      binStartRule: [{ required: true, pattern: /([0-9]){6}/, message: 'Field must contain 6 numbers', trigger: 'change' }],
      binEndRule: [{ required: true, pattern: /([0-9]){4}/, message: 'Field must contain 4 numbers', trigger: 'change' }]
    }
  }
}
</script>