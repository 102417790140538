import bookingReport from './reports/booking_report'

export default {
  namespace: 'ttn_avia_vi',
  component: 'reports',
  meta: {
    title: 'ttn_avia_vi.reports',
    icon: 'documentation'
  },
  tabs: [
    bookingReport
  ]
}
