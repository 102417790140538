import cardList from './corporate_cards/card_list'

export default {
  namespace: 'payment',
  component: 'settings',
  meta: {
    title: 'payment.corporate_cards',
    icon: 'chart',
    roles: ['payment']
  },
  tabs: [
    cardList
  ]
}