import dynamicPricing from './pricing/dynamic'

export default {
  namespace: 'avia',
  component: 'pricing',
  meta: {
    title: 'avia.pricing',
    icon: 'table'
  },
  tabs: [
    dynamicPricing
  ]
}
