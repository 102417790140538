import gdsStore from '../../avia/general/gds_store'

export default {
  name: 'pricer',
  meta: {
    title: 'pricer',
    icon: 'edit',
    permission: 'pricer_rules'
  },
  lazy: true,
  items: [
    {
      name: 'pricer_rules',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'pricerRule',
        queries: {
          index: {
           id: true,
            ruleIndex: true,
            airlines: true,
            officeId: true,
            driverId: true,
            routes: true,
            markups: true,
            active: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      stores: {
        paxes: {
          0: 'Any',
          1: 'ADT',
          2: 'CHD',
          3: 'INF'
        },
        percentFrom: {
          0: 'Total',
          1: 'Fare'
        },
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'ruleIndex',
            width: '100px'
          },
          {
            name: 'airlines',
            format: 'array_as_string'
          },
          // {
          //   name: 'officeId',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'driverId',
          //   format: 'array_as_integer'
          // },
          // {
          //   name: 'routes'
          // },
          // {
          //   name: 'markups'
          // },
          {
            name: 'active',
            type: 'switch',
            width: '80px'
          },
          // {
          //   name: 'createdAt'
          // },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
         {
           name: 'update',
           icon: 'el-icon-edit',
           type: 'primary',
           plain: true
         }
       ],
       panel: [
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'TtnAviaMultiSearchService::Pricer::Rule'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'airlines',
            type: 'text',
            operator: 'in_array_string',
          },
          {
            name: 'office_id',
            type: 'text',
            operator: 'in_array_string',
            camelizeLangKey: true
          },
          {
            name: 'driver_id',
            type: 'select',
            operator: 'in_array_int',
            store: gdsStore.arrayById(),
            camelizeLangKey: true,
            storeLabelKeys: ['id', 'name'],
          },
          {
            name: 'routes',
            type: 'text',
            operator: 'like',
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'ruleIndex',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'airlines',
            type: 'text',
            required: true,
            valueType: 'array_of_string',
            default: '00',
            valueDelimiter: ','
          },
          {
            name: 'officeId',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'driverId',
            type: 'multiselect',
            store: gdsStore.arrayById(),
            default: [],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: false,
            // valueType: 'array_of_integer'
          },
          {
            name: 'routes',
            type: 'textarea',
            default: '*-*',
            required: true,
            rows: 5
          },
          {
            name: 'markups',
            type: 'text',
            required: true,
            default: '[]',
            valueType: 'json_as_string'
          },
          {
            name: 'markups__paxType',
            type: 'select',
            operator: 'eq',
            storeKey: 'paxes',
            valueType: 'integer',
            ignore: true
          },
          {
            name: 'markups__value',
            type: 'text',
            required: true,
            valueType: 'string',
            ignore: true
          },
          {
            name: 'markups__percentValue',
            type: 'text',
            required: true,
            valueType: 'string',
            ignore: true
          },
          {
            name: 'markups__percentFrom',
            type: 'select',
            operator: 'eq',
            storeKey: 'percentFrom',
            valueType: 'integer',
            ignore: true

          },
          {
            name: 'markups__daysRange',
            type: 'text',
            default: '',
            required: false,
            ignore: true
          },
          {
            name: 'active',
            type: 'checkbox',
            required: true,
            default: true
          }
        ]
      }
    }
  ]
}
