<template lang="pug">
  el-dialog(:fullscreen="false" :title="translate('modal.change_permissions')" :visible.sync="visible" :close-on-click-modal="false" width="70%" :custom-class="'dialog-edit-form'")
    el-form(ref="dataForm" :model="temp")
      el-form-item
        el-input(v-model="filterText" clearable)
      el-form-item
        el-tree(:data="tree" :props="defaultProps" show-checkbox ref="tree" node-key="id" :filter-node-method="filterNode" default-expand-all)
    div(slot="footer" class="dialog-footer")
      el-button(@click="visible = false") {{ translate('form.cancel') }}
      el-button(type="primary" @click="updateRoles()") {{ translate('form.confirm') }}
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  data() {
    return {
      visible: false,
      filterText: '',
      temp: {},
      defaultProps: {
        children: 'children',
        label: (data, node) => {
          return `${data.type}: ${data.key}`
        }
      }
    }
  },

  computed: {
    tree() {
      return this.$store.getters[`${this.storeName}/preload`].tree ? JSON.parse(this.$store.getters[`${this.storeName}/preload`].tree) : []
    }
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },

  mounted() {
    this.$parent.$on('showPermissioinDialog', (row) => {
      this.temp = row
      this.visible = true
      this.loadData()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showPermissioinDialog')
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.key.indexOf(value) !== -1
    },

    async loadData() {
      this.$nextTick(() => {
        const currentIds = this.temp.backendUserRoles.map((i) => i.backendRoleId)
        const selected = []
        for (let i in currentIds) {
          const node = this.$refs.tree.getNode(currentIds[i])
          const childs = node.childNodes.map((n) => n.id)
          if (childs.filter((c) => currentIds.includes(c)).length === childs.length) {
            selected.push(currentIds[i])
          }
        }
        this.$refs.tree.setCheckedKeys(selected)
      })
    },

    async updateRoles() {
      const ids = this.$refs.tree.getCheckedNodes().map((i) => i.id).concat(this.$refs.tree.getHalfCheckedNodes().map((i) => i.id))
      try {
        const query = {
          __args: {
            id: this.temp.id,
            login: this.temp.login,
            roles: ids
          },
          id: true
        }

        await this.graphql('updateBackendUser', query, 'mutation')
        this.$message({
          message: 'Success!',
          type: 'success'
        })
        this.visible = false
        this.$elItem.$emitAction('refresh')
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    }

  }
}
</script>
