export default {
  items: [
    'selection',
    {
      name: 'id',
      width: '50px'
    },
    {
      name: 'priority',
      width: '75px',
      align: 'center'
    },
    {
      name: 'active',
      type: 'switch',
      width: '75px',
      align: 'center'
    },
    {
      name: 'bsp',
      width: '60px',
      align: 'center'
    },
    {
      name: 'gds',
      format: 'array_as_string'
    },
    {
      name: 'validatingSupplier',
      width: '80px',
      align: 'center'
    },
    {
      name: 'noShowTime',
      align: 'center',
      width: '120px'
    },
    {
      name: 'nonRefTax',
      format: 'array_as_string',
      align: 'center'
    },
    {
      name: 'refundableTax',
      align: 'center',
      format: 'array_as_string'
    },
    {
      name: 'void',
      align: 'right',
      width: '220px'
    },
    {
      name: 'actions',
      width: '70px',
      align: 'center'
    }
  ]
}