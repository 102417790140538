export default {
  packets_list: {
    table: {
      id : 'ID',
      providerName: 'Provider name',
      zone: 'Zone',
      checkedItem: 'Checked Item',
      risks: 'Risks',
    },
    form: {
      fields: {
        index: 'Index',
        providerName: 'Provider name',
        zone: 'Zone',
        checkedItem: 'Checked Item',
      }
    }
  }
}
