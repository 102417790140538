import components from './components'

export default {
  name: 'ryanair',
  meta: {
    title: 'ryanair',
    icon: 'el-icon-turn-off'
  },
  lazy: true,
  items: [
    {
      name: 'ryanair_worker',
      ...components.reportsQraphql(1),
      table: {
        items: components.reportsTableItems()
      },
      actions: {
        row: [
          ...components.reportsRowActions()
        ],
        panel: [
          {
            name: 'change_settings',
            icon: 'el-icon-magic-stick',
            type: 'info',
            params: {
              entity: 'ryanair_parser'
            }
          },
          ...components.reportsPanelActions()
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          ...components.reportsFormItems()
        ]
      }
    }
  ]
}
