<template lang="pug">
  extends /table
  block expand
    div {{scope.row.file}}
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base
}
</script>
