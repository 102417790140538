import dynamicPricing from './avia/pricing/dynamic_pricing'
import fakePnr from './avia/fake_pnr'
import searchPesimisationRules from './avia/search_settings/pesimisation_rules'
import reports from './avia/reports'
import l2bGdsSettings from './avia/l2b/gds_settings'
import l2bStatistics from './avia/l2b/l2b_statistics'
import l2bReports from './avia/l2b/l2b_reports'
import fareFamilies from './avia/fare_families'
import baggageDetails from './avia/flight_details'
import googleFlightsRestrictions from './avia/google_flights/restrictions'
import googleFlightsFeedSettings from './avia/google_flights/feed_settings'
import seatsBlockRules from './avia/seats_block/rules'
import seatsBlockReports from './avia/seats_block/reports'
import seatsBlockGenerators from './avia/seats_block/generators'
import seatsBlockAnalytics from './avia/seats_block/analytics'
import seatsBlockEvents from './avia/seats_block/events'
import substitution from './avia/substitution/from_rankings'
import fareRules from './avia/fare_rules'
import bookings from './avia/bookings'
import lowcostBaggage from './avia/lowcost/baggage'
import cacheWarmupWprker from './avia/cache/warmup_worker'
import parsersRyanair from './avia/parsers/ryanair'
import parsersPaxport from './avia/parsers/paxport'

export default {
  avia: {
    pricing: {
      dynamic_pricing: dynamicPricing
    },
    lowcost_params: {
      lowcost_baggage: lowcostBaggage
    },
    fake_pnr: {
      fake_pnr: fakePnr,
      search_rules: fakePnr,
      statistic: fakePnr,
      segment_cost: fakePnr
    },
    search_settings: {
      pesimisation_rules: searchPesimisationRules
    },
    reports: {
      reports: reports
	  },
    l2b: {
      gds_settings: l2bGdsSettings,
      l2b_statistics: l2bStatistics,
      l2b_reports: l2bReports
    },
    fare_families: {
      fare_families: {
        fare_families_full: fareFamilies,
      },
    },
    flight_details: {
      baggage_data: {
        baggage_details: baggageDetails,
      },
    },
    google_flights:{
      google_flights_restrictions: googleFlightsRestrictions,
      google_flights_feed_settings: googleFlightsFeedSettings
    },
    seats_block:{
      seats_block_rules: seatsBlockRules,
      seats_block_reports: seatsBlockReports,
      seats_block_generators: seatsBlockGenerators,
      seats_block_analytics: seatsBlockAnalytics,
      seats_block_events: seatsBlockEvents
    },
    substitution:{
      substitution: substitution
    },
    fare_rules: {
      fare_rules: {
        fare_rules_full: fareRules
      },
      default_fare_rules: {
        fare_rules_full: fareRules
      },
      fare_rule_templates: {
        fare_rule_templates_full: fareRules
      }
    },
    bookings: {
      bookings: bookings
    },
    cache: {
      cache_warmup_worker: cacheWarmupWprker
    },
    parsers: {
      ryanair: parsersRyanair,
      paxport: parsersPaxport
    }
  }
}
