export default {
  doc_type_rules_list: {
    form: {
      fields: {
        name: 'Name',
        mask: 'Mask',
        gdsId: 'Gds',
        gds_id: 'Gds',
        active: 'Active',
        restriction: 'Restriction',
        citizenship: 'Citizenship',
        ageMax: 'Max. age',
        ageMin: 'Min. age',
        ageBefore: 'Before age',
        expired: 'Expired date',
        hideInCabinet: 'Hide In Cabinet'
      },
      options: {
        name: {
          'NP': 'Internal Passport (NP)',
          'P': 'International Passport (P)',
          'I': 'ID card (I)',
          'B': 'Birth Certificate (B)',
          'A': 'Alien resident card (A)',
          'C': 'Permanent resident card (C)',
          'D': 'Taiwan travel permit for Chinese mainland residents (D)',
          'PD': 'Diplomatic Passport (PD)',
          'PM': 'Mission/Service Passport (PM)',
          'PS': 'Special Passport (PS)',
          'T': 'Refugee travel document and re-entry permit, US Travel document (T)',
          'F': 'Facilitation document (F)',
          'M': 'Military (M)',
          'N': 'Naturalization certificate (N)',
          'V': 'Border crossing card (V)',
        }
      }
    },
    table: {
      name: 'Name',
      mask: 'Mask',
      gdsId: 'Gds',
      active: 'Active',
      restriction: 'Restriction',
      citizenship: 'Citizenship',
      ageMax: 'Max. age',
      ageMin: 'Min. age',
      ageBefore: 'Before age',
      expired: 'Document expiry date',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      hideInCabinet: 'Hide In Cabinet'
    }
  }
}
