<style scoped>
  .row{ padding-right: 10px }
  .row-small{ padding-left: 10px }
  .sbf-fieldset-0{ border: 1px solid #9b0101 }
  .sbf-fieldset-1{ border: 1px solid #062876; margin-top: 20px }
  .sbf-fieldset-fares{ border: 1px solid #f556ff; margin-top: 20px }
  .sbf-fl-bt-block{ padding: 10px 0 0 15px; }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['name']" v-bind="attr('name')" label-width='90px')
        el-col(:span='10' class='row')
          ttn-select(v-model="temp['type']" v-bind="attr('type')" label-width='90px' @change="changeTypeField")
      el-row(v-if="temp['type'] == '0'")
        el-col(:span='6' class='row')
          ttn-select(v-model="temp['generateOptions__driverId']" v-bind="attr('generateOptions__driverId')" label-width='90px')
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['generateOptions__configId']" v-bind="attr('generateOptions__configId')" label-width='90px')
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['generateOptions__requireSeats']" v-bind="attr('generateOptions__requireSeats')" label-width='90px')
      el-row(v-if="temp['type'] == '0'")
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['generateOptions__departureLocation']" v-bind="attr('generateOptions__departureLocation')" label-width='90px')
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['generateOptions__arrivalLocation']" v-bind="attr('generateOptions__arrivalLocation')" label-width='90px')
        el-col(:span='6' class='row')
          ttn-date-picker(v-model="temp['generateOptions__startDate']" v-bind="attr('generateOptions__startDate')" label-width='60px')
        el-col(:span='6' class='row')
           ttn-date-picker(v-model="temp['generateOptions__endDate']" v-bind="attr('generateOptions__endDate')" label-width='60px')
      el-row(v-if="temp['type'] == '0'")
        el-col(:span='18' class='row')
          ttn-multiselect(v-model="temp['generateOptions__weekDays']" v-bind="attr('generateOptions__weekDays')" label-width='90px')
      el-row(v-if="temp['type'] == '0'")
        el-col(:span='6' class='row')
           ttn-input(v-model="temp['generateOptions__validatingSupplier']" v-bind="attr('generateOptions__validatingSupplier')" label-width='120px')
        el-col(:span='6' class='row')
           ttn-input(v-model="temp['generateOptions__flightNumber']" v-bind="attr('generateOptions__flightNumber')" label-width='90px')
        el-col(:span='6' class='row')
           ttn-input(v-model="temp['generateOptions__rbd']" v-bind="attr('generateOptions__rbd')" label-width='90px')

      el-divider

      el-row
        el-col(:span='6' class='row')
           ttn-input(v-model="temp['generateOptions__currency']" v-bind="attr('generateOptions__currency')" label-width='90px')
        el-col(:span='6' class='row')
           ttn-input(v-model="temp['generateOptions__issueOfficeId']" v-bind="attr('generateOptions__issueOfficeId')" label-width='90px')
        el-col(:span='6' class='row')
           ttn-input(v-model="temp['generateOptions__contractId']" v-bind="attr('generateOptions__contractId')" label-width='90px')
        el-col(:span='6' class='row')
           ttn-input(v-model="temp['generateOptions__configKey']" v-bind="attr('generateOptions__configKey')" label-width='90px')

      ttn-textarea(v-model="temp['generateOptions__comment']" v-bind="attr('generateOptions__comment')" label-width='120px')

      fieldset(:class="`el-form-fieldset`" )
        div
          el-row
            el-col(:span='6' class='row')
              ttn-checkbox(v-model="temp['generateOptions__checkPrice']" v-bind="attr('generateOptions__checkPrice')")
            el-col(:span='8' class='row')
              ttn-input(v-model="temp['generateOptions__checkConfigId']" v-bind="attr('generateOptions__checkConfigId')" label-width='120px')
            el-col(:span='8' class='row')
              ttn-input(v-model="temp['generateOptions__locatorConfigId']" v-bind="attr('generateOptions__locatorConfigId')" label-width='120px')

      fieldset(:class="`el-form-fieldset`")
        legend {{ translate(`form.legends.features`) }}
        div
          el-row
            el-col(:span='3' class='row')
              ttn-checkbox(v-model="temp['generateOptions__features__isLcc']" v-bind="attr('generateOptions__features__isLcc')" label-width="50px")
            el-col(:span='3' class='row')
              ttn-checkbox(v-model="temp['generateOptions__features__isCharter']" v-bind="attr('generateOptions__features__isCharter')" label-width="60px")
            el-col(:span='3' class='row')
              ttn-checkbox(v-model="temp['generateOptions__features__showSeatsCount']" v-bind="attr('generateOptions__features__showSeatsCount')" label-width="85px")

      fieldset(class="el-form-fieldset sbf-fieldset-fares" v-if="temp['generateOptions__fares_decoded'] && temp['type'] == '0'")
        legend {{ translate('form.fields.generateOptions__fares') }}

        el-tabs(v-model="fareEditableTabsValue" type="card" editable @edit="handleFareTabsEdit")
          el-tab-pane(label="Base" key='base' name='base')
            ttn-input(v-model="temp['generateOptions__fares_decoded']['name']" v-bind="elAttr('generateOptions__fares__name', `generateOptions__fares_decoded.name`)")
            div(v-for="paxCode in paxTypes" :key="paxCode")
              el-row
                el-col(:span='2' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") {{ paxCode }}
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['fare']" v-bind="elAttr('generateOptions__fares__fare', `generateOptions__fares_decoded.fares.${paxCode}.fare`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['taxes']" v-bind="elAttr('generateOptions__fares__taxes', `generateOptions__fares_decoded.fares.${paxCode}.taxes`)" label-width='60px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['total']" v-bind="elAttr('generateOptions__fares__total', `generateOptions__fares_decoded.fares.${paxCode}.total`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['fare_code']" v-bind="elAttr('generateOptions__fares__fareCode', `generateOptions__fares_decoded.fares.${paxCode}.fare_code`)" label-width='55px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['baggage']" v-bind="elAttr('generateOptions__fares__baggage', `generateOptions__fares_decoded.fares.${paxCode}.baggage`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
              el-row(v-for="baggageDetailsType in baggageDetailsTypes" :key="baggageDetailsType")
                el-col(:span='4' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") -{{ baggageDetailsType }}
                el-col(:span='6' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_sum']" v-bind="elAttr('generateOptions__fares__baggageDetails__dimensionSum', `generateOptions__fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_sum`)" label-width='60px')
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_unit']" v-bind="elAttr('generateOptions__fares__baggageDetails__dimensionUnit', `generateOptions__fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight']" v-bind="elAttr('generateOptions__fares__baggageDetails__weight', `generateOptions__fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight`)" label-width='60px')
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight_unit']" v-bind="elAttr('generateOptions__fares__baggageDetails__weightUnit', `generateOptions__fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fares_decoded']['fares'][paxCode]['baggage_details'][baggageDetailsType]['count']" v-bind="elAttr('generateOptions__fares__baggageDetails__count', `generateOptions__fares_decoded.fares.${paxCode}.baggage_details.${baggageDetailsType}.count`)" label-width='60px')

          el-tab-pane(
            v-for="(fareFamilyItem, index) in temp['generateOptions__fareFamilies_decoded']"
            :key="index+1+''"
            :label="fareFamilyItem.name"
            :name="index+1+''"
          )
            ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['name']" v-bind="elAttr('generateOptions__fares__name', `generateOptions__fareFamilies_decoded.${index}.name`)" :rules="required")
            div(v-for="paxCode in paxTypes" :key="paxCode")
              el-row
                el-col(:span='2' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") {{ paxCode }}
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['fare']" v-bind="elAttr('generateOptions__fares__fare', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.fare`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['taxes']" v-bind="elAttr('generateOptions__fares__taxes', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.taxes`)" label-width='60px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['total']" v-bind="elAttr('generateOptions__fares__total', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.total`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='5' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['fare_code']" v-bind="elAttr('generateOptions__fares__fareCode', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.fare_code`)" label-width='55px' :rules="paxCode == 'ADT' ? required : []")
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['baggage']" v-bind="elAttr('generateOptions__fares__baggage', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.baggage`)" label-width='50px' :rules="paxCode == 'ADT' ? required : []")
              el-row(v-for="baggageDetailsType in baggageDetailsTypes" :key="baggageDetailsType")
                el-col(:span='4' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") -{{ baggageDetailsType }}
                el-col(:span='6' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_sum']" v-bind="elAttr('generateOptions__fares__baggageDetails__dimensionSum', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_sum`)" label-width='60px')
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_unit']" v-bind="elAttr('generateOptions__fares__baggageDetails__dimensionUnit', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.dimension_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight']" v-bind="elAttr('generateOptions__fares__baggageDetails__weight', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight`)" label-width='60px')
                el-col(:span='3' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight_unit']" v-bind="elAttr('generateOptions__fares__baggageDetails__weightUnit', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.weight_unit`)" label-width='30px')
                el-col(:span='4' class='row')
                  ttn-input(v-model="temp['generateOptions__fareFamilies_decoded'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['count']" v-bind="elAttr('generateOptions__fares__baggageDetails__count', `generateOptions__fareFamilies_decoded.${index}.fares.${paxCode}.baggage_details.${baggageDetailsType}.count`)" label-width='60px')

      el-divider

      ttn-textarea(v-model="temp['generateOptions__fareConditions']" v-bind="attr('generateOptions__fareConditions')" label-width='120px')

      div(v-if="temp['type'] == '1'")
        ttn-textarea(v-model="temp['generateOptions__fileContent']" v-bind="attr('generateOptions__fileContent')" label-width='120px')
</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    computed:{
      paxTypes(){ return ['ADT', 'CHD', 'INF'] },
      faresFields(){ return ['fare', 'taxes', 'total', 'fare_code', 'baggage'] },
      baggageDetailsTypes() { return ['baggage',  'carry_on', 'personal_item'] },
      featureFields(){ return ['is_lcc', 'is_charter', 'show_seats_count'] },
      required(){
        const message = this.translate('form.required')
        const required = true
        return [{ required, message, trigger: 'change' }]
      },
      weekDays(){ return [1, 2, 3, 4, 5, 6, 0]}
    },
    extends: BaseForm,
    data() {
      return {
        fareEditableTabsValue: 'base',
        fareTabIndex: 0,
        hiddenBlocks: {
          group_bookings: false,
          from_file: true
        }
      }
    },
    methods: {
      elAttr(fieldName, prop){
        return this.attr(fieldName, Object.assign({}, this.fieldReference[fieldName], {prop: prop}))
      },
      formatTemp(){
        if(this.temp['type'] == '0'){
          if(this.temp['generateOptions__fares_decoded']){
            for(let paxCode in this.temp['generateOptions__fares_decoded']['fares']){
              for(let fareKey in this.temp['generateOptions__fares_decoded']['fares'][paxCode]){
                switch (fareKey) {
                  case 'baggage_details':
                    for (let fareBaggageDetailsType in this.baggageDetailsTypes) {
                      for (let baggageDetailsKey in this.temp['generateOptions__fares_decoded']['fares'][paxCode][fareKey][fareBaggageDetailsType]) {
                        this.temp['generateOptions__fares_decoded']['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey] = this.formatField(this.temp['generateOptions__fares_decoded']['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey], this.fieldReference[`generateOptions__fares__${this._.camelCase(fareKey)}__${this._.camelCase(baggageDetailsKey)}`])
                      }
                    }
                    break;
                  default:
                    this.temp['generateOptions__fares_decoded']['fares'][paxCode][fareKey] = this.formatField(this.temp['generateOptions__fares_decoded']['fares'][paxCode][fareKey], this.fieldReference[`generateOptions__fares__${this._.camelCase(fareKey)}`])
                }
              }
            }
            this.temp['generateOptions__fares'] = JSON.stringify(this.temp['generateOptions__fares_decoded'])
          }

          if(this.temp['generateOptions__fareFamilies_decoded']){
            for(let i in this.temp['generateOptions__fareFamilies_decoded']){
              for(let paxCode in this.temp['generateOptions__fareFamilies_decoded'][i]['fares']){
                for(let fareKey in this.temp['generateOptions__fareFamilies_decoded'][i]['fares'][paxCode]){
                  switch (fareKey) {
                    case 'baggage_details':
                      for (let fareBaggageDetailsType in this.baggageDetailsTypes) {
                        for (let baggageDetailsKey in this.temp['generateOptions__fareFamilies_decoded'][i]['fares'][paxCode][fareKey][fareBaggageDetailsType]) {
                          this.temp['generateOptions__fareFamilies_decoded'][i]['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey] = this.formatField(this.temp['generateOptions__fareFamilies_decoded'][i]['fares'][paxCode][fareKey][fareBaggageDetailsType][baggageDetailsKey], this.fieldReference[`generateOptions__fares__${this._.camelCase(fareKey)}__${this._.camelCase(baggageDetailsKey)}`])
                        }
                      }
                      break;
                    default:
                      this.temp['generateOptions__fareFamilies_decoded'][i]['fares'][paxCode][fareKey] = this.formatField(this.temp['generateOptions__fareFamilies_decoded'][i]['fares'][paxCode][fareKey], this.fieldReference[`generateOptions__fares__${this._.camelCase(fareKey)}`])
                  }
                }
              }
            }
            this.temp['generateOptions__fareFamilies'] = JSON.stringify(this.temp['generateOptions__fareFamilies_decoded'])
          }
        }

        let features = {}
        for(let i of this.featureFields){
          features[i] = this.formatField(this.temp[`generateOptions__features__${this._.camelCase(i)}`], this.fieldReference[`generateOptions__features__${this._.camelCase(i)}`])
        }
        this.temp['generateOptions__features'] = JSON.stringify(features)

        return this.temp
      },

      defaultFareFamily(){
        return { name: '', fares: this.paxTypes.reduce((o, key) => ({
            ...o, [key]: {
              baggage_details: this.baggageDetailsTypes.reduce((bo, bkey) => ({ ...bo, [bkey]: {} }), {})
            }}), {})
        }
      },
      beforeCreateCallback(){
        this.resetFareFamilyTabs()
        this.temp = Object.assign(
          {},
          this.temp,
          {
            generateOptions__fares_decoded: this.defaultFareFamily(),
            generateOptions__fareFamilies_decoded: []
          }
        )
      },
      beforeUpdateCallback() {
        this.crudType = 'create'
        this.resetFareFamilyTabs()
        this.temp = Object.assign(
          {},
          this.temp,
          {
            id: 0,
            generateOptions__fares_decoded: JSON.parse(this.temp.generateOptions__fares),
            generateOptions__fareFamilies_decoded: JSON.parse(this.temp.generateOptions__fareFamilies)
          }
        )
      },
      resetFareFamilyTabs(){
        this.fareEditableTabsValue = 'base'
        this.fareTabIndex = 0
      },
      handleFareTabsEdit(targetName, action){
        if (action === 'add') {
          this.temp['generateOptions__fareFamilies_decoded'].push(this.defaultFareFamily());
          this.fareEditableTabsValue = ++this.fareTabIndex + '';
        }
        if (action === 'remove' && targetName != 'base') {
          const tab_index = parseInt(targetName) - 1
          if(this.fareEditableTabsValue === targetName){
            if(!this.temp['generateOptions__fareFamilies_decoded'][tab_index + 1]){
              this.fareEditableTabsValue = (tab_index == 0 ? 'base' : tab_index.toString())
            }
          }
          this.fareTabIndex--
          this.temp['generateOptions__fareFamilies_decoded'].splice(tab_index, 1)
        }
      },
      changeTypeField(value){
        this.config.form = { ...this.config.form }
        this.$nextTick(() => {
          this.$refs.dataForm.clearValidate()
        })
      },
      updateData(){
        this.createData()
      }
    }
  }
</script>
