<template lang="pug">
  extends /form
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  extends: BaseForm,
  watch: {
    'temp.gds'(val) {
      this.changeRules(val)
    }
  },
  methods: {
    changeRules(val) {
      const isSig = val === 'sig'
      const office = this.fieldReference.office
      const configId = this.fieldReference.aviaConfigId
      this.config.form.fieldsets[office.fieldsetIndex].items[office.index].required = !isSig
      this.config.form.fieldsets[configId.fieldsetIndex].items[configId.index].required = isSig
      this.config.form = { ...this.config.form }
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    }
  }
}
</script>