import reports from "../reports";

export default {
  changeSettingsWindow: {
    title: 'Change settings',
    description: 'Description: config entity - ',
    msg: {
      title: 'Message',
      load_error: 'Settings not loaded',
      for_error_fields: 'Errors in fields: ',
      success_config: 'Config changed'
    },
    form: {
      ryanair_parser: {
        schedule: 'Schedule',
        allowed_attempts: 'Allowed attempts',
        validating_supplier: 'Validating sup.',
        fare_code: 'Fare code',
        rbd: 'Booking class',
        rbd_type: 'Class type',
        aircraft: 'Aircraft',
        zero_inf_fare: 'Zero inf?',
        baggage_details: 'Baggage',
        carry_on_details: 'Carry on',
        create_seats_block: 'Create SB',
        seats_block_contract_id: 'SB contract ID',
        seats_block_config_key: 'SB config key',
        seats_block_seats_count: 'SB seats count',
        create_vi_records: 'Create VI',
        vi_office_id: 'VI office ID',
        seats_block_comment: 'Comment',
        static_fares_fare: 'Fare',
        static_fares_taxes: 'Taxes',
        static_fares_total: 'Total',
        static_fares_currency: 'Currency',
        baggage_width: 'Size (WxLxH in (CM/IN))',
        baggage_length: 'X',
        baggage_height: 'X',
        baggage_dimention_unit: 'in',
        baggage_weight: 'Weight (XX-(KG/.))',
        baggage_weight_unit: '-',
        baggage_count: 'Count',
        schedule: {
          days_range: 'Days range',
          value: 'Value (10hh,22hh | 4h | 60m)',
        },
        fieldset: {
          seats_blocks: 'SeatsBlocks',
          ttn_vi: 'TtnAviaVI',
          schedule: 'Schedule',
          static_fares: 'Static fares',
          baggage: 'Baggage details'
        }
      },
      paxport_api_parser: {
        login: 'Login',
        password: 'Password',
        url: 'Url',
        maxDaysInRequest: 'Max days in request',
        viOfficeId: 'VI office ID',
        saveLogs: 'Save logs'
      }
    }
  },
  reportsTable: {
    id: 'ID',
    jobType: 'Job type',
    lastStartTime: 'Last start time',
    jobStatus: 'Job status',
    sessionId: 'Session',
    message: 'Message',
    filePath: 'File path',
    file: 'File?',
    params: 'Params',
    attemptsCount: 'Attempts'
  },
  reportsFormFields: {
    id: 'ID',
    jobType: 'Job type',
    lastStartTime: 'Last start time',
    jobStatus: 'Job status',
    sessionId: 'Session',
    message: 'Message',
    filePath: 'File path',
    params: 'Params'
  },
  reportsFormOptions: {
    jobType: {
      1: 'RyanairWorker'
    },
    jobStatus: {
      1: 'Initalized',
      2: 'Working',
      3: 'Waiting next start',
      4: 'Error',
      5: 'Success',
    }
  },
  reportsActions: {
    change_settings: 'Change settings'
  },
  reportsMessages: {
    download_error: 'File not exist',
    download_empty_content: 'File is empty'
  },
  settingsTable: {
    id: 'ID',
    name: 'Name',
    parserType: 'Parser type',
    options: 'Options',
    active: 'Active',
    lastRunTime: 'Last run time'
  },
  settingsFormFields: {
    id: 'ID',
    name: 'Name',
    parserType: 'Parser type',
    options: 'Options',
    active: 'Active',
    lastRunTime: 'Last run time'
  }
}
