export default {
  agents: {
    table: {
      name: 'Name',
      apiKey: 'Key',
      webhookUrl: 'Webhook url',
      active: 'Active'
    },
    form: {
      fields: {
        name: 'Name',
        webhookUrl: 'Webhook url',
        apiKey: 'Key',
        active: 'Active',
      },
      legends: {
        default: ''
      }
    }
  }
}
