export default {
  namespace: 'railway',
  component: 'timetable',
  meta: {
    title: 'railway.timetable',
    icon: 'calendar4-week'
  },
  tabs: [
    {
      name: 'timetable.agencies',
      items: [
        {
          name: 'agencies_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'timetableAgencys',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                name: true,
                url: true,
                language: true
              }
            }
          },
          table: {
            items: [
              'selection', 'id', 'name', 'url', 'language',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Timetable::AgencyRelation'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'url',
                type: 'text',
                required: false
              },
              {
                name: 'timezone',
                type: 'text',
                required: true
              },
              {
                name: 'lang',
                type: 'text',
                required: true
              }
            ]
          }
        }
      ]
    },
    {
      name: 'timetable.calendar_dates',
      items: [
        {
          name: 'calendar_dates_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'timetableCalendarDates',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                serviceId: true,
                date: true
              }
            }
          },
          table: {
            items: [
              'selection', 'serviceId', 'date',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Timetable::CalendarDateRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'service_id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_calendar_dates'
              },
              {
                name: 'date',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_calendar_dates'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'service_id',
                type: 'text',
                required: true
              },
              {
                name: 'date',
                type: 'text',
                required: true
              },
              {
                name: 'exception_type',
                type: 'text',
                required: true
              }
            ]
          }
        }
      ]
    },
    {
      name: 'timetable.routes',
      items: [
        {
          name: 'routes_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          lazy: true,
          graphql: {
            name: 'timetableRoutes',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                agencyName: true,
                shortName: true,
                longName: true,
                routeType: true,
                origin: true,
                originCode: true,
                destination: true,
                destinationCode: true
              }
            }
          },
          stores: {
            routeTypes: {
              0: 'Tram',
              1: 'Subway',
              2: 'Rail',
              3: 'Bus',
              4: 'Ferry',
              5: 'Cable tram',
              6: 'Aerial lift',
              7: 'Funicular',
              8: 'Trolleybus',
              9: 'Monorail'
            }
          },
          table: {
            items: [
              'selection', 'id', 'agencyName', 'shortName', 'origin', 'originCode', 'destination', 'destinationCode',
              {
                name: 'routeType',
                format: 'from_store',
                storeKey: 'routeTypes'
              },
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Timetable::RouteRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_routes'
              },
              {
                name: 'agency_id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_routes'
              },
              {
                name: 'short_name',
                type: 'text',
                operator: 'like',
                scope: 'timetable_routes'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'agency_id',
                type: 'text',
                required: true
              },
              {
                name: 'short_name',
                type: 'text',
                required: true
              },
              {
                name: 'long_name',
                type: 'text',
                required: true
              },
              {
                name: 'route_type',
                type: 'text',
                required: true
              }
            ]
          }
        }
      ]
    },
    {
      name: 'timetable.stop_times',
      items: [
        {
          name: 'stop_times_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'timetableStopTimes',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                tripId: true,
                headsign: true,
                arrivalTime: true,
                arrivalDatetime: true,
                departureTime: true,
                departureDatetime: true,
                stopSequence: true,
                stopId: true,
                shapeDistTraveled: true,
                stopName: true,
                stopCode: true,
                shortName: true,
                date: true
              }
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'tripId',
                width: '70px'
              }, {
                name: 'shortName',
                width: '120px'
              }, 'headsign', {
                name: 'date',
                width: '140px'
              }, {
                name: 'arrivalDatetime',
                width: '140px'
              }, {
                name: 'departureDatetime',
                width: '140px'
              }, {
                name: 'stopSequence',
                width: '120px'
              },
              'stopId', 'stopName', 'stopCode', 'shapeDistTraveled',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Timetable::StopTimeRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_stop_times'
              },
              {
                name: 'trip_id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_stop_times'
              },
              {
                name: 'stop_id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_stop_times'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'trip_id',
                type: 'text',
                required: true
              },
              {
                name: 'arrival_time',
                type: 'text',
                required: true
              },
              {
                name: 'departure_time',
                type: 'text',
                required: true
              },
              {
                name: 'stop_sequence',
                type: 'text',
                required: true
              },
              {
                name: 'stop_id',
                type: 'text',
                required: true
              },
              {
                name: 'shape_dist_traveled',
                type: 'text',
                required: true
              }
            ]
          }
        }
      ]
    },
    {
      name: 'timetable.stops',
      items: [
        {
          name: 'stops_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'timetableStops',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                code: true,
                name: true,
                lat: true,
                lon: true,
                locationType: true,
                parentStation: true,
                timezone: true
              }
            }
          },
          stores: {
            locationTypes: {
              0: 'Stop/Platform',
              1: 'Station',
              2: 'Entrance/Exit',
              3: 'Generic Node',
              4: 'Boarding Area'
            }
          },
          table: {
            items: [
              'selection', 'id', 'code', 'name', 'lat', 'lon', { name: 'locationType', format: 'from_store', storeKey: 'locationTypes' }, 'parentStation', 'timezone',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Timetable::StopRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_stops'
              },
              {
                name: 'code',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_stops'
              },
              {
                name: 'name',
                type: 'text',
                operator: 'like',
                scope: 'timetable_stops'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'code',
                type: 'text',
                required: true
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'lat',
                type: 'text',
                required: true
              },
              {
                name: 'lon',
                type: 'text',
                required: true
              },
              {
                name: 'location_type',
                type: 'text',
                required: true
              },
              {
                name: 'parent_station',
                type: 'text',
                required: false
              },
              {
                name: 'timezone',
                type: 'text',
                required: true
              }
            ]
          }
        }
      ]
    },
    {
      name: 'timetable.transfers',
      items: [
        {
          name: 'transfers_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'timetableTransfers',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                fromStopId: true,
                toStopId: true,
                type: true,
                minTransferTime: true,
                fromTrip: true,
                toTrip: true
              }
            }
          },
          table: {
            items: [
              'selection', 'id', 'fromStopId', 'toStopId', 'type', 'minTransferTime', 'fromTrip', 'toTrip',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Timetable::TransferRelation'
                }
              }
            ]
          }
        }
      ]
    },
    {
      name: 'timetable.trips',
      items: [
        {
          name: 'trips_list',
          endpoint: '/persistance/general/ttn_railway_directory',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'timetableTrips',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                routeId: true,
                serviceId: true,
                date: true,
                headsign: true,
                shortName: true,
                directionId: true
              }
            }
          },
          table: {
            items: [
              'selection', 'id', 'routeId', 'serviceId', 'date', 'headsign', 'shortName', 'directionId',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Timetable::TripRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'route_id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_trips'
              },
              {
                name: 'trip_id',
                type: 'text',
                operator: 'eq',
                scope: 'timetable_trips'
              },
              {
                name: 'short_name',
                type: 'text',
                operator: 'like',
                scope: 'timetable_trips'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'route_id',
                type: 'text',
                required: true
              },
              {
                name: 'service_id',
                type: 'text',
                required: true
              },
              {
                name: 'headsign',
                type: 'text',
                required: true
              },
              {
                name: 'short_name',
                type: 'text',
                required: true
              },
              {
                name: 'direction_id',
                type: 'text',
                required: true
              }
            ]
          }
        }
      ]
    }
  ]
}