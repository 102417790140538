export default {
  namespace: 'orders',
  component: 'order_items',
  meta: {
    title: 'orders.items',
    icon: 'table'
  },
  tabs: [
    {
      name: 'items',
      items: [
        {
          name: 'avia_bookings',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'orderItems',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                locator: true,
                status: true,
                orderedAt: true,
                orderCartId: true,
                depot: {
                  name: true
                },
                trafficSource: {
                  name: true
                },
                booking: {
                  status: true,
                  hasAncillaryServices: true,
                  comments: {
                    data: true,
                    createdAt: true,
                    backendUser: {
                      login: true
                    }
                  }
                },
                fakeBooking: {
                  status: true
                },
                aviaBookings: {
                  id: true,
                  supplier: true,
                  bspCost: true,
                  depotCost: true,
                  priceScheme: true,
                  departureTime: true,
                  locator: true,
                  aviaBookingSegments: {
                    supplier: true,
                    operatingSupplier: true,
                    marketingSupplier: true,
                    departure: true,
                    departureDate: true,
                    departureTime: true,
                    arrival: true,
                    arrivalDate: true,
                    arrivalTime: true
                  },
                  aviaBookingPassengers: {
                    type: true,
                    gender: true,
                    firstName: true,
                    lastName: true,
                    birthDate: true,
                    documentExpiration: true,
                    document: true
                  },
                  externalInfo: true
                }
              }
            }
            // preloadFields: ['depots']
          },
          table: {
            items: [
              'id'
            ]
          },
          actions: {
            row: [],
            panel: [
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            fieldsets: [
              {
                legend: 'order',
                items: [
                  {
                    name: 'status',
                    type: 'text',
                    operator: 'eq'
                  },
                  {
                    name: 'depot_id',
                    type: 'integer',
                    operator: 'eq'
                  },
                  {
                    name: 'traffic_source_id',
                    type: 'integer',
                    operator: 'eq'
                  },
                  {
                    name: 'ordered_at_gte',
                    type: 'text',
                    operator: 'gte'
                  },
                  {
                    name: 'ordered_at_lte',
                    type: 'text',
                    operator: 'lte'
                  },
                  {
                    name: 'locator',
                    type: 'text',
                    operator: 'eq'
                  }
                ]
              },
              {
                legend: 'booking',
                items: [
                  {
                    scope: 'aviaBookings',
                    name: 'supplier',
                    type: 'text',
                    operator: 'eq'
                  },
                  {
                    scope: 'aviaBookings',
                    name: 'depot_cost__currency',
                    type: 'text',
                    operator: 'in_hash_eq'
                  },
                  {
                    scope: 'aviaBookings',
                    name: 'departure_time_gte',
                    type: 'text',
                    operator: 'gte'
                  },
                  {
                    scope: 'aviaBookings',
                    name: 'departure_time_lte',
                    type: 'text',
                    operator: 'lte'
                  }
                ]
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden'
              },
              {
                name: 'locator',
                type: 'text'
              }
            ]
          }
        }
      ]
    }
  ]
}
