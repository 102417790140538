export default {
  settings_refresh_schedule: {
    table: {
      id: 'ID',
      intervalSeconds: 'Every seconds',
      dayFromRange: 'Days to dep. - From',
      dayToRange: 'Days to dep. - To'
    },
    form: {
      fields: {
        id: 'ID',
        intervalSeconds: 'Every seconds (s)',
        dayFromRange: 'Days to dep - From(d)',
        dayToRange: 'Days to dep - To (d)'
      }
    }
  }
}
