import gdsQueueManager from './mco/gds_queue_manager'
import kiwiQueue from './mco/kiwi_queue'

export default {
  mco: {
    gds_queue: {
      gds_queue_manager: gdsQueueManager
    },
    kiwi_queue: {
      kiwi_queue: kiwiQueue
    }
  }
}
