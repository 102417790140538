import { jsonToGraphQLQuery } from 'json-to-graphql-query'

export default {
  methods: {
    buildParams(type, params) {
      switch (this.config.type) {
        case 'graphql':
          return this[`_${type}GraphqlParams`](params)
        default:
          return this[`_${type}RestParams`](params)
      }
    },

    _historyGraphqlParams(params) {
      const query = {}
      let collection = {}
      const cfg = this.config.graphql
      collection = cfg.historyFields.reduce((acc, i) => {
        acc[i] = true
        return acc
      }, {})

      query[params.name || 'loadHistory'] = {
        collection,
        '__args': {
          limit: this.pagination.limit,
          page: this.pagination.page,
          filters: params
        },
        metadata: {
          totalCount: true
        }
      }

      return { query: `{${jsonToGraphQLQuery(query)}}` }
    },

    _historyRestParams(params) {
      return params
    }
  }
}
