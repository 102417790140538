<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    v-bind="labelAttribs(field, fieldset)"
  )
    el-select(
      v-model="dataValue"
      clearable
      filterable
      :placeholder="translate(`form.placeholder.${field.name}`, translate(`form.placeholder.select`))"
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
      el-option(
        v-for="(label, value) in filterOperatorOptions(field)"
        :key="value"
        :label="label"
        :value="value"
      )
</template>

<script>
import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnFilterOperatorSelect',
  mixins: [formElements]
}
</script>
