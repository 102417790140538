<style scoped>
  .ibl{
    border-radius: 15px;
    background: #eeeeee;
    padding: 10px;
    margin: 0 15px 0 0;
    line-height: 15px;
  }
  .ibl-title{ font-weight: bold; font-size: 1.2em; }
  .ibl-row-key{ width: 80px; font-weight: bold; display: inline-block;}
  .divider {
    border-top: 3px dotted #bbb;
    border-bottom: 0px;
    margin: 10px 0;
  }
</style>

<template lang="pug">
  extends /table

  block expand
    div(v-if="scope.row.fullDetails" style="line-height: 15px; word-wrap: break-word")
      div(class="ibl-title") Route
      div(v-for="(tp, ti) in scope.row.fullDetails['s']" :key="ti")
        el-row
          el-col(:span="8" class="col-b-r" v-for="(s, i) in tp" :key="`${ti}_${i}`")
            div(class="ibl")
              h3(class="ibl-row-title") {{s[0]}} - {{s[3]}}
              div(class="ibl-row")
                span(class="ibl-row-key") Departure:
                span(class="ibl-val") {{s[0]}} - {{s[1]}} ({{s[2]}})
              div(class="ibl-row")
                span(class="ibl-row-key") Arrival:
                span(class="ibl-val") {{s[3]}} - {{s[4]}} ({{s[5]}})
              div(class="ibl-row")
                span(class="ibl-row-key") Flight:
                span(class="ibl-val") {{s[7]}}{{s[8]}}
              div(class="ibl-row")
                span(class="ibl-row-key") Class (fare):
                span(class="ibl-val") {{s[9]}} / {{s[10]}} / {{s[11]}}
              div(class="ibl-row")
                span(class="ibl-row-key") Baggage:
                span(class="ibl-val") {{s[13]}}
              div(class="ibl-row")
                span(class="ibl-row-key") Bag. details:
                span(class="ibl-val") {{s[19]}}
              div(class="ibl-row")
                span(class="ibl-row-key") Self transfer:
                span(class="ibl-val") {{s[20]}}

        hr(class="divider")

      div(class="ibl-title") GDS
      el-row
        el-col(:span="8" class="col-b-r")
          div(class="ibl")
            div(class="ibl-row")
              span(class="ibl-row-key") Office ID:
              span(class="ibl-val") {{(scope.row.fullDetails['oi'].includes('|') ? `${scope.row.fullDetails['oi'].replace('|', ' [')}]` : scope.row.fullDetails['oi'])}}
            div(class="ibl-row")
              span(class="ibl-row-key") BSP:
              span(class="ibl-val") {{scope.row.fullDetails['bc']}}
            div(class="ibl-row")
              span(class="ibl-row-key") Driver ID:
              span(class="ibl-val") {{scope.row.fullDetails['gi']}}
            div(class="ibl-row")
              span(class="ibl-row-key") Driver name:
              span(class="ibl-val") {{scope.row.fullDetails['gn']}}

      hr(class="divider")

      div(class="ibl-title") Fares
      el-row
        el-col(:span="8" class="col-b-r" v-for="(f, i) in scope.row.fullDetails['amts']" :key="i")
          div(class="ibl")
            h3(class="ibl-row-title") {{f[0]}}
            div(class="ibl-row")
              span(class="ibl-row-key") Fare:
              span(class="ibl-val") {{f[1]}}
            div(class="ibl-row")
              span(class="ibl-row-key") Fare EQ:
              span(class="ibl-val") {{f[2][1]}}{{f[2][0]}}
            div(class="ibl-row")
              span(class="ibl-row-key") Taxes:
              span(class="ibl-val") {{f[3]}}
            div(class="ibl-row")
              span(class="ibl-row-key") Total:
              span(class="ibl-val") {{f[5]}}
            div(class="ibl-row")
              span(class="ibl-row-key") Card fee:
              span(class="ibl-val") {{f[6]}}
            div(class="ibl-row")
              span(class="ibl-row-key") Taxes list:
              span(class="ibl-val") {{f[4].join('; ')}}

      hr(class="divider")

</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatCell({row, $column, column, cellValue, index}) {
      switch(column.name) {
        case 'departure':
          return `${row['departureCity']} (${row['departureLocation']})`
        case 'arrival':
          return `${row['arrivalCity']} (${row['arrivalLocation']})`
        case 'departureTime':
        case 'arrivalTime':
          return `${this.$moment.unix(row[column.name]).utc().format('YYYY-MM-DD HH:mm')}`
        case 'flights':
          return row["flightKey"].split('_')[2]
        case 'validatingSupplier':
          return row["flightKey"].split('_')[3]
        case 'officeId':
          return (row["fullDetails"] ? row["fullDetails"]['oi'] : '-')
        default:
          return cellValue
      }
    },
    // formatExpandedRow(row){
    // }
  }
}
</script>
