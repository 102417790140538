<template lang="pug">
  extends /table
  block expand
    div custom render child
</template>

<script>
import Base from '@crud_combined/table'

export default {
  extends: Base,
  mounted() {
    console.log(this.elItem, this.elTab)
    console.log(this.getParent(this.elTab))
    this.$elTab.$on('expand_row', this.expandEvent)
  },

  beforeDestroy() {
    this.$elTab.$off('expand_row', this.expandEvent)
  },

  methods: {
    expandEvent(row) {
      console.log('expand!!!', row)
    }
  }
}
</script>
