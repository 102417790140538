export default {
  name: 'lowcost_baggage',
  alwaysShow: true,
  items: [
    {
      name: 'lowcost_search_baggage',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaLowcostParamsBaggages',
        queries: {
          index: {
            id: true,
            branch: true,
            supplier: true,
            airline: true,
            supplierClass: true,
            description: true,
            rules: true,
            descHash: true,
            baggage: true,
            status: true,
            active: true
          }
        },
        preloadFields: ['markets{id name}']
      },
      // preload: true,
      // preloadStores: ['markets'],
      table: {
        defaultSort: {
          column: 'id',
          dir: 'desc'
        },
        items: [
          'selection',
          {
            name: 'id',
            width: '80px'
          },
          'branch',
          {
            name: 'supplier',
          },
          'airline',
          'supplierClass',
          {
            name: 'description',
            readMore: true,
            readMoreChars: 50
          },
          {
            name: 'rules',
            readMore: true,
            readMoreChars: 50
          },
          'baggage',
          {
            name: 'status',
            format: 'from_store',
            storeKey: 'statuses'
          },
          {
            name: 'active',
            type: 'switch'
          },
          {
            name: 'actions',
            width: '100px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::Lowcost::Baggage'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'branch',
                type: 'text',
                operator: 'like',
                scope: 'aviaLowcostBaggages'
              },
              {
                name: 'supplier',
                operator: 'eq',
                type: 'text',
                scope: 'aviaLowcostBaggages'
              },
              {
                name: 'airline',
                operator: 'eq',
                type: 'text',
                scope: 'aviaLowcostBaggages'
              },
              {
                name: 'supplier_class',
                operator: 'eq',
                type: 'text',
                scope: 'aviaLowcostBaggages'
              },
              {
                name: 'status',
                type: 'select',
                button: true,
                operator: 'eq',
                scope: 'aviaLowcostBaggages',
                storeKey: 'statuses'
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  1: 'yes',
                  0: 'no'
                },
                scope: 'aviaLowcostBaggages'
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'airline',
                type: 'text'
              },
              {
                name: 'baggage',
                type: 'text'
              },
              {
                name: 'active',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        ]
      },
      stores: {
        statuses: {
          0: 'New',
          1: 'Active',
          2: 'Inactive',
          3: 'Updated'
        }
      }
    }
  ]
}