<template lang="pug">
  div(v-if="booking")
    div(v-if="upsales.length > 0 || Object.keys(discountInfo).length > 0")
      
      table(border="0" style="width: 100%")
        tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
          th Name
          th Seo
          th Price
          th Original price
          th As part of package

        tr(style="text-align: center" v-for="upsale in upsales")
          td {{ upsale.name }}
          td {{ upsale.seo_name }}
          td {{ upsale.price }} {{ booking.depotCurrency }}
          td {{ originalPrice(upsale) }}
          td(v-html="packageMsg(upsale)")

        template(v-if="discountInfo.direct_as_service")
          tr(style="text-align: center; background-color: #fff8e6; color: #ffba00")
            td Direct as service
            td direct_as_service
            td {{ discountInfo.direct_as_service }} {{ booking.depotCurrency }}
            td
            td -

    div(v-if="highPassOrders.length > 0" style="padding-top: 10px")
      div.bold HighPass Orders
      table(border="0" style="width: 100%; margin-top: 10px")
        tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
          th ID
          th Order
          th Name
          th Status
          th Airport
          th Time
          th Search (Issue) Price
          th Margin
          th Final price
          th Depot price
          th Diff
          th Terms
          th Actions

        tr(style="text-align: center" v-for="order in highPassOrders")
          td {{ order.id }}
          td {{ order.orderNumber }}
          td {{ order.name }}
          td {{ highPassStatuses[order.status] || order.status }}
          td {{ order.airport }} ({{ order.terminal }})
          td {{ order.time }}
          td {{ order.providerPrice }} ({{ order.actualPrice }}) {{ order.providerCurrency }}
          td {{ order.margin }} {{ order.providerCurrency }}
          td {{ order.payedPrice }} {{ order.providerCurrency }}
          td {{ order.price }} {{ order.currency }}
          td(v-html="diffWrap(order)") 
          td
            el-link(v-if="order.termsUrl.length > 0" :href="order.termsUrl" target="_blank" type="primary" style="font-size: 12px") Link
            span(v-else) -
          td

    div(v-if="brbOrder" style="padding-top: 10px")
      div.bold BRB Order
      table(border="0" style="width: 100%; margin-top: 10px")
        tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
          th Product code
          th Service number
          th Base price
          th Depot price
          th Coverage
          th Passengers
          th Status code
          th Status description
          th

        tr(style="text-align: center")
          td {{ brbOrder.code }}
          td {{ brbOrder.serviceNumber }}
          td {{ brbOrder.basePrice }} {{ brbOrder.baseCurrency }}
          td {{ brbOrder.depotPrice }} {{ brbOrder.depotCurrency }}
          td {{ brbOrder.coverage }} {{ brbOrder.baseCurrency }}
          td {{ brbOrder.passengers.join(',') }}
          td {{ brbOrder.status }}
          td {{ brbStatuses[brbOrder.status] || brbOrder.status }}
          td
            el-button(disabled) Purchase

</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],

  props: {
    booking: null
  },

  data() {
    return {
      highPassStatuses: {
        'created': 'Created. Not issued',
        'tarifficated': 'Tarifficated. Not issued',
        'unconfirmed': 'Unconfirmed',
        'overpriced': 'Price changed',
        'canceled': 'Canceled',
        'confirmed': 'Confirmed. Not issued',
        'cancel_pending': 'Cancel pending',
        'issued': 'Issued'
      },
      brbStatuses: {
        'PS': 'Purchase successful',
        'PF': 'Purchase failed',
        'WP': 'Waiting for purchase',
        'IV': 'Inactive'
      }
    }
  },

  computed: {
    upsales() { return JSON.parse(this.booking.additionalServices) },
    discountInfo() { return JSON.parse(this.booking.discountInfoJson) },
    highPassOrders() { return this.booking.highPassOrders },
    brbOrder() { return this.booking.brbOrder }
  },

  methods: {
    packageMsg(upsale) {
      let msg = '-'
      let extraData = upsale.extra_data ? JSON.parse(upsale.extra_data) : false

      if (extraData) {
        if (extraData.package_part) {
          msg = '<span style="color: red;"><b>Yes</b></span>'
        } else if (upsale.se0_name == 'flexible_ticket') {
          msg = `${extraData.flexible_total[this.booking.depotCurrency]} ${this.booking.depotCurrency}`
        }
      }

      return msg
    },

    originalPrice(upsale) {
      let orPrice = '-'

      if (upsale.original_prices) {
        let originalPrices = JSON.parse(upsale.original_prices)
        if (originalPrices && originalPrices[this.booking.depotCurrency]) {
          orPrice = `${originalPrices[this.booking.depotCurrency]} ${this.booking.depotCurrency}`
        }
      }

      return orPrice
    },

    diffWrap(order) {
      if (order.diff == 0) return '-'

      let cssClass = 'green'

      if (order.diff < 0) {
        cssClass = 'red'
      }

      return `<span class='${cssClass}'>${Math.abs(order.diff)} ${order.providerCurrency}</span>`
    }
  }
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  table {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
    width: 100%;
  }

  table tr { background-color: #fff; vertical-align: middle }
  table tr.route { vertical-align: middle; height: 40px }
  table tr td { padding: 5px }
  table tr th { padding: 5px }
</style>