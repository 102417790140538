<template lang="pug" src="./../templates/table.pug" />

<script>
import table from './../../mixin/table/base'
import common from './../../mixin/common'
import tableRemote from './../../mixin/table/remote'
import di from './../../mixin/di'
import component from './../../mixin/component'

export default {
  name: 'CrudCTable',
  mixins: [common, table, tableRemote, di, component],
  props: {
    config: {
      required: true,
      type: Object
    },
    records: {
      required: true,
      type: Array
    },
    parentFilters: {
      required: true,
      type: Object
    }
  }
}
</script>
