export default {
  search: {
    form: {
      fields: {
        adt: 'ADT',
        chd: 'CHD',
        inf: 'INF',
        passengers: 'Passengers',
        key: '',
        refid: 'Refid',
        domain: 'Domain/Key',
        serverScheme: '',
        serverDomain: '',
        withLink: 'Link',
        reportType: 'Mode',
        departure: '',
        arrival: '',
        route: 'Route',
        date: 'Dates',
        departureDate: '',
        arrivalDate: 'Date',
        airline: 'Airlines',
        configs: 'Configs',
        loadKeyButton: 'Load key'
      },
      legends: {
        main: 'Main filter',
        extended: 'Extended filter'
      },
      placeholder: {
        key: 'Api key',
        departure: 'Departure',
        arrival: 'Arrival',
        airline: 'Airlines',
        configs: 'Config ids',
        server: 'Server url',
        search: 'Type to search'
      }
    },
    table: {
      blocked: 'Blocked',
      multi: 'Multiticket',
      total: 'Total',
      results: 'Results',
      calculation: 'Calculation',
      time: 'Time',
      trafficSource: 'Traffic source',
      apiKey: 'API key',
      searchTime: 'Found in {time} sec',
      tooltip: {
        session: 'Session',
        config: 'Config'
      }
    },
    commercial: {
      config: 'Config',
      acquiring: 'Acquiring',
      with_empty: 'With empty',
      submit: 'Start'
    },
    copy: {
      submit: 'Parsing',
      textarea: 'Insert parameters from booking',
      invalid_format: 'Invalid format!!!'
    }
  },
}
