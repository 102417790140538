export default {
  name: 'aviaFareRuleTemplates',
  exclude: ['actions', 'expand'],
  queries: {
    index: {
      id: true,
      priority: true,
      active: true,
      validatingSupplier: true,
      fareBasis: true,
      bsp: true,
      gds: true,
      beforeDeparture: true,
      beforeDepartureAddFee: true,
      beforeDepartureChangeTo: true,
      beforeDepartureNonRef: true,
      afterDeparture: true,
      afterDepartureAddFee: true,
      afterDepartureChangeTo: true,
      afterDepartureNonRef: true,
      noShow: true,
      noShowAddFee: true,
      noShowChangeTo: true,
      noShowNonRef: true
    }
  }
}