<template lang="pug">
  el-table-column(
    type="index"
    v-on="$listeners"
    v-bind="attribs"
  )
    slot(v-for="slot in Object.keys($slots)" :name="slot" :slot="slot")
    template(v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope")
      slot(:name="slot" v-bind="scope")
</template>

<script>

import tableColumns from './../../../mixin/table/columns'

export default {
  name: 'TtnTableColumnIndex',
  mixins: [tableColumns]
}
</script>
