export default {
  table: {
    id: 'ID',
    name: 'Name',
    description: 'Description',
    sessionId: 'Session',
    reportType: 'Report type',
    handlerStatus: 'Handler status',
    filePath: 'File path',
    reportParams: 'Report params',
    messages: {
      download_error: 'Download valid only for Success status',
      download_empty_content: 'File not valid'
    }
  },
  form_fields: {
    id: 'ID',
    name: 'Name',
    description: 'Description',
    sessionId: 'Session',
    reportType: 'Report type',
    handlerStatus: 'Handler status',
    filePath: 'File path',
    reportParams: 'Report params'
  },
  form_options: {
    handlerStatus: {
      0: 'Undefined',
      1: 'Success',
      2: 'Error',
      3: 'Exception',
      4: 'Started',
    }
  },
  action: {
    regenerate: 'Regenerate'
  },
  messages: {
    selection: {
      title: 'Message',
      no_item_selected: 'No items selected',
      no_active_items: 'No valid items selected'
    },
    performRequest: {
      title: 'Message',
      success: 'Started regenerate for ID: ',
      error: 'Error regenerate for ID: '
    }
  }
}
