<template lang="pug">
  div(class="list-content")
    el-table(:data="records" :stripe="true" @selection-change="eventSelectionChange" v-loading="loading")
      el-table-column(:selectable="isSelectable" type="selection" width="55")
      el-table-column(type="expand")
          template(slot-scope="scope")
            fakePnrSearchRule(v-bind:re_search_rule="scope.row" :config="config" @updateAction="updateAction" @createAction="createAction" @deleteAction="deleteAction")
      el-table-column(prop="id", label="ID")
      el-table-column(:label="translate('form.fields.depots')")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.depots.join(", ") }}
      el-table-column(:label="translate('form.fields.blockedVc')")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.blockedVc.join(", ") }}
      el-table-column(:label="translate('form.fields.l1')")
        template(slot-scope="scope")
          el-switch(:value="scope.row.l1", disabled)
      el-table-column(:label="translate('form.fields.l2')")
        template(slot-scope="scope")
          el-switch(:value="scope.row.l2", disabled)
      el-table-column(:label="translate('form.fields.l3')")
        template(slot-scope="scope")
          el-switch(:value="scope.row.l3", disabled)
      el-table-column(prop="daysBeforeDeparture", :label="translate('form.fields.daysBeforeDeparture')")
      el-table-column(prop="margin", :label="translate('form.fields.margin')")
      el-table-column(prop="rateMargin", :label="translate('form.fields.rateMargin')")
      el-table-column(prop="calculate", :label="translate('form.fields.calculate')")
      el-table-column(:label="translate('form.fields.configCurrency')")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.configCurrency }}
      el-table-column(label="Status")
        template(slot-scope="scope")
          el-switch(:value="scope.row.status", disabled)
      el-table-column(class-name="small-padding" :label="$t('crud.table.actions')" width="70px" align="center")
        template(slot-scope="scope")
          el-button(
            :title="translate(`action.update`)"
            type="warning"
            plain
            size="mini"
            icon="el-icon-edit"
            @click="handleAction('update', scope.row)"
          )
</template>

<script>
import BaseTable from '@crud_view/table'

import fakePnrSearchRule from './re_search_rules'

export default {
  components: {
    BaseTable,
    fakePnrSearchRule
  },
  extends: BaseTable,

  data() {
    return {
    }
  },

  methods: {

    updateAction(row) {
      this.handleAction('update', row)
    },

    createAction(parentId) {
      this.handleAction('create', { parentId: parseInt(parentId) })
    },

    deleteAction(rows) {
      this.eventSelectionChange(rows)
      this.actionDelete()
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-tag.el-tag--warning.margin-bot-5{
    margin-bottom: 5px;
  }

  .el-tag.color-inherit{
    color: inherit;
  }

</style>
