export default {
  name: 'connections',
  lazy: true,
  items: [
    {
      name: 'connections_list',
      endpoint: '/persistance/default',
      pagination: true,
      type: 'graphql',
      graphql: {
        name: 'insuranceConnections',
        queries: {
          index: {
            id: true,
            name: true,
            connection: true,
            nameAlias: true,
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '70px',
            align: 'right'
          }, 'nameAlias', 'name', 'connection', {
                name: 'actions',
                width: '120px',
                align: 'center'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },{
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },{
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Legacy::Insurance::ConnectionRelation'
            }
          },{
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false
          },{
            name: 'name_alias',
            type: 'text',
            required: true
          },{
            name: 'name',
            type: 'text',
            required: true
          },{
            name: 'connection',
            required: false,
            type: 'jsoneditor',
            options: {
              modes: ['text', 'code', 'tree', 'form', 'view'],
              mode: 'code',
              ace: ace
            }
          }
        ]
      }
    }
  ]
}
