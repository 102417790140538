const autoticketingTypes = {
  0: ['Офис бронирования',            {'type' : 'direct'}],
  1: ['UA - LWOU23174 Amadeus',       {'type' : 'direct', 'issue_config_id' : '23', 'grand_access' : false}],
  2: ['КА - Амадеус Украина',         {'type' : 'rts', 'config_index' : 'queue_ka'}],
  6: ['DE - NUEL121QH Amadeus',       {'type' : 'direct', 'issue_config_id' : '38', 'grand_access' : true}],
  10: ['US - Amadeus CHI1S2140',      {'type' : 'direct', 'issue_config_id' : '44', 'grand_access' : true}],
  18: ['RU - LEDR22186 Amadeus',      {'type' : 'direct', 'issue_config_id' : '93', 'grand_access' : true}],
  21: ['KZ - ALAKZ28BW Amadeus',      {'type' : 'direct', 'issue_config_id' : '89', 'grand_access' : false, 'issue_direct_stock' : true}],
  22: ['RU - LEDR2218R Amadeus',      {'type' : 'direct', 'issue_config_id' : '94', 'grand_access' : true}],
  23: ['EE - TLLEE28TI Amadeus',      {'type' : 'direct', 'issue_config_id' : '118', 'grand_access' : true}],
  24: ['DE - BERL122YF Amadeus',      {'type' : 'direct', 'issue_config_id' : '126', 'grand_access' : true}],
  25: ['EE - TLLEE2351 Amadeus',      {'type' : 'direct', 'issue_config_id' : '141', 'grand_access' : true}],
  26: ['EE - MSQR228AU Amadeus',      {'type' : 'direct', 'issue_config_id' : '140', 'grand_access' : false}],
  27: ['UA - E9SI Sabre'],
  29: ['TR - F9BI Sabre'],
  30: ['DE - L3LI Sabre'],
  32: ['RU - MOWR222WU Amadeus',      {'type' : 'rts', 'config_index' : 'queue_MOWR222WU_RU'}],
  34: ['UA - LWOU23222 Amadeus',      {'type' : 'direct', 'issue_config_id' : '29', 'grand_access' : false, 'issue_direct_stock' : true}],
  36: ['KZ - ALAKZ28BN Amadeus',      {'type' : 'direct', 'issue_config_id' : '176', 'grand_access' : false, 'issue_direct_stock' : true }],
  37: ['DE - NUEL121UP Amadeus',      {'type' : 'direct', 'issue_config_id' : '184', 'grand_access' : true}],
  38: ['DE - NUEL121UA Amadeus',      {'type' : 'direct', 'issue_config_id' : '185', 'grand_access' : true}],
  40: ['UA - UFSA Sabre'],
  41: ['DE - K10I Sabre'],
  42: ['DE - H99I Sabre'],
  43: ['UA - 5K1G Sabre'],
  44: ['KZ - ALAKZ270D Amadeus',      {'type' : 'direct', 'issue_config_id' : '201', 'grand_access' : true}],
  45: ['KZ - ALAKZ270D Direct Amadeus', {'type' : 'direct', 'issue_config_id' : '201', 'grand_access' : true, 'issue_direct_stock' : true}],
  46: ['EE - IO8I Sabre'],
  47: ['EE - F7ZI Sabre'],
  48: ['DE - EQ3I Sabre'],
  49: ['PL - UP1I Sabre'],
  50: ['DE - UZ09 Sabre'],
  51: ['RU - LEDR228DQ Amadeus', {'type' : 'direct', 'issue_config_id' : '221', 'grand_access' : true, 'issue_direct_stock' : true}],
  52: ['MD - KIVU228BB Amadeus',       {'type' : 'direct', 'issue_config_id' : '233', 'grand_access' : true}],
  53: ['MD - 4EUI Sabre'],
  54: ['AT - VIEL121PM Amadeus',       {'type' : 'direct', 'issue_config_id' : '245', 'grand_access' : true}],
  55: ['AT - 4EVI Sabre'],
  56: ['RU - MOWR228JJ Amadeus',       {'type' : 'direct', 'issue_config_id' : '239', 'grand_access' : true}],
  57: ['AT - VIEL121PL Amadeus',       {'type' : 'direct', 'issue_config_id' : '275', 'grand_access' : true}],
  58: ['RU - UH1I Sabre'],
  59: ['DE - BERL1237C Amadeus',       {'type' : 'direct', 'issue_config_id' : '425', 'grand_access' : true}],
  60: ['DE - I2QI Sabre'],
  62: ['DE - 7EXI Sabre'],
  63: ['PL - X7AI Sabre'],
  64: ['KZ - Y3GI Sabre'],
  65: ['UA - LWOU23219 Amadeus',      {'type' : 'direct', 'issue_config_id' : '7', 'grand_access' : true}],
  66: ['UA - LWOU23222 Amadeus',      {'type' : 'direct', 'issue_config_id' : '29', 'grand_access' : true}],
  67: ['EE - TLLPS2300 Amadeus',      {'type' : 'direct', 'issue_config_id' : '378', 'grand_access' : true}],
  68: ['UA - AIDL - PS - IEVPS2301', {'type' : 'direct', 'issue_config_id' : '2010', 'grand_access' : false, 'issue_direct_stock_ws' : true}],
  69: ['UA - AIDL - PS - IEVPS2302', {'type' : 'direct', 'issue_config_id' : '2015', 'grand_access' : false, 'issue_direct_stock_ws' : true}],
  70: ['UA - AIDL - PS - IEVPS2304', {'type' : 'direct', 'issue_config_id' : '2020', 'grand_access' : false, 'issue_direct_stock_ws' : true}],
  71: ['UA - AIDL - PS - IEVPS2305', {'type' : 'direct', 'issue_config_id' : '2025', 'grand_access' : false, 'issue_direct_stock_ws' : true}],
  72: ['RU - LEDR22163 Amadeus',      {'type' : 'direct', 'issue_config_id' : '216', 'grand_access' : true}],
  73: ['KZ - ALAKZ2823 Amadeus',      {'type' : 'direct', 'issue_config_id' : '73', 'grand_access' : true}],
  74: ['AZ - BAKKZ28AB Amadeus',      {'type' : 'direct', 'issue_config_id' : '162', 'grand_access' : false}],
  75: ['PL - WAWPQ28UA Amadeus',      {'type' : 'direct', 'issue_config_id' : '45', 'grand_access' : true}],
  76: ['TR - ISTT1227F Amadeus',      {'type' : 'direct', 'issue_config_id' : '151', 'grand_access' : true}],
  98: ['UA - AIDL Amadeus',           {'type' : 'direct', 'issue_config_id' : '378', 'grand_access' : false, 'issue_direct_stock_ws' : true}],
  99: ['RU - LEDR221DQ Amadeus',      {'type' : 'direct', 'issue_config_id' : '1665', 'grand_access' : true}],
  100: ['ES - U6SJ Sabre'],
  101: ['UA - M10I Sabre'],
  102: ['DE - NUEPS2301 Amadeus',     {'type' : 'direct', 'issue_config_id' : '1743', 'grand_access' : true}],
  103: ['RU - SIG-Azimut-A4'],
  104: ['US - 54ZI Sabre'],
  105: ['RU - O1RI Sabre'],
  106: ['IT - 5SXJ Sabre'],
  107: ['IL - TLVAV28AA Amadeus',      {'type' : 'direct', 'issue_config_id' : '2285', 'grand_access' : true}],
  108: ['KZ - 7DFJ Sabre'],
  109: ['AE - DXBAV28AA Amadeus',      {'type' : 'direct', 'issue_config_id' : '2305', 'grand_access' : true}],
  110: ['NL - BMP AMSAE2103 (aerticket)',      {'type': 'rts', 'config_index': 'queue_AMSAE2103_NL'}],
  111: ['PL - AIDL - PS - WAWPS2300', {'type' : 'direct', 'issue_config_id' : '2465', 'grand_access' : false, 'issue_direct_stock_ws' : true}],
  112: ['KZ - AIDL - PS - ALAPS2300', {'type' : 'direct', 'issue_config_id' : '2460', 'grand_access' : false, 'issue_direct_stock_ws' : true}],
  113: ['RU - W5EJ Sabre'],
  114: ['US - 54ZI Sabre'],
  115: ['RU - S7 NDC'],
  116: ['DE - 0HVJ Sabre'],
  117: ['BY - MSQR2212N Amadeus',      {'type' : 'direct', 'issue_config_id' : '2935', 'grand_access' : true}],
  118: ['FR - BMP PARAE214T (aerticket)',      {'type': 'rts', 'config_index': 'queue_PARAE214T_FR'}],
  119: ['IL - BMP TLVAE28AA (aerticket)',      {'type': 'rts', 'config_index': 'queue_TLVAE28AA_IL'}],
  120: ['AT - BMP VIEAE21T5 (aerticket)',      {'type': 'rts', 'config_index': 'queue_VIEAE21T5_AT'}],
  121: ['DE - BMP BERAE23KD (aerticket)',      {'type': 'rts', 'config_index': 'queue_BERAE23KD_DE'}],
  122: ['ES - BMP PMIAE2021 (aerticket)',      {'type': 'rts', 'config_index': 'queue_PMIAE2021_ES'}],
  123: ['UA - IEVU22523 Amadeus',      {'type' : 'direct', 'issue_config_id' : '3172', 'grand_access' : true}],
  124: ['TR - CTN H5CI'],
  125: ['FR - CTN 45GH'],
  126: ['NL - CTN O6EH'],
  127: ['IL - CTN EF2J'],
  134: ['US - NYC1S21XE Amadeus (downtown travel)', {'type': 'downtown', 'config_index': 'queue_NYC1S21XE'}],
  135: ['US - CTN 77RI'],
  136: ['RU - U6-LEDU621TR NDC'],
  137: ['UA - 78UW Galileo', {'type': 'direct', 'issue_config_id': '3547'}],
  3: ['------- BSP RO - Voiaj',               {'type' : 'rts', 'config_index' : 'queue_voiaj_RO'}],
  4: ['------- BSP RU - Авиацентр',           {'type' : 'rts', 'config_index' : 'queue_aviacenter_ats'}],
  5: ['------- BSP RU - Кийавиа Вояж',        {'type' : 'rts', 'config_index' : 'queue_ka_voyaj'}],
  7: ['------- Собственный сток, Авиацентр'],
  8: ['------- КА RTS - Амадеус Украина',     {'type' : 'rts', 'config_index' : 'queue_ka_2'}],
  9: ['------- BSP RU - Випсервис',           {'type' : 'rts', 'config_index' : 'queue_vipservice'}],
  11: ['------- BSP PL - Firstclass',         {'type' : 'rts', 'config_index' : 'queue_first_class_PL'}],
  12: ['------- UA - Тикетс.юэй Габриэль PS'],
  13: ['------- RU - Скайтех Габриэль S7'],
  14: ['------- RU - Скайтех Габриэль PS'],
  15: ['------- DE - Panda Габриэль PS'],
  16: ['------- UA - Panda Accelaero G9'],
  17: ['------- BSP RU - Випсервис Аерофлот', {'type' : 'rts', 'config_index' : 'queue_vipservice_SU'}],
  19: ['------- RU - Випсервис Sabre'],
  20: ['------- RU - Випсервис SU Amadeus',   {'type' : 'rts', 'config_index' : 'queue_vipservice_SU_LED'}],
  28: ['------- EE - Not used'],
  31: ['TK - ISTT1227E Amadeus',      {'type' : 'rts', 'config_index' : 'queue_ISTT1227E_TK'}],
  33: ['------- DE - F7XI Sabre'],
  35: ['------- TR - F57I Sabre'],
  39: ['------- DE - NUEL121US Amadeus',      {'type' : 'direct', 'issue_config_id' : '43', 'grand_access' : true}],
  61: ['------- PL - Y3EI Sabre'],
  77: ['DE - S3UA38 Sabre (aerticket)'],
  78: ['TEST QUEUE - F3LI Sabre of gbie Dfrek.r'],
  79: ['DE - BERAE2101 Amadeus (aerticket) All', {'type': 'rts', 'config_index': 'queue_BERAE2101_DE_all'}],
  80: ['TEST QUEUE - VIEL121PL Amadeus 75', {'type': 'rts', 'config_index': 'queue_VIEL121PL_DE'}],
  81: ['AM - A8IJ Sabre'],
  82: ['Amadeus WS - DEU CGNL121S9',      {'type' : 'direct', 'issue_config_id' : '807', 'grand_access' : true}],
  83: ['UA - 1Y2H Sabre'],
  84: ['KZ - IN9I Sabre'],
  85: ['UA - 3D2H Sabre'],
  86: ['RU - 1ABH Sabre'],
  87: ['KZ - X5AI Sabre'],
  88: ['BY - YZB Sabre'],
  89: ['SE - Y3DI Sabre'],
  90: ['UA - 02YH Sabre'],
  91: ['AM - ZI8I Sabre'],
  92: ['AT - S7 NDC'],
  93: ['UA - farelogix AGDU'],
  94: ['RU - farelogix AF4O'],
  95: ['RU - LEDR221CF Amadeus', {'type' : 'direct', 'issue_config_id' : '954', 'grand_access' : true, 'issue_direct_stock' : true}],
  96: ['DE - S5KJ Sabre'],
  97: ['DE - BERAE2101 Amadeus ALL (aerticket)', {'type': 'rts', 'config_index': 'queue_BERAE2101_DE_all'}],
}

export default {
  forSelectById: () => {
    return _.reduce(autoticketingTypes, (res, v, k) => ( [...res, [ k, _.values(v)[0] ]] ), [])
  },

  officeFor: (tktDriverID) => {
    return autoticketingTypes[tktDriverID] ? autoticketingTypes[tktDriverID][0] : tktDriverID
  },
}
