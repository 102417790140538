<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")

  block custom
    change-settings-window
    mass-change-window
</template>

<script>
import BaseTable from '@crud_view/table'
import ChangeSettingsWindow from './change_settings_window'
import MassChangeWindow from './mass_change_window'

export default {
  components: {
    BaseTable,
    ChangeSettingsWindow,
    MassChangeWindow
  },
  extends: BaseTable,
  computed:{},
  methods: {
    formatExpandedRow(row) {
      return `<b>Settings:</b>${JSON.stringify(row['setting'], null, 2)}`
    },
    actionChangeSettings(params) {
      this.$emit('showChangeSettingsWindow', params.entity)
    },
    actionMassChange(params) {
      this.$emit('showMassChangeWindow', params)
    }
  }
}
</script>
