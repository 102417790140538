export default {
  namespace: 'railway',
  component: 'upsale_services',
  meta: {
    title: 'railway.upsale_services',
    icon: 'cart-plus'
  },
  tabs: [
    {
      name: 'upsale_services',
      items: [
        {
          name: 'upsale_services_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayUpsaleService',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                depotId: true,
                name: true,
                cost: true,
                currency: true,
                enabled: true,
                addlInfo: true,
                value: true,
                createdAt: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              'depotId', 'name',
              {
                name: 'cost',
                width: '70px',
                align: 'right'
              },
              {
                name: 'currency',
                width: '80px',
                align: 'right'
              },
              {
                name: 'enabled',
                type: 'switch',
                width: '80px',
                align: 'right'
              },
              'addlInfo',
              { name: 'value', type: 'switch' },
              'createdAt',
              'updatedAt',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::UpsaleServiceRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'depot_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true
              },
              {
                name: 'name',
                type: 'text',
                operator: 'eq',
                camelizeLangKey: true
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                required: true
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'cost',
                type: 'text',
                valueType: 'float',
                default: '0.0',
                required: false
              },
              {
                name: 'currency',
                // type: 'text',
                required: true
              },
              {
                name: 'enabled',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'addlInfo',
                type: 'yamleditor',
                required: true
              },
              {
                name: 'value',
                type: 'checkbox',
                default: false,
                required: false
              }

            ]
          }
        }
      ]
    }
  ]
}
