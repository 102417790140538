<template lang="pug">
  extends /table
  block custom
    permission-dialog
</template>

<script>
import Base from '@crud_view/table'
import permissionDialog from './permissions'

export default {
  components: {
    permissionDialog
  },
  extends: Base,
  methods: {
    async actionChangeRoles(row) {
      this.$emit('showPermissioinDialog', row)
    }
  }
}
</script>
