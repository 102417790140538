<template lang="pug">
  el-form-item(
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    slot(v-for="slot in Object.keys($slots)" :name="slot" :slot="slot")
    template(v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope")
      slot(:name="slot" v-bind="scope")
</template>

<script>
import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnFormItem',
  mixins: [formElements],
  mounted() {
    this.withFormItem = true
  }
}
</script>
