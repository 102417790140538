export default {
  name: 'actions_performed_handler',
  meta: {
    title: 'actions_performed_handler',
    icon: 'el-icon-eleme',
    permission: 'actions_performed_handler'
  },
  lazy: true,
  items: [
    {
      name: 'actions_performed_handler',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'performedHandler',
        queries: {
          index: {
           id: true,
            handlerType: true,
            handlerStatus: true,
            filePath: true,
            description: true,
            handlerResults: true,
            sessionId: true,
            handlerParams: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'handlerType',
            format: 'from_options'
          },
          {
            name: 'handlerStatus',
            format: 'from_options'
          },
          // {
          //   name: 'filePath'
          // },
          {
            name: 'description'
          },
          {
            name: 'handlerResults'
          },
          {
            name: 'sessionId'
          },
          // {
          //   name: 'handlerParams'
          // },
          // {
          //   name: 'createdAt'
          // },
          {
            name: 'updatedAt'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
        //  {
        //    name: 'update',
        //    icon: 'el-icon-edit',
        //    type: 'primary',
        //    plain: true
        //  }
       ],
       panel: [
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'TtnAviaMultiSearchService::PerformedHandler'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'handlerType',
            type: 'select',
            store: {
              '1': 'connection_time_upload'
            },
            translateOptions: true,
            default: '1',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'handlerStatus',
            type: 'hidden',
            required: false,
            default: '1',
            valueType: 'integer'
          },
          {
            name: 'description',
            type: 'text',
            required: false
          },
          {
            name: 'handlerResults',
            type: 'text',
            required: false
          },
          {
            name: 'handlerParams',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'handlerParams__fileName',
            required: false,
            ignore: true
          },

        ]
      }
    }
  ]
}
