export default {
  seats_block_analytics: {
    form: {
      fields: {
        validatingSupplier: 'Validating supplier',
        contractId: 'Contract ID',
        routeKey: 'Route key',
        remainedSeats: 'Left',
        departureDate: 'Departure',
        depotId: 'Depot',
        justDirect: 'Direct only',
        withProblemOnly: 'With problem only',
        active: 'Active'
      }
    },
    table: {
      route: 'Route',
      flight: 'Flight',
      date: 'Date',
      remainedSeats: 'Left',
      active: 'Active',
      blockPrice: 'SB. price',
      blockPriced: 'SB priced',
      blockPricedDifference: 'Diff',
      cheapestPrice: 'Cheap.',
      cheapestPriceDifference: 'Diff',
      cheapestDayPrice: 'Day 8-22',
      cheapestDayPriceDifference: 'Diff',
      cheapestNightPrice: 'Night 22-8',
      cheapestNightPriceDifference: 'Diff',
      recommendedMarkups: 'Fix On'
    },
    messages:{
      download_error: 'Download valid only for Success status',
      download_empty_content: 'File not valid'
    },
    changeMarkupsWindow: {
      title: 'Change markups for route',
      description: 'Change for',
      form: {
        markups: 'Markups',
      },
      msg:{
        title: 'Message',
        success: 'Markups was changed'
      }
    },
  },
  seats_block_analytics_recommendations: {
    table: {
      flight: 'Flight',
      departureTime: 'Depatrute',
      basePrice: 'Base price',
      sellingPrice: 'Sell. price',
      validatingSupplier: 'Validating',
      isMulti: 'Multi',
      gdsName: 'Gds',
      configId: 'Config',
      markups: 'Markups',
      blockPriceDifference: 'SB. diff.',
    }
  },
  seats_block_analytics_in_other_gds: {
    table: {
      basePrice: 'Base price',
      sellingPrice: 'Sell. price',
      validatingSupplier: 'Validating',
      cardAllowed: 'Card Allowed',
      multiTicket: 'Multi',
      gdsName: 'Gds',
      configId: 'Config',
      blockPriceDifference: 'SB. diff.',
      recommendationDifference: 'Rec. diff'
    }
  },
  seats_block_pricer_rules: {
    form: {
      fields: {
        id: 'ID',
        routes: 'Route',
        validatingSupplier: 'Airline',
        contractId: 'Contract ID',
        flightNumbers: 'Flights',
        active: 'Active',
        updatedAt: 'Updated at',
        index: 'Index',
        comment: 'Comment',

        items__index: 'Index',
        items__fromPrice: 'From',
        items__justDirect: 'Direct only',
        items__daysBeforeDeparture: 'Period',
        items__maxDiscount: 'Max. discout',
        items__minusValue: 'Minus val.',
        items__minusOtherGdsValue: 'Minus other GDS',
        items__minusBaggageRecValue: 'Minus bag. rec',
        items__maxMarkup: 'Max markup',
        items__weekDays: 'Week day',
        items__remainedSeatsRange: 'Remained'
      },
      options: {},
      legends: {
        items: 'Items'
      },
      buttons: {
        add_item: 'Add row'
      },
    },
    table: {
      id: 'ID',
      index: 'Index',
      routes: 'Route',
      validatingSupplier: 'Airline',
      contractId: 'Contract ID',
      flightNumbers: 'Flights',
      active: 'Active',
      comment: 'Comment',
      updatedAt: 'Updated at'
    }
  },
  seats_block_pricer_rules_items: {
    table: {
      index: 'Index',
      from_price: 'From',
      just_direct: 'Direct only',
      days_before_departure: 'Period',
      max_discount: 'Max. discout',
      minus_value: 'Minus val.',
      minus_other_gds_value: 'Minus other GDS'
    }
  }
}
