import Sugar from 'sugar-date'
import makeCrudRoute from './router'
import makeCrudModule from './store'
import ChannelService from './channel'
import { normalizeModule, registerComponent } from './helper'
import * as FormElements from './view/components/form-elements'
import * as DataElements from './view/components/data-elements'
import * as NavigationElements from './view/components/navigation-elements'
import * as TableElements from './view/components/table-elements'

const install = (Vue, opts = {}) => {
  [FormElements, DataElements, NavigationElements, TableElements].forEach((group) => {
    Object.values(group.default).forEach(component => {
      Vue.component(component.name, component)
    })
  })

  Sugar.extend()

  Vue.prototype.$channels = new ChannelService()
}

export default {
  install,
  makeCrudRoute,
  makeCrudModule,
  normalizeModule,
  registerComponent
}
