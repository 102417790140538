<template lang="pug">
  div
    el-card(v-for="(stata_scoped, index) in getLooper(searchStat)", :key='"pc_goupped" + index')
      h3 Group {{index+1}}
      el-row(v-if="scopedDetailsExists(stata_scoped)")
        el-table(:data="getShortenScopeData(stata_scoped)", :row-class-name="tablePriceHeaderClassName")
            el-table-column(label="Formula Price")
              el-table-column(label="PC", prop="book_price")
              el-table-column(label="New", prop="sum_recc_price")
            el-table-column(label="Price Ok?" width="70px")
              template(slot-scope="scope")
                i(:class="priceIsGood(scope.row)")

      el-row(v-for="(stata, locator) in getByLocators(stata_scoped)", :key="locator" class="block-item")
        el-row
          h3 Price Component {{locator}}:
          h5 Session: {{stata[0]["session_id"]}}
        el-row
          el-table(:data="stata", :row-class-name="tablePriceClassName")
            el-table-column(label="Search Config")
              template(slot-scope="scp")
                span {{ scp.row["config_ids"].join(", ") }}
            el-table-column(label="Formula Price")
              el-table-column(label="PC", prop="book_price")
              el-table-column(label="New", prop="recc_price")
            el-table-column(label="Fares")
              template(slot-scope="scp")
                span {{ scp.row["fare_codes"].join(", ") }}
                div(v-if="scp.row['brand'] && scp.row['brand'] != ''")
                  span.green FF: {{ scp.row["brand"] }}

            el-table-column(label="PriceScheme")
              el-table-column(label="startP" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "st_p", false) }}
              el-table-column(label="M" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "0", true) }}
              el-table-column(label="I" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "1", true) }}
              el-table-column(label="C" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "2", true) }}
              el-table-column(label="P" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "3", true) }}
              el-table-column(label="S" width="70px")
                template(slot-scope="scope")
                  div S: {{ sumColumn(scope.row.rec_pr_details, "4", true) }}
                  div Rec: {{ sumColumn(scope.row.rec_pr_details, "segment_cost_amount", false) }}
                  div Book: {{ sumColumn(scope.row.rec_pr_details, "original_cost_amount", false) }}
              el-table-column(label="A" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "5", true) }}
              el-table-column(label="RD" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "6", true) }}
              el-table-column(label="R" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "20", true) }}
              el-table-column(label="Meta" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "meta", false) }}
              el-table-column(label="BspC" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "bsp_c", false) }}
              el-table-column(label="RateM" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "rate_margin", false) }}
              el-table-column(label="URate" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "u_rate_margin", false) }}
              el-table-column(label="Revenue" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "revenue_margin", false) }}
              el-table-column(label="SaleP" width="70px")
                template(slot-scope="scope")
                  span {{ sumColumn(scope.row.rec_pr_details, "sl_p", false) }}
            el-table-column(label="Baggage Match" width="70px")
              template(slot-scope="scope")
                i(:class="checkBaggage(scope.row)")
                span ({{ scope.row["baggage_data"] }})
            el-table-column(label="Price Ok?" width="70px")
              template(slot-scope="scope")
                i(:class="priceIsGood(scope.row)")
            el-table-column(type="expand")
              template(slot-scope="sc")
                div
                  priceLog(:priceRules="decode(priceRules)", :pcId="sc.row['pc_id'].toString()", :recId="sc.row['recc_id']", v-if="priceRules")
                div
                  h3 Recc Segment Cost Log:
                  segmentCost(:rules="getSegmentCost(sc.row, 'segment_cost_rules')")
                div
                  h3 Order Segment Cost Log:
                  segmentCost(:rules="getSegmentCost(sc.row, 'segment_cost_orig_rules')")
</template>

<script>

import priceLog from './priceLog'
import segmentCost from './segmentCost'

export default {

  name: 'FallBack',
  components: {
    priceLog,
    segmentCost
  },
  props: {
    searchStat: String,
    priceRules: String
  },

  data() {
    return {
    }
  },

  methods: {

    getLooper(data) {
      var stata = JSON.parse(data);
      if(stata.length > 0 && Array.isArray(stata[0])) {
        return stata
      }else{
        return [stata]
      }
    },

    decode(item) {
      return JSON.parse(item)
    },

    getSegmentCost(row, field) {
      return row[field]
    },

    priceIsGood(item) {
      return item['price_is_up'] ? 'el-icon-close' : 'el-icon-check'
    },

    checkBaggage(item) {
      return item['baggage_mismatch'] ? 'el-icon-close' : 'el-icon-check'
    },

    getByLocators(stata) {
      var locators = {}

      if(stata.length > 0) {
        stata.forEach((element) => {
          if(!locators[element['pc_locator']]) {
            locators[element['pc_locator']] = []
          }

          locators[element['pc_locator']].push(element)
        })
      }
      return locators
    },

    getShortenScopeData(obj) {
      return [obj[0]]
    },

    scopedDetailsExists(scopedDetails) {      
      if(typeof(scopedDetails[0]) == 'undefined'){
        return false;
      }else{
        if(scopedDetails.length == 0 || scopedDetails[0].length == 1) {
          return false
        }else{
          return true
        }
      }
    },

    tablePriceClassName({ row, rowIndex }) {
      let is_price_up = row['price_is_up']

      if(typeof row['sum_price_is_up'] == 'undefined') {
        is_price_up = row['price_is_up']
      }

      var klass = (is_price_up == false && row['baggage_mismatch'] == false) ? 'status_price_ok' : ''

      if(klass == 'status_price_ok'){
        if( row["c"] == true ){
          klass = 'cheapest_price'
        }
      }

      if( row['book_price'] == row['recc_price'] ){
        klass += ' bold'
      }

      return klass
    },

    tablePriceHeaderClassName({ row, rowIndex }) {
      let is_price_up = row['price_is_up']

      if(typeof row['sum_price_is_up'] == 'undefined') {
        is_price_up = row['price_is_up']
      }

      return (is_price_up == false && row['baggage_mismatch'] == false) ? 'status_price_ok' : ''
    },

    sumColumn(stata, column, rl) {
      var sum = 0
      stata.forEach((element) => {
        if(rl == true) {
          sum += parseFloat(element['rl'][column]['m'])
        }else{
          sum += parseFloat(element[column])
        }
      })
      return sum
    }

  }
}

</script>
