const railway_providers_list = [
  { id: 53, name: 'gioc_tickets' },
  { id: 55, name: 'gioc_sabre' },
  { id: 29, name: 'kz' },
  { id: 59, name: 'cat' },
  { id: 183, name: 'tequila_rail' },
  { id: 184, name: 'regiojet' },
  { id: 185, name: 'mobility' },
  { id: 186, name: 'rail_europe' }
]

export default {
  arrayById: () => {
    return railway_providers_list
  },
  objectByName: () => {
    return railway_providers_list.reduce((res, v) => ({ ...res, [v.name]: v.name }), {})
  }
}