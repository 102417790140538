<template lang="pug">
  div
    table(border="0" style="width: 100%")
      tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
        th(style="width: 50%" :colspan="2") Info
        th Departure
        th Arrival
        th(:colspan="2")

      template(v-for="(segments, tripPartInx) in tripParts")
        tr.route
          td(:colspan="6" v-html="routeFor(segments)")

        template(v-for="(seg, segmentInx) in segments")
          tr(v-if="segmentInx > 0" style="background-color: #FFF099")
            td(:colspan="1") Waiting: {{ waitingFor(seg, segments[segmentInx - 1]) }}
            td(:colspan="5")
              span(v-if="differentAirportOrTerminalFor(seg, segments[segmentInx - 1])") Other terminal or airport

          tr
            td(style="width: 25%")
              div.bold(v-html="flightFor(seg, tripPartInx, segmentInx)")
              div.bold Airline: {{ seg.supplierName }}
              div.bold On the way: {{ duration(seg.duration) }}
              div.bold(v-html="baggageFor(seg.baggage, tripPartInx, segmentInx)" style="display: inline-block")
              template(v-if="seg.baggageDetails && seg.baggageDetails.length > 0")
                i(class="el-icon-circle-plus" v-if="!dispayBaggageInfo[seg.id]" style="margin-left: 5px" @click="toggleBaggaeInfo(seg.id, true)").pointer
                i(class="el-icon-remove" v-else style="margin-left: 5px" @click="toggleBaggaeInfo(seg.id, false)").pointer
            td(style="width: 25%")
              div.bold Aircraft: {{ seg.aircraft }}
              div.bold Class: {{ seg.bookingClass }}, {{ seg.bookingClassType }}
              div.bold
                span Fare code: {{ seg.fareCode }}
                span(v-if="fareFromFakeBooking(seg.id)").red &nbsp;&nbsp;{{ fareFromFakeBooking(seg.id) }}
              template(v-if="booking.virtualInterline")
                div.bold PNR: {{ segmentPnrByPC(seg.id).join(',') }}
                div.bold PCI: {{ segmentPciByPC(seg.id).join(',') }}
            td
              div.bold {{ `${getDictionaryBy('cities', seg.departureCity)} (${getDictionaryBy('countries', seg.departureCountry)})` }}
              div Aeroport: {{ `${getDictionaryBy('airports', seg.departureLocation)}(${seg.departureTerminal}) [${seg.departureLocation}]` }}
              div Departure: {{ `${seg.departureDate} ${seg.departureTime}` }}
            td
              div.bold {{ `${getDictionaryBy('cities', seg.arrivalCity)} (${getDictionaryBy('countries', seg.arrivalCountry)})` }}
              div Aeroport: {{ `${getDictionaryBy('airports', seg.arrivalLocation)}(${seg.arrivalTerminal}) [${seg.arrivalLocation}]` }}
              div Arrival: {{ `${seg.arrivalDate} ${seg.arrivalTime}` }}
            td(style="vertical-align: middle")
              el-button(icon="el-icon-edit" mini type="primary" @click="showEditLocationDialog(seg)")
            td(style="vertical-align: middle")
              el-checkbox(:key="seg.id" disabled)

          tr(v-if="dispayBaggageInfo[seg.id]")
            td(:colspan="6")
              table
                tr
                  td(width="15%" style="background-color:#e6f3ff; color:#000;") Type
                  td(width="15%" style="background-color:#e6f3ff; color:#000;") PTC
                  td(width="10%" style="background-color:#e6f3ff; color:#000;") Count
                  td(width="10%" style="background-color:#e6f3ff; color:#000;") Weight
                  td(width="10%" style="background-color:#e6f3ff; color:#000;") Width
                  td(width="10%" style="background-color:#e6f3ff; color:#000;") Height
                  td(width="10%" style="background-color:#e6f3ff; color:#000;") Length
                  td(width="10%" style="background-color:#e6f3ff; color:#000;") Dimensions
                template(v-for="sDetails in seg.baggageDetails")
                  tr(v-for="sItem in sDetails.items")
                    td {{ sDetails.type }}
                    td {{ sItem.pax.join(', ') }}
                    td {{ sItem.count != undefined ? sItem.count : '-' }}
                    td {{ sItem.weight != undefined ? `${sItem.weight} ${fixUnit(sItem.weightUnit)}` : '-' }}
                    td {{ sItem.width != undefined ? `${sItem.width} ${fixUnit(sItem.dimensionUnit)}` : '-' }}
                    td {{ sItem.height != undefined ? `${sItem.height} ${fixUnit(sItem.dimensionUnit)}` : '-' }}
                    td {{ sItem.length != undefined ? `${sItem.length} ${fixUnit(sItem.dimensionUnit)}` : '-' }}
                    td {{ sItem.dimensionSum ? `${sItem.dimensionSum} ${fixUnit(sItem.dimensionUnit)}` : '-' }}

    el-dialog(title="Edit flight information"
              :visible.sync="editLocationDialog.visible"
              width="800px"
              v-loading="editLocationDialog.loading" 
              element-loading-text="Loading...")
      el-form(:model="editLocationDialog.form" :rules="editLocationDialog.rules" label-position="right" label-width="100px" style="padding: 25px 0")

        el-row(:gutter="10")
          el-col(:span="12")
            el-form-item(label="Trip part" prop="tripPart")
              el-input-number(v-model="editLocationDialog.form.tripPart" style="width: 200px")

            el-form-item(label="Segment ID" prop="segmentId")
              el-input-number(v-model="editLocationDialog.form.segmentId" style="width: 200px")

            el-form-item(label="Aircompany" prop="supplierIata")
              el-input(v-model="editLocationDialog.form.supplierIata" style="width: 200px")

            el-form-item(label="Flight" prop="carrier")
              el-input(v-model="editLocationDialog.form.carrier" style="width: 200px")

            el-form-item(label="Baggage" prop="baggage")
              el-input(v-model="editLocationDialog.form.baggage" style="width: 200px")

            el-form-item(label="Booking class" prop="bookingClass")
              el-input(v-model="editLocationDialog.form.bookingClass" style="width: 200px")

            el-form-item(label="Booking type" prop="bookingClassType")
              el-select(v-model="editLocationDialog.form.bookingClassType")
                el-option(
                  v-for="o in ['economy', 'business', 'first', 'premiumeconomy']" 
                  :label="o"
                  :value="o"
                  :key="o")

            el-form-item(label="Fare code" prop="fareCode")
              el-input(v-model="editLocationDialog.form.fareCode" style="width: 200px")

            el-form-item(label="Aircraft" prop="aircraft")
              el-input(v-model="editLocationDialog.form.aircraft" style="width: 200px")

          el-col(:span="12")
            fieldset(:class="`el-form-fieldset`")
              legend Departure
              el-form-item(label="Airport" prop="departureLocation")
                el-input(v-model="editLocationDialog.form.departureLocation" style="width: 200px")   

              el-form-item(label="Terminal" prop="departureTerminal")
                el-input(v-model="editLocationDialog.form.departureTerminal" style="width: 200px")

              el-form-item(label="Time" prop="departureDateTime")
                el-input(v-model="editLocationDialog.form.departureDateTime" style="width: 200px")

            fieldset(:class="`el-form-fieldset`")
              legend Arrival
              el-form-item(label="Airport" prop="arrivalLocation")
                el-input(v-model="editLocationDialog.form.arrivalLocation" style="width: 200px")  

              el-form-item(label="Terminal" prop="arrivalTerminal")
                el-input(v-model="editLocationDialog.form.arrivalTerminal" style="width: 200px")  

              el-form-item(label="Time" prop="arrivalDateTime")
                el-input(v-model="editLocationDialog.form.arrivalDateTime" style="width: 200px")          

      span.dialog-footer(slot="footer")
        el-button(type="primary" small @click="saveLocationDialogForm()") Save
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins:[common, di],
  props: {
    booking: null,
    reloadBooking: null
  },

  data() {
    return {
      editLocationDialog: {
        visible: false,
        loading: false,
        form: {},
        rules: {}
      },
      dispayBaggageInfo: {}
    }
  },

  computed: {
    tripParts() { return this._.groupBy(this.booking.locations, 'tripPart') },
    dictionary() { return JSON.parse(this.booking.dictionary) },
    priceComponents() { return this.booking.priceComponents },
    additionalInfo() { return JSON.parse(this.booking.additionalInfo) },
    activePriceComponents() { return this._.filter(this.booking.priceComponents, ['status', 1]) }
  },

  methods: {
    getDictionaryBy(key, code) {
      return this.dictionary[key][code] || code
    },

    routeFor(segments) {
      let route = []
      const departure = this._.first(segments)
      const arrival = this._.last(segments)

      route.push(`<b>${this.getDictionaryBy('cities', departure.departureCity)}</b> (${this.getDictionaryBy('countries', departure.departureCountry)}), ${this.getDictionaryBy('airports', departure.departureLocation)}(${departure.departureTerminal})`)
      route.push(`<b>${this.getDictionaryBy('cities', arrival.arrivalCity)}</b> (${this.getDictionaryBy('countries', arrival.arrivalCountry)}), ${this.getDictionaryBy('airports', arrival.arrivalLocation)}(${arrival.arrivalTerminal})`)

      return route.join(' → ')
    },

    duration(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60

      return `${hours} hours ${minutes} minutes`
    },

    segmentPnrByPC(segId) {
      let segPnr = []

      this.activePriceComponents.forEach((pc) => {
        if (this._.indexOf(pc.segmentIds, segId) != -1) {
          segPnr.push(pc.locator)
        }
      })

      return segPnr
    },

    segmentPciByPC(segId) {
      let segPci = []

      this.activePriceComponents.forEach((pc, i) => {
        if (this._.indexOf(pc.segmentIds, segId) != -1) {
          segPci.push(i + 1)
        }
      })

      return segPci
    },

    waitingFor(fromSeg, toSeg) {
      const from = this.$moment(`${fromSeg.departureDate} ${fromSeg.departureTime}`, 'DD.MM.YYYY h:mm')
      const to = this.$moment(`${toSeg.arrivalDate} ${toSeg.arrivalTime}`, 'DD.MM.YYYY h:mm')
      const diff = from.diff(to, 'minutes')
      return this.duration(diff)
    },

    differentAirportOrTerminalFor(fromSeg, toSeg) {
      let result = false

      if (fromSeg.departureLocation != toSeg.arrivalLocation) result = true
      if (fromSeg.departureTerminal != toSeg.arrivalTerminal) result = true

      return result
    },

    showEditLocationDialog(seg) {
      this.editLocationDialog.visible = true
      this.editLocationDialog.form = {
        ...seg,
        departureDateTime: `${seg.departureDate} ${seg.departureTime}`,
        arrivalDateTime: `${seg.arrivalDate} ${seg.arrivalTime}`
      }
    },

    baggageFor(baggage, tripInx, segInx) {
      let baggageInfo = baggage

      if(this.additionalInfo.fake_baggage) {
        this._.each(this.additionalInfo.fake_baggage, (fakeBaggage) => {
          if (fakeBaggage.trip_inx == tripInx && this._.indexOf(fakeBaggage.sgm_inx, segInx) != -1) {
            baggageInfo = `0PC <span style='color: green; font-weight: bold'>(${fakeBaggage.bg_unit})</span>`
          }
        })
      } else if (this.additionalInfo.substitution_v2 && this.additionalInfo.substitution_v2[tripInx] && this.additionalInfo.substitution_v2[tripInx][segInx]) {
        let ss = this.additionalInfo.substitution_v2[tripInx][segInx]
        if (ss.baggage) {
          baggageInfo = `${baggage} <span style='color: red'> (${ss.baggage})</span>`
        }
      }

      return `Baggage: ${baggageInfo}`
    },

    flightFor(seg, tripInx, segInx) {
      let flightInfo = `${seg.supplierIata}-${seg.carrier}`
      if (this.additionalInfo.substitution_v2 && this.additionalInfo.substitution_v2[tripInx] && this.additionalInfo.substitution_v2[tripInx][segInx]) {
        let ss = this.additionalInfo.substitution_v2[tripInx][segInx]
        if (ss.supplierIATA) {
          flightInfo = ss.carrierNumber ? `${flightInfo} <span style='color: red'> (${ss.supplierIATA} - ${ss.carrierNumber})</span>` : `${flightInfo} <span style='color: red'> (${ss.supplierIATA})</span>`
        }
      }
      return `Flight: ${flightInfo}`
    },

    fareFromFakeBooking(segId) {
      let fare = null

      this.activePriceComponents.forEach((pc, i) => {
        pc.segmentIds.forEach((sId, segInx) => {
          if (sId == segId && pc.realFares[segInx]) {
            fare = pc.realFares[segInx]
          }
        })
      })

      return fare
    },

    toggleBaggaeInfo(segId, value) {
      this.dispayBaggageInfo = { ...this.dispayBaggageInfo, [segId]: value }
    },

    fixUnit(unit) {
      return unit || ''
    },

    async saveLocationDialogForm() {
      this.editLocationDialog.loading = true

      const keys = [
        'id',
        'supplierIata',
        'carrier',
        'baggage',
        'bookingClass',
        'bookingClassType',
        'fareCode',
        'aircraft',
        'departureLocation',
        'departureTerminal',
        'departureDateTime',
        'arrivalLocation',
        'arrivalTerminal',
        'arrivalDateTime',
        'tripPart',
        'segmentId'
      ]

      try {
        const query = {
          __args: {
            ...this._.pick(this.editLocationDialog.form, keys)
          }
        }

        let res = await this.graphql('aviaBookingItemSaveLocation', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.editLocationDialog.loading = false
        this.editLocationDialog.visible = false

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.editLocationDialog.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      } 
    }
  }
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  .red { color: red }
  table {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
    width: 100%;
  }

  table tr { background-color: #fff; vertical-align: top }
  table tr.route { vertical-align: middle; height: 40px }
  table tr td { padding: 5px }
  table tr th { padding: 5px }
</style>