export default {
  name: 'cache_warmup_worker',
  meta: {
    title: 'cache_warmup_worker',
    icon: 'donate',
    permission: 'cache_warmup_worker'
  },
  lazy: true,
  items: [
    {
      name: 'cache_warmup_worker',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaCacheWarmupWorker',
        queries: {
          index: {
           id: true,
            depotId: true,
            trafficSourceId: true,
            requestType: true,
            routes: true,
            routeTypes: true,
            departurePeriods: true,
            configIds: true,
            schedule: true,
            active: true,
            forGoogleFeed: true,
            forTtnAviaVi: true,
            lastPerformTime: true,
            createdAt: true,
            updatedAt: true,
            requestParams: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'depotId'
          },
          {
            name: 'trafficSourceId'
          },
          {
            name: 'requestType',
            format: 'from_options'
          },
          // {
          //   name: 'routes'
          // },
          // {
          //   name: 'routeTypes',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'departurePeriods'
          // },
          // {
          //   name: 'configIds',
          //   format: 'array_as_string'
          // },
          {
            name: 'schedule',
            format: 'array_as_string'
          },
          {
            name: 'active',
            type: 'switch'
          },
          // {
          //   name: 'forGoogleFeed',
          //   type: 'switch'
          // },
          // {
          //   name: 'forTtnAviaVi',
          //   type: 'switch'
          // },
          {
            name: 'lastPerformTime'
          },
          // {
          //   name: 'createdAt'
          // },
          {
            name: 'updatedAt'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
         {
           name: 'update',
           icon: 'el-icon-edit',
           type: 'primary',
           plain: true
         }
       ],
       panel: [
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'PersistanceApi::Avia::Cache::WarmupWorker::ItemRepository'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'depot_id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'traffic_source_id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'config_ids',
            type: 'text',
            operator: 'in_array_int',
            camelizeLangKey: true
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'depotId',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'trafficSourceId',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'requestType',
            type: 'select',
            store: {
              0: 'optimized',
              1: 'api_request'
            },
            translateOptions: true,
            default: '0',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'routes',
            type: 'textarea',
            required: true,
            rows: 4
          },
          {
            name: 'routeTypes',
            type: 'multiselect',
            store: [
              { id: 1, name: 'OW' },
              { id: 2, name: 'RT' }
            ],
            default: [],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: false
          },
          {
            name: 'departurePeriods',
            type: 'text',
            required: true
          },
          {
            name: 'configIds',
            type: 'text',
            required: true,
            valueType: 'array_of_integer',
            valueDelimiter: ','
          },
          {
            name: 'schedule',
            type: 'text',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'active',
            type: 'checkbox',
            required: false,
            default: true
          },
          {
            name: 'forGoogleFeed',
            type: 'checkbox',
            required: false,
            default: true
          },
          {
            name: 'forTtnAviaVi',
            type: 'checkbox',
            required: false,
            default: true
          },
          {
            name: 'requestParams',
            type: 'textarea',
            required: true,
            default: '{}',
            row: 3,
            valueType: 'json_as_string'
          }
        ]
      }
    }
  ]
}
