<template lang=pug>
  el-dialog(:title="translate('changeSettingsWindow.title')" :visible.sync="dialogFormVisible" width="1024px")
    div(class="txt-w-header")
      b {{ translate('changeSettingsWindow.description') }}
      span(class="txt-pd-10") {{ description() }}
    el-form(:model="form" v-loading="formLoading" v-if="entity")
      template(v-if="entity == 'ryanair_parser'")
        el-row
          fieldset(:class="`el-form-fieldset`")
            legend {{ translate(`changeSettingsWindow.form.${entity}.fieldset.schedule`) }}

            el-button(@click="drivers[entity].actionAddItem(form, 'schedule')" type="primary") {{ translate('form.add') }}
            div(v-if="form.settings.schedule" v-for="(item, i) in form.settings.schedule")
              el-row
                el-col(:span='8' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.schedule.days_range`)}`" :label-width="formLabelWidth" class="is-required")
                    el-input(v-model="form.settings.schedule[i].days_range")
                el-col(:span='10' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.schedule.value`)}`" label-width="200px" class="is-required")
                    el-input(v-model="form.settings.schedule[i].value")
                el-col(:span='1' class='row')
                  span &nbsp
                el-col(:span='1' class='row')
                  el-button(@click="drivers[entity].actionRemoveItem(form, 'schedule', i)" type="danger" :disabled="drivers[entity].isDeleteRowDisabled(form, 'schedule')" class="sbpr-cnc-b") -

        el-row(class="mt20")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.allowed_attempts`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.allowed_attempts")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.validating_supplier`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.validating_supplier")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.fare_code`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.fare_code")
        el-row
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.aircraft`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.aircraft")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.rbd`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.rbd")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.rbd_type`)}`" :label-width="formLabelWidth" class="is-required")
              el-select(v-model="form.settings.rbd_type" placeholder="Select")
                el-option(v-for="item in classOptions" :key="item.value" :label="item.label" :value="item.value")
        el-row
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.zero_inf_fare`)}`" :label-width="formLabelWidth")
              el-switch(v-model="form.settings.zero_inf_fare")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_details`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.baggage_details")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.carry_on_details`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.carry_on_details")

        el-row
          fieldset(class="el-form-fieldset mt20")
            legend {{ translate(`changeSettingsWindow.form.${entity}.fieldset.static_fares`) }}
            div
              el-row(v-for="(k, i) in paxTypes" :key="k")
                el-col(:span='2' class='row')
                  b {{k}}
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.static_fares_fare`)}`" label-width="60px")
                    el-input(v-model="form.settings.static_fares[k].fare")
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.static_fares_taxes`)}`" label-width="60px")
                    el-input(v-model="form.settings.static_fares[k].taxes")
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.static_fares_total`)}`" label-width="60px")
                    el-input(v-model="form.settings.static_fares[k].total")
                el-col(:span='5' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.static_fares_currency`)}`" label-width="80px")
                    el-input(v-model="form.settings.static_fares[k].currency")

        el-row
          fieldset(class="el-form-fieldset mt20")
            legend {{ translate(`changeSettingsWindow.form.${entity}.fieldset.baggage`) }}
            div
              div(v-for="(k, i) in paxTypes" :key="k")
                el-row(v-for="(bt, j) in baggageType" :key="`${k}_${bt}`")
                  el-col(:span='1' class='row')
                    b {{(bt == 'carry_on' ? k : '---')}}
                  el-col(:span='2' class='row')
                    span {{ bt }}
                  el-col(:span='5' class='row')
                    el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_width`)}`" label-width="150px")
                      el-input(v-model="form.settings[bt][k].wd")
                  el-col(:span='2' class='row')
                    el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_length`)}`" label-width="20px")
                      el-input(v-model="form.settings[bt][k].l")
                  el-col(:span='2' class='row')
                    el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_height`)}`" label-width="20px")
                      el-input(v-model="form.settings[bt][k].h")
                  el-col(:span='2' class='row')
                    el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_dimention_unit`)}`" label-width="30px")
                      el-input(v-model="form.settings[bt][k].du")
                  el-col(:span='5' class='row')
                    el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_weight`)}`" label-width="150px")
                      el-input(v-model="form.settings[bt][k].wg")
                  el-col(:span='2' class='row')
                    el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_weight_unit`)}`" label-width="30px")
                      el-input(v-model="form.settings[bt][k].wgu")
                  el-col(:span='3' class='row')
                    el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.baggage_count`)}`" label-width="60px")
                      el-input(v-model="form.settings[bt][k].c")
                hr


        el-row
          fieldset(class="el-form-fieldset mt20")
            legend {{ translate(`changeSettingsWindow.form.${entity}.fieldset.seats_blocks`) }}
            div
              el-row
                el-col(:span='8' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.create_seats_block`)}`" :label-width="formLabelWidth")
                    el-switch(v-model="form.settings.create_seats_block")
              el-row
                el-col(:span='8' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.seats_block_config_key`)}`" :label-width="formLabelWidth" class="is-required")
                    el-input(v-model="form.settings.seats_block_config_key")
                el-col(:span='8' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.seats_block_contract_id`)}`" :label-width="formLabelWidth" class="is-required")
                    el-input(v-model="form.settings.seats_block_contract_id")
                el-col(:span='8' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.seats_block_seats_count`)}`" :label-width="formLabelWidth" class="is-required")
                    el-input(v-model="form.settings.seats_block_seats_count")
              el-row
                el-col(:span='24' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.seats_block_comment`)}`" :label-width="formLabelWidth" class="is-required")
                    el-input(v-model="form.settings.seats_block_comment" type="textarea" :rows="4")

        el-row
          fieldset(class="el-form-fieldset mt20")
            legend {{ translate(`changeSettingsWindow.form.${entity}.fieldset.ttn_vi`) }}
            div
              el-row
                el-col(:span='8' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.create_vi_records`)}`" :label-width="formLabelWidth")
                    el-switch(v-model="form.settings.create_vi_records")
                el-col(:span='8' class='row')
                  el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.vi_office_id`)}`" :label-width="formLabelWidth" class="is-required")
                    el-input(v-model="form.settings.vi_office_id")
      //- --------------------------------------------------------
      template(v-if="entity == 'paxport_api_parser'")
        el-row
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.url`)}`" label-width="60px" class="is-required")
              el-input(v-model="form.settings.url")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.login`)}`" label-width="80px" class="is-required")
              el-input(v-model="form.settings.login")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.password`)}`" label-width="100px" class="is-required")
              el-input(v-model="form.settings.password")
        el-row
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.viOfficeId`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.vi_office_id")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.maxDaysInRequest`)}`" :label-width="formLabelWidth" class="is-required")
              el-input(v-model="form.settings.max_days_in_request")
          el-col(:span='8' class='row')
            el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.saveLogs`)}`" :label-width="formLabelWidth" class="is-required")
              el-checkbox(v-model="form.settings.save_logs")
      //- --------------------------------------------------------
    span(slot="footer" class="dialog-footer" v-loading="changeLoading")
      el-button(@click="dialogFormVisible = false" type="danger") {{ translate('form.cancel') }}
      el-button(@click="actionPerform" type="primary" :disabled="changeLoading") {{ translate('form.confirm') }}

</template>

<style scoped>
  .txt-w-header{
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 10px;

  }
  .txt-pd-10{
    padding: 0 0 0 10px;
  }
  .mt20 {
    margin: 20px 0 0 0;
  }
</style>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  computed:{
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    },
    classOptions(){
      return [
        { value: 'economy', label: 'economy' },
        { value: 'business', label: 'business' },
        { value: 'first', label: 'first' },
      ]
    },
    paxTypes() { return ['ADT', 'CHD', 'INF'] },
    baggageType(){ return ['carry_on', 'baggage'] },
    drivers(){
      const _self = this
      return {
        ryanair_parser: {
          defaultValues: {
            schedule: { days_range: '', value: '' },
            static_fares: { fare: '', taxes: '', total: '', currency: '' },
            baggage: {
              wd: '', h: '', l: '', du: '',
              wg: '', wgu: '', c: ''
            }
          },
          defaultForm() {
            return {
              schedule: [{ ...this.defaultValues['schedule'] }],
              static_fares: { ...this.defaultStaticFares() },
              baggage: { ...this.defaultBaggage() },
              carry_on: { ...this.defaultCarryOn() }
            }
          },
          defaultStaticFares() {
            return _self.paxTypes.reduce((res, k) => { return { ...res, ...{ [k]: { ...this.defaultValues['static_fares'] } } } }, {})
          },
          defaultBaggage() {
            return _self.paxTypes.reduce((res, k) => { return { ...res, ...{ [k]: { ...this.defaultValues['baggage'] } } } }, {})
          },
          defaultCarryOn() {
            return _self.paxTypes.reduce((res, k) => { return { ...res, ...{ [k]: { ...this.defaultValues['baggage'] } } } }, {})
          },
          isDeleteRowDisabled(form, key) {
            return (form.settings[key].length <= 1)
          },
          actionAddItem(form, key) {
            form.settings[key].push({ ...this.defaultValues[key] })
          },
          actionRemoveItem(form, key, i) {
            form.settings[key].splice(i, 1)
          },
          decorateSettings(settings){
            if (!settings.static_fares) {
              settings.static_fares = { ...this.defaultStaticFares() }
            }

            if (!settings.baggage) {
              settings.baggage = { ...this.defaultBaggage() }
            }

            if (!settings.carry_on) {
              settings.carry_on = { ...this.defaultCarryOn() }
            }
            return settings
          },
          isValid() {
            let errorFields = []

            let fields = ["validating_supplier", "fare_code", "rbd",
              "rbd_type", "baggage_details", "carry_on_details"].
              concat(_self.form.settings['create_seats_block'] ? ["seats_block_config_key", "seats_block_contract_id"] : []).
              concat(_self.form.settings['create_vi_records'] ? ["vi_office_id"] : [])
            fields.forEach((el) => { if (!_self.form.settings[el] || _self.form.settings[el].trim == '') { errorFields.push(el) } })

            fields = ["allowed_attempts"].
              concat(_self.form.settings['create_seats_block'] ? ["seats_block_seats_count"] : [])
            fields.forEach((el) => { if (!_self.form.settings[el] || parseInt(`${_self.form.settings[el]}`) == 0) { errorFields << el } })

            return errorFields
          },
          formatForm() {
            let fields = ["allowed_attempts"].
              concat(_self.form.settings['create_seats_block'] ? ["seats_block_seats_count"] : [])

            fields.forEach((el) => { _self.form.settings[el] = parseInt(`${_self.form.settings[el]}`) });

            _self.form.settings['schedule'] = _self.form.settings['schedule'].filter((row) => (row['days_range'] != '' && row['value'] != ''))
          }
        },
        paxport_api_parser:{
          defaultForm(){
            return {
              login: '', password: '', url: '', max_days_in_request: '', vi_office_id: '', save_logs: true
            }
          },
          decorateSettings(settings){
            return settings
          },
          isValid() {
            let errorFields = []

            let fields = ["url", "login", "password", "vi_office_id"]
            fields.forEach((el) => { if (!_self.form.settings[el] || _self.form.settings[el].trim == '') { errorFields.push(el) } })

            fields = ["max_days_in_request"]
            fields.forEach((el) => { if (!_self.form.settings[el] || parseInt(`${_self.form.settings[el]}`) == 0) { errorFields << el } })

            return errorFields
          },
          formatForm() {
            let fields = ["max_days_in_request"]
            fields.forEach((el) => { _self.form.settings[el] = parseInt(`${_self.form.settings[el]}`) })
          }
        }
      }
    }

  },
  data: function() {
    return {
      dialogFormVisible: false,
      changeLoading: false,
      formLoading: true,
      formLabelWidth: '140px',
      entity: null,
      form: this.defaultForm()
    }
  },

  mounted() {
    this.$parent.$on('showChangeSettingsWindow', (entity) => {
      this.entity = entity
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showChangeSettingsWindow')
  },

  methods: {
    defaultForm(){
      return {
        id: null,
        settings: (this.entity ? this.drivers[this.entity].defaultForm() : {})
      }
    },
    reset() {
      this.changeLoading = false
      this.formLoading = true
      this.loadForm()
      this.form = this.defaultForm()
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    description(){
      return `${this.entity}`
    },
    actionPerform() {
      if(this.changeLoading){ return }
      if (!this.isValid()) { return }
      this.drivers[this.entity].formatForm()
      this.changeLoading = true
      this.performRequest(this.form)
    },
    async loadForm(){
      try {
        const query = {
          collection:{
            id: true,
            entity: true,
            setting: true,
          },
          __args: {
            limit: 25,
            page: 1,
            filters: {
              featureSettings: [{field: "entity", operator: "eq", value: this.entity, hidden: false}]
            }
          },
        }

        let result = await this.graphql('featureSettings', query, 'collection')

        if(result['collection'] && result['collection'][0]){
          this.form.id = result['collection'][0].id
          this.form.settings = this.drivers[this.entity].decorateSettings(JSON.parse(result['collection'][0].setting))
        }

        this.formLoading = false
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.load_error'),
          type: 'warning',
          duration: 2600
        })
      }
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            id: this.form.id,
            entity: this.entity,
            settingJson: JSON.stringify(this.form.settings)
          },
          id: true
        }

        let result = await this.graphql((this.form.id ? 'updateFeatureSetting' : 'createFeatureSetting'), query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.success_config'),
          type: 'success',
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
    },
    isValid() {
      let errorFields = this.drivers[this.entity].isValid()

      if (errorFields.length == 0) {
        return true
      } else {
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: `${this.translate('changeSettingsWindow.msg.for_error_fields')}${errorFields.map((el) => this.translate(`changeSettingsWindow.form.${this.entity}.${el}`)).join(', ')}`,
          type: 'warning',
          duration: 5000
        })

        return false
      }
    },
  }
}
</script>
