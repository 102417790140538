<style scoped>
  .row{ padding-right: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='4' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='70px')
        el-col(:span='5' class='row')
          ttn-input(v-model="temp['index']" v-bind="attr('index')" label-width='70px')
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['daysRange']" v-bind="attr('daysRange')" label-width='100px')
        el-col(:span='7' class='row')
          ttn-input(v-model="temp['timelimit']" v-bind="attr('timelimit')" label-width='100px')
      el-row
        ttn-multiselect(v-model="temp['gdsIds']" v-bind="attr('gdsIds')" label-width='120px')
      ttn-textarea(v-model="temp['suppliers']" v-bind="attr('suppliers')")
      ttn-textarea(v-model="temp['routes']" v-bind="attr('routes')")

</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    methods: {
    }
  }
</script>
