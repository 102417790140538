<template lang="pug">
  el-dialog(
    v-if="booking"
    :visible.sync="convertorDialog.visible" 
    :title="convertorDialog.title"
    center
  )
    el-row
      el-input-number(v-model="convertorDialog.form.value" style="width: 200px" :precision="2" :step="0.1")
      el-select(v-model="convertorDialog.form.currency" style="width: 200px; padding-left: 10px")
        el-option(v-for="v in currenciesList" :label="v" :value="v" :key="v")
    
    el-row(:gutter="20")
      el-col(:span="10")
        div( style="padding: 10px 0") Result
        vue-json-pretty(:data="calculate()")
      el-col(:span="10")
        div( style="padding: 10px 0") Exchange rates
        vue-json-pretty(:data="exchanges")

</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  props: {
    booking: null
  },
  data() {
    return {
      convertorDialog: {
        visible: false,
        title: 'Convertor',
        form: {
          currency: '',
          value: 0.0
        }
      }
    }
  },

  computed: {
    currenciesList() { return this._.sortBy(Object.keys(JSON.parse(this.booking.finalAllPrice))) },
    exchanges() { return JSON.parse(this.booking.exchanges) }
  },

  methods: {
    show() {
      this.convertorDialog.visible = true
      this.convertorDialog.form = {
        currency: this.booking.depotCurrency,
        value: 0.0
      }
    },

    calculate() {
      let result = {}
      this._.each(this.currenciesList, (cur) => {
        result = { ...result, 
          [cur]: this._.round(Number(this.convertorDialog.form.value) * (this.exchanges[this.convertorDialog.form.currency] / this.exchanges[cur]), 2)
        }
      })
      return result
    }
  }
}
</script>