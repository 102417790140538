<style>
  .el-table--striped .el-table__body tr.sba-r-info-row td.el-table__cell{
    background: #d4d4d4;
  }
</style>

<script>
import Base from '@crud_combined/table'

export default {
  extends: Base,
  methods: {
    rowClassName({ row, rowIndex }) {
      switch(row['recType']){
        case 'seat_block':
          return 'success-row'
        case 'daily':
          return 'warning-row'
        case 'nigth':
          return 'sba-r-info-row'
        default:
          return ''
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 8:
          if(row['blockPriceDifference'] > 0){
            return { 'background-color': '#47ff5e69' }

          } else if(row['recType'] == 'seat_block'){
            return { 'background-color': '#daa2d894' }

          } else if(row['blockPriceDifference'] < 0){
            return { 'background-color': '#ff393973' }

          }
      }

      return {}
    },
  }
}
</script>
