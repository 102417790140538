<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:insuranceConnection="scope")
      template(v-if="scope.row['insuranceConnection']")
        div 
          strong {{ scope.row['insuranceConnection']['nameAlias'] }}
          span &nbsp;({{ scope.row['insuranceConnection']['id'] }})
    template(v-slot:insurancePackets="scope")
      template(v-if="scope.row['insurancePackets'].length")
        template(v-for="(packet) in scope.row['insurancePackets']")
          div
            strong {{ packet['providerName'] }} 
            span &nbsp;({{ packet['id'] }})
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  data() {
    return {
      enabled: [],
      drawer: {
        type: 'none',
        visible: false,
        loading: false,
        title: '',
      }
    }
  }

}
</script>
