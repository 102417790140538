import engine from '@/engine'

export default function checkLegacyPermission(value) {
  if (value && value instanceof Array) {
    if (value.length > 0) {
      const roles = engine.store.getters && engine.store.getters.legacyRoles

      const permissionRoles = value

      const hasPermission = roles.some(role => {
        return permissionRoles.includes(role)
      })

      if (!hasPermission) {
        return false
      }
    }
    return true
  } else {
    return false
  }
}
