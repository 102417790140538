import gdsStore from '../../../../../settings/modules/avia/general/gds_store'

export default {
  settings_timelimit: {
    table: {
      id: 'ID',
      index: 'Index',
      Index: 'Index',
      suppliers: 'Suppliers',
      routes: 'Routes',
      daysRange: 'Days range',
      timelimit: 'Timelimit',
      gdsIds: 'Gds',
      active: 'Active'
    },
    form: {
      fields: {
        id: 'ID',
        index: 'Index',
        suppliers: 'Suppliers',
        routes: 'Routes',
        daysRange: 'Days range',
        timelimit: 'Timelimit (m)',
        gdsIds: 'Gds',
        active: 'Active'
      },
      placeholder: {
        daysRange: '0-365',
        suppliers: '00,PS',
        routes: 'UA-PL,DE,FR;PL,DE,FR-UA',
        timelimit: '60'
      },
      options: {
        gdsIds: {...gdsStore.objectById(), ...{0: 'All'}}
      }
    }
  }
}
