<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:flight="scope") {{scope.row.supplier}}-{{scope.row.flightNumber}}
    template(v-slot:variants="scope")
      div(v-for="v in JSON.parse(scope.row.variants)" :key='"variant_" + v.supplier')
        div {{v.supplier}}-{{v.number}}
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,
  methods: {
    async actionDownload(row) {
      this.loading = true
      try {
        const query = {
          content: true,
          cacheKey: true,
          __args: {
            filters: this.filters
          }
        }

        let result = await this.graphql('reportSubstitutionDownload', query, 'collection')

        if(result.content) {
          const blob = new Blob([result.content], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = result.cacheKey
          link.click()
          URL.revokeObjectURL(link.href)
        } else{
          this.$message({
            message: this.translate('messages.download_empty_content'),
            type: 'error'
          })
        }

      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.loading = false
    }
  }
}
</script>
