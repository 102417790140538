<template lang="pug">
  extends /table
</template>

<script>

import BaseTable from '@crud_view/table'
export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    actionClearCache() {
      try {
        const query = {
          __args: {
            ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
          }
        }

        this.graphql('railwayRailPriceRuleItemClearCache', query, 'mutation')

        this.$message({
          message: 'Success!',
          type: 'success'
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    }
  }
}
</script>