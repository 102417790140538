export default {
  name: 'corporate_cards',
  items: [
    {
      name: 'card_list',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'paymentCorporatePaymentCards',
        exclude: ['actions', 'expand'],
        queries: {
          index: {
            id: true,
            active: true,
            binStart: true,
            binEnd: true,
            forLogins: true,
            userTeamInfo: true
          }
        }
      },
      table: {
        items: [
          {
            name: 'id',
            width: '80px',
            align: 'center'
          },
          {
            name: 'active',
            width: '100px',
            align: 'center'
          },
          {
            name: 'binStart',
            width: '200px',
            align: 'center'
          },
          {
            name: 'forLogins',
            format: 'array_as_string',
            align: 'center'
          },
          {
            name: 'userTeamInfo',
            align: 'center'
          },
          {
            name: 'actions',
            width: '250px',
            align: 'center'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          },
          // {
          //   name: 'total',
          //   icon: 'el-icon-money',
          //   type: 'info',
          //   plain: true
          // }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Payment::CorporateCardRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            scope: 'corporatePaymentCards',
            operator: 'eq'
          },
          {
            name: 'bin_start',
            type: 'text',
            operator: 'like',
            scope: 'corporatePaymentCards',
            camelizeLangKey: true
          },
          {
            name: 'bin_end',
            type: 'text',
            operator: 'like',
            scope: 'corporatePaymentCards',
            camelizeLangKey: true
          },
          {
            name: 'for_logins',
            type: 'text',
            operator: 'in_array_str',
            scope: 'corporatePaymentCards',
            camelizeLangKey: true
          },
          {
            name: 'user_team_info',
            type: 'text',
            operator: 'like',
            scope: 'corporatePaymentCards',
            camelizeLangKey: true
          },
          {
            name: 'active',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            scope: 'corporatePaymentCards',
            store: {
              '': 'Any',
              true: 'Yes',
              false: 'No'
            }
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'card',
            translateLegend: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'active',
                type: 'checkbox',
                default: true,
                required: false
              },
              {
                name: 'binStart',
                type: 'text',
                valueType: 'string'
              },
              {
                name: 'binEnd',
                type: 'text',
                valueType: 'string'
              },
              {
                name: 'forLogins',
                type: 'text',
                valueType: 'array_of_string'
              },
              {
                name: 'userTeamInfo',
                type: 'text',
                required: false,
                valueType: 'string'
              },
            ]
          }
        ]
      }
    }
  ]
}