import ttnTableColumnSelection from './table-column-selection.vue'
import ttnTableColumnIndex from './table-column-index.vue'
import ttnTableColumnData from './table-column-data.vue'
import ttnTableColumnExpand from './table-column-expand.vue'
import ttnTableColumnActions from './table-column-actions.vue'

export default {
  ttnTableColumnSelection,
  ttnTableColumnIndex,
  ttnTableColumnData,
  ttnTableColumnExpand,
  ttnTableColumnActions
}
