export default {
  computed:{
    gdsStorePath() { return 'avia/l2b/l2b_statistics/daily_statistics/preload' },
    gdsStore() {
      let result = {}
      for(let i of (this.$store.getters[this.gdsStorePath]['gds'] || [])) { result[i.id] = i }
      return result
    }
  },
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 1:
          return this.firstCellStyle ? this.firstCellStyle : {}
        case 2:
        case 3:
          return { 'background-color': '#bec9ff6b' }
        case 4:
        case 5:
          return { 'background-color': '#47ff5e69' }
        case 6:
        case 7:
          return { 'background-color': '#f9b47894' }
        case 8:
        case 9:
          return { 'background-color': '#daa2d894' }
        case 10:
          return { 'background-color': '#73ecff59' }
        case 11:
          return { 'background-color': '#00d00099' }
        case 15:
          if(this.gdsStore[row.gdsId] && row.l2bRate > this.gdsStore[row.gdsId].maxValue) {
            return { 'background-color': '#ff393973' }
          } else{
            return { 'background-color': '#bb881485' }
          }
      }

      return {}
    },

    formatCell({ row, $column, column, cellValue, index }) {
      switch(column.name) {
        case 'depotId':
          return `${row['depotName']} (${cellValue})`
        case 'refId':
          return `${row['refName']} (${cellValue})`
        default:
          return cellValue
      }
    }
  }
}
