export default {
  report_pricing_data: {
    form: {
      fields: {
        market: 'Market',
        routeKey: 'Route key'
      }
    },
    table: {
      routeKey: 'Route key',
      currency: 'Currency',
      ttl: 'TTL'
    }
  },
  report_original_data: {
    form: {
      fields: {
        market: 'Market',
        routeKey: 'Route key'
      }
    },
    table: {
      routeKey: 'Route key',
      currency: 'Currency',
      sessionCreatedOn: 'Date'
    }
  },
}
