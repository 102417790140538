<template lang="pug">
  div(v-if="booking" )
    el-dialog(title="Edit ancillary information"
              :visible.sync="editAncillaryDialog.visible"
              v-loading="editAncillaryDialog.loading" 
              element-loading-text="Loading..."
              center
              class='edit-ancillary-dialog'
              width="90%")
      el-form(:model="editAncillaryDialog" label-width="0px" ref="editAncillaryForm")

        div(v-if="false") {{ editAncillaryDialog.form }}
        div(v-if="false") {{ preparedAncillaryServices }}

        table(border="0" style="width: 100%" v-if="editAncillaryDialog.form.length > 0")
          tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
            th(width="75px") Service
            th(width="150px") Passenger
            th(width="200px") Segments
            th(width="150px") Description
            th(width="150px") Details
            th Netto
            th Total
            th Bsp total
            th(width="80px") Type
            th(width="100px") Status
            th(width="150px") Number
            th(width="50px")

          template(v-for="(s, inx) in editAncillaryDialog.form")
            tr(style="text-align: center")        
              td
                div(v-if="s.id") {{ s.ssrCat }}
                el-form-item(v-else :prop="'form.' + inx + '.ssrCat'" :rules="required")
                  el-select(v-model="editAncillaryDialog.form[inx].ssrCat")
                    el-option(v-for="o in ssrCats" :label="o" :value="o" :key="o")

              td
                div(v-if="s.id") {{ paxName(s.pass) }}
                el-form-item(v-else :prop="'form.' + inx + '.pass'" :rules="required")
                  el-select(v-model="editAncillaryDialog.form[inx].pass")
                    el-option(v-for="(o, oInx) in passengers" :label="`${o.firstName} ${o.lastName}`" :value="oInx" :key="oInx")
 
              td
                div(v-if="s.id") {{ segmentsFor(s) }}
                el-form-item(v-else :prop="'form.' + inx + '.segmentId'" :rules="required")
                  el-checkbox-group(v-model="editAncillaryDialog.form[inx].segmentId" 
                                    size="mini" 
                                    v-for="(tripPartSegments, tripPart) in _.groupBy(locations, 'tripPart')" :key="tripPart")
                    el-checkbox(v-for="seg in tripPartSegments" 
                                :label="`${seg.segmentId}-${tripPart}`" :key="`${seg.segmentId}-${tripPart}`") {{ `${seg.departureLocation}-${seg.arrivalLocation}` }}

              td
                el-form-item(:prop="'form.' + inx + '.description'")
                  el-input(v-model="editAncillaryDialog.form[inx].description")

              td
                el-form-item(:prop="'form.' + inx + '.details'")
                  el-input(v-model="editAncillaryDialog.form[inx].details")

              td
                div(v-if="s.id") {{ `${Number(s.fare).toFixed(2)}${s.currency}` }}
                el-form-item(v-else :prop="'form.' + inx + '.fare'" :rules="required")
                  el-input(v-model="editAncillaryDialog.form[inx].fare" style="width: 50px; margin-right: 2px")
                  el-select(v-model="editAncillaryDialog.form[inx].currency" style="width: 70px" filterable)
                    el-option(v-for="v in currenciesList" :label="v" :value="v" :key="v")

              td
                div(v-if="s.id") {{ `${Number(s.amount).toFixed(2)}${s.currency}` }}
                el-form-item(v-else :prop="'form.' + inx + '.amount'" :rules="required")
                  el-input(v-model="editAncillaryDialog.form[inx].amount" style="width: 50px; margin-right: 2px")
                  el-select(v-model="editAncillaryDialog.form[inx].currency" style="width: 70px" filterable)
                    el-option(v-for="v in currenciesList" :label="v"  :value="v" :key="v")

              td
                el-form-item(:prop="'form.' + inx + '.issueAmt'")
                  el-input(v-model="editAncillaryDialog.form[inx].issueAmt" style="width: 50px; margin-right: 2px")
                  el-select(v-model="editAncillaryDialog.form[inx].issueCur" style="width: 70px" filterable)
                    el-option(v-for="v in currenciesList" :label="v" :value="v" :key="v")

              td
                el-tag(v-if="s.id" type="info") {{ typeFor(s) }}
                el-form-item(v-else :prop="'form.' + inx + '.type'" :rules="required")
                  el-select(v-model="editAncillaryDialog.form[inx].type")
                    el-option(v-for="(v, k) in serviceTypes" :label="v" :value="k" :key="k" v-if="k != 'baggage_tab'")

              td
                el-form-item(:prop="'form.' + inx + '.status'" :rules="required")
                  el-select(v-model="editAncillaryDialog.form[inx].status")
                    el-option(v-for="o in statuses" :label="_.capitalize(o)" :value="o" :key="o")

              td
                el-form-item(:prop="'form.' + inx + '.emd'" :rules="emdRequired(inx)")
                  el-input(v-model="editAncillaryDialog.form[inx].emd")

              td(:rowspan="2")
                el-button(@click.stop="editAncillaryDialog.form.splice(inx, 1)" icon="el-icon-delete" type="danger" mini plain v-if="editAncillaryDialog.form[inx].type != 'baggage_tab'")

            tr
              td(:colspan="11")

                fieldset(:class="`el-form-fieldset`" )
                  legend Corporate card details
                  el-row(:gutter="20")
                    el-col(:span="3")
                      el-form-item(:prop="'form.' + inx + '.externalPurchase.price'" label="Amount" label-width="100")
                        el-input(v-model="editAncillaryDialog.form[inx].externalPurchase.price" style="width: 75px")

                    el-col(:span="3")
                      el-form-item(:prop="'form.' + inx + '.externalPurchase.currency'" label="Currency" label-width="100")
                        el-select(v-model="editAncillaryDialog.form[inx].externalPurchase.currency" style="width: 100px" filterable)
                          el-option(v-for="v in currenciesList" :label="v" :value="v" :key="v")

                    el-col(:span="5")
                      el-form-item(:prop="'form.' + inx + '.externalPurchase.card_number'" label="Card" label-width="100")
                        el-select(v-model="editAncillaryDialog.form[inx].externalPurchase.card_number" style="width:200px" filterable)
                          el-option(v-for="v in paymentCards" :label="v.fullBin" :value="v.fullBin" :key="v.fullBin")

            tr(v-if="inx != editAncillaryDialog.form.length - 1")
              td(:colspan="12" style="padding: 0")
                el-divider

      span.dialog-footer(slot="footer")
        el-button(type="primary" small @click="addNewItemToForm()") Add
        el-button(type="success" small @click="sendAncillaryForm()") Save

    table(border="0" style="width: 100%")
      tr
        td(:colspan="10" style="padding: 15px 10px")
          el-button(type="primary" size="mini" plain @click="editAncillaryDialog.visible = true") Edit
          el-button(type="primary" size="mini" plain disabled) Send Itin

      template(v-for="(s, sInx) in preparedAncillaryServices")
        tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
          th Service
          th Passenger
          th Segments
          th Description
          th Details
          th Status
          th Mail update
          th Number

        tr(style="text-align: center")
          td.bold {{ s.ssrCat }}
          td.bold {{ paxName(s.pass) }}
          td {{ segmentsFor(s) }}
          td {{ s.description }}
          td {{ s.details }}

          td
            el-tag(:type="tagFor(s.status)") {{ _.capitalize(s.status) }}

          td 
            el-tag(:type="tagFor('cancelled')") {{ s.mailUpdate }}

          td(style="vertical-align: middle")
            div(v-if="s.emd") {{ s.emd }}
            el-tag(v-else-if="s.info.cannot_issue" type="danger") Cannot issue
            el-button(v-else icon="el-icon-warning" mini plain type="danger" @click="cannotIssueAncillaryMail(s)") Cannot issue

        tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
          th Netto
          th Markup
          th Total
          th Price per segment
          th BSP total
          th External
          th Type
          th Checkin

        tr(style="text-align: center")
          td {{ `${s.fare.toFixed(2)}${s.currency}` }}
          td {{ `${(s.amount[s.currency] - s.fare).toFixed(2)}${s.currency}` }}
          td {{ `${Number(s.amount[s.currency]).toFixed(2)}${s.currency}` }}
          td(v-html="_.map((s.info.price || []), (x, i) => { return `[${i}] => ${x}` }).join('<br/>')")
          td {{ s.issueAmt ? `${Number(s.issueAmt).toFixed(2)}${s.issueCur}` : '' }}
          td(:set="external = getExternalPurchaseFor(s)")
            el-tag(v-if="external" type="info") {{ `${Number(external.price).toFixed(2)}${external.currency}` }}
            el-tag(v-else type="info") No
          td
            el-tag(type="info") {{ typeFor(s) }}
            template(v-if="addedAfterPayment(s)")
              el-divider(direction="vertical")
              el-tag(type="danger") Post-sale
          td
            template(v-if="s.manualy")
              el-tag(v-if="s.info.sent_queue" type="success") Request sent
              el-button(mini plain type="primary" @click="createCheckinRequest(s)" v-else) Online Checkin
            el-tag(v-else type="info" icon="el-icon-warning") Not allowed

        tr(v-if="s.active")
          td(:colspan="8")
            div(style="padding: 10px 0")
              span № {{ `${s.pass}${s.tripPart}[${s.segmentId.join('+')}]${s.id}` }}
              el-divider(direction="vertical")
              span SSR {{ s.ssrCode }}
              span(v-if="s.info.rules") 
                el-divider(direction="vertical")
                span Rules: {{ s.info.rules }}
              span(v-if="s.mcoOrderId")
                el-divider(direction="vertical")
                span MCO ID: {{ s.mcoOrderId }}
            div(v-for="(comment, cInx) in s.info.comments" v-if="s.info.comments").bold {{ `${cInx + 1}) ${comment}` }}

        tr(style="background-color: #e6f3ff")
          td(@click="s.active = !s.active" :colspan="9" class="collapse-panel")
            i(class="el-icon-arrow-down" :class="{'is-active': s.active}")
</template>

<script>
// import BaseTable from '@crud_view/table'
import currencies_list from '@/settings/modules/avia/general/currencies_list'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  // extends: BaseTable,

  props: {
    booking: null,
    reloadBooking: null
  },

  data() {
    return {
      preparedAncillaryServices: [],
      paymentCards: [],
      editAncillaryDialog: {
        visible: false,
        loading: false,
        form: []
      }
    }
  },

  computed: {
    dictionary() { return JSON.parse(this.booking.dictionary) },
    ancillaryServices() { return this.booking.ancillaryServices },
    passengers() { return this.booking.passengers },
    locations() { return this.booking.locations },
    externalPurchases() { return JSON.parse(this.booking.externalPurchases) },
    ssrCats() { return ['BG', 'SA', 'SQ'] },
    statuses() { return ['issued', 'cancelled', 'created'] },
    currenciesList() { return this._.sortBy(Object.keys(JSON.parse(this.booking.finalAllPrice))) },
    serviceTypes() { return { gds: 'GDS', manualy: 'Manual EMD', baggage_tab: 'Baggage tab manual' } },
    exchanges() { return JSON.parse(this.booking.exchanges) },

    required() {
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    }
  },

  watch: {
    booking(val) {
      this.initAncillaryServices()
      // this.loading = false
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.initAncillaryServices()
    })
  },

  methods: {
    initAncillaryServices() {
      this.preparedAncillaryServices = []
      this.editAncillaryDialog.form = []

      this.booking.ancillaryServices.forEach((x) => {
        this.preparedAncillaryServices = [
          ...this.preparedAncillaryServices,
          {
            ...x,
            active: false,
            info: (JSON.parse(x.info) || {}),
            amount: (JSON.parse(x.amount) || {})
          }
        ]

        this.editAncillaryDialog.form = [
          ...this.editAncillaryDialog.form,
          {
            id: x.id,
            ssrCat: x.ssrCat,
            pass: x.pass,
            segmentId: x.segmentId,
            tripPart: x.tripPart,
            description: x.description,
            details: x.details,
            status: x.status,
            emd: x.emd,
            fare: x.fare,
            currency: x.currency,
            amount: (JSON.parse(x.amount) || {})[x.currency],
            issueAmt: (x.issueAmt || ''),
            issueCur: (x.issueCur || ''),
            externalPurchase: (this.getExternalPurchaseFor(x) || {}),
            type: this.serviceTypeFor(x),
            ssrCat: x.ssrCat,
            ssrCode: x.ssrCode
          }
        ]
      })

      this.loadPaymentCards()
    },

    getDictionaryBy(key, code) {
      return this.dictionary[key][code] || code
    },

    paxName(paxInx) {
      const pax = this.passengers[paxInx]
      return `${pax.firstName} ${pax.lastName}`
    },

    segmentsFor(s) {
      let segments = []
      let forAllSegments = this.locations.length == s.segmentId.length

      this._.filter(this.locations, (x) => { return forAllSegments ? true : x.tripPart == s.tripPart }).forEach((seg) => {
        let globalSegInx = this.locations.indexOf(seg) + 1
        if(!forAllSegments && s.segmentId.indexOf(seg.segmentId) == -1) {
          segments.push(`[${seg.tripPart}${globalSegInx}]`)
        } else {
          segments.push(`${[seg.departureLocation, seg.arrivalLocation].join('')} [${seg.tripPart}${globalSegInx}]`)
        }
      })

      return segments.join(', ')
    },

    tagFor(status) {
      let tagName
      switch(status) {
        case 'issued':
          tagName = 'success'
          break
        case 'created':
          tagName = ''
          break
        case 'cancelled':
          tagName = 'danger'
          break
        case 'confirmed':
          tagName = 'warning'
          break
        default:
          tagName = ''
          break
      }

      return tagName
    },

    typeFor(service) {
      return this.serviceTypes[this.serviceTypeFor(service)]
    },

    serviceTypeFor(service) {
      return service.type
    },

    getExternalPurchaseFor(service) {
      if (this.externalPurchases.length == 0) return null

      return this._.find(this.externalPurchases, (x) => {
        const uniqKeyParts = x.service_unique_key.split('|')

        return String(uniqKeyParts[0]) == String(service.pass) && String(uniqKeyParts[1]) == String(service.tripPart) && String(uniqKeyParts[2]) == String(service.segmentId.join('_')) && String(uniqKeyParts[3]) == service.ssrCode
      })
    },

    addNewItemToForm() {
      this.editAncillaryDialog.form.push({ segmentId: [], externalPurchase: {}, ssrCode: this.generateSsrCode(10) })
    },

    showEditAncillaryDialog(pax) {
      this.editAncillaryDialog.visible = true
      this.editAncillaryDialog.form = {}
    },

    sendAncillaryForm() {
      this.$refs.editAncillaryForm.validate((valid) => {
        if (valid) {
          let ancillaryReward = []
          let depotCurrency = this.booking.depotCurrency
          let self = this

          this._.each(this.editAncillaryDialog.form, (item) => {
            if (item.status == 'issued') {
              let issueAmt = parseFloat(item.issueAmt)
              let fareInDepotCurrency = this._.round(item.fare * (this.exchanges[item.currency] / this.exchanges[depotCurrency]), 2)
              let issueAmtInDepotCurrency = this._.round(item.issueAmt * (this.exchanges[item.issueCur] / this.exchanges[depotCurrency]), 2)

              if (item.currency != item.issueCur) {
                issueAmt = this._.round(issueAmt * (this.exchanges[item.issueCur] / this.exchanges[item.currency]), 2)
              }

              if (fareInDepotCurrency != issueAmtInDepotCurrency) {
                ancillaryReward = [
                  ...ancillaryReward,
                  {
                    ...item,
                    issueAmt: issueAmt,
                    issueCur: item.currency,
                    issueAmtInDepotCurrency: issueAmtInDepotCurrency,
                    fareInDepotCurrency: fareInDepotCurrency
                  }
                ]
              }
            }
          })

          if (ancillaryReward.length > 0) {
            let message = ''

            message += `<table style="width: 100%" class="ancillary-reward-dialog">
                            <tbody>
                                <colgroup>
                                    <col width="20%">
                                    <col width="20%">
                                    <col width="20%">
                                    <col width="20%">
                                    <col width="20%">
                                </colgroup>
                                <tr background-color: rgb(245, 247, 250); height: 25px; color: rgb(0, 0, 0);>
                                    <th>Type</th>
                                    <th>Netto</th>
                                    <th>Issued</th>
                                    <th>Currency</th>
                                    <th>Difference</th>
                                </tr>`
            let totalDiff = 0
            ancillaryReward.forEach(function(item) {
              let diff = parseFloat(item.fareInDepotCurrency) - parseFloat(item.issueAmtInDepotCurrency)
              totalDiff = totalDiff + diff
              message += `<tr>
                                    <td>${item.ssrCat} ${item.ssrCode}</td>
                                    <td>${item.fareInDepotCurrency}</td>
                                    <td>${item.issueAmtInDepotCurrency}</td>
                                    <td>${depotCurrency}</td>
                                    <td style="color: ${parseFloat(diff) > 0 ? 'green' : 'red'}">${self._.round(Math.abs(diff), 1)}</td>
                                </tr>`
            })

            message += `<tr>
                                    <td colspan="5"></td>
                                </tr>
                                <tr style="font-weight: bold;">
                                    <td colspan="3">Total difference</td>
                                    <td>${depotCurrency}</td>
                                    <td style="color: '${parseFloat(totalDiff) > 0 ? 'green' : 'red'}">${self._.round(Math.abs(totalDiff), 1)}</td>
                                </tr>`

            message += `</tbody>
                        </table>`

            this.$confirm(message, 'Additional discount', {
              dangerouslyUseHTMLString: true,
              distinguishCancelAndClose: true,
              confirmButtonText: 'Save discount',
              cancelButtonText: 'Save without discount'
            })
              .then(() => {
                this.saveAncillaryDialogForm(true)
              })
              .catch((action) => {
                if (action == 'cancel') this.saveAncillaryDialogForm(false)
              })
          } else {
            this.saveAncillaryDialogForm(false)
          }
        }
      })
    },

    generateSsrCode(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
      }
      return result
    },

    emdRequired(inx) {
      const message = 'This field is required and allowed [0-9a-zA-Z-]'
      const required = this.editAncillaryDialog.form[inx].status == 'issued'
      return [{ required, message, trigger: 'change', pattern: /^([0-9a-zA-Z\-]+)$/ }]
    },

    addedAfterPayment(s) {
      return s.cleverIgnore || s.mcoOrderId
    },

    async saveAncillaryDialogForm(calculateAncillaryReward) {
      this.editAncillaryDialog.loading = true

      try {
        const query = {
          __args: {
            bookingId: this.booking.id,
            ancillaryList: JSON.stringify(this.editAncillaryDialog.form),
            calculateAncillaryReward: calculateAncillaryReward || false
          }
        }

        let res = await this.graphql('aviaBookingItemSaveAncillary', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.editAncillaryDialog.loading = false
        this.editAncillaryDialog.visible = false

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.editAncillaryDialog.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      } 
    },

    async createCheckinRequest(service) {
      this.loading = true
      try {
        const query = {
          __args: {
            bookingId: this.booking.id,
            ancillaryId: service.id,
            paxId: service.pass,
            tripId: service.tripPart
          }
        }

        let res = await this.graphql('aviaBookingItemCreateCheckinRequest', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.loading = false
    },

    async cannotIssueAncillaryMail(service) {
      this.loading = true
      try {
        const query = {
          __args: {
            bookingId: this.booking.id,
            paxId: service.pass,
            tripId: service.tripPart,
            segmentId: service.segmentId.join(',')
          }
        }

        let res = await this.graphql('aviaBookingItemCannotIssueAncillaryRequest', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.loading = false
    },

    async loadPaymentCards() {
      if(this.paymentCards.length > 0) return this.paymentCards

      this.editAncillaryDialog.loading = true
      const query = {
        collection: {
          id: true,
          fullBin: true
        }
      }
      let res = await this.graphql('paymentCorporatePaymentCardBackendUserCard', query, 'query')
      this.paymentCards = res.collection
      this.editAncillaryDialog.loading = false

      return this.paymentCards
    }
  }
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  table {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
    width: 100%;
  }

  table tr { background-color: #fff; vertical-align: middle }
  table tr.route { vertical-align: middle; height: 40px }
  table tr td { padding: 5px }
  table tr th { padding: 5px }

  .collapse-panel {
    text-align: center;
    cursor: pointer;
  }

  .collapse-panel i.is-active { transform: rotate(180deg) }
</style>

<style lang="scss">
  .edit-ancillary-dialog {
    .el-form-item { margin: 0px }
    table { 
      border-spacing: 0px 
    }

    .el-checkbox__label { font-size: 11px }
    .el-divider--horizontal { margin: 12px 0 }
    .el-form-item--mini .el-form-item__label { line-height: 30px }
  }

  .ancillary-reward-dialog {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;

    tr { background-color: #fff }
    tr td { padding: 5px }
    tr th { padding: 5px }
  }
</style>