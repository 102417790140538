<template>
  <td class="custom-table--cell">
    <div class="cell">
      <slot />
    </div>
  </td>
</template>
<script>
export default {
}
</script>
