import Vue from 'vue'
import Listing from './view/listing'
import { strToCamelCase } from './helper'

const buildChild = (c) => {
  const name = strToCamelCase((c.namespace ? c.namespace + '_' : '') + c.component)
  const query = {}
  const firstTab = c.tabs.find(x => Array.isArray(x.items) && x.items.length > 0)
  let firstItem = null
  if (firstTab) {
    firstItem = firstTab.items[0]
    query.tab = firstTab.name
    query.item = firstItem.name
  }
  query.page = 1
  query.limit = 25
  if (firstItem) {
    const defautSortColumn = firstItem.table.defaultSort.column
    const defautSortDir = firstItem.table.defaultSort.dir
    if (defautSortColumn && defautSortDir) {
      query.sort = defautSortColumn
      query.dir = defautSortDir
    }
  }

  const component = Vue.extend({
    name,
    extends: Listing
  })
  // console.log(component)

  return {
    name: name,
    path: `${c.component}`,
    component,
    props: {
      module: c
    },
    query,
    meta: c.meta
  }
}

export default function makeCrudRoute({
  module,
  layout
}) {
  let children = []
  for(let ci in module.children) {
    let c = module.children[ci]
    if (c.component) {
      children.push(buildChild(c))
    } else {
      let i = {
        path: c.path,
        component: module.layout || c.layout || layout,
        redirect: c.redirect,
        alwaysShow: c.alwaysShow,
        name: (c.namespace ? c.namespace + '/' : '') + c.name,
        hidden: c.hidden,
        meta: c.meta,
        children: []
      }
      for(let cc in c.children) {
        i.children.push(buildChild(c.children[cc]))
      }
      children.push(i)
    }
  }
  return {
    path: module.path,
    component: layout,
    redirect: module.redirect,
    name: module.name,
    hidden: module.hidden,
    alwaysShow: module.alwaysShow,
    meta: module.meta,
    children
  }
}
