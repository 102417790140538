<template lang="pug">
  div(class="list-content pc_list avia-booking-list-table")
    el-dialog(:visible.sync="centerDialogVisible" center title="Booking Comments")
      el-row
        el-col
          el-table(:data="comments[locator]")
            el-table-column(prop="backendUser.login" width="100px")
            el-table-column
              template(slot-scope="scope")
                div(v-html="scope.row.data")
            el-table-column(width="200px")
              template(slot-scope="scope")
                span {{new Date(scope.row.createdAt) | moment("LLL")}}
      el-row(:gutter="20")
        el-col(:span="16")
          el-input(type="textarea" v-model="comment" :autosize="{ minRows: 7, maxRows: 15}")
        el-col(:span="6")
          el-row
            el-checkbox(v-model="attentionComment") Add as notice
          el-row
            el-checkbox(v-model="mcoGeneralComment") MCO general comment
          el-row
            el-button(@click="addComment" type="primary") Add Comment

      span.dialog-footer(slot="footer")
        el-button(@click="centerDialogVisible = false") Close
    
    el-container(style="height: 750px")
      el-header
        top-menu(:currentRow="currentRow" :navigateTab="navigateTab")

      el-main
        el-table(
          :data="records"
          :row-class-name="tableRowClassName"
          v-loading="loading" 
          @row-dblclick="showDetails"
          highlight-current-row
          @current-change="handleCurrentChange"
        )

          el-table-column
            template(slot-scope="scope")
              el-row
                el-col
                  span(v-if="editLocators[scope.row.id] == undefined")
                    span(@click.prevent="setEditLocator(scope.row.id, scope.row.locator)").blue.bold.upcase.pointer Locator: {{ scope.row.locator }}
                  span(v-else class="edit-locator-wrap")
                    span.blue.bold.upcase.pointer Locator: 
                    span(style="display: inline-block; margin-left: 5px; width: 130px")
                      el-input(type="string" v-model="editLocators[scope.row.id]")
                        template(slot="append")
                          el-button-group
                            el-button(icon="el-icon-check" plain type="success" @click="saveLocator(scope.row.id)")
                            el-button(icon="el-icon-close" plain type="danger" @click="editLocators[scope.row.id] = undefined")
              el-row
                el-col
                  span ID: {{ scope.row.id }}
              el-row
                el-col.upcase
                  span STATUS: &nbsp;
                  span.blue {{ scope.row.status }}
              el-row
                el-col
                span.upcase {{ generateTripRoute(scope.row.locations) }}
              el-row
                el-col
                  span.bold.upcase DEP {{ scope.row.departureTime }}
          el-table-column
            template(slot-scope="scope")
              el-row
                el-col
                  span Depot: {{ scope.row.depot.name }} [{{ scope.row.depot.id }}]
              el-row
                el-col
                  span Refid: {{ scope.row.trafficSource ? scope.row.trafficSource.name + ' [' + scope.row.trafficSource.id + ']' : "-" }}
              el-row
                el-col
                  span Created at: {{ new Date(scope.row.createdAt) | moment("YYYY-MM-DD HH:mm") }}
              el-row
                el-col
                  span Timelimit: {{ new Date(scope.row.timelimit) | moment("YYYY-MM-DD HH:mm") }}
          el-table-column(width="400")
            template(slot-scope="scope")
              span {{ setComments(scope.row, scope.row.locator) }}
              div(@click="showComments(scope.row.locator)").pointer
                el-row
                  el-col
                    span {{ getLastCommentAuthor(scope.row.locator) }}
                el-row
                  el-col
                    span.short(v-html="getLastCommentText(scope.row.locator)")
</template>

<script>
import BaseTable from '@crud_view/table'
import TopMenu from './top_menu'

export default {
  components: {
    TopMenu
  },
  extends: BaseTable,
  data() {
    return {
      loading: false,
      comments: {},
      centerDialogVisible: false,
      locator: 0,
      comment: '',
      currentRow: null,
      attentionComment: false,
      mcoGeneralComment: false,
      editLocators: {}
    }
  },

  methods: {
    addComment() {
      if (this.comment.length === 0) {
        return
      } else {
        this.proceedComment()
      }
    },

    async proceedComment() {
      try {
        const query = {
          __args: {
            locator: this.locator,
            comment: this.comment,
            table: 'avia_bookings',
            attentionComment: this.attentionComment,
            mcoGeneralComment: this.mcoGeneralComment
          },
          id: true,
          createdAt: true
        }

        await this.graphql('addComment', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Сomment added',
          duration: 2600
        })

        this.comment = ''
        this.attentionComment = false
        this.mcoGeneralComment = false

        this.navigateTab({
          reload: true,
          tab: 'items',
          item: 'avia_booking_list'
        })
      } catch (e) {
        console.error(e)
      }
    },

    generateTripRoute(segments) {
      var route = []
      segments.forEach((segment) => {
        if (route[route.length - 1] === undefined) {
          route.push(segment.departureLocation)
        } else {
          if (route[route.length - 1] !== segment.departureLocation) {
            route.push(segment.departureLocation)
          }
        }
        route.push(segment.arrivalLocation)
      })
      return route.join(' — ')
    },

    getLastCommentAuthor(locator) {
      if (this.comments[locator] === null || this.comments[locator].length === 0) {
        return ''
      }
      var comment = this._.first(this.comments[locator])

      return this.$moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss') + ' ' + comment.backendUser.login
    },

    getLastCommentText(locator) {
      if (this.comments[locator] === null || this.comments[locator].length === 0) {
        return ''
      }
      var comment = this._.first(this.comments[locator])

      if (comment === null) {
        return ''
      }

      return comment.data
    },

    setComments(booking, locator) {
      this.comments[locator] = []

      if (booking === undefined || booking === null) {
        return
      }

      if (booking.comments === undefined) {
        return
      }

      this.comments[locator] = [...booking.comments]
    },

    showComments(locator) {
      this.locator = locator
      this.centerDialogVisible = true
      this.comment = ''
    },

    tableRowClassName({ row, rowIndex }) {
      if (row == null) {
        return ''
      } else if (row.testOrder) {
        return 'status_row_test'
      } else {
        return 'status_row_' + row.status
      }
    },

    showDetails(row) {
      this.navigateTab({
        tab: 'items',
        item: 'avia_booking_show',
        reload: true,
        filters: {
          bookings: [
            {
              field: 'id', operator: 'eq', value: row.id
            }
          ]
        }
      })
    },

    handleCurrentChange(val) {
      this.currentRow = val
      this.$elItem.$emit('showInfo', val)
    },

    setEditLocator(bookingId, state) {
      this.editLocators = { ...this.editLocators, [bookingId]: state }
    },

    async saveLocator(bookingId) {
      try {
        const query = {
          __args: {
            id: bookingId,
            newLocator: this.editLocators[bookingId]
          }
        }

        await this.graphql('aviaBookingItemSaveLocator', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Locator saved',
          duration: 2600
        })

        this.editLocators = { ...this.editLocators, [bookingId]: null }

        this.navigateTab({
          reload: true,
          tab: 'items',
          item: 'avia_booking_list'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss">
  .avia-booking-list-table {
    .el-table tr {
      &.status_row_W{
        background: #80FFFF;
      }
      &.status_row_P{
        background: #9EFF7F;
      }
      &.status_row_PN{
        background: #FFE681;
      }
      &.status_row_PN_autotckt{
        background: #CCCC99;
      }
      &.status_row_CM{
        background: #E5CBF1;
      }
      &.status_row_CMO{
        background: #f1e0cb;
      }
      &.status_row_CMP{
        background: #cbd3f1;
      }
      &.status_row_CMW{
        background: #9EFF7F;
      }
      &.status_row_NP{
        background: #70AAE9;
      }
      &.status_row_WA{
        background: #B97A57;
      }
      &.status_row_LCP{
        background: #00AEE1;
      }
      &.status_row_manual_book{
        background: #e1f3d8;
      }
      &.grey{
        background: #F2F6FC;
        border-bottom: 3px solid white;
      }
      &.status_row_test{
        background: #FFCFCF;
      }
    }

    .el-table__body tr > td.el-table__cell:first-child {
      border-left: 4px solid #fff
    }

    .el-table__body tr.current-row > td.el-table__cell:first-child {
      border-left: 4px solid red
    }

    .el-table__header-wrapper { display: none }
  }

  $checkbox-props: "[prop='urgent'],[prop='not_actually'],[prop='booking_by_legal_person'],[prop='gmt_only'],[prop='with_special_fare'],[prop='with_special_fare_search'],[prop='fare_downgrade'],[prop='has_ancillary_services'],[prop='has_fare_family'],[prop='from_baggage_tab'],[prop='manual_emd'],[prop='baggage_gds'],[prop='baggage_tab_manual'],[prop='not_urgent'],[prop='urgent_h'],[prop='is_rebooked'],[prop='explorer_check'],[prop='price_component_partially_issued'],[prop='exclude_tickets_kz'],[prop='ground_segments'],[prop='infants'],[prop='is_charter'],[prop='test_order'],[prop='exclude_conv_booking'],[prop='conv_booking'],[prop='self_transfers'],[prop='is_multi_fare'],[prop='is_multi'],[prop='is_multi_gds'],[prop='with_auction'],[prop='ttn_avia_vi'],[prop='with_dynamic_commission'],[prop='price_component_gte'],[prop='price_component_lte'],[prop='is_recommended'],[prop='all_fake_booking'],[prop='all_fake_success'],[prop='fake_only_in_process'],[prop='fake_exclude_in_process'],[prop='fake_only_with_errors'],[prop='fake_pnr_excluded'],[prop='fake_recc_excluded'],[prop='exclude_conso_queue'],[prop='exclude_backlog_queue'],[prop='exclude_autoticketing_queue'],[prop='manual_processing_queue']";

  #pane-avia_booking_list {
    div.el-form-item:has(#{$checkbox-props}) {
      div.el-form-item__content { display: inline-block; }
      label.el-form-item__label { width: 130px }
    }

    div:is(#{$checkbox-props}) span.el-checkbox__input {
      top: 10px;
      left: 10px
    }

    div.el-form-item:has([prop="urgent_h"],[prop="price_component_gte"],[prop="price_component_lte"]) {
      div.el-form-item__content { width: 75px }
      label.el-form-item__label { width: 75px }
      div.el-input { top: 10px }
    }

    .edit-locator-wrap div.el-input-group__append {
      width: 45px;
      padding: 0 10px 0 20px;
    }
  }
</style>

<style lang="scss" scoped>
  .avia-booking-list-table {
    .blue{
      color: #409EFF;
    }
    .bold{
      font-weight: bold;
    }
    .upcase{
      text-transform: uppercase;
    }
    .small{
      font-size: smaller;
    }

    .wrap{
      &:last-child {
        margin-bottom: 5px;
      }
    }

    hr {
      border:none;
      border-top:1px dotted black;
      color:#fff;
      background-color:#fff;
      height:1px;
    }
    // .short {
    //   display: inline-block;
    //   width: 400px;
    //   white-space: nowrap;
    //   overflow: hidden !important;
    //   text-overflow: ellipsis;
    // }
    .pointer{
      cursor: pointer;
    }

    .el-dialog .el-row {
       margin-bottom: 20px;
       &:last-child {
         margin-bottom: 0;
       }
     }

    .el-card .el-row {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .el-card {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .right{
      text-align: right;
    }
  }
</style>
