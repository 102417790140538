import { strToCamelCase } from './../helper'

export default {
  props: {
    channels: {
      required: true,
      type: Array
    },
    namedChannels: {
      required: true,
      type: Array
    }
  },

  beforeCreate() {
    this.$options.computed = this.$options.computed || {}
    const channels = this.$options.props.channels.type
    for (let i of channels) {
      const methodName = strToCamelCase(i.replace(':', '_'))
      this.$options.computed[methodName] = () => {
        return i
      }
    }

    const named = this.$options.props.namedChannels.type
    for (let i of named) {
      const methodName = strToCamelCase(i.replace(':', '_'))
      this[methodName] = (tab, item) => {
        return `${i}|${tab}|${item}`
      }
    }
  }
}
