<template lang="pug" src="./../templates/history.pug" />

<script>
import common from './../../mixin/common'
import history from './../../mixin/history'
import di from './../../mixin/di'
import component from './../../mixin/component'

export default {
  name: 'CrudHistory',
  mixins: [component, common, di, history]
}
</script>

<style lang="scss">
  .log_field_name {
    font-weight: bold;
    font-style: italic;
  }

  .log_old_value {
    color: red;
  }
  .log_new_value {
    color: green;
  }
</style>
