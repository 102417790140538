export default {
  risks_list: {
    table: {
      id : 'ID',
      key: 'Translation Key',
      name: 'Name',
      token: 'Token',
    },
    form: {
      fields: {
        index: 'Index',
        key: 'Translation Key',
        name: 'Name',
        token: 'Token',
      }
    }
  }
}
