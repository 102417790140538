export default {
  kiwi_queue_locators: {
    table: {
      kiwiLocator: 'Kiwi locator',
      adminLocator: 'Admin locator',
      trigger: 'Trigger',
      supplier: 'Supplier',
      status: 'Status',
      route: 'Route',
      departureDate: 'Departure date',
      mmbLink: 'MMB link'
    },
    form: {
      fields: {
        locator: 'Locator',
        trigger: 'Trigger',
        departureDate: 'Departure date',
        supplier: 'Supplier',
        processingDate: 'Processing date',
        status: 'Status',
        urgent: 'Urgent'
      }
    },
    alert: {
      mark_as_success_confirm: 'Mark selected entries as successful. Are you sure?',
      mark_as_awaiting_confirm: 'Mark selected entries as awaiting. Are you sure?',
      mark_as_new_confirm: 'Re-queue the selected entries. Are you sure?'
    },
    action: {
      mark_as_success: 'Successful!',
      mark_as_awaiting: 'Awaiting!',
      mark_as_new: 'Re-queue!',
      mmb_link: 'MMB Link'
    }
  },
  kiwi_queue_refunds: {
    table: {
      kiwiLocator: 'Kiwi locator',
      adminLocator: 'Admin locator',
      webhookTrigger: 'Webhook trigger',
      status: 'Status',
      mmbLink: 'MMB link',
      originalKiwiLocator: 'Original BID',
      reference: 'Reference',
      webhookType: 'Webhook type',
      mcoType: 'MCO type',
    },
    form: {
      fields: {
        locator: 'Locator',
        supplier: 'Supplier',
        webhookTrigger: 'Webhook trigger',
        webhookType: 'Webhook type',
        mcoType: 'MCO type',
        status: 'Status'
      }
    },
    alert: {
      mark_as_success_confirm: 'Mark selected entries as successful. Are you sure?',
      mark_as_awaiting_confirm: 'Mark selected entries as awaiting. Are you sure?',
      mark_as_new_confirm: 'Re-queue the selected entries. Are you sure?'
    },
    action: {
      mark_as_success: 'Successful!',
      mark_as_awaiting: 'Awaiting!',
      mark_as_new: 'Re-queue!'
    }
  },
  kiwi_queue_issues: {
    table: {
      kiwiLocator: 'Kiwi locator',
      adminLocator: 'Admin locator',
      webhookType: 'Webhook type',
      webhookTrigger: 'Webhook trigger',
      status: 'Status',
      mmbLink: 'MMB link',
      supplier: 'Supplier',
      departureDate: 'Departure date',
      comment: 'Comment',
    },
    form: {
      fields: {
        locator: 'Locator',
        supplier: 'Supplier',
        webhookTrigger: 'Webhook trigger',
        webhookType: 'Webhook type',
        status: 'Status',
        departureDate: 'Departure date'
      }
    },
    alert: {
      mark_as_success_confirm: 'Mark selected entries as successful. Are you sure?',
      mark_as_awaiting_confirm: 'Mark selected entries as awaiting. Are you sure?',
      mark_as_new_confirm: 'Re-queue the selected entries. Are you sure?'
    },
    action: {
      mark_as_success: 'Successful!',
      mark_as_awaiting: 'Awaiting!',
      mark_as_new: 'Re-queue!'
    },
    modal: {
      title: 'Comment',
      show_more: 'show more'
    }
  },
  kiwi_queue_issue_history: {
    table: {
      time: 'Time',
      userLogin: 'User',
      operation: 'Operation',
      changes: 'Changes'
    }
  }
}