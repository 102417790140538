export default{
  computed:{
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    },
    multiRowConfig(){
      return {}
    }
  },
  methods: {
    elAttr(fieldName, prop){
      return this.attr(fieldName, Object.assign({}, this.fieldReference[fieldName], {prop: prop}))
    },
    beforeCreateCallback(){
      this.temp = Object.assign({}, this.temp, Object.fromEntries(Object.entries(this.multiRowConfig).map(([k, v]) => [`${k}_decoded`, [{...v['default']}]])))
    },
    formatTemp() {
      Object.keys(this.multiRowConfig).forEach(fieldKey => {
        for(let item of this.temp[`${fieldKey}_decoded`]){
          Object.keys(item).map((key, index) => { item[key] = this.formatField(item[key], this.fieldReference[`${fieldKey}__${this._.camelCase(key)}`]) })
        }

        this.temp[fieldKey] = JSON.stringify(this.temp[`${fieldKey}_decoded`])
      })

      return this.temp
    },
    async actualizeCallback(temp){
      let newTemp = {}

      for(let i = 0; i < this.fields.length; i++){
        let field = this.fields[i]

        if(!field.ignore){
          newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
        }
      }

      Object.keys(this.multiRowConfig).forEach(fieldKey => {
        if(temp[fieldKey]){
          newTemp[`${fieldKey}_decoded`] = []
          for(let item of temp[fieldKey]){
            Object.keys(item).map((key, index) => { item[key] = this.formatFieldReverse(item[key], this.fieldReference[`${fieldKey}__${this._.camelCase(key)}`]) })
            newTemp[`${fieldKey}_decoded`].push(item)
          }
        }
      })

      return newTemp
    },
    isDeleteRowDisabled(key){
      return (this.temp[`${key}_decoded`].length <= 1)
    },
    actionAddItem(key){
      this.temp[`${key}_decoded`].push({...this.multiRowConfig[key]['default']})
    },
    actionRemoveItem(key, i){
      this.temp[`${key}_decoded`].splice(i, 1)
    }

  }
}
