export default {
  reportsQraphql(jobType){
    return {
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaParsersReport',
        queries: {
          index: {
            id: true,
            jobType: true,
            lastStartTime: true,
            jobStatus: true,
            sessionId: true,
            message: true,
            filePath: true,
            params: true,
            createdAt: true,
            updatedAt: true,
            attemptsCount: true
          }
        },
        baseParams: {
          index: {
            filters: {
              aviaParsersReports: [
                { field: 'job_type', operator: (Array.isArray(jobType) ? "in" : "eq"), value: jobType }
              ]
            }
          }
        }
      }
    }
  },
  reportsTableItems(){
    return [
      'selection',
      {
        name: 'id',
        width: '60px'
      },
      {
        name: 'jobType',
        format: 'from_options'
      },
      {
        name: 'jobStatus',
        format: 'from_options'
      },
      {
        name: 'attemptsCount'
      },
      {
        name: 'file',
        width: '60px'
      },
      {
        name: 'lastStartTime',
        format: 'date_time',
        width: '120px'
      },
      // {
      //   name: 'sessionId'
      // },
      // {
      //   name: 'message'
      // },
      // {
      //   name: 'filePath'
      // },
      // {
      //   name: 'params'
      // },
      // {
      //   name: 'createdAt'
      // },
      {
        name: 'updatedAt',
        format: 'date_time',
        width: '120px'
      },
      {
        name: 'actions',
        width: '60px'
      },
      {
        name: 'expand',
        type: 'expand',
        width: '30px'
      }
    ]
  },
  reportsRowActions(){
    return [
      {
        name: 'download',
        icon: 'el-icon-download',
        type: 'success',
        plain: true,
        roles: false
      }
    ]
  },
  reportsPanelActions(){
    return [
      //  {
      //    name: 'create',
      //    icon: 'el-icon-plus',
      //    type: 'primary',
      //    plain: false
      //  },
      //  {
      //    name: 'delete',
      //    icon: 'el-icon-delete',
      //    type: 'danger',
      //    plain: true,
      //    dependent: 'selection'
      //  },
      {
        name: 'refresh',
        icon: 'el-icon-refresh',
        type: 'primary',
        plain: true
      },
      {
        name: 'history',
        icon: 'el-icon-refresh',
        type: 'warning',
        plain: true,
        params: {
          record_class: 'PersistanceApi::Avia::Parsers::Report::ItemRepository'
        }
      },
      {
        name: 'filter',
        context: 'panel',
        icon: 'el-icon-help',
        plain: true
      }
    ]
  },
  reportsFormItems(){
    return [
      {
        name: 'id',
        type: 'hidden',
        required: false,
        valueType: 'integer'
      },
      {
        name: 'jobType',
        type: 'select',
        required: true,
        valueType: 'integer'
      },
      {
        name: 'lastStartTime',
        type: 'text',
        required: false
      },
      {
        name: 'jobStatus',
        type: 'text',
        required: true,
        valueType: 'integer'
      },
      {
        name: 'sessionId',
        type: 'text',
        required: true
      },
      {
        name: 'message',
        type: 'text',
        required: true
      },
      {
        name: 'filePath',
        type: 'text',
        required: false
      },
      {
        name: 'params',
        type: 'text',
        required: false,
        valueType: 'json_as_string'
      }
    ]
  },
  settingsQraphql(parserType) {
    return {
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaParsersSetting',
        queries: {
          index: {
            id: true,
            name: true,
            parserType: true,
            options: true,
            active: true,
            lastRunTime: true,
            createdAt: true,
            updatedAt: true
          }
        },
        baseParams: {
          index: {
            filters: {
              aviaParsersSettings: [
                { field: 'parser_type', operator: (Array.isArray(parserType) ? "in" : "eq"), value: parserType }
              ]
            }
          }
        }
      },
    }
  },
  settingsTableItems() {
    return [
      'selection',
      {
        name: 'id',
        width: '60px'
      },
      {
        name: 'name'
      },
      // {
      //   name: 'parserType'
      // },
      // {
      //   name: 'options'
      // },
      {
        name: 'active',
        type: 'switch',
        width: '70px'
      },
      {
        name: 'lastRunTime',
        width: '150px'
      },
      // {
      //   name: 'createdAt'
      // },
      {
        name: 'updatedAt',
        width: '150px'
      },
      {
        name: 'actions',
        width: '60px'
      },
      {
        name: 'expand',
        type: 'expand',
        width: '30px'
      }
    ]
  },
  settingsRowActions() {
    return [
      {
        name: 'update',
        icon: 'el-icon-edit',
        type: 'primary',
        plain: true
      }
    ]
  },
  settingsPanelActions(){
    return [
      {
        name: 'create',
        icon: 'el-icon-plus',
        type: 'primary',
        plain: false
      },
      {
        name: 'delete',
        icon: 'el-icon-delete',
        type: 'danger',
        plain: true,
        dependent: 'selection'
      },
      {
        name: 'refresh',
        icon: 'el-icon-refresh',
        type: 'primary',
        plain: true
      },
      {
        name: 'history',
        icon: 'el-icon-refresh',
        type: 'warning',
        plain: true,
        params: {
          record_class: 'PersistanceApi::Avia::Parsers::Setting::ItemRepository'
        }
      },
      {
        name: 'filter',
        context: 'panel',
        icon: 'el-icon-help',
        plain: true
      }
    ]
  },
  settingsFilterItems(){
    return [
      {
        name: 'id',
        type: 'text',
        operator: 'eq'
      }
    ]
  },
  settingsFormItems(parserType) {
    return [
      {
        name: 'id',
        type: 'hidden',
        required: false,
        valueType: 'integer'
      },
      {
        name: 'name',
        type: 'text',
        required: true
      },
      {
        name: 'parserType',
        type: 'hidden',
        required: true,
        valueType: 'integer',
        default: parserType
      },
      {
        name: 'options',
        type: 'hidden',
        required: true,
        valueType: 'json_as_string'
      },
      {
        name: 'active',
        type: 'checkbox',
        required: false,
        default: true
      }
    ]
  },
}
