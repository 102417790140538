export default {
  name: 'markup_analysis',
  items: [
    {
      name: 'meta_markup',
      endpoint: '/persistance/default',
      pagination: false,
      type: 'graphql',
      graphql: {
        name: 'analysisMarkupStatistics',
        queries: {
          index: {
              key: true,
              bookingsCount: true,
              convBookingsCount: true,
              clicksCount: true,
              ctr: true,
              realCtr: true,
              metaMarkup: true,
              outgoings: true,
              revenue: true,
              items: true,
              key: true,
              convOutgoings: true
          }
        }
      },
      combined: [
        {
          name: 'top_items',
          boot: {
            type: 'local',
            row_key: 'items'
          },
          combined: [
            {
              name: 'first_level',
              boot: {
                type: 'local',
                row_key: 'first_level'
              },
              combined: [
                {
                  name: 'second_level',
                  boot: {
                    type: 'local',
                    row_key: 'second_level'
                  },
                  combined: [
                    {
                      name: 'third_level',
                      boot: {
                        type: 'local',
                        row_key: 'third_level'
                      },
                      combined: [
                        {
                          name: 'locators',
                          boot: {
                            type: 'local',
                            row_key: 'locators'
                          },
                          table: {
                            hideHeader: true,
                            items: [
                              {
                                name: 'index',
                                type: 'index',
                                width: '40',
                              },
                              'locator'
                            ]
                          },
                        },
                      ],
                      table: {
                        hideHeader: true,
                        sortType: 'local',
                        items: [
                          {
                            name: 'index',
                            type: 'index',
                          },
                          'locator'
                        ]
                      },
                    },
                  ],
                  table: {
                    sortType: 'local',
                    items: [
                      {
                        name: 'expand',
                        type: 'expand',
                        width: '25',
                      },
                      {
                        name: 'index',
                        type: 'index',
                      },
                      'key',
                      'clicks_count',
                      'bookings_count',
                      'conv_bookings_count',
                      'ctr',
                      'meta_markup',
                      {
                        name: 'outgoings',
                        format: 'hash_as_string',
                        sortable: false,
                      },
                      {
                        name: 'revenue',
                        format: 'hash_as_string',
                        sortable: false,
                      },
                      {
                        name: 'conv_outgoings',
                        format: 'hash_as_string',
                        sortable: false,
                      },
                    ]
                  },
                },
              ],
              table: {
                sortType: 'local',
                items: [
                  {
                    name: 'expand',
                    type: 'expand',
                    width: '25',
                  },
                  {
                    name: 'index',
                    type: 'index',
                  },
                  'key',
                  'clicks_count',
                  'bookings_count',
                  'conv_bookings_count',
                  'ctr',
                  'real_ctr',
                  'meta_markup',
                  {
                    name: 'outgoings',
                    format: 'hash_as_string',
                    sortable: false,
                  },
                  {
                    name: 'revenue',
                    format: 'hash_as_string',
                    sortable: false,
                  },
                  {
                    name: 'conv_outgoings',
                    format: 'hash_as_string',
                    sortable: false,
                  },
                ]
              },
            },
          ],
          table: {
            sortType: 'local',
            items: [
              {
                name: 'expand',
                type: 'expand',
                width: '25',
              },
              {
                name: 'index',
                type: 'index',
              },
              'key',
              'clicks_count',
              'bookings_count',
              'conv_bookings_count',
              'ctr',
              'real_ctr',
              'meta_markup',
              {
                name: 'outgoings',
                format: 'hash_as_string',
                sortable: false,
              },
              {
                name: 'revenue',
                format: 'hash_as_string',
                sortable: false,
              },
              {
                name: 'conv_outgoings',
                format: 'hash_as_string',
                sortable: false,
              },
            ]
          },
        },
      ],
      table: {
        items: [
          {
            name: 'expand',
            type: 'expand',
            width: '25',
          },
          {
            name: 'key',
            sortable: false
          },
          {
            name: 'clicksCount',
            sortable: false
          },
          {
            name: 'bookingsCount',
            sortable: false
          },
          {
            name: 'convBookingsCount',
            sortable: false
          },
          {
            name: 'ctr',
            sortable: false
          },
          {
            name: 'real_ctr',
            sortable: false
          },
          {
            name: 'meta_markup',
            sortable: false
          },
          {
            name: 'outgoings',
            format: 'hash_as_string',
            sortable: false,
          },
          {
            name: 'revenue',
            format: 'hash_as_string',
            sortable: false,
          },
          {
            name: 'conv_outgoings',
            format: 'hash_as_string',
            sortable: false
          },
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'main',
            items: [
              {
                  name: 'group_options',
                  type: 'multiselect',
                  store: {
                      date: 'date',
                      validating_supplier: 'supplier',
                      // avia_search_config_id: 'config_id',
                      route: 'route',
                  },
                  operator: 'eq',
                  default: ['date']
              },
              {
                name: 'refid',
                required: true,
                type: 'select',
                storeKey: 'traffic_sources',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                operator: 'eq'
              },
              {
                name: 'dates',
                operator: 'eq',
                type: 'daterange',
                required: true,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: ['last week', 'now'],
              },
              {
                name: 'supplier',
                camelizeLangKey: true,
                type: 'text',
                required: false,
                operator: 'in_array_str'
              },
              {
                name: 'config_id',
                type: 'multiselect',
                storeKey: 'config_items',
                storeLabelKeys: ['id', 'name'],
                operator: 'in_array_str',
                lazy: true
              },
              {
                name: 'route',
                type: 'text',
                required: false,
                operator: 'in_array_str'
                // поки не працює valueDelimiter для фільтрів, лише для форми
                // browse/app/javascript/vendor/element-crud/src/mixin/form.js#355
                // valueType: 'array_of_string',
                // valueDelimiter: ','
              },
              {
                name: 'price_range',
                type: 'text',
                operator: 'in_array_str',
                default: '0-MAX'
              }
            ]
          },
          {
            legend: 'extended',
            items: [
              {
                name: 'except_config',
                type: 'multiselect',
                storeKey: 'config_items',
                storeLabelKeys: ['id', 'name'],
                operator: 'in_array_str'
              },
              {
                name: 'except_supplier',
                type: 'text',
                operator: 'in_array_str',
                valueDelimiter: ','
              },
              {
                name: 'currency',
                type: 'text',
                // store: currency_list,
                // store: [],
                operator: 'string',
                // type: 'select',
              },
            ]
          }
        ]
      }
    }
  ]
}
