<template lang="pug">
  extends /table
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatCell({ row, $column, column, cellValue, index }) {

      if (column.name == 'noShowTime') {

        if (cellValue)
          return cellValue + ' minutes'
        else
          return ' --- '
      }

      if (column.name == 'void') {

        if (cellValue)
          return cellValue + ' minutes ' + (row.voidSwitcher ? 'AFTER the issue time' : 'BEFORE the end of the issue day')
        else
          return ' --- '

      }

      return cellValue
    }
  }
}
</script>