import gdsStore from '../../avia/general/gds_store'

export default {
  name: 'settings_timelimit',
  meta: {
    title: 'settings_timelimit',
    icon: 'skill',
    permission: 'settings_timelimit'
  },
  lazy: true,
  items: [
    {
      name: 'settings_timelimit',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'settingsTimelimitRule',
        queries: {
          index: {
            id: true,
            index: true,
            suppliers: true,
            routes: true,
            daysRange: true,
            timelimit: true,
            gdsIds: true,
            active: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: '_index',
            width: '70px'
          },
          {
            name: 'suppliers',
            format: 'array_as_string',
            width: '120px',
            readMore: true,
            readMoreChars: 25
          },
          {
            name: 'gds_ids',
            format: 'array_as_string_options',
            // width: '150px'
          },
          {
            name: 'routes',
            readMore: true,
            readMoreChars: 25
          },
          {
            name: 'days_range',
            width: '90px'
          },
          {
            name: 'timelimit',
            width: '80px'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '90px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'TtnAviaMultiSearchService::Settings::TimelimitRule'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'suppliers',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'routes',
            type: 'text',
            operator: 'eq'
          },
          // {
          //   name: 'active',
          //   type: 'radiogroup',
          //   button: true,
          //   default: 'all',
          //   operator: 'eq',
          //   store: {
          //     'all': 'All',
          //     true: 'Yes',
          //     false: 'No'
          //   }
          // }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'index',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'days_range',
            type: 'text',
            required: true,
            valueType: 'string'
          },
          {
            name: 'timelimit',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'suppliers',
            type: 'textarea',
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 5
          },
          {
            name: 'routes',
            type: 'textarea',
            valueType: 'string',
            required: false,
            rows: 5
          },
          {
            name: 'gdsIds',
            type: 'multiselect',
            store: [{ id: 0, name: 'all' }].concat(gdsStore.arrayById()),
            default: [0],
            required: true,
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: true
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          }
        ]
      }
    }
  ]
}
