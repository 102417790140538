import meta from './reports/meta'
import pricing from './reports/pricing'

export default {
  namespace: 'travel_rankings',
  component: 'reports',
  meta: {
    title: 'travel_rankings.reports',
    icon: 'table'
  },
  tabs: [
    meta,
    pricing
  ]
}
