<template lang="pug">
  extends /filter
  block fields
    el-row
      el-col(:span="24")
        el-form-item(:label="translate(`form.fields.struct`)" prop="struct_version" :show-message="false")
          el-col(:span="24")
            div(class="form-el-itm")
              ttn-select(
                v-model="filters.struct_version.value"
                v-bind="attr('struct_version')"
                :form-item-wraper="true"
                @change="changeStructVersion"
              )
            div(class="form-el-itm")
              el-button(type="text" @click="viewStruct" v-if="btnVisible") {{translate('form.fields.previewStruct')}}
    el-row
      el-col(:span="24")
        el-form-item(:label="translate(`form.fields.inputData`)" prop="data" :show-message="false")
          el-row
            el-col(:span="24")
              ttn-textarea(
                v-model="filters.data.value"
                v-bind="attr('data')"
                :form-item-wraper="true"
              )
  block custom
    preview-dialog
</template>

<script>
import BaseFilter from '@crud_view/filter'
import PreviewDialog from './preview_dialog'

export default {
  components: {
    PreviewDialog
  },
  extends: BaseFilter,
  data() {
    return {
      btnVisible: false
    }
  },
  methods: {
    changeStructVersion(val) {
      this.btnVisible = !!val
    },

    viewStruct() {
      console.log(this.preloadStores)
      const schema = this.preloadStores['structs'][this.filters.struct_version.value].schema
      this.$elItem.$emit('previewSchemaDialog', { schema: schema })
    }
  }
}
</script>

<style lang="scss">
  .webscraper_validator_filter {
    .el-form-item .el-form-item__content {
      margin-bottom: 4px;
      .form-el-itm {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
</style>
