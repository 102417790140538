<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='12' class='row')
          ttn-switch(v-model="temp['active']" v-bind="attr('active')")
        el-col(:span='6' class='row')
          div &nbsp;
        el-col(:span='2' class='row')
          span Priority
        el-col(:span='4' class='row')
          el-input-number(v-model="temp['priority']" v-bind="attr('priority')" label='Priority' :min='0' :max='10' size='mini')
      fieldset(:class="`el-form-fieldset`" )
        legend {{ translate(`form.legends.fare_rule_data`) }}
        div
          el-row
            el-col(:span='24' class='row')
              ttn-textarea(v-model="temp['ruleText']" v-bind="attr('ruleText')")

          el-row
            el-col(:span='2' class='row')
              div &nbsp;
            el-col(:span='22' class='row')
              span Variables for parsing: %amount%, %currency%. For example: "Fee 10.00 USD for pay"  -  "Fee %amount% %currency% for pay"

          el-row
            el-col(:span='24' class='row')
              div &nbsp;

          el-row
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['beforeDeparture']" v-bind="attr('beforeDeparture')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['beforeDepartureAddFee']" v-bind="attr('beforeDepartureAddFee')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['beforeDepartureChangeTo']" v-bind="attr('beforeDepartureChangeTo')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['beforeDepartureNonRef']" v-bind="attr('beforeDepartureNonRef')" label-width='80px')
          el-row
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['afterDeparture']" v-bind="attr('afterDeparture')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['afterDepartureAddFee']" v-bind="attr('afterDepartureAddFee')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['afterDepartureChangeTo']" v-bind="attr('afterDepartureChangeTo')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['afterDepartureNonRef']" v-bind="attr('afterDepartureNonRef')" label-width='80px')
          el-row
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['noShow']" v-bind="attr('noShow')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['noShowAddFee']" v-bind="attr('noShowAddFee')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['noShowChangeTo']" v-bind="attr('noShowChangeTo')" label-width='80px')
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['noShowNonRef']" v-bind="attr('noShowNonRef')" label-width='80px')

          hr

          el-row(class='sbf-fl-bt-sblock')
            el-col(:span='12' class='row')
              span &nbsp;
            el-col(:span='6' class='row')
              el-button(@click="parseRule" type="danger" round :disabled="parseLoader") {{ translate('form.buttons.parse_rule') }}
            el-col(:span='6' class='row')
              span &nbsp;

          hr

          el-row
            el-col(:span='4' class='row')
              ttn-checkbox(v-model="temp['penaltyBeforeDepartureNonRef']" v-bind="attr('penaltyBeforeDepartureNonRef')")
            el-col(:span='5' class='row')
              span &nbsp;
            el-col(:span='7' class='row')
              ttn-input(v-model="temp['penaltyBeforeDepartureAmount']" v-bind="attr('penaltyBeforeDepartureAmount')")
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='6' class='row')
              ttn-select(v-model="temp['penaltyBeforeDepartureCurrency']" v-bind="attr('penaltyBeforeDepartureCurrency')")
            el-col(:span='1' class='row')
              div &nbsp;
          el-row
            el-col(:span='4' class='row')
              ttn-checkbox(v-model="temp['penaltyAfterDepartureNonRef']" v-bind="attr('penaltyAfterDepartureNonRef')")
            el-col(:span='5' class='row')
              span &nbsp;
            el-col(:span='7' class='row')
              ttn-input(v-model="temp['penaltyAfterDepartureAmount']" v-bind="attr('penaltyAfterDepartureAmount')")
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='6' class='row')
              ttn-select(v-model="temp['penaltyAfterDepartureCurrency']" v-bind="attr('penaltyAfterDepartureCurrency')")
            el-col(:span='1' class='row')
              div &nbsp;
          el-row
            el-col(:span='4' class='row')
              ttn-checkbox(v-model="temp['penaltyNoshowNonRef']" v-bind="attr('penaltyNoshowNonRef')")
            el-col(:span='5' class='row')
              span &nbsp;
            el-col(:span='7' class='row')
              ttn-input(v-model="temp['penaltyNoshowAmount']" v-bind="attr('penaltyNoshowAmount')")
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='6' class='row')
              ttn-select(v-model="temp['penaltyNoshowCurrency']" v-bind="attr('penaltyNoshowCurrency')")
            el-col(:span='1' class='row')
              div &nbsp;
    
      br

      fieldset(:class="`el-form-fieldset`" )
        legend {{ translate(`form.legends.main_rule_data`) }}
        div
          el-row
            el-col(:span='10' class='row')
              ttn-input(v-model="temp['validatingSupplier']" v-bind="attr('validatingSupplier')" type='text' showWordLimit maxLength='2' minLength='2')
            el-col(:span='2' class='row')
              div &nbsp;
            el-col(:span='10' class='row')
              ttn-input(v-model="temp['fareBasis']" v-bind="attr('fareBasis')")
            el-col(:span='2' class='row')
              div &nbsp;
          el-row
            el-col(:span='11' class='row')
              ttn-select(v-model="temp['gds']" v-bind="attr('gds')")
            el-col(:span='1' class='row')
              div &nbsp;
            el-col(:span='11' class='row')
              ttn-select(v-model="temp['bsp']" v-bind="attr('bsp')")
            el-col(:span='1' class='row')
              div &nbsp;

          hr

          el-row(class='sbf-fl-bt-sblock')
            el-col(:span='12' class='row')
              span &nbsp;
            el-col(:span='6' class='row')
              el-button(@click="saveRule" type="success" round :disabled="saveLoader") {{ translate('form.buttons.save_rule') }}
            el-col(:span='6' class='row')
              span &nbsp;
            
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  components: {
    BaseForm
  },
  extends: BaseForm,
  data() {
    return {
      saveLoader: false,
      parseLoader: false
    }
  },
  methods:{
    parseRule() {
      if (this.ifAnyTemplatePresent(this.temp)){
        this.$confirm(this.$t('alert.delete_confirm'), this.$t('alert.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(async() => {
          try {
            this.parseLoader = true
            const query = {
              __args: {
                ruleText: this.temp.ruleText,
                beforeDeparture: this.temp.beforeDeparture,
                beforeDepartureAddFee: this.temp.beforeDepartureAddFee,
                beforeDepartureChangeTo: this.temp.beforeDepartureChangeTo,
                beforeDepartureNonRef: this.temp.beforeDepartureNonRef,
                afterDeparture: this.temp.afterDeparture,
                afterDepartureAddFee: this.temp.afterDepartureAddFee,
                afterDepartureChangeTo: this.temp.afterDepartureChangeTo,
                afterDepartureNonRef: this.temp.afterDepartureNonRef,
                noShow: this.temp.noShow,
                noShowAddFee: this.temp.noShowAddFee,
                noShowChangeTo: this.temp.noShowChangeTo,
                noShowNonRef: this.temp.noShowNonRef
              }
            }
  
            const resp = await this.graphql('runAviaFareRuleParser', query, 'mutation')
            this.temp = Object.assign({}, this.temp, resp)
  
            this.parseLoader = false
  
            this.$message({
              message: 'See amount blocks',
              type: 'success'
            })
          } catch (e) {
            this.$message({
              message: e,
              type: 'error'
            })
            this.parseLoader = false
          }
        }).catch((e) => {})
      }else{
        this.temp['penaltyNoshowNonRef'] = true
        this.temp['penaltyAfterDepartureNonRef'] = true
        this.temp['penaltyBeforeDepartureNonRef'] = true

      }
    },
    saveRule() {
      if (this.validRule(this.temp)){
        this.$confirm(this.$t('alert.delete_confirm'), this.$t('alert.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(async() => {
          try {  
            this.saveLoader = true
            const query = {
              __args: {
                priority: 5,
                active: false,
                validatingSupplier: this.temp.validatingSupplier,
                fareBasis: this.temp.fareBasis,
                bsp: this.temp.bsp,
                gds: this.temp.gds,
                penaltyBeforeDepartureAmount: this.temp.penaltyBeforeDepartureAmount || null,
                penaltyBeforeDepartureCurrency: this.temp.penaltyBeforeDepartureCurrency || null,
                penaltyBeforeDepartureNonRef: (this.temp.penaltyBeforeDepartureNonRef || false),
                penaltyAfterDepartureAmount: this.temp.penaltyAfterDepartureAmount || null,
                penaltyAfterDepartureCurrency: this.temp.penaltyAfterDepartureCurrency || null,
                penaltyAfterDepartureNonRef: (this.temp.penaltyAfterDepartureNonRef || false),
                penaltyNoshowAmount: this.temp.penaltyNoshowAmount || null,
                penaltyNoshowCurrency: this.temp.penaltyNoshowCurrency || null,
                penaltyNoshowNonRef: (this.temp.penaltyNoshowNonRef || false)
              }
            }
  
            const resp = await this.graphql('aviaFareRuleCreate', query, 'mutation')
            
            this.$message({
              message: resp,
              type: 'success'
            })
            this.saveLoader = false
          } catch (e) {
            this.$message({
              message: e,
              type: 'error'
            })
            this.saveLoader = false
          }
        }).catch((e) => {})
      }
    },
    ifAnyTemplatePresent(vModel){
      let indicator = false
      
      let fields = ['beforeDeparture', 'beforeDepartureAddFee', 'beforeDepartureChangeTo', 'beforeDepartureNonRef',
       'afterDeparture', 'afterDepartureAddFee', 'afterDepartureChangeTo', 'afterDepartureNonRef',
       'noShow', 'noShowAddFee', 'noShowChangeTo', 'noShowNonRef']

      fields.forEach(function(item,index){
        if(vModel[item].length > 1) indicator = true
       })

       return indicator
    },
    validRule(vModel){
    
      if (vModel['validatingSupplier'].length != 2){
    
        this.$refs.dataForm.validateField('validatingSupplier')

        this.$notify({
          title: 'Warning',
          message: 'Check Validating supplier. Must contain two letters or numbers',
          type: 'warning',
          duration: 2600
        })
    
        return false

      }

      return true
    },
  }
}
</script>