export default {
  'UAH': 'UAH',
  'USD': 'USD',
  'EUR': 'EUR',
  'RUR': 'RUR',
  'PLN': 'PLN',
  'AZN': 'AZN',
  'KZT': 'KZT',
  'MDL': 'MDL',
  'TRY': 'TRY',
  'RON': 'RON',
  'BYR': 'BYR',
  'BYN': 'BYN',
  'AED': 'AED',
  'KGS': 'KGS',
  'AMD': 'AMD',
  'UZS': 'UZS',
  'AUD': 'AUD',
  'MXN': 'MXN',
  'GEL': 'GEL',
  'CHF': 'CHF',
  'SEK': 'SEK',
  'ILS': 'ILS',
  'QAR': 'QAR',
  'SAR': 'SAR',
  'EGP': 'EGP',
  'INR': 'INR',
  'BHD': 'BHD',
  'KWD': 'KWD',
  'PHP': 'PHP',
  'CZK': 'CZK',
  'DKK': 'DKK',
  'CNY': 'CNY',
  'NGN': 'NGN',
  'MYR': 'MYR'
}