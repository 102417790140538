export default {
  settings_search: {
    table: {
      index: 'Index',
      Index: 'Index',
      key: 'Key',
      departures: 'Departure',
      arrivals: 'Arrival',
      active: 'Active',
      daysRange: 'Max trip days',
      flightDuration: 'Flight dur.(h)',
      maxPrice: 'Max price (EUR)',
      minAirportConnTime: 'Min PC airp conn. (m)',
      minCityConnTime: 'Min PC city conn. (m)',
      maxConnTime: 'Max PC conn. (m)',
      priceComponentsCount: 'Price comp. count',
    },
    form: {
      fields: {
        id: 'ID',
        index: 'Index',
        key: 'Key',
        departures: 'Departure country',
        arrivals: 'Arrival country',
        countries: 'Search country',
        active: 'Active',
        daysRange: 'Max trip days(d)',
        maxPrice: 'Max price (EUR)',
        flightDuration: 'Flight dur.(h)',
        minAirportConnTime: 'Min PC airp conn.(m)',
        minCityConnTime: 'Min PC city conn.(m)',
        maxConnTime: 'Max PC conn.(m)',
        priceComponentsCount: 'Price comp. count',
      },
      placeholder: {
        daysRange: '2',
        priceComponentsCount: '2-3'
      }
    }
  }
}
