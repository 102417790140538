<template lang="pug" src="./../templates/filter.pug" />

<script>
import common from './../../mixin/common'
import formBase from './../../mixin/form/base'
import filter from './../../mixin/filter'
import di from './../../mixin/di'
import component from './../../mixin/component'

export default {
  name: 'CrudFilter',
  mixins: [component, common, di, formBase, filter]
}
</script>
