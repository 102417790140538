<template lang="pug">
  div(class="list-content" v-loading="loading")
    div(v-for="(rec, i) in records" :key="i" class="block-item")
      el-card(class="box-card")
        div(slot="header")
          span {{rec.route}}, {{rec.airline}}, {{rec.date}}
        el-tabs(type="card" :value="Object.keys(rec.items)[0]")
          el-tab-pane(v-for="(items, key) in rec.items" :label="key" :key="key" :name="key.replace(/\s+/g, '_')" :lazy="true")
            el-row
              el-col(:span="12")
                div(style="padding: 20px")
                  chart-position(:group="`${rec.route}_${rec.airline}_${rec.date}_${key}`")
              el-col(:span="12")
                el-table(
                  :data="items"
                  @selection-change="handleSelectionChange(`${rec.route}_${rec.airline}_${rec.date}_${key}`, $event)"
                  :default-sort = "{prop: 'first', order: 'descending'}"
                )
                  el-table-column(type="selection" width="55")
                  el-table-column(prop="key" :label="translate('key')")
                  el-table-column(prop="first" :label="translate('first')" sortable)
                  el-table-column(prop="second" :label="translate('second')" sortable)
</template>

<script>
import Base from '@crud_view/table'
import ChartPosition from './chart'

export default {
  name: 'TrTimeReport',
  components: {
    ChartPosition
  },
  extends: Base,
  data() {
    return {
    }
  },

  mounted() {
    this.$elItem.$on('changeReportType', this.changeReportType)
  },
  beforeDestroy() {
    this.$elItem.$off('changeReportType', this.changeReportType)
  },

  methods: {
    handleSelectionChange(key, val) {
      this.$emit('updateChart', { key, data: val.map((i) => i.chart) })
    },
    showInfo(details) {
      this.$emit('showInfo', details)
    },
    changeReportType(val) {
      this.report_type = val
    }
  }
}
</script>
