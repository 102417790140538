export default {
  legend: 'taxes',
  translateLegend: true,
  items: [
    {
      name: 'noShowTime',
      type: 'text',
      valueType: 'integer'
    },
    {
      name: 'nonRefTax',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    },
    {
      name: 'refundableTax',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    },
    {
      name: 'void',
      type: 'text',
      valueType: 'integer',
      required: false
    },
    {
      name: 'voidSwitcher',
      type: 'switch',
      default: false,
      required: false
    }
  ]
}