export default {
  operator_schedule: {
    table: {
      id: 'ID',
      active: 'Active',
      name: 'Name',
      shift: 'Shift',
      mo: 'Monday',
      tu: 'Tuesday',
      we: 'Wednesday',
      th: 'Thursday',
      fr: 'Friday',
      sa: 'Saturday',
      su: 'Sunday'
    },
    form: {
      fields: {
        name: 'Name',
        active: 'Active',
        shift: 'Shift',
        settings__depot: 'Depot',
        settings__pc: 'PC',
        settings__status: 'Status',
        settings__depot_type: 'Depot type',
        settings__backlog: 'Backlog'
      }    
    }
  }
}