export default {
  as_pesimisation_rules_list: {
    form: {
      fields: {
        id: 'ID',
        requestType: 'Request type',
        referenceType: 'Ref. type',
        referenceValue: 'Ref.value',
        routes: 'Routes',
        offices: 'Offices',
        gdsId: 'Gds',
        active: 'Active',
        data__count: 'Count',
        data__multiTicket: 'MultiTicket allowed',
        data__multiPcc: 'MultiPcc allowed'
      },
      options: {
        referenceType: {
          all: 'All',
          depot: 'Depot',
          traffic_source: 'Traffic source',
          0: 'All',
          1: 'Depot',
          2: 'Traffic source',
        },
        requestType: {
          all: 'All',
          api: 'Api',
          0: 'All',
          1: 'Api'
        },
        data__count: {
          '20i': '20ITINS',
          '50i': '50ITINS',
          '100i': '100ITINS',
          '200i': '200ITINS'
        },
        gdsId: {
          sabre: 'Sabre',
          0: 'Sabre'
        }
      },
      legends: {
        settings: 'Settings'
      }
    },
    table: {
      requestType: 'Req.',
      referenceType: 'Ref. type',
      referenceValue: 'Ref.value',
      routes: 'Routes',
      offices: 'Offices',
      gdsId: 'Gds',
      active: 'Active'
    }
  }
}
