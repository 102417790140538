export default {
  avia_bookings: {
    action: {
      info: 'Info'
    },
    table: {
      locator: 'Locator'
    },
    form: {
      fields: {
        locator: 'Locator',
        depotId: 'Depot',
        trafficSourceId: 'Traffic Source',
        supplier: 'VC Airline',
        currency: 'Currency',
        depotCostCurrency: 'Depot Currency',
        departureTimeGte: 'H Before Dep, From',
        departureTimeLte: 'H Before Dep, To',
        orderedAtGte: 'Ordered From (1h, 1d, 1w, 1m, 1y)',
        orderedAtLte: 'Ordered To (1h, 1d, 1w, 1m, 1y)'
      },
      legends: {
        order: 'Order item filter',
        booking: 'Booking filter'
      }
    }
  }
}
