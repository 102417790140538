/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue'

import Cookies from 'js-cookie'

import VJsoneditor from 'v-jsoneditor'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import '@/styles/element-variables.scss'
import 'element-ui/lib/theme-chalk/display.css'

import '@/styles/index.scss' // global css
import '@/styles/mini.scss'
import ElementCrud from '@/vendor/element-crud'
Vue.use(ElementCrud)

import App from '@/app.vue'
import engine from '../engine'
engine.init()

import i18n from '@/lang' // internationalization
import '@/icons' // icon
import '../permission' // permission control
import '../utils/error-log' // error log

import * as filters from '../filters' // global filters

import components from '../vendor/custom-table'

Object.entries(components).forEach(([name, component]) => Vue.component(name, component))

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
Vue.component('vue-json-pretty', VueJsonPretty)

import ReadMore from 'vue-read-more'
Vue.use(ReadMore)

const { mockXHR } = require('../mock')
mockXHR()

import moment_timezone from 'moment-timezone'

Vue.prototype.$moment_timezone = moment_timezone;

Vue.use(require('vue-moment'))

Vue.use(VJsoneditor)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Date.setLocale('en')
Vue.use(Element, {
  size: Cookies.get('size') || 'mini', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router: engine.router.router,
  store: engine.store,
  i18n,
  render: h => h(App)
})
