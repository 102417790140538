export default {
  fake_search_rules: {
    form: {
      fields: {
        vc: 'VC',
        configItems: 'Config Item ID',
        flightGeography: 'Flight Geography',
        config_items: 'Config Item ID',
        flight_geography: 'Flight Geography',
        monosearch: 'Monosearch',
        comments: 'Comments',
        depots: 'Depots',
        gds: 'GDS',
        refid: 'Ref ID'
      }
    }
  },

  fake_search_depot_rules: {
    form: {
      fields: {
        depots: 'Depots',
        blockedVc: 'Blocked VC',
        l1: 'L1',
        l2: 'L2',
        l3: 'L3',
        daysBeforeDeparture: 'Days BF',
        rateMargin: 'Rate Margin',
        margin: 'Margin',
        calculate: 'Calc.',
        configCurrency: 'Config Curr',
        blocked_vc: "Blocked VC"
      }
    }
  },

  fake_airline_rules: {
    table: {
      vc: 'Supplier',
      gds: 'Gds',
      hoursBeforeDeparture: 'Hours Before Dept.',
      priority: 'Priority'
    },
    form: {
      fields: {
        priority: 'Priority',
        gds: 'GDS',
        vc: 'Supplier',
        hoursBeforeDeparture: 'Hours Before Dept.'
      }
    }
  },

  fake_price_rules: {
    form: {
      fields: {
        priority: 'Priority',
        depot: 'Depot',
        refid: 'RefId',
        config: 'Config IID',
        vc: 'Supplier',
        markup: 'Markup',
        auction: 'Auction',
        dynamicPricing: 'Dynamic Price',
        ancillary: 'Ancillary',
        upsales: 'Upsales',
        manualDiscount: 'Manual Discount',
        manualBudget: 'Manual Budget',
        maxDiscount: 'Max Discount',
        mDiscPrice: 'Discount Price Range',
        mDiscType: 'Discount Type',
        mDiscDateRange: 'Discount Start/End Date',
        mBudgetStatus: 'Budget Status',
        mBudgetDateRange: 'Budget Start/End Date',
        maxDisPrice: 'Max Discount Price Range',
        maxDisType: 'Max Discount Type',
        budgetsJson: 'Manual Budget',
        discountsJson: 'Manual Discount',
        discountLimitsJson: 'Max Discount',
        maxDiscPriceFrom: 'Max Discount Price From',
        maxDiscPriceTo: 'Max Discount Price To',
        mDiscPriceFrom: 'Discount Price From',
        mDiscPriceTo: 'Discount Price To'
      }
    }
  },


  fake_search_stata: {
    form: {
      fields: {
        locator: 'Locator',
        profitability: "Book Profit",
        status: "Status",
        bookingStatus: "Booking Status",
        orderDateRange: "Created At",
        report: "Report",
        generateReport: "Generate Report",
        pcGdsId: "GDS ID",
        validatingSupplier: "Supplier",
        bspStr: "BSP",
        depotId: "DepotId",
        trafficSourceId: "RefId",
        locatorPc: "PC Locator"
      }
    }
  },


  fake_rules: {
    form: {
      fields: {
        priority: 'Priority',
        gds: 'GDS',
        refId: 'Traffic Source IDs',
        ref_id: 'Traffic Source ID',
        depots: 'Depots',
        refid: 'Traffic Source ID',
        payLimit: 'Pay Time Limit (min.)'
      }
    }
  },

  fake_search_booked_rules: {
    form: {
      fields: {
        vc: 'VC',
        configItems: 'Config Item ID',
        flightGeography: 'Flight Geography',
        config_items: 'Config Item ID',
        flight_geography: 'Flight Geography',
        monosearch: 'Monosearch',
        comments: 'Comments',
        depots: 'Depots',
        gds: 'GDS',
        refid: 'Ref ID'
      }
    }
  },

  segment_cost_rules: {
    table: {
      priority: 'Priority',
      bsp: 'BSP',
      gdsId: 'GDS',
      flightGeography: 'F Geo',
      supplier: 'Supplier',
      fareBasis: 'FB',
      fareBasisType: 'FB Type',
      rbd: 'RBD',
      rbdType: 'RBD Type',
      amount: 'Amount',
      currency: 'Currency',
      costType: 'Cost Type',
      valueType: 'Value Type',
      saleFrom: 'Sale Time From',
      saleTo: 'Salte Time To',
      periodFrom: 'Flight Time From',
      periodTo: 'Flight Time To',
      marketingSupplier: 'Marketing S',
      flightType: 'Flight Over',
      configGroups: 'Configs Group'
    },
    form: {
      options: {
        valueType: {
          0: 'Intensive GDS',
          1: 'Rebook'
        },
        costType: {
          0: 'By Pax',
          1: 'By Segment'
        },
        rbdType: {
          0: 'All',
          1: 'One From'
        },
        fareBasisType: {
          0: 'All',
          1: 'One From'
        },
        flightType: {
          0: 'Any',
          1: 'Direct',
          2: 'Layover Flight'
        },
        gdsId: {
          1: "amadeus_eretail",
        2: "amadeus",
        3: "galileo",
        4:"sirena",
        5:"gabriel",
        6:"sabre",
        7:"charter",
        8:"galileo_uapi",
        9:"accel",
        10:"fake_gds",
        11:"click_avia",
        12:"fly_dubai",
        13:"sun_express",
        14:"atlas_global",
        15:"travel_fusion",
        16:"radix",
        17:"troya",
        18:"gaia",
        19:"fare_logix",
        20:"hitit",
        21:"skyup",
        22:"afkl",
        23:"central_data_storage",
        24:"sig",
        25:"kiwi",
        26:"glory",
        27:"orchestrated_driver",
        28:"aerticket",
        29:"nemo_adapter_driver",
        30:"proxy",
        31:"pobeda_adapter",
        32:"tui",
        33:"arcadialab_adapter",
        34:"sq_driver",
        35:"yanair_adapter_driver",
        36:"travelport",
        37:"aero_crs",
        49:"himytrip"}
      },
      fields: {
        configGroups: 'Группы конфигов',
        priority: 'Приоритет',
        bsp: 'BSP',
        gdsId: 'GDS',
        flightGeography: 'Flight Geography',
        supplier: 'Supplier',
        fareBasis: 'Fare Basis',
        fareBasisType: 'FB Type',
        rbd: 'RBD',
        rbdType: 'RBD Type',
        amount: 'Сумма',
        currency: 'Валюта',
        costType: 'Выплата',
        valueType: 'Тип Доходности',
        saleFrom: 'Дата продажи с',
        saleTo: 'Дата продажи по',
        periodFrom: 'Перелет с',
        periodTo: 'Перелет по',
        marketingSupplier: 'Маркетинговый',
        flightType: 'Тип перелета',
        gds: "GDS",
        refid: "Ref ID"
      }
    }
  },

  fake_upsale_settings: {
    table: {
      supplier: 'Supplier',
      gdsId: 'Gds',
      fareFamilyCheck: 'Fare Family Status',
      ancillaryCheck: 'Ancillaries Status',
      ancillaryAllowedSsr: 'Ancillary SSR',
      fallbackRules: 'Fallback Rules'
    },
    form: {
      fields: {
        supplier: 'Supplier',
        gdsId: 'Gds',
        fareFamilyCheck: 'Fare Family Status',
        ancillaryCheck: 'Ancillaries Status',
        ancillaryAllowedSsr: 'Ancillary SSR',
        fallbackRules: 'Fallback Rules'
      },
      placeholder: {
        ancillaryAllowedSsr: 'ABAG:1PC:23KG',
        fallbackRules: 'any:any, any:23,45, 23:any'
      }
    }
  },

    fake_recc_setting: {
    form: {
      fields: {
        vc: "Airlines",
        refid: 'Ref Id',
        airlines: 'Airlines',
        maxPercent: 'Percent %',
        maxAmount: 'Max Amt',
        minAmount: 'Min Amt',
        active: 'Status',
        comments: 'Comments'
      }
    }
  },

  rec_settings: {
    table: {
      refId: 'Ref Id',
      airlines: 'Airlines',
      percent: '%',
      ceilingAmt: 'Max Amt',
      active: 'Status'
    },
    form: {
      fields: {
        refId: 'Ref Id',
        airlines: 'Airlines',
        percent: 'Percent %',
        ceilingAmt: 'Max Amt',
        active: 'Status'
      }
    }
  }
}
