export default {
  data() {
    return {
      filterCollapsed: false,
      fullStores: {},
      storesCache: {},
      storeValueCache: {},
      mountedStores: {
        panel: false,
        table: false,
        filter: false,
        form: false,
        history: false,
        panel_bottom: false
      },
      preloadStoreCache: {}
    }
  },

  inject: ['$elContainer', '$elTab'],

  provide() {
    return {
      '$elItem': this,
      '$elRootItem': this
    }
  },

  computed: {
    classObj() {
      return {
        hide_filter: this.filterCollapsed,
        open_filter: !this.filterCollapsed
      }
    },
    componentTable() {
      return () => this.dynamicComponent('table')
    },
    componentForm() {
      return () => this.dynamicComponent('form')
    },
    componentPanel() {
      return () => this.dynamicComponent('panel')
    },
    componentPanelBottom() {
      return () => this.dynamicComponent('panel_bottom')
    },
    componentFilter() {
      return () => this.dynamicComponent('filter')
    },
    componentHistory() {
      return () => this.dynamicComponent('history')
    },
    defaultOperators() {
      return ['eq', 'in', 'ne', 'start_with', 'like', 'gt', 'gte', 'lt', 'lte']
    }
  },

  mounted() {
    this.$on(this.$channels.filterCollapse, this.eventCollapseFilter)
    this.$on(this.$channels.hooksPreloadFinish(this.config.tab, this.config.name), this._resetStoreCache)
    this.$on(this.$channels.hooksPreloadFinish(this.config.tab, this.config.name), this._preparePreloadStores)
  },

  activated() {
    if (this.isActiveItem()) {
      this.$elContainer.$emit(this.$channels.navigationItemStart(this.tab, this.item))
      this.$nextTick(() => {
        this.$elContainer.$emit(this.$channels.navigationItemActivate(this.tab, this.item), {})
      })
    }
  },

  beforeDestroy() {
    this.$off(this.$channels.filterCollapse, this.eventCollapseFilter)
    this.$off(this.$channels.hooksPreloadFinish(this.config.tab, this.config.name), this._resetStoreCache)
    this.$off(this.$channels.hooksPreloadFinish(this.config.tab, this.config.name), this._preparePreloadStores)
    this.$store.dispatch(`${this.storeName}/resetState`)
  },

  methods: {
    eventCollapseFilter(val) {
      this.filterCollapsed = val
    },

    hookChildMounted(name) {
      this.mountedStores[name] = true
      if (Object.values(this.mountedStores).every((i) => i)) {
        this.$nextTick(() => {
          this.$elContainer.$emit(
            this.$channels.navigationItemReady,
            {
              item: this.config.name,
              tab: this.config.tab
            }
          )
        })
      }
    },

    eventTableComplete() {
      this.$elContainer.$emit(this.$channels.navigationItemFinish)
    },

    dynamicComponent(type) {
      return new Promise((resolve) => {
        try {
          const file = `${[this.config.namespace, this.config.component, this.config.tab, this.config.name, type].filter((i) => !!i).join('/')}.vue`
          const module = require(`@/views/crud/${file}`)
          resolve(module)
        } catch (e) {
          resolve(require(`./../../view/components/${type}.vue`))
        }
      })
    },

    _fieldOptions(field, type, value, fieldset) {
      const fieldName = fieldset && fieldset.prop ? fieldset.prop : field.name
      const key = `${type}_${fieldName}`
      if (value) {
        value = (Array.isArray(value) ? value : [value]).filter((i) => i).map((i) => {
          let ii = parseInt(i)
          return ii.toString() === i ? ii : i
        })
      } else {
        value = []
      }

      if (this.fullStores[key] && this.fullStores[key].length > 0) {
        return field.store || this.config.stores[field.storeKey] ? this.storesCache[key] : this._sortOptionsByValue(key, value, field)
      }

      let store = []
      if (field.store) {
        store = field.store instanceof Array ? field.store : Object.keys(field.store).map((i) => { return { id: i, label: field.store[i] } })
      } else if (this.config.stores[field.storeKey]) {
        if (this.config.stores[field.storeKey] instanceof Object) {
          store = Object.keys(this.config.stores[field.storeKey]).map((i) => { return { id: i, label: this.config.stores[field.storeKey][i] } })
        } else {
          store = this.config.stores[field.storeKey]
        }
      } else if (this.$store.getters[`${this.storeName}/preload`][field.storeKey]) {
        store = this.$store.getters[`${this.storeName}/preload`][field.storeKey]
      } else if (this.$store.getters['bootData'][field.storeKey]) {
        store = this.$store.getters['bootData'][field.storeKey]
      }

      if (field.storePrimaryKey || field.storeLabelKey || field.storeLabelKeys) {
        store = store.reduce((acc, i) => {
          let label = null
          let primary_key = field.storePrimaryKey ? field.storePrimaryKey : 'id'
          if (field.storeLabelKey || field.storeLabelKeys) {
            label = field.storeLabelKey ? i[field.storeLabelKey] : field.storeLabelKeys.map((k) => i[k]).join(': ')
          } else {
            label = i['label']
          }
          let colorTag = i.colorTag
          acc.push({ id: i[primary_key], label, colorTag })
          return acc
        }, [])
      }

      this.fullStores[key] = store
      this.storesCache[key] = this.fullStores[key]
      return this._sortOptionsByValue(key, value, field)
    },

    _remoteMethod(query, field, type, value, fieldset) {
      const fieldName = fieldset && fieldset.prop ? fieldset.prop : field.name
      const key = `${type}_${fieldName}`
      let tempCache = this.fullStores[key]
      if (query !== '') {
        tempCache = this.fullStores[key].filter(item => {
          return item.label.toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        })
      }

      this.storesCache[key] = tempCache.slice(0, 10)
      this.storesCache = { ...this.storesCache }
      return this.storesCache[key]
    },

    _sortOptionsByValue(key, value, field) {
      let optionsLimit = 10
      const valueHash = value.join('_')

      if (valueHash && this.storeValueCache[key] !== valueHash) {
        const tempCache = this.fullStores[key].sort((a, b) => {
          const index1 = value.indexOf(a.id)
          const index2 = value.indexOf(b.id)
          return ((index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity))
        })
        optionsLimit = Math.max(optionsLimit, value.length)
        this.storesCache[key] = tempCache
        this.storeValueCache[key] = valueHash
      }

      return field.lazy ? this.storesCache[key].slice(0, optionsLimit) : this.storesCache[key]
    },

    filterOperatorOptions(field) {
      const operators = field.operators ? this.defaultOperators.filter((i) => field.operators.includes(i)) : this.defaultOperators
      return operators.reduce((acc, key) => {
        acc[key] = key
        return acc
      }, {})
    },

    _resetStoreCache() {
      this.storesCache = {}
      this.fullStores = {}
      this.storeValueCache = {}
    },

    _preparePreloadStores() {
      if (this.config.preloadStores.length === 0) {
        return
      }
      const preload = this.$store.getters[`${this.storeName}/preload`]
      if (!preload) {
        return
      }

      for (let i of this.config.preloadStores) {
        if (!Array.isArray(preload[i.name])) {
          continue
        }

        const store = {}
        for(let o of preload[i.name]) {
          store[o[i.storePrimaryKey]] = o
        }
        this.preloadStoreCache[i.name] = Object.freeze(store)
      }
    },

    async reloadDependentTabs() {
      for (let i = 0; i < this.config.dependentItems.length; i++) {
        const itm = this.config.dependentItems[i]
        const name = this.storeNameFor(itm)
        await this.$store.dispatch(`${name}/setPreload`, false)
      }
    }
  }
}
