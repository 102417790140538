<style scoped>
  .row{ padding-right: 10px }
  .row-small{ padding-left: 10px }
  .sbf-fieldset-0{ border: 1px solid #9b0101 }
  .sbf-fieldset-1{ border: 1px solid #062876; margin-top: 20px }
  .sbf-fieldset-fares{ border: 1px solid #f556ff; margin-top: 20px }
  .sbf-fl-bt-block{ padding: 10px 0 0 15px; }
  .d-button{ padding: 3px 0 0 10px; }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='4' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='80px')
        el-col(:span='10' class='row')
          ttn-input(v-model="temp['name']" v-bind="attr('name')" label-width='80px')
        el-col(:span='8' class='row')
          ttn-select(v-model="temp['settingsType']" v-bind="elAttr('settingsType')" label-width='80px')

      el-divider

      el-row(v-if="temp['options_decoded']")
        ttn-input(v-model="temp['options_decoded']['validating_supplier']" v-bind="elAttr('options__validatingSupplier', `options_decoded.validating_supplier`)" label-width='90px' :rules="required")
      el-row(v-if="temp['options_decoded']")
        ttn-input(v-model="temp['options_decoded']['contract_id']" v-bind="elAttr('options__contractId', `options_decoded.contract_id`)" label-width='90px' :rules="required")
      //- Check PNR worker
      fieldset(:class="`el-form-fieldset sbf-fieldset-0`" v-if="temp['settingsType'] == '1' && temp['options_decoded']")
        legend {{ translate('form.fieldsets.schedule') }}
        el-row
          el-col(:span='5' class='row')
            el-button(type="primary" plain size="mini" icon="el-icon-plus" @click="actionAddSchedule()") Add
        div(v-for="(row, i) in temp['options_decoded']['schedule']")
          el-row
            el-col(:span='4' class='row')
              ttn-input(v-model="temp['options_decoded']['schedule'][i]['index']" v-bind="elAttr('options__schedule__index', `options_decoded.schedule.${i}.index`)" label-width='60px' :rules="required")
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['options_decoded']['schedule'][i]['departure_in']" v-bind="elAttr('options__schedule__departureIn', `options_decoded.schedule.${i}.departure_in`)" label-width='100px' :rules="required")
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['options_decoded']['schedule'][i]['seats_range']" v-bind="elAttr('options__schedule__seatsRange', `options_decoded.schedule.${i}.seats_range`)" label-width='100px' :rules="required")
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['options_decoded']['schedule'][i]['time']" v-bind="elAttr('options__schedule__time', `options_decoded.schedule.${i}.time`)" label-width='60px' :rules="required")
            el-col(:span='3' class='row d-button')
              el-button(type="danger" plain size="mini" icon="el-icon-minus" @click="actionDeleteSchedule(i)") Delete
          el-row
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['options_decoded']['schedule'][i]['min_to_departure']" v-bind="elAttr('options__schedule__minToDeparture', `options_decoded.schedule.${i}.min_to_departure`)" label-width='90px' :rules="required")
            el-col(:span='10' class='row')
              ttn-input(v-model="temp['options_decoded']['schedule'][i]['routes']" v-bind="elAttr('options__schedule__routes', `options_decoded.schedule.${i}.routes`)" label-width='60px')
            el-col(:span='9' class='row')
              ttn-input(v-model="temp['options_decoded']['schedule'][i]['flights']" v-bind="elAttr('options__schedule__flights', `options_decoded.schedule.${i}.flights`)" label-width='60px')
          hr
      //- Pricer worker
      fieldset(:class="`el-form-fieldset sbf-fieldset-0`" v-if="temp['settingsType'] == '2' && temp['options_decoded']")
        legend {{ translate('form.fieldsets.pricerWorkerFilters') }}
        el-row
          el-col(:span='8' class='row')
            ttn-select(v-model="temp['options_decoded']['depot_id']" v-bind="elAttr('options__depotId', `options_decoded.depot_id`)" label-width='80px' :rules="required")
          el-col(:span='6' class='row')
            ttn-select(v-model="temp['options_decoded']['remained_seats_operator']" v-bind="elAttr('options__remainedSeatsOperator', `options_decoded.remained_seats_operator`)" label-width='120px' :rules="required")
          el-col(:span='3' class='row')
            ttn-input(v-model="temp['options_decoded']['remained_seats']" v-bind="elAttr('options__remainedSeats', `options_decoded.remained_seats`)" label-width='0px' :rules="required")
          el-col(:span='7' class='row')
            ttn-input(v-model="temp['options_decoded']['departure_in']" v-bind="elAttr('options__departureIn', `options_decoded.departure_in`)" label-width='80px' :rules="required")
        el-row
          el-col(:span='7' class='row')
            ttn-radio-group(v-model="temp['options_decoded']['active']" v-bind="elAttr('options__active', `options_decoded.active`)" label-width='80px' :rules="required")
          el-col(:span='10' class='row')
            ttn-date-picker(v-model="temp['options_decoded']['departure_date']" v-bind="elAttr('options__departureDate', `options_decoded.departure_date`)" label-width='80px')
        hr
        el-row
          el-col(:span='8' class='row')
            ttn-input(v-model="temp['options_decoded']['schedules']" v-bind="elAttr('options__schedules', `options_decoded.schedules`)" label-width='80px' :rules="required")
          el-col(:span='6' class='row')
            ttn-input(v-model="temp['options_decoded']['scheme_id']" v-bind="elAttr('options__schemeId', `options_decoded.scheme_id`)" label-width='100px' :rules="required")
          el-col(:span='6' class='row')
            ttn-input(v-model="temp['options_decoded']['system_id']" v-bind="elAttr('options__systemId', `options_decoded.system_id`)" label-width='100px' :rules="required")

</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    computed:{
      required(){
        const message = this.translate('form.required')
        const required = true
        return [{ required, message, trigger: 'change' }]
      }
    },
    extends: BaseForm,
    methods: {
      elAttr(fieldName, prop){
        return this.attr(fieldName, Object.assign({}, this.fieldReference[fieldName], {prop: prop}))
      },
      formatTemp() {
        let options = {}

        if(this.temp['options_decoded']){
          for(let i in this.temp['options_decoded']){
            switch(i){
              case 'schedule':
                options['schedule'] = this.temp['options_decoded']['schedule'].sort((a,b) => a['index'] - b['index']).reduce((res, curr) =>
                  {
                    res.push(Object.keys(curr).reduce((o, k) => { o[k] = this.formatField(curr[k], this.fieldReference[`options__schedule__${this._.camelCase(k)}`]); return o}, {}))
                    return res
                  },
                  []
                )
                break;
              case 'last_perform_time':
                options[i] = this.temp['options_decoded'][i]
                break
              default:
                options[i] = this.formatField(this.temp['options_decoded'][i], this.fieldReference[`options__${this._.camelCase(i)}`])
            }
          }
        }

        this.temp['options'] = JSON.stringify(options)

        return this.temp
      },
      async actualizeCallback(temp){
        let newTemp = {}

        for(let i = 0; i < this.fields.length; i++) {
          let field = this.fields[i]

          if(field.name.indexOf('options__') !== -1) {
            continue
          } else {
            newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
          }
        }

        if(temp['options']){
          newTemp['options_decoded'] = this.defaultOptions()
          let options = JSON.parse(temp['options'])

          for(let i in options){
            switch(i){
              case 'schedule':
                options['schedule'].forEach((curr) =>
                  newTemp['options_decoded']['schedule'].push(Object.keys(curr).reduce((o, k) => { o[k] = this.formatFieldReverse(curr[k], this.fieldReference[`options__schedule__${this._.camelCase(k)}`]); return o}, {}))
                )
                break
              case 'last_perform_time':
                newTemp['options_decoded'][i] = options[i]
                break
              default:
                newTemp['options_decoded'][i] = this.formatFieldReverse(options[i], this.fieldReference[`options__${this._.camelCase(i)}`])
            }
          }
        }

        console.log(newTemp)

        return newTemp
      },
      defaultOptions(){ return { schedule: [] } },
      defaultscheduleRow(){ return {departure_in: '*-*', time: '', seats_range: '1-*', index: 1, routes: '', flights: '', min_to_departure: 0} },
      beforeCreateCallback(){
        this.temp = Object.assign(
          {},
          this.temp,
          {options_decoded: this.defaultOptions()}
        )
        this.actionAddSchedule()
      },
      actionAddSchedule(){ this.temp.options_decoded.schedule.push(this.defaultscheduleRow()) },
      actionDeleteSchedule(index){ this.temp.options_decoded.schedule.splice(index, 1) }
    }
  }
</script>
