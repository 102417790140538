<template lang="pug">
  div
    el-row      
      el-table(:stripe="true", :data="rules")
        el-table-column(label="ID")
          template(slot-scope="scp")
            span {{ scp.row["rule"]["id"] }}
        el-table-column(label="GDS")
          template(slot-scope="scp")
            span {{ scp.row["rule"]["gds_id"] }}
        el-table-column(label="BSP")
          template(slot-scope="scp")
            span {{ scp.row["rule"]["bsp"] }}
        el-table-column(label="Supplier")
          template(slot-scope="scp")
            span {{ scp.row["rule"]["supplier"] }}
        el-table-column(label="Marketing")
          template(slot-scope="scp")
            span {{ scp.row["rule"]["marketing_supplier"] }}
        el-table-column(label="Value")
          template(slot-scope="scp")
            span {{ scp.row["rule"]["amount"] }}&nbsp;
            span {{ scp.row["rule"]["currency"] }}
        el-table-column(label="Type")
          template(slot-scope="scp")
            span {{ scp.row["rule"]["cost_type"] == 1 ? 'By Segment' : 'By Pax' }}
        el-table-column(label="Calculation")
          el-table-column(label="Paxes")
            template(slot-scope="scp")
              span {{ scp.row["pax"] }}
          el-table-column(label="Segment")
            el-table-column(label="Suppliers")
              template(slot-scope="scp")
                span {{ scp.row["supplier"] }}
            el-table-column(label="Count")
              template(slot-scope="scp")
                span {{ scp.row["times"] }}
          el-table-column(label="Amount")
            template(slot-scope="scp")
              span {{ scp.row["amount"] }}&nbsp;
              span {{ scp.row["currency"] }}

</template>

<script>
export default {
  name: 'SegmentCost',
  props: {
    rules: Array
  },

  data() {
    return {
    }
  },

  methods: {
  }
}

</script>
