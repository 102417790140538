import components from './components'

export default {
  paxport_worker: {
    table: {
      ...components.reportsTable
    },
    form: {
      fields: {
       ...components.reportsFormFields
      },
      options: {
        ...components.reportsFormOptions
      },
    },
    action: {
      ...components.reportsActions
    },
    messages: {
      ...components.reportsMessages
    },
    changeSettingsWindow: components.changeSettingsWindow
  },
  paxport_settings: {
    table: {
      ...components.settingsTable
    },
    form: {
      fields: {
        ...components.settingsFormFields,
        options__schedule__daysRange: 'Days range',
        options__schedule__value: 'Refresh in',
        options__schedule__ttl: 'Not older then (m)',
        options__routes: 'Routes',
        options__airlines: 'Airlines'
      },
      placeholder: {
        options__schedule__ttl: '60',
        options__schedule__daysRange: '0-30',
        options__schedule__value: '1h or 2hh,6hh,12hh,18hh',
        options__routes: 'IEV-LON,BER;LWO-WAW,BCN',
        options__airlines: 'PS,U2'
      }
    },
    fieldset: {
      schedule: 'Schedule'
    },
    action: {
      ...components.reportsActions
    },
    changeSettingsWindow: components.changeSettingsWindow
  }
}
