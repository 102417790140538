<template lang="pug">
  extends /table
  block expand
    el-row
      el-col(:span="12")
        el-card(class="box-card")
          div(slot="header") Data
          vue-json-pretty(path="res" :data="scope.row.data")
      el-col(:span="12")
        el-card(class="box-card")
          div(slot="header") Schema
          vue-json-pretty(path="res" :data="scope.row.schema")
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(column.columnKey) {
        case 'message':
          return { color: '#bd0b0b' }
        case 'fragment':
          return { fontWeight: 'bold' }
        default:
          return {}
      }
    }
  }
}
</script>
