<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:name="scope")
      div(class="name" @click="showBookingInQueue(scope.row)") 
        span.link {{ scope.row.name }}
        i(v-if="scope.row.isSystem" style="color:red" class="el-icon-star-on el-icon--right")
      div
        i(class="el-icon-s-data")
        span &nbsp;{{ scope.row.inQueue.result.length }}
        span.debug &nbsp;{{ scope.row.inQueue.query_time.toPrecision(2) }}
      div
        i(class="el-icon-time")
        span &nbsp;{{ scope.row.shift }}
    template(v-for="day in days" v-slot:[day]="scope")
      div(v-html="formatDayCell(scope.row, day)")
    template(v-slot:createdAt="scope")
      div {{ scope.row['createdAt'] }}
      div {{ scope.row['updatedAt'] }}
  block custom
    el-drawer(
      :title="drawer.title"
      :visible.sync="drawer.visible"
      direction="ttb")
      span {{ drawer.inQueue }}
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  data() {
    return {
      days: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
      filterProps: ['depot', 'pc', 'status', 'depot_type', 'driver', 'backlog'],
      statusDescriptions: { 
        'PN': { name: 'Payed. Not issued' },
        'PC': { name: 'Cancelled. Payed' },
        'NP': { name: 'Pay not confirm' }
      },
      drawer: {
        visible: false,
        title: '',
        inQueue: []
      }
    }
  },

  mounted () {
    this.depots = this.$store.getters['bootData']['depots_hash']
  },

  methods: {
    showBookingInQueue(row) {
      this.drawer.visible = true
      this.drawer.title = row.name
      this.drawer.inQueue = row.inQueue.result.length > 0 ? row.inQueue.result : 'Empty list'
    },

    wrapStatus(active) {
      return `<span class='${active ? "green" : "red" }'>${active ? "Yes" : "No"}</span>`
    },

    formatDayCell(row, day) {
      let result = []
      let settings = row['settings'].find((x) => x.day == day)

      if (settings && settings.filters) {
        let filterFullResult = []
        settings.filters.forEach((filters) => {
          let filterResult = []
          this.filterProps.forEach((prop) => {
            if (filters[prop] === undefined) return
            if (String(filters[prop]).length == 0) return
            if (row.isSystem) return

            switch(prop) {
              case 'status':
              case 'depot':
                const dict = prop == 'status' ? this.statusDescriptions : this.depots
                const except = prop == 'depot' ? filters.depot_except : false
                const dispayStr = filters[prop].count == 0 ? 'All' : filters[prop].map(id => dict[id].name )
                const label = except ? `${this._.upperFirst(prop)} not` : `${this._.upperFirst(prop)} is`

                if (dispayStr == 'All' || dispayStr.length == 1) {
                  filterResult.push(`[${label}]: ${dispayStr}`)
                } else {
                  filterResult.push(`[${label}]: <br/>${dispayStr.join('<br/>')}`)
                }
                break;
              
              case 'depot_type':
                filterResult.push(`[Depot type]: ${filters[prop]}`)
                break;

              case 'pc':
                if (filters[prop]) {
                  filterResult.push(`[PC]: ${filters[prop]}`)
                }
                break;

              case 'driver':
                const driverLabel = filters.driver_except ? `${this._.upperFirst(prop)} not` : `${this._.upperFirst(prop)} is`
                filterResult.push(`[${driverLabel}]: ${filters[prop]}`)
                break;

              case 'backlog':
                if (filters[prop]) {
                  filterResult.push(`[Backlog]: ${this.wrapStatus(filters[prop])}`)
                }
                break;
            }
          })
          filterFullResult.push(filterResult.join('<br/>'))
        })

        result.push(filterFullResult.join('<div align="center"><b>AND</b></div>'))
      }

      return result.join('<br/>')
    },

    _className(row, column, rowIndex, columnIndex) {
      if ( column.label == this.$moment().format('dddd') ) {
        return 'today'
      } else if ( column.label == 'Saturday' || column.label == 'Sunday' ) {
        return 'weekend'
      } else {
        return ''
      }
    },

    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      return this._className(row, column, rowIndex, columnIndex)
    },

    cellClassName({ row, column, rowIndex, columnIndex }) {
      return this._className(row, column, rowIndex, columnIndex)
    },

    isToday(settingDay) {
      return settingDay == this._.lowerCase(this.$moment().format('dd'))
    },

    isDisabledAction(action, row) {
      if (action.name !== 'update') {
        return false
      }
      return row.isSystem
    },

    isSelectable(row, index) {
      return !row.isSystem
    }
  }
}
</script>

<style lang="scss">
  .operator-schedule-table {
    .red { color: red }
    .green { color: green }
    th.weekend, td.weekend { background-color: #fffaf1 !important }
    .bold { font-weight: bold }
    th.today, td.today { background-color: #f1fff6 !important }
    span.name { cursor: pointer }
    .el-drawer__body { padding: 0 25px }
    .link { color: #409eff; text-decoration: underline dotted; cursor: pointer }
    .debug { font-size: 7px; color: grey }
  }
</style>