<style scoped lang="scss">
  .operator-schedule-form { margin-top: 10px; }
  .operator-schedule-form {
    .weekend { background-color: #fffaf1 }
    .today { background-color: #f1fff6 }
    .day-cell { width: 125px; }
    .pc-cell { width: 100px }
    .status-cell { width: 200px }
    .backlog-cell { width: 75px }
    .depot-type-cell { width: 125px }
    .bold { font-weight: bold }
    .head { font-weight: bold }
    .custom-table--cell { padding: 10px }
    .date { color: grey; font-size: 10px }
    .actions { width: 100px }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>

<template lang="pug">
  extends /form
  block fields
    div(class="operator-schedule-form")
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      ttn-input(v-model="temp['name']" v-bind="attr('name')")
      ttn-select(v-model="temp['shift']" v-bind="attr('shift')")
      ttn-checkbox(v-model="temp['active']" v-bind="attr('active')")

      custom-table
        slot(v-for="(setting, dayIndex) in temp['settings_decoded']")
          custom-row(class="head" v-if="dayIndex == 0")
            custom-cell(class="day-cell")
            custom-cell Depot
            custom-cell Driver
            custom-cell(class="status-cell") Status
            custom-cell(class="backlog-cell") Backlog
            custom-cell(class="pc-cell") PC
            custom-cell(class="depot-type-cell") Depot type
            custom-cell(class="actions")
          custom-row(v-for="(filters, filterIndex) in temp['settings_decoded'][dayIndex]['filters']" :key="[dayIndex, filterIndex].join('__')" :class="{ weekend: dayIndex > 4, today: isToday(setting['day']) }")
            custom-cell
              div(v-if="filterIndex == 0")
                span.bold {{ translate(`table.${setting.day}`) }}
                div.date {{ dateBy(dayIndex) }}
            custom-cell
              el-row
                el-col(:span='1')
                  span(style="line-height: 36px") Ex.
                el-col(:span='1')
                  ttn-checkbox(
                    :form-item-wraper="false"
                    v-model="filters['depot_except']"
                    v-bind="elAttr('settings__depotExcept', `settings_decoded.${dayIndex}.filters.${filterIndex}.depot_except`, dayIndex, filterIndex)"
                    style="line-height: 36px"
                  )
                el-col(:span='22')
                  ttn-multiselect(
                    :form-item-wraper="false"
                    v-model="filters['depot']"
                    v-bind="elAttr(`settings__depot`, `settings_decoded.${dayIndex}.filters.${filterIndex}.depot`, dayIndex, filterIndex)"
                    style="width: 90%"
                  )
            custom-cell
              el-row
                el-col(:span='1')
                  span(style="line-height: 36px") Ex.
                el-col(:span='1')
                  ttn-checkbox(
                    :form-item-wraper="false"
                    v-model="filters['driver_except']"
                    v-bind="elAttr('settings__driverExcept', `settings_decoded.${dayIndex}.filters.${filterIndex}.driver_except`, dayIndex, filterIndex)"
                    style="line-height: 36px"
                  )
                el-col(:span='22')
                  ttn-multiselect(
                    :form-item-wraper="false"
                    v-model="filters['driver']"
                    v-bind="elAttr(`settings__driver`, `settings_decoded.${dayIndex}.filters.${filterIndex}.driver`, dayIndex, filterIndex)"
                    style="width: 90%"
                )
            custom-cell
              ttn-multiselect(
                :form-item-wraper="false"
                v-model="filters['status']"
                v-bind="elAttr('settings__status', `settings_decoded.${dayIndex}.filters.${filterIndex}.status`, dayIndex, filterIndex)"
                style="width: 90%"
              )
            custom-cell
              ttn-checkbox(
                :form-item-wraper="false"
                v-model="filters['backlog']"
                v-bind="elAttr('settings__backlog', `settings_decoded.${dayIndex}.filters.${filterIndex}.backlog`, dayIndex, filterIndex)"
              )
            custom-cell
              ttn-input(
                :form-item-wraper="false"
                v-model="filters['pc']"
                v-bind="elAttr('settings__pc', `settings_decoded.${dayIndex}.filters.${filterIndex}.pc`, dayIndex, filterIndex)"
                :min="0"
              )
            custom-cell
              ttn-select(
                :form-item-wraper="false"
                v-model="filters['depot_type']"
                v-bind="elAttr('settings__depotType', `settings_decoded.${dayIndex}.filters.${filterIndex}.depot_type`, dayIndex, filterIndex)"
              )
            custom-cell
              el-dropdown(trigger="click" @command="handleCommand")
                span(class="el-dropdown-link") Actions
                  i(class="el-icon-arrow-down el-icon--right")
                el-dropdown-menu(slot="dropdown")
                  el-dropdown-item(
                    icon="el-icon-plus" 
                    :command="{params: [dayIndex], action: 'addFilterRule', message: 'Add new rule - Ok'}"
                  ) Add
                  el-dropdown-item(
                    icon="el-icon-delete" 
                    :disabled="filterIndex == 0"
                    :command="{params: [dayIndex, filterIndex], action: 'removeFilterRule', message: 'Delete rule - Ok'}"
                  ) Delete
                  el-dropdown-item(
                    icon="el-icon-copy-document" 
                    :disabled="!!copyBuffer"
                    :command="{params: [dayIndex, filterIndex], action: 'copyFilterRule', message: 'Copy rule - Ok'}"
                  ) Copy
                  el-dropdown-item(
                    icon="el-icon-receiving" 
                    :disabled="!copyBuffer"
                    :command="{params: [dayIndex, filterIndex], action: 'pasteFilterRule', message: 'Paste rule - Ok'}"
                  ) Paste
                  el-dropdown-item(
                    icon="el-icon-refresh-left" 
                    :command="{params: [dayIndex, filterIndex], action: 'clearFilterRule', message: 'Clear rule - Ok'}"
                  ) Clear
</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,

    data() {
      return {
        days: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
        reverses: {},
        copyBuffer: false
      }
    },

    methods: {
      elAttr(fieldName, prop, dayIndex, filterIndex){
        return this.attr(
          fieldName, 
          Object.assign(
            {}, 
            this.fieldReference[fieldName]
          ), 
          { 
            dayIndex: dayIndex,
            prop: prop,
            filterIndex: filterIndex
          }
        )
      },

      formatTemp() {
        if(this.temp['settings_decoded']){
          for(let i in this.temp['settings_decoded']){
            for(let x in this.temp['settings_decoded'][i]['filters']){
              for(let filter in this.defaultFilterRule()){
                this.temp['settings_decoded'][i]['filters'][x][filter] = this.formatField(this.temp['settings_decoded'][i]['filters'][x][filter], this.fieldReference[`settings__${this._.camelCase(filter)}`])
              }
            }
          }
          this.temp['settings'] = JSON.stringify(this.temp['settings_decoded'])
        }

        return this.temp
      },

      beforeCreateCallback() {
        this.temp = Object.assign({}, this.temp, {settings_decoded: this.defaultItem()})
      },

      defaultItem() {
        let item = []
        this.days.forEach((day) => {
          item.push({ day: day, filters: [this.defaultFilterRule()] })
        })
        return item
      },

      defaultFilterRule() {
        return { depot: [], status: [], driver: [], depot_type: 'ALL', pc: null, depot_except: false, driver_except: false, backlog: false }
      },

      isToday(settingDay) {
        return settingDay == this._.lowerCase(this.$moment().format('dd'))
      },

      dateBy(dayIndex) {
        return this.$moment().startOf('isoWeek').add(dayIndex, 'days').format('YYYY-MM-DD')
      },

      async actualizeCallback(temp) {
        let newTemp = {}

        for(let i = 0; i < this.fields.length; i++) {
          let field = this.fields[i]

          if(field.name.indexOf('settings__') !== -1) {
            continue
          } else {
            newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
          }
        }

        newTemp['settings_decoded'] = []
        if (temp['settings']) {
          this.days.forEach((day, index) => {
            let daySetting = temp['settings'].find((x) => x.day == day )
            daySetting ||= { day: day, active: false }

            let newSetting = { ...daySetting, filters: daySetting['filters'] }
            newTemp['settings_decoded'].push(newSetting)
          })
        }

        return newTemp
      },

      reverseField(field, fieldset) {
        this.reverses[fieldset.dayIndex] ||= {}
        this.reverses[fieldset.dayIndex][fieldset.filterIndex] = !this.reverses[fieldset.dayIndex][fieldset.filterIndex]

        let filters = this.temp['settings_decoded'][fieldset.dayIndex].filters[fieldset.filterIndex]
        let settingName = field.name.split('__')[1]

        if(this.reverses[fieldset.dayIndex][fieldset.filterIndex]) { // textarea
          field.valueType = 'array_of_integer'
          this.temp['settings_decoded'][fieldset.dayIndex].filters[fieldset.filterIndex][settingName] = this.formatFieldReverse(filters[settingName], field)
        } else {
          field.valueType = 'array_of_integer'
          this.temp['settings_decoded'][fieldset.dayIndex].filters[fieldset.filterIndex][settingName] = this.formatField(filters[settingName], field)
          field.valueType = null
        }
      },

      isReversed(field, fieldset) {
        if (!fieldset) return false
        if (!this.reverses) return false
        if (this.reverses[fieldset.dayIndex] == undefined) return false
        return !!this.reverses[fieldset.dayIndex][fieldset.filterIndex]
      },

      expandSelect(field, fieldset, refs) {
        refs[`reverse_${fieldset.prop}`].collapseTags = !refs[`reverse_${fieldset.prop}`].collapseTags
        refs[`reverse_${fieldset.prop}`].handleResize()
      },

      addFilterRule(dayIndex) {
        this.temp['settings_decoded'][dayIndex]['filters'].push(this.defaultFilterRule())
      },

      removeFilterRule(dayIndex, filterIndex) {
        this.temp['settings_decoded'][dayIndex]['filters'].splice(filterIndex, 1)
      },

      handleCommand(command) {
        this[command.action].apply(this, command.params)
        this.$forceUpdate();
        this.$message(command.message)
      },

      copyFilterRule(dayIndex, filterIndex) {
        this.copyBuffer = this.temp['settings_decoded'][dayIndex]['filters'][filterIndex]
      },

      pasteFilterRule(dayIndex, filterIndex) {
        this.temp['settings_decoded'][dayIndex]['filters'][filterIndex] = { ...this.copyBuffer }
        this.copyBuffer = false
      },

      clearFilterRule(dayIndex, filterIndex) {
        this.temp['settings_decoded'][dayIndex]['filters'][filterIndex] = this.defaultFilterRule()
        this.copyBuffer = false
      }
    }
  }
</script>