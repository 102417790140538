export default {
  namespace: 'railway',
  component: 'rail_agents',
  meta: {
    title: 'railway.rail_agents',
    icon: 'el-icon-service'
  },
  tabs: [
    {
      name: 'rail_agents',
      items: [
        {
          name: 'rail_agents_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayRailAgents',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                email: true,
                fromCode: true,
                toCode: true,
                trainNumber: true,
                date: true,
                luxCount: true,
                compartmentCount: true,
                reservedCount: true,
                sedentaryCount: true,
                generalCount: true,
                softCount: true,
                lastCheck: true,
                book: true,
                done: true,
                doneTime: true,
                depotId: true,
                createdAt: true,
                lang: true,
                bookingId: true,
                cancelBedclothes: true,
                phone: true,
                searchTo: true,
                low: true,
                noSide: true,
                oneCoupe: true,
                maxPrice: true,
                agentType: true,
                status: true,
                userId: true,
                departureDate: true,
                expiresOn: true,
                railAgentDocuments: {
                  id: true,
                  railAgentId: true,
                  adult: true,
                  birthday: true,
                  firstName: true,
                  lastName: true,
                  patronymic: true,
                  userPassengerId: true,
                  documentNumber: true,
                  nationality: true,
                  salutation: true,
                  docType: true
                }
              }
            }
          },
          stores: {
            statuses: {
              0: 'active',
              1: 'wait_for_payment',
              2: 'paid',
              3: 'canceled',
              4: 'inactive'
            },
            agentTypes: {
              0: 'inform',
              1: 'book',
              2: 'pay'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              'email', 'fromCode', 'toCode', 'trainNumber', 'date',
              'luxCount', 'compartmentCount', 'reservedCount', 'sedentaryCount', 'generalCount', 'softCount',
              'lastCheck',
              { name: 'book', type: 'switch' },
              { name: 'done', type: 'switch' }, 'doneTime',
              {
                name: 'depotId',
                format: 'from_boot',
                storeKey: 'depots_hash',
                storeLabelKey: 'name'
              },
              'createdAt', 'lang', 'bookingId',
              { name: 'cancelBedclothes', type: 'switch' }, 'phone', 'searchTo',
              { name: 'low', type: 'switch' }, { name: 'noSide', type: 'switch' },
              { name: 'oneCoupe', type: 'switch' }, 'maxPrice', { name: 'agentType', format: 'from_options' }, { name: 'status', format: 'from_options' }, 'userId', 'departureDate', 'expiresOn',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              },
              {
                name: 'expand'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'depot_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                scope: 'RailAgents'
              },
              {
                name: 'done',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  1: 'yes',
                  0: 'no'
                },
                scope: 'RailAgents'
              },
              {
                name: 'created_at',
                operator: 'btw',
                type: 'datetimerange',
                required: false,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                scope: 'RailAgents'
              },
              {
                name: 'status',
                type: 'select',
                button: true,
                operator: 'eq',
                storeKey: 'statuses',
                scope: 'RailAgents'
              },
              {
                name: 'agent_type',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                storeKey: 'agentTypes',
                scope: 'RailAgents'
              }
            ]
          },
          combined: [
            {
              name: 'rail_agent_documents',
              graphql: {
                name: 'railAgentDocuments'
              },
              type: 'graphql',
              boot: {
                type: 'local',
                row_key: 'railAgentDocuments',
                relation: {
                  railAgentId: 'id'
                }
              },
              table: {
                items: [
                  {
                    name: 'id',
                    width: '80px'
                  },
                  { name: 'adult', type: 'switch' },
                  'birthday',
                  'firstName',
                  'lastName',
                  'patronymic',
                  'userPassengerId',
                  'documentNumber',
                  'nationality',
                  'salutation',
                  'docType'
                ]
              }
            }
          ]
        }
      ]
    }
  ]
}