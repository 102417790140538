import l2bGdsSettings from './l2b/gds_settings'
import l2bStatistics from './l2b/l2b_statistics'
import l2bReports from './l2b/l2b_reports'

export default {
  namespace: 'avia',
  component: 'l2b',
  meta: {
    title: 'avia.l2b',
    icon: 'chart',
    roles: ['pesimisation_rules']
  },
  tabs: [
    l2bStatistics,
    l2bReports,
    l2bGdsSettings
  ]
}
