export default {
  data_flight_item: {
    table: {
      id: 'ID',
      bucketKey: 'Dep. date',
      flightKey: 'Flight key',
      departureDate: 'Departure date',
      departureCity: 'Departure city',
      departureLocation: 'Departure location',
      departureTime: 'Departure time',
      arrivalCity: 'Arrival city',
      arrivalLocation: 'Arrival location',
      arrivalTime: 'Arrival time',
      price: 'Price',
      departure: 'Departure',
      arrival: 'Arrival',
      departureTime: 'Dep. time',
      arrivalTime: 'Arr. time',
      flights: 'Flights',
      validatingSupplier: 'VS',
      officeId: 'OfficeID'
    },
    form: {
      fields: {
        id: 'ID',
        bucketKey: 'Dep. date',
        flightKey: 'Flight key',
        departureDate: 'Departure date',
        departureCity: 'Select only one of the filters below  OR | Departure city',
        departureLocation: 'Departure location',
        departureTime: 'Departure time',
        arrivalCity: 'OR | Arrival city',
        arrivalLocation: 'Arrival location',
        arrivalTime: 'Arrival time',
        route: 'OR | Route',
        price: 'Price'
      },
    }
  }
}
