<template lang="pug">
  el-row(v-if="records && records[0]" v-loading="loading")
    div(v-if="false" @click="loadBookingInfo(38259673)").pointer {{ booking ? '' : 'load' }}

    top-menu(:booking="booking" :reloadBooking="loadBookingInfo", :navigateTab="navigateTab")

    el-container
      el-container(v-if="booking")
        el-container
          el-main.avia-bookings
            el-collapse(v-model="activeAreas")
              el-collapse-item(title="Old price table" name="price-details")
                old-price-details(:booking="booking")

              el-collapse-item(title="Pricer" name="pricer" )
                pricer-details(:booking="booking")

              el-collapse-item(title="Buyer info" name="buyer-details")
                buyer-details(:booking="booking")

              el-collapse-item(title="Passengers info" name="passenger-details")
                passenger-details(:booking="booking" :reloadBooking="loadBookingInfo")

              el-collapse-item(title="Upsale" name="upsale")
                upsale-details(:booking="booking")

              el-collapse-item(title="Insuarance" name="insurance-details")
                insurance-details(:booking="booking" :reloadBooking="loadBookingInfo")

              el-collapse-item(title="Ancillary" name="ancillary-details")
                ancillary-details(:booking="booking" :reloadBooking="loadBookingInfo")

              el-collapse-item(title="Price components" name="price-components")
                price-component-details(:booking="booking")

              el-collapse-item(title="Segments" name="trip-location-details" )
                trip-location-details(:booking="booking" :reloadBooking="loadBookingInfo")

        el-aside
          side-menu(:detailedInformation="booking.detailedInformation")
</template>

<script>
import BookingStruct from '@/settings/modules/avia/general/booking_struct'
import BaseTable from '@crud_view/table'

import SideMenu from './side_menu'
import TopMenu from './top_menu'
import OldPriceDetails from './booking/old_price_details'
import PricerDetails from './booking/pricer_details'
import PriceComponentDetails from './booking/price_component_details'
import TripLocationDetails from './booking/trip_location_details'
import PassengerDetails from './booking/passenger_details'
import AncillaryDetails from './booking/ancillary_details'
import InsuranceDetails from './booking/insurance_details'
import UpsaleDetails from './booking/upsale_details'
import BuyerDetails from './booking/buyer_details'

export default {
  components: {
    SideMenu,
    OldPriceDetails,
    PricerDetails,
    TopMenu,
    PriceComponentDetails,
    TripLocationDetails,
    PassengerDetails,
    AncillaryDetails,
    InsuranceDetails,
    UpsaleDetails,
    BuyerDetails
  },
  extends: BaseTable,
  data() {
    return {
      loading: false,
      activeAreas: ['upsale', 'ancillary-details', 'price-details', 'passenger-details', 'trip-location-details', 'price-components', 'buyer-details']
    }
  },

  computed: {
    booking: {
      get() { return this.records[0] },
      set(value) { return value }
    }
  },

  methods: {
    async loadBookingInfo(id) {
      this.loading = true
      try {
        const query = {
          collection: { ...BookingStruct.FullStruct },
          __args: {
            limit: 25,
            page: 1,
            filters: {
              bookings: [
                { field: 'id', operator: 'eq', value: id }
              ]
            }
          }
        }

        let result = await this.graphql('aviaBookingItemDetails', query, 'query')

        this.booking = result.collection[0]
        this.loading = false
        return true
      } catch (e) {
        this.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
  .avia-bookings {
    .header-icon { margin-left: 5px; }
    .hidden { display: none }

    .el-collapse-item__header {
      background-color: #dcc8fa;
      border-right: 1px solid #e6ebf5;
      border-left: 1px solid #e6ebf5;
      padding-left: 25px;
      height: 30px;
    }

    .inactive-price-component .el-collapse-item__header {
      background-color: #f4f4f5;
      border-right: 1px solid #e6ebf5;
      border-left: 1px solid #e6ebf5;
      height: 30px;
    }

    .inactive-price-component .el-collapse-item__wrap {
      border-bottom: none;
    }
  }
</style>