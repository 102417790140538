export default {
  legend: 'flight_data',
  translateLegend: true,
  items: [
    {
      name: 'carrier',
      type: 'text',
      valueType: 'string',
      required: false
    },
    {
      name: 'flight',
      type: 'text',
      valueType: 'string',
      required: false
    }
  ]
}