<template lang="pug">
  extends /table
  block expand
    div(v-html="(scope.row.generateOptions)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    actionDownload(row) {
      if(row['filePath'] == '') {
        this.$message({
          message: this.translate('messages.download_error'),
          type: 'error'
        })
      } else{
        this.startDownload(row)
      }
    },
    async startDownload(row) {
      try {
        const query = {
          content: true,
          __args: {
            key: row['filePath']
          }
        }

        let result = await this.graphql('aviaSeatsBlockGeneratorFileContent', query, 'collection')

        if(result.content) {
          const blob = new Blob([result.content], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = row['filePath']
          link.click()
          URL.revokeObjectURL(link.href)
        } else{
          this.$message({
            message: this.translate('messages.download_empty_content'),
            type: 'error'
          })
        }

        return true
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 5:
          switch(row['handlerStatus']) {
            case 0:
              return { 'background-color': '#ced0dc6b' }
            case 1:
              return { 'background-color': '#2cf3606b' }
            case 2:
              return { 'background-color': '#f9d93a7a' }
            case 3:
              return { 'background-color': '#f93a3a7a' }
          }
      }

      return {}
    },
    formatCell({row, $column, column, cellValue, index}) {
      switch(column.name) {
        case 'description':
          switch(row['type']){
            case 0:
              return `${row['generateOptions']['departureLocation']}-${row['generateOptions']['arrivalLocation']} ${row['generateOptions']['startDate']} - ${row['generateOptions']['endDate']}`
            default:
              return '-'
          }
          // return `${cellValue}\n${row['locator']}`
        default:
          return cellValue
      }
    },
    // formatExpandedRow(row) {
    //   return `<b>Period:</b>  ${row['searchOptions']['startDepartureDate']} - ${row['searchOptions']['endDepartureDate']}<br/>
    //   <b>Validating suppliers:</b>  ${row['searchOptions']['validatingSupplier'].join(', ')} <br/>
    //   <b>Offices:</b>  ${row['searchOptions']['officeId'].join(', ')} <br/>
    //   <b>Contract:</b>  ${row['searchOptions']['contractId']} <br/>
    //   <b>Route:</b>  ${row['searchOptions']['routeKey']} <br/>
    //   `
    // }
  }
}
</script>
