<template lang="pug">
  extends /table
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    preloadValue() {
      return this.$store.getters["insurance/settings/packets/packets_list/preload"]
    },
    preloadRisks() {
      return this.preloadValue().risks
    },
    formatCell({row, $column, column, cellValue, index}) {
      if (column.name == 'risks') {
        const risks = this.preloadRisks().filter(function(r) { return r.id == row.id });
        return risks.length == 0 ? [] : risks.map(({id, name}) => (name))
      }
      return cellValue
    }
  }
}
</script>
