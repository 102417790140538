<template lang="pug">
  extends /table
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,

  methods: {
    isSelectable(row, index) {
      return row.status === 2
    },

    actionMarkAsSuccess() {
      this.$confirm(this.translate('alert.mark_success_confirm'), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        try {
          const query = {
            __args: {
              ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
            }
          }

          await this.graphql('mcoGdsQueueManagerLocatorMarkSuccess', query, 'mutation')
          this.$message({
            message: 'Success!',
            type: 'success'
          })
          this.$elItem.$emitAction('refresh')
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }).catch((e) => {})
    },

    actionRequeue() {
      this.$confirm(this.translate('alert.requeue_confirm'), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        try {
          const query = {
            __args: {
              ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
            }
          }

          await this.graphql('mcoGdsQueueManagerLocatorRequeue', query, 'mutation')
          this.$message({
            message: 'Success!',
            type: 'success'
          })
          this.$elItem.$emitAction('refresh')
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }).catch((e) => {})
    },

    rowClassName({ row, rowIndex }) {
      switch(row.status) {
        case 2:
          return 'danger-row'
        case 3:
          return row.error !== null ? 'warning-row' : 'success-row'
        default:
          return ''
      }
    },

    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property !== 'status') {
        return {}
      }
      switch(row.status) {
        case 0:
          return { color: 'black' }
        case 1:
          return { color: 'blue' }
        case 2:
          return { color: 'red' }
        case 3:
          return { color: 'green' }
        default:
          return {}
      }
    },

    formatTime(value) {
      return this.$moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
    },

    formatCell({ row, $column, column, cellValue, index }) {
      if (column.name == 'office') {
        return cellValue || row.aviaConfigId
      }
      return cellValue
    }
  }
}
</script>
