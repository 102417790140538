<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    v-jsoneditor(v-model="dataValue" v-bind="attribs(field, fieldset)" ref="jsoneditor" v-on="$listeners")
</template>

<script>

import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnJsoneditor',
  mixins: [formElements],
  watch: {
    dataValue() {
      this.$refs['jsoneditor'].editor.refresh()
    }
  },
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        plus: true,
        options: field.options || {}
      }
    }
  }
}
</script>
