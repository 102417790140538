<template lang="pug">
  div(:style="{ height: '500px', width: '100%' }")
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons')
import BaseTable from '@crud_view/table'

export default {
  props: {
    data: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')

      let source = this.data.map((rec) => {
        return { 
          name: rec.queueName,
          queue: rec.details.in_queue.cnt,
          issued: rec.details.issued.cnt,
          queueIds: rec.details.in_queue.locators,
          issuedIds: rec.details.issued.locators
        }
      })

      this.chart.setOption({
        dataset: {
          dimensions: ['name', 'queue', 'issued'],
          source: source
        },
        xAxis:  {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }          
        },
        yAxis:  { inverse: true, type: 'category' },
        series: [
          { 
            label: {
              show: true,
              position: 'inside'
            },
            type: 'bar', markPoint: { label: { position: 'right' } }
          },
          { 
            label: {
              show: true,
              position: 'inside'
            },
            type: 'bar', markPoint: { label: { position: 'right' } }
          }
        ],
        legend: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { title: 'Save' }
          }
        },
      })

      this.chart.on('click', (params) => {
        let drawer = {
          visible: true,
          title: `${params.data.name} [${params.seriesName}]`,
          locators: params.data[params.seriesName + 'Ids']
        }

        this.$emit('showDrawer', drawer)
      })
    }
  },
  watch: {
    data(val) {
      this.initChart()
    }
  }
}
</script>
