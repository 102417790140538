import components from './components'

export default {
  google_flights_feed_settings: {
    action: {
      change_settings: 'Change settings'
    },
    form: {
      fields: {
        id: 'ID',
        airlines: 'Airlines',
        routes: 'Routes',
        trafficSourceIds: 'Tr. source',
        routeTypes: 'Route type',
        flightType: 'Flight type',
        departurePeriod: 'Departure period',
        timelimit: 'Timelimit',
        drivers: 'Gds',
        officeIds: 'Offices',
        active: 'Active',
        updatedAt: 'Updated at',
        forceReprice: 'Perform reprice',
        forceRepriceOptions__aviaConfigItemsIds: 'Config IDs',
        forceRepriceOptions__hoursToProcess: 'Schedule',
        forceRepriceOptions__routeTypes: 'Route type',
        forceRepriceOptions__lastPerformTime: 'Last perform time'
      },
      options: {
        routeTypes: {
          1: 'OW',
          2: 'RT'
        },
        flightType: {
          0: 'All',
          1: 'Own',
          2: 'Interline',
          'all': 'All',
          'own': 'Own',
          'interline': 'Interline'
        },
        drivers: {
          2: 'amadeus',
          3: 'galileo',
          4: 'sirena',
          5: 'gabriel',
          6: 'sabre',
          7: 'charter',
          8: 'galileo_uapi',
          9: 'accel',
          11: 'click_avia',
          12: 'fly_dubai',
          13: 'sun_express',
          14: 'atlas_global',
          15: 'travel_fusion',
          16: 'radix',
          17: 'troya',
          18: 'gaia',
          19: 'fare_logix',
          20: 'hitit',
          21: 'skyup',
          22: 'afkl',
          24: 'sig',
          25: 'kiwi',
          26: 'glory',
          28: 'aerticket',
          29: 'nemo',
          31: 'pobeda',
          32: 'tui',
          33: 'arcadialab',
          34: 'sq',
          35: 'yanair',
          36: 'travelport',
          37: 'aero_crs',
          38: 'aerticket',
          39: 'su',
          41: 'ps',
          45: 'seats_block',
          49: 'himytrip'
        },
        force_reprice_options__route_types: {
          1: 'OW',
          2: 'RT'
        },
      },
      placeholder: {
        airlines: 'Example: PS,dl,FF',
        routes: 'Example: IEV-MUC,waw;LWO-NYC (city only)',
        departurePeriod: 'Example: 2-5 ( days from search day )',
        timelimit: 'Example: 4 (hours)',
        officeIds: 'Example: LWOU228AE,5K1G',
        forceRepriceOptions__aviaConfigItemsIds: '10,20,30',
        forceRepriceOptions__hoursToProcess: '4h,8h,12h',
      },
      legends: {
        force_reprice_options: 'Reprice options'
      }
    },
    table: {
      index: 'Index',
      airlines: 'Airlines',
      routes: 'Routes',
      trafficSourceIds: 'Traffic source',
      routeTypes: 'Route type',
      flightType: 'flight type',
      departurePeriod: 'Departure period',
      timelimit: 'Timelimit',
      drivers: 'Gds',
      office_ids: 'Offices',
      active: 'Active',
      updatedAt: 'Updated at'
    },
    changeSettingsWindow: components.changeSettingsWindow
  }
}
