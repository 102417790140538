<template lang="pug">
  el-dialog(
    v-if="commissionWindow.visible"
    :visible.sync="commissionWindow.visible" 
    center
    :title="commissionWindow.title"
    v-loading="commissionWindow.loading" 
    element-loading-text="Loading..."
    class='avia-commission-rules-window'
    width="90%")
    el-table(:data="commissionWindow.items")
      el-table-column(label="ID" prop="id" width="75px")
      el-table-column(label="Own flights" width="600px")
        template(slot-scope="scope")
          div(v-html="scope.row.ownFlights")
      el-table-column(label="Part interline")
        template(slot-scope="scope")
          div(v-html="scope.row.partInterline")
      el-table-column(label="Full interline" width="200px")
        template(slot-scope="scope")
          div(v-html="scope.row.fullInterline")
      el-table-column(label="Comment" prop="lastComment" width="200px")
      el-table-column(label="Last update" width="150px")
        template(slot-scope="scope")
          span {{ scope.row.updatedAt | moment('LLL') }}

    span.dialog-footer(slot="footer")
      el-form(:model="commissionWindow.form" :rules="commissionWindowRules" ref="commissionWindow")
        el-row(:gutter="24")
          el-col(:span="6")
            el-form-item(label="Aircompany" prop="aircompany")
              el-input(v-model="commissionWindow.form.aircompany" style="width: 75%")

          el-col(:span="6")
            el-form-item(label="Issue office" prop="issueOffice")
              el-select(v-model="commissionWindow.form.issueOffice" filterable)
                el-option(
                  v-for="o in autoticketingOffices" 
                  :label="o[1]"
                  :value="o[0]"
                  :key="o[0]")

          el-col(:span="12" style="text-align: left")
            el-button(@click="sendForm") Search
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'
import autoticketingStore from '@/settings/modules/avia/general/autoticketing_store'

export default {
  mixins:[common, di],
  data() {
    return {
      commissionWindow: {
        title: 'Commission rules',
        visible: false,
        loading: false,
        items: [],
        form: { aircompany: '', issueOffice: '' }
      },
      commissionWindowRules: {
        aircompany:  [{ required: true, message: 'Please input Aircompany', trigger: 'blur' }],
        issueOffice: [{ required: true, message: 'Please select Issue office', trigger: 'blur' }]
      }
    }
  },

  computed:{
    autoticketingOffices() { return autoticketingStore.forSelectById() }
  },

  methods: {
    show() {
      this.commissionWindow.visible = true
      this.$refs.commissionWindow?.resetFields()
    },

    sendForm() {
      this.$refs.commissionWindow.validate((valid) => {
        if (valid) { this.search() }
      })
    },

    async search()
    {
      this.commissionWindow.loading = true
      try {
        const form = this.commissionWindow.form
        const query = {
          __args: {
            aircompany: form.aircompany,
            issueOffice: form.issueOffice
          },
          collection: {
            id: true,
            airline: true,
            ownFlights: true,
            partInterline: true,
            fullInterline: true,
            lastComment: true,
            updatedAt: true
          }
        }

        let res = await this.graphql('aviaBookingItemCommissionRule', query, 'query')
        this.commissionWindow.items = res.collection
        this.commissionWindow.loading = false
      } catch (e) {
        this.commissionWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    }

  }
}
</script>

<style lang="scss" scopped>
  .bold { font-weight: bold }

  .avia-commission-rules-window {
  }
</style>