import { loadCrudModules } from '@/utils'

export default {
  name: 'travel_rankings',
  path: '/travel_rankings',
  redirect: '/travel_rankings/reports',
  alwaysShow: true,
  layout: () => import('@/views/crud/travel_rankings/layout'),
  meta: {
    title: 'travel_rankings.default',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./travel_rankings', false, /\.js$/))
}
