import ttnInput from './input.vue'
import ttnInputNumber from './input-number.vue'
import ttnTextarea from './textarea.vue'
import ttnDatePicker from './date-picker.vue'
import ttnCheckbox from './checkbox.vue'
import ttnSelect from './select.vue'
import ttnMultiselect from './multiselect.vue'
import ttnRadioGroup from './radio-group.vue'
import ttnJsoneditor from './jsoneditor.vue'
import ttnYamleditor from './yamleditor.vue'
import ttnSwitch from './switch.vue'
import ttnSlider from './slider.vue'
import ttnFormItem from './form-item.vue'
import ttnFilterOperatorSelect from './filter-operator-select.vue'
import ttnUpload from './upload.vue'

export default {
  ttnInput,
  ttnInputNumber,
  ttnTextarea,
  ttnDatePicker,
  ttnCheckbox,
  ttnSelect,
  ttnMultiselect,
  ttnRadioGroup,
  ttnJsoneditor,
  ttnYamleditor,
  ttnSwitch,
  ttnSlider,
  ttnFormItem,
  ttnFilterOperatorSelect,
  ttnUpload
}
