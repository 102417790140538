export default {
  connection_time_rule: {
    table: {
      location: 'Location',
      oindex: 'Index',
      transferTime: 'Time',
      transferType: 'Type',
      arrivalCarrier: 'Arrival carrier',
      arrivalCodeshareCarrier: 'Arrival codeshare',
      departureCarrier: 'Departure carrier',
      departureCodeshareCarrier: 'Departure codeshare',
      arrivalAircraft: 'Arrival aircraft',
      departureAircraft: 'Departure aircraft',
      previousLocation: 'Previous location',
      nextLocation: 'Next location',
      arrivalTerminal: 'Arrival terminal',
      departureTerminal: 'Departure terminal',
      arrivalFlightNumber: 'Arrival flight number',
      departureFlightNumber: 'Departure flight number',
      validFrom: 'Valid from',
      validTo: 'Valid to',
      nativeId: 'Native',
      description: 'Description'
    },
    form: {
      fields: {
        id: 'ID',
        location: 'Location',
        oindex: 'Index',
        transferTime: 'Time (m)',
        transferType: 'Type',
        arrivalCarrier: 'Arr. carrier',
        arrivalCodeshareCarrier: 'Arr. codeshare',
        departureCarrier: 'Dep. carrier',
        departureCodeshareCarrier: 'Dep. codeshare',
        arrivalAircraft: 'Arr. aircraft',
        departureAircraft: 'Dep. aircraft',
        previousLocation: 'Previous location',
        nextLocation: 'Next location',
        arrivalTerminal: 'Arr. terminal',
        departureTerminal: 'Dep. terminal',
        arrivalFlightNumber: 'Arr. flight num.',
        departureFlightNumber: 'Dep. flight num.',
        validFrom: 'Valid from',
        validTo: 'Valid to',
        nativeId: 'Native'
      },
      options: {
        transferType: {
          1: 'Dom.-Dom.',
          2: 'Dom.-Int.',
          3: 'Int.-Dom.',
          4: 'Int.-Int.'
        }
      },
      placeholder: {
        location: 'IEV / IEV-KBP (airports only)',
        previousLocation: 'LHR,LTN (airports only)',
        arrivalCarrier: 'PS,DL',
        arrivalCodeshareCarrier: 'PS,DL',
        departureCarrier: 'PS,DL',
        departureCodeshareCarrier: 'PS,DL',
        arrivalAircraft: '737,A30',
        departureAircraft: '737,A30',
        nextLocation: 'LHR,LTN (airports only)',
        arrivalTerminal: '1A,5D',
        departureTerminal: '1A,5D',
        arrivalFlightNumber: '10-110,200-250',
        departureFlightNumber: '10-110,200-250'
      }
    }
  }
}
