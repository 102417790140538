export default {
  name: 'backend_users',
  items: [
    {
      name: 'backend_users',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'backendUsers',
        queries: {
          index: {
            id: true,
            login: true,
            email: true,
            backendUserRoles: {
              backendRoleId: true
            }
          }
        },
        preloadFields: ['tree']
      },
      preload: true,
      table: {
        items: [
          {
            name: 'id',
            width: '80px'
          },
          'login',
          'email',
          {
            name: 'actions',
            width: '70px',
            align: 'center'
          }
        ]
      },
      history: [
        'Legacy::BackendUserRepository'
      ],
      actions: {
        row: [
          {
            name: 'change_roles',
            icon: 'el-icon-s-operation',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'login',
                type: 'text',
                operator: 'eq'
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'login',
                type: 'text'
              },
              {
                name: 'email',
                type: 'text'
              },
              {
                name: 'backendUserRoles',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        ]
      }
    },

    {
      name: 'backend_roles',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'backendRoles',
        queries: {
          index: {
            id: true,
            key: true,
            type: true,
            parentId: true
          }
        }
      },
      table: {
        items: [
          {
            name: 'id',
            width: '80px'
          },
          'key',
          'type'
        ]
      },
      history: [
        'Legacy::BackendUserRepository'
      ],
      actions: {
        row: [],
        panel: [
          {
            name: 'import',
            icon: 'el-icon-refresh',
            type: 'danger',
            plain: true
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'key',
                type: 'text',
                operator: 'like'
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'login',
                type: 'text'
              },
              {
                name: 'email',
                type: 'text'
              },
              {
                name: 'backendUserRoles',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        ]
      }
    }

  ]
}