<template lang="pug">
  div(v-if="booking")
    table(border="0" style="width: 100%")
      tr(style="background-color:#f5f7fa; height: 25px; color: #000; width: 100%")
        th Name
        th Email
        th Phone

      tr(style="text-align: center")
        td {{ user.username }}
        td {{ user.email }}
        td {{ user.phone }}

      tr(v-if="Object.keys(additionalInfo.real_contacts).length > 0 && (additionalInfo.real_contacts.email != user.email || additionalInfo.real_contacts.phone != user.phone)" style="text-align: center")
        td
        td {{ additionalInfo.real_contacts.email }}
        td {{ additionalInfo.real_contacts.phone }}
</template>

<script>
export default {
  props: {
    booking: null
  },

  computed: {
    user() { return this.booking.user },
    additionalInfo() { return JSON.parse(this.booking.additionalInfo) }
  }
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  table {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
    width: 100%;
  }

  table tr { background-color: #fff; vertical-align: middle }
  table tr.route { vertical-align: middle; height: 40px }
  table tr td { padding: 5px }
  table tr th { padding: 5px }
</style>