<template lang="pug">
  el-dialog(
    v-if="issueRuleWindow.visible"
    :visible.sync="issueRuleWindow.visible" 
    center
    :title="issueRuleWindow.title"
    v-loading="issueRuleWindow.loading" 
    element-loading-text="Loading..."
    class='avia-commission-rules-window'
    width="90%")
    el-table(:data="issueRuleWindow.items")
      el-table-column(label="ID" width="75px" prop="id")
      el-table-column(label="Priority" width="75px" prop="priority")
      el-table-column(label="Aircompany" width="100px" prop="airlines")
      el-table-column(label="Scheme" width="200px" prop="scheme_name")

      el-table-column(label="GDS" width="350px")
        template(slot-scope="scope")
          span {{ preparedGdsList(scope.row.gds) }}

      el-table-column(label="Issue office" width="150px")
        template(slot-scope="scope")
          span {{ officeFor(scope.row.ticketing_driver) }}      

      el-table-column(label="Text" style="width: 30%")
        template(slot-scope="scope")
          div(v-html="scope.row.free_text")

      el-table-column(label="Last update" width="150px")
        template(slot-scope="scope")
          span {{ scope.row.updated_at | moment('LLL') }}

    span.dialog-footer(slot="footer")
      el-form(:model="issueRuleWindow.form" :rules="issueRuleWindowRules" ref="issueRuleWindow")
        el-row(:gutter="24")
          el-col(:span="6")
            el-form-item(label="Aircompany" prop="aircompany")
              el-input(v-model="issueRuleWindow.form.aircompany" style="width: 75%")

          el-col(:span="6")
            el-form-item(label="Group" prop="parent")
              el-select(v-model="issueRuleWindow.form.parent" filterable)
                el-option(
                  v-for="o in issueRuleWindow.ruleParents" 
                  :label="o[1]"
                  :value="o[0]"
                  :key="o[0]")

          el-col(:span="6")
            el-form-item(label="GDS" prop="gds")
              el-select(v-model="issueRuleWindow.form.gds" filterable)
                el-option(
                  v-for="o in gdsList" 
                  :label="o.name"
                  :value="o.id"
                  :key="o.id")

          el-col(:span="6" style="text-align: left")
            el-button(@click="sendForm") Search
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'
import autoticketingStore from '@/settings/modules/avia/general/autoticketing_store'
import gdsStore from '@/settings/modules/avia/general/gds_store'

export default {
  mixins:[common, di],
  data() {
    return {
      issueRuleWindow: {
        title: 'Issue rules',
        visible: false,
        loading: false,
        items: [],
        form: { aircompany: '', parent: '', gds: '' },
        ruleParents: []
      },
      issueRuleWindowRules: {
        aircompany: [{ required: true, message: 'Please input Aircompany', trigger: 'blur' }],
        parent:     [{ required: true, message: 'Please select Group', trigger: 'blur' }],
        gds:        [{ required: true, message: 'Please select GDS', trigger: 'blur' }]
      }
    }
  },

  computed:{
    autoticketingOffices() { return autoticketingStore.forSelectById() },
    gdsList() { return gdsStore.arrayById() },
    gdsListById() { return gdsStore.objectById() }
  },

  methods: {
    show() {
      this.issueRuleWindow.visible = true
      this.$refs.issueRuleWindow?.resetFields()

      this.loadIssueRuleParents()
    },

    sendForm() {
      this.$refs.issueRuleWindow.validate((valid) => {
        if (valid) { this.search() }
      })
    },

    async search()
    {
      this.issueRuleWindow.loading = true
      try {
        const form = this.issueRuleWindow.form
        const query = {
          __args: {
            aircompany: form.aircompany,
            parent:     form.parent,
            gds:        form.gds
          }
        }

        let res = await this.graphql('aviaBookingItemIssueRuleSearch', query, 'query')
        this.issueRuleWindow.items = JSON.parse(res)

        console.log(this.issueRuleWindow.items)
        this.issueRuleWindow.loading = false
      } catch (e) {
        this.issueRuleWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    async loadIssueRuleParents() {
      let res = await this.graphql('aviaBookingItemIssueRuleParent', {}, 'query')
      this.issueRuleWindow.ruleParents = JSON.parse(res)
    },

    officeFor(officeID) {
      return autoticketingStore.officeFor(officeID)
    },

    preparedGdsList(gdsList) {
      return gdsList.split(',').map((x) => this.gdsListById[x]).join(',')
    }

  }
}
</script>

<style lang="scss" scopped>
  .bold { font-weight: bold }

  .avia-commission-rules-window {
  }
</style>