import { loadCrudModules } from '@/utils'

export default {
  name: 'payment',
  path: '/payment',
  alwaysShow: true,
  meta: {
    title: 'payment.default',
    icon: 'skill'
  },
  children: loadCrudModules(require.context('./payment', true, /\.js$/))
}