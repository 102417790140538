import cacheWarmupWorker from './cache/warmup_worker'

export default {
  namespace: 'avia',
  component: 'cache',
  meta: {
    title: 'avia.cache',
    icon: 'el-icon-files',
    roles: ['pesimisation_rules']
  },
  tabs: [
    cacheWarmupWorker
  ]
}
