<template lang="pug">
  extends /table
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatCell({ row, $column, column, cellValue, index }) {

      if (column.name == 'penaltyBeforeDepartureAmount') {

        if (row.penaltyBeforeDepartureNonRef)
          return 'none'
        else
          return cellValue + ' ' + row.penaltyBeforeDepartureCurrency

      }

      if (column.name == 'penaltyAfterDepartureAmount') {

        if (row.penaltyAfterDepartureNonRef)
          return 'none'
        else
          return cellValue + ' ' + row.penaltyAfterDepartureCurrency

      }

      if (column.name == 'penaltyNoshowAmount') {

        if (row.penaltyNoshowNonRef)
          return 'none'
        else
          return cellValue + ' ' + row.penaltyNoshowCurrency

      }

      return cellValue
    }
  }
}
</script>