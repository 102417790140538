const BookingInformationStruct = {
  extraMetaRevenue: true,
  priceDetails: true
}

const AmountCurrencyStruct = {
  amount: true,
  currency: true
}

const PriceCalculationStruct = {
  netto: AmountCurrencyStruct,
  nettoCorrection: AmountCurrencyStruct,
  profitMarkups: AmountCurrencyStruct,
  paymentMarkups: AmountCurrencyStruct,
  surchargeFlight: AmountCurrencyStruct,
  surchargeAddons: AmountCurrencyStruct,
  surchargePostsale: AmountCurrencyStruct,
  paymentMethodMarkup: AmountCurrencyStruct,
  alternativeCurrencyMarkup: AmountCurrencyStruct,
  flightTotal: AmountCurrencyStruct,
  upsale: AmountCurrencyStruct,
  ancillary: AmountCurrencyStruct,
  insurance: AmountCurrencyStruct,
  diffMarkup: AmountCurrencyStruct,
  payedTotal: AmountCurrencyStruct,
  currencies: true,
  payedCurrency: true,
  fieldCalculationDetails: true
}

const PriceComponentStruct = {
  id: true,
  gdsId: true,
  aviaConfigItemId: true,
  issueOfficeId: true,
  status: true,
  isFake: true,
  issueSystemId: true,
  validatingSupplier: true,
  combinationId: true,
  locator: true,
  priceDetails: true,
  vndLocator: true,
  ticketed: true,
  segmentIds: true,
  passengerIds: true,
  extraData: true,
  issueOfficeName: true,
  cardAllowed: true,
  cardFee: true,
  externalPurchasesTotal: true,
  issuedAt: true,
  fareDowngradeInProgress: true,
  commission: true,
  bspCommissionBookingOffice: true,
  realFares: true
}

const LocationStruct = {
  id: true,
  supplierIata: true,
  carrier: true,
  departureDate: true,
  departureTime: true,
  departureLocation: true,
  arrivalDate: true,
  arrivalTime: true,
  arrivalLocation: true,
  segmentId: true,
  tripPart: true,
  bookingClassType: true,
  supplierName: true,
  otherSupplierName: true,
  otherSupplierIata: true,
  departureCountry: true,
  departureCity: true,
  departureLocationName: true,
  departureTerminal: true,
  arrivalCountry: true,
  arrivalCity: true,
  arrivalLocationName: true,
  arrivalTerminal: true,
  aircraft: true,
  aircraftCode: true,
  bookingClass: true,
  baggage: true,
  fareCode: true,
  duration: true,
  baggageDetails: {
    type: true,
    items: {
      pax: true,
      width: true,
      height: true,
      length: true,
      weight: true,
      weightUnit: true,
      dimensionSum: true,
      dimensionUnit: true,
      count: true,
      passengers: true      
    }
  }
}

const PassengerStruct = {
  id: true,
  firstName: true,
  lastName: true,
  gender: true,
  countryId: true,
  bonusCard: true,
  passportNumber: true,
  ageType: true,
  commission: true,
  patronymic: true,
  doctype: true,
  issueLocator: true,
  vndLocator: true,
  idNumber: true,
  bonusCard: true,
  ticketNumber: true,
  amounts: true,
  passportTerm: true,
  birthDay: true
}

const AncillaryServiceStruct = {
  id: true,
  tripPart: true,
  segmentId: true,
  segAssoc: true,
  status: true,
  ssrCat: true,
  pass: true,
  ssrCode: true,
  info: true,
  tax: true,
  fare: true,
  currency: true,
  issueCur: true,
  issueAmt: true,
  manualy: true,
  checkin: true,
  description: true,
  emd: true,
  externalData: true,
  amount: true,
  details: true,
  mailUpdate: true,
  type: true,
  cleverIgnore: true,
  mcoOrderId: true
}

const InsuranceBookingStruct = {
  reservationId: true,
  providerName: true,
  periodFrom: true,
  periodTo: true,
  bookingStatus: true,
  depotCost: true,
  depotCurrency: true,
}

const UserStruct = {
  username: true,
  phone: true,
  email: true
}

const HighPassOrderStruct = {
  id: true,
  airport: true,
  terminal: true,
  orderNumber: true,
  name: true,
  time: true,
  termsUrl: true,
  status: true,
  currency: true,
  providerCurrency: true,
  providerPrice: true,
  diff: true,
  price: true,
  updatedPrice: true,
  payedPrice: true,
  actualPrice: true,
  margin: true
}

const BrbOrderStruct = {
  id: true,
  serviceNumber: true,
  code: true,
  basePrice: true,
  baseCurrency: true,
  depotPrice: true,
  depotCurrency: true,
  status: true,
  coverage: true,
  passengers: true
}

const FullStruct = {
  id: true,
  status: true,
  createdAt: true,
  priceDetailsCode: true,
  depotCurrency: true,
  finalAllPrice: true,
  additionalInfo: true,
  locator: true,
  bookingInformations: BookingInformationStruct,
  priceCalculation: PriceCalculationStruct,
  priceComponents: PriceComponentStruct,
  locations: LocationStruct,
  passengers: PassengerStruct,
  dictionary: true,
  virtualInterline: true,
  ancillaryServices: AncillaryServiceStruct,
  insuranceBookings: InsuranceBookingStruct,
  externalPurchases: true,
  exchanges: true,
  additionalServices: true,
  user: UserStruct,
  discountInfoJson: true,
  highPassOrders: HighPassOrderStruct,
  brbOrder: BrbOrderStruct,
  detailedInformation: true
}

export default Object.freeze({
  FullStruct: FullStruct
})
