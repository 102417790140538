<template lang="pug">
  div(class="list-content")
    el-table(:data="records" :stripe="true" @selection-change="eventSelectionChange" v-loading="loading")
      el-table-column(:selectable="isSelectable" type="selection" width="55")
      el-table-column(type="expand")
          template(slot-scope="scope")
            fakePnrSearchRule(v-bind:re_search_rule="scope.row", @updateAction="updateAction" @createAction="createAction" @deleteAction="deleteAction")
      el-table-column(prop="id", label="ID")
      el-table-column(label="Depots")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.depots.join(", ") }}
      el-table-column(label="Gds")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.gds.join(", ") }}
      el-table-column(label="VC")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.vc.join(", ") }}
      el-table-column(label="Configs")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.configItems.join(", ") }}
      el-table-column(label="Geography")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.flightGeography }}
      el-table-column(label="Refid")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.refid.join(", ") }}
      el-table-column(label="Comments", prop="comments")
      el-table-column(label="Monosearch")
        template(slot-scope="scope")
          el-switch(:value="scope.row.monosearch", disabled)
      el-table-column(label="Status")
        template(slot-scope="scope")
          el-switch(:value="scope.row.status", disabled)
      el-table-column(label="Updated At")
        template(slot-scope="scope")
          span {{new Date(scope.row.updatedAt) | moment("DD/MM/YY  HH:mm:ss")}}
      el-table-column(label="Childs")
        template(slot-scope="scope")
          span {{getSizeOfChildRules(scope.row)}}
      el-table-column(class-name="small-padding" :label="$t('crud.table.actions')" width="70px" align="center")
        template(slot-scope="scope")
          el-button(
            :title="translate(`action.update`)"
            type="warning"
            plain
            size="mini"
            icon="el-icon-edit"
            @click="handleAction('update', scope.row)"
          )
</template>

<script>
import BaseTable from '@crud_view/table'

import fakePnrSearchRule from './re_search_rules'

export default {
  components: {
    BaseTable,
    fakePnrSearchRule
  },
  extends: BaseTable,

  data() {
    return {
      configItems: {}
    }
  },

  created() {
    this.configItems = this.$store.getters['bootData']['config_items_hash']
  },

  methods: {

    updateAction(row) {
      this.handleAction('update', row)
    },

    createAction(parentId) {
      this.handleAction('create', { parentId: parseInt(parentId) })
    },

    deleteAction(rows) {
      this.eventSelectionChange(rows)
      this.handleDelete()
    },

    getSizeOfChildRules(row) {
      return row.childRules.length + ' / ' + row.childRules.filter(x => x.monosearch).length
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-tag.el-tag--warning.margin-bot-5{
    margin-bottom: 5px;
  }

  .el-tag.color-inherit{
    color: inherit;
  }

</style>
