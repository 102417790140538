<template lang="pug">
  div
    el-row
      h3 Price Rule:
    el-row
      el-table(:stripe="true", :data="findPriceRule()")
        el-table-column(label="ID", prop="id")
        el-table-column(label="Discounts")
          el-table-column(label="Markup", prop="markup")
          el-table-column(label="Auction", prop="auction")
          el-table-column(label="Dynamic", prop="dynamic_pricing")
          el-table-column(label="Ancillary", prop="ancillary")
          el-table-column(label="Upsales", prop="upsales")
        el-table-column(label="Config", prop="config")
          template(slot-scope="scp")
            span {{ scp.row["config"] ? scp.row["config"].join(", ") : '' }}
        el-table-column(label="RefId", prop="refid")
          template(slot-scope="scp")
            span {{ scp.row["refid"] ? scp.row["refid"].join(", ") : '' }}
        el-table-column(label="Supplier")
          template(slot-scope="scp")
            span {{ scp.row["vc"] ? scp.row["vc"].join(", ") : '' }}
        el-table-column(type="expand")
          template(slot-scope="scope")
            el-row
              h4 Manual Discount
            el-row
              el-table(:stripe="true", :data="scope.row['discounts']")
                el-table-column(label="Price Range")
                  el-table-column(label="From", prop="price_from")
                  el-table-column(label="To", prop="price_to")
                el-table-column(label="Currency", prop="currency")
                el-table-column(label="Value", prop="value")
                el-table-column(label="Type")
                  template(slot-scope="scp")
                    span {{ scp.row["type"] == 1 ? 'order' : 'ticket' }}
                el-table-column(label="Expiration Date")
                  el-table-column(label="Start", prop="start_date")
                  el-table-column(label="End", prop="end_date")
            el-row
              h4 Manual Budget
            el-row
              el-table(:stripe="true", :data="scope.row['budgets']")
                el-table-column(label="Amount", prop="amount")
                el-table-column(label="Currency", prop="currency")
                el-table-column(label="Expiration Date")
                  el-table-column(label="Start", prop="start_date")
                  el-table-column(label="End", prop="end_date")
            el-row
              h4 Max Discount
            el-row
              el-table(:stripe="true", :data="scope.row['discount_limits']")
                el-table-column(label="Price Range")
                  el-table-column(label="From", prop="price_from")
                  el-table-column(label="To", prop="price_to")
                el-table-column(label="Currency", prop="currency")
                el-table-column(label="Value", prop="value")
                el-table-column(label="Type")
                  template(slot-scope="scp")
                    span {{ scp.row["type"] == 1 ? 'order' : 'ticket' }}
    el-row
      h3 Price Log:
    el-row
      el-table(:stripe="true", :data="findPriceLog()")
        el-table-column(label="Loss", prop="loss")
        el-table-column(label="Markup", prop="markup")
        el-table-column(label="Auction", prop="auction")
        el-table-column(label="Dynamic", prop="dynamic")
        el-table-column(label="Manual Discount")
          el-table-column(label="Available", prop="manual_discount")
          el-table-column(label="Used", prop="manual_discount_used")
        el-table-column(label="Loss After Manual Apply")
          template(slot-scope="scp")
            span {{ scp.row["need_to_cover_after_manual_discount"] > 0 ? scp.row["need_to_cover_after_manual_discount"] : 0 }}
        el-table-column(label="Budget")
          el-table-column(label="Total", prop="budget_amount")
          el-table-column(label="Available", prop="budget_used_according_to_max_limit")
          el-table-column(label="Used", prop="budget_used")
        el-table-column(label="Loss After Budget Apply", prop="need_to_cover_after_budget")
          template(slot-scope="scp")
            span {{ scp.row["need_to_cover_after_budget"] > 0 ? scp.row["need_to_cover_after_budget"] : 0 }}
        el-table-column(label="Max Discount", prop="max_discount")
</template>

<script>
export default {
  name: 'PriceLog',
  props: {
    priceRules: Array,
    pcId: String,
    recId: String
  },

  data() {
    return {
    }
  },

  methods: {

    findPriceLog() {
      var item = null
      var recId = this.recId

      this.priceRules.flat().forEach((element) => {
        if(element['recc_id'] == recId) {
          item = element
        }
      })

      if(item == null || item['price_log'] == undefined) {
        return []
      }else{
        return [item['price_log']]
      }
    },

    findPriceRule() {
      var item = null
      var pcId = this.pcId

      this.priceRules.flat().forEach((element) => {
        if(element['pc_id'] == pcId) {
          item = element
        }
      })

      if(item == null || item['price_rule'] == undefined) {
        return []
      }else{
        return [item['price_rule']]
      }
    }

  }
}

</script>
