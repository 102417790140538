import gds_list from '@/settings/modules/avia/general/gds_store'

export default {
  items: [
    {
      name: 'id',
      type: 'text',
      operator: 'eq',
      camelizeLangKey: true
    },
    {
      name: 'validating_supplier',
      type: 'text',
      operator: 'in_array_str',
      camelizeLangKey: true
    },
    {
      name: 'fare_name',
      type: 'text',
      operator: 'in_array_str',
      camelizeLangKey: true
    },
    {
      name: 'departure_from',
      type: 'text',
      operator: 'in_array_str',
      camelizeLangKey: true
    },
    {
      name: 'transfer',
      type: 'text',
      operator: 'in_array_str',
      camelizeLangKey: true
    },
    {
      name: 'arrival_to',
      type: 'text',
      operator: 'in_array_str',
      camelizeLangKey: true
    },
    {
      name: 'carrier',
      type: 'text',
      operator: 'eq',
      camelizeLangKey: true
    },
    // {
    //   name: 'flight',
    //   type: 'text',
    //   operator: 'flight_filter',
    //   camelizeLangKey: true
    // },
    {
      name: 'gds_id',
      type: 'select',
      operator: 'in_array_str',
      store: Object.assign({ 'all': 'all' }, gds_list.objectByName()),
      camelizeLangKey: true
    },
    {
      name: 'active',
      type: 'radiogroup',
      button: true,
      operator: 'eq',
      store: {
        '': 'Any',
        true: 'Yes',
        false: 'No'
      }
    }
  ]
}