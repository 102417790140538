<template lang="pug">
  extends /table
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,

  methods: {

    actionDownload() {
      this.exportToCsv()
    },

    async exportToCsv() {
      var fields = ['Ref ID', 'Key', 'Search', 'Clicks','CTS, %', 'Created', 'Paid', 'Issued', 'Convers', 'Total Issued', 'BTR,%', 'CTR, %', 'Demo CTR, %', 'Paid / Created, %', 'Issued / Created, %'];
      var filename = new Date().toISOString().slice(0, 19);
      var refids = this.$store.getters[`${this.storeName}/list`]
      var content = [];
      content.push(fields.join(';'));
      for (var i=0; i < refids.length; i++) {
        var content_row = [];
        var rows = refids[i];
        content_row.push(rows.key);
        for (var j=0; j < rows['items'].length; j++) {
          var row = rows['items'][j];
          content_row.push(row.key);
          content_row.push(row.searches);
          content_row.push(row.clicks_count);
          content_row.push(row.cts);
          content_row.push(row.created_bookings);
          content_row.push(row.paid_bookings);
          content_row.push(row.issued_bookings);
          content_row.push(row.convers_bookings);
          content_row.push(row.total_issued_bookings);
          content_row.push(row.btr);
          content_row.push(row.ctr);
          content_row.push(row.demo_ctr);
          content_row.push(row.paid_to_created);
          content_row.push(row.issued_to_created);
          content_row.push('\n');
        }
        content.push(content_row.join(';'))
      }

      var blob = new Blob([content.join('\n')], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
      } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
    }
  }
}
</script>
