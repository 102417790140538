import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import waves from '@/directive/waves'
import permission from '@/directive/permission'
import checkPermission from '@/utils/permission'
import checkLegacyPermission from '@/utils/legacy-permission'

export default {
  directives: { waves, permission },
  props: {
    row: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    fullNameParts() {
      return [this.config.namespace, this.config.component, this.config.tab, this.config.name].filter((i) => !!i)
    },

    storeName() {
      return `${this.fullNameParts.join('/')}`
    },

    isTopFilter() {
      return this.config.filter.position === 'top'
    },
    isRightFilter() {
      return this.config.filter.position === 'right'
    },
    preloadStores() {
      return this.$elItem.preloadStoreCache
    }
  },
  methods: {
    injectHook(options, name, hook) {
      var existing = options[name]
      options[name] = existing
        ? Array.isArray(existing) ? existing.concat(hook) : [existing, hook]
        : [hook]
    },
    $onAction(name, callback) {
      this.$on(`action_${name}`, callback)
    },
    $offAction(name, callback) {
      this.$off(`action_${name}`, callback)
    },
    $emitAction(name, data) {
      this.$emit(`action_${name}`, data)
    },

    checkPermission,
    checkLegacyPermission,
    translate(name, arg1 = null, arg2 = null) {
      const key = `${this.fullNameParts.join('.')}.${name}`
      let defaultValue = arg1
      let params = arg2
      if (arg1 !== null && !['string', 'boolean'].includes(typeof arg1)) {
        defaultValue = arg2
        params = arg1
      }
      if (this.$te(key)) {
        return this.$t(key, params)
      } else {
        if (this.$te(`crud.${name}`)) {
          return this.$t(`crud.${name}`, params)
        }
        return defaultValue === true ? null : (defaultValue || key)
      }
    },

    isActiveItem() {
      return this.tab === this.config.tab && this.item === this.config.name
    },

    parentItemComponent() {
      if (this.config.isCombined) {
        return this.config.boot.type === 'local' ? this.$elTab : this.$elItem
      } else {
        return this.$elItem
      }
    },

    storeNameFor(itm) {
      return [itm.namespace, itm.component, itm.tab, itm.name].filter((i) => !!i).join('/')
    },

    handleAction(name, row) {
      this.$elItem.$emit(`action_${name}`, row)
    },
    async server(url, params) {
      return await this.$store.dispatch(`${this.storeName}/custom`, { url, params })
    },

    async graphql(request, query, type) {
      let params = {}
      const vars = {}
      vars[request] = query
      params = { query: (type === 'mutation' ? `${jsonToGraphQLQuery({ mutation: vars })}` : `{${jsonToGraphQLQuery(vars)}}`) }

      return await this.$store.dispatch(`${this.storeName}/custom`, { url: request, params })
    },
    getParent(name) {
      let component = null
      let parent = this.$parent
      while (parent && !component) {
        if (parent.$options.name === name) {
          component = parent
        }
        parent = parent.$parent
      }
      return component
    },

    fieldOptions(field, type, value, fieldset) {
      return this.$parent._fieldOptions(field, type, value, fieldset)
    },

    remoteMethod(query, field, type, fieldset) {
      this.$parent._remoteMethod(query, field, type, fieldset)
    },

    preloadStore(key) {
      if (!key) {
        throw new Error('Missing key for preload store')
      }
      return this.$elItem.preloadStoreCache[key] || {}
    },

    exception(error) {
      this._log('exception', error)
    },

    debug(text) {
      this._log('debug', text)
    },

    _log(type, mix) {
      if(!process.env.ENABLE_LOGS) {
        return
      }

      switch(type) {
        case 'exception':
          console.error(mix)
          break
        case 'debug':
          console.info(mix)
          break
      }
    },

    async copyToClipboard(text, notifyText) {
      await navigator.clipboard.writeText(text)

      this.$notify({
        title: 'Success',
        message: notifyText,
        duration: 2600
      })
    }
  }
}
