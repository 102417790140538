export default {
  l2b_reports: {
    table: {
      name: 'Name',
      handlerStatus: 'Status',
      filePath: 'Report file',
      reportType: 'Type',
      // searchOptions: {
      //   dates: true,
      //   gdsIds: true,
      //   offices: true,
      //   depots: true,
      //   refs: true
      // },
      groupOptions: 'Group by'
    },
    form: {
      fields: {
        name: 'Name',
        groupOptions: 'Group by',
        reportType: 'Report type',
        searchOptions__startDate: 'Date from',
        searchOptions__endDate: 'Date to',
        searchOptions__gdsIds: 'Gds',
        searchOptions__offices: 'Offices',
        searchOptions__depots: 'Depots',
        searchOptions__refs: 'Refs'
      },
      options: {
        groupOptions: {
          date: 'Date',
          gds_id: 'Gds',
          offices: 'Offices',
          depots: 'Depots',
          refs: 'Refs'
        },
        handlerStatus: {
          0: 'Undefined',
          1: 'Success',
          2: 'Error',
          3: 'Exception',
          4: 'Started',
        },
        reportType: {
          0: 'L2b report',
          1: 'Sales report',
          'l2b_report': 'L2b report',
          'sales_report': 'Sales report'
        }
      },
      legends: {
        settings: 'Settings'
      }
    },
    messages:{
      download_error: 'Download valid only for Success status',
      download_empty_content: 'File not valid'
    }
  }
}
