<style scoped>
  .rs-dv{ padding-bottom: 5px;  }
  .rs-label{ font-weight: bold; padding-right: 10px; display: inline-block; width: 90px}
  .rs-value{}
  .rs-date{ width: 72px; display: inline-block; padding-right: 10px }
  .rs-tx-left{ text-align: right }
</style>

<template lang="pug">
  extends /table
  block expand
    div(class="rs-dv")
      span(class="rs-label") Flight type:
      span(class="rs-value") {{ from_options(scope.row.flightType, {name: 'flightType'}, _self) }}
    div(class="rs-dv")
      span(class="rs-label") Interline:
      span(class="rs-value") {{ from_options(scope.row.interline, {name: 'interline'}, _self) }}
    div(class="rs-dv")
      span(class="rs-label") Class:
      span(class="rs-value") {{ array_as_string_options(scope.row.rbdTypes, {name: 'rbdTypes'}, _self) }} -  {{ from_options(scope.row.rbdTypesFor, {name: 'rbdTypesFor'}, _self) }}
    div(class="rs-dv")
      span(class="rs-label") Passengers:
      span(class="rs-value") {{ array_as_string_options(scope.row.passengerTypes, {name: 'passengerTypes'}, _self) }}
    div(class="rs-dv")
      span(class="rs-label") Min segments:
      span(class="rs-value") {{ scope.row.minSegments}}
    div(class="rs-dv")
      span(class="rs-label") Search period:
      span(class="rs-date rs-tx-left") {{ scope.row.searchDateFrom || '*' }} --
      span(class="rs-date") {{ scope.row.searchDateTo || '*' }}
    div(class="rs-dv")
      span(class="rs-label ") Travel period:
      span(class="rs-date rs-tx-left") {{ scope.row.travelDateFrom || '*' }} --
      span(class="rs-date") {{ scope.row.travelDateTo || '*' }}
    div(class="rs-dv")
      span(class="rs-label") Routes:
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import formats from '@/utils/formats.js'
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  methods: {
    ...formats,
    formatExpandedRow(row) {
      var res = []
      var routes = row['routes'].split(';')
      routes.forEach(v => {
        res.push(v.replace('-', '<font color=red> -- </font>'))
      })
      return res.join('<br/>')
    }
  }
}
</script>
