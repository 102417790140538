export default {

  beforeCreate() {
    const actions = this.$options.propsData.config ? this.$options.propsData.config.actions : this.$options.props.config.default().actions
    for (let i of Object.keys(actions)) {
      if(!Array.isArray(actions[i]) || actions[i].length === 0) {
        continue
      }
      for(let n = 0; n < actions[i].length; n++) {
        const action = actions[i][n]

        this.$options.methods.injectHook(this.$options, 'mounted', () => {
          typeof this[action.methodName] === 'function' && this.$elItem.$onAction(action.name, this[action.methodName])
        })
        this.$options.methods.injectHook(this.$options, 'beforeDestroy', () => {
          typeof this[action.methodName] === 'function' && this.$elItem.$offAction(action.name, this[action.methodName])
        })
      }
    }
  }
}