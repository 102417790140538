export default {
  json_schema: {
    table: {},
    form: {
      fields: {
        structVersion: '',
        struct: 'Struct version',
        inputData: 'Data',
        data: '',
        previewStruct: 'Preview'
      },
      legends: {
        main: ''
      }
    },
    modal: {
      preview: 'Preview'
    },
    table: {
      type: 'Type',
      dataPointer: 'Data pointer',
      schemaPointer: 'Schema pointer',
      details: 'Details'
    }
  }
}
