<template lang=pug>
  extends /table

  block custom
    clone-window

    group-change-window

    manage-seats-window
</template>

<style lang="scss">
  .ch-gr-padding{
    margin: 0 0 0 20px;

    .el-checkbox__label{
      font-size: 12px;
    }
  }
  .ch-tp-title{
    margin: 0 0 0 6px;
    font-weight: bold;
  }
  .asb-pr-line{
    div.cell{
      white-space: pre-line;
    }
  }
  .asb-pr-green{
    background: #23ff52 !important;
  }
  .asb-pr-red{
    background: #ffacac !important;
  }
  .asb-cl-old{
    .el-collapse-item__header{
      color: #8a2d0d;
      font-weight: bold;
    }
  }
  .asb-cl-new{
    .el-collapse-item__header{
      color: #066600;
      font-weight: bold;
    }
  }

</style>

<script>
import BaseTable from '@crud_view/table'
import CloneWindow from './components/clone_window'
import GroupChangeWindow from './components/group_change_window'
import ManageSeatsWindow from './components/manage_seats_window'

export default {
  components: {
    BaseTable,
    GroupChangeWindow,
    CloneWindow,
    ManageSeatsWindow
  },
  extends: BaseTable,
  methods: {
    formatCell({row, $column, column, cellValue, index}) {
      switch(column.name) {
        case 'validatingSupplier':
          return `${cellValue}\n${row['locator']}`
        case 'remainedSeats':
          return `${row['availableSeats']} / ${row['remainedSeats']}`
        case 'lastSaleTime':
          return (row['lastSaleTime'] ? row['lastSaleTime'].slice(0, 19).replace(' ', "\n") : '-')
        case 'priceDiff':
          let fares = (row['fares']['fares'] || row['fares'])
          return (row['currentPrice']['amount'] ? (`${row['currentPrice']['amount'] - fares['ADT']['total']} (${row['currentPrice']['rbd']})\n${this.$moment(row['lastCheckPriceTime'], 'YYYY-MM-DD hh:mm:ss').format('MM-DD HH:mm')}`) : '-')
        default:
          return cellValue
      }
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      switch(columnIndex) {
        case 3:
          return `asb-pr-line`
        case 5:
          if(row['currentPrice']['amount']){
            let fares = (row['fares']['fares'] || row['fares'])
            return `asb-pr-line asb-pr-${(row['currentPrice']['amount'] - fares['ADT']['total'] > 0.0 ? 'green' : 'red')  }`
          }
        case 7:
          return `asb-pr-line`
        default:
          return ''
      }
    },
    rowClassName({ row, rowIndex }) {
      if(!row['lastSaleTime'] || row['lastSaleTime'] == '' || !row['active']){ return '' }

      const days = (Date.parse(row['lastSaleTime']) - new Date) / (1000 * 3600 * 24)

      if(days <= 0){ return '' }

      if(days <= 5 && row['remainedSeats'] > 5){
        return 'danger-row'
      } else if (days <= 10 && row['remainedSeats'] > 10){
        return 'warning-row'
      }

      return ''
    },
    expandRequestFilters(childConfig, row){
      switch(childConfig.name){
        case "seats_block_rules_history":
          return {
            record_class: this.config.history[0].record_class,
            recordId: row.id
          }
        default:
          return {}
      }
    },
    decorateCombinedChildRecords(childConfig, results){
      switch(childConfig.name){
        case "seats_block_rules_history":
          return results.map((row) => {
            const changes = JSON.parse(row['changes'])

            const values = {
              locator: (changes['locator'] ? changes['locator'][1] : '-'),
              seatsCount: (changes['seats_count'] ? changes['seats_count'][1] : '-'),
              remainedSeatsBefore: (changes['remained_seats'] ? changes['remained_seats'][0] : '-'),
              remainedSeatsAfter: (changes['remained_seats'] ? changes['remained_seats'][1] : '-')
            }

            return {...row, ...values}
          })
        default:
          return results
      }
    },
    actionClone(row){
      this.$emit('showCloneWindow', row)
    },
    isSelectedItems(checkFunction){
      let error = false
      if(this.multipleSelection.length == 0){
        error = 'messages.no_item_selected'
      } else if(!this.multipleSelection.some((row) => { return checkFunction(row) })){
        error = 'messages.no_active_items'
      }

      if(error){
        this.$notify({
          title: this.translate('messages.title'),
          message: this.translate(error),
          type: 'warning',
          duration: 2600
        })
        return false
      } else{
        return true
      }
    },
    actionCheckPrice(){
      if( this.isSelectedItems((row) => { return (row.checkPriceParams.configId) }) ){
        this.performCheckRequest('CacheHandler::Avia::SeatsBlock::CheckPricesHandler')
      }
    },
    actionCheckInGds(){
      if( this.isSelectedItems((row) => { return (row.locatorConfigId) }) ){
        this.performCheckRequest('CacheHandler::Avia::SeatsBlock::CheckPnrsHandler')
      }
    },
    async performCheckRequest(entity){
      try {
        const query = {
          __args: {
            entity: entity,
            type: 'handler',
            params: {
              id: this.multipleSelection.map((i) => { return parseInt(i.id) }).join(',')
            }
          }
        }

        await this.$elItem.graphql('regenerateCache', query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('messages.title'),
          message: this.translate('messages.success'),
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.$notify({
          title: this.translate('messages.title'),
          message: this.translate('messages.error'),
          type: 'warning',
          duration: 2600
        })
      }
    },
    actionGroupChange(row){
      this.$emit('showGroupChangeWindow')
    },
    actionManageSeats(row){

      this.$emit('showManageSeatsWindow', row)
    }
  }
}
</script>
