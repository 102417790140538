<template>
  <div>
    <el-dialog :title="translate('refresh_statistic_window.title')" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item :label="translate('refresh_statistic_window.form.start_time')" :label-width="formLabelWidth">
          <el-date-picker v-model="form.start_time" type="datetime" />
        </el-form-item>
        <el-form-item :label="translate('refresh_statistic_window.form.end_time')" :label-width="formLabelWidth">
          <el-date-picker v-model="form.end_time" type="datetime" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-popconfirm :title="translate('refresh_statistic_window.confirm')" @confirm="actionConfirm">
          <el-button slot="reference">Confirm</el-button>
        </el-popconfirm>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  props: {
    isVisible: {
      required: false,
      type: Boolean,
      default: () => { return false }
    }
  },
  data: function() {
    return {
      dialogFormVisible: this.isVisible,
      form: {
        start_time: null,
        end_time: null
      },
      formLabelWidth: '120px'
    }
  },

  mounted() {
    this.$parent.$on('showRefreshWindow', () => {
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showRefreshWindow')
  },

  methods: {
    reset() {
      this.form.start_time = (new Date()).setHours(0, 0, 0, 0)
      this.form.end_time = (new Date())
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    actionConfirm() {
      this.performRequest(this.form)
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            entity: 'CacheHandler::Avia::L2b::DailyStatisticsHandler',
            type: 'handler',
            params: {
              start_time: this.$moment.utc(this.form.start_time).format(),
              end_time: this.$moment.utc(this.form.end_time).format()
            }
          }
        }

        await this.$elItem.graphql('regenerateCache', query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('refresh_statistic_window.msg.title'),
          message: this.translate('refresh_statistic_window.msg.success'),
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.$notify({
          title: this.translate('refresh_statistic_window.msg.title'),
          message: this.translate('refresh_statistic_window.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
    }
  }
}
</script>
