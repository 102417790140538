<template lang="pug">
  el-dialog(:fullscreen="false" :title="translate('modal.preview')" :visible.sync="visible" :close-on-click-modal="false" width="70%" :custom-class="'dialog-edit-form'")
    vue-json-pretty(:path="'res'" :data="schema")
    div(slot="footer" class="dialog-footer")
      el-button(@click="visible = false") {{ translate('form.cancel') }}
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  data() {
    return {
      visible: false,
      schema: ''
    }
  },

  mounted() {
    this.$elItem.$on('previewSchemaDialog', (params) => {
      this.schema = params.schema
      this.visible = true
    })
  },

  beforeDestroy() {
    this.$elItem.$off('previewSchemaDialog')
    this.schema = ''
  }
}
</script>
