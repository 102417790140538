import paramsTable from './params.js'
import formats from '@/utils/formats.js'

const Table = {
  data() {
    return {
      loading: false,
      isReady: false,
      multipleSelection: [],
      pagination: {
        page: 1,
        limit: 25,
        cursor: null
      }
    }
  },

  mixins: [paramsTable],

  provide() {
    return {
      '$table': this
    }
  },

  created() {
    this.actualizeSort()
  },

  mounted() {
    this.pagination.limit = parseInt(this.$route.query.limit) || this.config.paginationSize
    this.pagination.cursor = this.$route.query.cursor || null
    if (this.isCombined) {
      this.isReady = null
    }
  },

  watch: {
    loading(val) {
      if (!val && this.isReady === false) {
        this.isReady = true
      }
    }
  },

  computed: {
    _reservedColumns() {
      return [
        'selection',
        'actions',
        'expand'
      ]
    },

    _reservedDirs() {
      return ['asc', 'desc']
    },

    tableFields() {
      if (this.config.table.items.length > 0) {
        return this.config.table.items
      } else {
        return this.records[0] ? Object.keys(this.records[0]).map((i) => { return { name: i } }) : []
      }
    },

    tableFieldNames() {
      return this.tableFields.map(i => i.name)
    },

    rowActions() {
      return this.config.actions.row.filter((i) => this.checkPermission(i.roles || []))
    },

    defaultTableSort() {
      const mapping = {
        asc: 'ascending',
        desc: 'descending'
      }
      return {
        prop: this.pagination.sort,
        order: mapping[this.pagination.dir]
      }
    },

    isCombined() {
      return this.config.isCombined === true
    }
  },

  methods: {
    /* Actions & Events */
    eventExpandChange(row, expandedRows) {},

    eventSelectionChange(val) {
      this.multipleSelection = val
      this.$elItem.$emit(this.$channels.tableSelectionChange, val.length > 0)
    },

    async eventSortChange(data) {
      const sort = await this._getSortColumn(data.prop)
      const dir = await this._getSortDirection(data.order ? (data.order === 'descending' ? 'desc' : 'asc') : null)

      switch(this.config.table.sortType) {
        case 'remote':
          await this._sortChangeRemote(sort, dir)
          break
        case 'local':
          await this._sortChangeLocal(sort, dir)
          break
        default:
          throw new Error('Invalid table sortType!')
      }
    },

    actionDelete() {
      this.$confirm(this.$t('alert.delete_confirm'), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        await this.beforeDeleteCallback(this.multipleSelection)
        this.$store.dispatch(`${this.storeName}/delete`, this.buildParams('delete')).then(async(res) => {
          await this.$elItem.reloadDependentTabs()
          await this.afterDeleteCallback(this.multipleSelection)
          this.parentItemComponent().$emitAction('refresh')
          this.$notify({
            title: this.$t('alert.delete_record'),
            message: this.$t('alert.success'),
            type: 'success',
            duration: 2000
          })
        }).catch((e) => {
          this.$message({
            message: e,
            type: 'error',
            dangerouslyUseHTMLString: true
          })
        })
      }).catch((e) => {
        this.$message({
          message: e,
          type: 'error'
        })
      })
    },

    /* Row & Cell */
    isSelectable(row, index) {
      return true
    },
    indexMethod(index) {
      return index + 1
    },

    renderCell(field, row) {
      if(field.beforeRender) {
        return field.beforeRender(row[field.name], field.name, row)
      }

      let value = row[field.name]
      if(field.format) {
        if(formats[field.format]) {
          value = formats[field.format](row[field.name], field, this)
        } else{
          console.log(''.concat('Field format not exist ', field.format, ' for ', field.name))
        }
      }

      if (field.default !== undefined && (value === '' || value === null || value === undefined)) {
        value = field.default
      }

      return value
    },
    rowClassName({ row, rowIndex }) {
      return ''
    },

    cellClassName({ row, column, rowIndex, columnIndex }) {
      return ''
    },

    cellStyle({ row, column, rowIndex, columnIndex }) {
      return {}
    },

    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      return ''
    },

    headerRowClassName({ row, rowIndex }) {
      return ''
    },

    labelClassName(field) {
      if(field.breakWord) {
        return 'break-word'
      }
      return ''
    },

    cellClick( row, column, event ) {
      return ''
    },

    cellDblClick( row, column, cell, event ) {
      return ''
    },

    formatCell({ row, $column, column, cellValue, index }) {
      return cellValue
    },

    isDisabledAction(action, row) {
      return false
    },

    sortableColumnType(field) {
      if (field.sortable) {
        return this.config.table.sortType === 'remote' ? 'custom' : true
      }
      return false
    },

    /* Other */
    async beforeDeleteCallback(rows) {},
    async afterDeleteCallback(rows) {},

    async actualizeSort() {
      let sort = this._getSortColumn(this.pagination.sort) || this._getSortColumn(this.$route.query.sort)
      let dir = this._getSortDirection(this.pagination.dir) || this._getSortDirection(this.$route.query.dir)
      if (sort && dir) {
        this.pagination.sort = sort
        this.pagination.dir = dir
      } else {
        this.pagination.sort = null
        this.pagination.dir = null
      }
      if (this.pagination.page === null) {
        this.pagination.page = parseInt(this.$route.query.page) || 1
      }
    },

    _getSortColumn(value) {
      const defautSortColumn = this.config.table.defaultSort.column
      value = value || defautSortColumn
      if (!value) {
        return
      }
      const tableColumnNames = this.tableFieldNames.filter(i => !this._reservedColumns.includes(i))
      if (!tableColumnNames.includes(value)) {
        return
      }
      return value
    },

    _getSortDirection(value) {
      const defautSortDir = this.config.table.defaultSort.dir
      value = value || defautSortDir
      if (!value) {
        return
      }
      if (!this._reservedDirs.includes(value)) {
        return
      }
      return value
    },

    async _sortChangeRemote(sort, dir) {},
    async _sortChangeLocal(sort, dir) {},

    attr(column) {
      return {
        column
      }
    }
  }
}

export default Table
