import { render, staticRenderFns } from "./../templates/panel_bottom.pug?vue&type=template&id=b8c2d05a&lang=pug&"
import script from "./panel_bottom.vue?vue&type=script&lang=js&"
export * from "./panel_bottom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports