export default {
  iata_portal: {
    form: {
      fields: {
        ticketNumbers: 'Ticket numbers',
        ticketNumber: 'Ticket number',
      },
      legends: {
        main: 'Main filter',
        extended: 'Extended filter'
      },
    },
    table: {
      ticketNumbers: 'Ticket numbers',
      filename: 'Filename',
      error: 'Error',
      cacheKey: 'Key'
    },
    action: {
      download: 'Download',
      upload: 'Upload'
    },
    alert: {
      upload_confirm: 'Are your sure?'
    }
  }
}
