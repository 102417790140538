<template lang="pug">
  extends /table
</template>

<script>

import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    actionMmbLink(row) {
      if (row.mmbLink) window.open(row.mmbLink, '_blank')
      else this.$message({ message: 'No MMB Link', type: 'error' })
    },

    actionMarkAsNew() {
      this.updateStatus(0, 'alert.mark_as_new_confirm')
    },

    actionMarkAsAwaiting() {
      this.updateStatus(1, 'alert.mark_as_awaiting_confirm')
    },

    actionMarkAsSuccess() {
      this.updateStatus(2, 'alert.mark_as_success_confirm')
    },

    updateStatus(status, message) {
      this.$confirm(this.translate(message), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        try {
          const query = {
            __args: {
              ids: this.multipleSelection.map((i) => { return parseInt(i.id) }),
              status: status
            }
          }

          await this.graphql('mcoKiwiQueueLocatorUpdateStatus', query, 'mutation')

          this.$message({
            message: 'Success!',
            type: 'success'
          })
          this.$elItem.$emitAction('refresh')
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }).catch((e) => {})
    },

    rowClassName({ row, rowIndex }) {
      switch(true) {
        case (row.status != 2 && row.followers.length > 1):
          return 'danger-row'
        case (row.status == 1):
          return 'warning-row'
        case (row.status == 2):
          return 'success-row'
        default:
          return ''
      }
    }
  }
}
</script>