export default {
  namespace: 'core',
  component: 'history',
  meta: {
    title: 'core.history',
    icon: 'calendar4-week'
  },
  tabs: [
    {
      name: 'history',
      meta: {
        title: 'history'
      },
      items: [
        {
          name: 'global',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'loadHistory',
            queries: {
              index: {
                time: true,
                recordClass: true,
                recordId: true,
                operation: true,
                userLogin: true,
                changes: true
              }
            },
            actions: {
              index: 'loadHistory'
            },
          },
          table: {
            items: [
              {
                name: 'time',
                width: '140px',
              },
              {
                name: 'userLogin',
                width: '120px'
              },
              {
                name: 'operation',
                width: '100px'
              },
              {
                name: 'recordId',
                width: '100px'
              },
              {
                name: 'recordClass'
              },
              {
                name: 'changes'
              }
            ]
          },
          actions: {
            row: [],
            panel: [
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'userLogin',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'time',
                operator: 'eq',
                type: 'datetimerange',
                required: false,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                format: 'yyyy-MM-dd HH:mm:ss'
              },
              {
                name: 'operation',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'recordId',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'recordClass',
                type: 'text',
                operator: 'eq'
              },
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'entity',
                type: 'text',
                required: true
              },
              {
                name: 'setting_json',
                required: true,
                type: 'jsoneditor',
                options: {
                  modes: ['text', 'code', 'tree', 'form', 'view'],
                  mode: 'code',
                  ace: ace
                }
              }

            ]
          }
        }
      ]
    }
  ]
}
