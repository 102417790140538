<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:depotId="scope")
      div {{ scope.row['depotId'] == 0 ? 'All' : `${depots[scope.row['depotId']]} [${scope.row['depotId']}]`}}
</template>

<script>

import BaseTable from '@crud_view/table'
export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  data() {
    return {
      depots: {}
    }
  },
  created() {
    this.depots = this.$store.getters['bootData']['depots'].reduce((res, v) => ({ ...res, [v.id]: v.name }), {})
  },
  methods: {
    actionClearCache() {
      try {
        const query = {
          __args: {
            ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
          }
        }

        this.graphql('railwayRailSettingClearCache', query, 'mutation')

        this.$message({
          message: 'Success!',
          type: 'success'
        })
        // this.$elItem.$emitAction('refresh')
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    }
  }
}
</script>