export default {
  name: 'access',
  items: [
    {
      name: 'agents',
      endpoint: '/persistance/webscraper',
      lazy: true,
      type: 'graphql',
      graphql: {
        name: 'apiAgents',
        queries: {
          index: {
            id: true,
            name: true,
            apiKey: true,
            webhookUrl: true,
            active: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '80px'
          },
          'name',
          'apiKey',
          'webhookUrl',
          {
            name: 'active',
            type: 'switch'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '130px',
            align: 'center'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'name',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'apiKey',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                }
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'name',
                type: 'text'
              },
              {
                name: 'webhookUrl',
                type: 'text',
                required: false
              },
              {
                name: 'active',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        ]
      }
    }
  ]
}
