export default {
  row: [
    {
      name: 'update',
      icon: 'el-icon-edit',
      type: 'primary',
      plain: true
    }
  ],
  panel: [
    {
      name: 'create',
      icon: 'el-icon-plus',
      type: 'primary',
      plain: false
    },
    {
      name: 'refresh',
      icon: 'el-icon-refresh',
      type: 'primary',
      plain: true
    },
    {
      name: 'history',
      icon: 'el-icon-refresh',
      type: 'warning',
      plain: true,
      params: {
        record_class: 'Avia::FareRule::TemplateRelation'
      }
    },
    {
      name: 'filter',
      context: 'panel',
      icon: 'el-icon-help',
      plain: true
    },{
      name: 'delete',
      icon: 'el-icon-delete',
      type: 'danger',
      plain: true,
      dependent: 'selection'
    }
  ]
}