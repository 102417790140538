import currencies_list from '../../../general/currencies_list'

export default {
  legend: 'penalty_before_departure',
  translateLegend: true,
  items: [
    {
      name: 'penaltyBeforeDepartureAmount',
      type: 'text',
      valueType: 'float',
      required: false
    },
    {
      name: 'penaltyBeforeDepartureCurrency',
      type: 'select',
      store: Object.assign({'%': '%'}, currencies_list),
      required: false
    },
    {
      name: 'penaltyBeforeDepartureNonRef',
      type: 'checkbox',
      default: false,
      required: false
    }
  ]

}