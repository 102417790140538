export default {
  name: 'pricing',
  items: [
    {
      name: 'report_pricing_data',
      endpoint: '/persistance/rankings',
      autoLoad: false,
      type: 'graphql',
      graphql: {
        name: 'reportPricingData',
        queries: {
          index: {
            routeKey: true,
            currency: true,
            ttl: true,
            items: true
          }
        },
        actions: {
          preload: 'commonRankingsPreload'
        },
        preloadFields: ['markets{id name}']
      },
      preload: true,
      preloadStores: ['markets'],
      table: {
        items: [
          'routeKey',
          'currency',
          'ttl',
          'expand'
        ]
      },
      actions: {
        row: [],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: '',
            items: [
              {
                name: 'market',
                operator: 'eq',
                type: 'select',
                storeKey: 'markets',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                default: 'UA',
                required: true
              },
              {
                name: 'route_key',
                operator: 'eq',
                type: 'text'
              }
            ]
          }
        ]
      },
      form: {
        items: []
      }
    },
    {
      name: 'report_original_data',
      endpoint: '/persistance/rankings',
      lazy: true,
      autoLoad: false,
      type: 'graphql',
      graphql: {
        name: 'reportOriginalData',
        queries: {
          index: {
            sessionCreatedOn: true,
            currency: true,
            routeKey: true,
            data: true
          }
        },
        actions: {
          preload: 'commonRankingsPreload'
        },
        preloadFields: ['markets{id name}']
      },
      preload: true,
      preloadStores: ['markets'],
      table: {
        items: [
          'sessionCreatedOn',
          'routeKey',
          'currency',
          'expand'
        ]
      },
      actions: {
        row: [],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'filter',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: '',
            items: [
              {
                name: 'market',
                operator: 'eq',
                type: 'select',
                storeKey: 'markets',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                default: 'UA',
                required: true
              },
              {
                name: 'route_key',
                operator: 'eq',
                type: 'text'
              }
            ]
          }
        ]
      },
      form: {
        items: []
      }
    }
  ]
}
