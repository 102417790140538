<template lang="pug">
  extends /table
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatCell({ row, $column, column, cellValue, index }) {
      switch(column.name) {
        case 'baggageDetails':
          return Object.keys(cellValue).join(',')
        default:
          return cellValue
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 3:
          return { 'color': (row['active'] ? 'green' : 'red') }
        default:
          return {}
      }
    }
  }
}
</script>