<script>
  import BaseReportTable from '../../../../avia/general/reports/table'

  export default {
    components: {
      BaseReportTable
    },
    computed: {

    },
    extends: BaseReportTable,
    methods: {

    }
  }
</script>
