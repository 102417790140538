import searchPesimisationRules from './search_settings/pesimisation_rules'

export default {
  namespace: 'avia',
  component: 'search_settings',
  meta: {
    title: 'avia.search_settings',
    icon: 'skill'
  },
  tabs: [
    searchPesimisationRules
  ]
}
