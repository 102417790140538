<style scoped>
  .rs-dv{ padding-bottom: 5px;  }
  .rs-label{ font-weight: bold; padding-right: 10px; display: inline-block; width: 120px}
  .rs-value{}
  .rs-date{ width: 72px; display: inline-block; padding-right: 10px }
  .rs-tx-left{ text-align: right }
</style>

<template lang="pug">
  extends /table
  block expand
    div(class="rs-dv")
      span(class="rs-label") Route type:
      span(class="rs-value") {{ scope.row.routeTypes.map(x => from_options(x, {name: 'routeTypes'}, _self)).join(', ') }}
    div(class="rs-dv")
      span(class="rs-label") Departure period:
      span(class="rs-value") {{ scope.row.departurePeriods }}
    div(class="rs-dv")
      span(class="rs-label") Config IDs:
      span(class="rs-value") {{ scope.row.configIds }}
    div(class="rs-dv")
      span(class="rs-label") Google feed:
      span(class="rs-value") {{ scope.row.forGoogleFeed }}
    div(class="rs-dv")
      span(class="rs-label") TtnAviaVI:
      span(class="rs-value") {{ scope.row.forTtnAviaVi }}
    div(class="rs-dv")
      span(class="rs-label") Routes:
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")

  block custom

</template>

<script>
import formats from '@/utils/formats.js'
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    ...formats,
    formatExpandedRow(row) {
      var res = []
      var routes = row['routes'].split(';')
      routes.forEach(v => {
        res.push(v.replace('-', '<font color=red> -- </font>'))
      })
      return res.join('<br/>')
    }
  }
}
</script>
