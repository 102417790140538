export default {
  name: 'seats_block_analytics',
  meta: {
    title: 'Reports',
    icon: 'skill',
    permission: 'pesimisation_rules'
  },
  lazy: true,
  items: [
    {
      name: 'seats_block_analytics',
      endpoint: '/persistance/default',
      type: 'graphql',
      autoLoad: false,
      graphql: {
        name: 'aviaSeatsBlockAnalyticItems',
        queries: {
          index: {
            id: true,
            route: true,
            flight: true,
            date: true,
            remainedSeats: true,
            blockPrice: true,
            blockPriced: true,
            blockPricedDifference: true,
            cheapestPrice: true,
            cheapestPriceDifference: true,
            cheapestDayPrice: true,
            cheapestDayPriceDifference: true,
            cheapestNightPrice: true,
            cheapestNightPriceDifference: true,
            error: true,
            sessionId: true,
            userToken: true,
            currency: true,
            isCorrect: true,
            redirectUrl: true,
            active: true,
            recommendedMarkups: true,
            depotId: true,
            recommendations: {
              flight: true,
              departureTime: true,
              basePrice: true,
              sellingPrice: true,
              validatingSupplier: true,
              isMulti: true,
              gdsName: true,
              configId: true,
              recType: true,
              markups: true,
              blockPriceDifference: true,
              pricesInOtherGds:{
                sellingPrice: true,
                configId: true,
                gdsName: true,
                cardAllowed: true,
                multiTicket: true,
                blockPriceDifference: true,
                recommendationDifference: true
              }
            }
          }
        }
      },
      table: {
        items: [
          // 'selection',
          {
            name: 'route',
            width: '80px',
            sortable: false
          },
          {
            name: 'flight',
            sortable: false
          },
          {
            name: 'date',
            width: '80px',
            sortable: false
          },
          {
            name: 'remained_seats',
            width: '50px',
            sortable: false
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px',
            sortable: false
          },
          {
            name: 'block_price',
            width: '70px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'block_priced',
            width: '70px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'block_priced_difference',
            width: '60px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'cheapest_price',
            width: '70px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'cheapest_price_difference',
            width: '60px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'cheapest_day_price',
            width: '70px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'cheapest_day_price_difference',
            width: '60px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'cheapest_night_price',
            width: '70px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'cheapest_night_price_difference',
            width: '60px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'recommended_markups',
            width: '60px',
            sortable: false,
            format: 'formatted_integer'
          },
          {
            name: 'actions',
            width: '90px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'redirect',
            icon: 'el-icon-right',
            type: 'success',
            plain: true,
            roles: false
          },
          {
            name: 'change_markups',
            icon: 'el-icon-magic-stick',
            type: 'warning',
            plain: true
          }
        ],
        panel: [
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'depot_id',
            type: 'select',
            operator: 'eq',
            storeKey: 'depots',
            storePrimaryKey: 'id',
            storeLabelKey: 'name'
          },
          {
            name: 'remained_seats',
            type: 'text',
            operators: ['eq', 'gt', 'gte', 'lt', 'lte']
          },
          {
            name: 'validating_supplier',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'contract_id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'route_key',
            type: 'text',
            operator: 'like'
          },
          {
            name: 'departure_date',
            operator: 'btw',
            type: 'daterange',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false
          },
          {
            name: 'active',
            type: 'radiogroup',
            button: true,
            default: 'all',
            operator: 'eq',
            store: {
              'all': 'All',
              true: 'Yes',
              false: 'No'
            }
          },
          {
            name: 'just_direct',
            type: 'radiogroup',
            button: true,
            default: true,
            operator: 'eq',
            store: {
              true: 'Yes',
              false: 'No'
            }
          },
          {
            name: 'with_problem_only',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            default: false,
            store: {
              true: 'Yes',
              false: 'No'
            }
          }
        ]
      },
      combined: [
        {
          name: 'seats_block_analytics_recommendations',
          boot: {
            type: 'local',
            row_key: 'recommendations'
          },
          table: {
            items: [
              {
                name: 'validatingSupplier',
                width: '100px',
                sortable: false
              },
              {
                name: 'configId',
                width: '80px',
                sortable: false
              },
              {
                name: 'gdsName',
                sortable: false
              },
              {
                name: 'flight',
                sortable: false
              },
              {
                name: 'departureTime',
                width: '80px',
                sortable: false
              },
              {
                name: 'basePrice',
                format: 'formatted_integer',
                width: '75px',
                sortable: false
              },
              {
                name: 'sellingPrice',
                format: 'formatted_integer',
                width: '75px',
                sortable: false
              },
              {
                name: 'markups',
                format: 'formatted_integer',
                width: '70px',
                sortable: false
              },
              {
                name: 'blockPriceDifference',
                format: 'formatted_integer',
                width: '70px',
                sortable: false
              },
              {
                name: 'expand',
                type: 'expand',
                width: '30px'
              }
            ]
          },
          combined: [
            {
              name: 'seats_block_analytics_in_other_gds',
              boot: {
                type: 'local',
                row_key: 'pricesInOtherGds'
              },
              table: {
                items: [
                  {
                    name: 'configId',
                    width: '80px',
                    sortable: false
                  },
                  {
                    name: 'gdsName',
                    sortable: false
                  },
                  {
                    name: 'cardAllowed',
                    width: '120px',
                    sortable: false
                  },
                  {
                    name: 'multiTicket',
                    width: '120px',
                    sortable: false
                  },
                  {
                    name: 'sellingPrice',
                    format: 'formatted_integer',
                    width: '75px',
                    sortable: false
                  },
                  {
                    name: 'recommendationDifference',
                    format: 'formatted_integer',
                    width: '70px',
                    sortable: false
                  },
                  {
                    name: 'blockPriceDifference',
                    format: 'formatted_integer',
                    width: '80px',
                    sortable: false
                  },
                ]
              }
            }
          ]
        }
      ]
    },
    {
      name: 'seats_block_pricer_rules',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaSeatsBlockPricerRules',
        queries: {
          index: {
            id: true,
            index: true,
            active: true,
            validatingSupplier: true,
            contractId: true,
            routes: true,
            flightNumbers: true,
            comment: true,
            items: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'index'
          },
          {
            name: 'validatingSupplier',
            width: '70px',
            sortable: false
          },
          {
            name: 'routes'
          },
          {
            name: 'flightNumbers',
            width: '150px',
            sortable: false,
            format: 'array_as_string'
          },
          {
            name: 'contractId',
            width: '120px'
          },
          {
            name: 'comment'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '70px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::SeatsBlock::PricerRuleRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'validating_supplier',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'contract_id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'routes',
            type: 'text',
            operator: 'like'
          },
          {
            name: 'comment',
            type: 'text',
            operator: 'like'
          },
          {
            name: 'active',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              true: 'Yes',
              false: 'No'
            }
          }
        ]
      },
      form: {
        actualize: true,
        windowWidth: '1024px',
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'items',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'routes',
            required: false
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'validating_supplier',
            required: true
          },
          {
            name: 'contract_id',
            required: false
          },
          {
            name: 'comment',
            type: 'textarea',
            required: false,
            rows: 3
          },
          {
            name: 'index',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'flight_numbers',
            required: false,
            valueType: 'array_of_integer'
          },
          {
            name: 'items__index',
            required: true,
            valueType: 'integer',
            ignore: true
          },
          {
            name: 'items__from_price',
            type: 'select',
            storeLabelKeys: ['name'],
            store: [
              { id: 0, name: 'cheapest' },
              { id: 1, name: 'daily' },
              { id: 2, name: 'nigth' }
            ],
            default: 0,
            style: 'width: 300px',
            // valueType: 'integer',
            required: false,
            ignore: true
          },
          {
            name: 'items__just_direct',
            type: 'checkbox',
            default: true,
            required: false,
            ignore: true
          },
          {
            name: 'items__days_before_departure',
            required: true,
            ignore: true
          },
          {
            name: 'items__max_discount',
            required: true,
            valueType: 'float',
            ignore: true
          },
          {
            name: 'items__minus_value',
            required: true,
            valueType: 'float',
            ignore: true
          },
          {
            name: 'items__minus_other_gds_value',
            required: true,
            valueType: 'float',
            ignore: true
          },
          {
            name: 'items__minus_baggage_rec_value',
            required: true,
            valueType: 'float',
            ignore: true
          },
          {
            name: 'items__max_markup',
            required: true,
            valueType: 'float',
            ignore: true
          },
          {
            name: 'items__week_days',
            type: 'multiselect',
            store: [
              { id:1, name:'Mon' }, { id:2, name:'Tue' }, { id:3, name:'Wed' },
              { id:4, name:'Thu' }, { id:5, name:'Fri' }, { id:6, name:'Sat' },
              { id:0, name:'Sun' }
            ],
            storeLabelKeys: ['id', 'name'],
            default: [0,1,2,3,4,5,6],
            ignore: true
          },
          {
            name: 'items__remainedSeatsRange',
            required: true,
            ignore: true
          },
        ]
      },
      combined:[
        {
          name: 'seats_block_pricer_rules_items',
          boot: {
            type: 'local',
            row_key: 'items'
          },
          table: {
            items: [
              {
                name: 'index',
                width: '100px',
                sortable: false
              },
              {
                name: 'days_before_departure',
                sortable: false
              },
              {
                name: 'just_direct',
                sortable: false
              },
              {
                name: 'max_discount',
                sortable: false
              },
              {
                name: 'minus_value',
                width: '80px',
                sortable: false
              },
              {
                name: 'minus_other_gds_value',
                width: '80px',
                sortable: false
              }
            ]
          },

        }
      ]
    }
  ]
}
