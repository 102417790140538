export default {
  config: {
    table: {
      id: 'ID',
      key: 'Key',
      depotId: 'Depot',
      version: 'Version',
      ttl: 'Ttl (min)',
      setting: 'Setting'
    },
    form: {
      fields: {
        id: 'ID',
        key: 'Key',
        depotId: 'Depot',
        version: 'Version',
        ttl: 'Ttl (min)',
        setting: 'Setting'
      },
    },
    action: {
      change_settings: 'Change settings',
      mass_change: 'Mass change'
    },
    changeSettingsWindow: {
      title: 'Change settings',
      description: 'Description: config entity - ',
      msg: {
        title: 'Message',
        load_error: 'Settings not loaded',
        for_error_fields: 'Errors in fields: ',
        success_config: 'Config changed'
      },
      form: {
        frontend_configs: {
          cacheStorageUrl: 'Redis URL',
          cachePrefix: 'Cache prefix'
        }
      }
    },
    massChangeWindow: {
      title: 'Mass change',
      description: 'Format:',
      msg: {
        title: 'Message',
        load_error: 'Settings not loaded',
        for_error_fields: 'Errors in fields: ',
        success_config: 'Mass change started with session ID:'
      },
      form: {
        values: 'Values'
      }
    }
  }
}
