<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:depotId="scope")
      div {{ scope.row['depotId'] == 0 ? 'All' : `${depots[scope.row['depotId']]} [${scope.row['depotId']}]`}}
</template>

<script>

import BaseTable from '@crud_view/table'
export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  data() {
    return {
      depots: {}
    }
  },
  created() {
    this.depots = this.$store.getters['bootData']['depots'].reduce((res, v) => ({ ...res, [v.id]: v.name }), {})
  }
}
</script>