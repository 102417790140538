<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='10' class='row')
          ttn-input(v-model="temp['name']" v-bind="attr('name')" label-width='70px')
        el-col(:span='14' class='row')
          ttn-input(v-model="temp['description']" v-bind="attr('description')" label-width='100px')
      el-row
        el-col(:span='10' class='row')
          ttn-select(v-model="temp['reportType']" v-bind="attr('reportType')" label-width='120px')
        el-col(:span='14' class='row')
          ttn-input(v-model="temp['sessionId']" v-bind="attr('sessionId')" label-width='120px')

      block reportParams
        el-row
          el-col(:span='12' class='row')
            ttn-date-picker(v-model="temp['reportParams__startDate']" v-bind="attr('reportParams__startDate')" label-width='120px')
          el-col(:span='12' class='row')
            ttn-date-picker(v-model="temp['reportParams__endDate']" v-bind="attr('reportParams__endDate')" label-width='120px')


</template>

<script>
  import BaseReportForm from '../../../../avia/general/reports/form'

  export default {
    components: {
      BaseReportForm
    },
    computed: {

    },
    extends: BaseReportForm,
    methods: {

    }
  }
</script>
