export default {
  name: 'aviaFareFamilyServicesSettings',
  exclude: ['actions', 'expand'],
  queries: {
    index: {
      id: true,
      priority: true,
      validatingSupplier: true,
      fareName: true,
      active: true,
      gdsId: true,
      departureFrom: true,
      arrivalTo: true,
      carrier: true,
      flight: true
    }
  }
}