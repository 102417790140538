<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    el-input(
      v-model="dataValue"
      :placeholder="translate(`form.placeholder.${field.name}`, true)"
      :disabled="isDisabledField(field, fieldset.legend)"
      @change="changeFieldValue(field, $event)"
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
</template>

<script>

import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnTextarea',
  mixins: [formElements],
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        clearable: true,
        type: field.type,
        rows: field.rows
      }
    }
  }
}
</script>
