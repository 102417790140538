export default {
  rail_controls_list: {
    table: {
      id: 'ID',
      depotId: 'Depot ID',
      providerName: 'Provider Name',
      active: 'Active',
      percent: 'Percent',
      depotType: 'Depot Type',
      countryCodes: 'Country Codes'
    },
    form: {
      fields: {
        depotId: 'Depot ID',
        providerName: 'Provider Name',
        active: 'Active',
        percent: 'Percent',
        depotType: 'Depot Type',
        countryCodes: 'Country Codes'
      }
    }
  }
}