<template lang="pug">
  el-dialog(:fullscreen="false" :title="translate('modal.title')" :visible.sync="visible" :close-on-click-modal="true" width="50%")
    div(v-html="comment")
    div(slot="footer" class="dialog-footer")
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  data: function() {
    return {
      visible: false,
      comment: null
    }
  },

  mounted() {
    this.$parent.$on('showComment', (comment) => {
      this.comment = comment
      this.visible = true
    })
  },

  beforeDestroy() {
    this.$parent.$off('showComment')
  }
}
</script>
