<template lang="pug">
  extends /table
  block expand
    el-row
      el-col(:span=24)
        custom-table
          custom-row
            custom-cell(width="200px") {{translate('table.startUrl')}}
            custom-cell
              a(style="color:blue", :href="scope.row.startUrl", target="_blank") {{scope.row.startUrl }}
          custom-row
            custom-cell(width="200px") {{translate('table.startValue')}}
            custom-cell
              vue-json-pretty(:path="'res'" :data="JSON.parse(scope.row.startValue || '{}')")
          custom-row
            custom-cell(width="200px") {{translate('table.sourceData')}}
            custom-cell
              vue-json-pretty(:path="'res'" :data="JSON.parse(scope.row.decodedSourceSegments || '{}')")
          custom-row
            custom-cell(width="200px") {{translate('table.data')}}
            custom-cell
              vue-json-pretty(:path="'res'" :data="JSON.parse(scope.row.decodedData || '{}')")
  block custom
    match-variants-dialog(:config="config" :tab="tab" :item="item")
</template>

<script>
import Base from '@crud_view/table'
import MatchVariantsDialog from './match_dialog'

export default {
  components: {
    MatchVariantsDialog
  },
  extends: Base,

  mounted() {
    this.$elItem.$once(this.$channels.hooksAfterList(this.config.tab, this.config.name), this.showMatchDialog)
  },

  beforeDestroy() {
    this.$elItem.$off(this.$channels.hooksAfterList(this.config.tab, this.config.name), this.showMatchDialog)
  },

  methods: {
    rowClassName({ row, rowIndex }) {
      if (row.airlineType === '0') {
        return 'success-row'
      } else if (row.airlineType === '1' && row.withCombination) {
        return 'danger-row'
      } else if (row.airlineType === '1') {
        return 'warning-row'
      }
    },

    isDisabledAction(action, row) {
      if (action.name !== 'check_run_data') {
        return false
      }
      return ['cancelled', 'complete', 'error'].includes(row.status)
    },

    async actionCheckRunData(row) {
      try {
        const query = {
          __args: { id: row.id },
          id: true
        }

        await this.graphql('siteProjectRunCheckDatum', query, 'mutation')
        this.$message({
          message: 'Success!',
          type: 'success'
        })
        this.$elItem.$emitAction('refresh')
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    async actionMatchVariant(row) {
      this.$emit('showMatchVariantsDialog', row)
    },

    async showMatchDialog() {
      const selectedId = parseInt(this.$route.query.with_details_row)
      if (selectedId > 0) {
        const selectedRow = this.records.find((i) => i.id === selectedId)
        if (selectedRow !== undefined) {
          this.$emit('showMatchVariantsDialog', selectedRow)
        }
      }
    }
  }
}
</script>
