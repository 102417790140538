export default {
  packets_risks_list: {
    table: {
      insurancePacketId: 'Packet',
      insuranceRiskId: 'Risk',
      coverage: 'Coverage',
      franchise: 'Franchise',
      active: 'Active',
    },
    form: {
      fields: {
        insurancePacketId: 'Packet ID',
        insuranceRiskId: 'Risk ID',
        coverage: 'Coverage',
        franchise: 'Franchise',
        active: 'Active',
      }
    }
  }
}
