export default {
  name: 'connection_time_rule',
  meta: {
    title: 'connection_time_rule',
    icon: 'el-icon-s-unfold',
    permission: 'connection_time_rule'
  },
  lazy: true,
  items: [
    {
      name: 'connection_time_rule',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'settingsConnectionTimeRule',
        queries: {
          index: {
            id: true,
            location: true,
            oindex: true,
            transferTime: true,
            transferType: true,
            arrivalCarrier: true,
            arrivalCodeshareCarrier: true,
            departureCarrier: true,
            departureCodeshareCarrier: true,
            arrivalAircraft: true,
            departureAircraft: true,
            previousLocation: true,
            nextLocation: true,
            arrivalTerminal: true,
            departureTerminal: true,
            arrivalFlightNumber: true,
            departureFlightNumber: true,
            validFrom: true,
            validTo: true,
            nativeId: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'location',
            width: '80px'
          },
          {
            name: 'oindex',
            width: '70px'
          },
          {
            name: 'transferTime',
            width: '60px'
          },
          {
            name: 'transferType',
            format: 'from_options',
            width: '80px'
          },
          {
            name: 'description'
          },
          // {
          //   name: 'arrivalCarrier',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'arrivalCodeshareCarrier',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'departureCarrier',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'departureCodeshareCarrier',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'arrivalAircraft',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'departureAircraft',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'previousLocation',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'nextLocation',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'arrivalTerminal',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'departureTerminal',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'arrivalFlightNumber',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'departureFlightNumber',
          //   format: 'array_as_string'
          // },
          // {
          //   name: 'validFrom'
          // },
          // {
          //   name: 'validTo'
          // },
          // {
          //   name: 'nativeId'
          // },
          // {
          //   name: 'createdAt'
          // },
          {
            name: 'updatedAt',
            width: '100px'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
         {
           name: 'update',
           icon: 'el-icon-edit',
           type: 'primary',
           plain: true
         }
       ],
       panel: [
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'TtnAviaMultiSearchService::Settings::ConnectionTimeRule'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'location',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'transferTime',
            type: 'text',
            operator: 'eq',
            valueType: 'integer'
          },
          {
            name: 'previousLocation',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'arrivalTerminal',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'arrivalCarrier',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'arrivalCodeshareCarrier',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'arrivalAircraft',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'nextLocation',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'departureTerminal',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'departureCarrier',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'departureCodeshareCarrier',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'departureAircraft',
            type: 'text',
            operator: 'in_array_str'
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'location',
            type: 'text',
            required: true
          },
          {
            name: 'oindex',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'transferTime',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'transferType',
            type: 'select',
            required: true,
            storeLabelKeys: ['id'],
            translateOptions: true,
            default: '1',
            store: [
              {id: '1'},
              {id: '2'},
              {id: '3'},
              {id: '4'},
            ],
            valueType: 'integer'
          },
          {
            name: 'arrivalCarrier',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'arrivalCodeshareCarrier',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'departureCarrier',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'departureCodeshareCarrier',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'arrivalAircraft',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'departureAircraft',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'previousLocation',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'nextLocation',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'arrivalTerminal',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'departureTerminal',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'arrivalFlightNumber',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'departureFlightNumber',
            type: 'text',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'validFrom',
            type: 'date',
            required: false
          },
          {
            name: 'validTo',
            type: 'date',
            required: false
          },
          {
            name: 'nativeId',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          }
        ]
      }
    }
  ]
}
