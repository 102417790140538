<template lang="pug">
  extends /filter
  block fields
    el-row
      el-col(:span="24")
        el-form-item(:label="translate(`form.fields.passengers`)")
          el-col(:span="8")
            ttn-input-number(v-model="filters.adt.value" v-bind="attr('adt')" :form-item-wraper="true" :label-width="'40px'")
          el-col(:span="8")
            ttn-input-number(v-model="filters.chd.value" v-bind="attr('chd')" :form-item-wraper="true" :label-width="'40px'")
          el-col(:span="8")
            div(style="float:left")
              ttn-input-number(v-model="filters.inf.value" v-bind="attr('inf')" :form-item-wraper="true" :label-width="'40px'")
            div(style="margin-right: 10px")
              el-popover(placement="right-start" width="400" trigger="manual" v-model="popoverVisible")
                el-row
                  el-col(:span="24")
                    el-input(type="textarea" :autosize="{ minRows: 4, maxRows: 10}" :placeholder="translate('copy.textarea')" v-model="copyParams")
                el-row
                  el-col(:span="24" style="padding-top: 5px; text-align: right")
                    el-button(type="primary" @click="processCopyParams") {{translate('copy.submit')}}
                el-button(slot="reference" type="success" style="float: right" icon="el-icon-edit" @click="popoverVisible = !popoverVisible")
    el-row
      el-col(:xs="24" :sm="24" :md="24" :lg="12" :xl="12")
        ttn-form-item(v-bind="attr('domain')")
          el-col(:span="12")
            ttn-select(v-model="filters.domain.value" value-key="label" :value-as-object="true" :default-first-option="true" @change="changeDomainKey" v-bind="attr('domain')" )
            ttn-input(v-model="filters.site.value" v-bind="attr('site')")
          el-col(:span="12")
            ttn-input(v-model="filters.key.value" v-bind="attr('key')" :form-item-wraper="true" @keyup.enter.native="actionApplyFilter")
      el-col(:xs="24" :sm="24" :md="24" :lg="12" :xl="12")
        ttn-form-item(v-bind="attr('refid')")
          span(slot="label" class="hidden-lg-and-up") {{translate(`form.fields.refid`)}}
          el-col(:span="12")
            ttn-input-number(v-model="filters.refid.value" v-bind="attr('refid')")
          el-col(:span="12")
            el-button(
              type="default"
              style="width: 100%"
              :loading="apiKeyLoading"
              @click="loadApiKey"
              :disabled="!filters.refid.value || parseInt(filters.refid.value) === 0"
            ) {{translate(`form.fields.loadKeyButton`)}}
    el-row
      el-col(:xs="24" :sm="24" :md="24" :lg="12" :xl="12")
        el-form-item(:label="translate(`form.fields.route`)")
          el-col(:span="11")
            ttn-input(
              v-model="filters.departure.value"
              maxlength="3"
              minlength="3"
              v-bind="attr('departure')"
              :form-item-wraper="true"
              @keyup.enter.native="actionApplyFilter"
              @input="forceUpper($event, filters, 'departure')"
            )
          el-col(:span="2")
            el-button(
              type="success"
              icon="el-icon-sort"
              class="button-rotate"
              plain
              :disabled="!filters.arrival.value || !filters.departure.value"
              @click="toggleAirports"
            )
          el-col(:span="11")
            ttn-input(
              v-model="filters.arrival.value"
              maxlength="3"
              minlength="3"
              v-bind="attr('arrival')"
              :form-item-wraper="true"
              @keyup.enter.native="actionApplyFilter"
              @input="forceUpper($event, filters, 'arrival')"
            )
      el-col(:xs="24" :sm="24" :md="24" :lg="12" :xl="12")
        el-form-item(label="")
          el-col(:span="12")
            ttn-date-picker(
              v-model="filters.departure_date.value"
              v-bind="attr('departure_date')"
              :form-item-wraper="true"
              :picker-options="firstDateOptions"
            )
          el-col(:span="12")
            ttn-date-picker(
              v-model="filters.arrival_date.value"
              v-bind="attr('arrival_date')"
              :picker-options="secondDateOptions"
              :default-value="dateToDefaultDate()"
            )
    el-row
      el-col(:span="24")
        el-form-item(label="")
          el-col(:span="12")
            ttn-input(v-model="filters.airline.value" v-bind="attr('airline')" @keyup.enter.native="actionApplyFilter")
          el-col(:span="12")
            el-col(:span="12")
              ttn-select(v-model="filters.class.value" v-bind="attr('class')")
            el-col(:span="12")
              ttn-switch(v-model="filters.with_link.value" :active-text="translate(`form.fields.withLink`)" v-bind="attr('with_link')")
    el-row
      el-col(:span="24")
        el-form-item(:label="translate(`form.fields.reportType`)")
          el-col(:span="12")
            ttn-radio-group(v-model="filters.report_type.value" v-bind="attr('report_type')")
          el-col(:span="12")
            ttn-input(v-model="filters.server_domain.value" v-bind="attr('server_domain')" :form-item-wraper="true" @keyup.enter.native="actionApplyFilter")
              ttn-select(v-model="filters.server_scheme.value" v-bind="attr('server_scheme')" :form-item-wraper="false" slot="prepend" style="width: 70px")
      el-col(:span="24")
        el-form-item(label="")
          el-col(:span="12")
            ttn-radio-group(v-model="filters.search_type.value" v-bind="attr('search_type')")
          el-col(:span="12")
            ttn-input(v-model="filters.configs.value" v-bind="attr('configs')" @keyup.enter.native="actionApplyFilter")

</template>

<script>
import BaseFilter from '@crud_view/filter'
import uppercase from '@/directive/uppercase'

export default {
  directives: { uppercase },
  components: {
    BaseFilter
  },
  extends: BaseFilter,
  data() {
    let __self = this
    return {
      apiKeyLoading: false,
      firstDateOptions: {
        firstDayOfWeek: 1,
        disabledDate: (time) => {
          let start = new Date()
          start.setHours(0, 0, 0, 0)
          return time.getTime() < start
        }
      },
      secondDateOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          if (__self.filters.departure_date.value) {
            return time.getTime() < new Date(__self.filters.departure_date.value)
          } else {
            return true
          }
        }
      },

      copyParams: '',
      popoverVisible: false
    }
  },
  methods: {
    async loadApiKey() {
      try {
        this.apiKeyLoading = true
        const query = {
          __args: { refid: this.filters.refid.value }
        }
        const res = await this.graphql('systemToolsThoughtsOfSalesSearchLoadKey', query, 'mutation')
        this.filters.key.value = res
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.$nextTick(() => {
        this.apiKeyLoading = false
      })
    },

    forceUpper(e, o, prop) {
      this.$set(o[prop], 'value', e.toUpperCase())
    },

    toggleAirports() {
      const dep = this.filters.departure.value
      this.filters.departure.value = this.filters.arrival.value
      this.filters.arrival.value = dep
    },

    changeDomainKey(value) {
      this.filters.key.value = value.id
      this.filters.site.value = value.label
      this.filters = { ...this.filters }
    },

    processCopyParams() {
      try {
        const data = JSON.parse(this.copyParams)
        const mapper = {
          from: 'departure',
          date_from: 'departure_date',
          to: 'arrival',
          date_to: 'arrival_date'
        }
        for (let i in data) {
          const key = mapper[i] || i
          this.filters[key].value = data[i]
        }
        this.filters = { ...this.filters }
        this.copyParams = ''
        this.popoverVisible = false
      } catch(err) {
        this.exception(err)
        this.$message({
          message: this.translate('copy.invalid_format'),
          type: 'error'
        })
      }
    },

    dateToDefaultDate() {
      return this.filters.departure_date.value || new Date()
    }
  }
}
</script>

<style lang="scss">
  #pane-search {
    .button-rotate {
      width: 100%;
      i {
        transform: rotate(90deg);
      }
    }
  }
</style>
