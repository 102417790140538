export default {
  agencies: {
    agencies_list: {
      table: {
        name: 'Name',
        url: 'URL',
        timezone: 'Timezone',
        language: 'Language'
      },
      form: {
        fields: {
          id: 'ID',
          name: 'Name',
          url: 'URL',
          timezone: 'Timezone',
          language: 'Language'
        }
      }
    }
  },
  calendar_dates: {
    calendar_dates_list: {
      table: {
        serviceId: 'Train ID',
        date: 'Date'

      },
      form: {
        fields: {
          serviceId: 'Train ID',
          date: 'Date'
        }
      }
    }
  },
  routes: {
    routes_list: {
      table: {
        agencyName: 'Agency',
        shortName: 'Train Number',
        longName: 'Train Route',
        routeType: 'Route Type',
        origin: 'Origin',
        originCode: 'Origin(code)',
        destination: 'Destination',
        destinationCode: 'Destination(code)'
      },
      form: {
        fields: {
          id: 'ID',
          agencyId: 'Agency ID',
          shortName: 'Train Number',
          longName: 'Long Name',
          routeType: 'Route Type'
        }
      }
    }
  },
  stop_times: {
    stop_times_list: {
      table: {
        tripId: 'Trip ID',
        shortName: 'Train Number',
        headsign: 'Headsign',
        date: 'Train Departure Date',
        arrivalDatetime: 'Arrival Time',
        departureDatetime: 'Departure Time',
        stopSequence: 'Stop Sequence',
        stopId: 'Stop ID',
        stopName: 'Station Name',
        stopCode: 'Station Code',
        shapeDistTraveled: 'Distance (km)'
      },
      form: {
        fields: {
          id: 'ID',
          tripId: 'Trip ID',
          stopId: 'Stop ID',
          arrivalTime: 'Arrival Time',
          departureTime: 'Departure Time',
          stopSequence: 'Stop Sequence',
          shapeDistTraveled: 'Distance (km)'
        }
      }
    }
  },
  stops: {
    stops_list: {
      table: {
        code: 'Code',
        name: 'Name',
        lat: 'Latitude',
        lon: 'Longitude',
        locationType: 'Location Type',
        parentStation: 'Parent Station',
        timezone: 'Timezone'
      },
      form: {
        fields: {
          id: 'Stop ID',
          code: 'Station Code',
          name: 'Station Name',
          lat: 'Latitude',
          lon: 'Longitude',
          locationType: 'Location Type',
          parentStation: 'Parent Station',
          timezone: 'Timezone'
        }
      }
    }
  },
  transfers: {
    transfers_list: {
      table: {
        fromStopId: 'From Stop ID',
        toStopId: 'To Stop ID',
        type: 'Type',
        minTransferTime: 'Minimum Transfer Time (sec)',
        fromTrip: 'From Trip ID',
        toTrip: 'To Trip ID'
      }
    }
  },
  trips: {
    trips_list: {
      table: {
        routeId: 'Route ID',
        serviceId: 'Train ID',
        date: 'Date',
        headsign: 'Headsign',
        shortName: 'Train Number',
        directionId: 'Direction ID'
      },
      form: {
        fields: {
          routeId: 'Route ID',
          tripId: 'Trip ID',
          shortName: 'Train Number',
          serviceId: 'Train ID',
          headsign: 'Headsign',
          directionId: 'Direction ID'
        }
      }
    }
  }
}