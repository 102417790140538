<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")

  block custom
    change-settings-window
</template>

<script>
import BaseTable from '@crud_view/table'
import ChangeSettingsWindow from '../../components/change_settings_window'
import reportsTable from '../../components/reports_table'

export default {
  components: {
    BaseTable,
    ChangeSettingsWindow
  },
  extends: BaseTable,
  computed:{},
  methods: {
    ...reportsTable
  }
}
</script>
