<style scoped>
  .row{ padding-right: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      ttn-input(v-model="temp['nativeId']" v-bind="attr('nativeId')")
      el-row
        el-col(:span='5' class='row')
          ttn-input(v-model="temp['oindex']" v-bind="attr('oindex')" label-width='70px')
        el-col(:span='9' class='row')
          ttn-input(v-model="temp['location']" v-bind="attr('location')" label-width='90px')
        el-col(:span='9' class='row')
          ttn-input(v-model="temp['transferTime']" v-bind="attr('transferTime')" label-width='80px')


      el-row
        el-col(:span='8' class='row')
          ttn-select(v-model="temp['transferType']" v-bind="attr('transferType')" label-width='70px')
        el-col(:span='8' class='row')
          ttn-date-picker(v-model="temp['validFrom']" v-bind="attr('validFrom')" label-width='70px')
        el-col(:span='8' class='row')
          ttn-date-picker(v-model="temp['validTo']" v-bind="attr('validTo')" label-width='70px')

      hr

      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['previousLocation']" v-bind="attr('previousLocation')" label-width='120px')
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['arrivalTerminal']" v-bind="attr('arrivalTerminal')" label-width='120px')

      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['arrivalCarrier']" v-bind="attr('arrivalCarrier')" label-width='120px')
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['arrivalCodeshareCarrier']" v-bind="attr('arrivalCodeshareCarrier')" label-width='120px')

      el-row
        el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['arrivalFlightNumber']" v-bind="attr('arrivalFlightNumber')" label-width='120px')
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['arrivalAircraft']" v-bind="attr('arrivalAircraft')" label-width='120px')

      hr

      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['nextLocation']" v-bind="attr('nextLocation')" label-width='120px')
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['departureTerminal']" v-bind="attr('departureTerminal')" label-width='120px')

      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['departureCarrier']" v-bind="attr('departureCarrier')" label-width='120px')
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['departureCodeshareCarrier']" v-bind="attr('departureCodeshareCarrier')" label-width='120px')

      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['departureFlightNumber']" v-bind="attr('departureFlightNumber')" label-width='120px')
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['departureAircraft']" v-bind="attr('departureAircraft')" label-width='120px')

</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    methods: {
    }
  }
</script>
