export default {
  name: 'packets_risks',
  lazy: true,
  items: [
    {
      name: 'packets_risks_list',
      endpoint: '/persistance/default',
      pagination: true,
      type: 'graphql',
      preload: true,
      preloadStores: [
        'packets',
        'risks'
      ],
      graphql: {
        name: 'insurancePacketRisk',
        queries: {
          index: {
            insurancePacketId: true,
            insuranceRiskId: true,
            coverage: true,
            franchise: true,
            active: true
          }
        },
        preloadFields: [
          'packets{id name}',
          'risks{id name}'
        ]
      },
      table: {
        items: [
          'selection',
          'insurance_packet_id',
          'insurance_risk_id',
          'coverage',
          'franchise',
          {
            name: 'active',
            type: 'switch'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },{
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },{
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Legacy::Insurance::PacketsRisksRelation'
            }
          },{
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'insurance_packet_id',
            operator: 'eq',
            type: 'select',
            storeKey: 'packets',
            storePrimaryKey: 'id',
            storeLabelKey: 'name',
          },{
            name: 'insurance_risk_id',
            operator: 'eq',
            type: 'select',
            storeKey: 'risks',
            storePrimaryKey: 'id',
            storeLabelKey: 'name',
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },{
            name: 'insurance_packet_id',
            type: 'select',
            storeKey: 'packets',
            storePrimaryKey: 'id',
            storeLabelKey: 'name',
            required: true
          },{
            name: 'insurance_risk_id',
            type: 'select',
            storeKey: 'risks',
            storePrimaryKey: 'id',
            storeLabelKey: 'name',
            required: true
          },{
            name: 'coverage',
            type: 'yamleditor',
            required: true
          },{
            name: 'franchise',
            type: 'yamleditor',
            required: true
          },{
            name: 'active',
            type: 'switch',
            default: true,
            required: true
          }
        ]
      }
    }
  ]
}
