import railway_providers_list from '@/settings/modules/railway/general/railway_providers_store'
export default {
  namespace: 'railway',
  component: 'rail_prices',
  meta: {
    title: 'railway.rail_prices',
    icon: 'wallet2'
  },
  tabs: [
    {
      name: 'rail_prices',
      lazy: true,
      items: [
        {
          name: 'rail_prices_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayRailPrices',
            queries: {
              index: {
                id: true,
                ruleIndex: true,
                name: true,
                trafficSourceId: true,
                depotType: true,
                depotId: true,
                enabled: true,
                default: true,
                startDate: true,
                endDate: true,
                upsale: true,
                railPriceRules: {
                  id: true,
                  railPriceId: true,
                  providerName: true,
                  serviceCost: true,
                  bookingCost: true,
                  acquiringCost: true,
                  roundPrice: true,
                  refundCost: true,
                  extraServiceCost: true,
                  railPriceRuleItems: {
                    id: true,
                    railPriceRuleId: true,
                    startPrice: true,
                    endPrice: true,
                    serviceCost: true,
                    carType: true,
                    carClass: true,
                    departureTimeStart: true,
                    departureTimeEnd: true,
                    travelTimeStart: true,
                    travelTimeEnd: true,
                    trainNumber: true,
                    startDate: true,
                    endDate: true
                  }
                }
              }
            },
            actions: {
              create: 'railwayRailPriceCreate',
              update: 'railwayRailPriceUpdate'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              {
                name: 'ruleIndex',
                width: '80px'
              }, 'name',
              {
                name: 'depotId',
                format: 'from_boot',
                storeKey: 'depots_hash',
                storeLabelKey: 'name',
                default: 'All'
              }, {
                name: 'trafficSourceId',
                format: 'from_boot',
                storeKey: 'traffic_sources_hash',
                storeLabelKey: 'name',
                default: 'All'
              }, 'depotType', {
                name: 'startDate',
                format: 'date_time'
              }, {
                name:'endDate',
                format: 'date_time'
              }, {
                name: 'enabled',
                type: 'switch'
              }, {
                name: 'default',
                type: 'switch'
              }, {
                name: 'upsale',
                type: 'switch'
              },
              {
                name: 'actions',
                width: '120px',
                align: 'center'
              }, {
                name: 'expand'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                mutation: 'railway_rail_price_create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'clear_cache',
                icon: 'el-icon-s-release',
                type: 'info',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::RailPriceRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'id',
                operator: 'eq',
                type: 'number',
                scope: 'RailPrices'
              }, {
                name: 'depot_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                scope: 'RailPrices'
              }, {
                name: 'depot_type',
                type: 'select',
                operator: 'eq',
                store: {
                  '': 'Any',
                  'api': 'API',
                  'site': 'Site',
                  'agent': 'Agent',
                  'corporate': 'Corporate'
                },
                scope: 'RailPrices'
              }, {
                name: 'traffic_source_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'traffic_sources',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                scope: 'RailPrices'
              }, {
                name: 'enabled',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  1: 'Yes',
                  0: 'No'
                },
                scope: 'RailPrices'
              }, {
                name: 'default',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  1: 'Yes',
                  0: 'No'
                },
                scope: 'RailPrices'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'index',
                type: 'number',
                required: true,
                valueType: 'integer'
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'description',
                type: 'text',
                required: false
              },
              {
                name: 'depot_type',
                type: 'select',
                operator: 'in_array_str',
                store: {
                  'site': 'site',
                  'agent':'agent',
                  'api':'api',
                  'widget':'widget'
                },
                required: true
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                required: true
              },
              {
                name: 'enabled',
                type: 'switch',
                default: true,
                required: true
              },
              {
                name: 'default',
                type: 'switch',
                default: false,
                required: true
              },
              {
                name: 'start_date',
                type: 'datetime',
                options: {
                  firstDayOfWeek: 1
                },
                required: false,
                valueFormat: 'yyyy-MM-ddTHH:mm:ss',
                valueType: 'datetime_no_tz'
              },
              {
                name: 'end_date',
                type: 'datetime',
                options: {
                  firstDayOfWeek: 1
                },
                required: false,
                valueFormat: 'yyyy-MM-ddTHH:mm:ss',
                valueType: 'datetime_no_tz'
              },
              {
                name: 'traffic_source_id',
                type: 'number',
                required: true,
                valueType: 'integer',
                default: 0
              },
              {
                name: 'upsale',
                type: 'switch',
                default: false,
                required: false
              }
            ]
          },
          history: [
            'Legacy::Railway::RailPriceRelation'
          ],
          combined: [
            {
              name: 'railway_rail_price_rules',
              graphql: {
                name: 'railwayRailPriceRules'
              },
              type: 'graphql',
              boot: {
                type: 'local',
                row_key: 'railPriceRules',
                relation: {
                  railPriceId: 'id'
                }
              },
              table: {
                items: [
                  'selection',
                  {
                    name: 'id',
                    width: '80px'
                  },
                  'providerName',
                  'serviceCost',
                  'bookingCost',
                  'acquiringCost',
                  { name: 'roundPrice', type: 'switch' },
                  'refundCost',
                  'extraServiceCost',
                  {
                    name: 'actions',
                    width: '120px',
                    align: 'center'
                  }, {
                    name: 'expand'
                  }

                ]
              },
              actions: {
                row: [
                  {
                    name: 'update',
                    icon: 'el-icon-edit',
                    type: 'primary',
                    plain: true
                  }
                ],
                panel: [
                  {
                    name: 'create',
                    icon: 'el-icon-plus',
                    type: 'primary',
                    plain: false
                  },
                  {
                    name: 'delete',
                    icon: 'el-icon-delete',
                    type: 'danger',
                    plain: true,
                    dependent: 'selection'
                  },
                  {
                    name: 'clear_cache',
                    icon: 'el-icon-s-release',
                    type: 'info',
                    plain: true,
                    dependent: 'selection'
                  },
                  /* {
                    name: 'refresh',
                    icon: 'el-icon-refresh',
                    type: 'primary',
                    plain: true
                  },*/
                  // {
                  //   name: 'history',
                  //   icon: 'el-icon-refresh',
                  //   type: 'warning',
                  //   plain: true,
                  //   params: {
                  //     record_class: 'Legacy::Railway::RailPriceRuleRelation'
                  //   }
                  // },
                  {
                    name: 'filter',
                    context: 'panel',
                    icon: 'el-icon-help',
                    plain: true
                  }
                ]
              },
              form: {
                actualize: true,
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false
                  },
                  {
                    name: 'rail_price_id',
                    type: 'hidden',
                    required: true
                  },
                  {
                    name: 'provider_name',
                    type: 'select',
                    store: Object.assign({ '0': 'All' }, railway_providers_list.objectByName()),
                    required: true,
                    valueType: 'string'
                  },
                  {
                    name: 'service_cost',
                    type: 'number',
                    required: true,
                    valueType: 'float',
                    precision: 2,
                    default: '0.00'
                  },
                  {
                    name: 'booking_cost',
                    type: 'number',
                    required: true,
                    valueType: 'float',
                    precision: 2,
                    default: '0.00'
                  },
                  {
                    name: 'acquiring_cost',
                    type: 'number',
                    required: true,
                    valueType: 'float',
                    precision: 2,
                    default: '0.00'
                  },
                  {
                    name: 'round_price',
                    type: 'switch',
                    default: false,
                    required: true
                  },
                  {
                    name: 'refund_cost',
                    type: 'number',
                    required: true,
                    valueType: 'float',
                    precision: 2,
                    default: '0.00'
                  },
                  {
                    name: 'extra_service_cost',
                    type: 'number',
                    required: false,
                    valueType: 'float',
                    precision: 2,
                    default: '0.00'
                  }
                ]
              },
              history: [
                'Legacy::Railway::RailPriceRuleRelation'
              ],

              combined: [
                {
                  name: 'railway_rail_price_rule_items',
                  graphql: {
                    name: 'railwayRailPriceRuleItems',
                    actions: {
                      create: 'railwayRailPriceRuleItemCreate',
                      update: 'railwayRailPriceRuleItemUpdate'
                    }
                  },
                  type: 'graphql',
                  boot: {
                    type: 'local',
                    row_key: 'railPriceRuleItems',
                    relation: {
                      railPriceRuleId: 'id'
                    }
                  },
                  table: {
                    items: [
                      'selection',
                      {
                        name: 'id',
                        width: '80px'
                      },
                      'startPrice',
                      'endPrice',
                      'serviceCost',
                      { name: 'carType', format: 'from_options' },
                      'carClass',
                      { name: 'departureTimeStart', format: 'date_time' },
                      { name: 'departureTimeEnd', format: 'date_time' },
                      'travelTimeStart',
                      'travelTimeEnd',
                      'trainNumber',
                      { name: 'startDate', format: 'date_time' },
                      { name: 'endDate', format: 'date_time' },
                      {
                        name: 'actions',
                        width: '120px',
                        align: 'center'
                      }
                    ]
                  },
                  actions: {
                    row: [
                      {
                        name: 'update',
                        icon: 'el-icon-edit',
                        type: 'primary',
                        plain: true
                      }
                    ],
                    panel: [
                      {
                        name: 'create',
                        icon: 'el-icon-plus',
                        type: 'primary',
                        plain: false
                      },
                      {
                        name: 'delete',
                        icon: 'el-icon-delete',
                        type: 'danger',
                        plain: true,
                        dependent: 'selection'
                      },
                      {
                        name: 'clear_cache',
                        icon: 'el-icon-s-release',
                        type: 'info',
                        plain: true,
                        dependent: 'selection'
                      },
                      /* {
                        name: 'refresh',
                        icon: 'el-icon-refresh',
                        type: 'primary',
                        plain: true
                      },*/
                      // {
                      //   name: 'history',
                      //   icon: 'el-icon-refresh',
                      //   type: 'warning',
                      //   plain: true,
                      //   params: {
                      //     record_class: 'Legacy::Railway::RailPriceRuleItemRelation'
                      //   }
                      // },
                      {
                        name: 'filter',
                        context: 'panel',
                        icon: 'el-icon-help',
                        plain: true
                      }
                    ]
                  },
                  form: {
                    actualize: true,
                    items: [
                      {
                        name: 'id',
                        type: 'hidden',
                        required: false
                      },
                      {
                        name: 'rail_price_rule_id',
                        type: 'hidden',
                        required: true
                      },
                      {
                        name: 'start_price',
                        type: 'number',
                        required: true,
                        valueType: 'float',
                        default: '0.00',
                        precision: 2
                      },
                      {
                        name: 'end_price',
                        type: 'number',
                        required: true,
                        valueType: 'float',
                        default: '0.00',
                        precision: 2
                      },
                      {
                        name: 'service_cost',
                        type: 'text',
                        valueType: 'string',
                        required: true
                      },
                      {
                        name: 'car_type',
                        type: 'select',
                        store: {
                          '0': 'all',
                          '1': 'luxury',
                          '2': 'coupe',
                          '3': 'reserved',
                          '4': 'sitting',
                          '5': 'common',
                          '6': 'soft'
                        },
                        valueType: 'string',
                        required: true
                      },
                      {
                        name: 'car_class',
                        type: 'text',
                        required: false,
                        default: null
                      },
                      {
                        name: 'departure_time_start',
                        type: 'datetime',
                        required: false,
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        valueType: 'datetime_no_tz'
                      },
                      {
                        name: 'departure_time_end',
                        type: 'datetime',
                        required: false,
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        valueType: 'datetime_no_tz'
                      },
                      {
                        name: 'travel_time_start',
                        type: 'number',
                        required: false,
                        valueType: 'integer',
                        default: null
                      },
                      {
                        name: 'travel_time_end',
                        type: 'number',
                        required: false,
                        valueType: 'integer',
                        default: null
                      },
                      {
                        name: 'train_number',
                        type: 'text',
                        required: false,
                        default: null
                      },
                      {
                        name: 'start_date',
                        type: 'datetime',
                        required: false,
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        valueType: 'datetime_no_tz'
                      },
                      {
                        name: 'end_date',
                        type: 'datetime',
                        required: false,
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        valueType: 'datetime_no_tz'
                      }
                    ]
                  },
                  history: [
                    'Legacy::Railway::RailPriceRuleItemRelation'
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'rail_price_rule_service_items',
      lazy: true,
      items: [
        {
          name: 'rail_price_rule_service_items_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayRailPriceRuleServiceItems',
            queries: {
              index: {
                id: true,
                depotId: true,
                enabled: true,
                providerName: true,
                cost: true,
                addlInfo: true,
                serviceId: true,
                createdAt: true,
                updatedAt: true
              }
            }
          },
          stores: {
            serviceIds: {
              '1': 'Bolognese pasta with basil',
              '2': 'Drip coffee',
              '4': 'Bed linen',
              '5': '1 drink',
              '6': 'Combat tea',
              '7': 'Balanced lunch with curry chicken and rice',
              '8': 'Still water',
              '9': 'Balanced lunch with fish and bulgur',
              '10': 'Author\'s tea'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              {
                name: 'depotId',
                format: 'from_boot',
                storeKey: 'depots_hash',
                storeLabelKey: 'name'
              }, {
                name: 'enabled',
                type: 'switch'
              }, 'providerName', 'cost', 'addlInfo', {
                name: 'serviceId',
                format: 'from_store',
                storeKey: 'serviceIds'
              }, {
                name: 'createdAt',
                format: 'date_time'
              }, {
                name:'updatedAt',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '120px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::RailPriceRuleServiceItemRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                required: true
              },
              {
                name: 'enabled',
                type: 'switch',
                default: true,
                required: true
              },
              {
                name: 'provider_name',
                type: 'select',
                store: railway_providers_list.objectByName(),
                required: true
              },
              {
                name: 'cost',
                type: 'number',
                required: false,
                valueType: 'float'
              },
              {
                name: 'addl_info',
                type: 'yamleditor',
                required: false
              },
              {
                name: 'service_id',
                type: 'select',
                store: {
                  '1': 'Bolognese pasta with basil',
                  '2': 'Drip coffee',
                  '4': 'Bed linen',
                  '5': '1 drink',
                  '6': 'Combat tea',
                  '7': 'Balanced lunch with curry chicken and rice',
                  '8': 'Still water',
                  '9': 'Balanced lunch with fish and bulgur',
                  '10': 'Author\'s tea'
                },
                valueType: 'string',
                required: true
              }
            ]
          },
          history: [
            'Legacy::Railway::RailPriceRuleServiceItemRelation'
          ]
        }
      ]
    },
    {
      name: 'rail_price_rule_extra_items',
      lazy: true,
      items: [
        {
          name: 'rail_price_rule_extra_items_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayRailPriceRuleExtraItems',
            queries: {
              index: {
                id: true,
                depotId: true,
                enabled: true,
                providerName: true,
                cost: true,
                addlInfo: true,
                serviceId: true,
                createdAt: true,
                updatedAt: true
              }
            }
          },
          stores: {
            extraServiceIds: {
              1: 'animals',
              2: 'facility',
              3: 'extra baggage',
              4: 'personal item',
              5: 'cabin bag',
              6: 'hold_bag'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              }, 'depotId', {
                name: 'enabled',
                type: 'switch'
              }, 'providerName', 'cost', 'addlInfo',
              {
                name: 'serviceId',
                format: 'from_store',
                storeKey: 'extraServiceIds'
              }, {
                name: 'createdAt',
                format: 'date_time'
              }, {
                name:'updatedAt',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '120px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::RailPriceRuleExtraItemRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                required: true
              },
              {
                name: 'enabled',
                type: 'switch',
                default: true,
                required: true
              },
              {
                name: 'provider_name',
                type: 'select',
                operator: 'eq',
                store: railway_providers_list.objectByName(),
                required: true
              },
              {
                name: 'cost',
                type: 'number',
                required: false,
                valueType: 'float'
              },
              {
                name: 'addl_info',
                type: 'yamleditor',
                required: false
              },
              {
                name: 'service_id',
                type: 'select',
                store: {
                  1: 'animals',
                  2: 'facility',
                  3: 'extra baggage',
                  4: 'personal item',
                  5: 'cabin bag',
                  6: 'hold bag'
                },
                valueType: 'string',
                required: true
              }
            ]
          },
          history: [
            'Legacy::Railway::RailPriceRuleExtraItemRelation'
          ]
        }
      ]
    }
  ]
}
