<style scoped>
  .row{ padding-right: 10px }
  .row-small{ padding-left: 10px }
  .sbf-fieldset-0{ border: 1px solid #9b0101 }
  .sbf-fieldset-1{ border: 1px solid #062876; margin-top: 20px }
  .sbf-fieldset-fares{ border: 1px solid #f556ff; margin-top: 20px }
  .sbf-fl-bt-block{ padding: 10px 0 0 15px; }

  .sbpr-cnc-b{ margin-top: 3px  }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='3' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='70px')
        el-col(:span='5' class='row')
          ttn-input(v-model="temp['index']" v-bind="attr('index')" label-width='70px')
        el-col(:span='7' class='row')
          ttn-input(v-model="temp['validatingSupplier']" v-bind="attr('validatingSupplier')" label-width='90px')
        el-col(:span='9' class='row')
          ttn-input(v-model="temp['contractId']" v-bind="attr('contractId')" label-width='90px')
      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['routes']" v-bind="attr('routes')" label-width='80px')
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['flightNumbers']" v-bind="attr('flightNumbers')" label-width='80px')

      el-row
        el-col(:span='24' class='row')
          ttn-textarea(v-model="temp['comment']" v-bind="attr('comment')" label-width='80px')

      fieldset(:class="`el-form-fieldset`")
        legend {{ translate(`form.legends.items`) }}

        el-button(@click="actionAddItem()" type="primary") {{ translate('form.buttons.add_item') }}
        div(v-if="temp.items_decoded" v-for="(item, i) in temp.items_decoded")
          el-row
            el-col(:span='4' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['index']" v-bind="elAttr('items__index', `items_decoded.${i}.index`)" label-width='60px' :rules="required")
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['days_before_departure']" v-bind="elAttr('items__daysBeforeDeparture', `items_decoded.${i}.days_before_departure`)" label-width='60px' :rules="required")
            el-col(:span='6' class='row')
              ttn-select(v-model="temp['items_decoded'][i]['from_price']" v-bind="elAttr('items__fromPrice', `items_decoded.${i}.from_price`)" label-width='70px' :rules="required")
            el-col(:span='3' class='row')
              ttn-checkbox(v-model="temp['items_decoded'][i]['just_direct']" v-bind="elAttr('items__justDirect', `items_decoded.${i}.just_direct`)" label-width='80px')
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['remained_seats_range']" v-bind="elAttr('items__remainedSeatsRange', `items_decoded.${i}.remained_seats_range`)" label-width='90px' :rules="required")
          el-row
            el-col(:span='15' class='row')
              ttn-multiselect(v-model="temp['items_decoded'][i]['week_days']" v-bind="elAttr('items__weekDays', `items_decoded.${i}.week_days`)" label-width='80px' :rules="required")
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['max_discount']" v-bind="elAttr('items__maxDiscount', `items_decoded.${i}.max_discount`)" label-width='100px' :rules="required")
          el-row
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['minus_value']" v-bind="elAttr('items__minusValue', `items_decoded.${i}.minus_value`)" label-width='90px' :rules="required")
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['minus_other_gds_value']" v-bind="elAttr('items__minusOtherGdsValue', `items_decoded.${i}.minus_other_gds_value`)" label-width='120px' :rules="required")
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['minus_baggage_rec_value']" v-bind="elAttr('items__minusBaggageRecValue', `items_decoded.${i}.minus_baggage_rec_value`)" label-width='120px' :rules="required")
            el-col(:span='6' class='row')
              ttn-input(v-model="temp['items_decoded'][i]['max_markup']" v-bind="elAttr('items__maxMarkup', `items_decoded.${i}.max_markup`)" label-width='100px' :rules="required")
            el-col(:span='1' class='row')
              el-button(@click="actionRemoveItem(i)" type="danger" :disabled="temp['items_decoded'].length == 1" class="sbpr-cnc-b") -
          hr
</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    computed:{
      required(){
        const message = this.translate('form.required')
        const required = true
        return [{ required, message, trigger: 'change' }]
      }
    },
    extends: BaseForm,
    methods: {
      elAttr(fieldName, prop){
        return this.attr(fieldName, Object.assign({}, this.fieldReference[fieldName], {prop: prop}))
      },
      beforeCreateCallback(){
        this.temp = Object.assign({}, this.temp, {items_decoded: [this.defaultItem()]})
      },
      formatTemp() {
        for(let item of this.temp['items_decoded']){
          Object.keys(item).map((key, index) => { item[key] = this.formatField(item[key], this.fieldReference[`items__${this._.camelCase(key)}`]) })
        }

        this.temp['items'] = JSON.stringify(this.temp['items_decoded'])

        return this.temp
      },
      async actualizeCallback(temp){
        let newTemp = {}

        for(let i = 0; i < this.fields.length; i++){
          let field = this.fields[i]

          if(field.name.indexOf('items__') !== -1){
            continue
          } else {
            newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
          }
        }

        if(temp['items']){
          newTemp['items_decoded'] = []
          for(let item of temp['items']){
            Object.keys(item).map((key, index) => { item[key] = this.formatFieldReverse(item[key], this.fieldReference[`items__${this._.camelCase(key)}`]) })
            newTemp['items_decoded'].push(item)
          }
        }

        return newTemp
      },
      defaultItem(){
        return {
          index: 0, from_price: 0, just_direct: true, days_before_departure: '0-360',
          max_discount: 0.0, minus_value: 100.0, minus_other_gds_value: 0.0, max_markup: 0.0,
          week_days: [0,1,2,3,4,5,6], remained_seats_range: '*-*', minus_baggage_rec_value: 0.0
        }
      },
      actionAddItem(){
        this.temp['items_decoded'].push(this.defaultItem())
      },
      actionRemoveItem(i){
        this.temp['items_decoded'].splice(i,1)
      }
    }
  }
</script>
