import HistoryTab from './../../view/tabs/history.vue'
export default {
  components: {
    HistoryTab
  },
  data() {
    return {
      dialogVisible: false,
      tabHistory: ''
    }
  },

  provide() {
    return {
      '$elHistory': this
    }
  },

  methods: {
    actionHistory(params) {
      this.tabHistory = this.tabHistory || this.config.history[0].record_class
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$emit(this.$channels.historyTabChange, this.tabHistory)
      })
    },

    eventTabClick() {
      this.$nextTick(() => {
        this.$emit(this.$channels.historyTabChange, this.tabHistory)
      })
    },

    tabLabel(item) {
      return `${item.namespace ? item.namespace + '.' : ''}${item.name}`
    }
  }
}
