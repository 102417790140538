import gds_list from '@/settings/modules/avia/general/gds_store'

export default {
  namespace: 'avia',
  component: 'flight_details',
  meta: {
    title: 'avia.flight_details',
    icon: 'guide',
    roles: ['flight_details']
  },
  tabs: [
    {
      name: 'baggage_data',
      items: [
        {
          name: 'baggage_details',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaFlightDetails',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                active: true,
                priority: true,
                marketingSupplier: true,
                flightNumber: true,
                fareCode: true,
                geography: true,
                gdsId: true,
                baggageDetails: true
              }
            }
          },
          stores: {
            gdsIds: Object.assign({ 'all': 'all' }, gds_list.objectByName()),
            weightUnits: {
              'KG': 'KG',
              'LB': 'LB'
            },
            dimensionUnits: {
              'CM': 'CM',
              'IN': 'IN'
            },
            statuses: {
              '': 'Any',
              true: 'Yes',
              false: 'No'
            },
            useValues: {
              'skip': 'Skip block',
              'use': 'Use block'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px'
              },
              {
                name: 'priority',
                width: '80px',
              },
              {
                name: 'active',
                width: '80px',
                align: 'center'
              },
              {
                name: 'marketingSupplier',
                width: '80px',
                align: 'center'
              },
              'flightNumber',
              {
                name: 'fareCode',
                format: 'array_as_string'
              },
              {
                name: 'geography',
                format: 'array_as_string'
              },
              {
                name: 'gdsId',
                format: 'array_as_string'
              },
              'baggageDetails',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Avia::FlightDetailRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'id',
                type: 'text',
                operator: 'eq',
                camelizeLangKey: true
              },
              {
                name: 'marketing_supplier',
                type: 'text',
                operator: 'eq',
                camelizeLangKey: true
              },
              {
                name: 'flight_number',
                type: 'text',
                operator: 'like',
                camelizeLangKey: true
              },
              {
                name: 'fare_code',
                type: 'text',
                operator: 'in_array_str',
                camelizeLangKey: true
              },
              {
                name: 'geography',
                type: 'text',
                operator: 'in_array_str',
                camelizeLangKey: true
              },
              {
                name: 'gds_id',
                type: 'select',
                operator: 'in_array_str',
                storeKey: 'gdsIds',
                camelizeLangKey: true
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                storeKey: 'statuses'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'setting_type',
                type: 'hidden',
                valueType: 'integer',
                default: 0
              },
              {
                name: 'id',
                type: 'hidden',
                valueType: 'integer',
                required: false
              },
              {
                name: 'baggageDetails',
                type: 'hidden',
                valueType: 'string',
                default: '{}'
              },
              {
                name: 'active',
                type: 'checkbox',
                valueType: 'boolean',
                default: true
              },
              {
                name: 'priority',
                type: 'text',
                valueType: 'integer',
                required: true
              },
              {
                name: 'gdsId',
                type: 'multiselect',
                storeKey: 'gdsIds',
                required: true
              },
              {
                name: 'marketingSupplier',
                type: 'text',
                valueType: 'string',
                required: true
              },
              {
                name: 'flightNumber',
                type: 'text',
                valueType: 'string',
                required: false
              },
              {
                name: 'fareCode',
                type: 'text',
                valueType: 'array_of_string',
                required: false
              },
              {
                name: 'geography',
                type: 'text',
                valueType: 'array_of_string',
                required: false
              },
              {
                name: 'info__count',
                type: 'text',
                valueType: 'integer',
                ignore: true
              },
              {
                name: 'info__weight',
                type: 'text',
                valueType: 'integer',
                ignore: true
              },
              {
                name: 'info__weight_unit',
                type: 'select',
                storeKey: 'weightUnits',
                ignore: true
              },
              {
                name: 'info__width',
                type: 'text',
                valueType: 'integer',
                ignore: true
              },
              {
                name: 'info__height',
                type: 'text',
                valueType: 'integer',
                ignore: true
              },
              {
                name: 'info__length',
                type: 'text',
                valueType: 'integer',
                ignore: true
              },
              {
                name: 'info__dimension_sum',
                type: 'text',
                valueType: 'integer',
                ignore: true
              },
              {
                name: 'info__dimension_unit',
                type: 'select',
                storeKey: 'dimensionUnits',
                ignore: true
              },
              {
                name: 'info__override',
                type: 'checkbox',
                valueType: 'boolean',
                default: false,
                ignore: true
              },
              {
                name: 'info__use',
                type: 'radiogroup',
                storeKey: 'useValues',
                valueType: 'string',
                button: true,
                default: 'use',
                ignore: true
              }
            ]
          }
        }
      ]
    }
  ]
}