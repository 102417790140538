<template lang="pug">
  div 
    table(class="price-component-info")
      colgroup
        col(width="30")
        col(width="80")
        col(width="60")
        col(width="100")
        col(width="80")
        col(width="100")
        col(width="80")
        col(width="80")
        col(width="80")
        col(width="80")
        col(width="80")
        col(width="80")
        col(width="80")
        col(width="80")
        col(width="80")
        col(width="80")
      tr
        td(:colspan="colspan" style="padding: 15px 10px").left
          el-button(type="primary" size="mini" plain disabled) Edit PC
          el-button(type="primary" size="mini" plain disabled) Group Passengers
          el-button(type="primary" size="mini" plain disabled) Create PC
          el-button(type="primary" size="mini" plain disabled) History PC
      tr.head
        td
        td Locator
        td Supplier
        td Combination
        td GDS
        td Config
        td Start Price
        td Fare
        td Tax
        td Bsp Total
        td Payment
        td Card fee
        td Issue Office
        td VND Locator
        td Purchase at AK
        td Status

      template(v-for="(priceComponent, priceComponentIndex) in activePriceComponents")
        tr
          td(:colspan="colspan" style="padding: 0")
            table(style="width: 100%")
              tr(v-for="(paxId, index) in priceComponent.passengerIds")
                td(:set="paxInfo = passengersDict[paxId]" width="200" style="paddng-left: 10px;").bold {{ `${paxInfo.lastName} ${paxInfo.firstName} (${paxInfo.ageType})` }}
                td(v-if="index == 0" :rowspan="priceComponent.passengerIds.length" style="padding-left: 50px").bold.left {{ pcSegmentsList(priceComponent).join('; ') }}

        tr(:set="priceDetails = JSON.parse(priceComponent.priceDetails)")
          td {{ priceComponentIndex + 1 }}
          td {{ priceComponent.locator }}
          td {{ priceComponent.validatingSupplier }}
          td {{ priceComponent.combinationId }}
          td {{ gdsFor(priceComponent.gdsId) }}
          td {{ configFor(priceComponent.aviaConfigItemId) }}
          td {{ originBspCostFor(priceDetails) }}
          td {{ priceDetails.bsp && priceDetails.bsp.fare }}
          td {{ priceDetails.bsp && priceDetails.bsp.taxes }}
          td {{ priceDetails.bsp && `${priceDetails.bsp.total} ${priceDetails.bsp.currency}` }}
          td {{ priceComponent.cardAllowed ? 'CARD' : 'CASH' }}
          td {{ priceComponent.cardFee || '-' }}
          td {{ priceComponent.issueOfficeName }}
          td {{ priceComponent.vndLocator }}
          td {{ priceComponent.externalPurchasesTotal || '' }}
          td(:set="extraData = JSON.parse(priceComponent.extraData)") 
            div(v-if="priceComponent.isFake") Fake
            div(v-else-if="priceComponent.issuedAt") {{ priceComponent.issuedAt | moment('LLL') }}
            div(v-else-if="(booking.status != 'PN' || extraData.tf_data)") -
            div(v-else)
              el-row
                el-button(type="success" mini disabled) Issue
              el-row(v-if="priceComponent.fareDowngradeInProgress")
                span 'QUEUED (BE/IL)'
            <br/>
            div Ticketed: 
              span(v-if="priceComponent.ticketed").green Yes
              span(v-else).red No

        tr(:set="bspT = priceComponent.commission.split('||')").commission
          td(:colspan="3").bold Issue commission
          template(v-if="priceComponent.commission.length > 0")
            td.bold {{ `${bspT[0]}${bspT[1] == 'percent' ? '%' : 'A'}` }}
            td(:colspan="3") {{ officeFor(bspT[2]) }} ({{ `${bspT[7]}/${bspT[4]}` }})
            td(:colspan="colspan - 5") {{ bspT[3] }}
          template(v-else)
            td(:colspan="colspan - 3") Not available

        tr.commission(:set="commission = commissionValueBy(priceComponent.bspCommissionBookingOffice)")
          td(:colspan="3").bold Current issue commission
          template(v-if="priceComponent.bspCommissionBookingOffice")
            td.bold {{ commission.value }}
            td(:colspan="3") {{ commission.tOffice }}
            td(:colspan="colspan - 5") {{ commission.remark }}
          template(v-else)
            td(:colspan="colspan - 3") Not available

    el-collapse(class="inactive-price-component" v-if="inactivePriceComponents.length > 0")
      el-collapse-item(title="Inactive PC")
        table(class="price-component-info")
          colgroup
            col(width="30")
            col(width="80")
            col(width="60")
            col(width="100")
            col(width="80")
            col(width="100")
            col(width="80")
            col(width="80")
            col(width="80")
            col(width="80")
            col(width="80")
            col(width="80")
            col(width="80")
            col(width="80")
            col(width="80")
            col(width="80")

          template(v-for="(priceComponent, priceComponentIndex) in inactivePriceComponents")
            tr
              td(:colspan="colspan" style="padding: 0")
                table(style="width: 100%")
                  tr(v-for="(paxId, index) in priceComponent.passengerIds")
                    td(:set="paxInfo = passengersDict[paxId]" width="200" style="paddng-left: 10px;").bold {{ `${paxInfo.lastName} ${paxInfo.firstName} (${paxInfo.ageType})` }}
                    td(v-if="index == 0" :rowspan="priceComponent.passengerIds.length" style="padding-left: 50px").bold.left {{ pcSegmentsList(priceComponent).join('; ') }}

            tr(:set="priceDetails = JSON.parse(priceComponent.priceDetails)")
              td {{ priceComponentIndex + 1 }}
              td {{ priceComponent.locator }}
              td {{ priceComponent.validatingSupplier }}
              td {{ priceComponent.combinationId }}
              td {{ gdsFor(priceComponent.gdsId) }}
              td {{ configFor(priceComponent.aviaConfigItemId) }}
              td {{ originBspCostFor(priceDetails) }}
              td {{ priceDetails.bsp && priceDetails.bsp.fare }}
              td {{ priceDetails.bsp && priceDetails.bsp.taxes }}
              td {{ priceDetails.bsp && `${priceDetails.bsp.total} ${priceDetails.bsp.currency}` }}
              td {{ priceComponent.cardAllowed ? 'CARD' : 'CASH' }}
              td {{ priceComponent.cardFee || '-' }}
              td {{ priceComponent.issueOfficeName }}
              td {{ priceComponent.vndLocator }}
              td {{ priceComponent.externalPurchasesTotal || '' }}
              td Ticketed: 
                span.red No
</template>

<script>

import gdsStore from '@/settings/modules/avia/general/gds_store'
import autoticketingStore from '@/settings/modules/avia/general/autoticketing_store'

export default {
  props: {
    booking: null
  },

  data() {
    return {
      colspan: 16
    }
  }, 
  
  computed:{
    activePriceComponents() { return this._.filter(this.booking.priceComponents, ['status', 1]) },
    inactivePriceComponents() { return this._.filter(this.booking.priceComponents, ['status', 2]) },
    locationsDict() { return this._.reduce(this.booking.locations, (result, x) => { result[x.id] = x; return result }, {}) },
    passengersDict() { return this._.reduce(this.booking.passengers, (result, x) => { result[x.id] = x; return result }, {}) },
    gdsDict() { return gdsStore.objectById() },
    configsDict() { return this.$store.getters['bootData']['config_items_hash'] }
  },

  methods: {
    pcSegmentsList(priceComponent) {
      return this._.map(priceComponent.segmentIds, (segId) => {
        const seg = this.locationsDict[segId]
        const segIndex = this._.indexOf(Object.keys(this.locationsDict), String(segId))
        return `${seg.departureLocation}-${seg.arrivalLocation} [${segIndex + 1}]`
      })
    },

    gdsFor(gdsId) {
      return this.gdsDict[gdsId] ? `${this.gdsDict[gdsId]} (${gdsId})` : gdsId
    },

    configFor(aviaConfigItemId) {
      return this.configsDict[aviaConfigItemId] ? `${this.configsDict[aviaConfigItemId].name} (${aviaConfigItemId})` : aviaConfigItemId
    },

    originBspCostFor(priceDetails) {
      const originalBspCost = priceDetails.orig_bsp || priceDetails.bsp
      return originalBspCost ? `${originalBspCost.total.toFixed(2)} ${originalBspCost.currency}` : ''
    },

    commissionValueBy(bspCommissionJson) {
      if (!bspCommissionJson) return {}
  
      const commission = JSON.parse(bspCommissionJson)

      return {
        value: [commission.value, (commission.commission_type == 'amt' ? 'A' : '%'), ' / ', commission.infant_commission].join(''),
        remark: commission.remark,
        tOffice: [autoticketingStore.officeFor(commission.ticketing_driver), ' (', commission.avia_commission_id, '/', commission.id, ')'].join('')
      }
    },

    officeFor(officeID) {
      return autoticketingStore.officeFor(officeID)
    }
  } 
}
</script>

<style lang="scss" scoped>
  .bold { font-weight: bold }
  .underline { border-bottom: 1px solid }
  .green { color: green }
  .red { color: red }
  table.price-component-info {
    background-color: #d3d9e9;
    border-spacing: 1px;
    font: normal 11px arial,tahoma,helvetica,sans-serif;
    width: 100%;
    
    tr.head { 
      font-weight: bold;
      background-color: #f5f7fa;

      td { 
        text-align: center;
        padding: 10px 0;
      }
    }

    tr { 
      background-color: #fff;
    }

    td {
      padding: 5px;
      text-align: center;
    }

    td.left { text-align: left }
    td.right { text-align: right }

    tr.commission td {
      text-align: left
    }
  }
</style>