<template lang="pug">
  extends /form
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  extends: BaseForm,
  watch: {
    'temp.forDirect'(val) {
      this.changeRules(val)
    }
  },
  methods: {
    changeRules(val) {
      const refField = this.fieldReference.refid
      this.config.form.fieldsets[refField.fieldsetIndex].items[refField.index].required = !val
      this.config.form = { ...this.config.form }
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },

    isDisabledField(field, legend) {
      if (legend !== 'markup') {
        return false
      }
      return this.temp.airlineType === '1' && this.temp.withCombination
    }
  }
}
</script>
