<template>
  <div class="app-container">
    <el-tabs v-model="tab" type="border-card" class="main_route_tabs" :class="{hide_header: checkIsHide()}">
      <el-tab-pane
        v-for="component in allowedTabs"
        :key="component.name"
        :ref="component.name"
        :name="component.name"
        :label="$t(`main_tabs.${tabLabel(component)}`)"
      >
        <component-tab :component="component" :tab="tabValue" :item="itemValue" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ComponentTab from './tabs/component'
import checkPermission from '@/utils/permission'

export default {
  name: 'CrudListing',
  components: {
    ComponentTab
  },

  provide() {
    return {
      '$elContainer': this
    }
  },

  beforeRouteUpdate(to, from, next) {
    const query = to.query
    if (!query.tab || !query.item) {
      if (!query.tab && this.tab !== this.firstTabName) {
        this.tab = this.firstTabName
      }

      if (!query.item && this.item !== this.firstTabItemName) {
        this.item = this.firstTabItemName
      }
      if (this.tab && this.item) {
        this.activateItem({ clearParams: true })
      }
    } else {
      if (this.tab !== query.tab) {
        this.tab = query.tab
      }
      if (this.item !== query.item) {
        this.item = query.item
      }
    }
    // console.log(`beforeRouteUpdate: tab=${this.tab}, item=${this.item}, to_tab=${to.query.tab}, to_item=${to.query.item}`)
    next()
  },
  props: {
    module: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      tab: '',
      item: '',
      tabValue: '',
      itemValue: '',
      readyState: {},
      activateParams: {}
    }
  },

  computed: {
    allowedTabs() {
      const tabs = this.module.tabs.filter((i) => checkPermission(i.meta.roles))
      for (let t in tabs) {
        tabs[t].items = tabs[t].items.filter((i) => checkPermission(i.meta.roles))
      }
      return tabs.filter((i) => i.items.length > 0)
    },
    firstTabObject() {
      return this.allowedTabs[0] || false
    },
    firstTabName() {
      return this.firstTabObject ? this.firstTabObject.name : ''
    },
    firstTabItemName() {
      return this.firstTabObject ? this.firstTabObject.items[0].name : ''
    },
    tabItemKey() {
      return `${this.tab}_${this.item}`
    }
  },

  watch: {
    tab: {
      immediate: true,
      handler: function(newValue) {
        this.tabValue = newValue
      }
    },
    item: {
      immediate: true,
      handler: function(newValue) {
        this.itemValue = newValue
      }
    }
  },
  activated() {
    this.tab = this.$route.query.tab || this.firstTabName
    this.item = this.$route.query.item || this.firstTabItemName
  },

  mounted() {
    this.addListeners()
  },

  beforeDestroy() {
    this.removeListeners()
  },

  deactivated() {},

  methods: {
    checkPermission,
    addListeners() {
      this.tab = this.$route.query.tab || this.firstTabName
      this.item = this.$route.query.item || this.firstTabItemName
      this.$on(this.$channels.navigationTab, this.navigateTab)
      this.$on(this.$channels.navigationItemReady, this.itemReady)
    },

    removeListeners() {
      this.$off(this.$channels.navigationTab, this.navigateTab)
      this.$off(this.$channels.navigationItemReady, this.itemReady)
    },

    activateItem(params) {
      this.$nextTick(() => {
        this.$emit(this.$channels.navigationItemActivate(this.tab, this.item), params)
      })
    },

    itemReady(params) {
      const tabItemKey = `${params.tab}_${params.item}`
      this.readyState[tabItemKey] = true
      this.readyState = { ...this.readyState }
      if (params.tab === this.tab && params.item === this.item) {
        const params = { ...this.activateParams[this.tabItemKey] || {}}
        params.isReady = true
        this.activateItem(params)
        this.activateParams[this.tabItemKey] = null
        this.activateParams = { ...this.activateParams }
      }
    },

    itemChange(params) {
      if (params.tab === this.tab) {
        this.item = params.item
        if (!this.readyState[this.tabItemKey]) {
          this.activateParams[this.tabItemKey] = { clearParams: true }
          this.activateParams = { ...this.activateParams }
        } else {
          this.activateItem({ clearParams: this.activateParams[this.tabItemKey] !== null })
        }
      }
    },

    tabClick() {
      this.activateItem({})
    },

    tabLabel(config) {
      return `${config.namespace ? config.namespace + '.' : ''}${config.name}`
    },

    navigateTab(params) {
      this.tab = params.tab
      this.tabValue = params.tab
      this.item = params.item
      this.itemValue = params.item
      this.activateItem(params)
    },

    checkIsHide() {
      if (this.allowedTabs.length > 1) {
        return false
      }

      return !this.firstTabObject.alwaysShow
    }
  }
}
</script>
