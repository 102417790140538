async function quickFetch(request, url, data, method) {
  return new Promise((resolve, reject) => {
    const requestData = {
      url,
      method,
      data
    }
    request(requestData).then((res) => {
      resolve(res.data)
    }).catch((err) => {
      reject({message: err.message, status_code: err.response.status} )
    })
  })
}

export default function makeRestService(request, endpoint) {
  return {
    async list(params) {
      return quickFetch(request, endpoint, params, 'post')
    },
    async get(params) {
      return quickFetch(request, endpoint, params, 'post')
    },
    async create(params) {
      // return quickFetch(endpoint, params, 'post')
      return quickFetch(request, endpoint, params, 'post')
    },
    async update(params) {
      return quickFetch(request, endpoint, params, 'post')
    },
    async delete(params) {
      return quickFetch(request, endpoint, params, 'post')
    },
    async preload(params) {
      return quickFetch(request, endpoint, params, 'post')
    },
    async custom(url, params) {
      return quickFetch(request, endpoint, params, 'post')
    },
    async history(params) {
      return quickFetch(request, endpoint, params, 'post')
    }
  }
}
