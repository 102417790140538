<template lang="pug">
  extends /panel
  block right_prepend
    div(style="display: inline-block; margin-right: 10px")
      el-form(ref="searchForm" v-if="searchByFields")
        div(style="width: 100px; display: inline-block; margin-right: 10px")
          el-select(v-model="search.field" filterable)
            el-option(
              v-for="field in searchByFields"
              :key="field.name"
              :label="translate(`search.${field.name}`)"
              :value="field.name"
          )        
        div(style="width: 100px; display: inline-block; margin-right: 5px")
          el-input(v-model="search.value" @keyup.enter.native="actionApplyFilter")
        div(class="el-button-group")
          el-button(
            @click="actionApplyFilter"
            size="mini"
            plain
            type="primary"
            icon="el-icon-search"
          )

          el-button(
            @click="actionClearSearch"
            size="mini"
            plain
            type="danger"
            icon="el-icon-delete"
          )
</template>

<script>
import BasePanel from '@crud_view/panel'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  extends: BasePanel,
  mixins: [common, di]
}
</script>
