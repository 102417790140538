<template lang="pug">
  extends /table
  block expand
    vue-json-pretty(:path="'res'" :data="scope.row.params")
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,

  methods: {
    actionRun() {
      this.$confirm(this.$t('alert.delete_confirm'), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        try {
          const query = {
            __args: {
              ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
            }
          }

          await this.graphql('runCoreCacheInterval', query, 'mutation')
          this.$message({
            message: 'Success!',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }).catch((e) => {})
    }
  }
}
</script>
