<template lang="pug">
  el-collapse(style="margin: 0 0 10px 20px")
    el-collapse-item(title="Quick search" class="avia-booking-show-quick-filter")
      el-form(
        :inline="true"
        ref="filterForm"
        :label-position="config.filter.labelPosition"
        :label-width="config.filter.labelWidth"
        v-if="showForm"
        :rules="rules"
        :model="filters"
        :status-icon="config.filter.statusIcon"
        @submit.native.prevent="actionApplyFilter"
        @hook:mounted="eventFormMounted"
      )
        component(v-for="field in fields")
          el-form-item(:label="field.title" label-width="75px")
            el-input(v-model="filters[field.name].value" v-bind="attr(field.name, field, fieldset)" @keyup.enter.native="actionApplyFilter")

        el-form-item
          el-button(v-waves plain mini type="primary" icon="el-icon-check" @click="actionApplyFilter") {{ translate('form.apply') }}
</template>

<script>
import BaseFilter from '@crud_view/filter'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  components: {},
  extends: BaseFilter,
  mixins: [common, di]
}
</script>

<style lang="scss">
  #pane-avia_booking_show .avia-booking-show-quick-filter .el-collapse-item__header {
    line-height: 30px;
    height: 30px;
  }
</style>