<template lang="pug">
  div(class="variant-descriptions flex-row")
    div(class="flex-col" v-for="(description, d_key) in descriptions" :key="d_key")
      ttn-badge(
        class="description-badge"
        :value="description.index"
        :badge-style="{backgroundColor: description.color}"
      )
        el-card(class="services-card")
          div(slot="header")
            span(class="bold") {{description.name}}
          div
            ul
              li(v-for="(service, s_index) in description.services" :key="`service_${d_key}_${s_index}`") {{service}}
</template>

<script>
export default {
  name: 'VariantServices',
  props: {
    descriptions: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
    }
  }
}
  
</script>

<style lang="scss" scoped>
  .variant-descriptions {
    .services-card {
      flex: 1;
      .el-card__header {
        padding: 8px 10px;
      }
      .el-card__body {
        padding: 5px;
        ul{
          margin: 0;
          list-style-type: disclosure-closed;
          font-size: 10px;
        }
      }
    }
    .el-badge.description-badge {
      flex: auto;
      display: flex;
      .el-badge__content {
        right: 20px;
        top: 10px;
        color: #606266;
        border-radius: 2px;
      }
    }
  }
</style>
