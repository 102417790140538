import gds_list from '@/settings/modules/avia/general/gds_store'
import bsp_list from '../../../general/bsp_list'

export default {
  legend: 'general_data',
  translateLegend: true,
  items: [
    {
      name: 'id',
      type: 'hidden',
      required: false,
      valueType: 'integer'
    },
    {
      name: 'active',
      type: 'checkbox',
      default: true,
      required: false
    },
    {
      name: 'priority',
      type: 'text',
      valueType: 'integer',
    },
    {
      name: 'validatingSupplier',
      type: 'text',
      valueType: 'string',
    },
    {
      name: 'gds',
      type: 'multiselect',
      store: Object.assign({'all': 'all'}, gds_list.objectByName()),
    },
    {
      name: 'bsp',
      type: 'select',
      store: Object.assign({'all': 'all'}, bsp_list),
    }
  ]
}