export default {
  gds_settings: {
    form: {
      fields: {
        gdsName: 'Gds name',
        gdsRequests: 'Requests',
        keyFields: 'Group keys',
        city: 'City',
        extraCoefficients: 'Extra',
        issueOffices: 'Extra issue office',
        maxValue: 'Max value',
        active: 'Active',
        useTrxStatuses: 'Request list',
        formulaType: 'Formula',
        offices: 'Offices'
      },
      // options: {
      //   referenceType: {
      //     all: 'All',
      //     depot: 'Depot',
      //     traffic_source: 'Traffic source',
      //     0: 'All',
      //     1: 'Depot',
      //     2: 'Traffic source',
      //   },
      //   data__count: {
      //     '20i': '20ITINS',
      //     '50i': '50ITINS',
      //     '100i': '100ITINS',
      //     '200i': '200ITINS'
      //   },
      //   gdsId: {
      //     sabre: 'Sabre',
      //     0: 'Sabre'
      //   }
      // },
      // legends: {
      //   settings: 'Settings'
      // }
    },
    table: {
      gdsName: 'Gds name',
      gdsRequests: 'Requests',
      keyFields: 'Group keys',
      city: 'City',
      extraCoefficients: 'Extra',
      maxValue: 'Max value',
      active: 'Active'
    }
  }
}
