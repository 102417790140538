<script>
import Base from '@crud_combined/table'
import BaseStatisticTable from '../../base/table'

export default {
  extends: Base,
  mixins: [BaseStatisticTable],
  computed: {
    firstCellStyle() { return { 'padding-left': '10px' } }
  }
}
</script>
