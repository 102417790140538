<script>
import ElTabs from 'element-ui/packages/tabs/src/tabs.vue'
export default {
  name: 'TtnTabs',
  extends: ElTabs,
  render(h) {
    let {
      type,
      handleTabClick,
      handleTabRemove,
      handleTabAdd,
      currentName,
      panes,
      editable,
      addable,
      tabPosition,
      stretch
    } = this
    const newButton = editable || addable
      ? (<span
        class='el-tabs__new-tab'
        on-click={ handleTabAdd }
        tabindex='0'
        on-keydown={ (ev) => { if (ev.keyCode === 13) { handleTabAdd() } } }
      >
        <i class='el-icon-plus'></i>
      </span>)
      : null
    const navData = {
      props: {
        currentName,
        onTabClick: handleTabClick,
        onTabRemove: handleTabRemove,
        editable,
        type,
        panes,
        stretch
      },
      ref: 'nav'
    }
    const headerSlot = this.$slots.header
      ? (<span>{this.$slots.header}</span>)
      : null
    const RightBlock = (
      <span class='el-tabs__header-slot'>
        {headerSlot}
        {newButton}
      </span>
    )
    const header = (
      <div class={['el-tabs__header', `is-${tabPosition}`]}>
        {RightBlock}
        <tab-nav { ...navData }></tab-nav>
      </div>
    )
    const panels = (
      <div class='el-tabs__content'>
        {this.$slots.default}
      </div>
    )
    return (
      <div class={{
        'el-tabs': true,
        'el-tabs--card': type === 'card',
        [`el-tabs--${tabPosition}`]: true,
        'el-tabs--border-card': type === 'border-card'
      }}>
        { tabPosition !== 'bottom' ? [header, panels] : [panels, header] }
      </div>
    )
  }
}
</script>