export default {
  legend: 'route_data',
  translateLegend: true,
  items: [
    {
      name: 'departureFrom',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    },
    {
      name: 'transfer',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    },
    {
      name: 'arrivalTo',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    },
    {
      name: 'withReverseRoute',
      type: 'checkbox',
      default: false,
      required: false
    }
  ]
}