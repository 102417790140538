export default {
  from_rankings: {
    table: {
      origin: 'Origin',
      destination: 'Destination',
      destinationCountry: 'D. country',
      originCountry: 'O. country',
      flight: 'Flight',
      variants: 'Variants',
      otaCount: 'OTA count'
    },
    form: {
      fields: {
        market: 'Market',
        origin: 'Origin',
        destination: 'Destination',
        supplier: 'Supplier',
        destinationCountry: 'Destination country',
        originCountry: 'Origin country',
        otaCount: 'OTA count'
      },
      legends: {
        default: 'Filters'
      }
    },
    action: {
      download: 'Download'
    }
  }
}
