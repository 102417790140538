import uppercase from './uppercase'

const install = function(Vue) {
  Vue.directive('uppercase', uppercase)
}

if (window.Vue) {
  window.uppercase = uppercase
  Vue.use(install); // eslint-disable-line
}

uppercase.install = install
export default uppercase
