export default {
  namespace: 'core',
  component: 'settings',
  meta: {
    title: 'core.settings',
    icon: 'table'
  },
  tabs: [
    {
      name: 'main',
      meta: {
        title: 'main'
      },
      items: [
        {
          name: 'feature_setting',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'featureSetting',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                entity: true,
                setting: true,
                createdAt: true,
                updatedAt: true

              }
            }
          },
          table: {
            items: [
              'id', 'entity', 'setting', 'createdAt', 'updatedAt',
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::FeatureSettingRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'entity',
                type: 'text'
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false
              },
              {
                name: 'entity',
                type: 'text',
                required: true
              },
              {
                name: 'setting_json',
                required: true,
                type: 'jsoneditor',
                options: {
                  modes: ['text', 'code', 'tree', 'form', 'view'],
                  mode: 'code',
                  ace: ace
                }
              }

            ]
          }
        },
        {
          name: 'cache_interval',
          lazy: true,
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'coreCacheInterval',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                entity: true,
                interval: true,
                lastRun: true,
                createdAt: true,
                updatedAt: true,
                type: true,
                params: true,
                startAt: true,
                active: true,
                running: true
              }
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '50px'
              },
              'entity', 
              {
                name: 'type',
                width: '70px'
              },
              {
                name: 'params',
                readMore: true,
                readMoreChars: 100,
                format: 'json_string'
              },
              {
                name: 'interval',
                width: '100px'
              },
              {
                name: 'running',
                width: '80px'
              },
              {
                name: 'lastRun',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'startAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'active',
                width: '70px',
                type: 'switch'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '70px',
                align: 'center'
              },
              'expand'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'run',
                icon: 'el-icon-edit',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Core::CacheIntervalRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                scope: 'cacheIntervals',
                name: 'entity',
                type: 'text'
              },
              {
                name: 'active',
                scope: 'cacheIntervals',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                }
              }
            ]
          },
          form: {
            actualize: true,
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'entity',
                type: 'text'
              },
              {
                type: 'select',
                button: true,
                name: 'type',
                store: {
                  'handler': 'handler',
                  'repository': 'repository'
                }
              },
              {
                name: 'interval',
                type: 'text',
                valueType: 'integer'
              },
              {
                name: 'startAt',
                type: 'datetime',
                valueType: 'datetime'
              },
              {
                name: 'active',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'running',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'params',
                type: 'jsoneditor',
                options: {
                  modes: ['text', 'code', 'tree', 'form', 'view'],
                  mode: 'code',
                  ace: ace
                },
                default: {
                  'batch_size': 10,
                  'consumer_topic': '',
                  'filters': {},
                  'consumer_handler': ''
                }
              }
            ]
          }
        }
      ]
    }
  ]
}
