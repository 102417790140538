export default {
  name: 'google_flights_restrictions',
  meta: {
    title: 'restrictions',
    icon: 'skill',
    permission: 'restrictions'
  },
  lazy: true,
  items: [
    {
      name: 'google_flights_restrictions',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaGoogleFlightsRestrictions',
        queries: {
          index: {
            id: true,
            index: true,
            airlines: true,
            routes: true,
            trafficSourceIds: true,
            rbdTypes: true,
            rbdTypesFor: true,
            searchDateFrom: true,
            searchDateTo: true,
            travelDateFrom: true,
            travelDateTo: true,
            passengerTypes: true,
            minSegments: true,
            flightType: true,
            interline: true,
            minPrice: true,
            maxPrice: true,
            toDepartureHours: true,
            active: true,
            updatedAt: true
          }
        },
        preloadFields: ['restrictionTrafficSources{id name}']
      },
      preload: true,
      preloadStores: ['restrictionTrafficSources'],
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'index',
            width: '60px'
          },
          {
            name: 'airlines',
            format: 'array_as_string'
          },
          {
            name: 'trafficSourceIds',
            width: '150px',
            // format: 'array_as_string_options',
            format: 'array_from_instance',
            storeKey: 'restrictionTrafficSources',
            storeLabelKey: 'name'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::GoogleFlights::RestrictionRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'airlines',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'traffic_source_ids',
            type: 'select',
            storeKey: 'restrictionTrafficSources',
            operator: 'in_array_int',
            storeLabelKeys: ['id', 'name']
          }

        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'index',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'airlines',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'routes',
            type: 'textarea',
            required: false,
            rows: 6
          },
          {
            name: 'traffic_source_ids',
            type: 'multiselect',
            storeKey: 'restrictionTrafficSources',
            default: [],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            storePrimaryKey: 'id'
          },
          {
            name: 'rbd_types',
            type: 'multiselect',
            store: [
              { id: 1, name: 'economy' },
              { id: 2, name: 'prem. economy' },
              { id: 3, name: 'business' },
              { id: 4, name: 'first' }
            ],
            default: [],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: false
          },
          {
            name: 'rbd_types_for',
            type: 'select',
            store: {
              0: 'all',
              1: 'any'
            },
            default: '0',
            translateOptions: true,
            valueType: 'integer',
            required: false
          },
          {
            name: 'search_date_from',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false
          },
          {
            name: 'search_date_to',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false
          },
          {
            name: 'travel_date_from',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false
          },
          {
            name: 'travel_date_to',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false
          },
          {
            name: 'passenger_types',
            type: 'select',
            store: {
              0: 'all',
              1: 'child'
            },
            default: '0',
            translateOptions: true,
            valueType: 'integer',
            required: false
          },
          {
            name: 'min_segments',
            type: 'text',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'flight_type',
            type: 'select',
            store: {
              0: 'all',
              1: 'ow',
              2: 'rt'
            },
            default: '0',
            translateOptions: true,
            valueType: 'integer',
            required: false
          },
          {
            name: 'interline',
            type: 'select',
            store: {
              0: 'all',
              1: 'no',
              2: 'present'
            },
            default: '0',
            translateOptions: true,
            valueType: 'integer',
            required: false
          },
          {
            name: 'min_price',
            type: 'text',
            required: false
          },
          {
            name: 'max_price',
            type: 'text',
            required: false
          },
          {
            name: 'to_departure_hours',
            type: 'text',
            required: false,
            valueType: 'integer'
          },
        ]
      }
    }
  ]
}
