import { login } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import engine from '@/engine'
import settings from '@/settings'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'

const state = {
  token: getToken(),
  name: '',
  avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  }
}

const actions = {
  // user login
  login({ commit }, loginForm) {
    return new Promise((resolve, reject) => {
      let loginData = loginForm
      if (settings.login.type === 'graphql') {
        const query = {
          mutation: {
            signIn: {
              token: true,
              '__args': {
                credentials: {
                  login: loginForm.login,
                  password: loginForm.password
                }
              }
            }
          }
        }
        loginData = {
          query: `${jsonToGraphQLQuery(query)}`
        }
      }
      login(loginData).then(response => {
        let { data } = response
        if (settings.login.type === 'graphql') {
          data = data.signIn
        }
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve) => {
      dispatch('boot/resetData', null, { root: true })
      commit('SET_TOKEN', '')
      removeToken()
      engine.router.resetRouter()

      // reset visited views and cached views
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  },

  // remove token
  resetToken({ commit, dispatch }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      dispatch('boot/resetData', null, { root: true })
      removeToken()
      resolve()
    })
  }

  // // dynamically modify permissions
  // changeRoles({ commit, dispatch }, role) {
  //   return new Promise(async resolve => {
  //     const token = role + '-token'

  //     commit('SET_TOKEN', token)
  //     setToken(token)

  //     const { roles } = await dispatch('getInfo')

  //     resetRouter()

  //     // generate accessible routes map based on roles
  //     const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

  //     // dynamically add accessible routes
  //     router.addRoutes(accessRoutes)

  //     // reset visited views and cached views
  //     dispatch('tagsView/delAllViews', null, { root: true })

  //     resolve()
  //   })
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
