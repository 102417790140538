<script>
import Base from '@crud_combined/table'
import BaseStatisticTable from '../../base/table'

export default {
  extends: Base,
  mixins: [BaseStatisticTable],
  computed: {
    firstCellStyle() { return { 'padding-left': '15px' } },
    gdsStorePath() { return 'avia/l2b/l2b_statistics/daily_by_depot/preload' }
  }
}
</script>
