export default {
  graphql: (reportType) => {
    return {
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaReport',
        queries: {
          index: {
            id: true,
            name: true,
            description: true,
            sessionId: true,
            reportType: true,
            handlerStatus: true,
            filePath: true,
            reportParams: true,
            createdAt: true,
            updatedAt: true
          }
        },
        baseParams: {
          index: {
            filters: {
              aviaReports: [
                { field: 'report_type', operator: "in", value: Array.isArray(reportType) ? reportType : [reportType] }
              ]
            }
          }
        }
      }
    }
  },
  table_items: [
    'selection',
    {
      name: 'id',
      width: '60px'
    },
    {
      name: 'name'
    },
    {
      name: 'description'
    },
    {
      name: 'sessionId'
    },
    {
      name: 'handlerStatus',
      format: 'from_options'
    },
    {
      name: 'updatedAt'
    },
    {
      name: 'actions',
      width: '60px'
    },
    {
      name: 'expand',
      type: 'expand',
      width: '30px'
    }
  ],
  actions_row: [
    {
      name: 'download',
      icon: 'el-icon-download',
      type: 'success',
      plain: true,
      roles: false
    }
  ],
  actions_panel: [
    {
      name: 'regenerate',
      icon: 'el-icon-refresh',
      type: 'danger',
      plain: true
    },
    {
      name: 'create',
      icon: 'el-icon-plus',
      type: 'primary',
      plain: false
    },
    // {
    //   name: 'delete',
    //   icon: 'el-icon-delete',
    //   type: 'danger',
    //   plain: true,
    //   dependent: 'selection'
    // },
    {
      name: 'refresh',
      icon: 'el-icon-refresh',
      type: 'primary',
      plain: true
    },
    {
      name: 'history',
      icon: 'el-icon-refresh',
      type: 'warning',
      plain: true,
      params: {
        record_class: 'PersistanceApi::Avia::Report::ItemRepository'
      }
    },
    {
      name: 'filter',
      context: 'panel',
      icon: 'el-icon-help',
      plain: true
    }
  ],
  filter_items: [
    {
      name: 'id',
      operator: 'eq'
    }
  ],
  form_items: (reportType) => {
    let reportTypes = Array.isArray(reportType) ? reportType : [reportType]
    return [
      {
        name: 'id',
        type: 'hidden',
        required: false,
        valueType: 'integer'
      },
      {
        name: 'name',
        type: 'text',
        required: true
      },
      {
        name: 'description',
        type: 'text',
        required: true
      },
      {
        name: 'sessionId',
        type: 'text',
        required: false
      },
      {
        name: 'reportType',
        type: 'select',
        store: reportTypes.reduce((obj, i) => ({ ...obj, [i]: i }), {}),
        default: `${reportTypes[0]}`,
        translateOptions: true,
        valueType: 'integer',
        required: true
      },
      {
        name: 'reportParams',
        type: 'textarea',
        required: true,
        valueType: 'json_as_string',
        rows: 3,
        default: '{}'
      }
    ]
  }

}
