<template lang="pug" src="./../templates/history_tab.pug" />

<script>
import common from './../../mixin/common'
import historyTab from './../../mixin/history/tab'
import di from './../../mixin/di'
import component from './../../mixin/component'

export default {
  name: 'CrudHistoryTab',
  mixins: [component, common, di, historyTab],
  props: {
    record: {
      required: true,
      type: Object
    }
  }
}
</script>
