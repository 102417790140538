<template lang="pug">
  extends /table
  block expand
    vue-json-pretty(:path="'res'" :data="scope.row.items")
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base
}
</script>
