<template lang="pug">
  div(class="match-variants")
    el-divider(v-if="trip_index > 0 && withDelimiter")
      i(class="el-icon-more" v-for="(n, ni) in (new Array(10))" :key="`ic_${ni}`")
    el-descriptions(v-for="(segment, s_index) in trip.segments" :border="true" :column="4" :key="`m_segment_${trip_index}_${s_index}`")
      el-descriptions-item(label="Departure")
        span(class="bold") {{segment.dep_location}}, &nbsp;
        span {{segment.dep_time}}
      el-descriptions-item(label="Arrival")
        span(class="bold") {{segment.arr_location}}, &nbsp;
        span {{segment.arr_time}}
    div(class="flex-row")
      div(class="flex-col variant-col" v-for="(variant, v_index) in trip.variants" :key="`variant_${trip_index}_${v_index}`" @click="toggleVariant(variant)")
        el-card(class="services-card" :class="{selected: selected && selected.index === variant.index, action: groupName}")
          div(slot="header")
            span(class="bold") {{variant.name}}
            span(class="el-badge__content" :style="{backgroundColor: variant.color, color: '#606266', borderRadius: '2px'}") {{variant.index}}
            span(style="float: right")
              span(v-if="baseCurrency && variant.prices && variant.prices[baseCurrency]")
                span(class="price") {{variant.prices[baseCurrency]}}
                  span(class="currency") {{baseCurrency}}
                span(class="source-price") &nbsp;({{variant.price}}
                  span(class="currency") {{variant.currency}})
              span(v-else)
                span(class="price") {{variant.price}} {{variant.currency}}
          div(v-if="showServices")
            ul
              li(v-for="(service, s_index) in variant.services" :key="`service_${trip_index}_${v_index}_${s_index}`") {{service}}
</template>

<script>
export default {
  props: {
    trip: {
      required: true,
      type: Object
    },
    trip_index: {
      required: true,
      type: Number
    },
    baseCurrency: {
      required: false,
      type: String,
      default: null
    },
    withDelimiter: {
      required: false,
      type: Boolean,
      default: false
    },
    showServices: {
      required: false,
      type: Boolean,
      default: false
    },
    groupName: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    toggleVariant(variant) {
      if (!this.groupName) {
        return false
      }

      const parent = this.getParent('GroupDetailsDialog')
      if (this.selected && this.selected.index === variant.index) {
        this.selected = null
        parent.$emit('uncheckVariant', { group: this.groupName, item: variant, currency: this.baseCurrency })
      } else {
        if (this.selected) {
          parent.$emit('uncheckVariant', { group: this.groupName, item: this.selected, currency: this.baseCurrency })
        }
        this.selected = variant
        parent.$emit('checkVariant', { group: this.groupName, item: variant, currency: this.baseCurrency })
      }
    },
    getParent(name) {
      let component = null
      let parent = this.$parent
      while (parent && !component) {
        if (parent.$options.name === name) {
          component = parent
        }
        parent = parent.$parent
      }
      return component
    }
  }
}
  
</script>

<style lang="scss" scoped>
  .match-variants {
    .el-divider {
      margin: 10px 0;
    }

    .services-card {
      flex: 1
    }

    .services-card{
      &.selected {
        box-shadow: inset 4px 3px 20px 0px #c6e2ff;
      }
      &.action {
        cursor: pointer;
      }
      .el-card__header {
        padding: 8px 10px;
      }
      .el-card__body {
        padding: 5px;
      //   ul{
      //     margin: 0;
      //     list-style-type: disclosure-closed;
      //     font-size: 10px;
      //   }
      }
    }

    .flex-col {
      &.variant-col{
        max-width: 34%;
      }
    }

    .price {
      color: red;
      font-size:16px;
      font-weight: bold;
    }

    .source-price {
      font-weight: 600;
    }

    .original-price {
      display: flex;
      flex: 1;
      flex-direction: row-reverse;
      margin-right: 10px;
    }

    .currency {
      font-size: 12px;
      padding-left: 4px;
    }
  }

  .fullMatch {
    background: rgb(225, 243, 216);
  }
  .partialMatch {
    background: rgb(253, 226, 226);
  }
  .notMatch {
    background: rgb(250, 236, 216);
  }
</style>
