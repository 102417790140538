export default {
  name: 'doc_type_rules',
  items: [
    {
      name: 'doc_type_rules_list',
      endpoint: '/persistance/default',
      pagination: true,
      type: 'graphql',
      graphql: {
        name: 'aviaDocTypeRules',
        exclude: ['actions', 'expand'],
        queries: {
          index: {
            id: true,
            name: true,
            mask: true,
            citizenship: true,
            ageMax: true,
            ageMin: true,
            ageBefore: true,
            expired: true,
            restriction: true,
            gdsId: true,
            updatedAt: true,
            createdAt: true,
            active: true,
            hideInCabinet: true
          }
        }
      },
      table: {
        items: [
          // 'selection',
          {
            name: 'id',
            width: '40px'
          },
          {
            name: 'name',
            width: '140px',
            format: 'from_options'
          },
          {
            name: 'mask',
            width: '120px',
            format: 'string'
          },
          {
            name: 'restriction',
            width: null,
            format: 'array_as_string'
          },
          {
            name: 'citizenship',
            width: null
          },
          {
            name: 'gdsId',
            width: '80px',
            // format: 'from_options'
            format: 'array_as_string'
          },
          {
            name: 'ageMax',
            width: '70px'
          },
          {
            name: 'ageMin',
            width: '70px'
          },
          {
            name: 'ageBefore',
            width: '90px'
          },
          {
            name: 'expired'
          },
          {
            name: 'hide_in_cabinet',
            type: 'switch',
            width: '60px'
          },
          {
            name: 'updatedAt',
            format: 'date_time'
          },
          {
            name: 'createdAt',
            format: 'date_time'
          },
          {
            name: 'active',
            type: 'switch',
            width: '60px'
          },
          {
            name: 'actions',
            width: '60px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::DocTypeRuleRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'name',
            type: 'text',
            operator: 'eq',
            camelizeLangKey: true
          },
          {
            name: 'citizenship',
            type: 'text',
            operator: 'eq',
            camelizeLangKey: true
          },
          {
            name: 'restriction',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'mask',
            type: 'text',
            operator: 'in_array_str'
          },
          {
            name: 'gds_id',
            type: 'text',
            operator: 'in_array_int'
          },
          {
            name: 'hide_in_cabinet',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              '': 'Any',
              true: 'yes',
              false: 'no'
            }
          },
          {
            name: 'active',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              '': 'Any',
              true: 'yes',
              false: 'no'
            }
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'citizenship',
                type: 'text'
                // valueType: 'array_of_integer'
              },
              {
                name: 'name',
                type: 'select',
                store: {
                  'NP': 'NP',
                  'P': 'P',
                  'I': 'I',
                  'B': 'B'
                  // ========= possible available doctypes in future =========
                  // 'A': 'A',
                  // 'C': 'C',
                  // 'D': 'D',
                  // 'PD': 'PD',
                  // 'PM': 'PM',
                  // 'PS': 'PS',
                  // 'T': 'T',
                  // 'F': 'F',
                  // 'M': 'M',
                  // 'N': 'N',
                  // 'V': 'V',
                },
                translateOptions: true
              },
              {
                name: 'mask',
                type: 'text',
                valueType: 'string'
              },
              {
                name: 'restriction',
                type: 'text',
                valueType: 'array_of_string',
                required: false,
                valueDelimiter: ','
              },
              {
                name: 'gds_id',
                type: 'text',
                translateOptions: true,
                valueType: 'array_of_integer',
                required: false
              },
              {
                name: 'expired',
                type: 'number',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'ageMax',
                type: 'number',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'ageMin',
                type: 'number',
                valueType: 'integer',
                required: false
              },
              {
                name: 'ageBefore',
                type: 'number',
                valueType: 'integer',
                required: false
              },
              {
                name: 'hide_in_cabinet',
                type: 'checkbox',
                required: false
              },
              {
                name: 'active',
                type: 'checkbox',
                default: true,
                required: false
              }
            ]
          }
        ]
      }
    }
  ]
}
