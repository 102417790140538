<template lang="pug">
  extends /table
  block expand
    el-row
      el-col(:span=12)
        custom-table
          custom-row(v-for="key in ['markupRoundValue', 'markupRoundType', 'markupLossPosition', 'markupBehavior', 'maxMarkupPercent']" :key="key")
            custom-cell {{translate(`form.fields.${key}`)}}
            custom-cell
              div {{ renderValue(scope.row, key) }}
      el-col(:span=12)
        el-row
          el-col(:span=12 v-for="rid in scope.row.refid" :key="rid") {{ renderTS(rid) }}
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,
  created() {
    this.trafficSources = this.$store.getters['bootData']['traffic_sources_hash']
  },

  methods: {
    renderValue(row, key) {
      if (this.config.stores[key]) {
        return this.config.stores[key][row[key]]
      }
      return row[key]
    },

    renderTS(rid) {
      if (this.trafficSources[rid]) {
        return `${rid}: ${this.trafficSources[rid].name}`
      }
      return rid
    },

    async beforeDeleteCallback(rows) {
      if (rows.some((i) => i.active === true)) {
        throw new Error(this.translate('messages.allInactive'))
      }
    },

    rowClassName({ row, rowIndex }) {
      if (row.airlineType === '0') {
        return 'success-row'
      } else if (row.airlineType === '1' && row.withCombination) {
        return 'danger-row'
      } else if (row.airlineType === '1') {
        return 'warning-row'
      }
    }
  }
}
</script>
