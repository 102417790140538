export default {
  name: 'validator',
  items: [
    {
      name: 'json_schema',
      endpoint: '/persistance/webscraper',
      autoLoad: false,
      pagination: false,
      type: 'graphql',
      graphql: {
        name: 'dataValidator',
        queries: {
          index: {
            data: true,
            dataPointer: true,
            schemaPointer: true,
            type: true,
            details: true,
            schema: true
          }
        },
        actions: {
          index: 'dataValidatorResult'
        },
        preloadFields: ['structs{key name schema}']
      },
      preload: true,
      preloadStores: [
        {
          name: 'structs',
          storePrimaryKey: 'key'
        }
      ],
      table: {
        items: [
          {
            name: 'type',
            sortable: false
          },
          {
            name: 'dataPointer',
            sortable: false
          },
          {
            name: 'schemaPointer',
            sortable: false
          },
          {
            name: 'details',
            sortable: false
          },
          'expand'
        ]
      },
      actions: {
        row: [],
        panel: []
      },
      filter: {
        position: 'top',
        class: 'webscraper_validator_filter',
        fieldsets: [
          {
            legend: 'main',
            collapsible: false,
            scope: 'dataValidatorResult',
            items: [
              {
                name: 'struct_version',
                operator: 'eq',
                type: 'select',
                storeKey: 'structs',
                storeLabelKeys: ['name'],
                storePrimaryKey: 'key',
                required: true,
                hidden: true
              },
              {
                name: 'data',
                operator: 'eq',
                type: 'textarea',
                rows: 10,
                required: true,
                hidden: true
              }
            ]
          }
        ]
      },
      form: {
        fieldsets: []
      }
    }
  ]
}
