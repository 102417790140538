import settings from './travel_rankings/admin'
import meta from './travel_rankings/reports/meta'
import pricing from './travel_rankings/reports/pricing'

export default {
  travel_rankings: {
    admin: {
      settings: settings
    },
    reports: {
      meta: meta,
      pricing: pricing
    }
  }
}