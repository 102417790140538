<template lang="pug">
  div
    div(style="padding: 10px")
      el-row
        el-button(
          type="primary"
          plain
          size="mini"
          icon="el-icon-plus"
          @click="createAction()"
          ) Add
        el-button(
          type="danger"
          plain
          size="mini"
          icon="el-icon-delete"
          @click="deleteAction()"
          :disabled="disabled"
          ) Delete
      br
      el-row
        el-table(:data="re_search_rule.childRules", :stripe="true", @selection-change="selectionChange")
          el-table-column(type="selection", width="55")
          el-table-column(prop="id", label="ID")
          el-table-column(:label="translate('form.fields.depots')")
            template(slot-scope="scope")
              span(style="white-space: nowrap;") {{ scope.row.depots.join(", ") }}
          el-table-column(:label="translate('form.fields.blockedVc')")
            template(slot-scope="scope")
              span(style="white-space: nowrap;") {{ scope.row.blockedVc.join(", ") }}
          el-table-column(label="Loyalty")
            el-table-column(:label="translate('form.fields.l1')")
              template(slot-scope="scope")
                el-switch(:value="scope.row.l1", disabled)
            el-table-column(:label="translate('form.fields.l2')")
              template(slot-scope="scope")
                el-switch(:value="scope.row.l2", disabled)
            el-table-column(:label="translate('form.fields.l3')")
              template(slot-scope="scope")
                el-switch(:value="scope.row.l3", disabled)
          el-table-column(prop="daysBeforeDeparture", :label="translate('form.fields.daysBeforeDeparture')")
          el-table-column(prop="margin", :label="translate('form.fields.margin')")
          el-table-column(prop="rateMargin", :label="translate('form.fields.rateMargin')")
          el-table-column(prop="calculate", :label="translate('form.fields.calculate')")
          el-table-column(:label="translate('form.fields.configCurrency')")
            template(slot-scope="scope")
              span(style="white-space: nowrap;") {{ scope.row.configCurrency }}
          el-table-column(label="Status")
            template(slot-scope="scope")
              el-switch(:value="scope.row.status", disabled)
          el-table-column(class-name="small-padding" :label="$t('crud.table.actions')" width="70px" align="center")
            template(slot-scope="scope")
              el-button(
                :title="translate(`action.update`)"
                type="warning"
                plain
                size="mini"
                icon="el-icon-edit"
                @click="updateAction(scope.row)"
              )

</template>

<script>
import common from '@crud_lib/common'

export default {
  mixins: [common],
  props: {
    re_search_rule: null,
    config: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      selected: [],
      disabled: true
    }
  },

  methods: {

    selectionChange(selected) {
      var selection = []
      selected.forEach((row) => {
        selection.push(
          row
        )
      })

      if(selection.length > 0) {
        this.disabled = false
      }

      this.selected = selection
    },

    createAction() {
      this.$emit('createAction', this.re_search_rule.id)
    },

    updateAction(row) {
      this.$emit('updateAction', row)
    },

    deleteAction() {
      this.$emit('deleteAction', this.selected)
    }

  }
}
</script>
