<template lang="pug">
  div(class="list-content" v-loading="loading")
    el-tabs(v-if="records.length > 0" type="border-card" v-model="tabIndex")
      el-tab-pane(v-for="(rec, i) in records" :label="`${labelForTab(rec)}`" :key="i" :name="`statistic_tab_${i}`")
        el-row(:gutter="24")
          el-col(v-for="dataKey in dataKeys" :span="12" :key="`${i}_${dataKey}`")
            queue-chart-pie(v-if="rec.inQueue[dataKey] && rec.inQueue[dataKey].length > 0" :title="dataKey" :data="rec.inQueue[dataKey]")
    div(v-else) No bookings in status PN, PC or NP

</template>

<script>
import Base from '@crud_view/table'
import QueueChartPie from './chart'

export default {
  extends: Base,
  components: {
    QueueChartPie
  },
  data() {
    return {
      dataKeys: ['byDriver', 'byPc', 'bySiteType', 'byDepot', 'byOperator'],
      statusDescriptions: { 'PN': 'Payed. Not issued', 'PC': 'Cancelled. Payed', 'NP': 'Pay not confirm', 'ALL': 'By operator' },
      tabIndex: 'statistic_tab_0'
    }
  },
  methods: {
    totalForTab(rec) {
      return rec.status == 'ALL' ? (rec.inQueue.byOperator[0] ? rec.inQueue.byOperator[0].value : 0) : rec.inQueue.total
    },

    labelForTab(rec) {
      return rec.status == 'ALL' ? `${this.statusDescriptions[rec.status]} : ${rec.inQueue.total - this.totalForTab(rec)}/${rec.inQueue.total}` : `${this.statusDescriptions[rec.status]} [${rec.status}] : ${this.totalForTab(rec)}`
    }
  }
}
</script>
