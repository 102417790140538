/* Port of strftime(). Compatibility notes:
 *
 * %c - formatted string is slightly different
 * %D - notplemented (use "%m/%d/%y" or "%d/%m/%y")
 * %e - space is not added
 * %E - not implemented
 * %h - not implemented (use "%b")
 * %k - space is not added
 * %n - not implemented (use "\n")
 * %O - not implemented
 * %r - not implemented (use "%I:%M:%S %p")
 * %R - not implemented (use "%H:%M")
 * %t - not implemented (use "\t")
 * %T - not implemented (use "%H:%M:%S")
 * %U - not implemented
 * %W - not implemented
 * %+ - not implemented
 * %% - not implemented (use "%")
 */
function strftime(sFormat, date) {
  if (!(date instanceof Date)) date = new Date()
  var nDay = date.getDay(),
    nDate = date.getDate(),
    nMonth = date.getMonth(),
    nYear = date.getFullYear(),
    nHour = date.getHours(),
    aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    isLeapYear = function() {
      if ((nYear & 3) !== 0) return false
      return nYear % 100 !== 0 || nYear % 400 === 0
    },
    getThursday = function() {
      var target = new Date(date)
      target.setDate(nDate - ((nDay + 6) % 7) + 3)
      return target
    },
    zeroPad = function(nNum, nPad) {
      return ('' + (Math.pow(10, nPad) + nNum)).slice(1)
    }
  return sFormat.replace(/%[a-z]/gi, function(sMatch) {
    return {
      '%a': aDays[nDay].slice(0, 3),
      '%A': aDays[nDay],
      '%b': aMonths[nMonth].slice(0, 3),
      '%B': aMonths[nMonth],
      '%c': date.toUTCString(),
      '%C': Math.floor(nYear / 100),
      '%d': zeroPad(nDate, 2),
      '%e': nDate,
      '%F': date.toISOString().slice(0, 10),
      '%G': getThursday().getFullYear(),
      '%g': ('' + getThursday().getFullYear()).slice(2),
      '%H': zeroPad(nHour, 2),
      '%I': zeroPad((nHour + 11) % 12 + 1, 2),
      '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth > 1 && isLeapYear()) ? 1 : 0), 3),
      '%k': '' + nHour,
      '%l': (nHour + 11) % 12 + 1,
      '%m': zeroPad(nMonth + 1, 2),
      '%M': zeroPad(date.getMinutes(), 2),
      '%p': (nHour < 12) ? 'AM' : 'PM',
      '%P': (nHour < 12) ? 'am' : 'pm',
      '%s': Math.round(date.getTime() / 1000),
      '%S': zeroPad(date.getSeconds(), 2),
      '%u': nDay || 7,
      '%V': (function() {
        var target = getThursday(),
          n1stThu = target.valueOf()
        target.setMonth(0, 1)
        var nJan1 = target.getDay()
        if (nJan1 !== 4) target.setMonth(0, 1 + ((4 - nJan1) + 7) % 7)
        return zeroPad(1 + Math.ceil((n1stThu - target) / 604800000), 2)
      })(),
      '%w': '' + nDay,
      '%x': date.toLocaleDateString(),
      '%X': date.toLocaleTimeString(),
      '%y': ('' + nYear).slice(2),
      '%Y': nYear,
      '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
      '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
    }[sMatch] || sMatch
  })
}

export default {

  array_as_string: function(value, column, table) {
    return value.join(',')
  },

  date_time: function(value, column, table) {
    if (!value) {
      return value
    }
    return strftime('%Y-%m-%d %H:%M:%S', new Date(value))
  },

  from_options: function(value, column, table) {
    return table.translate(''.concat('form.options.', column.name, '.', value))
  },

  from_store: (value, column, table) => {
    return table.config.stores[column.storeKey][value]
  },

  from_instance: function(value, column, table) {
    try {
      const store = typeof table[column.storeKey] !== 'undefined' ? table[column.storeKey] : table.preloadStore(column.storeKey)
      return store[value][column.storeLabelKey]
    } catch(e) {
      return value
    }
  },
  from_boot: function(value, column, table) {
    try {
      return table.$store.getters['bootData'][column.storeKey][value][column.storeLabelKey]
    } catch(e) {
      return value
    }
  },

  array_from_boot: function(value, column, table) {
    return value.map((i) => this.from_boot(i, column, table)).join(',')
  },

  array_from_instance: function(value, column, table) {
    return value.map((i) => this.from_instance(i, column, table)).join(',')
  },

  formatted_integer: function(value, column, table) {
    return Number.parseInt(value).toLocaleString()
  },

  array_as_string_options: function(value, column, table) {
    return value.map((i) => table.translate(''.concat('form.options.', column.name, '.', i))).join(',')
  },

  json_string: function(value, column, table) {
    return JSON.stringify(value)
  },

  hash_as_string: function(value, column, table) {
    var val = JSON.parse(value)
    return Object.keys(val).map(key => (key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ') + ': ' + val[key])).join(' | ')
  }

}
