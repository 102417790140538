<template lang="pug" src="./../templates/table.pug" />

<script>
import table from './../../mixin/table'
import common from './../../mixin/common'
import table_remote from './../../mixin/table/remote'
import di from './../../mixin/di'
import component from './../../mixin/component'

export default {
  name: 'CrudTable',
  mixins: [component, common, table, table_remote, di]
}
</script>
