import substitutionRankings from './substitution/from_rankings'

export default {
  namespace: 'avia',
  component: 'substitution',
  meta: {
    title: 'avia.substitution',
    icon: 'table'
  },
  tabs: [
    substitutionRankings
  ]
}
