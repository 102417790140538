import items from './bookings/items'

export default {
  namespace: 'orders',
  component: 'bookings',
  meta: {
    title: 'orders.bookings',
    icon: 'skill'
  },
  tabs: [
    items
  ]
}
