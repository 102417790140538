<template>
  <div class="tab-container" :class="classObj">
    <component
      :is="componentFilter"
      v-if="isTopFilter"
      :config="config"
      :tab="tab"
      :item="item"
      @hook:mounted="hookChildMounted('filter')"
    />
    <component
      :is="componentPanel"
      :config="config"
      :tab="tab"
      :item="item"
      @hook:mounted="hookChildMounted('panel')"
    />
    <div class="list-content" :class="{'filter__right': isRightFilter, 'filter__top': isTopFilter}">
      <component
        :is="componentTable"
        :config="config"
        :tab="tab"
        :item="item"
        @hook:mounted="hookChildMounted('table')"
      />
      <component
        :is="componentPanelBottom"
        :config="config"
        :tab="tab"
        :item="item"
        @hook:mounted="hookChildMounted('panel_bottom')"
      />
    </div>
    <component
      :is="componentFilter"
      v-if="isRightFilter"
      :config="config"
      :tab="tab"
      :item="item"
      @hook:mounted="hookChildMounted('filter')"
    />
    <component :is="componentForm" :config="config" :tab="tab" :item="item" @hook:mounted="hookChildMounted('form')" />
    <component :is="componentHistory" :config="config" :tab="tab" :item="item" @hook:mounted="hookChildMounted('history')" />
  </div>
</template>

<script>
import common from './../../mixin/common'
import item from './../../mixin/tabs/item'

export default {
  name: 'CrudItem',
  mixins: [common, item],
  props: {
    config: {
      required: true,
      type: Object
    },
    tab: {
      required: true,
      type: String,
      default() {
        return this.$elContainer.tab
      }
    },
    item: {
      required: true,
      type: String,
      default() {
        return this.$elTab.item
      }
    }
  }
}
</script>
