import BookingStruct from '@/settings/modules/avia/general/booking_struct'

export default {
  name: 'items',
  items: [
    {
      name: 'avia_booking_list',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaBookingItems',
        queries: {
          index: {
            id: true,
            locator: true,
            createdAt: true,
            timelimit: true,
            status: true,
            departureTime: true,
            testOrder: true,
            depot: {
              id: true,
              name: true
            },
            trafficSource: {
              id: true,
              name: true
            },
            locations: {
              departureDate: true,
              departureTime: true,
              departureLocation: true,
              arrivalLocation: true
            },
            comments: {
              data: true,
              createdAt: true,
              backendUser: {
                login: true
              }              
            },
            detailedInformation: true
          }
        },
        preloadFields: [
          'feedOperatorQueueList{id name}',
          'feedAttentionList{id name}',
          'feedBspList{id name}',
          'feedGdsList{id name}',
          'feedTicketingOfficeList{id name}',
          'feedAdditionalServicesList{id name}',
          'feedAncillaryCategoryList{id name}',
          'feedPaymentSystemList{id name}',
        ]
      },
      preload: true,
      preloadStores: [
        'feedOperatorQueueList',
        'feedAttentionList',
        'feedBspList',
        'feedGdsList',
        'feedTicketingOfficeList',
        'feedAdditionalServicesList',
        'feedAncillaryCategoryList',
        'feedPaymentSystemList'
      ],
      actions: {
        row: [],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          }
        ],
        searchBy: [
          {
            name: 'locator',
            operator: 'eq',
            valueType: 'string',
            scope: 'SEARCH_BY',
            selected: true
          },
          {
            name: 'vnd_locators',
            operator: 'like',
            valueType: 'string',
            scope: 'bookings'
          },
          {
            name: 'partner_order_id',
            operator: 'eq',
            valueType: 'integer',
            scope: 'SEARCH_BY'
          },
          {
            name: 'eticket_number',
            operator: 'eq',
            valueType: 'string',
            scope: 'SEARCH_BY'
          },
          {
            name: 'user_email',
            operator: 'eq',
            valueType: 'string',
            scope: 'SEARCH_BY'
          },
          {
            name: 'user_phone',
            operator: 'eq',
            valueType: 'string',
            scope: 'SEARCH_BY'
          },
          {
            name: 'user_last_name',
            operator: 'eq',
            valueType: 'string',
            scope: 'SEARCH_BY'
          },
          {
            name: 'comment',
            operator: 'like',
            valueType: 'string',
            scope: 'REWRITE_ON_BACKEND'
          },
          {
            name: 'ticketing_comment',
            operator: 'like',
            valueType: 'string',
            scope: 'REWRITE_ON_BACKEND'
          },
          {
            name: 'user_id',
            operator: 'eq',
            valueType: 'integer',
            scope: 'bookings'
          },
          {
            name: 'id',
            operator: 'eq',
            valueType: 'integer',
            scope: 'bookings'
          },
          {
            name: 'traffic_source_id',
            operator: 'eq',
            valueType: 'integer',
            scope: 'bookings'
          },
          {
            name: 'node_id',
            operator: 'eq',
            valueType: 'integer',
            scope: 'bookings'
          },
          {
            name: 'depot_id',
            operator: 'eq',
            valueType: 'integer',
            scope: 'bookings'
          },
          {
            name: 'session_id',
            operator: 'eq',
            valueType: 'string',
            scope: 'SEARCH_BY'
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'issueQueue',
            collapsed: true,
            items: [
              {
                name: 'operator_queue',
                operator: 'eq',
                scope: 'OPERATOR_QUEUE',
                type: 'select',
                storeKey: 'feedOperatorQueueList',
                storeLabelKeys: ['id', 'name'],
                valueType: 'integer'
              }
            ]
          },

          {
            legend: 'pnrData',
            collapsed: false,
            items: [
              {
                name: 'status',
                operator: 'in',
                type: 'multiselect',
                scope: 'REWRITE_ON_BACKEND',
                store: [
                  { id: 'PN', name: 'Payed. Not issued', colorTag: 'avia-status-PN' },
                  { id: 'PC', name: 'Cancelled. Payed', colorTag: 'avia-status-PC' },
                  { id: 'QB' , name: 'Payed. Waiting for QUEUE', colorTag: 'avia-status-QB' },
                  { id: 'LCP', name: 'Payed after cancellation', colorTag: 'avia-status-LCP' },
                  { id: 'R', name: 'Payed. Refunded', colorTag: 'avia-status-R' },
                  { id: 'P', name: 'Payed. Issued', colorTag: 'avia-status-P' },
                  { id: 'FK' , name: 'Fake Booking Process', colorTag: 'avia-status-FK' },
                  { id: 'FKE' , name: 'Fake Booking Error', colorTag: 'avia-status-FKE' },
                  { id: 'CR', name: 'Payed. Waiting for refund', colorTag: 'avia-status-CR' },
                  { id: 'OR', name: 'Payed. Order refund', colorTag: 'avia-status-OR' },
                  { id: 'WD', name: 'Expected of return', colorTag: 'avia-status-WD' },
                  { id: 'WA', name: 'Waiting. Pay confirm (Preauth)', colorTag: 'avia-status-WA' },
                  { id: 'CWA', name: 'Cancelled by preauthorization', colorTag: 'avia-status-CWA' },
                  { id: 'C', name: 'Cancelled. Out of time', colorTag: 'avia-status-C' },
                  { id: 'CU', name: 'Cancelled by user', colorTag: 'avia-status-CU' },
                  { id: 'CO', name: 'Cancelled by operator', colorTag: 'avia-status-CO' },
                  { id: 'CL', name: 'Cancelled. Funds unlocked', colorTag: 'avia-status-CL' },
                  { id: 'NP', name: 'Pay not confirm', colorTag: 'avia-status-NP' },
                  { id: 'PCU', name: 'Payment is not confirmed', colorTag: 'avia-status-PCU' },
                  { id: 'W', name: 'Waiting. Not payed', colorTag: 'avia-status-W' },
                  { id: 'NC', name: 'Need confirmation from manager', colorTag: 'avia-status-NC' }
                ],
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'status_reason',
                operator: 'eq',
                type: 'select',
                scope: 'bookings',
                store: [
                  { id: 1, name: 'tkxl in booking' },
                  { id: 2, name: 'irrelevant for customer' },
                  { id: 3, name: 'insufficient funds' },
                  { id: 4, name: 'card acceptance blocked by airline' },
                  { id: 5, name: 'incorrect passenger info' },
                  { id: 6, name: 'airline canceled seats' },
                  { id: 7, name: 'Fraud' },
                  { id: 8, name: 'Specify reason' },
                  { id: 9, name: 'partial payment' },
                  { id: 10, name: 'change of fare' },
                  { id: 11, name: 'Double' },
                  { id: 12, name: 'MultiFOP' },
                  { id: 13, name: 'No Interline' },
                  { id: 14, name: 'UC/NO' },
                  { id: 15, name: 'layover isn\'t long enough for connection' },
                ],
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'attentions',
                operator: 'find_in_set',
                type: 'select',
                storeKey: 'feedAttentionList',
                storeLabelKeys: ['id', 'name'],
                scope: 'bookings'
              },

              // Booking params
              {
                type: 'divider',
                name: 'Booking params',
                position: 'left'
              },
              {
                name: 'avia_config_item_id',
                operator: 'eq',
                type: 'multiselect',
                scope: 'REWRITE_ON_BACKEND',
                storeKey: 'config_items',
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'bsp_config_item_id',
                operator: 'eq',
                type: 'multiselect',
                scope: 'REWRITE_ON_BACKEND',
                storeKey: 'feedBspList',
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'gds_config_item_id',
                operator: 'eq',
                type: 'multiselect',
                scope: 'REWRITE_ON_BACKEND',
                storeKey: 'feedGdsList',
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'date',
                operator: 'btw',
                type: 'datetimerange',
                options: { firstDayOfWeek: 1 },
                valueFormat: 'yyyy-MM-dd HH:mm',
                valueType: 'timestamp',
                scope: 'bookings'
              },

              // Issue params
              {
                type: 'divider',
                name: 'Issue params',
                position: 'left'
              },
              {
                name: 'ticketing_offices',
                operator: 'eq',
                type: 'select',
                scope: 'REWRITE_ON_BACKEND',
                storeKey: 'feedTicketingOfficeList',
                storeLabelKeys: ['id', 'name']
              },
              {
                name: 'ticketing_time',
                operator: 'btw',
                type: 'datetimerange',
                options: { firstDayOfWeek: 1 },
                valueFormat: 'yyyy-MM-dd HH:mm',
                valueType: 'datetime',
                scope: 'bookings'
              },
              {
                name: 'validating_supplier',
                operator: 'find_in_set',
                type: 'input',
                scope: 'bookings'
              },
              {
                name: 'marketing_supplier',
                operator: 'eq',
                type: 'input',
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'autotckt',
                operator: 'eq',
                value: 1,
                type: 'checkbox',
                scope: 'bookings'
              }
            ]
          },
          
          {
            legend: 'depotData',
            collapsed: true,
            items: [
              {
                name: 'depot_id',
                operator: 'eq',
                type: 'multiselect',
                scope: 'bookings',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name']
              },

              {
                name: 'traffic_source_id',
                operator: 'eq',
                type: 'multiselect',
                scope: 'bookings',
                storeKey: 'traffic_sources',
                storeLabelKeys: ['id', 'name']
              }
            ]            
          },

          {
            legend: 'showData',
            collapsed: true,
            items: [
              // Exclude bookings
              {
                type: 'divider',
                name: 'Exclude bookings',
                position: 'left'                
              },
              {
                name: 'exclude_conso_queue',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'                
              },
              {
                name: 'exclude_backlog_queue',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'                
              },
              {
                name: 'exclude_autoticketing_queue',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'                
              },
              {
                name: 'manual_processing_queue',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'                
              },

              // In-flight service
              {
                type: 'divider',
                name: 'In-flight service',
                position: 'left'
              },

              {
                name: 'has_ancillary_services',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },
              {
                name: 'has_fare_family',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'from_baggage_tab',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'manual_emd',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND',
                tooltip: 'Show bookings from the last 7 days'
              },
              {
                name: 'baggage_gds',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND',
                tooltip: 'Show bookings from the last 7 days'
              },
              {
                name: 'baggage_tab_manual',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND',
                tooltip: 'Show bookings from the last 7 days'
              },

              // For issue
              {
                type: 'divider',
                name: 'For issue',
                position: 'left'
              },
              {
                name: 'urgent',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'not_urgent',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'urgent_h',
                operator: 'eq',
                type: 'input',
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'is_rebooked',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'explorer_check',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'price_component_partially_issued',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'exclude_tickets_kz',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },

              // Book type
              {
                type: 'divider',
                name: 'Book type',
                position: 'left'
              },
              {
                name: 'ground_segments',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'infants',
                labelKey: 'withInfants',
                operator: 'gte',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },
              {
                name: 'is_charter',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },
              {
                name: 'test_order',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },
              {
                name: 'exclude_conv_booking',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND',
                default: 1,
                checked: true
              },
              {
                name: 'conv_booking',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'self_transfers',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'not_actually',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },

              // Pricing type
              {
                type: 'divider',
                name: 'Pricing type',
                position: 'left'
              },
              {
                name: 'is_multi_fare',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'is_multi',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },
              {
                name: 'is_multi_gds',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },
              {
                name: 'gmt_only',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'with_auction',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'ttn_avia_vi',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'with_dynamic_commission',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'price_component_gte',
                operator: 'eq',
                type: 'input',
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'price_component_lte',
                operator: 'eq',
                type: 'input',
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'is_recommended',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },

              // Fake PNR
              {
                type: 'divider',
                name: 'Fake PNR',
                position: 'left'
              },
              {
                name: 'all_fake_booking',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'all_fake_success',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'fake_only_in_process',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'fake_exclude_in_process',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'fake_only_with_errors',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'fake_pnr_excluded',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'fake_recc_excluded',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },

              // Other
              {
                type: 'divider',
                name: 'Other',
                position: 'left'
              },
              {
                name: 'booking_by_legal_person',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'bookings'
              },
              {
                name: 'with_special_fare',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'with_special_fare_search',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'fare_downgrade',
                operator: 'eq',
                type: 'checkbox',
                value: 1,
                scope: 'REWRITE_ON_BACKEND'
              }
            ]
          },

          {
            legend: 'addonData',
            collapsed: true,
            items: [
              {
                name: 'additional_services',
                operator: 'eq',
                type: 'select',
                scope: 'REWRITE_ON_BACKEND',
                storeKey: 'feedAdditionalServicesList',
                storeLabelKeys: ['name']
              },
              {
                name: 'ancillary_categories',
                operator: 'eq',
                type: 'select',
                scope: 'REWRITE_ON_BACKEND',
                storeKey: 'feedAncillaryCategoryList',
                storeLabelKeys: ['name']
              },
              {
                name: 'discount_info',
                operator: 'like',
                type: 'select',
                valueType: 'string',
                scope: 'bookings',
                store: [
                  { id: 'new_app_discount', name: 'New app discount' },
                ],
                storeLabelKeys: ['name']
              },
            ]            
          },

          {
            legend: 'paymentData',
            collapsed: true,
            items: [
              {
                name: 'pay_system',
                operator: 'in',
                type: 'multiselect',
                scope: 'bookings',
                storeKey: 'feedPaymentSystemList',
                storeLabelKeys: ['id', 'name']
              },
            ]            
          },

          {
            legend: 'other',
            collapsed: true,
            items: [
              {
                type: 'divider',
                name: 'User comments',
                position: 'left'
              },
              {
                name: 'comment',
                labelKey: 'commentText',
                operator: 'eq',
                type: 'input',
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'ticketing_comment',
                value: 1,
                type: 'checkbox',
                operator: 'eq',
                scope: 'REWRITE_ON_BACKEND'
              },
              {
                name: 'manual_processing',
                value: 1,
                type: 'checkbox',
                operator: 'eq',
                scope: 'REWRITE_ON_BACKEND'
              }
            ]            
          }
        ]
      }
    },
    {
      name: 'avia_booking_show',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaBookingItemDetail',
        queries: {
          index: { ...BookingStruct.FullStruct }
        }
      },
      pagination: false,
      disabled: true,
      autoLoad: false,
      actions: {
        row: [],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          }
        ]
      },
      filter: {
        position: 'top',
        items: [
          {
            name: 'id',
            type: 'input',
            operator: 'eq',
            scope: 'bookings',
            title: 'ID'
          },
          {
            name: 'locator',
            type: 'input',
            operator: 'eq',
            scope: 'bookings',
            title: 'Locator'
          }
        ]        
      }
    }
  ]
}