<template lang="pug">
  div
    el-collapse
      el-collapse-item(v-for="(stataByAttempt, attemptIndex) in decode(searchStat)", :name='"pc_goupped_attempts" + attemptIndex' :key='"pc_goupped_attempts" + attemptIndex' style="margin-top:10px;")
        template(slot="title")
            span Attempt:&nbsp;
              b {{attemptIndex+1}}
            span &nbsp;| &nbsp;
            span Status:&nbsp;
              b {{stataByAttempt["t"]}}
            span &nbsp;| &nbsp;
            span Time:&nbsp;
              b {{stataByAttempt["d"] | moment("LLL")}}
        el-row(v-for="(groupSearch, grouptIndex) in getByGroup(stataByAttempt)", :key='"pc_goupped_group" + grouptIndex')
          h4 Group: {{grouptIndex}}

            el-table(:data="groupSearch", :row-class-name="tablePriceClassName")
              el-table-column(label="Locator")
                template(slot-scope="scp")
                  span {{ scp.row[0] }}
              el-table-column(label="Session")
                template(slot-scope="scp")
                  span {{ scp.row[1] }}
              el-table-column(label="Fares")
                template(slot-scope="scp")
                  span {{ scp.row[9] }}
              el-table-column(label="Search Config")
                template(slot-scope="scp")
                  span {{ scp.row[8].join(", ") }}
              el-table-column(label="Formula Price")
                el-table-column(label="PC")
                  template(slot-scope="scp")
                    span {{ scp.row[2] }}
                el-table-column(label="New")
                  template(slot-scope="scp")
                    span {{ scp.row[3] }}

                el-table-column(label="PriceScheme")
                  el-table-column(label="startP" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "st_p", false) }}
                  el-table-column(label="M" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "0", true) }}
                  el-table-column(label="I" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "1", true) }}
                  el-table-column(label="C" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "2", true) }}
                  el-table-column(label="P" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "3", true) }}
                  el-table-column(label="S" width="70px")
                    template(slot-scope="scope")
                      div S: {{ sumColumn(scope.row[7], "4", true) }}
                      div Rec: {{ sumColumn(scope.row[7], "segment_cost_amount", false) }}
                      div Book: {{ sumColumn(scope.row[7], "original_cost_amount", false) }}
                  el-table-column(label="A" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "5", true) }}
                  el-table-column(label="RD" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "6", true) }}
                  el-table-column(label="R" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "20", true) }}
                  el-table-column(label="Meta" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "meta", false) }}
                  el-table-column(label="BspC" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "bsp_c", false) }}
                  el-table-column(label="RateM" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "rate_margin", false) }}
                  el-table-column(label="URate" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "u_rate_margin", false) }}
                  el-table-column(label="Revenue" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "revenue_margin", false) }}
                  el-table-column(label="SaleP" width="70px")
                    template(slot-scope="scope")
                      span {{ sumColumn(scope.row[7], "sl_p", false) }}
                el-table-column(label="Baggage Match" width="70px")
                  template(slot-scope="scope")
                    i(:class="getIcon(scope.row[5])")
                el-table-column(label="Price Ok?" width="70px")
                  template(slot-scope="scope")
                    i(:class="getIcon(scope.row[4])")
</template>

<script>

import priceLog from './priceLog'

export default {

  name: 'attempts',
  components: {
    priceLog,
  },
  props: {
    searchStat: String
  },

  data() {
    return {
    }
  },

  methods: {

    getByGroup(stataByAttempt){
      return stataByAttempt["s"];
    },

    decode(item) {
      return JSON.parse(item).flat();
    },

    getIcon(item) {
      return item ? 'el-icon-close' : 'el-icon-check'
    },

    tablePriceClassName({ row, rowIndex }) {
      return (row[4] == false && row[5] == false) ? 'status_price_ok' : ''
    },

    sumColumn(stata, column, rl) {
      var sum = 0
      stata.forEach((element) => {
        if(rl == true) {
          sum += parseFloat(element['rl'][column]['m'])
        }else{
          sum += parseFloat(element[column])
        }
      })
      return sum
    }

  }
}

</script>
