export default {
  table: {
    active: 'Active',
    gdsId: 'GDS ID',
    validatingSupplier: 'Supplier',
    priority: 'Priority',
    rbd: 'RBD',
    fareBasis: 'Fare basis',
    fareCode: 'Farecode',
    penaltyBeforeDepartureAmount: 'Before Departure',
    penaltyBeforeDepartureCurrency: 'Before Dep. Cur',
    penaltyBeforeDepartureNonRef: 'Before Dep. Non',
    penaltyAfterDepartureAmount: 'After Departure',
    penaltyAfterDepartureCurrency: 'After Dep.  Cur',
    penaltyAfterDepartureNonRef: 'After Dep.  Non',
    penaltyNoshowAmount: 'No show',
    penaltyNoshowCurrency: 'No show   Cur',
    penaltyNoshowNonRef: 'No show   Non',
    bsp: 'BSP',
    gds: 'GDS',
    actions: 'Actions',
    noShowTime: 'No Show Time (before departure)',
    nonRefTax: 'Non-refundable TAX',
    refundableTax: 'Refundable TAX',
    void: 'Void',
    voidSwitcher: 'After issue time / To the end of the Issue day',
    beforeDeparture: 'Before',
    beforeDepartureAddFee: 'Before Add',
    beforeDepartureChangeTo: 'Before Change',
    afterDeparture: 'Before',
    afterDepartureAddFee: 'Before Add',
    afterDepartureChangeTo: 'Before Change',
    noShow: 'Before',
    noShowAddFee: 'Before Add',
    noShowChangeTo: 'Before Change'
  },
  form: {
    fields: {
      id: 'ID',
      active: 'Active',
      validatingSupplier: 'Validating Supplier',
      priority: 'Priority',
      gds: 'GDS',
      bsp: 'BSP',
      rbd: 'RBD',
      fareBasis: 'Fare basis',
      fareCode: 'Farecode',
      penaltyBeforeDepartureAmount: 'Before Dep. Amount',
      penaltyBeforeDepartureCurrency: 'Before Dep. Cur',
      penaltyBeforeDepartureNonRef: 'Before Dep. Non-refundable',
      penaltyAfterDepartureAmount: 'After Dep. Amount',
      penaltyAfterDepartureCurrency: 'After Dep. Cur',
      penaltyAfterDepartureNonRef: 'After Dep. Non-refundable',
      penaltyNoshowAmount: 'No show Amount',
      penaltyNoshowCurrency: 'No show Cur',
      penaltyNoshowNonRef: 'No show Non-refundable',
      noShowTime: 'No Show Time (minutes bef Dep)',
      nonRefTax: 'NonRef TAX',
      refundableTax: 'Refundable TAX',
      void: 'Void',
      voidSwitcher: 'After issue time (false)/ To the end of the Issue day (true)',
      beforeDeparture: 'Before',
      beforeDepartureAddFee: 'Add',
      beforeDepartureChangeTo: 'Change',
      beforeDepartureNonRef: 'NonRef',
      afterDeparture: 'After',
      afterDepartureAddFee: 'Add',
      afterDepartureChangeTo: 'Change',
      afterDepartureNonRef: 'NonRef',
      noShow: 'No Show',
      noShowAddFee: 'Add',
      noShowChangeTo: 'Change',
      noShowNonRef: 'NonRef',
      ruleText: 'Text'
    },
    legends: {
      general_data: 'General data',
      route_data: 'Route data',
      flight_data: 'Flight data',
      fare_data: 'Fare data',
      penalty_before_departure: 'Penalty before departure',
      penalty_after_departure: 'Penalty after departure',
      penalty_noshow: 'Penalty no show',
      taxes: 'Taxes',
      template_data: 'Template Data',
      rule_text: 'Template',
      fare_rule_data: 'Rule',
      main_rule_data: 'Main rule data'
    },
    placeholder: {
      ruleText: 'Insert fare rule text here'
    },
    buttons: {
      parse_rule: 'Parse Text',
      save_rule: 'Save Rule'
    }
  }
}