<template lang=pug>
   el-dialog(:title="translate('manageSeatsWindow.title')" :visible.sync="dialogFormVisible")
      el-form(:model="form")
        el-form-item(:label="translate('manageSeatsWindow.form.bookingId')" :label-width="formLabelWidth")
          el-input(v-model="form.bookingId" width="200px")

        el-form-item(:label="translate('manageSeatsWindow.form.bookingLocator')" :label-width="formLabelWidth")
          el-input(v-model="form.bookingLocator" width="200px")

        el-form-item(:label="translate('manageSeatsWindow.form.locator')" :label-width="formLabelWidth")
          el-input(v-model="form.locator" width="200px")

        el-form-item(:label="translate('manageSeatsWindow.form.seatsCount')" :label-width="formLabelWidth")
          el-input(v-model="form.seatsCount" width="200px")

        el-form-item(:label="translate('manageSeatsWindow.form.justLog')" :label-width="formLabelWidth")
          el-checkbox(v-model="form.justLog" width="200px")

      span(slot="footer" class="dialog-footer" v-loading="changeLoading")
        el-button(@click="dialogFormVisible = false" type="danger") {{ translate('form.cancel') }}
        el-button(@click="actionPerform" type="primary" :disabled="changeLoading") {{ translate('form.confirm') }}
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  computed:{
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    }
  },
  data: function() {
    return {
      editRow: {},
      dialogFormVisible: false,
      form: {
        bookingId: null,
        bookingLocator: null,
        locator: null,
        seatsCount: null,
        justLog: null
      },
      changeLoading: false,
      formLabelWidth: '120px'
    }
  },

  mounted() {
    this.$parent.$on('showManageSeatsWindow', (row) => {
      this.editRow = row
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showManageSeatsWindow')
  },

  methods: {
    reset() {
      this.changeLoading = false
      this.form.bookingId = null
      this.form.bookingLocator = null
      this.form.locator = null
      this.form.seatsCount = null
      this.form.justLog = null
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    actionPerform() {
      if(this.changeLoading){ return }
      this.changeLoading = true
      this.performRequest(this.form)
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            id: parseInt(this.editRow.id),
            seatsCount: parseInt(form.seatsCount),
            bookingId: form.bookingId,
            bookingLocator: form.bookingLocator,
            locator: form.locator,
            justLog: form.justLog
          }
        }

        await this.graphql('aviaSeatsBlockRuleManageSeat', query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('cloneWindow.msg.title'),
          message: this.translate('manageSeatsWindow.msg.success'),
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('cloneWindow.msg.title'),
          message: this.translate('cloneWindow.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
      this.$elItem.$emitAction('refresh')
    }

  }
}
</script>
