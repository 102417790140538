<template lang="pug" src="./../templates/panel_bottom.pug" />

<script>
import common from './../../mixin/common'
import panel from './../../mixin/panel'
import di from './../../mixin/di'
import component from './../../mixin/component'

export default {
  name: 'CrudPanelBottom',
  mixins: [component, common, di, panel]
}
</script>
