<template lang="pug">
  el-dialog(:fullscreen="false" :visible.sync="visible" width="80%" @closed="onDialogClose" custom-class="all_results_dialog" append-to-body)
    el-card
      variant-services(:descriptions="descriptions")
    all-results-listing(v-for="(item, i_key) in items" :item="item" :key="`results_${i_key}`")
</template>

<script>
import AllResultsListing from '../_partials/all_results_listing'
import VariantServices from '../_partials/variant_services'

export default {
  components: {
    AllResultsListing,
    VariantServices
  },
  data() {
    return {
      visible: false,
      items: [],
      descriptions: {}
    }
  },
  mounted() {
    this.$root.$on('showAllResultsDialog', (item) => {
      this.visible = true
      this.items = item.items
      this.descriptions = item.descriptions
    })
  },

  methods: {
    onDialogClose() {
      this.items = []
    }
  }
}
  
</script>