<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'
import gdsStore from '../../../../../../settings/modules/avia/general/gds_store'

export default {
  components: {
    BaseTable
  },
  computed: {
    gdsList() { return gdsStore.objectById() }
  },
  extends: BaseTable,
  methods: {
    formatExpandedRow(row) {
      console.log()
      return `<b>Office ID:</b>  ${row['officeId'].join(',')}<br/>
              <b>DriverId ID:</b>  ${row['driverId'].map(x => this.gdsList[x]).join(',')}<br/>
              <b>Routes:</b>  ${row['routes']}<br/>
              <b>Markups:</b><br/>
              ${row['markups'].map(r => { return ` - ${[this.config.stores['paxes'][r['pax_type']], r['days_range'], r['value'], `${r['percent_value']}% from ${this.config.stores['percentFrom'][r['percent_from']]}`].join(' - ')}`}).join('<br>')}

              `
    }
  }
}
</script>
