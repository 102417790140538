import gdsStore from '../general/gds_store'

export default {
  name: 'google_flights_feed_settings',
  meta: {
    title: 'feed_settings',
    icon: 'skill',
    permission: 'feed_settings'
  },
  lazy: true,
  items: [
    {
      name: 'google_flights_feed_settings',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaGoogleFlightsFeedSettings',
        queries: {
          index: {
            id: true,
            airlines: true,
            routes: true,
            trafficSourceIds: true,
            routeTypes: true,
            flightType: true,
            departurePeriod: true,
            timelimit: true,
            drivers: true,
            officeIds: true,
            forceReprice: true,
            forceRepriceOptions: {
              aviaConfigItemsIds: true,
              hoursToProcess: true,
              routeTypes: true,
              lastPerformTime: true
            },
            active: true,
            updatedAt: true
          }
        },
        preloadFields: ['feedTrafficSources{id name}']
      },
      preload: true,
      preloadStores: ['feedTrafficSources'],
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'airlines',
            format: 'array_as_string'
          },
          {
            name: 'trafficSourceIds',
            width: '150px',
            // format: 'array_as_string_options'
            format: 'array_from_instance',
            storeKey: 'feedTrafficSources',
            storeLabelKey: 'name'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'change_settings',
            icon: 'el-icon-magic-stick',
            type: 'info',
            params: {
              entity: 'google_flights'
            }
          },
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::GoogleFlights::FeedSettingRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'airlines',
            type: 'text',
            operator: 'in_array_str',
            camelizeLangKey: true
          },
          {
            name: 'traffic_source_ids',
            type: 'select',
            storeKey: 'feedTrafficSources',
            operator: 'in_array_int',
            storeLabelKeys: ['id', 'name']
          },
          {
            name: 'drivers',
            type: 'select',
            store: gdsStore.arrayById(),
            operator: 'in_array_int',
            storeLabelKeys: ['id', 'name']
          },
          {
            name: 'routes',
            type: 'text',
            operator: 'like',
            camelizeLangKey: true
          },
          {
            name: 'active',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              '': 'All',
              true: 'Yes',
              false: 'No'
            }
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'airlines',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'routes',
            type: 'textarea',
            required: false,
            rows: 6,
            required: true
          },
          {
            name: 'traffic_source_ids',
            type: 'multiselect',
            storeKey: 'feedTrafficSources',
            default: [],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: true
          },
          {
            name: 'route_types',
            type: 'multiselect',
            store: [
              { id: 1, name: 'OW' },
              { id: 2, name: 'RT' }
            ],
            default: [],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: false
          },
          {
            name: 'flight_type',
            type: 'select',
            store: {
              0: 'all',
              1: 'own',
              2: 'interline'
            },
            default: '0',
            translateOptions: true,
            valueType: 'integer',
            required: false
          },
          {
            name: 'departure_period',
            type: 'text',
            required: false
          },
          {
            name: 'timelimit',
            type: 'text',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'drivers',
            type: 'multiselect',
            store: gdsStore.arrayById(),
            default: [],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: true
          },
          {
            name: 'office_ids',
            type: 'textarea',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ','
          },
          {
            name: 'force_reprice',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'force_reprice_options__avia_config_items_ids',
            type: 'text',
            required: false,
            // valueType: 'array_of_integer',
            // valueDelimiter: ','
          },
          {
            name: 'force_reprice_options__hours_to_process',
            type: 'text',
            required: false,
            // valueType: 'array_of_string',
            // valueDelimiter: ','
          },
          {
            name: 'force_reprice_options__route_types',
            type: 'multiselect',
            store: [
              { id: 1, name: 'OW' },
              { id: 2, name: 'RT' }
            ],
            default: [1, 2],
            storeLabelKeys: ['id', 'name'],
            collapseTags: false,
            splitInput: false,
            required: false
          },
          {
            name: 'force_reprice_options__last_perform_time',
            type: 'hidden',
            required: false
          }
        ]
      }
    }
  ]
}
