<template lang="pug">
  extends /table
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,

  methods: {
    actionDynamicRules(row) {
      this.navigateTab({
        tab: 'dynamic_pricing',
        item: 'dynamic_pricing_rules',
        filters: {
          dynamicPricingRules: [
            {
              field: 'scheme_id', operator: 'eq', value: row.id, prefilled: true
            }
          ]
        }
      })
    },

    async beforeDeleteCallback(rows) {
      if (rows.some((i) => i.rules.length > 0)) {
        throw new Error(this.translate('messages.hasRules'))
      }
    }
  }
}
</script>
