<template lang="pug">
  extends /table
  block expand
    div(class="rs-dv")
      span(class="rs-label") Session ID:
      span(class="rs-value") {{ scope.row.sessionId }}
</template>

<style scoped>
  .rs-dv{ padding-bottom: 5px;  }
  .rs-label{ font-weight: bold; padding-right: 10px; display: inline-block; width: 90px}
  .rs-value{}
  .rs-date{ width: 72px; display: inline-block; padding-right: 10px }
  .rs-tx-left{ text-align: right }
</style>

<script>
import Base from '@crud_combined/table'

export default {
  components: {
    Base
  },
  extends: Base,
  methods: {
    formatCell({row, $column, column, cellValue, index}) {
      switch(column.name) {
        case 'orderLocator':
          return `${cellValue} (${row['bookingId']})`
        case 'backendUser':
          return `${cellValue ? cellValue.login : '-'}`
        default:
          return cellValue
      }
    },
    rowClassName({ row, rowIndex }) {
      switch(row['status']) {
        case 1:
          return 'success-row'
        case 2:
        case 3:
          return 'danger-row'
        default:
          return ''
      }
    }
  }
}
</script>
