export default {
  google_flights_restrictions: {
    form: {
      fields: {
        id: 'ID',
        index: 'Index',
        airlines: 'Airlines',
        routes: 'Routes',
        trafficSourceIds: 'Tr. source',
        rbdTypes: 'Class',
        rbdTypesFor: 'for',
        searchDateFrom: 'Search from',
        searchDateTo: 'to',
        travelDateFrom: 'Travel from',
        travelDateTo: 'to',
        passengerTypes: 'Passenger types',
        minSegments: 'Min. segments',
        flightType: 'Flight type',
        interline: 'Interline',
        active: 'Active',
        updatedAt: 'Updated at',
        minPrice: 'Price from',
        maxPrice: 'to',
        toDepartureHours: 'To dep. hours'
      },
      options: {
        rbdTypes: {
          0: 'all',
          1: 'economy',
          2: 'prem_economy',
          3: 'business',
          4: 'first'
        },
        rbdTypesFor: {
          0: 'All segments',
          1: 'Any segment',
          all: 'All segments',
          any: 'Any segment'
        },
        passengerTypes: {
          0: 'All',
          1: 'CHD + INF',
          all: 'All',
          child: 'CHD + INF'
        },
        flightType: {
          0: 'All',
          1: 'Oneway',
          2: 'Round trip',
          'all': 'All',
          'ow': 'Oneway',
          'rt': 'Round trip',
        },
        interline: {
          0: 'All',
          1: 'No interline',
          2: 'Interline present',
          'all': 'All',
          'no': 'No interline',
          'present': 'Interline present',
        }
      },
      placeholder: {
        minPrice: '50EUR',
        maxPrice: '250EUR',
      }
    },
    table: {
      index: 'Index',
      airlines: 'Airlines',
      routes: 'Routes',
      trafficSourceIds: 'Traffic source',
      rbdTypes: 'Class',
      rbdTypesFor: 'Class for',
      searchDateFrom: 'Search from',
      searchDateTo: 'Search to',
      travelDateFrom: 'Travel from',
      travelDateTo: 'Travel to',
      passengerTypes: 'Passenger types',
      minSegments: 'Min. segments',
      flightType: 'Flight type',
      interline: 'Interline',
      active: 'Active',
      updatedAt: 'Updated at'
    }
  }
}
