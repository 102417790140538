import iataParser from './avia_parser/iata_parser'

export default {
  namespace: 'system_tools',
  component: 'avia_parsers',
  meta: {
    title: 'system_tools.avia_parsers',
    icon: 'tree-table'
  },
  tabs: [
    iataParser
  ]
}
