export default {
  upsale_services_list: {
    table: {
      id : 'ID',
      depotId: 'Depot ID',
      name: 'Name',
      cost: 'Cost',
      currency: 'Currency',
      enabled: 'Enabled',
      addlInfo: 'Rules',
      value: 'Prechecked?',
      createdAt: 'Created At',
      updatedAt: 'Updated At'
    },
    form: {
      fields: {
        depotId: 'Depot ID',
        name: 'Name',
        cost: 'Cost',
        currency: 'Currency',
        enabled: 'Enabled',
        addlInfo: 'Rules',
        value: 'Prechecked?'
      }
    }
  }
}
