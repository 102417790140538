<template lang="pug">
  extends /table
  block expand
    vue-json-pretty(:path="'res'" :data="scope.row.params")
  block custom
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,

  methods: {
    isDisabledAction(action, row) {
      switch(action.name) {
      case 'download':
        return row.status !== 1
      case 'upload':
        return row.status !== 3 || row.cacheKey === ''
      default:
        return false
      }
    },

    actionUpload(row) {
      this.$confirm(this.translate('alert.upload_confirm'), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {

        if(row['status'] != 3) {
          this.$message({
            message: this.translate('messages.upload_error'),
            type: 'error'
          })
        } else{
          this.loading = true
          this.startUpload(row)
        }
      }).catch((e) => {})
    },

    actionDownload(row) {
      if(row['status'] != 1) {
        this.$message({
          message: this.translate('messages.download_error'),
          type: 'error'
        })
      } else{
        this.startDownload(row)
      }
    },
    async startDownload(row) {
      try {
        const query = {
          content: true,
          __args: {
            key: row['filename']
          }
        }

        let result = await this.graphql('metaparserIataTicketFileContent', query, 'collection')

        if(result.content) {
          const blob = new Blob([this.str2bytes(result.content)], { type: 'application/zip' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = row['filename']
          link.click()
          URL.revokeObjectURL(link.href)
        } else{
          this.$message({
            message: this.translate('messages.download_empty_content'),
            type: 'error'
          })
        }

        return true
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    async startUpload(row) {
      try {
        const query = {
          __args: {
            id: row['id']
          }
        }

        let result = await this.graphql('metaparserIataTicketFileUpload', query, 'mutation')
        this.list()
        return result
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    str2bytes (str) {
      var bytes = new Uint8Array(str.length);
      for (var i=0; i<str.length; i++) {
          bytes[i] = str.charCodeAt(i);
      }
      return bytes;
    },

    rowClassName({ row, rowIndex }) {
      if (row.status == '1') {
        return 'success-row'
      } else if (row.status == '2') {
        return 'danger-row'
      } else if (row.status == '3') {
        return 'warning-row'
      }
    }
  }
}
</script>
