import settings from './insurance/settings/settings'
import connections from './insurance/settings/connections'
import packets from './insurance/settings/packets'
import risks from './insurance/settings/risks'
import packetsRisks from './insurance/settings/packets_risks'
import controls from './insurance/controls/controls'

export default {
  insurance: {
    settings: {
      settings: settings,
      connections: connections,
      packets: packets,
      risks: risks,
      packets_risks: packetsRisks,
    },
    controls: {
      controls: controls,
    }
  }
}
