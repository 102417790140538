import request from '@/utils/request'
import settings from '@/settings'

export function login(data) {
  return request({
    url: settings.login.endpoint,
    method: 'post',
    data
  })
}
