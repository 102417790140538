export default {
  namespace: 'mco',
  component: 'kiwi_queue',
  meta: {
    title: 'mco.kiwi_queue',
    icon: 'star'
  },
  tabs: [
    {
      name: 'kiwi_queue',
      meta: {
        title: 'kiwi_queue',
        icon: 'skill'
      },
      items: [
        {
          name: 'kiwi_queue_locators',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'mcoKiwiQueueLocators',
            queries: {
              index: {
                id: true,
                kiwiLocator: true,
                adminLocator: true,
                bookingId: true,
                trigger: true,
                mmbLink: true,
                departureDate: true,
                route: true,
                supplier: true,
                status: true,
                updatedAt: true,
                followers: {
                  id: true
                }
              }
            },
            preloadFields: [
              'triggerList{id name}'
            ]
          },
          preload: true,
          preloadStores: [
            'triggerList'
          ],
          stores: {
            statuses: {
              0: 'New',
              1: 'Awaiting',
              2: 'Success'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'kiwiLocator',
              'adminLocator',
              {
                name: 'trigger',
                format: 'from_store',
                storeKey: 'triggerList',
                format: 'from_instance',
                storeLabelKey: 'name',
                width: '250px'
              },
              'supplier',
              'route',
              {
                name: 'departureDate',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'status',
                format: 'from_store',
                storeKey: 'statuses',
                width: '80px'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '60px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'mmb_link',
                icon: 'el-icon-link',
                type: 'default'
              }
            ],
            panel: [
              {
                name: 'mark_as_success',
                icon: 'el-icon-circle-check',
                type: 'success',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'mark_as_awaiting',
                icon: 'el-icon-circle-check',
                type: 'warning',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'mark_as_new',
                icon: 'el-icon-circle-check',
                type: 'danger',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-date',
                type: 'primary',
                plain: true,
                params: {
                  record_class: 'Mco::KiwiQueue::LocatorRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'locator',
                type: 'text',
                operator: 'eq',
                scope: 'kiwiQueueLocators'
              },
              {
                name: 'trigger',
                type: 'multiselect',
                storeKey: 'triggerList',
                storeLabelKeys: ['name'],
                operator: 'in',
                scope: 'kiwiQueueLocators',
              },
              {
                name: 'departure_date',
                operator: 'eq',
                type: 'date',
                valueFormat: 'yyyy-MM-dd',
                scope: 'kiwiQueueLocators',
                options: {
                  firstDayOfWeek: 1
                }
              },
              {
                name: 'supplier',
                type: 'text',
                operator: 'like',
                scope: 'kiwiQueueLocators'
              },
              {
                name: 'processing_date',
                operator: 'eq',
                type: 'date',
                valueFormat: 'yyyy-MM-dd',
                scope: 'kiwiQueueLocators',
                options: {
                  firstDayOfWeek: 1
                }
              },
              {
                name: 'status',
                type: 'select',
                storeKey: 'statuses',
                operator: 'eq',
                scope: 'kiwiQueueLocators'
              },
              {
                name: 'urgent',
                type: 'text',
                operator: 'eq',
                scope: 'kiwiQueueLocators'
              }
            ]
          }
        },
        {
          name: 'kiwi_queue_refunds',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'mcoKiwiQueueRefunds',
            queries: {
              index: {
                id: true,
                kiwiLocator: true,
                adminLocator: true,
                bookingId: true,
                webhookTrigger: true,
                mmbLink: true,
                status: true,
                updatedAt: true,
                originalKiwiLocator: true,
                reference: true,
                webhookType: true,
                mcoType: true,
                details: true
              }
            },
            preloadFields: [
              'webhookList{id name}',
              'triggerList{id name}'
            ]
          },
          preload: true,
          preloadStores: [
            'webhookList',
            'triggerList'
          ],
          stores: {
            statuses: {
              0: 'New',
              1: 'Awaiting',
              2: 'Success'
            },
            mco_types: {
              0: 'MCO not found',
              1: 'Type not found',
              2: 'Refund',
              3: 'Cancellation'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'kiwiLocator',
              'adminLocator',
              'originalKiwiLocator',
              'reference',
              {
                name: 'webhookType',
                format: 'from_store',
                storeKey: 'webhookList',
                format: 'from_instance',
                storeLabelKey: 'name',
                width: '200px'
              },
              {
                name: 'webhookTrigger',
                format: 'from_store',
                storeKey: 'triggerList',
                format: 'from_instance',
                storeLabelKey: 'name',
                width: '250px'
              },
              {
                name: 'status',
                format: 'from_store',
                storeKey: 'statuses',
                width: '80px'
              },
              {
                name: 'mcoType',
                format: 'from_store',
                storeKey: 'mco_types'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'mmbLink',
                width: '100px',
                sortable: false
              },
              {
                name: 'expand',
                type: 'expand',
                width: '50',
              }
            ]
          },
          actions: {
            panel: [
              {
                name: 'mark_as_success',
                icon: 'el-icon-circle-check',
                type: 'success',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'mark_as_awaiting',
                icon: 'el-icon-circle-check',
                type: 'warning',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'mark_as_new',
                icon: 'el-icon-circle-check',
                type: 'danger',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-date',
                type: 'primary',
                plain: true,
                params: {
                  record_class: 'Mco::KiwiQueue::RefundRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'locator',
                type: 'text',
                operator: 'eq',
                scope: 'kiwiQueueRefunds'
              },
              {
                name: 'webhook_type',
                type: 'multiselect',
                storeKey: 'webhookList',
                storeLabelKeys: ['name'],
                operator: 'in',
                scope: 'kiwiQueueRefunds',
              },
              {
                name: 'webhook_trigger',
                type: 'multiselect',
                storeKey: 'triggerList',
                storeLabelKeys: ['name'],
                operator: 'in',
                scope: 'kiwiQueueRefunds',
              },
              {
                name: 'mco_type',
                type: 'select',
                storeKey: 'mco_types',
                operator: 'eq',
                scope: 'kiwiQueueRefunds'
              },
              {
                name: 'status',
                type: 'select',
                storeKey: 'statuses',
                operator: 'eq',
                scope: 'kiwiQueueRefunds'
              }
            ]
          }
        },
        {
          name: 'kiwi_queue_issues',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'mcoKiwiQueueIssues',
            queries: {
              index: {
                id: true,
                kiwiLocator: true,
                adminLocator: true,
                bookingId: true,
                webhookType: true,
                webhookTrigger: true,
                mmbLink: true,
                status: true,
                updatedAt: true,
                departureDate: true,
                supplier: true,
                comment: true
              }
            },
            preloadFields: [
              'webhookList{id name}',
              'triggerList{id name}'
            ]
          },
          preload: true,
          preloadStores: [
            'webhookList',
            'triggerList'
          ],
          stores: {
            statuses: {
              0: 'New',
              1: 'Awaiting',
              2: 'Success'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px'
              },
              'kiwiLocator',
              'adminLocator',
              {
                name: 'webhookType',
                format: 'from_store',
                storeKey: 'webhookList',
                format: 'from_instance',
                storeLabelKey: 'name',
                width: '140px'
              },
              {
                name: 'webhookTrigger',
                format: 'from_store',
                storeKey: 'triggerList',
                format: 'from_instance',
                storeLabelKey: 'name',
                width: '220px'
              },
              {
                name: 'status',
                format: 'from_store',
                storeKey: 'statuses',
                width: '80px'
              },
              {
                name: 'departureDate',
                width: '120px',
                format: 'date_time'
              },
              'supplier',
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'comment',
                width: '200px',
                sortable: false
              },
              {
                name: 'mmbLink',
                width: '70px',
                sortable: false
              },
              {
                name: 'expand',
                type: 'expand',
                width: '30px'
              }
            ]
          },
          actions: {
            panel: [
              {
                name: 'mark_as_success',
                icon: 'el-icon-circle-check',
                type: 'success',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'mark_as_awaiting',
                icon: 'el-icon-circle-check',
                type: 'warning',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'mark_as_new',
                icon: 'el-icon-circle-check',
                type: 'danger',
                plain: true,
                dependent: 'selection',
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-date',
                type: 'primary',
                plain: true,
                params: {
                  record_class: 'Mco::KiwiQueue::IssueRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'locator',
                type: 'text',
                operator: 'eq',
                scope: 'kiwiQueueIssues'
              },
              {
                name: 'supplier',
                type: 'text',
                operator: 'like',
                scope: 'kiwiQueueIssues'
              },
              {
                name: 'webhook_type',
                type: 'multiselect',
                storeKey: 'webhookList',
                storeLabelKeys: ['name'],
                operator: 'in',
                scope: 'kiwiQueueIssues',
              },
              {
                name: 'webhook_trigger',
                type: 'multiselect',
                storeKey: 'triggerList',
                storeLabelKeys: ['name'],
                operator: 'in',
                scope: 'kiwiQueueIssues',
              },
              {
                name: 'departure_date',
                operator: 'eq',
                type: 'date',
                valueFormat: 'yyyy-MM-dd',
                scope: 'kiwiQueueIssues',
                options: {
                  firstDayOfWeek: 1
                }
              },
              {
                name: 'status',
                type: 'select',
                storeKey: 'statuses',
                operator: 'eq',
                scope: 'kiwiQueueIssues'
              }
            ]
          },
          combined: [
            {
              name: 'kiwi_queue_issue_history',
              endpoint: '/persistance/default',
              type: 'graphql',
              graphql: {
                name: 'loadHistory',
                queries: {
                  index: {
                    time: true,
                    userLogin: true,
                    operation: true,
                    changes: true
                  }
                }
              },
              boot: {
                type: 'remote'
              },
              table: {
                items: [
                  {
                    name: 'time'
                  },
                  {
                    name: 'userLogin'
                  },
                  {
                    name: 'operation'
                  },
                  {
                    name: 'changes'
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ]
}