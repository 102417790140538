import graphql from './fare_families/graphql'
import table from './fare_families/table'
import actions from './fare_families/actions'
import filters from './fare_families/filters'
import general from './fare_families/form/general'
import route from './fare_families/form/route'
import flight from './fare_families/form/flight'
import fare from './fare_families/form/fare'

export default {
  namespace: 'avia',
  component: 'fare_families',
  meta: {
    title: 'avia.fare_families',
    icon: 'star',
    roles: ['fare_families']
  },
  tabs: [
    {
      name: 'fare_families',
      meta: {
        title: 'fare_families',
        icon: 'skill'
      },
      items: [
        {
          name: 'fare_families_full',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: graphql,
          table: table,
          actions: actions,
          filter: filters,
          form: {
            actualize: true,
            fieldsets: [
              general,
              route,
              flight,
              fare
            ]
          }
        }
      ]
    }
  ]
}