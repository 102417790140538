import rules from './pricer/rules'


export default {
  namespace: 'ttn_avia_vi',
  component: 'pricer',
  meta: {
    title: 'ttn_avia_vi.pricer',
    icon: 'el-icon-money'
  },
  tabs: [
    rules
  ]
}
