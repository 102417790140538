import seatsBlockRules from './seats_block/rules'
import seatsBlockReports from './seats_block/reports'
import seatsBlockGenerators from './seats_block/generators'
import seatsBlockAnalytics from './seats_block/analytics'
import seatsBlockEvents from './seats_block/events'

export default {
  namespace: 'avia',
  component: 'seats_block',
  meta: {
    title: 'avia.seats_block',
    icon: 'chart',
    roles: ['rules']
  },
  tabs: [
    seatsBlockRules,
    seatsBlockReports,
    seatsBlockGenerators,
    seatsBlockAnalytics,
    seatsBlockEvents
  ]
}
