import { loadCrudModules } from '@/utils'

export default {
  name: 'avia',
  path: '/avia',
  redirect: '/avia/pricing',
  alwaysShow: true,
  meta: {
    title: 'avia.default',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./avia', true, /\.js$/))
}
