<template lang="pug">
  el-dialog(:fullscreen="true" class="parser-match-dialog" :title="translate('modal.view_results')" :visible.sync="visible" :close-on-click-modal="false" @closed="onDialogClose")
    el-row
      el-col(:span="18" :offset="3")
        el-collapse(v-model="activeResults" v-if="!loading")
          el-collapse-item(name="original")
            template(slot="title")
              div Original&nbsp;
              div(class="price original-price") {{decodedPrices[decodedSource.currency] || decodedSource.price}}&nbsp;{{decodedSource.currency}}
            el-card(body-style="padding-top: 0;")
              el-divider(content-position="left") Search
              el-row(:gutter="20")
                el-col(:span="12")
                  el-row(type="flex" justify="space-around")
                    el-col
                      el-descriptions(:border="true" :column="3" size="mini")
                        el-descriptions-item(
                          v-for="(paxCnt, paxType) in searchPasengers"
                          :label="paxType"
                          :labelClassName="'pax-type' + (paxCnt > 0 ? ' pax-type-active' : '')"
                          :contentClassName="paxCnt > 0 ? 'pax-type-fill' : ''"
                          :key="`p_${paxType}`"
                        )
                          span(:key="`paxx_${paxType}`" :class="{'pax-count': paxCnt > 0, 'pax-count-zero': paxCnt === 0}") {{paxCnt}}
                el-col(:span="12")
                  el-row(type="flex" justify="space-around")
                    el-col(v-for="(loc, l_index) in searchLocations" :key="`step_${l_index}`" )
                      el-steps
                        el-step(icon="el-icon-top-right" status="finish")
                          template(v-slot:title)
                            div {{loc.departure}},
                              span(class="step-loc-date") &nbsp;{{loc.date}}
                        el-step(:title="loc.arrival" icon="el-icon-bottom-right" status="success")
              template(v-if="decodedPrices[decodedSource.currency]")
                el-divider(content-position="left") Price
                el-descriptions(:border="true" :column="7" size="mini" class="price-table")
                  el-descriptions-item(v-for="(price, code) in decodedPrices" :label="code" :key="code" :labelClassName="(decodedSource.currency === code ? 'bold' : '')")
                    span(:class="{price: decodedSource.currency === code}") {{price}}
              el-divider(content-position="left") Segments
              div(v-for="(trip, trip_index) in originalSegments" :key="`trips_${trip_index}`")
                original-segment(:trip="trip" :trip_index="trip_index")
              div(v-if="originalSegments.length === 0" style="text-align: center") Does not exist :(
          el-collapse-item(title="Best match" name="match" class="match-variants")
            div
              template(v-for="(trip, trip_index) in matches")
                el-divider(v-if="trip_index % 2 !== 0 && trip_index > 0")
                  i(class="el-icon-more" v-for="(n, ni) in (new Array(10))" :key="`ic_${ni}`")
                single-trip(:trip="trip" :trip_index="trip_index" :key="`m_trips_${trip_index}`" :class="matchClasses" :show-services="true")
              div(v-if="matches.length === 0" style="text-align: center") Not found :(
          el-collapse-item(title="All results" name="all")
            div
              a(style="color:blue", :href="searchUrl", target="_blank") {{searchUrl}}
              all-results-listing(v-for="(item, i_key) in allItems" :item="item" :show-services="true" :key="`results_${i_key}`")

    div(slot="footer" class="dialog-footer")
      el-button(type="primary" @click="visible = false") {{ translate('form.cancel') }}
</template>

<script>
import common from '@crud_lib/common'
import SingleTrip from '../_partials/single_trip'
import OriginalSegment from '../_partials/original_segment'
import AllResultsListing from '../_partials/all_results_listing'
import VariantServices from '../_partials/variant_services'

export default {
  components: {
    SingleTrip,
    OriginalSegment,
    AllResultsListing
  },
  mixins: [common],
  props: {
    config: {
      required: true,
      type: Object
    },
    tab: {
      required: true,
      type: String
    },
    item: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      visible: false,
      loading: true,
      currentRow: {},
      matches: [],
      allData: [],
      fullMatch: null,
      segments: [],
      activeResults: ['original', 'match'],
      allCollapseItems: ['outward', 'return']
    }
  },

  computed: {
    decodedSource() {
      let decodedSourceSegments = {}
      if (this.currentRow.decodedSourceSegments) {
        try {
          decodedSourceSegments = JSON.parse(this.currentRow.decodedSourceSegments)
        } catch (e) {}
      }

      return decodedSourceSegments
    },

    decodedPrices() {
      return this.decodedSource.prices || {}
    },

    originalSegments() {
      const trips = []
      for(let i in this.segments) {
        if (trips[this.segments[i].trip_part] === undefined) {
          trips[this.segments[i].trip_part] = []
        }
        trips[this.segments[i].trip_part].push(this.segments[i])
      }
      return trips
    },

    allItems() {
      if (!this.allData || !this.allData.items || this.allData.items.length === 0) {
        return []
      }
      return this.allData.items
    },

    searchUrl() {
      if (!this.allData || !this.allData.url) {
        return ''
      }
      return this.allData.url
    },
    matchClasses() {
      return {
        fullMatch: this.matches.length > 0 && this.fullMatch,
        partialMatch: this.matches.length > 0 && !this.fullMatch,
        notMatch: this.matches.length === 0
      }
    },
    startValue() {
      let decodedValue = {}
      try {
        decodedValue = JSON.parse(this.currentRow.startValue)
      } catch(e) {}
      return decodedValue
    },

    searchPasengers() {
      return this.startValue.passengers || {}
    },

    searchLocations() {
      const original = this.startValue.locations || {}
      
      const value = [{
        departure: original.departure,
        arrival: original.arrival,
        date: original.outward_date
      }]
      if (original.return_date) {
        value.push({
          departure: original.arrival,
          arrival: original.departure,
          date: original.return_date
        })
      }
      return value
    }
  },

  mounted() {
    this.$parent.$on('showMatchVariantsDialog', (row) => {
      this.currentRow = row
      this.visible = true
      this.$nextTick(() => {
        this.serverMatchVariants()
      })
    })
  },

  beforeDestroy() {
    this.$parent.$off('showMatchVariantsDialog')
    this.onDialogClose()
  },

  methods: {
    async serverMatchVariants() {
      try {
        const query = {
          __args: { id: this.currentRow.id },
          all: true,
          matches: true,
          fullMatch: true,
          segments: true
        }

        const res = await this.graphql('siteProjectRunMatchVariant', query, 'mutation')
        this.matches = res.matches
        this.allData = res.all
        this.fullMatch = res.fullMatch
        this.segments = res.segments
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },

    onDialogClose() {
      this.currentRow = {}
      this.matches = []
      this.allData = []
      this.fullMatch = null
    }

  }
}
</script>

<style lang="scss" scoped>
  .bold{
    font-weight: bold;
  }
  .price-table {
    .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
      &.bold{
        font-weight: bold;
        font-size: 14px;
        padding: 5px 9px;
      }
    }
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    column-gap: 10px;
  }
  .flex-col {
    display: flex;
    height: inherit;
    flex: 1;
    margin: 5px 2px;
    &.variant-col{
      max-width: 34%;
    }
  }

  .price {
    color: red;
    font-size:16px;
    font-weight: bold;
  }

  .original-price {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    margin-right: 10px;
  }

  .services-card {
    flex: 1
  }

  .services-card{
    .el-card__header {
      padding: 8px 10px;
    }
    .el-card__body {
      padding: 5px;
      ul{
        margin: 0;
        list-style-type: disclosure-closed;
      }
    }
  }

  .el-collapse-item__header {
    background: #f8f8f8;
    padding: 10px;
    height: 35px;
    line-height: 35px;
  }
  .all-items-card {
    margin-bottom: 10px;
    &>.el-card__body{
      padding: 3px 5px 3px 5px;
    }
  }

  .segment-wrapper {
    .el-divider {
      margin: 10px 0;
    }
  }

  .match-variants {
    .el-divider {
      margin: 10px 0;
    }
  }

  .fullMatch {
    background: rgb(225, 243, 216);
  }
  .partialMatch {
    background: rgb(253, 226, 226);
  }
  .notMatch {
    background: rgb(250, 236, 216);
  }

  .el-descriptions__body{
    .el-descriptions__table .el-descriptions-item__cell {
      &.pax-type-fill {
        background: rgb(225, 243, 216);
      }
      &.pax-type {
        font-weight: bold;
        font-size:14px;
        text-transform: uppercase;
        &.pax-type-active {
          color: #67C23A;
        }
      }
      .pax-count {
        font-weight: bold;
        font-size:14px;
        color: red;
        padding:0 5px 0 3px;
      }
    }
  }
  .el-steps {
    .el-step__title {
      font-size: 12px;
      line-height: 15px;
      .step-loc-date {
        font-size: 11px;
      }
    }
    .el-step__icon.is-icon {
      height: 15px;
      width: 20px;
      .el-step__icon-inner{
        font-size: 16px;
      }
    }
    .el-step__description {
      font-size: 10px;
    }
  }
</style>
