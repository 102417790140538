<template lang="pug">
  extends /form
</template>

<script>
import BaseForm from '@crud_view/form'

export default {
  extends: BaseForm,

  methods: {
    formatTemp() {
      this.temp['settingJson'] = JSON.stringify(this.temp['settingJson']);
      return this.temp
    },
  }
}
</script>
