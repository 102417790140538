<style scoped>
  .row{ padding-right: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      ttn-input(v-model="temp['handlerStatus']" v-bind="attr('handlerStatus')")
      el-row
        el-col(:span='8' class='row')
          ttn-select(v-model="temp['handlerType']" v-bind="attr('handlerType')" label-width='70px' onCha)
        el-col(:span='16' class='row')
          ttn-input(v-model="temp['description']" v-bind="attr('description')" label-width='100px')

      el-divider

      fieldset(:class="`el-form-fieldset sbf-fieldset-0`" v-if="temp['handlerType'] == '1' && temp['handlerParams_decoded']")
        legend {{ translate('form.fieldsets.connectionTimeUpload') }}
        el-row
          el-col(:span='12' class='row')
            ttn-input(v-model="temp['handlerParams_decoded']['file_name']" v-bind="elAttr('handlerParams__fileName', `handlerParams_decoded.file_name`)" label-width='80px' :rules="required")

</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    computed: {
      required() {
        const message = this.translate('form.required')
        const required = true
        return [{ required, message, trigger: 'change' }]
      }
    },
    extends: BaseForm,
    methods: {
      elAttr(fieldName, prop) {
        return this.attr(fieldName, Object.assign({}, this.fieldReference[fieldName], { prop: prop }))
      },
      formatTemp() {
        let handlerParams = {}

        if (this.temp['handlerParams_decoded']) {
          for (let i in this.temp['handlerParams_decoded']) {
            switch (i) {
              // TODO for custom fields
              default:
                handlerParams[i] = this.formatField(this.temp['handlerParams_decoded'][i], this.fieldReference[`handlerParams__${this._.camelCase(i)}`])
            }
          }
        }

        this.temp['handlerParams'] = JSON.stringify(handlerParams)

        return this.temp
      },
      beforeCreateCallback(){
        this.temp = Object.assign(
          {},
          this.temp,
          { handlerParams_decoded: this.defaultOptions() }
        )
      },
      defaultOptions(){
        switch(this.temp['handlerType']){
          case 1:
            return { file_name: '' }
          default:
            return {}
        }
      }
    }
  }
</script>
