export default {
  lowcost_search_baggage: {
    table: {
      branch: 'Branch',
      supplier: 'Supplier',
      airline: 'Flight number',
      supplierClass: 'Class',
      description: 'Description',
      rules: 'Fare rules',
      baggage: 'Baggage',
      active: 'Active'
    },
    form: {
      fields: {
        branch: 'Branch',
        supplier: 'Supplier',
        airline: 'Flight number',
        baggage: 'Baggage',
        active: 'Active',
        supplierClass: 'Class'
      },
      legends: {
        default: 'Filters'
      }
    },
    messages: {
      hasRules: 'Unable to delete. One of the schemas contains rules'
    },
    modal: {
      view_details: 'View details'
    }
  }
}


