<template>
  <el-col :span="24">
    <el-tabs
      v-model="itemModel"
      type="border-card"
      class="item_route_tabs"
      :class="{hide_header: checkIsHide()}"
      :before-leave="eventBeforeLeave"
      @tab-click="tabClick"
    >
      <el-tab-pane
        v-for="config in component.items"
        :key="config.name"
        :ref="config.name"
        :name="config.name"
        :lazy="config.lazy"
        :disabled="config.disabled"
        :label="$t(`item_tabs.${tabLabel(config)}`)"
      >
        <item-tab :config="config" :tab="tabName" :item="itemName" />
      </el-tab-pane>
    </el-tabs>
  </el-col>
</template>

<script>
import common from './../../mixin/common'
import tabComponent from './../../mixin/tabs/component'
import ItemTab from './item'

export default {
  name: 'CrudTab',
  components: {
    ItemTab
  },
  mixins: [common, tabComponent],
  props: {
    component: {
      required: true,
      type: Object
    },
    tab: {
      required: true,
      type: String
    },
    item: {
      required: true,
      type: String
    }
  }
}
</script>
