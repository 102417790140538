export default {
  rail_agents_list: {
    action: {
      clear_cache: 'Clear cache'
    },
    table: {
      id : 'ID',
      email: 'Email',
      fromCode: 'From',
      toCode: 'To',
      trainNumber: 'Trains',
      date: 'Date',
      luxCount: 'First',
      compartmentCount: 'Second',
      reservedCount: 'Reserved',
      sedentaryCount: 'Seated',
      generalCount: 'General',
      softCount: 'Soft',
      lastCheck: 'Last Check',
      book: 'Book?',
      done: 'Done?',
      doneTime: 'Done Time',
      depotId: 'Depot',
      createdAt: 'Created At',
      lang: 'Lang',
      bookingId: 'Booking ID',
      cancelBedclothes: 'Cancel bedclothes?',
      phone: 'phone',
      searchTo: 'Search To',
      low: 'Low?',
      noSide: 'No Side?',
      oneCoupe: 'One Coupe?',
      maxPrice: 'Max Price',
      agentType: 'Agent Type',
      status: 'Status',
      userId: 'User ID',
      departureDate: 'Departure Date',
      expiresOn: 'Expires On'

    },
    form: {
      options: {
        agentType: {
          0: 'inform',
          1: 'book',
          2: 'pay'
        },
        status: {
          0: 'active',
          1: 'wait_for_payment',
          2: 'paid',
          3: 'canceled',
          4: 'inactive'
        }
      },
      fields: {
        createdAt: 'Created At',
        done: 'Done?',
        depotId: 'Depot ID',
        status: 'Status',
        agentType: 'Agent Type'
      }
    }
  },
  rail_agent_documents: {
    table: {
      id: 'ID',
      adult: 'Adult?',
      birthday: 'Birthday',
      firstName: 'First Name',
      lastName: 'Last Name',
      patronymic: 'Patronymic',
      userPassengerId: 'User Passenger ID',
      documentNumber: 'Document Number',
      nationality: 'Nationality',
      salutation: 'Salutation',
      docType: 'Document Type'
    }
  }
}
