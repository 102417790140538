<template lang="pug">
  div
    div(style="padding: 10px")
      el-row
        el-button(
          type="primary"
          plain
          size="mini"
          icon="el-icon-plus"
          @click="createAction()"
          ) Add
        el-button(
          type="danger"
          plain
          size="mini"
          icon="el-icon-delete"
          @click="deleteAction()"
          :disabled="disabled"
          ) Delete
      br
      el-row
        el-table(:data="re_search_rule.childRules", :stripe="true", @selection-change="selectionChange")
          el-table-column(type="selection", width="55")
          el-table-column(prop="id", label="ID")
          el-table-column(label="VC")
            template(slot-scope="scope")
              span(style="white-space: nowrap;") {{ scope.row.vc.join(", ") }}

          el-table-column(label="maxPercent", prop="maxPercent")
          el-table-column(label="maxAmount", prop="maxAmount")
          el-table-column(label="minAmount", prop="minAmount")

          el-table-column(label="Comments", prop="comments")
          el-table-column(label="Status")
            template(slot-scope="scope")
              el-switch(:value="scope.row.status", disabled)
          el-table-column(label="Date")
            el-table-column(label="Updated")
              template(slot-scope="scope")
                span {{new Date(scope.row.updatedAt) | moment("DD/MM/YY  HH:mm:ss")}}
            el-table-column(label="Created")
              template(slot-scope="scope")
                span {{new Date(scope.row.createdAt) | moment("DD/MM/YY  HH:mm:ss")}}
          el-table-column(class-name="small-padding" width="70px" align="center")
            template(slot-scope="scope")
              el-button(
                type="warning"
                plain
                size="mini"
                icon="el-icon-edit"
                @click="updateAction(scope.row)"
              )

</template>

<script>

export default {

  props: {
    re_search_rule: null
  },

  data() {
    return {
      selected: [],
      disabled: true
    }
  },

  methods: {
    selectionChange(selected) {
      var selection = []
      selected.forEach((row) => {
        selection.push(
          row
        )
      })

      if(selection.length > 0) {
        this.disabled = false
      }

      this.selected = selection
    },

    createAction() {
      this.$emit('createAction', this.re_search_rule.id)
    },

    updateAction(row) {
      console.log('row', row)
      this.$emit('updateAction', row)
    },

    deleteAction() {
      this.$emit('deleteAction', this.selected)
    }
  }
}
</script>
