export default {
  actionDownload(row) {
    if (row['filePath'] == '') {
      this.$message({
        message: this.translate('messages.download_error'),
        type: 'error'
      })
    } else {
      this.startDownload(row)
    }
  },
  async startDownload(row) {
    try {
      const query = {
        content: true,
        __args: {
          key: row['filePath']
        }
      }

      let result = await this.graphql('aviaParsersReportFileContent', query, 'collection')

      if (result.content) {
        const blob = new Blob([result.content], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = row['filePath']
        link.click()
        URL.revokeObjectURL(link.href)
      } else {
        this.$message({
          message: this.translate('messages.download_empty_content'),
          type: 'error'
        })
      }

      return true
    } catch (e) {
      this.$message({
        message: e,
        type: 'error'
      })
    }
  },
  formatCell({ row, $column, column, cellValue, index }) {
    switch (column.name) {
      case 'file':
        return (row['filePath'] == '' ? 'NO' : 'YES')
      default:
        return cellValue
    }
  },
  cellStyle({ row, column, rowIndex, columnIndex }) {
    switch (columnIndex) {
      case 3:
        switch (row['jobStatus']) {
          case 1:
            return { 'background-color': '' }
          case 2:
            return { 'background-color': '#E0F4FD' }
          case 3:
            return { 'background-color': '#ced0dc6b' }
          case 4:
            return { 'background-color': '#f9d93a7a' }
          case 5:
            return { 'background-color': '#2cf3606b' }
        }
    }

    return {}
  },
  formatExpandedRow(row) {
    return `<b>Session ID:</b>  ${row['sessionId']}<br/>
      <b>Message:</b>  ${row['message']} <br/>
      <b>Params:</b>  ${JSON.stringify(row['params'], null, "&emsp;").replaceAll("\n", '<br>')} <br/>
      `
  },
  actionChangeSettings(params) {
    this.$emit('showChangeSettingsWindow', params.entity)
  }
}
