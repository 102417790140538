<template lang=pug>
  el-dialog(:title="translate('changeMarkupsWindow.title')" :visible.sync="dialogFormVisible")
      div(class="txt-w-header")
        b {{ translate('changeMarkupsWindow.description') }}:
        span(class="txt-pd-10") {{ description() }}
      el-form(:model="form")
        el-form-item(:label="`${translate('changeMarkupsWindow.form.markups')} (${form.currency})`" :label-width="formLabelWidth")
          el-input(v-model="form.markups" width="200px")

      span(slot="footer" class="dialog-footer" v-loading="changeLoading")
        el-button(@click="dialogFormVisible = false" type="danger") {{ translate('form.cancel') }}
        el-button(@click="actionPerform" type="primary" :disabled="changeLoading") {{ translate('form.confirm') }}

</template>

<style scoped>
  .txt-w-header{
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 10px;

  }
  .txt-pd-10{
    padding: 0 0 0 10px;
  }
</style>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  computed:{
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    }
  },
  data: function() {
    return {
      editRow: {},
      dialogFormVisible: false,
      form: {
        markups: null,
        currency: null
      },
      changeLoading: false,
      formLabelWidth: '120px'
    }
  },

  mounted() {
    this.$parent.$on('showChangeMarkupsWindow', (row) => {
      this.editRow = row
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showChangeMarkupsWindow')
  },

  methods: {
    reset() {
      this.changeLoading = false
      this.form.markups = this.editRow['recommendedMarkups']
      this.form.currency = this.editRow['currency']
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    description(){
      return `${this.editRow['route']}(${this.editRow['date']}) - ${this.editRow['flight']}`
    },
    actionPerform() {
      if(this.changeLoading){ return }
      this.changeLoading = true
      this.performRequest(this.form)
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            id: parseInt(this.editRow.id),
            depotId: parseInt(this.editRow.depotId),
            markups: parseFloat(form.markups),
            currency: form.currency
          }
        }

        let result = await this.graphql('aviaSeatsBlockAnalyticChangeMarkups', query, 'mutation')

        this.dialogFormVisible = false
        this.$alert(result, this.translate('changeMarkupsWindow.msg.title'), {
          confirmButtonText: 'OK',
          type: result.includes('ERROR') ? 'warning' : 'success',
          center: true
        });
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('changeMarkupsWindow.msg.title'),
          message: this.translate('changeMarkupsWindow.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
      // this.$elItem.$emitAction('refresh')
    }

  }
}
</script>
