export default {
  settings_price_concurrency: {
    table: {
      id: 'ID',
      oindex: 'Index',
      routes: 'Routes',
      suppliers: 'Suppliers',
      rules: 'Rules',
      active: 'Active'
    },
    form: {
      fields: {
        id: 'ID',
        oindex: 'Index',
        routes: 'Routes',
        suppliers: 'Suppliers',
        rules: 'Rules',
        active: 'Active',
        rules__daysRange: 'Days range',
        rules__difference: 'Min. diff. (%)',
        rules__timelimit: 'Timelimit (m)'
      },
      legends: {
        rules: 'Rules'
      },
      placeholder: {
        suppliers: '00,PS',
        routes: 'IEV-LON,LON-IEV (cities only)',
        rules__daysRange: '0-365',
      }
    }
  }
}
