<template lang="pug">
  div(class="sidebar-filter el-aside")
    div(class="border-card")
      div(class="tab-content-filters")
        info-block(:detailedInformation="detailedInformation")
</template>

<script>
import common from '@crud_lib/common'
import InfoBlock from '../avia_booking_list/info'

export default {

  components: {
    InfoBlock
  },
  mixins: [common],

  props: {
    detailedInformation: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .tab-container .sidebar-filter.el-aside {
    opacity: 1;
    width: 310px !important;
    display: block;
    border: none;
  }
</style>