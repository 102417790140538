export default {
  actions_performed_handler: {
    table: {
      id: 'ID',
      handlerType: 'Type',
      handlerStatus: 'Handler status',
      filePath: 'File path',
      description: 'Description',
      handlerResults: 'Handler results',
      sessionId: 'Session',
      handlerParams: 'Handler params'
    },
    form: {
      fields: {
        id: 'ID',
        handlerType: 'Type',
        handlerStatus: 'Handler status',
        filePath: 'File path',
        description: 'Description',
        handlerResults: 'Handler results',
        sessionId: 'Session',
        handlerParams: 'Handler params',
        handlerParams__fileName: 'Filename'
      },
      options: {
        handlerType: {
          1: 'Upload MCT file',
          connection_time_upload: 'Upload MCT file',
        },
        handlerStatus: {
          1: 'Created',
          2: 'Started',
          3: 'Error',
          4: 'Success',
          'created': 'Created',
          'started': 'Started',
          'error': 'Error',
          'success': 'Success',
        }
      },
      fieldsets:{
        connectionTimeUpload: 'MCT file options'
      }
    }
  }
}
