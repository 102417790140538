<template lang="pug">
  div(style="padding: 0 0 0 5px" fixed)
    el-dialog(
      center
      title="Reason of unconfirmed payment"
      :visible.sync="paymentNotConfirmDialog.visible"
      v-loading="paymentNotConfirmDialog.loading"
    )
      el-form(style="padding-bottom: 10px" :model="paymentNotConfirmDialog.form" ref="paymentNotConfirmDialog")
        el-form-item(prop="statusReason")
          el-select(v-model="paymentNotConfirmDialog.form.statusReason"
                    placeholder="Reason"
                    required)
            el-option(
              v-for="o in paymentNotConfirmDialog.reasons"
              :label="o[1]"
              :value="o[0]"
              :key="o[1]"
            )

        el-form-item(prop="reasonText")
          el-input(v-model="paymentNotConfirmDialog.form.reasonText"
                   v-if="[8, 12].indexOf(paymentNotConfirmDialog.form.statusReason) != -1"
                   width="300")

      span.dialog-footer(slot="footer")
        el-button(@click="paymentNotConfirmDialog.visible = false") Close
        el-button(@click="changeStatusAction(paymentNotConfirmDialog.form, paymentNotConfirmDialog)" type="success") Save

    el-dialog(
      center
      title="Send to backlog"
      :visible.sync="backlogDialog.visible"
      v-loading="backlogDialog.loading"
    )

      el-form(:model="backlogDialog.form" ref="backlogDialog")
        el-form-item(prop="date" label="Timer")
          el-date-picker(v-model="backlogDialog.form.date"
                         :disabled="backlogDialog.form.withoutDate" 
                         type="datetime"
                         placeholder="Select backlog datetime")

        el-form-item(prop="withoutDate")
          el-checkbox(v-model="backlogDialog.form.withoutDate") Without date

      span.dialog-footer(slot="footer")
        el-button(@click="backlogDialog.visible = false") Close
        el-button(@click="setBacklogAction()" type="success") Save

    el-row
      el-dropdown(trigger="click" 
                  @command="commandHandler" 
                  :disabled="!currentRow" 
                  style="padding-right: 10px")
        el-button(type="primary" plain) Change status <i class="el-icon-arrow-down el-icon--right"></i>
        el-dropdown-menu(slot="dropdown" v-if="currentRow") 
          el-dropdown-item(:command="{showDialog: paymentNotConfirmDialog, ref: 'paymentNotConfirmDialog'}") 
            el-tag(class="status-tag" ) NP
            el-divider(direction="vertical")
            span Payment not confirmed

          el-dropdown-item(:command="{changeStatus: 'CO', confirm: 'Do you really wish to mark this booking as \"Cancel booking\"'}"
                           :disabled="currentRow.status != 'W'")
            el-tag(class="status-tag" type='danger') CO
            el-divider(direction="vertical")
            span Cancel

          el-dropdown-item(:command="{changeStatus: 'P'}"
                           :disabled="currentRow.status != 'OR'")
            el-tag(class="status-tag" type='success') P
            el-divider(direction="vertical")
            span Cancel refund request

          el-dropdown-item(:command="{changeStatus: 'WD', confirm: 'Do you really wish to mark this booking as \"Pending refund\"'}"
                           :disabled="currentRow.status != 'PN'")
            el-tag(class="status-tag" type='warning') WD
            el-divider(direction="vertical")
            span Pending refund

          el-dropdown-item(:command="{changeStatus: 'CANCEL_PAYMENT', confirm: 'Do you really wish to mark this booking as \"Cancel payment\"'}"
                           :disabled="['P', 'NP', 'PN'].indexOf(currentRow.status) == -1")
            el-tag(class="status-tag" type='danger') W
            el-divider(direction="vertical")
            span Cancel payment

          el-dropdown-item(:command="{changeStatus: 'ANCILLARY_PAYED', confirm: 'Do you really wish to mark this booking as \"Paid. Issued\"'}")
            el-tag(class="status-tag" type='success') P
            el-divider(direction="vertical")
            span Paid after ancillary issue

          el-dropdown-item(:command="{changeStatus: 'STATUS_P', confirm: 'Do you really wish to mark this booking as \"Paid. Issued\"'}" divided)
            el-tag(class="status-tag" type='success') P
            el-divider(direction="vertical")
            span(style="color: #F56C6C")
              i(class="el-icon-warning") &nbsp;&nbsp;Status P

          el-dropdown-item(:command="{changeStatus: 'NOT_ACTUALLY'}" divided)
            i(class="el-icon-remove-outline icon-tag")
            el-divider(direction="vertical")
            span Irrelevant for customer

          el-dropdown-item(:command="{sendEmail: true, tmpl: 'problem-issuance-agent', comment: 'Sent problem issuance email', emailAction: 'common'}"
                           :disabled="currentRow.status != 'PN'")
            i(class="el-icon-message icon-tag")
            el-divider(direction="vertical")
            span Delay of ticketing

      el-button(type="primary"
                plain
                @click="commandHandler({ ref: 'backlogDialog', showDialog: backlogDialog })"
                :disabled="!currentRow") Backlog
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  components: {},
  mixins:[common, di],
  props: {
    currentRow: null,
    navigateTab: null
  },

  data() {
    return {
      paymentNotConfirmDialog: {
        visible: false,
        loading: false,
        reasons: [
          [1, 'tkxl in booking'],
          [2, 'irrelevant for customer'],
          [3, 'insufficient funds'],
          [4, 'card acceptance blocked by airline'],
          [5, 'incorrect passenger info'],
          [6, 'airline canceled seats'],
          [7, 'Fraud'],
          [8, 'Specify reason'],
          [9, 'partial payment'],
          [10, 'change of fare'],
          [11, 'Double'],
          [12, 'MultiFOP'],
          [13, 'No Interline'],
          [14, 'UC/NO'],
          [15, 'layover isn\'t long enough for connection']
        ],
        form: {
          statusReason: null,
          reasonText: null,
          status: 'NP'
        }
      },

      backlogDialog: {
        visible: false,
        loading: false,
        form: {
          withoutDate: false,
          date: null
        }        
      }
    }
  },

  methods: {
    commandHandler(command) {
      if (command.showDialog) { command.showDialog.visible = true }
      if (command.ref) { this.$refs[command.ref]?.resetFields() }
      if (command.changeStatus) {
        let message = command.confirm ? command.confirm : `Are you sure to change status to ${command.changeStatus}`
        this.$confirm(message, 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        }).then(() => {
          this.changeStatusAction({ status: command.changeStatus })
        })
      }
      if (command.sendEmail) {
        this.$confirm(`Are you sure to send ${command.tmpl} email?`, 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        }).then(() => {
          this.sendEmailAction(command)
        })        
      }
    },

    async changeStatusAction(form, dialog) {
      if (dialog) dialog.loading = true
      try {
        const query = {
          __args: {
            id: this.currentRow.id,
            ...form
          }
        }

        let res = await this.graphql('aviaBookingItemSetStatus', query, 'mutation')

        this.processResponse(res, dialog)
        this.reloadPage()
      } catch (e) {
        this.catchError(e, dialog)
      }  
    },

    async setBacklogAction() {
      const dialog = this.backlogDialog
      const form = dialog.form

      dialog.loading = true
      try {
        const query = {
          __args: {
            id: this.currentRow.id,
            date: (form.withoutDate ? null : form.date.toString())
          }
        }

        let res = await this.graphql('aviaBookingItemSetBacklog', query, 'mutation')

        this.processResponse(res, dialog)
        this.reloadPage()
      } catch (e) {
        this.catchError(e, dialog)
      }        
    },

    async sendEmailAction(form) {
      try {
        const query = {
          __args: {
            id: this.currentRow.id,
            tmpl: form.tmpl,
            comment: form.comment,
            emailAction: form.emailAction
          }
        }

        let res = await this.graphql('aviaBookingItemSendEmail', query, 'mutation')

        this.processResponse(res)
        this.reloadPage()
      } catch (e) {
        this.catchError(e, dialog)
      }  
    },

    reloadPage() {
      this.navigateTab({
        tab: 'items',
        item: 'avia_booking_list',
        reload: true
      })
    },

    catchError(e, dialog) {
      if (dialog) {
        dialog.visible = false
        dialog.loading = false
      }
      this.$message({
        message: e,
        type: 'error'
      })
    },

    processResponse(res, dialog) {
      this.$message({
        message: res,
        type: res == 'success' ? 'success' : 'error'
      })

      if (dialog) {
        dialog.visible = false
        dialog.loading = false
      }
    }
  } 
}
</script>

<style lang="scss" scoped>
  .empty {
    display: inline-block;
    width: 30px;
  }

  .status-tag { 
    width: 30px;
    text-align: center;
  }

  .icon-tag {
    width: 25px;
    text-align: center;
  }
</style>