export default {
  settings_system_settings: {
    table: {
      id: 'ID',
      key: 'Key',
      value: 'Value'
    },
    form: {
      fields: {
        id: 'ID',
        key: 'Key',
        value: 'Value'
      },
    }
  }
}
