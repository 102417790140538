import tableBase from './base.js'

const Table = {
  mixins: [tableBase],
  data() {
    return {
      filters: {},
      filterValue: {},
      filterCall: false
    }
  },

  mounted() {
    this.preloadData()
    this.$elItem.$on(this.$channels.paginationPage, this.eventPaginationPage)
    this.$elItem.$on(this.$channels.paginationSize, this.eventPaginationSize)
    this.$elItem.$on(this.$channels.filterReady, this.eventFilterReady)
    this.$elContainer.$on(this.$channels.navigationItemStart(this.config.tab, this.config.name), this.eventItemStart)
    this.$elContainer.$on(this.$channels.navigationItemActivate(this.config.tab, this.config.name), this.eventItemActivate)
    this.tableContainer = this.$refs.tableContainer
    this.tableContainer && this.tableContainer.addEventListener('transitionend', this.$_tableClassAnimation)
  },

  beforeDestroy() {
    this.$elItem.$off(this.$channels.paginationPage, this.eventPaginationPage)
    this.$elItem.$off(this.$channels.paginationSize, this.eventPaginationSize)
    this.$elItem.$off(this.$channels.filterReady, this.eventFilterReady)
    this.$elContainer.$off(this.$channels.navigationItemActivate(this.config.tab, this.config.name), this.eventItemActivate)
    this.$elContainer.$off(this.$channels.navigationItemStart(this.config.tab, this.config.name), this.eventItemStart)
    this.tableContainer && this.tableContainer.removeEventListener('transitionend', this.$_tableClassAnimation)
  },

  computed: {
    records() {
      return this.$store.getters[`${this.storeName}/list`].reduce((acc, i) => acc.concat(Object.freeze(i)), [])
    },
    total() {
      return this.$store.getters[`${this.storeName}/total`]
    }
  },

  methods: {
    $_tableClassAnimation(e) {
      if (e.propertyName === 'opacity' && Array.from(e.srcElement.classList).includes('base-table') && this.isReady) {
        this.isReady = null
      }
    },

    async eventFilterReady(filter) {
      this.filters = { ...filter.filters }
      this.filterValue = { ...filter.filterValue }
      this.filterCall = true
      if (this.isActiveItem()) {
        this.pagination.page = filter.resetPage ? 1 : (parseInt(this.$route.query.page) || 1)
        this.pagination.limit = parseInt(this.$route.query.limit) || this.config.paginationSize
        if (filter.resetPage) {
          this.pagination.cursor = null
        }
      } else {
        this.pagination.page = 1
        this.pagination.limit = this.config.paginationSize
      }
      await this.loadData()
    },

    eventPaginationPage(val) {
      this.pagination.page = val.page
      this.pagination.cursor = val.cursor
      this.list()
    },

    eventPaginationSize(val) {
      this.pagination.limit = val
      this.pagination.page = 1
      this.pagination.cursor = null
      this.list()
    },

    eventItemStart() {
      this.isReady = false
    },

    async eventItemActivate(params) {
      if(params.filters) {
        this.filters = params.filters
      }
      await this.actualizeSort()
      await this.setRouteQuery(params.clearParams === true, params.isReady === true)
      this.$nextTick(() => {
        if (params.reload || (this.config.preload && !this.$store.getters[`${this.storeName}/preloaded`])) {
          this.$elItem.$emitAction('refresh')
          this.loading = true
        } else {
          this.isReady = true
        }
      })
    },

    async list() {
      try {
        this.loading = true
        await this.preloadData()
        await this.initBeforeListCallbacks()
        await this.$store.dispatch(`${this.storeName}/all`, this.buildParams('list'))
        this.$nextTick(async() => {
          if (this.pagination.page > 1 && this.pagination.limit * (this.pagination.page - 1) > this.total) {
            this.pagination = { ...this.pagination, page: 1 }
            await this.$store.dispatch(`${this.storeName}/all`, this.buildParams('list'))
          }
          await this.initAfterListCallbacks()
          this.$nextTick(() => {
            this.loading = false
            this.$elItem.$emit(this.$channels.paginationParams, { ...this.pagination, total: this.total })
            if (this.isActiveItem()) {
              this.setRouteQuery()
            }
          })
        })
      } catch (err) {
        this.exception(err)
        this.loading = false
        this.$message({
          message: err,
          type: 'error'
        })
      }
    },
    async loadData() {
      try {
        if (this.config.autoLoad || this.filterCall) {
          this.loading = true
          await this.list()
        }
      } catch (err) {
        this.loading = false
        this.$message({
          message: err,
          type: 'error'
        })
      }
    },

    async preloadData() {
      try {
        if (this.config.preload) {
          if (!this.$store.getters[`${this.storeName}/preloaded`]) {
            await this.initBeforePreloadCallbacks()
            await this.$store.dispatch(`${this.storeName}/preload`, this.buildParams('preload'))
            await this.initAfterPreloadCallbacks()
          }
        }
      } catch (err) {
        this.$message({
          message: err,
          type: 'error'
        })
      }
    },

    async setRouteQuery(clearParams = false, isReady = false) {
      this.$nextTick(async() => {
        let query = {
          tab: this.tab,
          item: this.item,
          ...this.pagination
        }
        if (!isReady) {
          const urlFilters = {}
          for(let scope in this.filters) {
            urlFilters[scope] = urlFilters[scope] || []
            for(let f in this.filters[scope]) {
              if(!this.filters[scope][f].hidden) {
                urlFilters[scope].push({
                  field: this.filters[scope][f].field,
                  operator: this.filters[scope][f].operator,
                  value: this.filters[scope][f].value
                })
              }
            }
          }
          query.filters = urlFilters
        }
        if (!clearParams) {
          query = this._.pickBy({ ...this.$route.query, ...query }, this._.identity)
        }
        await this.$router.replace({ name: this.$route.name, query: query }).catch(err => {})
      })
    },

    navigateTab(params) {
      if ( !params.hasOwnProperty('reload') ) {
        params.reload = true
      }
      this.$elContainer.$emit(this.$channels.navigationTab, params)
    },

    async initBeforeListCallbacks() {
      this.$elItem.$emit(this.$channels.hooksBeforeList(this.config.tab, this.config.name), this.config.name)
      await this.beforeListCallback()
    },

    async initAfterListCallbacks() {
      this.$elItem.$emit(this.$channels.hooksAfterList(this.config.tab, this.config.name), this.config.name)
      await this.afterListCallback()
    },

    async initBeforePreloadCallbacks() {
      this.$elItem.$emit(this.$channels.hooksPreloadStart(this.config.tab, this.config.name), this.config.name)
      await this.beforePreloadCallback()
    },

    async initAfterPreloadCallbacks() {
      this.$elItem.$emit(this.$channels.hooksPreloadFinish(this.config.tab, this.config.name), this.config.name)
      await this.afterPreloadCallback()
    },

    async beforeListCallback() {},
    async afterListCallback() {},
    async beforePreloadCallback() {},
    async afterPreloadCallback() {},

    async _sortChangeRemote(sort, dir) {
      if (sort && dir) {
        this.pagination.sort = sort
        this.pagination.dir = dir
      } else {
        this.pagination.sort = null
        this.pagination.dir = null
      }
      this.list()
    }
  }
}

export default Table
