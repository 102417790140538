export default {
  namespace: 'mco',
  component: 'gds_queue',
  meta: {
    title: 'mco.gds_queue',
    icon: 'table'
  },
  tabs: [
    {
      name: 'gds_queue_manager',
      items: [
        {
          name: 'gds_queue_manager_locators',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'mcoGdsQueueManagerLocators',
            queries: {
              index: {
                id: true,
                type: true,
                gdsLocator: true,
                adminLocator: true,
                bookingId: true,
                mcoType: true,
                gds: true,
                office: true,
                aviaConfigId: true,
                reason: true,
                error: true,
                status: true,
                segmentDate: true,
                updatedAt: true,
                logs: {
                  logTime: true,
                  message: true,
                  userLogin: true,
                  createdAt: true
                }
              }
            }
            // preloadFields: ['schemes{id name}']
          },
          combined: [
            {
              name: 'gds_queue_manager_locator_logs',
              graphql: {
                name: 'mcoGdsQueueManagerLocators'
              },
              type: 'graphql',
              boot: {
                type: 'local',
                row_key: 'logs'
              },
              table: {
                sortType: 'local',
                items: [
                  {
                    name: 'logTime',
                    format: 'date_time'
                  },
                  'userLogin',
                  'message'
                ]
              },
              form: {
                fieldsets: [
                  {
                    legend: 'Default',
                    items: [
                      {
                        name: 'id',
                        type: 'hidden',
                        required: false,
                        valueType: 'integer'
                      },
                      {
                        name: 'locator_id',
                        type: 'hidden',
                        required: true,
                        valueType: 'integer'
                      },
                      {
                        name: 'logTime',
                        type: 'text'
                      }
                    ]
                  }
                ]
              }
            }
          ],
          stores: {
            statuses: {
              0: 'New',
              1: 'Inprocess',
              2: 'Error',
              3: 'Success'
            },
            gds: {
              sabre: 'Sabre',
              sig: 'SIG'
            },
            errors: {
              any: 'Any',
              exception: 'exception',
              booking_not_found: 'booking_not_found',
              manual_processing: 'manual_processing',
              driver_not_supported: 'driver_not_supported',
              check_segments: 'check_segments',
              all_segments_are_flown: 'all_segments_are_flown',
              retrieve_error: 'retrieve_error',
              connection_time: 'connection_time',
              check_route: 'check_route',
              mco_empty_changes: 'mco_empty_changes',
              mco_not_found: 'mco_not_found',
              ancillary_not_found: 'ancillary_not_found',
              mco_error: 'mco_error',
              tkt_numbers_not_updated: 'tkt_numbers_not_updated',
              delete_error: 'delete_error',
              mco_creating_error: 'mco_creating_error'
            },
            types: {
              '': 'All',
              'involuntary': 'Involuntary',
              'bg_checkin': 'BgCheckin',
              'additional_services': 'AdditionalServices',
              'sq_checkin': 'SqCheckin'
            },
            reasons: {
              '': 'all',
              'cancelation': 'cancelation',
              'changes': 'changes',
              'tkt_actualization': 'tkt_actualization',
              'bg_checkin': 'bg_checkin',
              'additional_services': 'additional_services',
              'sq_checkin': 'sq_checkin'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'type',
              'gdsLocator',
              'adminLocator',
              'gds',
              'office',
              'mcoType',
              'reason',
              'error',
              {
                name: 'status',
                format: 'from_store',
                storeKey: 'statuses'
              },
              'segmentDate',
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              'expand'
            ]
          },
          actions: {
            row: [],
            panel: [
              {
                name: 'mark_as_success',
                icon: 'el-icon-delete',
                type: 'success',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'requeue',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Mco::GdsQueueManager::LocatorRelation'
                }
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'locator',
                type: 'text',
                operator: 'eq',
                scope: 'gdsQueueManagerLocators'
              },
              {
                name: 'gds',
                operator: 'eq',
                type: 'select',
                storeKey: 'gds',
                scope: 'gdsQueueManagerLocators'
              },
              {
                name: 'office',
                type: 'text',
                operator: 'eq',
                scope: 'gdsQueueManagerLocators'
              },
              {
                name: 'status',
                type: 'select',
                storeKey: 'statuses',
                operator: 'eq',
                scope: 'gdsQueueManagerLocators'
              },
              {
                name: 'error',
                type: 'select',
                storeKey: 'errors',
                operator: 'eq',
                scope: 'gdsQueueManagerLocators'
              },
              {
                name: 'type',
                type: 'select',
                storeKey: 'types',
                operator: 'eq',
                scope: 'gdsQueueManagerLocators'
              },
              {
                name: 'reason',
                type: 'select',
                translateOptions: true,
                storeKey: 'reasons',
                operator: 'eq',
                scope: 'gdsQueueManagerLocators'
              },
              {
                name: 'segment_date',
                type: 'checkbox',
                operator: 'eq',
                scope: 'gdsQueueManagerLocators'
              }
            ]
          }
        },
        {
          name: 'gds_queue_manager_settings',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'mcoGdsQueueManagerConfigs',
            queries: {
              index: {
                id: true,
                gds: true,
                office: true,
                aviaConfigId: true,
                reason: true,
                queue: true,
                archiveQueue: true,
                segmentStatuses: true,
                deleteStatuses: true,
                changesStatuses: true,
                active: true,
                lastRun: true,
                runStatus: true,
                updatedAt: true
              }
            }
          },
          stores: {
            gds: {
              sabre: 'Sabre',
              sig: 'SIG'
            },
            reasons: {
              cancelation: 'cancelation',
              changes: 'changes',
              tkt_actualization: 'tkt_actualization'
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '80px'
              },
              'gds',
              'office',
              'aviaConfigId',
              'reason',
              'queue',
              'archiveQueue',
              {
                name: 'segmentStatuses',
                format: 'array_as_string'
              },
              {
                name: 'deleteStatuses',
                format: 'array_as_string'
              },
              {
                name: 'changesStatuses',
                format: 'array_as_string'
              },
              {
                name: 'lastRun',
                width: '120px',
                format: 'date_time'
              },
              'runStatus',
              {
                name: 'active',
                type: 'switch',
                width: '70px'
              },
              {
                name: 'updatedAt',
                width: '120px',
                format: 'date_time'
              },
              {
                name: 'actions',
                width: '100px'
              }
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Mco::GdsQueueManager::ConfigRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'gds',
                operator: 'eq',
                type: 'select',
                storeKey: 'gds',
                scope: 'gdsQueueManagerSettings'
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                },
                scope: 'gdsQueueManagerSettings'
              }
            ]
          },
          form: {
            actualize: true,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'gds',
                    type: 'select',
                    storeKey: 'gds'
                  },
                  {
                    name: 'office',
                    required: true,
                    type: 'text'
                  },
                  {
                    name: 'avia_config_id',
                    type: 'select',
                    storeKey: 'config_items',
                    storeLabelKeys: ['id', 'name'],
                    required: false,
                    lazy: true
                  },
                  {
                    name: 'reason',
                    type: 'select',
                    storeKey: 'reasons',
                    translateOptions: true
                  },
                  {
                    name: 'queue',
                    type: 'text'
                  },
                  {
                    name: 'archiveQueue',
                    type: 'text'
                  },
                  {
                    name: 'segmentStatuses',
                    type: 'text',
                    valueType: 'array_of_string'
                  },
                  {
                    name: 'deleteStatuses',
                    type: 'text',
                    valueType: 'array_of_string',
                    required: false
                  },
                  {
                    name: 'changesStatuses',
                    type: 'text',
                    valueType: 'array_of_string'
                  },
                  {
                    name: 'active',
                    type: 'checkbox',
                    default: false,
                    required: false
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  ]
}
