<template lang="pug">
  div(class="list-content" v-loading="loading")
    div(v-for="(rec, i) in records" :key="i" class="block-item")
      el-card(class="box-card")
        div(slot="header")
          span {{rec.route}}, {{rec.airline}}
        el-tabs(type="card")
          el-tab-pane(v-for="(items, key) in rec.items" :label="key" :key="key" :lazy="true")
            el-row
              el-col(:span="12")
                el-table(:data="items")
                  el-table-column(prop="rank" :label="translate('rank')")
                  el-table-column(prop="rec_count" :label="translate('rec_count')")
                  el-table-column(prop="rec_percent" :label="translate('rec_percent')")
                  el-table-column(prop="discount" :label="translate('discount')")
                  el-table-column(prop="markup" :label="translate('markup')")
                  el-table-column(:label="$t('crud.table.actions')")
                    template(slot-scope="scope")
                      el-button(
                        type="success"
                        plain
                        icon="el-icon-view"
                        :disabled="scope.row.details.length === 0"
                        @click="showInfo(scope.row.details)"
                      )
              el-col(:span="12")
                div(style="padding: 20px")
                  chart-position(:data="rec.chart[key]" :type="report_type")
    details-dialog
</template>

<script>
import Base from '@crud_view/table'
import ChartPosition from './chart'
import DetailsDialog from './details_dialog'

export default {
  components: {
    ChartPosition,
    DetailsDialog
  },
  extends: Base,
  data() {
    return {
      report_type: 'markup'
    }
  },

  mounted() {
    this.$elItem.$on('changeReportType', this.changeReportType)
  },
  beforeDestroy() {
    this.$elItem.$off('changeReportType', this.changeReportType)
  },

  methods: {
    showInfo(details) {
      this.$emit('showInfo', details)
    },
    changeReportType(val) {
      this.report_type = val
    }
  }
}
</script>
