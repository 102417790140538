<template lang="pug">
  extends /table
  block expand
    div(v-for="(trip, trip_index) in segments(scope.row)" :key="`trip_${scope.row.id}_${trip_index}`")
      original-segment(:trip="trip" :trip_index="trip_index")
    el-divider(content-position="left") Price
    el-descriptions(:border="true" :column="7" size="mini" class="price-table")
      el-descriptions-item(v-for="(price, code) in scope.row.prices" :label="code" :key="code" :labelClassName="(scope.row.currency === code ? 'bold' : '')")
        span(:class="{price: scope.row.currency === code}") {{price}}
  block custom
    group-details-dialog(:config="config" :tab="tab" :item="item")
</template>

<script>
import Base from '@crud_view/table'
import GroupDetailsDialog from './group_details_dialog'
import OriginalSegment from '../_partials/original_segment'

export default {
  components:{
    GroupDetailsDialog,
    OriginalSegment
  },
  extends: Base,
  mounted() {
    this.$elItem.$once(this.$channels.hooksAfterList(this.config.tab, this.config.name), this.showDetailsDialog)
  },

  beforeDestroy() {
    this.$elItem.$off(this.$channels.hooksAfterList(this.config.tab, this.config.name), this.showDetailsDialog)
  },

  methods: {
    segments(row) {
      let segments = {}
      try {
        segments = JSON.parse(row.extraData).segments
      } catch(e) {}
      const trips = []
      for(let i in segments) {
        if (trips[segments[i].trip_part] === undefined) {
          trips[segments[i].trip_part] = []
        }
        trips[segments[i].trip_part].push(segments[i])
      }
      return trips
    },

    actionViewDetails(row) {
      this.$emit('showGroupDetailsDialog', row)
    },

    actionRunList(row) {
      this.navigateTab({
        tab: 'parsing',
        item: 'site_runs',
        filters: {
          siteProjectRuns: [
            {
              field: 'site_run_group_id', operator: 'eq', value: row.id
            }
          ]
        }
      })
    },
    showDetailsDialog() {
      const selectedId = this.$route.query.with_details_row
      if (selectedId > 0) {
        const selectedRow = this.records.find((i) => i.id === selectedId)
        if (selectedRow !== undefined) {
          this.viewDetailsAction(selectedRow)
        }
      }
    },
    isDisabledAction(action, row) {
      if (action.name !== 'view_details') {
        return false
      }
      return 'complete' !== row.status
    }
  }
}
</script>

<style lang="scss" scoped>
  .bold{
    font-weight: bold;
  }

  .segment-wrapper {
    .el-divider {
      margin: 10px 0;
    }
    th.el-descriptions-item__cell.el-descriptions-item__label.is-bordered-label {
      width: 90px;
    }

    .other_supplier_iata {
      color: #F56C6C
    }
  }
</style>
