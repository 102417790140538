<template lang="pug">
  extends /panel
  block right_prepend
    div(class="filter-container")
      el-date-picker(
        type="date"
        v-model="date"
        :default-value="date"
        :editable="false"
        :clearable="false"
        size="mini"
      )
    div(class="filter-container" style="width: 100px")
      el-select(
        v-model="shift" 
        :clearable="false"
        :editable="false"
        @change="actionApplyFilter"
      )
        el-option(
          v-for="item in shiftStore"
          :key="item.id"
          :value="item.id"
          :lable="`${item.id} : ${item.name}`"
        )
    el-button(
      v-waves
      :title="translate(`action.refresh`)"
      icon="el-icon-refresh"
      plain
      size="mini"
      @click="refreshChart()"
      type="primary"
    ) {{ translate(`action.refresh`) }}
</template>

<script>
import BasePanel from '@crud_view/panel'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  extends: BasePanel,
  mixins: [common, di],
  data() {
    return {
      date: new Date(),
      shift: '0-24',
      shiftStore: [
        { id: '0-24', name: 'Full-day' }, 
        { id: '0-8', name: 'Night' },
        { id: '8-16', name: 'Day' },
        { id: '16-24', name: 'Evening' }
      ]
    }
  },
  methods: {
    actionApplyFilter(resetPage = true, clearParams = false) {
      if (this.process) return

      this.process = true
      const filters = {}
      const filterValue = {}
      const prefilledData = {}

      this.$nextTick(() => {
        filters['date'] = this.$moment(this.date).format('YYYY-MM-DD')
        filters['shift'] = this.shift
        this.$elItem.$emit(this.$channels.filterReady, { filters, resetPage, prefilledData, clearParams, filterValue })
        this.process = false
      })
    },

    refreshChart() {
      this.date = new Date()
      this.shift = '0-24'
      this.actionApplyFilter()
    }
  },
  watch: {
    date(val) {
      this.actionApplyFilter()
    }
  }
}
</script>

<style lang="scss">
.filter-container {
  display: inline-block;
  margin-right: 10px;
}
.el-drawer__wrapper .el-drawer__body { padding: 0 25px }
</style>