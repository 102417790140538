<template lang="pug">
  el-collapse(v-model="allCollapseItems")
    el-collapse-item(title="Outward" name="outward")
      el-card(class="all-items-card" v-for="(trip, trip_index) in item.outward" :key="`all_trips_o${trip_index}`")
        single-trip(:trip="trip" :trip_index="trip_index" :show-services="showServices" :base-currency="baseCurrency")
    el-collapse-item(title="Return" name="return" v-if="item.return && item.return.length > 0")
      el-card(class="all-items-card" v-for="(trip, trip_index) in item.return" :key="`all_trips_r${trip_index}`")
        single-trip(:trip="trip" :trip_index="trip_index" :show-services="showServices" :base-currency="baseCurrency")
</template>

<script>
import SingleTrip from './single_trip'

export default {
  components: {
    SingleTrip
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    showServices: {
      required: false,
      type: Boolean,
      default: false
    },
    baseCurrency: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      allCollapseItems: ['outward', 'return']
    }
  }
}
  
</script>
