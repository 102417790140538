<template lang="pug">
  el-menu(class="right-filter avia-booking-details" v-if="detailedInformation")
    el-scrollbar(wrap-class="scrollbar-wrapper")
      li(class="filter_row")
        el-collapse(v-model="activeName")
          el-collapse-item(title="Booking" name="booking")
            el-row(:gutter="2")
              el-col(:span="5")
                span Locator:
              el-col(:span="19")
                i(class="el-icon-document-copy" @click="copyToClipboard(booking.locator, 'Locator copied to clipboard')").pointer
                span &nbsp;
                span {{ booking.locator }}

            el-row(:gutter="2")
              el-col(:span="5")
                span PC:
              el-col(:span="19")
                div(v-for="pcLocator in booking.pc_locators")
                  i(class="el-icon-document-copy" @click="copyToClipboard(pcLocator, 'Locator copied to clipboard')").pointer
                  span &nbsp;
                  span {{ pcLocator }}

            el-row(:gutter="2")
              el-col(:span="5")
                span ID:
              el-col(:span="19")
                i(class="el-icon-document-copy" @click="copyToClipboard(booking.id, 'ID copied to clipboard')").pointer
                span &nbsp;
                span {{ booking.id }}

            el-row(:gutter="2")
              el-col(:span="5")
                span Inbound:
              el-col(:span="19")
                span {{ booking.inbound_date }}

            el-row(:gutter="2")
              el-col(:span="5")
                span Outbound:
              el-col(:span="19")
                span {{ booking.outbound_date }}

            el-row(:gutter="2")
              el-col(:span="5")
                span Config:
              el-col(:span="19")
                span(v-html="booking.configs.join('<br/>')")

            el-row(:gutter="2")
              el-col(:span="5")
                span Session ID:
              el-col(:span="19")
                i(class="el-icon-document-copy" @click="copyToClipboard(booking.session_id, 'Session ID copied to clipboard')").pointer
                span &nbsp;
                span {{ booking.session_id }}

            el-row(:gutter="2")
              el-col(:span="5")
                span Paxes:
              el-col(:span="19")
                span(v-html="booking.passengers.join('<br/>')")

            el-row(:gutter="2")
              el-col(:span="5")
                span Timelimit:
              el-col(:span="19")
                span {{ booking.timelimit | moment('DD.MM.YYYY HH:mm') }}

            el-row(:gutter="2")
              el-col(:span="5" style="line-height: 22px")
                span Lang:
              el-col(:span="19")
                el-tag(type="success" effect="plain") {{ String(booking.language).toUpperCase() }}

          el-collapse-item(name="attentions")
            template(slot="title") Attentions&nbsp;
              i(class="header-icon el-icon-warning")

            el-row
              div(v-for="attention in booking.attentions") {{ attention }}

          el-collapse-item(name="notes")
            template(slot="title") Notes&nbsp;
              i(class="header-icon el-icon-warning")
            el-row
              div(v-for="notes in booking.notes") {{ notes }}

          el-collapse-item(title="Contacts" name="contacts")
            el-row(:gutter="2")
              el-col(:span="5")
                span Name:
              el-col(:span="19")
                span {{ `${contacts.name} (${contacts.id})` }}

            el-row(:gutter="2")
              el-col(:span="5")
                span Email:
              el-col(:span="19")
                i(class="el-icon-document-copy" @click="copyToClipboard(contacts.email, 'Email copied to clipboard')").pointer
                span &nbsp;
                span {{ contacts.email }}

            el-row(:gutter="2")
              el-col(:span="5")
                span Phone:
              el-col(:span="19")
                i(class="el-icon-document-copy" @click="copyToClipboard(contacts.phone.replace(/[^0-9]/g, ''), 'Phone number copied to clipboard')").pointer
                span &nbsp;
                span {{ contacts.phone }}

            template(v-if="Object.keys(contacts.real_contacts).length > 0")
              el-row(:gutter="2")
                el-col(:span="5")
                  span Customer email:
                el-col(:span="19")
                  i(class="el-icon-document-copy" @click="copyToClipboard(contacts.real_contacts.email, 'Email copied to clipboard')").pointer
                  span &nbsp;
                  span {{ contacts.real_contacts.email }}

              el-row(:gutter="2")
                el-col(:span="5")
                  span Customer phone:
                el-col(:span="19")
                  i(class="el-icon-document-copy" @click="copyToClipboard(contacts.real_contacts.phone.replace(/[^0-9]/g, ''), 'Phone number copied to clipboard')").pointer
                  span &nbsp;
                  span {{ contacts.real_contacts.phone }}

          el-collapse-item(title="Payment" name="payment")
            el-row(:gutter="2")
              el-col(:span="7")
                span System:
              el-col(:span="17")
                span {{ payment.system_name }}

            el-row(:gutter="2")
              el-col(:span="7")
                span Date:
              el-col(:span="17")
                span {{ payment.date | moment('DD.MM.YYYY HH:mm:ss') }}

            el-row(:gutter="2")
              el-col(:span="7")
                span Tnx ID:
              el-col(:span="17")
                span {{ payment.transaction_id }}

          el-collapse-item(title="Ticketing info" name="ticketing-info")
            el-row(:gutter="2")
              el-col(:span="7")
                span Substitution:
              el-col(:span="17")
                div(v-html="wrapBoolean(ticketing.substitution)")

            el-row(:gutter="2")
              el-col(:span="7")
                span Autoticket:
              el-col(:span="17")
                div(v-html="wrapBoolean(ticketing.autoticket)")

            el-row(:gutter="2")
              el-col(:span="7")
                span Fare type:
              el-col(:span="17")
                div(v-html="wrapFare(ticketing.fare_type)")

            el-row(:gutter="2")
              el-col(:span="7")
                span Agent:
              el-col(:span="17")
                span {{ ticketing.agent }}

            template(v-for="tItem in ticketing.items")
              fieldset(:class="`el-form-fieldset`" )
                legend {{ tItem.locator }}
                el-row(:gutter="2")
                  el-col(:span="7")
                    span Aircomp.:
                  el-col(:span="17")
                    span {{ tItem.validating_supplier }}
                el-row(:gutter="2")
                  el-col(:span="7")
                    span Office:
                  el-col(:span="17")
                    span {{ tItem.issue_office_name }}
                el-row(:gutter="2")
                  el-col(:span="7")
                    span  Etkt.:
                  el-col(:span="17")
                    div(v-for="etkt in tItem.ticket_numbers") {{ etkt }}
                el-row(:gutter="2")
                  el-col(:span="7")
                    span  Date:
                  el-col(:span="17")
                    span {{ tItem.issued_at }}

            el-row
              br
</template>

<script>
import common from '@crud_lib/common'
import { uppercaseFirst } from '@/filters'

export default {

  mixins: [common],
  props: {
    detailedInformation: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      activeName: ['booking', 'attentions', 'notes', 'payment', 'ticketing-info', 'contacts']
    }
  },

  computed: {
    parsedInfo() { return JSON.parse(this.detailedInformation) },
    booking() { return this.parsedInfo.booking },
    payment() { return this.parsedInfo.payment },
    contacts() { return this.parsedInfo.contacts },
    ticketing() { return this.parsedInfo.ticketing }
  },
  
  methods: {
    wrapBoolean(bool) {
      return `<span class='${bool ? 'green' : 'red'}'>${bool ? 'Yes' : 'No'}</span>`
    },

    wrapFare(fare) {
      let cssClass = 'green'

      if (fare == 'mixed') {
        cssClass = 'yellow'
      } else if (fare == 'special') {
        cssClass = 'red'
      }

      return `<span class='${cssClass}'>${uppercaseFirst(fare)}</span>`
    }
  }
}
</script>

<style lang="scss">
  .right-filter.avia-booking-details {
    .filter_row {
      .el-row { 
        line-height: 16px;
        font-size: 12px;
      }

      .el-form-fieldset legend {
        font-size: 12px;
      }

    }

    .red { color: red }
    .green { color: green }
    .yellow { color: yellow }

    .scrollbar-wrapper.el-scrollbar__wrap { margin-bottom: 0!important }

    i.el-icon-document-copy {
      color: #ff0000;
    }
  }
</style>