<template lang=pug>
   el-dialog(:title="translate('groupChangeWindow.title')" :visible.sync="dialogFormVisible" width="75%")
      el-form(:model="form")
        el-row
          el-col(:span='6')
            el-form-item(:label="translate('groupChangeWindow.form.fromId')" label-width="80px" class="is-required")
              el-input(v-model="form.fromId" type="input")
          el-col(:span='6')
            el-form-item(:label="translate('groupChangeWindow.form.toId')" label-width="80px" class="is-required")
              el-input(v-model="form.toId" type="input")
          el-col(:span='6')
            el-form-item(:label="translate('form.fields.contractId')" label-width="90px" class="is-required")
              el-input(v-model="form.contractId" type="input")
          el-col(:span='6')
            el-form-item(:label="translate('form.fields.routeKey')" label-width="90px")
              el-input(v-model="form.routeKey" type="input")

        el-row
          el-col(:span='6')
            el-form-item(:label="translate('groupChangeWindow.form.status_label')" label-width="60px")
              el-select(v-model="form.status")
                el-option(v-for="k in [0,1,2]" :key="k" :label="translate(`groupChangeWindow.form.status.${k}`)" :value="k")
          el-col(:span='9')
            fieldset(class="el-form-fieldset sbf-fieldset-fares")
              legend {{ translate('form.fields.locatorConfigId') }}
              el-row
                el-col(:span='7')
                  el-form-item(:label="translate('groupChangeWindow.form.toChange')" label-width="60px")
                    el-switch(v-model="form.activeItems.locatorConfigId")
                el-col(:span='17')
                  el-form-item(:label="translate('form.fields.locatorConfigId')" label-width="120px" :hidden="!form.activeItems.locatorConfigId" class="is-required")
                    el-input(v-model="form.locatorConfigId")
          el-col(:span='9')
            el-form-item(:label="translate('form.fields.blockRemainedUpdate')" label-width="140px")
              el-select(v-model="form.blockRemainedUpdate")
                el-option(v-for="k in [0,1,2]" :key="k" :label="translate(`groupChangeWindow.form.status.${k}`)" :value="k")

        el-row
          fieldset(class="el-form-fieldset sbf-fieldset-fares")
            legend {{ `Base ${translate('form.fields.fares')}` }}

            el-row
              el-col(:span='5')
                el-form-item(:label="`${translate('groupChangeWindow.form.toChange')} ${translate('form.fields.fares__name')}`" label-width="150px")
                  el-switch(v-model="form.activeItems.fareName")
              el-col(:span='19')
                el-form-item(:label="translate('form.fields.fares__name')" label-width="110px" :hidden="!form.activeItems.fareName" class="is-required")
                  el-input(v-model="form.fareName" type="input" )

            el-row
              el-form-item(:label="translate('groupChangeWindow.form.toChange')" label-width="60px")
                el-switch(v-model="form.activeItems.fares")

            div(v-if="form['fares']" v-for="paxCode in paxTypes" :hidden="!form.activeItems.fares" :key="paxCode")
              el-row
                el-col(:span='2' class='row el-form-item el-form-item--mini')
                  label(class="el-form-item__label") {{ paxCode }}
                el-col(:span='4' class='row')
                  el-form-item(:label="translate('form.fields.fares__fare')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                    el-input(v-model="form['fares'][paxCode]['fare']")
                el-col(:span='4' class='row')
                  el-form-item(:label="translate('form.fields.fares__taxes')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                    el-input(v-model="form['fares'][paxCode]['taxes']")
                el-col(:span='5' class='row')
                  el-form-item(:label="translate('form.fields.fares__total')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                    el-input(v-model="form['fares'][paxCode]['total']")
                el-col(:span='5' class='row')
                  el-form-item(:label="translate('form.fields.fares__fareCode')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                    el-input(v-model="form['fares'][paxCode]['fare_code']")
                el-col(:span='4' class='row')
                  el-form-item(:label="translate('form.fields.fares__baggage')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                    el-input(v-model="form['fares'][paxCode]['baggage']")
              el-row(v-for="baggageDetailsType in baggageDetailsTypes" :key="baggageDetailsType")
                el-col(:span='4' class='row')
                  label(class="el-form-item__label") -{{ baggageDetailsType }}
                el-col(:span='6' class='row')
                  el-form-item(:label="translate('form.fields.fares__baggageDetails__dimensionSum')" label-width="60px")
                    el-input(v-model="form['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_sum']" :placeholder="translate('form.placeholder.fares__baggageDetails__dimensionSum')")
                el-col(:span='3' class='row')
                  el-form-item(:label="translate('form.fields.fares__baggageDetails__dimensionUnit')" label-width="30px")
                    el-input(v-model="form['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_unit']" :placeholder="translate('form.placeholder.fares__baggageDetails__dimensionUnit')")
                el-col(:span='4' class='row')
                  el-form-item(:label="translate('form.fields.fares__baggageDetails__weight')" label-width="60px")
                    el-input(v-model="form['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight']")
                el-col(:span='3' class='row')
                  el-form-item(:label="translate('form.fields.fares__baggageDetails__weightUnit')" label-width="30px")
                    el-input(v-model="form['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight_unit']" :placeholder="translate('form.placeholder.fares__baggageDetails__weightUnit')")
                el-col(:span='4' class='row')
                  el-form-item(:label="translate('form.fields.fares__baggageDetails__count')" label-width="60px")
                    el-input(v-model="form['fares'][paxCode]['baggage_details'][baggageDetailsType]['count']")

        el-row
          fieldset(class="el-form-fieldset sbf-fieldset-fares")
            legend {{ translate('groupChangeWindow.form.fareFamilies') }}

            el-form-item(:label="translate('groupChangeWindow.form.toChange')" label-width="60px")
              el-switch(v-model="form.activeItems.fareFamilies")

            el-tabs(v-model="form.fareEditableTabsValue" type="card" editable @edit="handleFareTabsEdit" :hidden="!form.activeItems.fareFamilies")
              el-tab-pane(
                v-for="(fareFamilyItem, index) in form.fareFamilies"
                :key="index+1+''"
                :label="fareFamilyItem.name"
                :name="index+1+''"
              )
                el-form-item(:label="translate('form.fields.fareFamilies__name')" label-width="100px")
                  el-input(v-model="form['fareFamilies'][index]['name']" :rules="required")
                div(v-for="paxCode in paxTypes" :key="paxCode")
                  el-row
                    el-col(:span='2' class='row el-form-item el-form-item--mini')
                      label(class="el-form-item__label") {{ paxCode }}
                    el-col(:span='4' class='row')
                      el-form-item(:label="translate('form.fields.fareFamilies__fare')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['fare']")
                    el-col(:span='4' class='row')
                      el-form-item(:label="translate('form.fields.fareFamilies__taxes')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['taxes']")
                    el-col(:span='5' class='row')
                      el-form-item(:label="translate('form.fields.fareFamilies__total')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['total']")
                    el-col(:span='5' class='row')
                      el-form-item(:label="translate('form.fields.fareFamilies__fareCode')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['fare_code']")
                    el-col(:span='4' class='row')
                      el-form-item(:label="translate('form.fields.fareFamilies__baggage')" label-width="60px" :class="paxCode == 'ADT' ? 'is-required' : ''")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['baggage']")
                  el-row(v-for="baggageDetailsType in baggageDetailsTypes" :key="baggageDetailsType")
                    el-col(:span='4' class='row')
                      label(class="el-form-item__label") -{{ baggageDetailsType }}
                    el-col(:span='6' class='row')
                      el-form-item(:label="translate('form.fields.fares__baggageDetails__dimensionSum')" label-width="60px")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_sum']" :placeholder="translate('form.placeholder.fares__baggageDetails__dimensionSum')")
                    el-col(:span='3' class='row')
                      el-form-item(:label="translate('form.fields.fares__baggageDetails__dimensionUnit')" label-width="30px")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['dimension_unit']" :placeholder="translate('form.placeholder.fares__baggageDetails__dimensionUnit')")
                    el-col(:span='4' class='row')
                      el-form-item(:label="translate('form.fields.fares__baggageDetails__weight')" label-width="60px")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight']")
                    el-col(:span='3' class='row')
                      el-form-item(:label="translate('form.fields.fares__baggageDetails__weightUnit')" label-width="30px")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['weight_unit']" :placeholder="translate('form.placeholder.fares__baggageDetails__weightUnit')")
                    el-col(:span='4' class='row')
                      el-form-item(:label="translate('form.fields.fares__baggageDetails__count')" label-width="60px")
                        el-input(v-model="form['fareFamilies'][index]['fares'][paxCode]['baggage_details'][baggageDetailsType]['count']")

        el-row
          fieldset(class="el-form-fieldset sbf-fieldset-fares")
            legend {{ translate('form.fields.comment') }}

            el-form-item(:label="translate('groupChangeWindow.form.toChange')" label-width="60px")
              el-switch(v-model="form.activeItems.comment")

            el-form-item(:label="translate('form.fields.comment')" label-width="110px" :hidden="!form.activeItems.comment" class="is-required")
              el-input(v-model="form.comment" type="textarea" rows="3" )

        el-row
          fieldset(class="el-form-fieldset sbf-fieldset-fares")
            legend {{ translate('form.fields.fareConditions') }}

            el-form-item(:label="translate('groupChangeWindow.form.toChange')" label-width="60px")
              el-switch(v-model="form.activeItems.fareConditions")

            el-form-item(:label="translate('form.fields.fareConditions')" label-width="110px" :hidden="!form.activeItems.fareConditions" class="is-required")
              el-input(v-model="form.fareConditions" type="textarea" rows="3" )

      span(slot="footer" class="dialog-footer" v-loading="changeLoading")
        el-button(@click="dialogFormVisible = false" type="danger") {{ translate('form.cancel') }}
        el-button(@click="actionPerform" type="primary" :disabled="changeLoading") {{ translate('form.confirm') }}
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  computed:{
    paxTypes(){ return ['ADT', 'CHD', 'INF'] },
    faresFields(){ return ['fare', 'taxes', 'total', 'fare_code', 'baggage'] },
    baggageDetailsTypes() { return ['baggage', 'carry_on', 'personal_item'] },
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    }
  },
  data: function() {
    return {
      dialogFormVisible: false,
      form: {
        status: 0,
        fromId: null,
        toId: null,
        activeItems: {
          fareFamilies: false,
          fareConditions: false,
          comment: false,
          fareName: false,
          fares: false,
          locatorConfigId: false
        },
        fareTabIndex: 1,
        fareFamilies: [],
        fareEditableTabsValue: '1',
        fareConditions: '',
        comment: '',
        fareName: '',
        fares: false,
        locatorConfigId: 0,
        blockRemainedUpdate: 0,
        contractId: '',
        routeKey: ''
      },
      changeLoading: false,
      formLabelWidth: '120px'
    }
  },

  mounted() {
    this.$parent.$on('showGroupChangeWindow', () => {
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showGroupChangeWindow')
  },

  methods: {
    reset() {
      this.changeLoading = false
      this.form.status = 0
      this.form.blockRemainedUpdate = 0
      for(let k in this.form.activeItems){ this.form.activeItems[k] = false }
      this.form.fareFamilies = [this.defaultFareFamily()]
      this.form.fareTabIndex = 1
      this.form.fares = this.defaultFareFamily().fares
      this.resetFormValues(['fromId', 'toId'], null)
      this.resetFormValues(['fareConditions', 'comment', 'fareName', 'locatorConfigId'], '')
      this.form.contractId = ''
      this.form.routeKey = ''
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    actionPerform() {
      if(this.changeLoading){ return }

      if(this.isValid(this.form)){
        this.changeLoading = true
        this.performRequest(this.form)
      }
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            fromId: (form.fromId ? parseInt(form.fromId) : 0),
            toId: (form.toId ? parseInt(form.toId) : 0),
            contractId: form.contractId,
            status: parseInt(form.status),
            fareFamilies: (form.activeItems.fareFamilies ? this.encodeFareFamilies(form.fareFamilies) : null),
            fareConditions: (form.activeItems.fareConditions ? form.fareConditions : null),
            comment: (form.activeItems.comment ? form.comment : null),
            fareName: (form.activeItems.fareName ? form.fareName : null),
            fares: (form.activeItems.fares ? this.encodeFareFamilies(form.fares) : null),
            locatorConfigId: (form.activeItems.locatorConfigId ? parseInt(form.locatorConfigId) : null),
            blockRemainedUpdate: parseInt(form.blockRemainedUpdate),
            routeKey: form.routeKey
          }
        }

        await this.graphql('aviaSeatsBlockRuleGroupChange', query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('cloneWindow.msg.title'),
          message: this.translate('groupChangeWindow.msg.success'),
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('cloneWindow.msg.title'),
          message: this.translate('cloneWindow.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
      this.$elItem.$emitAction('refresh')
    },
    isValid(form){
      let msgs = []

      if(
          (['fromId', 'toId'].some((el) => !form[el] || parseInt(form[el]) == 0) || (parseInt(form['fromId']) > parseInt(form['toId']))) &&
          (form['contractId'] == '')
      ){
        msgs.push('Check IDs')
      }

      if(form.status == 0 && form.blockRemainedUpdate == 0 && Object.values(form.activeItems).every((el) => !el)){
        msgs.push('Make changes')
      }

      if(form.activeItems.fareFamilies && form.fareFamilies.some((ff) => !this.validFareFamily(ff))){
        msgs.push('Check FareFamilies block')
      }

      if(msgs.length == 0){
        return true
      } else{
        this.$notify({
          title: this.translate('cloneWindow.msg.title'),
          message: msgs.join(" // "),
          type: 'warning',
          duration: 2600
        })
        return false
      }
    },
    validFareFamily(ff){
      if(!ff.name || ff.name == ''){ return false }
      if(this.faresFields.some((k) => !ff['fares']['ADT'][k] || ff['fares']['ADT'][k] == '')){ return false }
      return true
    },
    encodeFareFamilies(ff){
      return JSON.stringify(ff)
    },
    defaultFareFamily(){
      return {name: '', fares: this.paxTypes.reduce((o, key) => ({ ...o, [key]: {
        baggage_details: this.defaultBaggageDetails()
      }}), {}), change_fares: true, change_baggage_details: false}
    },
    defaultBaggageDetails(){
      return this.baggageDetailsTypes.reduce((bo, bkey) => ({ ...bo, [bkey]: {} }), {})
    },
    handleFareTabsEdit(targetName, action){
      if (action === 'add') {
        this.form.fareFamilies.push(this.defaultFareFamily());
        this.form.fareEditableTabsValue = ++this.form.fareTabIndex + '';
      }
      if (action === 'remove' && targetName != '1') {
        const tab_index = parseInt(targetName) - 1
        if(this.form.fareEditableTabsValue === targetName){
          if(!this.form.fareFamilies[tab_index + 1]){
            this.form.fareEditableTabsValue = (tab_index == 0 ? '1' : tab_index.toString())
          }
        }
        this.form.fareTabIndex--
        this.form.fareFamilies.splice(tab_index, 1)
      }
    },
    resetFormValues(fields, value){
      for(let k of fields){ this.form[k] = value }    }
  }
}
</script>
