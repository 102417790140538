<template lang="pug">
  extends /table
  block expand
    vue-json-pretty(:path="'res'" :data="scope.row.startValueFormat")
  block custom
    run-dialog(:config="config" :tab="tab" :item="item")
</template>

<script>
import Base from '@crud_view/table'
import RunDialog from './run_dialog'

export default {
  components: {
    RunDialog
  },
  extends: Base,

  methods: {
    actionGroupsList(row) {
      this.navigateTab({
        tab: 'parsing',
        item: 'site_project_groups',
        filters: {
          siteProjectGroups: [
            {
              field: 'site_project_id', operator: 'eq', value: row.id
            }
          ]
        }
      })
    },

    actionRunTemplate(row) {
      this.$emit('showRunDialog', row)
    }
  }
}
</script>
