<style scoped>
  .row{ padding-right: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      div
        ul
          li Обовязково вказуємо або Depot ID або Traficc ID, 0 - де пусто
          li Якщо для ApiRequest вибрано більше 1-го конфігу,  тоді пошук буде по всіх гдс для депоту/трафіку визначених у Офісах підключення
          li Request params - працює тільки для ApiRequest,  можна визначити додаткоі параметри за запиту - JSON

      el-row
        el-col(:span='4' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='80px')
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['depotId']" v-bind="attr('depotId')" label-width='90px')
        el-col(:span='6' class='row')
          ttn-input(v-model="temp['trafficSourceId']" v-bind="attr('trafficSourceId')" label-width='90px')
        el-col(:span='8' class='row')
          ttn-select(v-model="temp['requestType']" v-bind="elAttr('requestType')" label-width='100px')
      el-row
        el-col(:span='12' class='row')
          ttn-multiselect(v-model="temp['routeTypes']" v-bind="attr('routeTypes')")
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['departurePeriods']" v-bind="attr('departurePeriods')")
      el-row
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['configIds']" v-bind="attr('configIds')")
        el-col(:span='12' class='row')
          ttn-input(v-model="temp['schedule']" v-bind="attr('schedule')")
      el-row
        el-col(:span='6' class='row')
          ttn-checkbox(v-model="temp['forGoogleFeed']" v-bind="attr('forGoogleFeed')" label-width='100px')
        el-col(:span='6' class='row')
          ttn-checkbox(v-model="temp['forTtnAviaVi']" v-bind="attr('forTtnAviaVi')" label-width='100px')

      ttn-textarea(v-model="temp['requestParams']" v-bind="attr('requestParams')")
      ttn-textarea(v-model="temp['routes']" v-bind="attr('routes')")

</template>

<script>
  import BaseForm from '@crud_view/form'
  import MultiRowField from '@crud_lib/form/multi_row_field'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    mixins: [MultiRowField],
    // computed: {

    // },
    methods: {
    }
  }
</script>
