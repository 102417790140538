<template lang="pug">
  el-table-column(
    :prop="column.name"
    :label="translate('table.actions')"
    v-on="$listeners"
    v-bind="attribs"
  )
    template(slot-scope="scope")
      slot(:name="column.name" v-bind="slotAttribs(scope,column)")
        el-button(
          v-for="action in rowActions"
          :key="action.name"
          :title="translate(`action.${action.name}`)"
          :type="action.type"
          :plain="action.plain"
          size="mini"
          :icon="action.icon"
          :disabled="isDisabledAction(action, scope.row)"
          @click="handleAction(action.name, scope.row)"
        )
</template>

<script>

import tableColumns from './../../../mixin/table/columns'

export default {
  name: 'TtnTableColumnActions',
  mixins: [tableColumns],
  computed: {
    rowActions() {
      return this.$table.rowActions
    }
  },
  methods: {
    isDisabledAction(action, row) {
      return this.$table.isDisabledAction(action, row)
    },
    handleAction(name, row) {
      return this.$table.handleAction(name, row)
    }
  }
}
</script>
