<template lang="pug">
  extends /panel
  block right_prepend
    el-select(v-model="report_type")
      el-option(
        v-for="(key, value) in types"
        :key="key"
        :label="key"
        :value="value"
      )
</template>

<script>
import Base from '@crud_view/panel'

export default {
  extends: Base,
  data() {
    return {
      report_type: 'markup',
      types: {
        discount: 'Discount',
        markup: 'Markup'
      }
    }
  },
  watch: {
    report_type(val) {
      this.$elItem.$emit('changeReportType', val)
    }
  }
}
</script>
