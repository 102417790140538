<template lang="pug">
  extends /table
  block expand
    div(v-html="formatExpandedRow(scope.row)" style="line-height: 15px; word-wrap: break-word")
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    formatExpandedRow(row) {
      var res = []
      var routes = row['routes'].split(';')
      routes.forEach(v => {
        res.push(v.replace('-', '<font color=red> -- </font>'))
      })
      return res.join('<br/>')
    }
  }
}
</script>
