<template lang="pug">
  div(class="list-content")
    el-table(:data="records" :stripe="true" @selection-change="eventSelectionChange" v-loading="loading")
      el-table-column(:selectable="isSelectable" type="selection" width="55")
      el-table-column(type="expand")
          template(slot-scope="scope")
            childRules(v-bind:re_search_rule="scope.row", @updateAction="updateAction" @createAction="createAction" @deleteAction="deleteAction")
      el-table-column(prop="id", label="ID")
      el-table-column(label="Refid")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.refid.join(", ") }}
      el-table-column(label="Comments", prop="comments")
      el-table-column(label="Status")
        template(slot-scope="scope")
          el-switch(:value="scope.row.status == true", disabled)
      el-table-column(label="Updated At")
        template(slot-scope="scope")
          span {{new Date(scope.row.updatedAt) | moment("DD/MM/YY  HH:mm:ss")}}
      el-table-column(label="Childs")
        template(slot-scope="scope")
          span {{getSizeOfChildRules(scope.row)}}
      el-table-column(class-name="small-padding" width="70px" align="center")
        template(slot-scope="scope")
          el-button(
            type="warning"
            plain
            size="mini"
            icon="el-icon-edit"
            @click="handleAction('update', scope.row)"
          )
</template>

<script>
import BaseTable from '@crud_view/table'

import childRules from './child_rules'

export default {
  components: {
    BaseTable,
    childRules
  },
  extends: BaseTable,


  methods: {

    updateAction(row) {
      this.handleAction('update', row)
    },

    createAction(parentId) {
      this.handleAction('create', { parentId: parseInt(parentId) })
    },

    deleteAction(rows) {
      this.eventSelectionChange(rows)
      this.actionDelete()
    },

    getSizeOfChildRules(row) {
      return row.childRules.length
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-tag.el-tag--warning.margin-bot-5{
    margin-bottom: 5px;
  }

  .el-tag.color-inherit{
    color: inherit;
  }

</style>
