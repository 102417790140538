<template lang=pug>
  el-dialog(:title="translate('changeSettingsWindow.title')" :visible.sync="dialogFormVisible" width="1024px")
    div(class="txt-w-header")
      b {{ translate('changeSettingsWindow.description') }}
      span(class="txt-pd-10") {{ description() }}
    el-form(:model="form" v-loading="formLoading" v-if="entity")
       el-row
        el-col(:span='15' class='row')
          el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.cacheStorageUrl`)}`" label-width="120px" class="is-required")
            el-input(v-model="form.settings.cache_storage_url")
        el-col(:span='9' class='row')
          el-form-item(:label="`${translate(`changeSettingsWindow.form.${entity}.cachePrefix`)}`" label-width="120px" class="is-required")
            el-input(v-model="form.settings.cache_prefix")

    span(slot="footer" class="dialog-footer" v-loading="changeLoading")
      el-button(@click="dialogFormVisible = false" type="danger") {{ translate('form.cancel') }}
      el-button(@click="actionPerform" type="primary" :disabled="changeLoading") {{ translate('form.confirm') }}

</template>

<style scoped>
  .txt-w-header{
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 10px;

  }
  .txt-pd-10{
    padding: 0 0 0 10px;
  }
  .mt20 {
    margin: 20px 0 0 0;
  }
  .m-l-15{
    margin: 0 0 0 20px;
  }
</style>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  computed:{
    required(){
      const message = this.translate('form.required')
      const required = true
      return [{ required, message, trigger: 'change' }]
    },
    defaultValues(){
      return {
        cache_storage_url: '',
        cache_prefix: ''
      }
    },
    defaultForm(){
      return {
        id: null,
        settings: { ...this.defaultValues }
      }
    }
  },
  data: function() {
    return {
      dialogFormVisible: false,
      changeLoading: false,
      formLoading: true,
      formLabelWidth: '140px',
      entity: null,
      form: this.defaultForm
    }
  },

  mounted() {
    this.$parent.$on('showChangeSettingsWindow', (entity) => {
      this.entity = entity
      this.show()
    })
  },

  beforeDestroy() {
    this.$parent.$off('showChangeSettingsWindow')
  },

  methods: {
    reset() {
      this.changeLoading = false
      this.formLoading = true
      this.loadForm()
      this.form = this.defaultForm
    },
    show() {
      this.reset()
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
    },
    description(){
      return `${this.entity}`
    },
    actionPerform() {
      if(this.changeLoading){ return }

      if (!this.isValid()) { return }

      this.formatForm()
      this.changeLoading = true

      this.performRequest(this.form)
    },
    async loadForm(){
      try {
        const query = {
          collection:{
            id: true,
            entity: true,
            setting: true,
          },
          __args: {
            limit: 25,
            page: 1,
            filters: {
              featureSettings: [{field: "entity", operator: "eq", value: this.entity, hidden: false}]
            }
          },
        }

        let result = await this.graphql('featureSettings', query, 'collection')

        if(result['collection'] && result['collection'][0]){
          this.form.id = result['collection'][0].id
          this.form.settings = JSON.parse(result['collection'][0].setting)
        }

        this.formLoading = false
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.load_error'),
          type: 'warning',
          duration: 2600
        })
      }
    },
    async performRequest(form) {
      try {
        const query = {
          __args: {
            id: this.form.id,
            entity: this.entity,
            settingJson: JSON.stringify(this.form.settings)
          },
          id: true
        }

        let result = await this.graphql((this.form.id ? 'updateFeatureSetting' : 'createFeatureSetting'), query, 'mutation')

        this.dialogFormVisible = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.success_config'),
          type: 'success',
          duration: 2600
        })
      } catch (e) {
        console.error(e)
        this.changeLoading = false
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: this.translate('changeSettingsWindow.msg.error'),
          type: 'warning',
          duration: 2600
        })
      }
    },
    isValid(){
      let errorFields = []

      let fields = ["cache_storage_url", "cache_prefix"]
      fields.forEach((el) => { if (!this.form.settings[el] || this.form.settings[el].trim == '') { errorFields.push(el) } })

      if(errorFields.length == 0){
        return true
      } else {
        this.$notify({
          title: this.translate('changeSettingsWindow.msg.title'),
          message: `${this.translate('changeSettingsWindow.msg.for_error_fields')}${errorFields.join(', ')}`,
          type: 'warning',
          duration: 5000
        })

        return false
      }
    },
    formatForm(){
    }
  }
}
</script>
