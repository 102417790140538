<template lang="pug">
  el-dialog(
    v-if="bookingCredentialWindow.visible"
    :visible.sync="bookingCredentialWindow.visible" 
    center
    :title="bookingCredentialWindow.title"
    v-loading="bookingCredentialWindow.loading" 
    element-loading-text="Loading..."
    class='booking-credantial-window'
    width="90%")
    el-table(:data="bookingCredentialWindow.items")
      el-table-column(label="Locator" width="75px" prop="locator")
      el-table-column(label="Partner order id" prop="orderNo")
        template(slot-scope="scope")
          el-input(v-model="bookingCredentialWindow.form[scope.row.locator]")
      el-table-column(label="Airline site URL" prop="url")
      el-table-column(label="Login" prop="login")
      el-table-column(label="Password" prop="password")

    span.dialog-footer(slot="footer")
      el-button(@click="bookingCredentialWindow.visible = false") Close
      el-button(@click="saveBookingCredentials()" type="success") Save

</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'
import autoticketingStore from '@/settings/modules/avia/general/autoticketing_store'
import gdsStore from '@/settings/modules/avia/general/gds_store'

export default {
  
  mixins: [common, di],
  props: {
    bookingId: {
      type: Number
    },

    reloadBooking: null
  },
  data() {
    return {
      bookingCredentialWindow: {
        title: 'Credentials list',
        visible: false,
        loading: false,
        items: [],
        form: {}
      }
    }
  },

  methods: {
    async show()
    {
      this.bookingCredentialWindow.loading = true
      try {
        const query = {
          __args: {
            id: this.bookingId
          },
          collection: {
            locator: true,
            url: true,
            login: true,
            password: true,
            orderNo: true
          }          
        }

        let res = await this.graphql('aviaBookingItemCredentialList', query, 'query')
        this.bookingCredentialWindow.items = res.collection
        this._.each(res.collection, (item) => {
          this.bookingCredentialWindow.form = { ...this.bookingCredentialWindow.form, [item.locator]: item.orderNo }
        })
        this.bookingCredentialWindow.loading = false
        this.bookingCredentialWindow.visible = true
      } catch (e) {
        this.bookingCredentialWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    async saveBookingCredentials()
    {
      try {
        const query = {
          __args: {
            id: this.bookingId,
            data: JSON.stringify(this.bookingCredentialWindow.form)
          }
        }

        await this.graphql('aviaBookingItemSaveBookingCredential', query, 'mutation')
        this.reloadBooking(this.bookingId)

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.bookingCredentialWindow.visible = false
      } catch (e) {
        console.error(e)
      }      
    }
  }
}
</script>

<style lang="scss" scopped>
  .bold { font-weight: bold }

  .avia-commission-rules-window {
  }
</style>