import { loadCrudModules } from '@/utils'

export default {
  name: 'xex',
  path: '/xex',
  redirect: '/xex/dynamic_pricing',
  alwaysShow: true,
  meta: {
    title: 'xex',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./xex', true, /\.js$/))
}
