import lowcostParamsBaggage from './lowcost_params/baggage'

export default {
  namespace: 'avia',
  component: 'lowcost_params',
  meta: {
    title: 'avia.lowcost_params',
    icon: 'table'
  },
  tabs: [
    lowcostParamsBaggage
  ]
}
