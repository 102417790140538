export default {
  name: 'issue_queue_statistic',
  meta: {
    title: 'restrictions',
    icon: 'skill',
    permission: 'restrictions'
  },
  lazy: true,
  items: [
    {
      name: 'issue_queue_statistic',
      endpoint: '/persistance/default',
      type: 'graphql',
      pagination: false,
      graphql: {
        name: 'aviaIssueQueueStatistics',
        queries: {
          index: {
            status: true,
            inQueue: {
              total: true,
              byDepot: true,
              byPc: true,
              bySiteType: true,
              byDriver: true,
              byOperator: true
            }
          }
        }
      },
      table: {
        items: []
      },
      actions: {
        row: [],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          }
        ]
      },
      filter: {},
      form: {}
    }
  ]
}
