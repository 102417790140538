import search from './settings/search'
import timelimit from './settings/timelimit'
import refreshSchedule from './settings/refresh_schedule'
import limits from './settings/limits'
import priceConcurrency from './settings/price_concurrency'
import systemSettings from './settings/system_settings'

export default {
  namespace: 'ttn_avia_vi',
  component: 'settings',
  meta: {
    title: 'ttn_avia_vi.settings',
    icon: 'el-icon-share'
  },
  tabs: [
    search,
    timelimit,
    refreshSchedule,
    limits,
    priceConcurrency,
    systemSettings
  ]
}
