export default {
  avia_booking_list: {
    form: {
      legends: {
        pnrData: 'Pnr data',
        depotData: 'Depot data',
        issueQueue: 'Issue queue',
        showData: 'Show',
        other: 'Other',
        addonData: 'Addon data',
        paymentData: 'Payment data'
      },
      fields: {
        locator: 'Locator',
        status: 'Status',
        depotId: 'Depot',
        trafficSourceId: 'Traffic source',
        date: 'Booking date',
        ticketingTime: 'Issue date',
        operatorQueue: 'Operator',
        attentions: 'Attention',
        urgent: 'Only urgent < 72h',
        notUrgent: 'Only nonurgent > 72h',
        urgentH: 'Urgent (H)',
        notActually: 'Not actually',
        bookingByLegalPerson: 'Legal person',
        gmtOnly: 'GMT',
        withSpecialFare: 'Special fare',
        withSpecialFareSearch: 'Special fare search',
        fareDowngrade: 'Fare downgrade',
        hasAncillaryServices: 'Has ancillary',
        hasFareFamily: 'Fare families',
        fromBaggageTab: 'Baggage tab GDS',
        manualEmd: 'Manual EMD',
        baggageGds: 'Baggage GDS',
        baggageTabManual: 'Baggage tab manual',
        isRebooked: 'Rebooked',
        explorerCheck: 'Explorer check',
        priceComponentPartiallyIssued: 'Partially issued',
        excludeTicketsKz: 'Exclude Tickets KZ',
        groundSegments: 'ARNK',
        withInfants: 'With infants',
        isCharter: 'Charter',
        testOrder: 'Test',
        excludeConvBooking: 'Excl. conv. bookings',
        convBooking: 'Conv. bookings',
        selfTransfers: 'Self transfer',
        isMultiFare: 'Multi fare',
        isMulti: 'Only multi-ticket',
        isMultiGds: 'Only multi-GDS',
        withAuction: 'With auction',
        ttnAviaVi: 'TtnAviaVI',
        withDynamicCommission: 'Dynamic commission',
        priceComponentGte: 'PC >=',
        priceComponentLte: 'PC <=',
        isRecommended: 'Recommended',
        allFakeBooking: 'All fakes',
        allFakeSuccess: 'Success booked',
        fakeOnlyInProcess: 'In process',
        fakeExcludeInProcess: 'Exclude in process',
        fakeOnlyWithErrors: 'With errors',
        fakePnrExcluded: 'Excl. fake PNR',
        fakeReccExcluded: 'Excl. fake recc',
        aviaConfigItemId: 'Office',
        bspConfigItemId: 'BSP',
        gdsConfigItemId: 'GDS',
        ticketingOffices: 'Office',
        validatingSupplier: 'Validating supplier',
        marketingSupplier: 'Marketing supplier',
        autotckt: 'Autotckt',
        commentText: 'Comment text',
        ticketingComment: 'Ticketing comment',
        manualProcessing: 'Manual processing',
        additionalServices: 'Additional services (1 week)',
        ancillaryCategories: 'Ancillary category (1 week)',
        paySystem: 'Payment system',
        statusReason: 'Cancellation reason',
        discountInfo: 'Discount',
        excludeConsoQueue: 'Exclude conso',
        excludeBacklogQueue: 'Exclude backlog',
        excludeAutoticketingQueue: 'Exclude autoticketing',
        manualProcessingQueue: 'Manual processing',
      }
    },

    search: {
      locator: 'Locator',
      vnd_locators: 'Locator AC',
      user_id: 'User ID',
      comment: 'Comment',
      ticketing_comment: 'Ticketing comment',
      eticket_number: 'Eticket number',
      user_email: 'Email',
      user_phone: 'Phone',
      node_id: 'Node ID',
      depot_id: 'Depot ID',
      id: 'Booking ID',
      user_last_name: 'Last name',
      partner_order_id: 'Partner order ID',
      traffic_source_id: 'Traffic source ID',
      session_id: 'Session ID'
    }
  }
}
