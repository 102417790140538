export default {
  name: 'settings_price_concurrency',
  meta: {
    title: 'settings_price_concurrency',
    icon: 'el-icon-document-checked',
    permission: 'settings_price_concurrency'
  },
  lazy: true,
  items: [
    {
      name: 'settings_price_concurrency',
      endpoint: '/persistance/general/ttn_avia_vi',
      type: 'graphql',
      graphql: {
        name: 'settingsPriceConcurrencyRule',
        queries: {
          index: {
           id: true,
            oindex: true,
            routes: true,
            suppliers: true,
            rules: true,
            active: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'oindex',
            width: '70px'
          },
          {
            name: 'routes',
            format: 'array_as_string'
          },
          {
            name: 'suppliers',
            format: 'array_as_string'
          },
          {
            name: 'rules'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '120px'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
       row: [
         {
           name: 'update',
           icon: 'el-icon-edit',
           type: 'primary',
           plain: true
         }
       ],
       panel: [
         {
           name: 'create',
           icon: 'el-icon-plus',
           type: 'primary',
           plain: false
         },
         {
           name: 'delete',
           icon: 'el-icon-delete',
           type: 'danger',
           plain: true,
           dependent: 'selection'
         },
         {
           name: 'refresh',
           icon: 'el-icon-refresh',
           type: 'primary',
           plain: true
         },
         {
           name: 'history',
           icon: 'el-icon-refresh',
           type: 'warning',
           plain: true,
           params: {
             record_class: 'TtnAviaMultiSearchService::Settings::PriceConcurrencyRule'
           }
         },
         {
           name: 'filter',
           context: 'panel',
           icon: 'el-icon-help',
           plain: true
         }
       ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'oindex',
            type: 'text',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'routes',
            type: 'textarea',
            required: false,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 3
          },
          {
            name: 'suppliers',
            type: 'textarea',
            required: true,
            valueType: 'array_of_string',
            valueDelimiter: ',',
            rows: 3
          },
          {
            name: 'rules',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'rules__days_range',
            required: false,
            ignore: true
          },
          {
            name: 'rules__difference',
            required: false,
            ignore: true,
            valueType: 'float'
          },
          {
            name: 'rules__timelimit',
            required: false,
            ignore: true,
            valueType: 'integer'
          },
          {
            name: 'active',
            type: 'checkbox',
            required: true,
            default: true
          }
        ]
      }
    }
  ]
}
