export default {
  rail_settings_list: {
    action: {
      clear_cache: 'Clear cache'
    },
    table: {
      id : 'ID',
      settingType: 'Setting Type',
      config: 'Config',
      depotId: 'Depot ID',
      createdAt: 'Created At',
      updatedAt: 'Updated At'
    },
    form: {
      fields: {
        settingType: 'Setting Type',
        config: 'Config',
        depotId: 'Depot ID'
      }
    }
  }
}
