export default {
  items: [
    'selection',
    {
      name: 'id',
      width: '50px'
    },
    {
      name: 'priority',
      width: '75px',
      align: 'center'
    },
    {
      name: 'active',
      type: 'switch',
      width: '75px',
      align: 'center'
    },
    {
      name: 'bsp',
      width: '60px',
      align: 'center'
    },
    {
      name: 'gds',
    },
    {
      name: 'validatingSupplier',
      width: '80px',
      align: 'center'
    },
    'fareBasis',
    // 'beforeDeparture',
    // 'beforeDepartureAddFee',
    // 'beforeDepartureChangeTo',
    // 'afterDeparture',
    // 'afterDepartureAddFee',
    // 'afterDepartureChangeTo',
    // 'noShow',
    // 'noShowAddFee',
    // 'noShowChangeTo',
    {
      name: 'actions',
      width: '70px',
      align: 'center'
    }
  ]
}