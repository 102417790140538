export default {
  data() {
    return {
      childRecords: {},
      activeExpandedItems: []
    }
  },
  mounted() {
    this.$elItem.$onAction('refresh', this.resetChildrecords)
    this.$elItem.$on(this.$channels.filterReady, this.resetChildrecords)
  },
  beforeDestroy() {
    this.$elItem.$offAction('refresh', this.resetChildrecords)
    this.$elItem.$off(this.$channels.filterReady, this.resetChildrecords)
  },
  computed:{
    hasRemoteChildRecords() {
      if(!this.config.combined || this.config.combined.length == 0) { return false }
      return this.config.combined.some(element => (element.boot && element.boot.type == 'remote'))
    },
    childConfig() {
      return this.config.combined
    }
  },
  methods: {
    async resetChildrecords() {
      if(this.hasRemoteChildRecords) {
        this.childRecords = {}
        this.activeExpandedItems = []
      }
    },
    getParentFilters(index) {
      return this.childConfig[index].boot && this.childConfig[index].boot.parent_filters ? (this.parentFilters || this.filters) : {}
    },
    eventExpandChange(row, expandedRows) {
      this.hasRemoteChildRecords && this.initLoadCombinedChildRecords(row, expandedRows)
    },
    initLoadCombinedChildRecords(row, expandedRows) {
      for(let i in this.childConfig){
        if(this.childConfig[i].combineParams.expanded){
          this.activeExpandedItems.push(`${this.childConfig[i].name}_${i}`)
        }
      }

      let isExpanded = expandedRows.some(r => r.id === row.id)
      setTimeout(() => {
        if (isExpanded) {
          if (!this.childRecords[row.id]) {
            this.loadCombinedChildRecords(row)
          }
        } else {
          this.childRecords[row.id] = null
        }
        this.childRecords = { ...this.childRecords }
      }, 500)
    },
    expandRequestFilters(childConfig, row) {
      return {}
    },
    decorateCombinedChildRecords(childConfig, results) {
      return results
    },
    async loadCombinedChildRecords(row) {
      let anySuccess = false
      this.childRecords[row.id] = []

      for(let i in this.childConfig){
        let cConfig = this.childConfig[i]

        if(cConfig.boot.type != 'remote'){ continue }

        try {
          const query = {
            collection: cConfig.graphql.queries.index,
            __args: {
              limit: 25,
              page: 1,
              filters: this.expandRequestFilters(cConfig, row)
            }
          }

          const bootFiltersMapping = (cConfig.boot.filters_mapping || {})
          let existedFilters = []

          if(cConfig.boot.filters.length != 0) {
            query.__args.filters[cConfig.graphql.name] = []
            for(let field of cConfig.boot.filters) {
              existedFilters.push(field)
              query.__args.filters[cConfig.graphql.name].push({
                field: (bootFiltersMapping[field] || field),
                operator: 'eq',
                value: row[this._.camelCase(field)] + ''
              })
            }
          }

          // add filters from filter panel
          if(cConfig.boot && cConfig.boot.parent_filters) {
            console.log(this.parentFilters)
            const filterObj = this.parentFilters && !this._.isEmpty(this.parentFilters) ? this.parentFilters : (!this._.isEmpty(this.filters) ? this.filters : false)

            if(filterObj) {
              for(let k in filterObj) {
                if(k != this.config.graphql.name) { continue }

                for(let f of filterObj[k]) {
                  if(!existedFilters.includes(f.field)) { query.__args.filters[cConfig.graphql.name].push(f) }
                }
              }
            }
          }

          query.__args = { ...query.__args, ...(cConfig.graphql.baseParams.index || {}) }

          let result = await this.graphql(cConfig.graphql.name, query, 'collection')

          this.childRecords[row.id][i] = this.decorateCombinedChildRecords(cConfig, result.collection)
          this.childRecords = { ...this.childRecords }

          this.$message({
            message: 'Success!',
            type: 'success'
          })
          anySuccess = true
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }

      if(anySuccess){
        this.isReady = true
        return true
      }
    }
    // REmove in next release
    // async loadCombinedChildRecords(row) {
      // console.log(row)
      // console.log(this.childConfig)
      // try {
      //   const query = {
      //     collection: this.childConfig.graphql.queries.index,
      //     __args: {
      //       limit: 25,
      //       page: 1,
      //       filters: this.expandRequestFilters(this.childConfig, row)
      //     }
      //   }

      //   const bootFiltersMapping = (this.childConfig.boot.filters_mapping || {})
      //   let existedFilters = []

      //   if(this.childConfig.boot.filters.length != 0) {
      //     query.__args.filters[this.childConfig.graphql.name] = []
      //     for(let field of this.childConfig.boot.filters) {
      //       existedFilters.push(field)
      //       query.__args.filters[this.childConfig.graphql.name].push({
      //         field: (bootFiltersMapping[field] || field),
      //         operator: 'eq',
      //         value: row[this._.camelCase(field)] + ''
      //       })
      //     }
      //   }

      //   // add filters from filter panel
      //   if(this.childConfig.boot && this.childConfig.boot.parent_filters) {
      //     const filterObj = this.parentFilters && !this._.isEmpty(this.parentFilters) ? this.parentFilters : (!this._.isEmpty(this.filters) ? this.filters : false)

      //     if(filterObj) {
      //       for(let k in filterObj) {
      //         if(k != this.config.graphql.name) { continue }

      //         for(let f of filterObj[k]) {
      //           if(!existedFilters.includes(f.field)) { query.__args.filters[this.childConfig.graphql.name].push(f) }
      //         }
      //       }
      //     }
      //   }

      //   query.__args = { ...query.__args, ...(this.childConfig.graphql.baseParams.index || {}) }

      //   let result = await this.graphql(this.childConfig.graphql.name, query, 'collection')

      //   this.childRecords[row.id] = this.decorateCombinedChildRecords(this.childConfig, result.collection)
      //   this.childRecords = { ...this.childRecords }

      //   this.$message({
      //     message: 'Success!',
      //     type: 'success'
      //   })
      //   this.isReady = true
      //   return true
      // } catch (e) {
      //   this.$message({
      //     message: e,
      //     type: 'error'
      //   })
      // }
    // }

  }
}
