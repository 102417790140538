import gdsStore from '../../avia/general/gds_store'
export default {
  name: 'dynamic_pricing',
  alwaysShow: true,
  items: [
    {
      name: 'dynamic_pricing_schemes',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaDynamicPricingSchemes',
        queries: {
          index: {
            id: true,
            depotId: true,
            name: true,
            useMarkup: true,
            validateAuction: true,
            market: true,
            rules: {
              id: true
            },
            updatedAt: true
          }
        },
        preloadFields: ['markets{id name}']
      },
      preload: true,
      preloadStores: ['markets'],
      dependentItems: ['dynamic_pricing_rules'],
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '80px'
          },
          'name',
          {
            name: 'depotId',
            format: 'from_boot',
            storeKey: 'depots_hash',
            storeLabelKey: 'name'
          },
          'market',
          {
            name: 'useMarkup',
            type: 'switch'
          },
          {
            name: 'validateAuction',
            type: 'switch'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '100px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          },
          {
            name: 'dynamic_rules',
            icon: 'el-icon-s-operation',
            type: 'success',
            plain: true,
            roles: false
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::DynamicPricing::SchemeRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'name',
                type: 'text',
                operator: 'like',
                scope: 'dynamicPricingSchemes'
              },
              {
                name: 'market',
                operator: 'eq',
                type: 'select',
                storeKey: 'markets',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                scope: 'dynamicPricingSchemes'
              },
              {
                name: 'depot_id',
                operator: 'eq',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                scope: 'dynamicPricingSchemes'
              },
              {
                name: 'use_markup',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                },
                scope: 'dynamicPricingSchemes'
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'depot_id',
                type: 'select',
                storeKey: 'depots',
                storeLabelKeys: ['id', 'name'],
                lazy: true
              },
              {
                name: 'name',
                type: 'text'
              },
              {
                name: 'market',
                type: 'select',
                storeKey: 'markets',
                storePrimaryKey: 'id',
                storeLabelKey: 'name'
              },
              {
                name: 'validate_auction',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'use_markup',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          }
        ]
      }
    },

    {
      name: 'dynamic_pricing_rules',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaDynamicPricingRules',
        queries: {
          index: {
            id: true,
            schemeId: true,
            refid: true,
            forDirect: true,
            routeInclude: true,
            routeExclude: true,
            withCombination: true,
            airline: true,
            airlineType: true,
            driverIds: true,
            driverType: true,
            priority: true,
            airportToCity: true,
            active: true,
            updatedAt: true,
            markupRoundValue: true,
            markupRoundType: true,
            markupLossPosition: true,
            markupBehavior: true,
            maxMarkupPercent: true
          }
        },
        preloadFields: ['schemes{id name}']
      },
      preload: true,
      preloadStores: ['schemes'],
      stores: {
        airlineType: {
          0: 'include',
          1: 'exclude'
        },
        driverType: {
          0: 'include',
          1: 'exclude'
        },
        markupRoundType: {
          0: 'percent',
          1: 'amount'
        },
        markupLossPosition: {
          0: 'block',
          1: 'allow'
        },
        markupBehavior: {
          0: 'best',
          1: 'cheapest',
          2: 'save_position',
          3: 'save_profit'
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '80px'
          },
          {
            name: 'schemeId',
            format: 'from_instance',
            storeKey: 'schemes',
            storeLabelKey: 'name'
          },
          {
            name: 'refid',
            format: 'array_as_string',
            storeKey: 'traffic_sources_hash',
            storeLabelKey: 'name',
            default: '-',
            readMore: true,
            readMoreChars: 50
          },
          {
            name: 'routeInclude',
            // format: 'array_as_string',
            default: '-'
          },
          {
            name: 'routeExclude',
            // format: 'array_as_string',
            default: '-'
          },
          {
            name: 'airline',
            format: 'array_as_string'
          },
          {
            name: 'airlineType',
            format: 'from_options'
          },
          {
            name: 'driver_ids',
            format: 'array_as_string',
            default: '-'
          },
          {
            name: 'driverType',
            format: 'from_options'
          },
          'priority',
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::DynamicPricing::RuleRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'default',
            items: [
              {
                name: 'scheme_id',
                type: 'select',
                operator: 'eq',
                storeKey: 'schemes',
                storePrimaryKey: 'id',
                storeLabelKey: 'name',
                scope: 'dynamicPricingRules'
              },
              {
                name: 'refid',
                type: 'select',
                operator: 'in_array_int',
                storeKey: 'traffic_sources',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                scope: 'dynamicPricingRules'
              },
              {
                name: 'route_include',
                type: 'text',
                operator: 'like',
                labelKey: 'routeInclude',
                scope: 'dynamicPricingRules'
              },
              {
                name: 'route_exclude',
                type: 'text',
                operator: 'in_array_str',
                labelKey: 'routeExclude',
                scope: 'dynamicPricingRules'
              },
              {
                name: 'airline',
                type: 'text',
                operator: 'in_array_str',
                scope: 'dynamicPricingRules'
              },
              {
                name: 'airline_type',
                labelKey: 'airlineType',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: [
                  { id: '', label: 'Any' },
                  { id: '0', label: 'Include' },
                  { id: '1', label: 'Exclude' }
                ],
                scope: 'dynamicPricingRules'
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                },
                scope: 'dynamicPricingRules'
              },
              {
                name: 'for_direct',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                },
                scope: 'dynamicPricingRules'
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'scheme_id',
                type: 'select',
                valueType: 'integer',
                storeKey: 'schemes',
                storePrimaryKey: 'id',
                storeLabelKey: 'name'
              },
              {
                name: 'refid',
                type: 'multiselect',
                storeKey: 'traffic_sources',
                storeLabelKeys: ['id', 'name'],
                lazy: true,
                collapseTags: true,
                splitInput: true
              },
              {
                name: 'for_direct',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'airline',
                type: 'text',
                valueType: 'array_of_string',
                valueDelimiter: ','
              },
              {
                name: 'airline_type',
                type: 'radiogroup',
                storeKey: 'airlineType',
                translateOptions: true,
                default: '0',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'driver_ids',
                type: 'multiselect',
                store: gdsStore.arrayById(),
                default: [],
                storeLabelKeys: ['id', 'name'],
                collapseTags: false,
                splitInput: false,
                required: false,
              },
              {
                name: 'driver_type',
                type: 'radiogroup',
                storeKey: 'driverType',
                translateOptions: true,
                default: '0',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'withCombination',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'priority',
                type: 'text',
                default: 0,
                required: false,
                valueType: 'integer'
              },
              {
                name: 'route_include',
                type: 'textarea',
                required: false,
                // valueType: 'array_of_string',
                valueDelimiter: ','
              },
              {
                name: 'route_exclude',
                type: 'textarea',
                required: false,
                // valueType: 'array_of_string',
                valueDelimiter: ','
              },
              {
                name: 'airportToCity',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'active',
                type: 'checkbox',
                default: false,
                required: false
              }
            ]
          },
          {
            legend: 'markup',
            translateLegend: true,
            items: [
              {
                name: 'markup_round_value',
                type: 'text',
                default: '20',
                valueType: 'integer'
              },
              {
                name: 'markup_round_type',
                type: 'radiogroup',
                storeKey: 'markupRoundType',
                translateOptions: true,
                default: '1',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'markup_loss_position',
                type: 'select',
                storeKey: 'markupLossPosition',
                translateOptions: true,
                default: '1',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'markup_behavior',
                type: 'select',
                storeKey: 'markupBehavior',
                storePrimaryKey: 'id',
                translateOptions: true,
                default: '0',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'max_markup_percent',
                type: 'text',
                default: '0',
                valueType: 'integer',
                required: false
              }
            ]
          }
        ]
      }
    }
  ]
}