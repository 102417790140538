<template lang="pug">
  extends /table
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  methods: {
    preloadValue() {
      return this.$store.getters["insurance/settings/packets_risks/packets_risks_list/preload"]
    },
    preloadPackets() {
      return this.preloadValue().packets
    },
    preloadRisks() {
      return this.preloadValue().risks
    },
    formatCell({row, $column, column, cellValue, index}) {
      if (column.name == 'insurancePacketId') {
        const packet = this.preloadPackets().find((p) => p.id == row.insurancePacketId);
        return packet.name
      }

      if (column.name == 'insuranceRiskId') {
        const risk = this.preloadRisks().find((r) => r.id == row.insuranceRiskId);
        return risk.name
      }

      return cellValue
    }
  }
}
</script>
