export default {
  planned_maintenance_list: {
    table: {
      providerName: 'Provider Name',
      disabledStart: 'Start',
      disabledUntil: 'End',
      disableReason: 'Reason'
    },
    form: {
      fields: {
        providerName: 'Provider Name',
        disabledStart: 'Start',
        disabledUntil: 'End',
        disableReason: 'Reason'
      }
    },
    action: {
      update: 'Update Time',
      clear_time: 'Clear Time'
    }
  }
}