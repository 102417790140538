export default {
  connections_list: {
    table: {
      id : 'ID',
      name: 'Description',
      connection: 'Connection',
      nameAlias: 'Name',
    },
    form: {
      fields: {
        id : 'ID',
        name: 'Description',
        connection: 'Connection',
        nameAlias: 'Name',
      }
    }
  }
}
