<template lang="pug">
  el-dialog(
    v-if="crypticWindow.visible"
    :visible.sync="crypticWindow.visible" 
    center
    :title="crypticWindow.title"
    v-loading="crypticWindow.loading" 
    element-loading-text="Loading...")
    div(v-html="crypticInfo")

    span.dialog-footer(slot="footer")
      el-button(@click="crypticWindow.visible = false") Close
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins:[common, di],
  props: {
    bookingId: {
      type: Number
    },
    command: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      crypticInfo: '',
      crypticWindow: {
        loading: false,
        visible: false,
        title: 'Cryptic commands'
      }
    }
  },

  methods: {
    async show() {
      this.crypticWindow.loading = true
      this.crypticWindow.visible = true
      this.crypticInfo = ''
      try {
        const query = {
          __args: {
            id: this.bookingId,
            command: this.command
          }
        }

        let res = await this.graphql('aviaBookingItemCryptic', query, 'query')
        this.crypticWindow.loading = false
        this.crypticInfo = this._.replace(res, RegExp('\\n', 'gm'), '<br/>')

        if (this.command.length == 0) {
          ['Amadeus', 'Galileo', 'Sabre'].forEach((el) => {
            this.crypticInfo = this._.replace(this.crypticInfo, el, (x) => { return `<b>${x}</b>` })
          })
        }
      } catch (e) {
        this.crypticWindow.visible = false
        this.crypticWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }      
    }
  }
}
</script>

<style lang="scss" scopped>

</style>