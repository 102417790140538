export default {
  generated_files: {
    form: {
      fields: {
        entity: 'Entity',
        userId: 'UserId'
      }
    }
  }
}
