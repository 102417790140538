<style scoped>
  .row{ padding-right: 10px }
</style>

<template lang="pug">
  extends /form
  block fields
    div
      ttn-input(v-model="temp['id']" v-bind="attr('id')")
      el-row
        el-col(:span='4' class='row')
          ttn-checkbox(v-model="temp['active']" v-bind="attr('active')" label-width='70px')
        el-col(:span='8' class='row')
          ttn-input(v-model="temp['oindex']" v-bind="attr('oindex')" label-width='70px')

      ttn-textarea(v-model="temp['suppliers']" v-bind="attr('suppliers')")
      ttn-textarea(v-model="temp['routes']" v-bind="attr('routes')")

      fieldset(:class="`el-form-fieldset`")
        legend {{ translate(`form.legends.rules`) }}

        el-button(@click="actionAddItem('rules')" type="primary") {{ translate('form.add') }}
        div(v-if="temp.rules_decoded" v-for="(rule, i) in temp.rules_decoded")
          el-row
            el-col(:span='8' class='row')
              ttn-input(v-model="temp['rules_decoded'][i]['days_range']" v-bind="elAttr('rules__daysRange', `rules_decoded.${i}.days_range`)" label-width='100px' :rules="required")
            el-col(:span='5' class='row')
              ttn-input(v-model="temp['rules_decoded'][i]['difference']" v-bind="elAttr('rules__difference', `rules_decoded.${i}.difference`)" label-width='100px')
            el-col(:span='8' class='row')
              ttn-input(v-model="temp['rules_decoded'][i]['timelimit']" v-bind="elAttr('rules__timelimit', `rules_decoded.${i}.timelimit`)" label-width='100px' :rules="required")
            el-col(:span='1' class='row')
              el-button(@click="actionRemoveItem('rules', i)" type="danger" :disabled="isDeleteRowDisabled('rules')" class="sbpr-cnc-b") -
          hr
</template>

<script>
  import BaseForm from '@crud_view/form'
  import MultiRowField from '@crud_lib/form/multi_row_field'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    mixins: [MultiRowField],
    computed: {
      multiRowConfig(){
        return {
          rules: {
            default: { days_range: '0-365', difference: '', timelimit: 15}
          }
        }
      }
    },
    methods: {
    }
  }
</script>
