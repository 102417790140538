export default {
  name: 'packets',
  lazy: true,
  items: [
    {
      name: 'packets_list',
      endpoint: '/persistance/default',
      pagination: true,
      type: 'graphql',
      preload: true,
      preloadStores: [
        'risks'
      ],
      graphql: {
        name: 'insurancePackets',
        queries: {
          index: {
            id: true,
            providerName: true,
            zone: true,
            checkedItem: true,
          }
        },
        preloadFields: [
          'risks{id name}'
        ]
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '70px',
            align: 'right'
          }, 'providerName', 'zone', 'checkedItem', 'risks', {
            name: 'actions',
            width: '120px',
            align: 'center'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },{
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },{
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Legacy::Insurance::PacketRelation'
            }
          },{
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false
          },{
            name: 'provider_name',
            type: 'text',
            required: true
          },{
            name: 'zone',
            type: 'text',
            required: true
          },{
            name: 'checked_item',
            type: 'text',
            required: false
          }
        ]
      }
    }
  ]
}
