import reportBase from '../../avia/general/report'

export default {
  name: 'booking_report',
  meta: {
    title: 'booking_report',
    icon: 'el-icon-shopping-cart-full',
    permission: 'data_flight_item'
  },
  lazy: true,
  items: [
    {
      name: 'booking_report',
      ...reportBase.graphql(1),
      table: {
        items: [
          ...reportBase.table_items
        ]
      },
      actions: {
       row: [
        ...reportBase.actions_row
       ],
       panel: [
         ...reportBase.actions_panel
       ]
      },
      filter: {
        items: [
          ...reportBase.filter_items
        ]
      },
      form: {
        actualize: true,
        items: [
          ...reportBase.form_items(1),
          {
            name: 'reportParams__startDate',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            default: 'the beginning of this week',
            required: true
          },
          {
            name: 'reportParams__endDate',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            default: 'the end of this week',
            required: true
          },

        ]
      }
    }
  ]
}
