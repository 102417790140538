<template lang="pug">
  el-dialog(:fullscreen="true" :visible.sync="visible" :close-on-click-modal="false" @closed="onDialogClose" custom-class="group_details_dialog" :show-close="false")
    el-row
      el-col(:span="24")
        el-card(class="booking_info_card")
          template(slot="header")
            div &numero;{{group.id}} {{group.name}}
          el-descriptions(:border="true" :column="7" size="mini" class="price-table")
            el-descriptions-item(v-for="(price, code) in group.prices" :label="code" :key="code" :labelClassName="(group.currency === code ? 'bold' : '')")
              span(:class="{price: group.currency === code}") {{price}}

          div(class="flex-row segments-column")
            el-card(v-for="(trip, trip_index) in groupSegments" :key="`trip_${trip_index}`" class="flex-col trip-col" body-style="flex:auto; padding: 5px;")
              div(class="segment-wrapper flex-col")
                ttn-badge(
                  v-for="(segment, segment_index) in trip"
                  :key="`ss_${segment.id}`"
                  :value="segment.segment_index"
                  class="segment-badge"
                  :badge-style="{backgroundColor: segmentColor(segment.id)}"
                )
                  el-descriptions(:border="true" :column="1" :style="{borderColor: segmentColor(segment.id)}")
                    el-descriptions-item(label="Departure")
                      span(class="bold") {{segment.departure_location}},&nbsp;{{segment.departure_country}}
                        span(v-if="segment.departure_terminal !== ''") ,&nbsp;{{segment.departure_terminal}}
                      span &nbsp;{{segment.departure_time}}
                    el-descriptions-item(label="Arrival")
                      span(class="bold") {{segment.arrival_location}},&nbsp;{{segment.arrival_country}}
                        span(v-if="segment.arrival_terminal !== ''") ,&nbsp;{{segment.arrival_terminal}}
                      span &nbsp;{{segment.arrival_time}}
                    el-descriptions-item(label="Flight")
                      span(class="bold") {{segment.supplier_iata}}{{segment.carrier}}
                      span(
                        v-if="segment.other_supplier_iata !='' && segment.other_supplier_iata !== segment.supplier_iata"
                      ) 
                        span(class="other_supplier_iata") ({{segment.other_supplier_iata}}{{segment.carrier}})&nbsp;
                      span(v-else) ,&nbsp;
                      span {{segment.booking_class_type}}(#[span(class="bold") {{segment.booking_class}}) ]
                      span {{segment.aircraft || segment.aircraft_code}}
                    el-descriptions-item(label="Fare code")
                      span(class="bold") {{segment.fare_code}}
                    el-descriptions-item(label="Baggage")
                      span(class="bold") {{segment.baggage}}
          variant-services(:descriptions="descriptions")

        div(class="data-wrapper" v-loading="loading")
          el-card(class="run_info" v-for="item in collection" :name="item.runObject.id" :key="`item_${item.runObject.id}`")
            template(slot="header")
              div
                span Search type: {{item.runObject.searchType}} &nbsp;
                span 
                  span(
                    v-for="(n, id) in item.segmentNumbers"
                    class="el-badge__content"
                    :key="`sb_${id}`"
                    :style="{backgroundColor: segmentColor(id), color: '#606266'}"
                  ) {{n}}
                transition(name="fade-transform")
                  span(
                    v-if="selectedPrice[item.runObject.searchType] && parseFloat(selectedPrice[item.runObject.searchType].depot) > 0"
                    style="display: inline-block"
                  )
                    span(class="price selected-price") {{selectedPrice[item.runObject.searchType].depot.toFixed(2)}}
                      span(class="currency") {{item.bspCurrency || group.currency}}
                    span(class="source-price") &nbsp;({{selectedPrice[item.runObject.searchType].original.toFixed(2)}}
                      span(class="currency") {{selectedPrice[item.runObject.searchType].currency}})
                div(style="float: right")
                  a(@click="showAllResults(item)") All results
            el-table(:data="item.priceByPax" stripe border :span-method="priceTableSpanMethod")
              el-table-column(label="Per passenger")
                el-table-column(label="Type" prop="pax_type")
                el-table-column(label="Count" prop="pax_count")
                el-table-column(label="Fare")
                  template(slot-scope="scope") {{scope.row.pax_fare.price}}&nbsp;{{scope.row.pax_fare.currency}}
                el-table-column(label="Taxes")
                  template(slot-scope="scope") {{scope.row.pax_taxes.price}}&nbsp;{{scope.row.pax_taxes.currency}}
                el-table-column(label="Price")
                  template(slot-scope="scope") {{scope.row.pax_total.price}}&nbsp;{{scope.row.pax_total.currency}}
                el-table-column(label="Depot price")
                  template(slot-scope="scope") {{scope.row.pax_depot_total.price}}&nbsp;{{scope.row.pax_depot_total.currency}}
              el-table-column(label="BSP")
                el-table-column(label="Fare")
                  template(slot-scope="scope") {{scope.row.bsp_total.fare}}&nbsp;{{scope.row.bsp_total.currency}}
                el-table-column(label="Taxes")
                  template(slot-scope="scope") {{scope.row.bsp_total.taxes}}&nbsp;{{scope.row.bsp_total.currency}}
                el-table-column(label="Total")
                  template(slot-scope="scope") {{scope.row.bsp_total.total}}&nbsp;{{scope.row.bsp_total.currency}}
              el-table-column(label="Depot")
                el-table-column(label="Fare")
                  template(slot-scope="scope") {{scope.row.depot_total.fare}}&nbsp;{{scope.row.depot_total.currency}}
                el-table-column(label="Taxes")
                  template(slot-scope="scope") {{scope.row.depot_total.taxes}}&nbsp;{{scope.row.depot_total.currency}}
                el-table-column(label="Total")
                  template(slot-scope="scope")
                    span(class="price") {{scope.row.depot_total.total}}
                      span(class="currency") {{scope.row.depot_total.currency}}

            el-card(body-style="padding: 5px 0 0 0;")
              template(v-for="(trip, trip_index) in item.matches")
                single-trip(
                  :trip="trip"
                  :trip_index="trip_index"
                  :with-delimiter="true"
                  :key="`m_trips_${trip_index}`"
                  :class="matchClasses(item)"
                  :base-currency="item.bspCurrency"
                  :group-name="item.runObject.searchType"
                )
            a(class="source-link", :href="item.all.url", target="_blank") {{item.all.url}}

    all-results-dialog

</template>

<script>
import common from '@crud_lib/common'
import OriginalSegment from '../_partials/original_segment'
import SingleTrip from '../_partials/single_trip'
import SearchBlock from '../_partials/search_block'
import AllResultsDialog from './all_results_dialog'
import VariantServices from '../_partials/variant_services'

export default {
  name: 'GroupDetailsDialog',
  components: {
    OriginalSegment,
    SingleTrip,
    SearchBlock,
    AllResultsDialog,
    VariantServices
  },
  mixins: [common],
  props: {
    config: {
      required: true,
      type: Object
    },
    tab: {
      required: true,
      type: String
    },
    item: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      visible: false,
      loading: true,
      group: {},
      collection: [],
      activeResults: [],
      colorCache: {},
      selectedPrice: {},
      descriptions: {}
    }
  },
  computed: {
    extraData() {
      let extraData = {}
      if (this.group.extraData) {
        try {
          extraData = JSON.parse(this.group.extraData)
        } catch (e) {}
      }

      return extraData
    },
    groupSegments() {
      const segments = this.extraData.segments || []
      const trips = []
      let n = 1
      for(let i in segments) {
        if (trips[segments[i].trip_part] === undefined) {
          trips[segments[i].trip_part] = []
        }
        segments[i].segment_index = n
        trips[segments[i].trip_part].push(segments[i])
        n = n + 1
      }
      return trips
    }
  },
  mounted() {
    this.$parent.$on('showGroupDetailsDialog', (row) => {
      this.group = row
      this.visible = true
      this.$nextTick(() => {
        this.serverMatchVariants()
      })
    })
    this.$on('checkVariant', (data) => {
      if (!this.selectedPrice[data.group]) {
        this.selectedPrice[data.group] = {
          depot: 0,
          original: 0,
          currency: ''
        }
      }
      const currency = data.currency || this.group.currency
      this.selectedPrice[data.group].depot = this.round(this.selectedPrice[data.group].depot + data.item.prices[currency])
      this.selectedPrice[data.group].original = this.round(this.selectedPrice[data.group].original + data.item.price)
      this.selectedPrice[data.group].currency = data.item.currency
      this.selectedPrice = { ...this.selectedPrice }
    })
    this.$on('uncheckVariant', (data) => {
      const currency = data.currency || this.group.currency
      this.selectedPrice[data.group].depot = this.round(this.selectedPrice[data.group].depot - data.item.prices[currency])
      this.selectedPrice[data.group].original = this.round(this.selectedPrice[data.group].original - data.item.price)
      this.selectedPrice = { ...this.selectedPrice }
    })
  },

  beforeDestroy() {
    this.$parent.$off('showGroupDetailsDialog')
    this.$off('checkVariant')
    this.$off('uncheckVariant')
    this.onDialogClose()
  },
  methods: {
    async serverMatchVariants() {
      try {
        this.loading = true
        const query = {
          __args: { id: this.group.id },
          items: {
            collection: {
              all: true,
              matches: true,
              fullMatch: true,
              tripSegments: true,
              fareByPax: true,
              priceDetails: true,
              priceByPax: true,
              segmentNumbers: true,
              descriptions: true,
              bspCurrency: true,
              runObject: {
                id: true,
                startValue: true,
                decodedData: true,
                decodedSourceSegments: true,
                searchType: true,
                subGroup: true,
                passengersArray: true,
                passengers: true,
                status: true
              }
            }
          },
          descriptions: true
        }

        const res = await this.graphql('siteRunGroupViewDetail', query, 'mutation')
        this.$nextTick(() => {
          this.collection = res.items.collection
          this.descriptions = res.descriptions
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },

    matchClasses(item) {
      return {
        fullMatch: item.matches.length > 0 && item.fullMatch,
        partialMatch: item.matches.length > 0 && !item.fullMatch,
        notMatch: item.matches.length === 0
      }
    },

    searchLocations(item) {
      let original = {}
      try {
        original = JSON.parse(item.runObject.startValue).locations
      } catch (e) {}
      
      const value = [{
        departure: original.departure,
        arrival: original.arrival,
        date: original.outward_date
      }]
      if (original.return_date) {
        value.push({
          departure: original.arrival,
          arrival: original.departure,
          date: original.return_date
        })
      }
      return value
    },

    onDialogClose() {
      this.group = {}
      this.collection = []
      this.colorCache = {}
      this.selectedPrice = {}
      this.descriptions = {}
    },
    segmentColor(id) {
      const r = () => Math.floor(256 * Math.random())
      return this.colorCache[id] || (this.colorCache[id] = `rgba(${r()}, ${r()}, ${r()}, 0.3)`)
    },

    round(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100
    },

    priceTableSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 5) {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },

    showAllResults(item) {
      this.$root.$emit('showAllResultsDialog', item.all)
    }

  }
}
</script>

<style lang="scss" scoped>
  .group_details_dialog {
    .el-dialog__header {
      padding: 0;
    }
    .booking_info_card {
      .el-card__header {
        padding: 8px 10px;
      }
      .el-card__body {
        padding: 8px 10px;
      }
    }
    .run_info {
      .el-card__header {
        padding: 8px 10px;
      }
      .el-card__body {
        padding: 8px 10px;
      }
    }
    .segments-column {
      .trip-col {
        flex:1 1 auto;
      }
      .el-badge.segment-badge {
        flex: auto;
        .el-badge__content {
          right: 20px;
          top: 10px;
          color: #606266;
        }
      }

      .el-descriptions {
        flex: auto;
        margin: 1px;
        border: 3px solid;
      }
    }
    .price {
      color: red;
      font-size: 16px;
      font-weight: bold;
      &.selected-price {
        padding-left: 10px;
      }
    }
    .currency {
      font-size: 12px;
      padding-left: 4px;
    }
    .source-link {
      color: blue;
      padding: 5px 3px;
      display: inline-block;
      font-size: 11px;
    }
    .data-wrapper {
      min-height: 500px;
    }
  }
</style>
