import gds_list from '../general/gds_store'
import bsp_list from '../general/bsp_list'

export default {
  items: [
    {
                name: 'locator',
                scope: 'orderItems',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'status',
                type: 'multiselect',
                operator: 'in',
                scope: 'fakeBookings',
                store: {
                  '0': 'in_queue',
                  '1': 'in_progress',
                  '2': 'no_alternative',
                  '3': 'profit_booking',
                  '4': 'loss_booking',
                  '5': 'wrong_booking_status',
                  '6': 'search_error',
                  '7': 'overtop_loss',
                  '8': 'partially_booked',
                  '9': 'unconfirmed_flights',
                  '10': 'initial_booking',
                  '11': 'issued',
                  '12': 'cancel',
                  '13': 'exception',
                  '14': 'fake_is_off',
                  '15': 'payed'
                }
              },
              {
                name: 'profitability',
                type: 'select',
                operator: 'eq',
                scope: 'fakeBookings',
                store: {
                  'profit': 'In Profit',
                  'loss': 'In Loss',
                  'zero': 'Zero'
                }
              },
              {
                name: 'booking_status',
                scope: 'fakeBookings',
                type: 'select',
                operator: 'eq',
                store: {
                  'issued': 'Issued',
                  'cancel': 'Cancel',
                  'w': 'Waiting For Payment',
                  'pn': 'Payed. Waiting Issue'
                }
              },
              {
                name: 'order_date_range',
                operator: 'eq',
                type: 'daterange',
                required: false,
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                scope: 'fakeBookings'
              },
              {
                name: 'generate_report',
                type: 'select',
                button: true,
                operator: 'eq',
                scope: 'fakeBookings',
                store: {
                  '': '',
                  'by_booking': 'By Booking',
                  'by_passengers': 'By Passengers'
                }
              },
            {
              name: 'pc_gds_id',
              type: 'select',
              scope: 'bookingPriceComponents',
              operator: 'in_array_str',
              store: gds_list.objectById(),
              camelizeLangKey: true
            },
            {
              name: 'validating_supplier',
              type: 'text',
              scope: 'bookingPriceComponents',
              operator: 'eq'
            },
            {
              name: 'bsp_str',
              type: 'select',
              scope: 'bookingPriceComponents',
              operator: 'in_array_str',
              store: bsp_list,
            },
            {
              name: 'depot_id',
              type: 'integer',
              scope: 'bookingPriceComponents',
              operator: 'eq'
            },
            {
              name: 'traffic_source_id',
              type: 'integer',
              scope: 'bookingPriceComponents',
              operator: 'eq'
            },
            {
              name: 'locator_pc',
              type: 'text',
              scope: 'bookingPriceComponents',
              operator: 'eq'
            },


  ]
}