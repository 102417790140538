function dictionary(){
  return {
    table: {
      date: 'Date',
      gdsKey: 'Gds key',
      ordersCount: 'Ord.',
      segmentsCount: 'Seg.',
      bookingsCount: 'Book',
      trxTotal: 'Total',
      trxCache: 'cache',
      trxCachePercent: '%',
      trxGdsTimeout: 'tmout',
      trxGdsTimeoutPercent: '%',
      trxGdsSuccess: 'succ.',
      trxGdsSuccessPercent: '%',
      trxGdsError: 'error',
      trxGdsErrorPercent: '%',
      trxGdsReal: 'Real',
      l2bRate: 'L2b'
    },
    form: {
      fields: {
        date: 'Date',
        gdsId: 'Gds',
        gdsKey: 'Gds key',
        officeId: 'Offices',
        depotId: 'Depots',
        refId: 'Refs'
      }
    },
    action:{
      refresh_statistic: 'Refresh statistic',
      refresh_monthly_statistic: 'Refresh statistic'
    },
    refresh_statistic_window:{
      title: 'Refresh statistic',
      confirm: 'Are you sure to start process?',
      form: {
        start_time: 'Start time',
        end_time: 'End time'
      },
      msg:{
        title: 'Message',
        success: 'Process started. Check results in 30 sec.',
        error: 'Someting went wrong'
      }
    },
    refresh_monthly_statistic_window: {
      title: 'Refresh statistic',
      confirm: 'Are you sure to start process?',
      form: {
        start_date: 'Start date',
        end_date: 'End date'
      },
      msg: {
        title: 'Message',
        success: 'Process started. Check results in 30 sec.',
        error: 'Someting went wrong'
      }
    }
  }
}

export default {
  daily_statistics: dictionary(),
  daily_by_depot: dictionary(),
  monthly_statistics: dictionary(),
  monthly_by_depot: dictionary()
}
