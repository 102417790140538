export default {
  conversion: {
    form: {
      fields: {
        refid: 'Ref Id',
        dates: 'Date range',
        supplier: "Supplier(s) via ';' or 'AK+' as part",
        route: "Route(s) via ';'",
        configId: 'Config Item Id',
        depots: 'Depots',
        groupOptions: 'Group by',
        direct: 'Select type'
      },
    },
    table: {
      key: 'Ref ID / Depot',
      searches: 'Search',
      clicksCount: 'Clicks',
      cts: 'CTS, %',
      createdBookings: 'Created',
      paidBookings: 'Paid',
      issuedBookings: 'Issued',
      conversBookings: 'Convers',
      totalIssuedBookings: 'Total Issued',
      btr: 'BTR,%',
      ctr: 'CTR, %',
      demoCtr: 'Demo CTR, %',
      paidToCreated: 'Paid/Created, %',
      issuedToCreated: 'Issued/Created, %',
    },
    action: {
      download: 'Download'
    }
  },
  items: {
    table: {
      key: 'Key',
      searches: 'Search',
      clicks_count: 'Clicks',
      cts: 'CTS, %',
      created_bookings: 'Created',
      paid_bookings: 'Paid',
      issued_bookings: 'Issued',
      convers_bookings: 'Convers',
      total_issued_bookings: 'Total Issued',
      btr: 'BTR,%',
      ctr: 'CTR, %',
      demo_ctr: 'Demo CTR, %',
      paid_to_created: 'Paid/Created',
      issued_to_created: 'Issued/Created',
    }
  },
  first_level_items: {
    table: {
      key: 'Key',
      searches: 'Search',
      clicks_count: 'Clicks',
      cts: 'CTS, %',
      created_bookings: 'Created',
      paid_bookings: 'Paid',
      issued_bookings: 'Issued',
      convers_bookings: 'Convers',
      total_issued_bookings: 'Total Issued',
      btr: 'BTR,%',
      ctr: 'CTR, %',
      demo_ctr: 'Demo CTR, %',
      paid_to_created: 'Paid/Created',
      issued_to_created: 'Issued/Created',
    }
  },
  second_level_items: {
    table: {
      key: 'Key',
      searches: 'Search',
      clicks_count: 'Clicks',
      cts: 'CTS, %',
      created_bookings: 'Created',
      paid_bookings: 'Paid',
      issued_bookings: 'Issued',
      convers_bookings: 'Convers',
      total_issued_bookings: 'Total Issued',
      btr: 'BTR,%',
      ctr: 'CTR, %',
      demo_ctr: 'Demo CTR, %',
      paid_to_created: 'Paid/Created',
      issued_to_created: 'Issued/Created',
    }
  },
  third_level_items: {
    table: {
      key: 'Key',
      searches: 'Search',
      clicks_count: 'Clicks',
      cts: 'CTS, %',
      created_bookings: 'Created',
      paid_bookings: 'Paid',
      issued_bookings: 'Issued',
      convers_bookings: 'Convers',
      total_issued_bookings: 'Total Issued',
      btr: 'BTR,%',
      ctr: 'CTR, %',
      demo_ctr: 'Demo CTR, %',
      paid_to_created: 'Paid/Created',
      issued_to_created: 'Issued/Created',
    }
  },
}
