import gds_list from '@/settings/modules/avia/general/gds_store'
import bsp_list from '../../general/bsp_list'

export default {
  items: [
    {
      name: 'id',
      type: 'text',
      operator: 'eq',
      camelizeLangKey: true
    },
    {
      name: 'validatingSupplier',
      type: 'text',
      operator: 'eq',
      camelizeLangKey: true
    },
    {
      name: 'bsp',
      type: 'select',
      operator: 'eq',
      store: Object.assign({'all': 'all'}, bsp_list),
      camelizeLangKey: true
    },
    {
      name: 'gds',
      type: 'select',
      operator: 'in_array_str',
      store: Object.assign({'all': 'all'}, gds_list.objectByName()),
      camelizeLangKey: true
    },
    {
      name: 'active',
      type: 'radiogroup',
      button: true,
      operator: 'eq',
      store: {
        '': 'Any',
        true: 'Yes',
        false: 'No'
      }
    }
  ]
}