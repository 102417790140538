<template lang="pug">
  el-table(
    max-height="700px"
    :data="records.filter(data => !search || data[0].text.toLowerCase().includes(search.toLowerCase()))"
    :empty-text="$t('crud.empty_results')"
    :show-summary="summary && summary.length > 0"
    :summary-method="getSummaries"
    border
    stripe
  )
    el-table-column(type="index" width="50")
    template(v-for="(h, k) in columns")
      el-table-column(
        :label="h.name.toString()"
        :key="k"
        :width="h.width"
        class-name="my-cell"
        v-if="!h.hidden"
      )
        template(slot="header" slot-scope="scope")
          div(v-if="columns[scope.$index - 1].type !== 'config'" class="header-content header-search-flex")
            span(class="header-search-flex") {{scope.column.label}}
            el-input(
              class="header-search-flex"
              v-model="search"
              :placeholder="$elItem.translate('form.placeholder.search')"
              clearable
            )
          div(v-else class="header-content" :class="{current_config: configs.indexOf(columns[scope.$index - 1].name) !== -1}")
            div(class="header-label") {{scope.column.label}}
              el-popover(placement="bottom" width="auto" trigger="click")
                i(slot="reference" class="el-icon-question pointer")
                table(class="el-table__body el-table--border")
                  tr(class="el-table__row")
                    th(class="is-leaf")
                      div(class="cell") {{$elItem.translate('table.tooltip.session')}}
                    td(class="is-leaf")
                      div(class="cell") {{sessions.all[columns[scope.$index - 1].name] || sessions.all.m}}
                  tr(v-if="columns[scope.$index - 1].gds" class="el-table__row")
                    th(class="is-leaf")
                      div(class="cell") {{$elItem.translate('table.tooltip.config')}}
                    td(class="is-leaf")
                      div(class="cell") {{columns[scope.$index - 1].gds}}
            div(class="header_minmax" v-if="columns[scope.$index - 1].min !== undefined")
              span {{columns[scope.$index - 1].min}}
              span(class="s_last") {{columns[scope.$index - 1].max}}
        template(slot-scope="scope")
          div(
            :title="scope.row[k].title"
            class="my-row"
            :style="cellStyle(scope.row[k])"
          )
            span(class="my-flex-col") {{ scope.row[k].text }}
            span(class="icon_tools my-flex-col")
              span(class="my-flex-row")
                span(class="el-icon-warning-outline int_icon" :title="$elItem.translate('table.blocked')" v-if="scope.row[k].deleted")
                span(class="el-icon-connection int_icon" title="$elItem.translate('table.multi')" v-if="scope.row[k].is_multi")
              a(:href="scope.row[k].link" target="_blank" class="el-icon-link search-link my-flex-row" v-if="scope.row[k].link")
</template>

<script>

import di from '@crud_lib/di'

export default {
  mixins: [di],
  props: {
    records: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    sessions: {
      type: Object,
      required: true
    },
    configs: {
      type: Array,
      required: true
    },
    summary: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      search: ''
    }
  },

  methods: {
    cellStyle(row) {
      return {
        background: row.background,
        color: row.color
      }
    },

    getSummaries(param) {
      if (!this.summary || this.summary.length === 0) {
        return false
      }
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          return
        }
        if (index === 1) {
          sums[index] = this.$elItem.translate('table.total')
          return
        }
        sums[index] = this.summary[index - 1].min + ', ' + this.summary[index - 1].max
      })

      return sums
    }
  }
}
</script>
