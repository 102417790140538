export default {
  name: 'refid_configs',
  items: [
    {
      name: 'refid_configs_list',
      endpoint: '/persistance/default',
      pagination: true,
      type: 'graphql',
      graphql: {
        name: 'frontendRefidConfigs',
        exclude: ['actions', 'expand'],
        queries: {
          index: {
            id: true,
            refid: true,
            citizenship: true,
            defaultPhone: true,
            dateFormatMonthFirst: true,
            updatedAt: true,
            createdAt: true,
            active: true,
          }
        }
      },
      table: {
        items: [
          {
            name: 'id',
            width: '40px'
          },
          {
            name: 'refid',
            width: '100px'
          },
          'citizenship',
          'defaultPhone',
          {
            name: 'dateFormatMonthFirst',
            type: 'switch',
          },
          'createdAt',
          'updatedAt',
          {
            name: 'active',
            type: 'switch',
            width: '80px'
          },
          {
            name: 'actions',
            width: '60px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Frontend::RefidConfigRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'refid',
            type: 'text',
            operator: 'like',
            camelizeLangKey: true
          },
          {
            name: 'citizenship',
            type: 'text',
            operator: 'eq',
            camelizeLangKey: true
          },
          {
            name: 'default_phone',
            type: 'text',
            operator: 'eq',
            camelizeLangKey: true
          },
          {
            name: 'date_format_month_first',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              '': 'Any',
              true: 'yes',
              false: 'no'
            }
          },
          {
            name: 'active',
            type: 'radiogroup',
            button: true,
            operator: 'eq',
            store: {
              '': 'Any',
              true: 'yes',
              false: 'no'
            }
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'refid',
                type: 'text',
                translateOptions: true,
                valueType: 'string',
              },
              {
                name: 'citizenship',
                type: 'text',
                required: false
              },
              {
                name: 'default_phone',
                type: 'text',
                valueType: 'string',
                required: false
              },
              {
                name: 'date_format_month_first',
                type: 'checkbox',
                default: false,
                required: false
              },
              {
                name: 'active',
                type: 'checkbox',
                default: true,
                required: false
              }
            ]
          }
        ]
      }
    }
  ]
}
