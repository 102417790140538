<template lang="pug">
  extends /filter
  block info
    el-tab-pane(label="Info" class="tab-content-info" name='info')
      info-block(:detailedInformation="detailedInformation")
</template>

<script>
import BaseFilter from '@crud_view/filter'
import common from '@crud_lib/common'
import di from '@crud_lib/di'
import InfoBlock from './info'

export default {
  components: {
    InfoBlock
  },
  extends: BaseFilter,
  mixins: [common, di],

  data() {
    return {
      detailedInformation: null
    }
  },

  mounted() {
    this.getParent('CrudItem').$on('showInfo', this.showInfo)
  },
  beforeDestroy() {
    this.getParent('CrudItem').$off('showInfo', this.showInfo)
  },

  methods: {
    showInfo(currentRow) {
      this.detailedInformation = currentRow.detailedInformation
      this.activeTabValue = 'info'
    }
  }
}
</script>

<style lang="scss">
  #pane-avia_booking_list {
    .list-content.filter__right, .filter-form.filter__right { margin-right: 300px }
    .sidebar-filter { width: 310px !important }
  }
</style>