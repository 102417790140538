<template lang="pug">
  el-dialog(
    v-if="transactionWindow.visible"
    :visible.sync="transactionWindow.visible" 
    center
    :title="transactionWindow.title"
    v-loading="transactionWindow.loading" 
    element-loading-text="Loading..."
    class='avia-transaction-window')
    el-row
      el-col
        el-table(
          :data="transactionWindow.transactions"
          :row-class-name="transactionWindowTableRowClassName")
          el-table-column(type="expand")
            template(slot-scope="scope")
              div Comment: {{ scope.row.comment }}
              div Error: {{ scope.row.processingError }}
              div Error (translation): {{ scope.row.processingErrorEngl }}
              div Original error name: {{ scope.row.processingErrorOriginal }}

          el-table-column(label="Time|Card number" :render-header="transactionWindowRenderMultilineHeader")
            template(slot-scope="scope")
              div {{ scope.row.createdAt }}
              div(v-if="scope.row.pan6") {{ scope.row.pan6 }}****{{ scope.row.pan4 }}

          el-table-column(label="Payment system|ID transaction" :render-header="transactionWindowRenderMultilineHeader")
            template(slot-scope="scope")
              div {{ scope.row.paymentSystemName }}
              div {{ scope.row.transactionId }}

          el-table-column(label="Total|Amount + Commission" :render-header="transactionWindowRenderMultilineHeader")
            template(slot-scope="scope")
              div.bold {{ `${scope.row.total} ${scope.row.currency}` }}
              div {{ scope.row.amount }} + {{ scope.row.commission }}

          el-table-column(label="Status" prop="status")

    span.dialog-footer(slot="footer")
      el-button(@click="transactionWindow.visible = false") Close
</template>

<script>
// import BaseTable from '@crud_view/table'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins:[common, di],
  props: {
    booking: null
  },
  data() {
    return {
      transactionWindow: {
        title: 'Transactions history',
        visible: false,
        loading: false,
        transactions: []
      }
    }
  },

  methods: {
    async show()
    {
      this.transactionWindow.visible = true
      this.transactionWindow.loading = true
      try {
        const query = {
          __args: {
            locator: this.booking.locator
          },
          collection: {
            id: true,
            createdAt: true,
            paymentSystem: true,
            transactionId: true,
            amount: true,
            systemId: true,
            status: true,
            total: true,
            currency: true,
            commission: true,
            pan4: true,
            pan6: true,
            paymentSystemName: true,
            comment: true,
            processingError: true,
            processingErrorEngl: true,
            processingErrorOriginal: true
          }
        }

        let res = await this.graphql('aviaBookingItemPaymentTransaction', query, 'query')
        this.transactionWindow.transactions = res.collection
        this.transactionWindow.loading = false
      } catch (e) {
        this.transactionWindow.visible = false
        this.transactionWindow.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    transactionWindowRenderMultilineHeader(h, { column, $index }) {
      let lines = column.label.split('|')
      return h('div', {}, [
        h('span', lines[0]),
        h('br'),
        h('span', lines[1])
      ])      
    },

    transactionWindowTableRowClassName({ row, rowIndex }) {
      return `row-${row.status}`
    }
  }
}
</script>

<style lang="scss" scopped>
  .bold { font-weight: bold }

  .avia-transaction-window{
    .el-table tr.row-failure { background-color: #FFCFCF }
    .el-table tr.row-issuer_void_failed { background-color: #D48FFF }
    .el-table tr.row-success { background-color: #9EFF7F }
    .el-table tr.row-refunded { background-color: #FFCC66 }
    .el-table tr.row-lock { background-color: #C0C0C0 }
    .el-table tr.row-unlock { background-color: #75D2FF }
    .el-table tr.row-refunded_confirm { background-color: #B97A57 }
    .el-table tr.row-preauth { background-color: #FF3E9E }
    .el-table tr.row-order_info { background-color: #EEEEEE }
    .el-table tr.row-accept { background-color: #8DB2E3 }
    .el-table tr.row-reject { background-color: #8DB2E3 }
    .el-table tr.row-review { background-color: #8DB2E3 }
    .el-table tr.row-preauth_accept { background-color: #f9b1d5 }
    .el-table tr.row-preauth_reject { background-color: #f9b1d5 }
    .el-table tr.row-preauth_review { background-color: #f9b1d5 }
    .el-table tr.row-preauth_secure_queue { background-color: #f9b1d5 }
  }
</style>