export default {
  table: {
    active: 'Active',
    gdsId: 'GDS ID',
    fareName: 'Fare Name',
    validatingSupplier: 'Validating Supplier',
    priority: 'Priority',
    departureFrom: 'Departure',
    arrivalTo: 'Arrival',
    carrier: 'Carrier',
    flight: 'Flight(-s)',
    actions: 'Actions',
  },
  form: {
    fields: {
      id: 'ID',
      active: 'Active',
      gdsId: 'GDS ID',
      fareName: 'Fare Name',
      validatingSupplier: 'Validating Supplier',
      priority: 'Priority',
      departureFrom: 'Departure From',
      transfer: 'Transfer In',
      arrivalTo: 'Arrival To',
      withReverseRoute: 'Reverse route',
      carrier: 'Marketing Carrier',
      flight: 'Flight Number(-s)',
      INC: 'Included',
      CHA: 'Chargeable',
      NOF: 'Not offered',
    },
    legends: {
      general_data: 'General data',
      route_data: 'Route data',
      flight_data: 'Flight data',
      fare_data: 'Fare data',
    }
  }
}