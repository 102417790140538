export default {
  backend_users: {
    table:{
      login: 'Login',
      email: 'Email'
    },
    form: {
      fields: {
        login: 'Login'
      },
      legends: {
        default: 'Filters'
      }
    },
    action: {
      change_roles: 'Change roles'
    },
    modal: {
      change_permissions: 'Change user permissions'
    }
  },
  backend_roles: {
    table: {
      key: 'Key',
      type: 'Type'
    },
    form: {
      fields: {
        key: 'Key'
      },
      legends: {
        default: 'Filters'
      }
    },
    action: {
      import: 'Import'
    },
    alert: {
      import: 'Are you sure?'
    }
  }
}

