export default {
  refid_configs_list: {
    form: {
      fields: {
        refid: 'RefId',
        defaultPhone: 'Default Phone Code',
        dateFormatMonthFirst: 'Month first in date',
        active: 'Active',
        citizenship: 'Default Citizenship',
      },
    },
    table: {
      refid: 'RefId',
      active: 'Active',
      citizenship: 'Default Citizenship',
      defaultPhone: 'Default Phone Code',
      dateFormatMonthFirst: 'Month first in date format',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
    }
  }
}
