<template lang="pug">
  el-dialog(:fullscreen="false" :title="translate('modal.view_details')" :visible.sync="visible" :close-on-click-modal="false" width="50%" :custom-class="'dialog-edit-form'")
    el-form(ref="dataForm" :model="temp")
      el-form-item
        el-input(v-model="dataText" type="textarea" rows=20)
    div(slot="footer" class="dialog-footer")
      el-button(@click="visible = false") {{ translate('form.cancel') }}
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  data() {
    return {
      visible: false,
      dataText: '',
      temp: {}
    }
  },

  mounted() {
    this.$parent.$on('showDialogInfo', (text) => {
      this.dataText = text
      this.visible = true
    })
  },

  beforeDestroy() {
    this.$parent.$off('showDialogInfo')
  },

  methods: { }
}
</script>
