import { jsonToGraphQLQuery } from 'json-to-graphql-query'

export default {
  methods: {
    buildCrudParams() {
      switch (this.config.type) {
        case 'graphql':
          return this[`_${this.crudType}Graphql`](this.formatTemp())
        default:
          return this[`_${this.crudType}Rest`](this.formatTemp())
      }
    },
    buildActualizeParams(row) {
      switch (this.config.type) {
        case 'graphql':
          return this['_actualizeGraphql'](row)
        default:
          return this['_actualizeRest'](row)
      }
    },

    _updateGraphql(temp) {
      const query = {
        mutation: {}
      }
      query.mutation[this.config.graphql.actions.update] = { id: true }
      query.mutation[this.config.graphql.actions.update]['__args'] = this.fields.reduce((acc, i) => {
        if(i.ignore) { return acc }
        if(i.name.indexOf('__')) {
          this._.set(acc, i.name.replace('__', '.'), this.formatField(temp[i.name], i))
        } else{
          acc[i.name] = this.formatField(temp[i.name], i)
        }
        return acc
      }, {})
      // .replace('[null]', '[]') monkeypatch for empty
      return { query: `${jsonToGraphQLQuery(query).replace('[null]', '[]')}` }
    },

    _createGraphql(temp) {
      const query = {
        mutation: {}
      }
      query.mutation[this.config.graphql.actions.create] = { id: true }
      query.mutation[this.config.graphql.actions.create]['__args'] = this.fields.filter((i) => i.name !== 'id').reduce((acc, i) => {
        if(i.ignore) { return acc }
        if(i.name.indexOf('__')) {
          this._.set(acc, i.name.replace('__', '.'), this.formatField(temp[i.name], i))
        } else{
          acc[i.name] = temp[i.name] !== undefined ? temp[i.name] : ''
          acc[i.name] = this.formatField(temp[i.name], i)
        }

        return acc
      }, {})
      // .replace('[null]', '[]') monkeypatch for empty
      return { query: `${jsonToGraphQLQuery(query).replace('[null]', '[]')}` }
    },

    _actualizeGraphql(row) {
      const query = {}
      query[this.config.graphql.actions.get] = this.config.graphql.queries.get !== undefined ? this.config.graphql.queries.get : this.fields.reduce((acc, i) => {
        if(i.ignore) { return acc }
        if(i.name.indexOf('__')) {
          this._.set(acc, i.name.replace('__', '.'), true)
        } else{
          acc[i.name] = true
        }
        return acc
      }, {})

      query[this.config.graphql.actions.get]['__args'] = {
        id: row.id
      }
      return { query: `{${jsonToGraphQLQuery(query)}}` }
    },

    _updateRest() {
      return this.temp
    },

    _createRest() {
      return this.temp
    },

    _actualizeRest(row) {
      return { id: row.id }
    },

    async beforeUpdateCallback() {},
    async afterUpdateCallback() {},
    async beforeCreateCallback() {},
    async afterCreateCallback() {},
    async actualizeCallback(temp) {
      let newTemp = {}
      for(let i = 0; i < this.fields.length; i++) {
        let field = this.fields[i]

        if(field.name.indexOf('__') !== -1) {
          newTemp[field.name] = this.formatFieldReverse(this._.get(temp, field.name.replace('__', '.')), field)
        } else{
          newTemp[field.name] = this.formatFieldReverse(temp[field.name], field)
        }
      }
      return newTemp
    }
  }
}
