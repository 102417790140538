export default {
  name: 'seats_block_reports',
  meta: {
    title: 'Reports',
    icon: 'skill',
    permission: 'pesimisation_rules'
  },
  lazy: true,
  items: [
    {
      name: 'seats_block_reports',
      endpoint: '/persistance/default',
      type: 'graphql',
      preload: false,
      graphql: {
        name: 'aviaSeatsBlockReports',
        queries: {
          index: {
            id: true,
            name: true,
            handlerStatus: true,
            filePath: true,
            searchOptions: {
              startDepartureDate: true,
              endDepartureDate: true,
              validatingSupplier: true,
              officeId: true,
              contractId: true,
              routeKey: true,
              useGdsData: true
            },
            reportType: true,
            createdAt: true,
            updatedAt: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '40px'
          },
          {
            name: 'name'
          },
          {
            name: 'reportType',
            format: 'from_options'
          },
          {
            name: 'handlerStatus',
            format: 'from_options'
          },
          {
            name: 'filePath'
          },
          {
            name: 'updatedAt',
            width: '120px',
            format: 'date_time'
          },
          {
            name: 'actions',
            width: '60px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'download',
            icon: 'el-icon-download',
            type: 'success',
            plain: true,
            roles: false
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::SeatsBlock::ReportRelation'
            }
          }
        ]
      },
      // filter: {
      //   items: [
      //     {
      //       name: 'id',
      //       type: 'text'
      //     },
      //     {
      //       name: 'active',
      //       type: 'radiogroup',
      //       button: true,
      //       operator: 'eq',
      //       store: {
      //         '': 'Any',
      //         true: 'yes',
      //         false: 'no'
      //       }
      //     },
      //     {
      //       name: 'gds_id',
      //       type: 'select',
      //       operator: 'eq',
      //       storeKey: 'gds',
      //       storePrimaryKey: 'id',
      //       storeLabelKey: 'gdsName'
      //     }
      //   ]
      // },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'General',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'name',
                type: 'text',
                required: true
              },
              {
                name: 'report_type',
                type: 'select',
                store: {
                  0: 'available_seats'
                },
                translateOptions: true,
                default: '0',
                required: true,
                valueType: 'integer'
              }
            ]
          },
          {
            legend: 'settings',
            translateLegend: true,
            items: [
              {
                name: 'searchOptions__startDepartureDate',
                type: 'date',
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: 'the beginning of this month',
                required: false
              },
              {
                name: 'searchOptions__endDepartureDate',
                type: 'date',
                options: {
                  firstDayOfWeek: 1
                },
                valueFormat: 'yyyy-MM-dd',
                default: 'the end of this month',
                required: false
              },
              {
                name: 'searchOptions__validatingSupplier',
                type: 'text',
                required: false,
                valueType: 'array_of_string'
              },
              {
                name: 'searchOptions__officeId',
                type: 'text',
                required: false,
                valueType: 'array_of_integer'
              },
              {
                name: 'searchOptions__contractId',
                type: 'text',
                required: false
              },
              {
                name: 'searchOptions__routeKey',
                type: 'text',
                required: false
              },
              {
                name: 'searchOptions__useGdsData',
                type: 'checkbox',
                default: false,
                required: false,
                valueType: 'boolean'
              },
            ]
          }
        ]
      }
    }
  ]
}
