export default {
  name: 'shift_statistic',
  meta: {
    title: 'restrictions',
    icon: 'skill',
    permission: 'restrictions'
  },
  lazy: true,
  items: [
    {
      name: 'shift_statistic',
      endpoint: '/persistance/default',
      type: 'graphql',
      pagination: false,
      graphql: {
        name: 'aviaShiftStatistics',
        queries: {
          index: {
            queueName: true,
            details: true
          }
        }
      },
      table: {
        items: []
      },
      actions: {
        row: [],
        panel: []
      },
      filter: {},
      form: {}
    }
  ]
}
