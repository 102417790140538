<template lang="pug">
  div(class="list-content fake_search_stata_table")
    el-table(:data="records" :row-class-name="tableRowClassName" v-loading="loading")
      el-table-column(prop="locator", label="Locator")
      el-table-column(label="Depot")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.depot.name }}
      el-table-column(label="Traffic Source")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.trafficSource ? scope.row.trafficSource.name : '' }}
      el-table-column(label="Booking Status")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.booking ? scope.row.booking.status : '' }}
      el-table-column(label="RebookAt")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ scope.row.fakeBooking ? scope.row.fakeBooking.bookingStatus : '' }}
      el-table-column(label="Result")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ getRebookStatus(scope.row) }}
      el-table-column(label="Profit")
        template(slot-scope="scope")
          span(style="white-space: nowrap;") {{ getProfit(scope.row) }}
      el-table-column(label="Order At")
        template(slot-scope="scope")
          span {{new Date(scope.row.orderedAt) | moment("DD.MM.YY HH:mm:ss")}}
      el-table-column
        template(slot-scope="scope")
          el-button(
            type="primary"
            plain
            size="mini"
            icon="el-icon-magic-stick"
            @click="run(scope.row)"
            v-if="rebookAvailable(scope.row)"
            )
      el-table-column(type="expand")
        template(slot-scope="scope")
          el-tabs(v-model="activeName[scope.row.locator]", type="border-card")
            el-tab-pane(label="Pricing", :key='"p" + scope.row.locator', :name='"p" + scope.row.locator')
              el-table(:data="getPriceRows(scope.row)")
                el-table-column(prop="currency", label="Currency")
                el-table-column(label="Нетто")
                  el-table-column(label="Старого бука")
                    template(slot-scope="sc")
                      span {{ getPrice(sc.row.currency, 'netto_old', scope.row) }}
                  el-table-column(label="Нового бука")
                    template(slot-scope="sc")
                      span {{ getPrice(sc.row.currency, 'netto_new', scope.row) }}
                el-table-column(label="Сумма оплаты")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'sum_pay', scope.row) }}
                el-table-column(label="Эквайринг")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'equiring', scope.row) }}
                el-table-column(label="Цена Трафика")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'traff_pirce', scope.row) }}
                el-table-column(label="Наценка Всего")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'extra_charge', scope.row) }}
                el-table-column(label="Динам. нац.")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'dynamic_cost', scope.row) }}
                el-table-column(label="Total Discount")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'total_discount', scope.row) }}
                el-table-column(label="Доплата")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'addl_payment', scope.row) }}
                el-table-column(label="Доп наценка")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'addl_extra', scope.row) }}
                el-table-column(label="Fake Baggage")
                  template(slot-scope="sc")
                    span {{ getPrice(sc.row.currency, 'fake_baggage', scope.row) }}
            el-tab-pane(label='Price Components/ Segments / Passengers', :key='"pc" + scope.row.locator', :name='"pc" + scope.row.locator')
              el-table(:data="scope.row.aviaBookings")
                el-table-column(prop="locator", label="Locator")
                el-table-column(prop="supplier", label="Supplier")
                el-table-column(label="PriceScheme")
                  el-table-column(label="startP" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.st_p}}

                  el-table-column(label="M" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_m}}
                  el-table-column(label="I" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_i}}
                  el-table-column(label="C" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_c}}
                  el-table-column(label="P" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_p}}
                  el-table-column(label="S" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_s}}
                  el-table-column(label="A" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_a}}
                  el-table-column(label="RD" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_rd}}
                  el-table-column(label="R" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.col_r}}
                  el-table-column(label="Meta" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.meta}}
                  el-table-column(label="BspC" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.bsp_c }}
                  el-table-column(label="RateM" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.rate_margin}}
                  el-table-column(label="Revenue" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.revenue_margin}}
                  el-table-column(label="SaleP" width="70px")
                    template(slot-scope="scope")
                      span {{ scope.row.priceScheme.sl_p}}
                el-table-column(label="Departure", prop="departure")
                  template(slot-scope="scope")
                    span {{scope.row.departureTime | moment("LLL")}}
                el-table-column(type="expand")
                  template(slot-scope="scope")
                    el-col(:span="10", :offset="2")
                      el-row
                        h3 Segments
                      el-row
                        el-table(:stripe="true", :data="scope.row.aviaBookingSegments")
                          el-table-column(label="Supplier", prop="supplier")
                          el-table-column(label="Departure")
                            el-table-column(label="Iata", prop="departure")
                            el-table-column(label="Date")
                              template(slot-scope="scope")
                                span {{scope.row.departureDate + ' ' + scope.row.departureTime}}
                          el-table-column(label="Arrival")
                            el-table-column(label="Iata", prop="arrival")
                            el-table-column(label="Date")
                              template(slot-scope="scope")
                                span {{scope.row.arrivalDate + ' ' + scope.row.arrivalTime}}

                    el-col(:span="10", :offset="2")
                      el-row
                        h3 Passengers
                      el-row
                        el-table(:stripe="true", :data="scope.row.aviaBookingPassengers")
                          el-table-column(label="Type", prop="type")
                            template(slot-scope="scope")
                              span {{scope.row.type + '. ' + scope.row.gender}}
                          el-table-column(label="Name")
                            template(slot-scope="scope")
                              span {{scope.row.firstName +  ' ' + scope.row.lastName}}
                          el-table-column(label="Birth Date", prop="birthDate")

            el-tab-pane(label='Search Rules', :key='"search" + scope.row.locator', :name='"search" + scope.row.locator')

              el-row
                h3 Payed Book:
              el-row
                el-table(:stripe="true", :data="getRules(scope.row.fakeBooking.payedSearchRule)" v-if="scope.row.fakeBooking")
                  el-table-column(label="Identifier")
                    template(slot-scope="scp")
                      span {{ scp.row["locator"] || scp.row["pc_id"]}}
                  el-table-column(label="Rule ID")
                    el-table-column(label="Depot")
                      template(slot-scope="scp")
                        span {{ scp.row["depot_rule"]["id"] }}
                    el-table-column(label="D.Child")
                      template(slot-scope="scp")
                        span {{ scp.row["depot_rule"]["child_id"] }}
                    el-table-column(label="Search")
                      template(slot-scope="scp")
                        span {{ scp.row["config_rules"].join(", ") }}
                  el-table-column(label="Configs")
                    template(slot-scope="scp")
                      span {{ scp.row["configs"].join(", ") }}
                      span &nbsp;&nbsp;
                      span
                        el-button(
                          type="warning"
                          plain
                          size="mini"
                          icon="el-icon-edit"
                          @click="actionRefreshStatistic(scp.row, 'payed_search_rule', scope.row.fakeBooking.bookingId)"
                        )

                  el-table-column(label="Formula", prop="calculate")
                  el-table-column(label="Margin", prop="margin")
                  el-table-column(label="Rate Margin", prop="rate_margin")

            el-tab-pane(label="Book Rules", :key='"pf" + scope.row.locator', :name='"pf" + scope.row.locator', v-if="scope.row.fakeBooking")
              el-row
                h3 Fake Pnr Rule:
              el-row
                el-table(:stripe="true", :data="getRules(scope.row.fakeBooking.rule)")
                  el-table-column(label="ID")
                    el-table-column(label="Rule", prop="id")
                    el-table-column(label="Depots")
                      template(slot-scope="scp")
                        span {{ scp.row["depots"].join(", ") }}
                    el-table-column(label="Ref")
                      template(slot-scope="scp")
                        span {{ scp.row["ref_id"].join(", ") }}
                  el-table-column(label="GDS", prop="gds")
                    template(slot-scope="scp")
                      span {{ scp.row["gds"].join(", ") }}
                  el-table-column(label="Pay Time Limit (min.)", prop="pay_limit")
                  el-table-column(label="Status", prop="status")
                    template(slot-scope="scp")
                      span(v-html='wrapStatus(scp.row["status"])')
              el-row
                h3 Fake Pnr Airline Rule:
              el-row
                el-table(:stripe="true", :data="getRules(scope.row.fakeBooking.airlineRule)")
                  el-table-column(label="ID")
                    el-table-column(label="Rule", prop="id")
                  el-table-column(label="Supplier")
                    template(slot-scope="scp")
                      span {{ scp.row["vc"].join(", ") }}
                  el-table-column(label="Gds")
                    template(slot-scope="scp")
                      span {{ scp.row["gds"].join(", ") }}
                  el-table-column(label="Hours Before Dept.", prop="hours_before_departure")
                  el-table-column(label="Status", prop="status")
                    template(slot-scope="scp")
                      span(v-html='wrapStatus(scp.row["status"])')
              el-row
                h3 Attrs:
              el-row
                el-table(:stripe="true", :data="getRules(scope.row.fakeBooking.attrs)")
                  el-table-column(label="ID", prop="depot_id")
                    el-table-column(label="Depot", prop="depot_id")
                    el-table-column(label="System")
                      template(slot-scope="scp")
                        span {{ scp.row["system_ids"].join(", ") }}
                  el-table-column(label="Is Multi")
                    template(slot-scope="scp")
                      span {{ scp.row["is_multi"] == true ? 'true' : 'false'  }}
                  el-table-column(label="Is MultiGds")
                    template(slot-scope="scp")
                      span {{ scp.row["is_multi_gds"] == true ? 'true' : 'false'  }}
                  el-table-column(label="Has Ancillary")
                    template(slot-scope="scp")
                      span {{ scp.row["has_ancillary_services"] == true ? 'true' : 'false'  }}
                  el-table-column(label="Is Lcc")
                    template(slot-scope="scp")
                      span {{ scp.row["is_lcc"] == true ? 'true' : 'false'  }}
                  el-table-column(label="Card Allowed")
                    template(slot-scope="scp")
                      span {{ scp.row["card_allowed"] == true ? 'true' : 'false'  }}

            el-tab-pane(label='Research Results', :key='"search_result" + scope.row.locator', :name='"search_result" + scope.row.locator', v-if="scope.row.fakeBooking")
              el-card(v-for="(stata_scoped, index) in getLooper(scope.row.fakeBooking.searchStat)", :key='"pc_goupped" + index', :class="colorCard(index)")
                h3 Group {{index+1}}
                el-row(v-if="scopedDetailsExists(stata_scoped)")
                  el-table(:data="getShortenScopeData(stata_scoped)", :row-class-name="tablePriceHeaderClassName")
                      el-table-column(label="Formula Price")
                        el-table-column(label="PC", prop="book_price")
                        el-table-column(label="New", prop="sum_recc_price")
                        el-table-column(label="Configs", prop="sum_avia_configs")
                          template(slot-scope="sc")
                            span(v-if="Array.isArray(sc.row.sum_avia_configs)") {{ sc.row.sum_avia_configs.join(", ") }}
                      el-table-column(label="Price Ok?" width="70px")
                        template(slot-scope="scope")
                          i(:class="priceIsGood(scope.row)")
                      el-table-column
                        template(slot-scope="sc")
                          el-button.wrap(type="primary" plain @click="expandDetails(index, scope.row.locator)" :icon="cardExpander[index+'_'+scope.row.locator] ? 'el-icon-arrow-up' : 'el-icon-arrow-down'") Pricer

                el-row(v-if="cardExpander[index+'_'+scope.row.locator]")
                  el-row(v-for="(stata, locator) in getByLocators(stata_scoped)", :key="locator" class="block-item")
                    el-row
                      h3 Price Component {{locator}}:
                      h5 Session: {{stata[0]["session_id"]}}
                    el-row
                      el-table(:data="stata", :row-class-name="tablePriceClassName")
                        el-table-column(label="Search Config")
                          template(slot-scope="scp")
                            span {{ getConfigString(scp.row["config_ids"]) }}
                        el-table-column(label="Formula Price")
                          el-table-column(label="PC", prop="book_price")
                          el-table-column(label="New", prop="recc_price")
                        el-table-column(label="Fares")
                          template(slot-scope="scp")
                            span {{ scp.row["fare_codes"].join(", ") }}
                            div(v-if="scp.row['brand'] && scp.row['brand'] != ''")
                              span.green FF: {{ scp.row["brand"] }}

                        el-table-column(label="PriceScheme")
                          el-table-column(label="startP" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "st_p", false) }}
                          el-table-column(label="M" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "0", true) }}
                          el-table-column(label="I" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "1", true) }}
                          el-table-column(label="C" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "2", true) }}
                          el-table-column(label="P" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "3", true) }}
                          el-table-column(label="S" width="70px")
                            template(slot-scope="scope")
                              div S: {{ sumColumn(scope.row.rec_pr_details, "4", true) }}
                              div Rec: {{ sumColumn(scope.row.rec_pr_details, "segment_cost_amount", false) }}
                              div Book: {{ sumColumn(scope.row.rec_pr_details, "original_cost_amount", false) }}
                          el-table-column(label="A" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "5", true) }}
                          el-table-column(label="RD" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "6", true) }}
                          el-table-column(label="R" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "20", true) }}
                          el-table-column(label="Meta" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "meta", false) }}
                          el-table-column(label="BspC" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "bsp_c", false) }}
                          el-table-column(label="RateM" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "rate_margin", false) }}
                          el-table-column(label="URate" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "u_rate_margin", false) }}
                          el-table-column(label="Revenue" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "revenue_margin", false) }}
                          el-table-column(label="SaleP" width="70px")
                            template(slot-scope="scope")
                              span {{ sumColumn(scope.row.rec_pr_details, "sl_p", false) }}
                        el-table-column(label="Baggage Match" width="70px")
                          template(slot-scope="scope")
                            i(:class="checkBaggage(scope.row)")
                            span ({{ scope.row["baggage_data"] }})
                        el-table-column(label="Price Ok?" width="70px")
                          template(slot-scope="scope")
                            i(:class="priceIsGood(scope.row)")
                        el-table-column(type="expand")
                          template(slot-scope="sc")
                            div
                              priceLog(:priceRules="decode(scope.row.fakeBooking.priceRules)", :pcId="sc.row['pc_id'].toString()", :recId="sc.row['recc_id']", v-if="scope.row.fakeBooking.priceRules")
                            div
                              h3 Recc Segment Cost Log:
                              segmentCost(:rules="getSegmentCost(sc.row, 'segment_cost_rules')")
                            div
                              h3 Order Segment Cost Log:
                              segmentCost(:rules="getSegmentCost(sc.row, 'segment_cost_orig_rules')")

            el-tab-pane(label="Fallback", :key='"fallback" + scope.row.locator', :name='"fallback" + scope.row.locator', v-if="scope.row.fakeBooking")
              fallBack(:searchStat="scope.row.fakeBooking.searchResultsFallback", :priceRules='scope.row.fakeBooking.priceRulesFallback', v-if="scope.row.fakeBooking.searchResultsFallback")

            el-tab-pane(label="Attempts", :key='"attempts" + scope.row.locator', :name='"attempts" + scope.row.locator', v-if="scope.row.fakeBooking")
              attempts(:searchStat="scope.row.fakeBooking.searchAttempts", v-if="scope.row.fakeBooking.searchAttempts")

            el-tab-pane(label="Rebook Result", :key='"rebook" + scope.row.locator', :name='"rebook" + scope.row.locator', v-if="scope.row.fakeBooking")
              el-row(v-for="(stata, locator) in getBookings(scope.row.fakeBooking)", :key="locator" class="block-item")
                el-row
                  h3 Price Component {{findLocator(stata['pc_id'], decode(scope.row.fakeBooking.searchStat))}}:
                el-row
                  el-table(:stripe="true", :data="wrapArray(stata)")
                    el-table-column(label="Locator")
                      template(slot-scope="scp")
                        span {{ scp.row["booking"] ? scp.row["booking"]["locator"] : ''  }}
                    el-table-column(label="Baggage")
                      template(slot-scope="scp")
                        span {{ baggageData( scp.row["recc_id"], decode(scope.row.fakeBooking.searchStat), decode(scope.row.fakeBooking.searchResultsFallback) ) }}
                    el-table-column(label="Formula")
                      el-table-column(label="Original")
                        template(slot-scope="scp")
                          span {{ scp.row["book_price"] }}
                      el-table-column(label="ReBook")
                        template(slot-scope="scp")
                          span {{ scp.row["recc_price"] }}
                    el-table-column(label="ReccID")
                      template(slot-scope="scp")
                        span {{ scp.row["recc_id"] }}
                priceLog(:priceRules="decode(scope.row.fakeBooking.priceRules)", :pcId='stata["pc_id"].toString()', :recId="stata['recc_id']", v-if="scope.row.fakeBooking.priceRules")
    SearchRuleWindow(ref="SearchRuleWindowRef")

</template>

<script>
import BaseTable from '@crud_view/table'
import VJsoneditor from 'v-jsoneditor'

import priceLog from './priceLog'
import segmentCost from './segmentCost'
import fallBack from './fallBack'
import attempts from './attempts'
import SearchRuleWindow from './searchRuleForm'

export default {
  components: {
    BaseTable,
    VJsoneditor,
    priceLog,
    segmentCost,
    fallBack,
    attempts,
    SearchRuleWindow
  },
  extends: BaseTable,

  data() {
    return{
      configItems: {},
      formLabelWidth: '120px',
      activeName: {},
      configItems: {},
      trafficSources: {},
      currentRowId: 0,
      plus: true,
      orderedRules: [],
      options: {
        modes: ['text', 'code', 'tree', 'form', 'view'],
        mode: 'code',
        ace: ace
      },
      rebookStatuses: [
        'in_queue',
        'in_progress',
        'no_alternative',
        'profit_booking',
        'loss_booking',
        'wrong_booking_status',
        'search_error',
        'overtop_loss',
        'partially_booked',
        'unconfirmed_flights',
        'initial_booking',
        'issued',
        'cancel',
        'exception',
        'fake_is_off',
        'payed',
        'fallback',
        'fallback_processing',
        'requeued',
        'prefake'
      ],
      cardExpander: {}
    }
  },

  created() {
    this.configItems = this.$store.getters['bootData']['config_items_hash']
  },

  methods: {

    actionRefreshStatistic(row, type, fkID) {
      this.$refs.SearchRuleWindowRef.show(row["configs"].join(", "), type, fkID, row["pc_id"])
    },

    isExpanded(index, locator) {
      return this.cardExpander[index + '_' + locator] ? 'active' : 'passive'
    },

    expandDetails(index, locator) {
      let key = index + '_' + locator

      if(this.cardExpander[key] == true) {
        this.cardExpander[key] = false
      }else{
        this.cardExpander[key] = true
      }

      this.cardExpander = { ...this.cardExpander }
    },

    expandedIcon(index, locator) {
      let key = index + '_' + locator
      return this.cardExpander[key] ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
    },

    getConfigString(configs) {
      return configs.map((configId) => {
        if(this.configItems[configId] != undefined){
          return this.configItems[configId]["name"] + " (" + configId + ")"
        }else{
          return configId
        }
      }).join(", ")
    },

    parseRules(item) {
      return {
        rule: JSON.parse(item.rule),
        airlineRule: JSON.parse(item.airlineRule),
        attrs: JSON.parse(item.attrs)
      }
    },

    wrapArray(obj) {
      return [obj]
    },

    colorCard(index) {
      let card_class = 'card_even'
      if(index % 2 == 0) {
        card_class = 'card_odd'
      }

      return card_class
    },

    getSegmentCost(row, field) {
      return row[field].flat();
    },

    priceIsUp(row) {
      return row['price_is_up'] ? 'true' : 'false'
    },

    priceLog(row) {
      return [row[price_log]]
    },

    getBookings(fakeBooking) {
      return JSON.parse(fakeBooking.rebookResults)
    },

    getRebookStatus(row) {
      return row.fakeBooking ? this.rebookStatuses[row.fakeBooking.status] : ''
    },

    rebookAvailable(row) {
      return ['in_queue', 'exception', 'fake_is_off'].includes(this.getRebookStatus(row))
    },

    getProfit(row) {
      return (row.fakeBooking && [10, 11, 15].includes(row.fakeBooking.status)) ? row.fakeBooking.profit + ' ' + row.fakeBooking.currency : ''
    },

    checkBaggage(item) {
      return item['baggage_mismatch'] ? 'el-icon-close' : 'el-icon-check'
    },

    sumPriceIsGood(item) {
      return item['sum_price_is_up'] ? 'el-icon-close' : 'el-icon-check'
    },

    priceIsGood(item) {
      let klass = item['sum_price_is_up'] ? 'el-icon-close' : 'el-icon-check'

      if(typeof item['sum_price_is_up'] == 'undefined') {
        klass = item['price_is_up'] ? 'el-icon-close' : 'el-icon-check'
      }
      return klass
    },

    sumColumn(stata, column, rl) {
      var sum = 0
      stata.forEach((element) => {
        if(rl == true) {
          sum += parseFloat(element['rl'][column]['m'])
        }else{
          if(column == "segment_cost_amount" || column == "original_cost_amount"){
            if( Array.isArray(element[column]) ){
              sum = element[column].reduce((partialSum, a) => partialSum + a, 0);
            }else{
              sum = element[column];
            }
          }else{
            sum += parseFloat(element[column])
          }
        }
      })
      return sum
    },

    wrapStatus(status) {
      if (status === true) {
        return '<span class="el-tag el-tag--success color-inherit"> allowed </span>'
      } else {
        return '<span class="el-tag el-tag--danger color-inherit"> blocked </span>'
      }
    },

    getRules(rule, stash) {
      var data = [JSON.parse(rule)].flat();
      if(stash){
        this.orderedRules = data;
      }
      return data;
    },

    decode(item) {
      return JSON.parse(item)
    },

    findLocator(pcId, searchStat) {
      var locator = ''
      searchStat.forEach((element) => {
        if(element['pc_id'] == pcId) {
          locator = element['pc_locator']
          return
        }
      })

      return locator
    },

    baggageData(reccId, searchStat, fallbackStat){
      var baggage = "-";
      searchStat.forEach((element) => {
        if(element['recc_id'] == reccId) {
          baggage = element['baggage_data']
          return
        }
      })

      if( baggage == "-"){
        fallbackStat.forEach((element) => {
          if(element['recc_id'] == reccId) {
            baggage = element['baggage_data']
            return
          }
        })
      }

      return baggage;
    },

    tablePriceClassName({ row, rowIndex }) {

      let is_price_up = row['price_is_up']

      if(typeof row['sum_price_is_up'] == 'undefined') {
        is_price_up = row['price_is_up']
      }

      var klass = (is_price_up == false && row['baggage_mismatch'] == false) ? 'status_price_ok' : ''

      if(klass == 'status_price_ok'){
        if( row["c"] == true ){
          klass = 'cheapest_price'
        }
      }

      // var orderedPC = this.orderedRules.find((element) => element["pc_id"] == row["pc_id"]);
      // if( orderedPC == undefined ){
      //   if(this.orderedRules[0]["pc_id"] == undefined){
      //     orderedPC = this.orderedRules[0];
      //   }
      // }

      // if(orderedPC != undefined){
      //   var config = orderedPC["configs"].map((x) => parseInt(x) )[0];
      //   if( row["config_ids"].includes(config) ){
      //     klass += ' bold'
      //   }
      // }

      return klass
    },

    tablePriceHeaderClassName({ row, rowIndex }) {
      let is_price_up = row['price_is_up']

      if(typeof row['sum_price_is_up'] == 'undefined') {
        is_price_up = row['price_is_up']
      }

      return (is_price_up == false && row['baggage_mismatch'] == false) ? 'status_price_ok' : ''
    },

    tableRowClassName({ row, rowIndex }) {
      this.activeName[row.locator] = 'p' + row.locator

      var booking = row.booking
      if (booking == null) {
        return ''
      } else {
        return 'status_row_' + row.booking.status
      }
    },

    getPriceRows(row) {
      return [{ 'currency' : row.depot.currency }, { 'currency' : 'EUR' }]
    },

    scopedDetailsExists(scopedDetails) {
      if(scopedDetails.length == 0 || scopedDetails[0].length == 1) {
        return false
      }else{
        return true
      }
    },

    getLooper(data) {
      var stata = JSON.parse(data)
      if(stata.length > 0 && Array.isArray(stata[0])) {
        return stata
      }else{
        return [stata]
      }
    },

    getShortenScopeData(obj) {
      return [obj[0]]
    },

    getByLocators(stata) {
      var locators = {}

      if(stata.length > 0) {
        stata.forEach((element) => {
          if(!locators[element['pc_locator']]) {
            locators[element['pc_locator']] = []
          }

          locators[element['pc_locator']].push(element)
        })
      }
      return locators
    },

    getPrice(currency, type, row) {
      let addlInfo = JSON.parse(row.booking.additionalInfo)
      switch(type) {
        case 'netto_old':
          return JSON.parse(row.booking.startAllPrice)[currency].toFixed(2)
        case 'extra_charge':
          return (JSON.parse(row.booking.finalAllPrice)[currency] - JSON.parse(row.booking.startAllPrice)[currency]).toFixed(2)
        case 'sum_pay':
          return JSON.parse(row.booking.finalAllPrice)[currency].toFixed(2)
        case 'fake_baggage':
          if(addlInfo['fake_baggage'] !== undefined) {
            var total = 0
            for(var t in addlInfo['fake_baggage']) {
              total += addlInfo['fake_baggage'][t]['amounts'][currency]
            }
            return total
          }else{
            return '0.00'
          }
        case 'dynamic_cost':
          if(addlInfo['dynamic_comission'] !== undefined) {
            return addlInfo['dynamic_comission'][currency]
          }else{
            return '0.00'
          }
        default:
          return '0.00'
      }
    },

    run(row) {
      this.$confirm(this.$t('alert.delete_confirm'), this.$t('alert.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
        try {
          const query = {
            __args: {
              id: row.id,
              fakeBookingId: row.fakeBooking.bookingId
            }
          }

          await this.graphql('runRebooking', query, 'mutation')
          this.$message({
            message: 'Success!',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: e,
            type: 'error'
          })
        }
      }).catch((e) => {})
    }
  }
}
</script>

<style lang="scss">
  .fake_search_stata_table {
    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .el-table tr {
      &.bold{
        font-weight: bold;
      }
      &.status_price_ok{
        background: #e1f3d8;
      }
      &.cheapest_price{
        background: #ffe681;
      }
      &.status_row_W{
        background: #80FFFF;
      }
      &.status_row_P{
        background: #9EFF7F;
      }
      &.status_row_PN{
        background: #FFE681;
      }
      &.status_row_PN_autotckt{
        background: #CCCC99;
      }
      &.status_row_CM{
        background: #E5CBF1;
      }
      &.status_row_CMO{
        background: #f1e0cb;
      }
      &.status_row_CMP{
        background: #cbd3f1;
      }
      &.status_row_CMW{
        background: #9EFF7F;
      }
      &.status_row_NP{
        background: #70AAE9;
      }
      &.status_row_WA{
        background: #B97A57;
      }
      &.status_row_LCP{
        background: #00AEE1;
      }
      &.status_row_OB{
        background: blue;
      }
    }

    .green {
      color: green;
    }

    .card_even{
      background: #F0F0F1;
    }
  }
</style>
