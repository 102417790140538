export default {
  legend: 'fare_data',
  translateLegend: true,
  items: [
    {
      name: 'INC',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    },
    {
      name: 'CHA',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    },
    {
      name: 'NOF',
      type: 'text',
      valueType: 'array_of_string',
      required: false
    }
  ]
}