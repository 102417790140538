export default {
  logs: {
    table: {
      requestType: 'Request type',
      message: 'Message'
    },
    form: {
      fields: {
        logDate: 'Date',
        requestType: 'Request type'
      }
    }
  },
  airports: {
    table: {
      code: 'IATA code',
      tzName: 'Time zone'
    },
    form: {
      fields: {
        code: 'IATA code',
        tzName: 'Time zone'
      }
    }
  }
}